import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../../App/app.service";
import DataVisualizer from "../../../components/ChartBuilder/DataVisualizer";
import SingleLevelDrilldown from "../../../components/Drilldown/SingleLevelDrilldown";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
} from "../../../util/customFunctions";
import QuarterTrendsTable from "../../components/QuarterTrendsTable";
import SummaryTrendsTable from "../AccountHealth/components/SummaryTrendsTable";
import QBRDrilldown from "./components/QBRDrilldown";
import QBRTable from "./components/QBRTable";
import addHeaderdescription from "../../../components/HeaderDescription";

const styles = (theme) => ({
  gridItem: {
    margin: "1rem",
  },
});
class QuarterlyBusinessReview extends Component {
  filters = ["QBR_Fiscal_Qtr", "Account_Type"];
  state = {
    QBR_Fiscal_Qtr: ["All"],
    Account_Type: ["Customer"],
    disabled: ["year", "month", "quarter"],
    filterAccount: ["Customer"],
  };
  componentDidMount() {
    this.props.getQBRFilters();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      let defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelCompareFilters,
        "QBR_Fiscal_Qtr"
      );
      if (defaultFiscalQuarter.length <= 0) {
        defaultFiscalQuarter = ["All"];
      }
      this.setState({
        ...prevState,
        QBR_Fiscal_Qtr: defaultFiscalQuarter,
        Account_Type: ["Customer"],
        filterAccount: ["Customer"],
      });
    }
  }

  passFiltersForDrilldown = () => {
    let filters = [];
    if (!this.state.Account_Type.includes("All")) {
      filters = [...filters, this.state.Account_Type];
    }

    if (this.state.Account_Type.includes("All")) {
      filters = [...filters, "All"];
    }

    return filters[0];
  };

  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
    });
    this.props.setTable("");
  };
  handleChangeLocation = (location, filters, metaData) => {
    console.log(metaData);
    const alteredCol = Object.keys(filters).includes("cell") ? "cell" : "col";
    let formattedFilters = [
      {
        name: "QBR_Fiscal_Qtr",
        value:
          typeof filters[alteredCol] === "object"
            ? filters[alteredCol] === null
              ? [null]
              : filters[alteredCol]
            : [filters[alteredCol]],
        string: true,
      },
      {
        name: "Account_Segment",
        value: typeof filters.row === "object" ? filters.row : [filters.row],
        string: true,
      },
    ];

    if (!this.state.Account_Type.includes("All")) {
      formattedFilters = [
        ...formattedFilters,
        { name: "Account_Type", value: this.state.filterAccount, string: true },
      ];
    }
    // make api call
    this.props.setTable("ChangeOverTime");
    this.props.getChartDrilldown("changeOverTime", formattedFilters);

    // update the state and open drilldown window
    this.setState({
      ...this.state,
      location: location,

      filtersForDrilldown: [
        ...formattedFilters.map((item) => ({
          ...item,
          title: item.name.split("_").join(" "),
        })),
      ],
    });
  };
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    this.setState({
      ...this.state,
      filterAccount: this.passFiltersForDrilldown(),
    });
    this.props.getQBRData(this.props.company, filters);
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer}>
        {/* <Grid
          className={classes.gridItem}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            QBRs Completed
          </Typography>
          <Typography variant="body1">
            Explore the QBRs completed by account segment and CARR.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "QBRs Completed",
          "Explore the QBRs completed by account segment and CARR."
        )}
        <Grid className={classes.gridItem} item container justify="flex-start">
          <Grid
            justify="flex-start"
            item
            xs={2}
            container
            component={Paper}
            style={{ padding: 10, marginRight: 10 }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    color: !this.state.QBR_Fiscal_Qtr.includes("All")
                      ? "#4472c4"
                      : "#000",
                  }}
                  id="select-vfiscalQuarter-label"
                >
                  QBR Fiscal Quarter:
                </InputLabel>
                <Select
                  labelId="select-vfiscalQuarter-label"
                  id="select-cfiscalQuarter"
                  value={this.state.QBR_Fiscal_Qtr}
                  onChange={this.handleChange}
                  label="QBR Fiscal Quarter"
                  name="QBR_Fiscal_Qtr"
                  multiple
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {this.props.funnelCompareFilters !== "" &&
                    this.props.funnelCompareFilters
                      .filter(
                        (y) =>
                          y.type === "QBR_Fiscal_Qtr" &&
                          y["QBR_Fiscal_Qtr"] !== ""
                      )
                      .map((y) => (
                        <MenuItem
                          key={y["QBR_Fiscal_Qtr"]}
                          value={y["QBR_Fiscal_Qtr"]}
                        >
                          {y["QBR_Fiscal_Qtr"]}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            justify="flex-start"
            item
            xs={2}
            container
            component={Paper}
            style={{ padding: 10, marginRight: 10 }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    color: !this.state.Account_Type.includes("All")
                      ? "#4472c4"
                      : "#000",
                  }}
                  id="select-vfiscalQuarter-label"
                >
                  Account Type:
                </InputLabel>
                <Select
                  labelId="select-vfiscalQuarter-label"
                  id="select-cfiscalQuarter"
                  value={this.state.Account_Type}
                  onChange={this.handleChange}
                  label="Account Type"
                  name="Account_Type"
                  multiple
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {this.props.funnelCompareFilters === "" && (
                    <MenuItem value={"Customer"}>{"Customer"}</MenuItem>
                  )}
                  {this.props.funnelCompareFilters !== "" &&
                    this.props.funnelCompareFilters
                      .filter(
                        (y) =>
                          y.type === "Account_Type" && y["Account_Type"] !== ""
                      )
                      .map((y) => (
                        <MenuItem
                          key={y["Account_Type"]}
                          value={y["Account_Type"]}
                        >
                          {y["Account_Type"]}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            item
            sm={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" color="primary" onClick={this.handleGo}>
              GO
            </Button>
          </Grid>
        </Grid>

        <Grid className={classes.gridItem} item container justify="flex-start">
          {this.props.QBRData !== "" ? (
            <DataVisualizer
              title={`QBRs Completed`}
              type={"Account_Segment"}
              chartName="QBR"
              chartType="number"
              winACVMixAnalysisData={this.props.QBRData[0].map((item) => ({
                ...item,
                acv: item.CARR,
              }))}
              handleChangeLocation={this.handleChangeLocation}
              quarter={"QBR_Fiscal_Qtr"}
              unit="count"
              isDrilldown={true}
              page="QBRSuccess"
              tableComponent={
                <SummaryTrendsTable
                  handleChangeLocation={this.handleChangeLocation}
                  data={this.props.QBRData[0]}
                  type="count"
                  row="Account_Segment"
                  col="QBR_Fiscal_Qtr"
                  chartName="QBRSummary"
                  rowHeading="QBR Fiscal Quarter"
                />
              }
            />
          ) : (
            <Grid
              item
              container
              xs={12}
              className={classes.gridItem}
              component={Card}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid className={classes.gridItem} item container justify="flex-start">
          {this.props.QBRData !== "" ? (
            <DataVisualizer
              title={`QBRs Completed - CARR Coverage`}
              type={"Account_Segment"}
              chartType="CARR"
              chartName="QBR"
              winACVMixAnalysisData={this.props.QBRData[0].map((item) => ({
                ...item,
                acv: item.CARR,
              }))}
              handleChangeLocation={this.handleChangeLocation}
              quarter={"QBR_Fiscal_Qtr"}
              unit="acv"
              isDrilldown={true}
              page="QBRSuccess"
              tableComponent={
                <SummaryTrendsTable
                  handleChangeLocation={this.handleChangeLocation}
                  data={this.props.QBRData[0]}
                  type="CARR"
                  row="Account_Segment"
                  col="QBR_Fiscal_Qtr"
                  chartName="QBRSummary"
                  rowHeading="QBR Fiscal Quarter"
                />
              }
            />
          ) : (
            <Grid
              item
              container
              xs={12}
              className={classes.gridItem}
              component={Card}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid className={classes.gridItem} item container justify="flex-start">
          {this.props.QBRData !== "" ? (
            <Grid container component={Card}>
              <Grid item style={{ padding: "2rem" }} xs={12}>
                <Typography
                  align="center"
                  variant="h2"
                  style={{ margin: "1rem" }}
                >
                  QBRs Completed - Coverage by segment
                </Typography>
              </Grid>
              <QBRTable
                data={this.props.QBRData}
                type="count"
                row="Account_Segment"
                column="QBR_Fiscal_Qtr"
                chartName="QBRSummary"
                handleChangeLocation={this.handleChangeLocation}
              />
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={12}
              className={classes.gridItem}
              component={Card}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Grid>
          )}
        </Grid>
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`QBRs Completed`}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <QBRDrilldown
                header={`QBRs Completed`}
                body={this.props.drilldownOnGraphData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  const { QBRFilters, QBRData, drilldownOnGraphData, user } = state.app;

  return {
    funnelCompareFilters: QBRFilters,
    QBRData,
    drilldownOnGraphData,
    company: user.company,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },
  getChartDrilldown: (key, filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });

    appService.getChartDrilldown("C0003.CHID00000018", key, filters).then(
      (json) => {
        let tempOpportunities = json.message.recordsets[1];
        tempOpportunities = tempOpportunities.sort((a, b) =>
          a.CARR > b.CARR ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let x = {
            ...item,
            OppNo: index + 1,
          };
          return x;
        });
        // tempOpportunities.sort((a, b) => a.ACV > b.ACV ? -1 : 1)

        // tempOpportunities = tempOpportunities.map((item, index) => {
        //   let createdDateString = (item.Created_Date.split('T')[0])
        //   var [createdYear, createdMonth, createdDate] = createdDateString.split("-")
        //   let temp = {
        //     ...item,
        //     OppNo: index + 1,
        //     age: Math.round(
        //       (new Date(Date.now()).setHours(0) - (new Date(new Date(parseInt(createdYear), parseInt(createdMonth) - 1, parseInt(createdDate.substr(0, 2))).setHours(0)).setMinutes(0))) /
        //       (1000 * 60 * 60 * 24)
        //     ),
        //   }
        //   return temp
        // })

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.recordsets[0],
            opportunities: tempOpportunities,
            // columns: json.columns
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getQBRFilters: (company) => {
    dispatch({ type: "get_QBR_filters_request" });
    appService.getChartFilters("C0003.CHID00000018").then(
      (json) => {
        dispatch({ type: "get_QBR_filters_success", json });
        dispatch({ type: "get_QBR_data_request" });
        let filters = [];
        const defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message,
          "QBR_Fiscal_Qtr"
        );
        if (defaultFiscalQuarter.length > 0) {
          filters = [
            ...filters,
            {
              name: "QBR_Fiscal_Qtr",
              value: defaultFiscalQuarter,
              string: true,
            },
            { name: "Account_Type", value: ["Customer"], string: true },
          ];
        }
        appService.getChartData("C0003.CHID00000018", filters).then(
          (json) => {
            dispatch({ type: "get_QBR_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_QBR_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_QBR_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_QBR_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_QBR_filters_failure", error });
      }
    );
  },
  getQBRData: (company, filters = []) => {
    dispatch({ type: "get_QBR_data_request" });
    appService.getChartData("C0003.CHID00000018", filters).then(
      (json) => {
        dispatch({ type: "get_QBR_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_QBR_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_QBR_data_failure", error });
      }
    );
  },
});

const connectedQuarterlyBusinessReview = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QuarterlyBusinessReview));
export default connectedQuarterlyBusinessReview;
