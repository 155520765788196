import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import {
  countOrACVFormatterMillion,
  customDateFormatter,
  getUniqueValues,
  StyledTableRow,
} from "../../util/customFunctions";

import { makeStyles } from "@material-ui/core";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const tableStyle = makeStyles(
  (theme) => ({
    root: {
      margin: "0.5rem",
      padding: "0.5rem",
    },
    colHeaderNew: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      backgroundColor: "inherit",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    dataCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
    },
    drilldownCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      // cursor: "pointer",
    },
    drilldownCellNQP: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: "3%",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    cellMargin: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      paddingLeft: "2rem",
    },
    fontBold: {
      fontWeight: 600,
    },
    indentName: {
      paddingLeft: 30,
    },
    noBorder: {
      border: "none",
    },
    primaryColumnColor: {
      backgroundColor: "#4472C4",
      color: "white",
    },
  }),
  { index: 1 }
);

function SummaryTable(props) {
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [parentWidth, setparentWidth] = useState();

  console.log("props.data", props);
  props.rows.map((team, i) => {
    console.log("tead", team, i);
    const teampData = props.data.filter((c) => c[props.gb] === team);
    const splits = props.splits;
    console.log("teampData", teampData);
    console.log("splits", splits);
  });

  useEffect(() => {
    // Get the width of the parent TableContainer
    const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(parentWidth);
  }, [parentWidth]);

  const classes = tableStyle();

  return (
    <div
      style={{
        maxWidth: "100%",
        justifyContent: "center",
        padding: "2.5rem",
        paddingTop: 0,
      }}
    >
      {/* <Grid container component={Card} raised> */}
      <Grid xs={12}>
        {parentWidth !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
              width: `${
                tableContainerRef.current.getBoundingClientRect().width
              }px`,
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{
          width: "auto",
        }}
        id="myTableContainer-Second"
        ref={tableContainerRef}
      >
        <Table ref={tableRef} data-test={props.dataTest} id={props.tableID}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.colHeader}
                style={{
                  minWidth: 150,
                  whiteSpace: "nowrap",
                  background: "white",
                }}
              >
                Team / Rep ↓ / Week Ending →
              </TableCell>
              {props.columns.map((col, index) => (
                <TableCell
                  colSpan={props.length}
                  style={
                    index % 2 === 0
                      ? {
                          background: "#4471c4",
                          color: "white",
                          minWidth: "7rem",
                        }
                      : {
                          background: "#5b9bd5",
                          color: "white",
                          minWidth: "7rem",
                        }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {["", null, undefined, "Total"].includes(col)
                    ? col
                    : customDateFormatter(col)}{" "}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((team, i) => {
              const teampData = props.data.filter((c) => c[props.gb] === team);
              const splits = props.splits;

              return (
                <>
                  {" "}
                  <StyledTableRow
                    key={i}
                    className={classes.fontBold}
                    style={{}}
                  >
                    <TableCell
                      // className={classes.colHeader}
                      className={`${classes.colHeader}`}
                      style={{ background: "#f5f5f5" }}
                      //   onClick={() => handleExpandClick(i)}
                    >
                      {team}
                    </TableCell>
                    {props.columns.map((it) => {
                      return (
                        <TableCell
                          align="right"
                          // className={classes.colHeader}
                          className={classes.drilldownCell}
                          style={{}}
                        ></TableCell>
                      );
                    })}
                  </StyledTableRow>
                  {["Goal Total"].map((it, k) => {
                    return (
                      <StyledTableRow key={k}>
                        <TableCell
                          // className={`${classes.dataCell} ${classes.indentName}`}
                          className={classes.colHeader}
                          style={{
                            whiteSpace: "nowrap",
                            // background: "#fffff",
                            paddingLeft: "25px",
                          }}
                        >
                          {it}
                        </TableCell>
                        {props.columns.map((col) => {
                          const goal = teampData.filter(
                            (f) => f[props.timeframe] === col
                          );
                          let g = "-";
                          if (goal.length > 0) {
                            if (
                              ![null, undefined, ""].includes(goal[0].totalGoal)
                            ) {
                              g = countOrACVFormatterMillion(
                                goal
                                  .map((t) => t.totalGoal)
                                  .reduce((a, b) => a + b, 0),
                                true
                              );
                            }
                          }
                          return (
                            <TableCell
                              align="right"
                              // className={classes.colHeader}
                              className={classes.colHeader}
                              //   style={{ background: "#ffffff" }}
                            >
                              {g}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
                  {["Actual Total"].map((it, k) => {
                    return (
                      <StyledTableRow key={k}>
                        <TableCell
                          // className={`${classes.dataCell} ${classes.indentName}`}
                          className={classes.colHeader}
                          style={{
                            background: "#ffffff",
                            paddingLeft: "25px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {it}
                        </TableCell>

                        {props.columns.map((col) => {
                          // console.log(col, teampData.filter(f => f[props.timeframe]), teampData, 'TTTTTT')
                          const goal = teampData.filter(
                            (f) => f[props.timeframe] === col
                          );
                          let g = "-";
                          let bg = "#ffffff";
                          if (goal.length > 0) {
                            if (
                              ![null, undefined, ""].includes(
                                goal[0].totalActual
                              )
                            ) {
                              g = countOrACVFormatterMillion(
                                goal
                                  .map((t) => t.totalActual)
                                  .reduce((a, b) => a + b, 0),
                                true
                              );

                              const target = goal
                                .map((t) => t.totalGoal)
                                .reduce((a, b) => a + b, 0);
                              const act = goal
                                .map((t) => t.totalActual)
                                .reduce((a, b) => a + b, 0);
                              if (target > 0 && act >= target) {
                                bg = "#E2EFDA";
                              }
                            }
                          }
                          return (
                            <TableCell
                              align="right"
                              // className={classes.colHeader}
                              className={classes.colHeader}
                              style={{ background: bg, cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  {
                                    row: splits,
                                    cell:
                                      col === "Total"
                                        ? props.columns.filter(
                                            (f) => f !== "Total"
                                          )
                                        : [col],
                                    repOrTeam:
                                      team === "Grand Total"
                                        ? props.rows.filter(
                                            (f) => f !== "Grand Total"
                                          )
                                        : [team],
                                  },
                                  props.type,
                                  props.timeframe,
                                  props.gb
                                )
                              }
                            >
                              {g}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
                  {["Delta (Under -ve / Over +ve) Total"].map((it, k) => {
                    return (
                      <StyledTableRow key={k}>
                        <TableCell
                          // className={`${classes.dataCell} ${classes.indentName}`}
                          className={classes.colHeader}
                          style={{
                            //   background: "#dae3f3",
                            paddingLeft: "25px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {it}
                        </TableCell>

                        {props.columns.map((col) => {
                          const goal = teampData.filter(
                            (f) => f[props.timeframe] === col
                          );
                          let g = "-";
                          let cl = "inherit";
                          if (goal.length > 0) {
                            if (
                              ![null, undefined, ""].includes(goal[0].Delta)
                            ) {
                              g = countOrACVFormatterMillion(
                                goal
                                  .map((t) => t.Delta)
                                  .reduce((a, b) => a + b, 0),
                                true
                              );
                            }

                            if (
                              ![null, undefined, ""].includes(goal[0].Delta) &&
                              goal
                                .map((t) => t.Delta)
                                .reduce((a, b) => a + b, 0) < 0
                            ) {
                              cl = "red";
                            }
                          }
                          return (
                            <TableCell
                              align="right"
                              // className={classes.colHeader}
                              className={classes.colHeader}
                              style={{ color: cl }}
                            >
                              {g}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
                  {team !== "Grand Total" &&
                    [""].map((it, k) => {
                      {
                        console.log("teasData", team);
                      }
                      return (
                        <StyledTableRow key={k} style={{}}>
                          <TableCell
                            // className={`${classes.dataCell} ${classes.indentName}`}
                            className={`${classes.dataCell} ${classes.indentName}`}
                            style={{ padding: "18px", whiteSpace: "nowrap" }}
                          ></TableCell>
                          {props.columns.map((col) => {
                            return (
                              <TableCell
                                align="right"
                                // className={classes.colHeader}
                                className={classes.drilldownCell}
                                style={{ padding: "18px" }}
                              ></TableCell>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
                </>
              );
            })}
          </TableBody>
          {/* <TableBody>
            {(() => {
              const firstTeam = props.rows[0];
              const teampData = props.data.filter(
                (c) => c[props.gb] === firstTeam
              );
              const splits = props.splits;

              return (
                <>
                  {["Goal Total"].map((it, k) => {
                    return (
                      <StyledTableRow key={k}>
                        <TableCell
                          className={classes.colHeader}
                          style={{
                            whiteSpace: "nowrap",
                            paddingLeft: "25px",
                          }}
                        >
                          {it}
                        </TableCell>
                        {props.columns.map((col) => {
                          const goal = teampData.filter(
                            (f) => f[props.timeframe] === col
                          );
                          let g = "-";
                          if (goal.length > 0) {
                            if (
                              ![null, undefined, ""].includes(goal[0].totalGoal)
                            ) {
                              g = countOrACVFormatterMillion(
                                goal
                                  .map((t) => t.totalGoal)
                                  .reduce((a, b) => a + b, 0),
                                true
                              );
                            }
                          }
                          return (
                            <TableCell
                              align="right"
                              className={classes.colHeader}
                            >
                              {g}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
                  {["Actual Total"].map((it, k) => {
                    return (
                      <StyledTableRow key={k}>
                        <TableCell
                          className={classes.colHeader}
                          style={{
                            background: "#ffffff",
                            paddingLeft: "25px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {it}
                        </TableCell>

                        {props.columns.map((col) => {
                          const goal = teampData.filter(
                            (f) => f[props.timeframe] === col
                          );
                          let g = "-";
                          let bg = "#ffffff";
                          if (goal.length > 0) {
                            if (
                              ![null, undefined, ""].includes(
                                goal[0].totalActual
                              )
                            ) {
                              g = countOrACVFormatterMillion(
                                goal
                                  .map((t) => t.totalActual)
                                  .reduce((a, b) => a + b, 0),
                                true
                              );

                              const target = goal
                                .map((t) => t.totalGoal)
                                .reduce((a, b) => a + b, 0);
                              const act = goal
                                .map((t) => t.totalActual)
                                .reduce((a, b) => a + b, 0);
                              if (target > 0 && act >= target) {
                                bg = "#E2EFDA";
                              }
                            }
                          }
                          return (
                            <TableCell
                              align="right"
                              className={classes.colHeader}
                              style={{ background: bg, cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  {
                                    row: splits,
                                    cell:
                                      col === "Total"
                                        ? props.columns.filter(
                                            (f) => f !== "Total"
                                          )
                                        : [col],
                                    repOrTeam:
                                      firstTeam === "Grand Total"
                                        ? props.rows.filter(
                                            (f) => f !== "Grand Total"
                                          )
                                        : [firstTeam],
                                  },
                                  props.type,
                                  props.timeframe,
                                  props.gb
                                )
                              }
                            >
                              {g}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
                  {["Delta (Under -ve / Over +ve) Total"].map((it, k) => {
                    return (
                      <StyledTableRow key={k}>
                        <TableCell
                          className={classes.colHeader}
                          style={{
                            paddingLeft: "25px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {it}
                        </TableCell>

                        {props.columns.map((col) => {
                          const goal = teampData.filter(
                            (f) => f[props.timeframe] === col
                          );
                          let g = "-";
                          let cl = "inherit";
                          if (goal.length > 0) {
                            if (
                              ![null, undefined, ""].includes(goal[0].Delta)
                            ) {
                              g = countOrACVFormatterMillion(
                                goal
                                  .map((t) => t.Delta)
                                  .reduce((a, b) => a + b, 0),
                                true
                              );
                            }

                            if (
                              ![null, undefined, ""].includes(goal[0].Delta) &&
                              goal
                                .map((t) => t.Delta)
                                .reduce((a, b) => a + b, 0) < 0
                            ) {
                              cl = "red";
                            }
                          }
                          return (
                            <TableCell
                              align="right"
                              className={classes.colHeader}
                              style={{ color: cl }}
                            >
                              {g}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
                </>
              );
            })()}
          </TableBody> */}
        </Table>
      </TableContainer>
      {/* </Grid> */}
    </div>
  );
}
export default SummaryTable;
