import { capitalize } from "@mui/material";

export const getNumOfCols = (
  result,
  { distinctHalvesThen, distinctHalvesNow, distinctStagesThen }
) => {
  // distinctHalvesThen = distinctHalvesThen.filter((it) => it === "2024-H1");
  // distinctHalvesNow = distinctHalvesNow.filter((it) => it === "2024-H1");
  console.log(
    { distinctHalvesThen, distinctHalvesNow, distinctStagesThen },
    "current_qp_fixes"
  );
  const keys = Object.keys(result).map((it) => it.split("|"));
  const res = [];
  for (let tQtr of distinctHalvesThen) {
    for (let stage of distinctStagesThen) {
      for (let nQtr of distinctHalvesNow) {
        const val = keys.filter((it) => it[0] === tQtr && it[1] === nQtr);
        console.log(
          val,
          keys.filter(
            (it) => it[0] === tQtr && stage == it[2] && it[1] === nQtr
          ),
          "current_qp_fixes"
        );
        const temp = {
          nQtr,
          tQtr,
          tStage: stage,
          nStageCount: val.length,
          nStages: val,
        };
        res.push(temp);
      }
    }
  }
  console.log(res, "current_qp_fixes");
  let nStageCi = 0;
  let maxHalvesStagesNow = [];
  for (let nQtr of distinctHalvesNow) {
    const nStageC = res.filter((it) => it.nQtr == nQtr);
    nStageC.sort((a, b) => b.nStageCount - a.nStageCount);
    const maxVal = nStageC[0];
    nStageCi += maxVal.nStageCount;
    maxHalvesStagesNow.push(maxVal.nStages);
  }
  console.log(maxHalvesStagesNow, "current_qp_fixes");

  return { rowLength: nStageCi, maxHalvesStagesNow: maxHalvesStagesNow };
};
export const convertHalfToQuarters = (halves, halfToQuarterMap) => {
  return halves
    .map((it) => {
      const [year, half] = it.split("-");
      const quarters = halfToQuarterMap[half].map((qtr) => `${year}-${qtr}`);
      return quarters;
    })
    .flat();
};
export const filterNameDrilldownTitleMap = {
  Projected_Close_Date_At_Time_Fiscal_Quarter:
    "Projected_Close_Date_At_Time_Fiscal_Quarter".split("_").join(" "),
  Projected_Close_Fiscal_Quarter: "Projected_Close_Fiscal_Quarter"
    .split("_")
    .join(" "),
  Stage_At_Time_T: "Stage Then",
  Stage: "Stage Now",
  milestone_stage: "Milestone Stage",
};
export const sankeyDataTransformer = (data, referer) => {
  const sankeyData = [];
  for (let key in data) {
    const keyVals = key.split("|");
    const source = `source-qtr|${keyVals[0]}|source-stage|${keyVals[2]}`;
    const target = `target-qtr|${keyVals[1]}|target-stage|${keyVals[3]}`;
    const value = data[key][referer];
    sankeyData.push({
      source,
      target,
      value,
    });
  }
  return sankeyData;
};
export function parseField(field) {
  const parts = field.split("|");
  return {
    qtr: parts[1], // "2024-H2", for example
    stage: parts[3], // "Qualified / Develop", for example
  };
}
export function sortSankeyData(data, stagePriority) {
  return data.sort((a, b) => {
    const aSource = parseField(a.source);
    const bSource = parseField(b.source);
    const aTarget = parseField(a.target);
    const bTarget = parseField(b.target);

    // First sort by source quarter
    if (aSource.qtr < bSource.qtr) return -1;
    if (aSource.qtr > bSource.qtr) return 1;

    // Then by source stage priority
    if (stagePriority[aSource.stage] < stagePriority[bSource.stage]) return -1;
    if (stagePriority[aSource.stage] > stagePriority[bSource.stage]) return 1;

    // Then by target quarter
    if (aTarget.qtr < bTarget.qtr) return -1;
    if (aTarget.qtr > bTarget.qtr) return 1;

    // Finally by target stage priority
    if (stagePriority[aTarget.stage] < stagePriority[bTarget.stage]) return -1;
    if (stagePriority[aTarget.stage] > stagePriority[bTarget.stage]) return 1;

    return 0;
  });
}

/**
 *
 * @param {string} acv
 */
const acvFormatter = (acv) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(parseInt(acv));
};
export const drilldownBubblesFormatter = {
  acv: acvFormatter,
  acvlt: acvFormatter,
};
export const drilldownLabelMap = (key, valueLabel, splitJoin) => {
  const splitKeyAndJoin = (str) =>
    str
      .split("_")
      .map((w) => capitalize(w))
      .join(" ");

  const _drilldownLabelMap = {
    acv: `${valueLabel} >=`,
    acvlt: `${valueLabel} <`,
    Stage_At_Time_T: "From Stage",
    Stage: "To Stage",
  };
  if (_drilldownLabelMap[key]) {
    return _drilldownLabelMap[key];
  }
  if (splitJoin) {
    return splitKeyAndJoin(key);
  }
  return key;
};
