import { stopWordsSet } from "./data";
import * as fuzzball from "fuzzball";
import {
  get_highest_score_and_longest_string,
  get_highest_score_and_longest_string_for_dashboards,
} from "./utils";
import { getUniqueValues } from "../../util/customFunctions";
function removePunctuation(text) {
  return text.replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, "");
}
function removeStopWords(question, stopWordsArray) {
  const words = question.toLowerCase().split(" ");
  const filteredWords = words.filter((word) => !stopWordsSet.has(word));
  return filteredWords.join(" ");
}
// function which take keywordMap and return list of keywords
const getKeywords = (keywordMap) => {
  const keywords = [];
  for (const key in keywordMap) {
    if (Object.hasOwnProperty.call(keywordMap, key)) {
      const element = keywordMap[key];
      keywords.push(element);
    }
  }
  // flatten the keywords array
  return keywords.flat();
};
const findInAllWords = (cleanedQuestion, keywordData) => {
  const result = [];
  for (const dashboardId in keywordData) {
    if (Object.hasOwnProperty.call(keywordData, dashboardId)) {
      const keywords = keywordData[dashboardId];
      const matchedKeywords = [];
      keywords.forEach((keyword) => {
        // number of words in the longest matching phrase which is called matchingKeyWordCount here.
        let matchingKeyWordCount = 0;
        keyword.split(" ").forEach((word) => {
          if (cleanedQuestion.includes(word)) {
            matchingKeyWordCount += 1;
          }
        });
        if (matchingKeyWordCount > 0) {
          result.push({ matchingKeyWordCount, dashboardId, keyword });
        }
      });
    }
  }
  const sortedResult = result.sort(
    (a, b) => b.matchingKeyWordCount - a.matchingKeyWordCount
  );
  if (sortedResult[0] === undefined) {
    return [];
  }
  const maxCountValue = sortedResult[0].matchingKeyWordCount;
  const setResult = [
    ...new Set(
      result
        .filter((it) => it.matchingKeyWordCount === maxCountValue)
        .map((it) => it.dashboardId)
    ),
  ];
  return setResult;
};
const findExactMatch = (cleanedQuestion, keywordData) => {
  const keysFound = [];

  for (const key in keywordData) {
    if (keywordData[key].includes(cleanedQuestion)) {
      keysFound.push(key);
    }
  }

  return keysFound;
};
const findWithSpellingErrors = (
  cleanedQuestion,
  keywordData,
  { CONV_AI_CONSTANTS }
) => {
  const keyowrdsList = getKeywords(keywordData);
  const ex_keywords = fuzzball.extract(cleanedQuestion, keyowrdsList, {
    scorer: fuzzball.partial_token_set_ratio,
  });
  const matchedKeywords = get_highest_score_and_longest_string_for_dashboards(
    ex_keywords,
    {
      // CONV_AI_CONSTANTS_THRESHOLD: 70,
      CONV_AI_CONSTANTS_THRESHOLD:
        CONV_AI_CONSTANTS.KEYWORD_MATCHING_FUZZY_THRESHOLD,
    }
  );
  const keywordsSet = new Set(matchedKeywords.map((x) => x[0]));
  let keysFound = [];

  for (const key in keywordData) {
    for (const [keyword, score] of matchedKeywords) {
      if (keywordData[key].includes(keyword)) {
        keysFound.push({
          dashboard: key,
          keyword,
          score,
        });
      }
    }
  }
  keysFound = keysFound
    .sort((a, b) => b.score - a.score)
    .map((it) => it.dashboard)
    .filter(getUniqueValues);
  return [...keysFound];
};
export function extractDashboards(
  question,
  keywordData,
  { CONV_AI_CONSTANTS }
) {
  const cleanedQuestion = removeStopWords(
    removePunctuation(question).toLowerCase()
  );

  let result = findExactMatch(cleanedQuestion, keywordData);
  if (result.length > 0) {
    return result;
  }
  result = findInAllWords(cleanedQuestion, keywordData);
  if (result.length > 0) {
    return result;
  }
  result = findWithSpellingErrors(cleanedQuestion, keywordData, {
    CONV_AI_CONSTANTS,
  });
  // if the findWithSpellingErrors returns any dashboard we return that, if not we return the empty array
  return result;
}

/**
 *
 * @param {string[]} dashboards
 * @returns
 */
export const buildRecommendationResponse = (dashboards) =>
  `The ${dashboards.join(",")} dashboard${
    dashboards.length > 1 ? "s" : ""
  } can answer this question`;
