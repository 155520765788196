import React, { useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom';

const StageProgressionComponent = (props) => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await localStorage.setItem('skygeni-user-page', 'Enhanced Insights')
      await localStorage.setItem('skygeni-user-enhancedPage', "Stage Progression Analysis")
      await localStorage.removeItem('skygeni-user-repPage')
      await localStorage.setItem('params', JSON.stringify(props.match.params.filters))
      history.push('/')
      window.location.reload()
    })();
  }, [])

  return (
    <div style={{ margin: "1rem auto" }}>Loading...</div>)
}
let routerAddedStageProgression = withRouter(StageProgressionComponent)
export default routerAddedStageProgression
