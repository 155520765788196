import { Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  message: {
    padding: theme.spacing(1),
    width: "fit-content",
    maxWidth: "85%",
    border: "0.1px solid #979797",
    // borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backdropFilter: "blur(0px)",
    filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25))",
  },
  userMessage: {
    backgroundColor: "#DAF3D8", // Light green for user messages
    alignSelf: "flex-end",
    borderRadius: "10px 0px 10px 10px",
  },
  contactMessage: {
    backgroundColor: "#FFFFFF", // White for contact messages
    alignSelf: "flex-start",
    borderRadius: "0px 10px 10px 10px",
  },
}));
const MessageContainer = ({
  text,
  botMessage,
  dashboards,
  handleLocationChange,
  isAnswered,
  dashboardChartMapping,
  dashbaordIdNavItemMap,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={`${classes.message} ${
        botMessage ? classes.contactMessage : classes.userMessage
      }`}
    >
      <Grid item>
        <Typography style={{ fontWeight: 400 }} variant="body1">
          {text}
          {botMessage && dashboards && dashboards.length > 0 && (
            <>
              {`The following dashboard${
                dashboards.length > 1 ? "s" : ""
              } can provide you more information:`}{" "}
              {dashboards.map((it) => (
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "blue",
                  }}
                  onClick={() =>
                    handleLocationChange(
                      dashboardChartMapping[it],
                      dashbaordIdNavItemMap[it]
                    )
                  }
                >
                  {"\n"}
                  {dashboardChartMapping[it]}
                </span>
              ))}
            </>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MessageContainer;
