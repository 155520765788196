import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  percentFormatter,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";

import StyledTableRow from "../../components/StyledTableRow";
import * as d3 from "d3";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
const useStyle = makeStyles((theme) => ({
  root: {},
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    width: "100%",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader1: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function DeviationTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();

  const uniqueQuarters = props.winAcvData
    .map((item) => item.quarter)
    .filter(getUniqueValues)
    .sort();
  const averageData = props.averageDevaitionCalculation;

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        <Grid container justify="center">
          <TableContainer style={{ width: "auto" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
            <Table
              ref={tableRef}
              data-test={"pipeline"}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  {/* <TableCell align='center' className={classes.repHeader1} colSpan={1} ></TableCell> */}
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 120,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                minWidth: 120,
                              }
                        }
                        className={classes.repHeader1}
                        colSpan={1}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {props.averageDevaitionCalculation !== undefined && (
                    <React.Fragment>
                      <TableCell
                        align="center"
                        className={classes.repHeader1}
                        colSpan={1}
                      >
                        Average
                      </TableCell>
                      {/* <TableCell align='center' className={classes.repHeader1} colSpan={1}  >STD.DEV.P</TableCell>
                                          <TableCell align='center' className={classes.repHeader1} colSpan={1}  >Sqrt of # of Obs.</TableCell>
                                          <TableCell align='center' className={classes.repHeader1} colSpan={1}  >Std. Error</TableCell> */}
                    </React.Fragment>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader1}
                      style={{ width: 200, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>

                <StyledTableRow>
                  {uniqueQuarters.map((cell, j) => {
                    const cellData = props.winAcvData.filter(
                      (item) => item.quarter === cell
                    )[0];
                    return (
                      <React.Fragment key={j}>
                        <TableCell colSpan={1}>
                          {cellData !== undefined
                            ? countOrACVFormatter(
                                Math.round(cellData["acv"]),
                                true
                              )
                            : "-"}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {props.averageDevaitionCalculation !== undefined && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {averageData !== undefined
                          ? countOrACVFormatter(averageData["average"], true)
                          : "-"}
                      </TableCell>
                      {/* <TableCell style={{ cursor: "pointer" }} onClick={() => props.handleChangeLocation("drilldownOnGraph", { })} align='right' className={classes.repNumber} colSpan={1}>{averageData !== undefined ? countOrACVFormatter(averageData['std'],true) : "-"}</TableCell>
                                     <TableCell style={{ cursor: "pointer" }} onClick={() => props.handleChangeLocation("drilldownOnGraph", { })} align='right' className={classes.repNumber} colSpan={1}>{averageData !== undefined ? countOrACVFormatter(averageData['sqrt'],false) : "-"}</TableCell>
                                     <TableCell style={{ cursor: "pointer" }} onClick={() => props.handleChangeLocation("drilldownOnGraph", { })} align='right' className={classes.repNumber} colSpan={1}>{averageData !== undefined ? countOrACVFormatter(averageData['stdErr'],true) : "-"}</TableCell> */}
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
