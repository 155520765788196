import React, { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";

const BuildingPipelineComponent = (props) => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await localStorage.setItem("skygeni-user-page", "Enhanced Insights");
      await localStorage.setItem(
        "skygeni-user-enhancedPage",
        "Building Pipeline to Win"
      );
      await localStorage.removeItem("skygeni-user-repPage");
      await localStorage.setItem(
        "params",
        JSON.stringify(props.match.params.filters)
      );
      history.push("/");
      window.location.reload();
    })();
  }, []);

  return <div style={{ margin: "1rem auto" }}>Loading...</div>;
};
let routerAddedBuildingPipelineComponent = withRouter(
    BuildingPipelineComponent
);
export default routerAddedBuildingPipelineComponent;
