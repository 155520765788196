import { Grid, makeStyles, Typography } from '@material-ui/core';
import { convertToThousands, getUniqueValues, groupBy, replaceHyphens, countOrACVFormatter } from "../../util/customFunctions";
import { LegendPosition } from 'ag-grid-community'
import * as d3 from 'd3';
import React from 'react'
import { updatedScheme10 } from '../../util/chartColorScheme';
const useStyle = makeStyles(theme => ({
    square: {
        height: 20, width: 20
    },
}))
export default function Legend(props) {
    let uniqueStages = props.data.map(item => item[props.type]).filter(getUniqueValues).filter(i=> i!== 'Total')
    var sortedUniquestages={}
    uniqueStages.map(item=>sortedUniquestages[item]=props.data.filter(f=>f[props.type] === item).map(i=>i.acv).reduce((a,b)=>a+b,0))
    const sortable = Object.fromEntries(
     Object.entries(sortedUniquestages).sort(([,a],[,b]) => a-b).reverse()
 );
    uniqueStages=Object.keys(sortable)
    let legends= uniqueStages.length >=1 ? [...uniqueStages,'Total'] : [...uniqueStages] 
   const color = props.colors !== undefined ? d3.scaleOrdinal(props.colors) : d3.scaleOrdinal(updatedScheme10);
    legends = legends.map(i => ({text: i, color: color(i)}))
    const totalLegend=legends.filter(i=>i.text === 'Total')
    legends=[...legends.filter(i=>i.text !== 'Total'),...totalLegend]
    const classes = useStyle()
    
    return (
        <Grid container justify= {["AsOfDate",'segment'].includes(props.type) ? "center" : "left"}>
            {legends.map((item, i) => (
                props.type==="AsOfDate" ? <Grid key={i} item xs={12/legends.length} container justify={props.just!== undefined?props.just:"center"} alignItems="center">
                <Grid item style={{ height: props.square, width: props.square, background: item.color, opacity: item.color === '#BF9000' ? 0.3 : props.legendOpacity === undefined ? 1 : props.legendOpacity }}></Grid>
                <Grid item >
                    <Typography align="center" style={{ margin: '1rem' }}>{item.text}</Typography>
                </Grid>
            </Grid> : 
            <Grid key={i} item xs={legends.length > 6 ? 4 : 2} container justify="left" alignItems="center" style={{paddingLeft:"4rem"}}>
               
            <Grid item style={{ height: props.square, width: props.square, background: item.color, opacity: item.color === '#BF9000' ? 0.3 : props.legendOpacity === undefined ? 1 : props.legendOpacity }}></Grid>
            <Grid item >
                <Typography align="center" style={{ margin: '1rem' }}>{item.text}</Typography>
            </Grid>  
        </Grid> 
                
            ))}
        </Grid>
    )
}