import Skeleton from "@material-ui/lab/Skeleton";
import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import Drilldown from "../../components/Drilldown/Drilldown";
import { NumberFormatCustom } from "../../components/CustomNumberFormat";
import {
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
  getDefaultSelectedStageV2,
  getStageNames,
} from "../../util/customFunctions";
import AttchRateTable from "./AttachRateTable";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  tableClickable: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
    cursor: "pointer",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});

class AttchRate extends Component {
  state = {
    location: null,
    closed_fiscal_quarter: ["All"],
    disabled: ["year", "month", "quarter"],
    earliestOpenStage: "",
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Segment: ["All"],
    filtersForDrilldownPcfq: ["All"],
    filtersForDrilldown: [],
    rep: ["All"],
    repTeam: "rep",
    team: ["All"],
    Acct_Vertical: ["All"],
    Solution_Cat: ["All"],
    Acct_Segment: ["All"],
    Cust_Type: ["All"],
    Acct_Industry: ["All"],
    Acct_Size_Segment: ["All"],
    acv: "",
    acvRadio: "preset",
    acvError: "",
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    tableType: "",
  };
  componentDidMount = () => {
    this.props.getAttchRateFilters();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const stageFilters = this.props.funnelCompareFilters.filter(
        (f) => f.type === "stage_name"
      );
      const stageSequence = getDefaultSelectedStage(stageFilters);

      let stage_names = getStageNames(stageFilters, stageSequence);
      const default_fiscal_quarters = getDefaultSelectedFiscalQuarters(
        this.props.funnelCompareFilters,
        "closed_fiscal_quarter"
      );
      this.setState({
        ...prevState,
        closed_fiscal_quarter: default_fiscal_quarters,
        filtersForDrilldownPcfq: default_fiscal_quarters,
        earliestOpenStage: stageSequence,
        filtersForDrilldown: [
          { title: "Closed Fiscal Quarter", value: default_fiscal_quarters },
          { title: "Earliest Lost from Stage", value: stage_names[0] },
        ],
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Region_Rollup",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Booking_Type_NL_CS_US",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Acct_Vertical",
          projectedQuarter: "closed_fiscal_quarter",
          Lead_Source: "Lead_Source",
          Opp_Created_By: "Opp_Created_By",
          acv_filter: "acv",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Solution_Cat",
          projectedQuarter: "closed_fiscal_quarter",
          acv_filter: "acv",
          Lead_Source: "Lead_Source",
          Opp_Created_By: "Opp_Created_By",
        };

  orderRepsByLastName = () => {
    let temp = this.props.funnelCompareFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[1]} ${res[0]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleChangeLocation = (location, obj, key, tableType) => {
    if (location !== "overview") {
      const rowName = obj.oppType;
      var filters = this.props.currentFilters;
      filters = [
        ...filters,
        { name: "closed_fiscal_quarter", value: obj.quarter },
      ];
      var drilldownFilter = [
        { title: "Closed Fiscal Quarter", value: obj.quarter },
      ];
      drilldownFilter = [
        ...drilldownFilter,
        ...this.state.filtersForDrilldown.filter(
          (s) => s.title !== "Closed Fiscal Quarter"
        ),
      ];
      this.props.getAttchRateDrilldown(filters, key, rowName, tableType);
      this.setState({
        ...this.state,
        location,
        header: tableType === "radar" ? "RADAR and Accelerators" : "Connectors",
        filtersForDrilldown: drilldownFilter,
        tableType,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
    });
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };
  stepBack = () => {
    this.setState({
      ...this.state,
      location: "drilldownOppId",
    });
    this.props.clearExpectedAcvData();
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    var filters1 = this.props.lkpFilters.filter(
      (item) => item !== "sequence" && item !== "acv"
    );
    let filters = filters1
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_names = getStageNames(
      this.props.funnelCompareFilters.filter((f) => f.type === "stage_name"),
      this.state.earliestOpenStage
    );

    filters = [
      ...filters,
      {
        name: "final_stage_for_closed",
        value: stage_names,
        string: true,
      },
    ];

    if (![null, undefined, ""].includes(this.state.acv)) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }
    if (this.state.closed_fiscal_quarter.includes("All")) {
      filters = [
        {
          name: "closed_fiscal_quarter",
          value: this.props.funnelCompareFilters
            .filter((i) => i.type === "closed_fiscal_quarter")
            .map((i) => i.closed_fiscal_quarter),
          string: true,
        },
        ...filters,
      ];
    }

    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      filtersForDrilldownPcfq: this.state.closed_fiscal_quarter,
    });
    this.props.getAttachRateData(filters);
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Fiscal Quarter",
          value: this.state.closed_fiscal_quarter,
        },
      ];
    }
    let stage_names = [];
    this.props.funnelCompareFilters.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence >= this.state.earliestOpenStage)
          stage_names = [...stage_names, i.stage_name];
      }
    });
    filtersArray = [
      ...filtersArray,
      { title: "Earliest Lost From Stage", value: stage_names[0] },
    ];
    if (!this.state.rep.includes("All")) {
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];
    }
    if (!this.state.team.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];
    }
    if (![null, undefined, ""].includes(this.state.acv)) {
      filtersArray = [
        ...filtersArray,
        { title: "ACV >=", value: this.state.acv },
      ];
    }

    if (!this.state.Cust_Type.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Customer Type", value: this.state.Cust_Type },
      ];
    }
    if (!this.state.Acct_Industry.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Industry", value: this.state.Acct_Industry },
      ];
    }
    if (!this.state.Acct_Size_Segment.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Customer segment", value: this.state.Acct_Size_Segment },
      ];
    }
    if (!this.state.Lead_Source.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Lead Source", value: this.state.Lead_Source },
      ];
    }
    if (!this.state.Opp_Created_By.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];
    }
    if (!this.state.Solution_Cat.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];
    }
    if (!this.state.Acct_Vertical.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Account Vertical", value: this.state.Acct_Vertical },
      ];
    }

    return filtersArray;
  };

  render() {
    const classes = this.props;
    return (
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} style={{ marginTop: "10px" }}>
          {addHeaderdescription(
            "Attach rate for Product Add-ons",
            "Examine attach rates for various Add-on products for both won and lost opportunities."
          )}
        </Grid>
        <Grid
          item
          container
          className={classes.grid}
          style={{ marginBottom: 10, marginLeft: 25 }}
          xs={12}
        >
          {/* <Grid item xs={12} justify="center">
            <Typography variant="h2" align="center">
              Attach rate for Product Add-ons
            </Typography>
          </Grid>
          <Grid item xs={12} justify="left" style={{ margintop: 10 }}>
            <Typography variant="body1">
              Examine attach rates for various Add-on products for both won and
              lost opportunities.
            </Typography>
          </Grid> */}
          {this.props.funnelCompareFilters !== "" ? (
            <Grid item container xs={12}>
              <Grid container item style={{ padding: 10 }} xs={4} md={4}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  justify="space-around"
                >
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.projectedQuarter
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-fiscalQuarter-label"
                      >
                        Closed Fiscal Quarter:
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.projectedQuarter]}
                        onChange={this.handleChange}
                        name={"closed_fiscal_quarter"}
                        data-test="closed-fiscal-quarter"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters.map((y) =>
                            y.type === this.variableCols.projectedQuarter ? (
                              <MenuItem
                                key={y[this.variableCols.projectedQuarter]}
                                value={y[this.variableCols.projectedQuarter]}
                              >
                                {y[this.variableCols.projectedQuarter]}
                              </MenuItem>
                            ) : null
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} style={{ paddingTop: 10 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-stage-label"
                        style={{ color: "#4472c4" }}
                      >
                        Earliest Lost from Stage
                      </InputLabel>
                      <Select
                        labelId="select-stage-label"
                        id="select-stage"
                        value={this.state.earliestOpenStage}
                        onChange={this.handleChangeEarliestOpenStage}
                        label="Earliest Open Stage:"
                        name="earliestOpenStage"
                        data-test="earliestOpenStage"
                      >
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters.map(
                            (y) =>
                              y.stage_name && (
                                <MenuItem key={y.sequence} value={y.sequence}>
                                  {y.stage_name}
                                </MenuItem>
                              )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item style={{ padding: 10 }} xs={4}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Autocomplete
                      data-test="rep"
                      id="combo-box-demo"
                      options={
                        this.props.funnelCompareFilters !== "" &&
                        this.orderRepsByLastName()
                      }
                      getOptionLabel={(option) => option}
                      value={this.state.rep[0]}
                      onChange={this.onRepChange}
                      name="rep"
                      disabled={!this.state.team.includes("All")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            style: {
                              color: !this.state.rep.includes("All")
                                ? "#4472c4"
                                : "#000",
                            },
                          }}
                          label="Rep:"
                          variant="standard"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={!this.state.rep.includes("All")}
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.team.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-team-label"
                      >
                        Team:
                      </InputLabel>
                      <Select
                        data-test="team"
                        value={this.state.team}
                        onChange={this.handleChange}
                        name="team"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) => y.type === "team" && y.Display_Name !== ""
                            )
                            .sort((a, b) =>
                              a.Display_Name < b.Display_Name
                                ? -1
                                : a.Display_Name > b.Display_Name
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Display_Name}
                                value={y.Display_Name}
                              >
                                {y.Display_Name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                justify="space-between"
                xs={4}
                lg={3}
              >
                <Grid
                  item
                  spacing={2}
                  xs={8}
                  xl={9}
                  container
                  component={Paper}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ paddingTop: 0, height: "0px" }}
                  >
                    <Autocomplete
                      id="free-solo-demo"
                      data-test="acv"
                      freeSolo
                      options={
                        this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.acv_filter &&
                              y[this.variableCols.acv_filter] !== ""
                          )
                          .map((y) => y[this.variableCols.acv_filter])[0]
                      }
                      onChange={this.onAcvChange}
                      value={this.state.acv}
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ACV >= :"
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4 }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.acv !== "" && this.state.acv !== null
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4} xl={3} container justify="flex-end">
                  <Button
                    data-test="go"
                    variant="contained"
                    color="primary"
                    onClick={this.handleGo}
                  >
                    GO
                  </Button>
                </Grid>
              </Grid>

              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={6}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.CustomerType
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        {this.props.company === "C0003"
                          ? "Opportunity Type:"
                          : "Customer Type:"}
                      </InputLabel>
                      <Select
                        data-test="Customer Type"
                        value={this.state[this.variableCols.CustomerType]}
                        onChange={this.handleChange}
                        name={this.variableCols.CustomerType}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.CustomerType &&
                                y[this.variableCols.CustomerType] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.CustomerType] <
                              b[this.variableCols.CustomerType]
                                ? -1
                                : a[this.variableCols.CustomerType] >
                                  b[this.variableCols.CustomerType]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.CustomerType]}
                                value={y[this.variableCols.CustomerType]}
                              >
                                {y[this.variableCols.CustomerType]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Industry
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        {this.props.company === "C0003"
                          ? "Region:"
                          : "Industry:"}
                      </InputLabel>
                      <Select
                        data-test="Industry"
                        value={this.state[this.variableCols.Industry]}
                        onChange={this.handleChange}
                        name={this.variableCols.Industry}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Industry &&
                                y[this.variableCols.Industry] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.Industry] <
                              b[this.variableCols.Industry]
                                ? -1
                                : a[this.variableCols.Industry] >
                                  b[this.variableCols.Industry]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Industry]}
                                value={y[this.variableCols.Industry]}
                              >
                                {y[this.variableCols.Industry]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.CustomerSegment
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        {this.props.company === "C0003"
                          ? "Segment:"
                          : "Customer Segment:"}
                      </InputLabel>
                      <Select
                        data-test="Customer Segment"
                        value={this.state[this.variableCols.CustomerSegment]}
                        onChange={this.handleChange}
                        name={this.variableCols.CustomerSegment}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.CustomerSegment &&
                                y[this.variableCols.CustomerSegment] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.CustomerSegment]}
                                value={y[this.variableCols.CustomerSegment]}
                              >
                                {y[this.variableCols.CustomerSegment]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Acct_Vertical
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        {this.props.company === "C0003"
                          ? "Vertical:"
                          : "Solution Category:"}
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.Acct_Vertical]}
                        onChange={this.handleChange}
                        name={this.variableCols.Acct_Vertical}
                        data-test={this.variableCols.Acct_Vertical}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Acct_Vertical &&
                                y[this.variableCols.Acct_Vertical] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.Acct_Vertical] <
                              b[this.variableCols.Acct_Vertical]
                                ? -1
                                : a[this.variableCols.Acct_Vertical] >
                                  b[this.variableCols.Acct_Vertical]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Acct_Vertical]}
                                value={y[this.variableCols.Acct_Vertical]}
                              >
                                {y[this.variableCols.Acct_Vertical]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              container
              component={Card}
              style={{ margin: "1rem" }}
              justify="center"
            >
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            className={classes.grid}
            container
            justify="center"
            style={{ marginTop: "1rem" }}
          >
            <Grid
              item
              xs={12}
              style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
            >
              <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                {this.props.funnelData !== "" ? (
                  <Grid item container xs={12} justify="left">
                    <Grid item xs={12}>
                      <Typography
                        variant="h2"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        RADAR and Accelerators
                      </Typography>
                    </Grid>
                    <AttchRateTable
                      tableType={"radar"}
                      tableData={this.props.funnelData.radarAcceleratorData}
                      handleChangeLocation={this.handleChangeLocation}
                    />
                  </Grid>
                ) : (
                  <Grid item container justify="center">
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.grid}
            container
            justify="center"
            style={{ marginTop: "1rem" }}
          >
            <Grid
              item
              xs={12}
              style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
            >
              <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                {this.props.funnelData !== "" ? (
                  <Grid item container xs={12} justify="left">
                    <Grid item xs={12}>
                      <Typography
                        variant="h2"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        Connectors
                      </Typography>
                    </Grid>
                    <AttchRateTable
                      tableType={"connectors"}
                      tableData={this.props.funnelData.connectorData}
                      handleChangeLocation={this.handleChangeLocation}
                    />
                  </Grid>
                ) : (
                  <Grid item container justify="center">
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.header}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                body={this.props.drilldownOnGraphData}
                showCoonectorValue={
                  this.state.tableType === "connectors" ? true : false
                }
                handleDrilldown={this.handleDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  var {
    funnelData,
    OpportunityPyramidFilters,
    lkpFilters,
    drilldownOnGraphData,
    currentFilters,
    user,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelData,
    funnelCompareFilters: OpportunityPyramidFilters,
    lkpFilters,
    drilldownOnGraphData,
    currentFilters,
    company: user.company,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAttchRateFilters: () => {
    dispatch({ type: "get_OpportunityPyramid_filters_request" });
    appService.getAttachRateFilters().then(
      (json) => {
        var lkpFilters = Object.values(
          json.message.lkpFilters.reduce((r, c) => Object.assign(r, c), {})
        );
        json.message = json.message.computedFilters;
        json.lkpFilters = lkpFilters;
        dispatch({ type: "get_OpportunityPyramid_filters_success", json });
        dispatch({ type: "get_funnel_data_request" });
        const stageSequence = getDefaultSelectedStage(
          json.message.filter((f) => f.type === "stage_name")
        );
        const stageFilters = json.message.filter(
          (f) => f.type === "stage_name"
        );

        const defaultStages = getStageNames(stageFilters, stageSequence);
        const filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
          {
            name: "final_stage_for_closed",
            string: true,
            value: defaultStages,
          },
        ];
        dispatch({ type: "current_selected_filters", filters });
        appService.getAttachRateData(filters).then(
          (json) => {
            dispatch({ type: "get_funnel_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_funnel_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_funnel_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_OpportunityPyramid_filters_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_OpportunityPyramid_filters_failure", error });
      }
    );
  },
  getAttchRateDrilldown: (filters, key, rowName, tableType) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getAttchRateDrilldown(filters, key, rowName, tableType).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1];
        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });
        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getAttachRateData: (filters) => {
    dispatch({ type: "get_funnel_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getAttachRateData(filters).then(
      (json) => {
        dispatch({ type: "get_funnel_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_data_failure", error });
      }
    );
  },
});
const connectedAttchRate = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AttchRate));
export default connectedAttchRate;
