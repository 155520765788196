import {
    CardContent,
    Collapse,
    Grid,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    withStyles,
  } from "@material-ui/core";
  import Tooltip from "@material-ui/core/Tooltip";
  import CloseIcon from "@material-ui/icons/Close";
  
  import PerfectScrollbar from "react-perfect-scrollbar";
  import React, { useEffect, useState } from "react";
  import {
    getUniqueValues,
    _isNaN,
    countOrACVFormatter,
    percentFormatter,
    countOrACVFormatterRoundToNearestN,
    countOrACVFormatterMillionNew,
    countOrACVFormatterMillion,
    countOrACVFormatterMillionFixedOne,
    roundToNearestN,
  } from "../../util/customFunctions";
  import { getMixKey } from "../../util/generateHeadings";
  
  import StyledTableRow from "../../components/StyledTableRow";
  import * as d3 from "d3";
  import { deSnake } from "../../util/dataFormatter";
  import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
  import GrowthModelByMonthDescriptionTable from "./GrowthModelByMonthDescription";
  import { Alert } from "@mui/material";
  const useStyle = makeStyles((theme) => ({
    root: {},
    content: { width: "100%" },
    table: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      width: "100%",
    },
    segmentHeader: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
    },
    repHeader: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
    },
    repHeader1: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "10px",
      color: "inherit",
    },
    repText: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      textAlign: "left",
      minWidth: "169px",
    },
    repNumber: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      textAlign: "right",
    },
    fontBold: {
      fontWeight: 600,
    },
    tableColWidth: {
      minWidth: 90,
    },
    drilldownCell: {
      cursor: "pointer",
    },
  }));
  export default function GrowthModelByTable(props) {
    const classes = useStyle();
    const tableRef = React.createRef();
  
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "rgb(255,255,255,0.8)",
        color: "black",
        fontFamily: "roboto",
        fontSize: theme.typography.pxToRem(14),
        borderRadius: "0.5rem",
        padding: "0px",
        boxShadow: "0 0.1rem 0.1rem 0",
        marginTop: "0.1rem",
      },
    }))(Tooltip);
    const [errorMessage, setErrorMessage] = useState('')
    const [open, setOpen] = useState(false)
  
    const valueA = [null, undefined, ""].includes(
      props.data.filter(
        (f) =>
          f.Stage_at_time_T ===
          "A: Won from Opps qualified or later at start of month with PCD in month"
      )[0].perc
    )
      ? 0
      : (props.data.filter(
          (f) =>
            f.Stage_at_time_T ===
            "A: Won from Opps qualified or later at start of month with PCD in month"
        )[0].perc) *
        100;
    const valueB = [null, undefined, ""].includes(
      props.data.filter(
        (f) =>
          f.Stage_at_time_T ===
          "B: Qualified and Won in the same month and NOT Qualified or later at start of month"
      )[0].perc
    )
      ? 0
      : props.data.filter(
          (f) =>
            f.Stage_at_time_T ===
            "B: Qualified and Won in the same month and NOT Qualified or later at start of month"
        )[0].perc * 100;
  
        const valueC = [null, undefined, ""].includes(
            props.data.filter(
              (f) =>
                f.Stage_at_time_T ===
                "C: Won from Opps qualified or later at start of month with PCD NOT in month (Pulled in)"
            )[0].perc
        )
          ? 0
          : (
              props.data.filter(
                (f) =>
                  f.Stage_at_time_T ===
                  "C: Won from Opps qualified or later at start of month with PCD NOT in month (Pulled in)"
              )[0].perc) *
            100;
    const valueConA = ![null, undefined, ""].includes(
      props.dataA[0].convPerc 
    )
      ? (props.dataA[0].convPerc) * 100
      : 0;
    const valueConB = ![null, undefined, ""].includes(props.dataB[0].convPerc)
      ? props.dataB[0].convPerc * 100
      : 0;
      const valueConC = ![null, undefined, ""].includes(
        props.dataC[0].convPerc
      )
        ? (props.dataC[0].convPerc) * 100
        : 0;
  
    const [editA, setEditA] = useState(parseFloat(valueA.toFixed(1)));
    const [targetA, setTargetA] = useState(parseFloat(valueA.toFixed(1)));
    const [editB, setEditB] = useState(parseFloat(valueB.toFixed(1)));
    const [targetB, setTargetB] = useState(parseFloat(valueB.toFixed(1)));
    const [editC, setEditC] = useState(parseFloat(valueC.toFixed(1)));
    const [targetC, setTargetC] = useState(parseFloat(valueC.toFixed(1)));
    const [toggleA, setToggleA] = useState(true);
    const [toggleB, setToggleB] = useState(true);
    const [toggleC, setToggleC] = useState(true);
  
    const [editConvA, setEditConvA] = useState(parseFloat(valueConA.toFixed(1)));
    const [targetConvA, setTargetConvA] = useState(
      parseFloat(valueConA.toFixed(1))
    );
    const [toggleConvA, setToggleConvA] = useState(true);
    const [isOverflowing, setIsOverflowing] = useState(false);
  
    const [editConvB, setEditConvB] = useState(parseFloat(valueConB.toFixed(1)));
    const [targetConvB, setTargetConvB] = useState(
      parseFloat(valueConB.toFixed(1))
    );
    const [toggleConvB, setToggleConvB] = useState(true);
  
    const [editConvC, setEditConvC] = useState(parseFloat(valueConC.toFixed(1)));
    const [targetConvC, setTargetConvC] = useState(
      parseFloat(valueConC.toFixed(1))
    );
    const [toggleConvC, setToggleConvC] = useState(true);
    const [valueToA, setValueToA] = useState(0)
    const [valueToB, setValueToB] = useState(0)
    const [valueToC, setValueToC] = useState(0)
  
  
  
    const targetVal = ![null, undefined, ""].includes(props.targetValue)
      ? parseFloat(props.targetValue)
      : 0;
  
    const handleChangeA = (e) => {
      console.log(e.target.value);
      // const parts = e.target.vale.split('.')
      // const val = e.target.value
      // if(parts.length >=2){
      //   val = val.slice(0, val.lastIndexOf('.'))
      // }
      setEditA(e.target.value);
    };
  
    const toggleInputA = () => {
      setToggleA(false);
    };
  
    // const onSaveA = (target) => {
    //   // console.log(target)
    //   let val = parseFloat(target) > 100 ? 100 : parseFloat(target);
    //   setEditA(parseFloat(val.toFixed(1)));
    //   setTargetB(parseFloat((100 - val - valueC).toFixed(1)));
    //   setTargetC(parseFloat((100 - val - valueB).toFixed(1)));
    //   setTargetA(parseFloat(val.toFixed(1)));
    //   setEditB(parseFloat((100 - val - valueC).toFixed(1)));
    //   setEditC(parseFloat((100 - val - valueB).toFixed(1)));
    //   setToggleA(true);
    // };
  
    const onSaveA = (target) => {
      console.log(target, 'Target')
      let val = parseFloat(target);
      if(val < 0 || isNaN(val)){
        val = 0
      }
  
      const maxSum = 100;
      const currentValueC = parseFloat(editC.toFixed(1)); // assuming valueC is a state or prop value 
      const maxValueA = maxSum - currentValueC; // maximum possible value for A
  
      if(val > maxValueA){
        setErrorMessage(`A+B cannot exceed ${maxValueA}%. Please change C first if you want A+B to be > ${maxValueA}%`)
        setOpen(true)
      }else{
        setOpen(false)
        setErrorMessage('')
      }
  
      // Determine the new value for A, ensuring it does not exceed maxValueA
      const cappedValueA = Math.min(val, maxValueA);
  
      // Calculate the new value of B
      const cappedValueB = (cappedValueA === maxValueA) ? 0 : (maxSum - currentValueC - cappedValueA);
  
      // Update the states for A and B
      setEditA(parseFloat(cappedValueA.toFixed(1)));
      setTargetA(parseFloat(cappedValueA.toFixed(1)));
      setEditB(parseFloat(cappedValueB.toFixed(1)));
      setTargetB(parseFloat(cappedValueB.toFixed(1)));
  
      // Calculate and send data for A, B, and C
      const vA = Math.round(
          (parseFloat(cappedValueA.toFixed(1)) / 100) * targetVal * (1 / (editConvA / 100))
      );
      props.onDataA(roundToNearestN(vA, 10000));
  
      const vB = Math.round(
          (cappedValueB / 100) * targetVal * (1 / (editConvB / 100))
      );
      props.onDataB(roundToNearestN(vB, 10000));
  
      const vC = Math.round(
          (currentValueC / 100) * targetVal * (1 / (editConvC / 100))
      );
      props.onDataC(roundToNearestN(vC, 10000));
  
      setToggleA(true);
  };
  
  
    const handleChangeB = (e) => {
      setEditB(e.target.value);
    };
  
    const toggleInputB = () => {
      setToggleB(false);
    };
  
    const onSaveB = (target) => {
      let val = parseFloat(target);
      if(val < 0 || isNaN(val)){
        val = 0
      }
  
      const maxSum = 100;
      const currentValueC = parseFloat(editC.toFixed(1)); // assuming valueC is a state or prop value
      const maxValueB = maxSum - currentValueC; // maximum possible value for A
  
      if(val > maxValueB){
        setErrorMessage(`A+B cannot exceed ${maxValueB}%. Please change C first if you want A+B to be > ${maxValueB}%`)
        setOpen(true)
      }else{
        setOpen(false)
        setErrorMessage('')
      }
  
      // Determine the new value for A, ensuring it does not exceed maxValueA
      const cappedValueB = Math.min(val, maxValueB);
  
      // Calculate the new value of B
      const cappedValueA = (cappedValueB === maxValueB) ? 0 : (maxSum - currentValueC - cappedValueB);
  
      // Update the states for A and B
      setEditA(parseFloat(cappedValueA.toFixed(1)));
      setTargetA(parseFloat(cappedValueA.toFixed(1)));
      setEditB(parseFloat(cappedValueB.toFixed(1)));
      setTargetB(parseFloat(cappedValueB.toFixed(1)));
  
      // Calculate and send data for A, B, and C
      const vA = Math.round(
          (parseFloat(cappedValueA.toFixed(1)) / 100) * targetVal * (1 / (editConvA / 100))
      );
      props.onDataA(roundToNearestN(vA, 10000));
  
      const vB = Math.round(
          (cappedValueB / 100) * targetVal * (1 / (editConvB / 100))
      );
      props.onDataB(roundToNearestN(vB, 10000));
  
      const vC = Math.round(
          (currentValueC / 100) * targetVal * (1 / (editConvC / 100))
      );
      props.onDataC(roundToNearestN(vC, 10000));
  
      setToggleB(true);
  };
  
  
  
    const handleChangeC = (e) => {
      console.log(e.target.value);
      // const parts = e.target.vale.split('.')
      // const val = e.target.value
      // if(parts.length >=2){
      //   val = val.slice(0, val.lastIndexOf('.'))
      // }
      setEditC(e.target.value);
    };
  
    const toggleInputC = () => {
      setToggleC(false);
    };
  
    const onSaveC = (target) => {
      let val = parseFloat(target);
      if(val < 0 || isNaN(val)){
        val = 0
      }
  
       setOpen(false)
        setErrorMessage('')
  
      const maxSum = 100;
      let currentValueB = parseFloat(editB.toFixed(1)); // assuming valueB is a state or prop value 
    //  console.log(valueB, editB, 'EDIITTT')
      const maxValueC = val >= 100 ? 100 : maxSum - currentValueB; // maximum possible value for C
  
      // Determine the new value for A, ensuring it does not exceed maxValueA
      const cappedValueC = Math.min(val, maxValueC);
  
      // Calculate the new value of B
      let cappedValueA = (cappedValueC === maxValueC) ? 0 : (maxSum - currentValueB - cappedValueC);
      if(val >= 100){
        cappedValueA = 0
        currentValueB = 0
        setEditB(parseFloat(currentValueB.toFixed(1)))
        setTargetB(parseFloat(currentValueB.toFixed(1)))
      }
  
      // Update the states for A and B
      setEditA(parseFloat(cappedValueA.toFixed(1)));
      setTargetA(parseFloat(cappedValueA.toFixed(1)));
      setEditC(parseFloat(cappedValueC.toFixed(1)));
      setTargetC(parseFloat(cappedValueC.toFixed(1)));
  
      // Calculate and send data for A, B, and C
      const vA = Math.round(
          (parseFloat(cappedValueA.toFixed(1)) / 100) * targetVal * (1 / (editConvA / 100))
      );
      props.onDataA(roundToNearestN(vA, 10000));
  
      const vB = Math.round(
          (currentValueB / 100) * targetVal * (1 / (editConvB / 100))
      );
      props.onDataB(roundToNearestN(vB, 10000));
  
      const vC = Math.round(
          (cappedValueC / 100) * targetVal * (1 / (editConvC / 100))
      );
      props.onDataC(roundToNearestN(vC, 10000));
  
      setToggleC(true);
  };
  
  
    const handleChangeConvA = (e) => {
      if(e.target.value === ''){
        setEditConvA(null)
      }else{
        setEditConvA(e.target.value);
      }
    };
  
    const toggleInputConvA = () => {
      setToggleConvA(false);
    };
  
    const onSaveConvA = (target) => {
      let val =
        parseFloat(target) > 100
          ? 100
          : parseFloat(parseFloat(target).toFixed(1));
          if(val < 0 || isNaN(val)){
            val = 0
          } 
      setEditConvA(val);
      setTargetConvA(val);
  
      
  
      const vA = Math.round(
        val === 0 ? 0 : (editA / 100) * targetVal * (1 / (val / 100))
      )
      props.onDataA(roundToNearestN(vA, 10000))
  
      const vB = Math.round(
        editConvB === 0 ? 0 : (editB / 100) * targetVal * (1 / (editConvB / 100))
      )
      props.onDataB(roundToNearestN(vB, 10000))
  
      const vC = Math.round(
        editConvC === 0 ? 0 : (editC / 100) * targetVal * (1 / (editConvC / 100))
      )
      props.onDataC(roundToNearestN(vC, 10000))
      setToggleConvA(true);
    };
  
    const handleChangeConvB = (e) => {
      if(e.target.value === ''){
        setEditConvB(null)
      }else{
        setEditConvB(e.target.value);
      }
    }   
  
    const toggleInputConvB = () => {
      setToggleConvB(false);
    };
  
    const onSaveConvB = (target) => {
      let val =
        parseFloat(target) > 100
          ? 100
          : parseFloat(parseFloat(target).toFixed(1));
          if(val < 0 || isNaN(val)){
            val = 0
          }
      setEditConvB(val);
      setTargetConvB(val);
      const vA = Math.round(
        editConvA === 0 ? 0 : (editA / 100) * targetVal * (1 / (editConvA / 100))
      )
      props.onDataA(roundToNearestN(vA, 10000))
  
      const vB = Math.round(
        val === 0 ? 0 : (editB / 100) * targetVal * (1 / (val / 100))
      )
      props.onDataB(roundToNearestN(vB, 10000))
  
      const vC = Math.round(
        editConvC === 0 ? 0 : (editC / 100) * targetVal * (1 / (editConvC / 100))
      )
      props.onDataC(roundToNearestN(vC, 10000))
      setToggleConvB(true);
    };
  
    const handleChangeConvC = (e) => {
      if(e.target.value === ''){
        setEditConvC(null)
      }else{
        setEditConvC(e.target.value);
      }
    };
  
    const toggleInputConvC = () => {
      setToggleConvC(false);
    };
  
    const onSaveConvC = (target) => {
      let val =
        parseFloat(target) > 100
          ? 100
          : parseFloat(parseFloat(target).toFixed(1));
          if(val < 0 || isNaN(val)){
            val = 0
          }
      setEditConvC(val);
      setTargetConvC(val);
      const vA = Math.round(
       editConvA === 0 ? 0 : (editA / 100) * targetVal * (1 / (editConvA / 100))
      )
      props.onDataA(roundToNearestN(vA, 10000))
  
      const vB = Math.round(
        editConvB === 0 ? 0 : (editB / 100) * targetVal * (1 / (editConvB / 100))
      )
      props.onDataB(roundToNearestN(vB, 10000))
  
      const vC = Math.round(
        val === 0 ? 0 : (editC / 100) * targetVal * (1 / (val / 100))
      )
      props.onDataC(roundToNearestN(vC, 10000))
      setToggleConvC(true);
    };
  
    // console.log(editB, valueB);
    useEffect(() => {
      const tableContainer = document.getElementById("myTableContainer");
  
      const handleOverflowCheck = () => {
        if (tableContainer) {
          setIsOverflowing(
            tableContainer.scrollWidth > tableContainer.offsetWidth
          );
        }
      };
  
      // Check for overflow initially and whenever the component updates
      handleOverflowCheck();
  
      // Attach a resize event listener to check for overflow when the window is resized
      window.addEventListener("resize", handleOverflowCheck);
  
      return () => {
        // Clean up the event listener when the component unmounts
        window.removeEventListener("resize", handleOverflowCheck);
      };
    }, []);
  
    useEffect(() => {
      const vA = Math.round(
        editConvA === 0 ? 0 : (editA / 100) * targetVal * (1 / (editConvA / 100))
      )
      props.onDataA(roundToNearestN(vA, 10000))
  
      const vB = Math.round(
        editConvB === 0 ? 0 :  (editB / 100) * targetVal * (1 / (editConvB / 100))
      )
      props.onDataB(roundToNearestN(vB, 10000))
  
      const vC = Math.round(
        editConvC === 0 ? 0 : (editC / 100) * targetVal * (1 / (editConvC / 100))
      )
      props.onDataC(roundToNearestN(vC, 10000))
  
    }, [targetVal]);
  
    return (
      <div
        className={classes.root}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <CardContent className={classes.content} style={{ padding: 10 }}>
          {isOverflowing ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null}
          <Grid container justify="center">
            <TableContainer className={classes.mainTable} id="myTableContainer">
              
                {errorMessage !== "" && (
                  <Collapse in={open}>
                    <Alert
                      severity="error"
                      color="error"
                      variant="filled"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpen(false)
                            setErrorMessage('')
                            // this.setState({
                            //   ...this.state,
                            //   open: false,
                            //   errorMessage: "",
                            // });
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <Typography variant="body1">
                        {errorMessage}
                      </Typography>
                    </Alert>
                  </Collapse>
                )}
              
              {isOverflowing === false ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                    // marginRight: "10px",
                  }}
                >
                  <CopyTablesObject tableRef={tableRef} />
                </div>
              ) : null}
              <Table
                ref={tableRef}
                data-test={"pipeline"}
                size="small"
                aria-label="html table"
                style={{ tableLayout: "auto", width: "initial" }}
              >
                <TableHead></TableHead>
                <TableBody>
                  <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                    <TableCell
                      align="center"
                      style={{ width: "30rem" }}
                      className={classes.repHeader1}
                      colSpan={1}
                      rowSpan={2}
                    >
                      Source of Qualified Pipeline
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={2}
                      rowspan={1}
                      style={{ background: "#4471c4", color: "white" }}
                    >
                      Mix
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                      rowSpan={2}
                      style={{
                        background: "#5b9bd5",
                        color: "white",
                        width: "10rem",
                      }}
                    >
                      Contribution by source
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={2}
                      rowSpan={1}
                      style={{ background: "#4471c4", color: "white" }}
                    >
                      Conversion Efficiency
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                      rowSpan={2}
                      style={{
                        background: "#5b9bd5",
                        color: "white",
                        width: "10rem",
                      }}
                    >
                      Qual. Pipeline multiple required
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                      rowSpan={2}
                      style={{
                        background: "#4471c4",
                        color: "white",
                        width: "10rem",
                      }}
                    >
                      Qual. Pipeline required
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                      rowspan={1}
                      style={{ width: "7rem" }}
                    >
                      Historical
                    </TableCell>
                    <HtmlTooltip
                                                              title={
                                                                  <div
                                                                  style={{
                                                                    borderTop: "solid #fee599ff",
                                                                    borderRadius: "0.5rem",
                                                                    padding: "0px",
                                                                    margin: 0,
                                                                    borderWidth: "0.25rem",
                                                                  }}
                                                                >
                                                                  <div className="container">
                                                                    <Typography
                                                                      style={{ padding: "0.1rem" }}
                                                                      className="heading-style"
                                                                      align='left'
                                                                    >{`Double-click on the cell you'd like to edit`}</Typography>
                                                                  </div>
                                                                </div>
                                                              }
                                                      >
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                      rowSpan={1}
                      style={{ width: "7rem", border: "2px solid black" }}
                    >
                      Assumed{" "}
                      <span
                        style={{
                          background: "#fee599ff",
                          padding: "0rem 0.4rem",
                          margin: "0rem 0.5rem",
                          border: "1px solid lightgrey",
                          color: "black",
                        }}
                      >
                        Edit
                      </span>
                    </TableCell>
                    </HtmlTooltip>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                      rowspan={1}
                      style={{ width: "7rem" }}
                    >
                      Historical
                    </TableCell>
                    <HtmlTooltip
                                                              title={
                                                                  <div
                                                                  style={{
                                                                    borderTop: "solid #fee599ff",
                                                                    borderRadius: "0.5rem",
                                                                    padding: "0px",
                                                                    margin: 0,
                                                                    borderWidth: "0.25rem",
                                                                  }}
                                                                >
                                                                  <div className="container">
                                                                    <Typography
                                                                      style={{ padding: "0.1rem" }}
                                                                      className="heading-style"
                                                                      align='left'
                                                                    >{`Double-click on the cell you'd like to edit`}</Typography>
                                                                  </div>
                                                                </div>
                                                              }
                                                      >
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                      rowSpan={1}
                      style={{ width: "7rem", border: "2px solid black" }}
                    >
                      Assumed{" "}
                      <span
                        style={{
                          background: "#fee599ff",
                          padding: "0rem 0.4rem",
                          margin: "0rem 0.5rem",
                          border: "1px solid lightgrey",
                          color: "black",
                        }}
                      >
                        Edit
                      </span>
                    </TableCell>
                    </HtmlTooltip>
                  </TableRow>
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      <span>Opps qualified or later at start of month</span> <br />{" "}
                      <span>(A from Conversion Efficiency)</span>
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      {[null, undefined, ""].includes(
                        props.data.filter(
                          (f) =>
                            f.Stage_at_time_T ===
                            "A: Won from Opps qualified or later at start of month with PCD in month"
                        )[0].perc
                      )
                        ? "0%"
                        : `${(
                            (props.data.filter(
                              (f) =>
                                f.Stage_at_time_T ===
                                "A: Won from Opps qualified or later at start of month with PCD in month"
                            )[0].perc) *
                            100
                          ).toFixed(1)}%`}
                    </TableCell>
                 
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                      style={{
                        border: "2px solid black",
                        background:
                          parseFloat(targetA) < parseFloat(valueA).toFixed(1)
                            ? "#FFA257"
                            : parseFloat(targetA) > parseFloat(valueA).toFixed(1)
                            ? "#9ACB77"
                            : "",
                      }}
                      onDoubleClick={toggleInputA}
                    >
                      {toggleA ? (
                        <span>{`${targetA.toFixed(1)}%`}</span>
                      ) : (
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            width: "5rem",
                            background: "none",
                          }}
                          type="number"
                          autoFocus
                          value={editA}
                          onChange={handleChangeA}
                          onBlur={() => onSaveA(editA)}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              onSaveA(editA);
                            }
                          }}
                        />
                      )}
                    </TableCell>
                  
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >{`$${countOrACVFormatter(
                      (targetA / 100) * targetVal
                    )}`}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      {![null, undefined, ""].includes(
                        props.dataA[0].convPerc 
                      )
                        ? `${(
                            (props.dataA[0].convPerc) *
                            100
                          ).toFixed(1)}%`
                        : "0%"}
                    </TableCell>
                   
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                      style={{
                        border: "2px solid black",
                        background:
                          parseFloat(targetConvA) <
                          parseFloat(valueConA).toFixed(1)
                            ? "#FFA257"
                            : parseFloat(targetConvA) >
                              parseFloat(valueConA).toFixed(1)
                            ? "#9ACB77"
                            : "",
                      }}
                      onDoubleClick={toggleInputConvA}
                    >
                      {toggleConvA ? (
                        <span>{`${targetConvA.toFixed(1)}%`}</span>
                      ) : (
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            width: "5rem",
                            background: "none",
                          }}
                          type="number"
                          autoFocus
                          value={editConvA}
                          onBlur={() => onSaveConvA(editConvA)}
                          onChange={handleChangeConvA}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              onSaveConvA(editConvA);
                            }
                          }}
                        />
                      )}
                    </TableCell>
              
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >{`${(editConvA === 0 || isNaN(editConvA) ? 0 : (1 / (targetConvA / 100))).toFixed(1)}`}</TableCell>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      <span>
                        $
                        {countOrACVFormatterRoundToNearestN(
                          editConvA === 0 || isNaN(editConvA) ? 0 : (targetA / 100) * targetVal * (1 / (targetConvA / 100)),
                          10000
                        )}
                      </span>
                      <br />
                      <span>(At month start)</span>
                    </TableCell>
                  </StyledTableRow>
  
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      <span>Qualified and Closed Won in the same month</span>{" "}
                      <br /> <span>(B from Conversion Efficiency)</span>
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      {[null, undefined, ""].includes(
                        props.data.filter(
                          (f) =>
                            f.Stage_at_time_T ===
                            "B: Qualified and Won in the same month and NOT Qualified or later at start of month"
                        )[0].perc
                      )
                        ? "0%"
                        : `${(
                            props.data.filter(
                              (f) =>
                                f.Stage_at_time_T ===
                                "B: Qualified and Won in the same month and NOT Qualified or later at start of month"
                            )[0].perc * 100
                          ).toFixed(1)}%`}
                    </TableCell>
                  
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      style={{
                        border: "2px solid black",
                        background:
                          parseFloat(targetB) < parseFloat(valueB).toFixed(1)
                            ? "#FFA257"
                            : parseFloat(targetB) > parseFloat(valueB).toFixed(1)
                            ? "#9ACB77"
                            : "",
                      }}
                      colSpan={1}
                      onDoubleClick={toggleInputB}
                    >
                      {toggleB ? (
                        <span>{`${targetB.toFixed(1)}%`}</span>
                      ) : (
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            width: "5rem",
                            background: "none",
                          }}
                          type="number"
                          autoFocus
                          value={editB}
                          onBlur={() => onSaveB(editB)}
                          onChange={handleChangeB}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              onSaveB(editB);
                            }
                          }}
                        />
                      )}
                    </TableCell>
              
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >{`$${countOrACVFormatter(
                      (targetB / 100) * targetVal
                    )}`}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      {![null, undefined, ""].includes(props.dataB[0].convPerc)
                        ? `${(props.dataB[0].convPerc * 100).toFixed(1)}%`
                        : "0%"}
                    </TableCell>
                   
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      style={{
                        border: "2px solid black",
                        background:
                          parseFloat(targetConvB) <
                          parseFloat(valueConB).toFixed(1)
                            ? "#FFA257"
                            : parseFloat(targetConvB) >
                              parseFloat(valueConB).toFixed(1)
                            ? "#9ACB77"
                            : "",
                      }}
                      colSpan={1}
                      onDoubleClick={toggleInputConvB}
                    >
                      {toggleConvB ? (
                        <span>{`${targetConvB.toFixed(1)}%`}</span>
                      ) : (
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            width: "5rem",
                            background: "none",
                          }}
                          type="number"
                          autoFocus
                          value={editConvB}
                          onBlur={() => onSaveConvB(editConvB)}
                          onChange={handleChangeConvB}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              onSaveConvB(editConvB);
                            }
                          }}
                        />
                      )}
                    </TableCell>
                
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >{`${(editConvB === 0 || isNaN(editConvB) ? 0 : (1 / (targetConvB / 100))).toFixed(1)}`}</TableCell>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      <span>
                        $
                        {countOrACVFormatterRoundToNearestN(
                          editConvB === 0 || isNaN(editConvB) ? 0 : (targetB / 100) * targetVal * (1 / (targetConvB / 100)),
                          10000
                        )}
                      </span>
                      <br />
                      <span>(Created in month)</span>
                    </TableCell>
                  </StyledTableRow>
  
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      <span>Opps pulled in</span> <br />{" "}
                      <span>(C from Conversion Efficiency)</span>
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      {[null, undefined, ""].includes(
                          props.data.filter(
                            (f) =>
                              f.Stage_at_time_T ===
                              "C: Won from Opps qualified or later at start of month with PCD NOT in month (Pulled in)"
                          )[0].perc
                      )
                        ? "0%"
                        : `${(
                            (
                              props.data.filter(
                                (f) =>
                                  f.Stage_at_time_T ===
                                  "C: Won from Opps qualified or later at start of month with PCD NOT in month (Pulled in)"
                              )[0].perc) *
                            100
                          ).toFixed(1)}%`}
                    </TableCell>
                 
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      style={{
                        border: "2px solid black",
                        background:
                          parseFloat(targetC) < parseFloat(valueC).toFixed(1)
                            ? "#FFA257"
                            : parseFloat(targetC) > parseFloat(valueC).toFixed(1)
                            ? "#9ACB77"
                            : "",
                      }}
                      colSpan={1}
                      onDoubleClick={toggleInputC}
                    >
                      {toggleC ? (
                        <span>{`${targetC.toFixed(1)}%`}</span>
                      ) : (
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            width: "5rem",
                            background: "none",
                          }}
                          type="number"
                          autoFocus
                          value={editC}
                          onBlur={() => onSaveC(editC)}
                          onChange={handleChangeC}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              onSaveC(editC);
                            }
                          }}
                        />
                      )}
                    </TableCell>
                  
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >{`$${countOrACVFormatter(
                      (targetC / 100) * targetVal
                    )}`}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      {![null, undefined, ""].includes(
                         props.dataC[0].convPerc
                      )
                        ? `${(
                            ( props.dataC[0].convPerc) *
                            100
                          ).toFixed(1)}%`
                        : "0%"}
                    </TableCell>
                   
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                      style={{
                        border: "2px solid black",
                        background:
                          parseFloat(targetConvC) <
                          parseFloat(valueConC).toFixed(1)
                            ? "#FFA257"
                            : parseFloat(targetConvC) >
                              parseFloat(valueConC).toFixed(1)
                            ? "#9ACB77"
                            : "",
                      }}
                      onDoubleClick={toggleInputConvC}
                    >
                      {toggleConvC ? (
                        <span>{`${targetConvC.toFixed(1)}%`}</span>
                      ) : (
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            width: "5rem",
                            background: "none",
                          }}
                          type="number"
                          autoFocus
                          value={editConvC}
                          onBlur={() => onSaveConvC(editConvC)}
                          onChange={handleChangeConvC}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              onSaveConvC(editConvC);
                            }
                          }}
                        />
                      )}
                    </TableCell>
              
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                    >{`${(editConvC === 0 || isNaN(editConvC) ? 0 : (1 / (targetConvC / 100))).toFixed(1)}`}</TableCell>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                    >
                      <span>
                        $
                        {countOrACVFormatterRoundToNearestN(
                         editConvC === 0 || isNaN(editConvC) ? 0 : (targetC / 100) * targetVal * (1 / (targetConvC / 100)),
                         10000
                        )}
                      </span>
                      <br />
                      <span>(At month start)</span>
                    </TableCell>
                  </StyledTableRow>
  
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={classes.repHeader1}
                      colSpan={1}
                      style={{ paddingTop: "1rem" }}
                      rowSpan={4}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                      style={{ paddingTop: "1rem" }}
                      rowSpan={4}
                    >
                      100%
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                      style={{ paddingTop: "1rem" }}
                      rowSpan={4}
                    >
                      {Math.round(targetA + targetB + targetC)}%
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader1}
                      colSpan={1}
                      style={{ paddingTop: "1rem" }}
                      rowSpan={4}
                    >
                      ${countOrACVFormatter(targetVal)}
                    </TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
  
          <Grid
            container
            items
            alignItems="left"
            xs={12}
            sm={12}
            style={{
              marginTop: "1rem",
              // border: "1px solid black",
              padding: "0.5rem",
            }}
          >
            <Grid item xs={12}>
              <Typography
                className={classes.fontBold}
                align="left"
                variant="body1"
                style={{marginTop: "0.8rem",marginBottom: "0.8rem", }}
              >
                In order to win{" "}
                <span
                  style={{ background: "#E2EFDA", padding: "0.2rem", }}
                >{`${countOrACVFormatterMillionNew(targetVal, true)}`}</span>{" "}
                in ${props.quarter}, 
              </Typography>
              <GrowthModelByMonthDescriptionTable data1={props.table1} rows1={['Needed', 'Available', 'Delta']} header={'Qualified Pipeline entering the quarter'}
              data2={props.table2} rows2={['Needed', 'Avg. of Qualified Pipe Created per month (past 4 months)', 'Delta']}
              data3={props.table3} rows3={['Needed', 'Available', 'Delta']}
              qtr = {props.quarter}
              />
            </Grid>
          </Grid>
        </CardContent>
      </div>
    );
  }