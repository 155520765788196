import { FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core'
import React from 'react'

export default function RenewalType(props) {
    return (
        <Grid justify="flex-start" item xs={12} container component={Paper} style={{ padding: 10, marginRight: 10 }}>
            <Grid item xs={12}>
                <FormControl fullWidth >
                    <InputLabel style={{ color: !props.Renewal_Type_cValue.includes('All') ? '#4472c4' : '#000' }} id="select-vfiscalQuarter-label">Renewal Type:</InputLabel>
                    <Select
                        labelId="select-vRenewal_Type_c-label"
                        id="select-cRenewal_Type_c"
                        value={props.Renewal_Type_cValue}
                        onChange={props.handleChange}
                        label="Renewal_Type_c"
                        name='Renewal_Type_c'
                        multiple
                    >
                        <MenuItem value={'All'} >All</MenuItem>

                        {props.funnelCompareFilters !== '' && props.funnelCompareFilters.filter(y => y.type === "Renewal_Type_c" && y["Renewal_Type_c"] !== '')
                            .map(y => <MenuItem key={y['Renewal_Type_c']} value={y['Renewal_Type_c']}>{y['Renewal_Type_c']}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}
