import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
} from "../../util/customFunctions";

import * as d3 from "d3";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import stickytableStyle from "../../StickyTableStyles/Sticky_Table_Styles";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
const useStyle = makeStyles((theme) => ({
  root: {},
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "130px",
    fontWeight: "inherit",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: "inherit",
  },
  repNumberACV: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "92px",
    fontWeight: "inherit",
  },
  repNumberPercent: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "90px",
    fontWeight: 600,
    cursor: "pointer",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
}));
export default function TeamAttainmentTable(props) {
  console.log(props);
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  // const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [parentWidth, setparentWidth] = useState();
  const [rowPin, setrowPin] = useState(false);

  let totalindex;

  const uniqueQuarters = props.data.final
    .map((item) => item.Quarter)
    .filter(getUniqueValues)
    .sort();
  let uniqueStages = props.data.rowTotal
    .sort((a, b) => (a.attainmentPercent > b.attainmentPercent ? -1 : 1))
    .map((item) => item.Display_Name)
    .filter(getUniqueValues);
  const ast = (x) => (
    <>
      <span>{x}</span> <span style={{ color: "#ed7d31" }}>*</span>
    </>
  );

  //to Update copy to clipboard position
  const updateParentWidth = () => {
    const newParentWidth =
      tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(newParentWidth);
  };

  useEffect(() => {
    // Initial width calculation
    updateParentWidth();

    // Add event listener for window resize
    window.addEventListener("resize", updateParentWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateParentWidth);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (tableContainer.scrollTop > 0) {
        setrowPin(true);
      } else {
        setrowPin(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);
  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        {parentWidth !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
              width: `${parentWidth}px`,
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="center">
          <TableContainer
            className={classes.mainTable}
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
              borderTop: rowPin ? "0.5px solid lightgrey" : "",
              maxHeight: "85vh",
            }}
            id="myTableContainer"
            ref={tableContainerRef}
          >
            <Table
              ref={tableRef}
              data-test={"segmentHistorical"}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead style={{ position: "sticky", top: -1, zIndex: 5 }}>
                <TableRow
                  style={{
                    fontWeight: 600,
                    paddingLeft: 5,
                    outline: "0.4px solid lightgrey",
                    outlineOffset: "-0.5px",
                  }}
                >
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ background: "white" }}
                  ></TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        className={classes.repHeader}
                        colSpan={uniqueQuarters.length === 1 ? 4 : 3}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueQuarters.length > 1 &&
                          uniqueQuarters.length % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        align="center"
                        className={classes.repHeader}
                        colSpan={4}
                      >
                        {"Total"}
                      </TableCell>
                    </React.Fragment>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 200, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
                <TableRow
                  style={{
                    fontWeight: 600,
                    paddingLeft: 5,
                    outline: "0.4px solid lightgrey",
                    outlineOffset: "-0.5px",
                  }}
                >
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ background: "white" }}
                  >
                    Team
                  </TableCell>
                  {uniqueQuarters.map((i) => {
                    return (
                      <React.Fragment key={i}>
                        <TableCell
                          align="center"
                          style={{ minWidth: 70, background: "white" }}
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          Quota
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: 90, background: "white" }}
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          Actual
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: 70, background: "white" }}
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          Attain %
                        </TableCell>
                        {uniqueQuarters.length === 1 && (
                          <>
                            <TableCell
                              align="center"
                              style={{ minWidth: 70,background: "white" }}
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              Contribution to Total %
                            </TableCell>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        align="center"
                        style={{ minWidth: 70, background: "white" }}
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        Quota
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 90, background: "white" }}
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        Actual
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 70, background: "white" }}
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        Attain %
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 70, background: "white" }}
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        Contribution to Total %
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueStages.map((row, i) => {
                  totalindex = i + 1;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // className={classes.repText}
                        className={
                          isSticky
                            ? `${stickyClasses.sticky} ${classes.repText}`
                            : classes.repText
                        }
                        style={
                          isSticky && i % 2 === 1
                            ? { background: "#f5f5f5" }
                            : {}
                        }
                        colSpan={1}
                      >
                        {props.data.final
                          .filter((f) => f.Display_Name === row)
                          .map((s) => s.Quarter).length < uniqueQuarters.length
                          ? ast(row)
                          : row}
                      </TableCell>
                      {uniqueQuarters.map((cell, j) => {
                        let quota = props.data.final
                          .filter(
                            (item) =>
                              item.Quarter === cell && item.Display_Name === row
                          )
                          .map((item) => item.Quota)[0];
                        let acv = props.data.final
                          .filter(
                            (item) =>
                              item.Quarter === cell && item.Display_Name === row
                          )
                          .map((item) => item.acv)[0];
                        let services = props.data.final
                          .filter(
                            (item) =>
                              item.Quarter === cell && item.Display_Name === row
                          )
                          .map((item) => item.services)[0];
                        let elv = props.data.final
                          .filter(
                            (item) =>
                              item.Quarter === cell && item.Display_Name === row
                          )
                          .map((item) => item.ELV)[0];
                        let attainPerc = props.data.final
                          .filter(
                            (item) =>
                              item.Quarter === cell && item.Display_Name === row
                          )
                          .map((item) => item.attainmentPercent)[0];
                        return (
                          <React.Fragment key={j}>
                            <TableCell
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {quota === "undefined" ||
                              quota === null ||
                              _isNaN(quota)
                                ? "-"
                                : countOrACVFormatter(quota, true)}{" "}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  [cell],
                                  [row]
                                )
                              }
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {acv === "undefiend" ||
                              acv === null ||
                              _isNaN(acv)
                                ? "-"
                                : countOrACVFormatter(acv, true)}{" "}
                            </TableCell>

                            <TableCell
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {attainPerc === "undefiend" ||
                              attainPerc === null ||
                              _isNaN(attainPerc)
                                ? "-"
                                : attainPerc + "%"}
                            </TableCell>
                            {uniqueQuarters.length === 1 && (
                              <>
                                <TableCell
                                  align="right"
                                  className={classes.repNumber}
                                  colSpan={1}
                                >
                                  {[null, undefined, ""].includes(
                                    props.data.rowTotal.filter(
                                      (item) => item.Display_Name === row
                                    )[0].contributionToToalPercent
                                  )
                                    ? "0"
                                    : props.data.rowTotal.filter(
                                        (item) => item.Display_Name === row
                                      )[0].contributionToToalPercent}
                                  %
                                </TableCell>
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}
                      {uniqueQuarters.length > 1 && (
                        <React.Fragment>
                          <TableCell
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {countOrACVFormatter(
                              props.data.rowTotal.filter(
                                (item) => item.Display_Name === row
                              )[0].Quota,
                              true
                            )}
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                uniqueQuarters,
                                [row]
                              )
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {countOrACVFormatter(
                              props.data.rowTotal.filter(
                                (item) => item.Display_Name === row
                              )[0].acv,
                              true
                            )}
                          </TableCell>

                          <TableCell
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {[null, undefined, ""].includes(
                              props.data.rowTotal.filter(
                                (item) => item.Display_Name === row
                              )[0].attainmentPercent
                            )
                              ? "0"
                              : props.data.rowTotal.filter(
                                  (item) => item.Display_Name === row
                                )[0].attainmentPercent}
                            %
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {[null, undefined, ""].includes(
                              props.data.rowTotal.filter(
                                (item) => item.Display_Name === row
                              )[0].contributionToToalPercent
                            )
                              ? "0"
                              : props.data.rowTotal.filter(
                                  (item) => item.Display_Name === row
                                )[0].contributionToToalPercent}
                            %
                          </TableCell>
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    align="left"
                    // className={classes.repText}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repText}`
                        : classes.repText
                    }
                    style={
                      isSticky && totalindex % 2 === 1
                        ? { background: "#f5f5f5" }
                        : {}
                    }
                    colSpan={1}
                  >
                    Total
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    const totalQuota = props.data.columnTotal.filter(
                      (item) => item.Quarter === cell
                    )[0].Quota;
                    const totalAcv = props.data.columnTotal.filter(
                      (item) => item.Quarter === cell
                    )[0].acv;
                    const totalServices = props.data.columnTotal.filter(
                      (item) => item.Quarter === cell
                    )[0].services;
                    const totalELV = props.data.columnTotal.filter(
                      (item) => item.Quarter === cell
                    )[0].ELV;
                    const attainmentPercent = props.data.columnTotal.filter(
                      (item) => item.Quarter === cell
                    )[0].attainmentPercent;
                    return (
                      <React.Fragment key={j}>
                        <TableCell className={classes.repNumber} colSpan={1}>
                          {countOrACVFormatter(
                            [null, undefined, ""].includes(totalQuota)
                              ? 0
                              : totalQuota,
                            true
                          )}
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              [cell],
                              uniqueStages
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {countOrACVFormatter(
                            [null, undefined, ""].includes(totalAcv)
                              ? 0
                              : totalAcv,
                            true
                          )}
                        </TableCell>

                        <TableCell
                          align="right"
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {[null, undefined, ""].includes(attainmentPercent)
                            ? 0
                            : attainmentPercent}
                          %
                        </TableCell>
                        {uniqueQuarters.length === 1 && (
                          <>
                            <TableCell
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            ></TableCell>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {countOrACVFormatter(props.data.total[0].Quota, true)}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            uniqueQuarters,
                            uniqueStages
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {countOrACVFormatter(props.data.total[0].acv, true)}
                      </TableCell>

                      <TableCell
                        /* style={{cursor: "pointer"}}
                                                             onClick={() => props.handleChangeLocation("drilldownOnGraph",uniqueQuarters,uniqueStages)} */ align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {["", null, undefined].includes(
                          props.data.total[0].attainmentPercent
                        )
                          ? "0"
                          : props.data.total[0].attainmentPercent}
                        %
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      ></TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {uniqueStages
          .map((team) =>
            props.data.final.filter(
              (x) => x.Display_Name === team && x.Quota !== null
            ).length < uniqueQuarters.length
              ? props.data.final.filter(
                  (x) => x.Display_Name === team && x.Quota !== null
                ).length
              : 0
          )
          .reduce((a, b) => a + b, 0) !== 0 &&
          uniqueStages
            .map((team) =>
              props.data.final.filter(
                (x) => x.Display_Name === team && x.Quota !== null
              ).length < uniqueQuarters.length
                ? props.data.final.filter(
                    (x) => x.Display_Name === team && x.Quota !== null
                  ).length
                : 0
            )
            .reduce((a, b) => a + b, 0) !== 0 && (
            <Grid
              item
              container
              xs={12}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="body2" style={{ paddingTop: "1rem" }}>
                <span style={{ color: "#ED7D31" }}>*</span>&nbsp; Excluded from
                analysis for time periods where no quota data is available.
              </Typography>
            </Grid>
          )}
      </CardContent>
    </div>
  );
}
