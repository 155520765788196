import {
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import {
  countOrACVFormatter,
  StyledTableRow,
  _isNaN,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
const useStyle = makeStyles((theme) => ({
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "10px 10px",
    color: "inherit",
  },
  nonScoreCell: {
    fontWeight: 400,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  repHeaderDrilldown: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
    cursor: "pointer",
  },
  repNameMargin: {
    paddingLeft: "1.75rem",
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    padding: "0.25rem",
  },
}));
function QTDDealsTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();
  const [isOverflowing, setIsOverflowing] = useState(false);

  const uniqueNames = props.dealsTableData.map((s) => s.name).sort();
  const compare = (a, b) => (a >= b ? "#e2efda" : null);

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainerdeal");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);
  return (
    <>
      <Grid xs={12}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              // marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{ padding: "1rem", width: "100%" }}
        align="center"
        id="myTableContainerdeal"
      >
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Table ref={tableRef} data-test={props.dataTest}>
          <TableBody>
            {/* col headers row */}
            <StyledTableRow style={{ height: 70 }}>
              <TableCell
                align="center"
                className={classes.repHeader}
                rowSpan={1}
                colSpan={1}
                style={{ background: "#FCE3D7", minWidth: 160 }}
              >{`# of deals ≥ $30K ${
                props.type === "CFQ" ? "Current" : "Next"
              } Qtr. Qualified and above`}</TableCell>
              <TableCell
                align="center"
                className={`${classes.repHeader} ${classes.tableHeader}`}
                colSpan={1}
                style={{ background: "#4472C4", color: "white" }}
              >
                {"Total"}
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.repHeader} ${classes.tableHeader}`}
                colSpan={1}
                style={{ background: "#4472C4", color: "white" }}
              >
                {"New Customer"}
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.repHeader} ${classes.tableHeader}`}
                colSpan={1}
                style={{ background: "#4472C4", color: "white" }}
              >
                {"	Existing Customer"}
              </TableCell>
              {props.type === "CFQ" && (
                <TableCell
                  align="center"
                  className={`${classes.repHeader} ${classes.tableHeader}`}
                  colSpan={1}
                  style={{ background: "#BF8F00", color: "white" }}
                >
                  {"Remaining Current Qtr. Target"}
                </TableCell>
              )}
            </StyledTableRow>
            {/* Segment Row */}
            <StyledTableRow>
              <TableCell align="left" className={classes.repHeader} colSpan={1}>
                {props.selectedTeam.includes("All") ? "Company" : "Segment"}
              </TableCell>

              <TableCell
                align="right"
                onClick={() =>
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    uniqueNames,
                    ["New Customer", "Existing Customer"],
                    props.type,
                    "Deals20K"
                  )
                }
                className={classes.repHeader}
                style={{ cursor: "pointer" }}
                colSpan={1}
              >
                {`${d3.format(",")(
                  ![null, undefined, ""].includes(
                    props.dealsSegmentRow[0].total
                  )
                    ? props.dealsSegmentRow[0].total
                    : 0
                )}`}
              </TableCell>
              <TableCell
                align="right"
                className={classes.repHeaderDrilldown}
                onClick={() =>
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    uniqueNames,
                    ["New Customer"],
                    props.type,
                    "Deals20K"
                  )
                }
                style={{ cursor: "pointer" }}
                colSpan={1}
              >
                {![null, undefined, ""].includes(
                  props.dealsSegmentRow[0].newCustomerTotal
                )
                  ? props.dealsSegmentRow[0].newCustomerTotal
                  : 0}
              </TableCell>
              <TableCell
                align="right"
                className={classes.repHeader}
                onClick={() =>
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    uniqueNames,
                    ["Existing Customer"],
                    props.type,
                    "Deals20K"
                  )
                }
                style={{ cursor: "pointer" }}
                colSpan={1}
              >
                {![null, undefined, ""].includes(
                  props.dealsSegmentRow[0].existingCustomerTotal
                )
                  ? props.dealsSegmentRow[0].existingCustomerTotal
                  : 0}
              </TableCell>
              {props.type === "CFQ" && (
                <TableCell
                  align="right"
                  className={classes.repHeader}
                  colSpan={1}
                >
                  {![null, undefined, ""].includes(
                    props.dealsSegmentRow[0].remainingCurrentQuarter
                  )
                    ? props.dealsSegmentRow[0].remainingCurrentQuarter
                    : 0}
                </TableCell>
              )}
            </StyledTableRow>

            {uniqueNames.map((rep) => {
              const rowData = props.dealsTableData.filter(
                (item) => item.name === rep
              )[0];
              return (
                <>
                  <StyledTableRow key={rep}>
                    <TableCell
                      align="left"
                      className={classes.repNameMargin}
                      colSpan={1}
                    >
                      {rep}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader}
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          [rep],
                          ["New Customer", "Existing Customer"],
                          props.type,
                          "Deals20K"
                        )
                      }
                      style={{ cursor: "pointer" }}
                      colSpan={1}
                    >{`${d3.format(",")(
                      ![null, undefined, ""].includes(rowData.totalCount)
                        ? rowData.totalCount
                        : 0
                    )}`}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader}
                      colSpan={1}
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          [rep],
                          ["New Customer"],
                          props.type,
                          "Deals20K"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >{`${d3.format(",")(
                      ![null, undefined, ""].includes(rowData.newCount)
                        ? rowData.newCount
                        : 0
                    )}`}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.repHeader}
                      colSpan={1}
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          [rep],
                          ["Existing Customer"],
                          props.type,
                          "Deals20K"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >{`${d3.format(",")(
                      ![null, undefined, ""].includes(rowData.existingCount)
                        ? rowData.existingCount
                        : 0
                    )}`}</TableCell>
                    {props.type === "CFQ" && (
                      <TableCell
                        align="right"
                        className={classes.repHeader}
                        colSpan={1}
                      >{`${d3.format(",")(
                        ![null, undefined, ""].includes(
                          rowData.remainingCurrentQuarter
                        )
                          ? rowData.remainingCurrentQuarter
                          : 0
                      )}`}</TableCell>
                    )}
                  </StyledTableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default QTDDealsTable;
