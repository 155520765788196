import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button, Grid, Modal, Hidden, Dialog } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import HeaderDD from "./DrilldownSubComponents/HeaderDD";
import FiltersDD from "./DrilldownSubComponents/FiltersDD";

const useStyle = makeStyles((theme) => ({
  paperParent: {
    left: "87px",
    display: "flex",
    position: "relative",
    width: "calc(100% - 118px)",
    // paddingRight: '36px',
    // paddingLeft: '36px',
    paddingTop: "74px",
    justifyContent: "center",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.down("md")]: {
      left: "0px",
      width: "100%",
      paddingRight: "26px",
      paddingLeft: "26px",
      paddingTop: "74px",
    },
  },

  paperRoot: {
    width: "100%",
    padding: 20,
    maxWidth: 1600,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SingleLevelDrilldown = (props) => {
  const classes = useStyle();
  // console.log(props.showOutliers)
  //This old
  return (
    <React.Fragment>
      <Hidden mdUp>
        <Dialog fullScreen open={true} TransitionComponent={Transition}>
          <div>
            <Paper className={classes.paperRoot}>
              <HeaderDD
                title={props.header}
                description={props.description}
                back={props.back}
                stepBack={props.stepBack}
                showOutliers={props.showOutliers}
                outliersData={props.outliersData}
                outlierValue={props.outlierValue}
              />
              <FiltersDD filters={props.filters} />
              {props.children}
            </Paper>
          </div>
        </Dialog>
      </Hidden>
      <Hidden smDown>
        <Modal open={true} style={{ overflow: "auto" }}>
          <div className={classes.paperParent}>
            <Paper className={classes.paperRoot}>
              <HeaderDD
                title={props.header}
                description={props.description}
                back={props.back}
                stepBack={props.stepBack}
                showOutliers={props.showOutliers}
                outliersData={props.outliersData}
                outlierValue={props.outlierValue}
              />
              <br />
              <br />
              <FiltersDD filters={props.filters} count={props.count} />
              {props.children}
            </Paper>
          </div>
        </Modal>
      </Hidden>
    </React.Fragment>
  );
};

export default SingleLevelDrilldown;
