import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "./StyledTableRow";
import {
  convertToThousands,
  countOrACVFormatter,
  getUniqueValues,
  _isNaN,
} from "../util/customFunctions";
import * as d3 from "d3";
import { getMixKey } from "../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
  customSortedtierASegments,
  customSortedtierBSegments,
} from "../util/customSortings";
import { deSnake } from "../util/dataFormatter";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CopyTablesObject from "./xlsx-export/CopyTablesObject";
import stickytableStyle from "../StickyTableStyles/Sticky_Table_Styles";
// import ExcelExportButton from "./xlsx-export/xlsxexport";

const useStyle = makeStyles((theme) => ({
  root: {},
  // sticky: {
  //   position: "sticky",
  //   left: -1,
  //   // color: "inherit",
  //   background: "white",
  //   // backgroundColor: "inherit",
  //   // boxShadow: "5px 2px 5px grey",
  //   // borderRight: "2px solid black",
  // },
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
    fontWeight: "inherit",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: "inherit",
  },
  repNumberACV: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "92px",
    fontWeight: "inherit",
  },
  repNumberPercent: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "90px",
    fontWeight: 600,
    cursor: "pointer",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
}));

export default function DyanmicTable(props) {
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const labelMap = {
    Open_Initial_Year_Month: "Open Initial Month",
    Open_Date_Initial_Fiscal_Quarter: "Open Initial Fiscal Qtr",
    Open_Initial_Year_Month: "Open Initial Month",
    Created_Year_Month: "Created Month",
    Qualified_Date_Initial_Fiscal_Quarter: "Qualified Initial Fiscal Qtr",
    Qualified_Initial_Year_Month: "Qualified Initial Month",
  };
  let acvLabel = "ACV";
  if (props.Value_Label) {
    acvLabel = props.Value_Label;
  }

  const [acvordering, setacvordering] = useState("acv");
  const [oppsordering, setOppsordering] = useState("dsc");

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [pointer, setPointer] = useState("acv");

  const [isSticky, setIsSticky] = useState(false);

  let countinc = 0;

  const uniqueQuarters = props.winAcvData
    .map((item) => item[props.quarter])
    .filter(getUniqueValues);

  let uniqueStages = props.winAcvData
    .map((item) => item[props.type])
    .filter(getUniqueValues);

  const stages = props.winAcvData.map((item) => item[props.type]);
  if (props.type === "Booking_Type_NL_CS_US") {
    uniqueStages = customSortedOppType;
  } else if (props.type === "Acct_Segment") {
    const sortedSegemnt = customSortedSegments;
    const missingSegemnt = uniqueStages.filter(function (obj) {
      return sortedSegemnt.indexOf(obj) == -1;
    });
    uniqueStages = [...sortedSegemnt, ...missingSegemnt];
  }
  const tierASegment = props.winAcvData.filter(
    (item) => item.Acct_Segment_Rollup === "Major / Enterprise"
  );
  const tierBSegment = props.winAcvData.filter(
    (item) => item.Acct_Segment_Rollup === "Mid-Market / SMB"
  );
  let tierAStages = customSortedtierASegments;
  let tierBStages = customSortedtierBSegments;
  const missingTierASegment = props.winAcvData
    .filter((item) => item.Acct_Segment_Rollup === "Major / Enterprise")
    .map((item) => item[props.type])
    .filter(getUniqueValues)
    .filter((obj) => tierAStages.indexOf(obj) == -1);
  const missingTierBSegment = props.winAcvData
    .filter((item) => item.Acct_Segment_Rollup === "Mid-Market / SMB")
    .map((item) => item[props.type])
    .filter(getUniqueValues)
    .filter((obj) => tierBStages.indexOf(obj) == -1);

  let tierAUniqueStages = [...tierAStages, ...missingTierASegment];
  let tierBUniqueStages = [...tierBStages, ...missingTierBSegment];

  //////////////////////////////////////////////////////
  var sortedUniquestages = {};

  tierAUniqueStages.map(
    (item) =>
      (sortedUniquestages[item] = props.winAcvData
        .filter((f) => f[props.type] === item)
        .map((i) => i[acvordering])
        .reduce((a, b) => a + b, 0))
  );

  // for descending order
  let sortable = Object.fromEntries(
    Object.entries(sortedUniquestages)
      .sort(([, a], [, b]) => a - b)
      .reverse()
  );

  // for ascending order
  if (oppsordering === "asc") {
    sortable = Object.fromEntries(
      Object.entries(sortedUniquestages).sort(([, a], [, b]) => a - b)
    );
  }

  tierAUniqueStages = Object.keys(sortable);

  // tierBUniqueStages = Object.keys(sortable);
  //////////////////////////////////////////////////////

  //////////////////////////////////////////////////////
  var sortedBUniquestages = {};

  tierBUniqueStages.map(
    (item) =>
      (sortedBUniquestages[item] = props.winAcvData
        .filter((f) => f[props.type] === item)
        .map((i) => i[acvordering])
        .reduce((a, b) => a + b, 0))
  );

  // for descending order
  let sortableB = Object.fromEntries(
    Object.entries(sortedBUniquestages)
      .sort(([, a], [, b]) => a - b)
      .reverse()
  );

  // for ascending order
  if (oppsordering === "asc") {
    sortableB = Object.fromEntries(
      Object.entries(sortedBUniquestages).sort(([, a], [, b]) => a - b)
    );
  }

  // tierAUniqueStages = Object.keys(sortable);

  tierBUniqueStages = Object.keys(sortableB);
  //////////////////////////////////////////////////////

  const acv = props.avgDealSize ? "avgAcv" : "acv";

  const handleSort = (key) => {
    console.log("clickedkey", key);
    setacvordering(key);
    setPointer(key);
    // setOppsordering(oppsordering === "asc" ? "dsc" : "asc");
    console.log("opsorder", oppsordering);
  };

  //Copy Icon Positioning
  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-second");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="center">
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id="myTableContainer-second"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              ref={tableRef}
              data-test={
                props.avgDealSize
                  ? `${getMixKey(props.type)}avgDealSize`
                  : getMixKey(props.type, "ACV")
              }
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                  >
                    {labelMap[props.quarter]
                      ? labelMap[props.quarter]
                      : deSnake(props.quarter)}
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        className={classes.repHeader}
                        colSpan={3}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueQuarters.length > 1 &&
                          uniqueQuarters.length % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        align="center"
                        className={classes.repHeader}
                        colSpan={3}
                      >
                        {"Total"}
                      </TableCell>
                    </React.Fragment>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 200, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                  >
                    {getMixKey(props.type, acvLabel)}
                  </TableCell>
                  {uniqueQuarters.map((i) => {
                    return (
                      <React.Fragment key={i}>
                        <TableCell
                          align="center"
                          style={{ minWidth: 70 }}
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          # of Opps
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: 90 }}
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          {/* ACV */}
                          {`${acvLabel}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: 70 }}
                          className={classes.repHeader}
                          colSpan={1}
                        >
                          {props.avgDealSize ? props.avgDealSize : "% of Total"}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        align="center"
                        style={{ minWidth: 95 }}
                        className={classes.repHeader}
                        colSpan={1}
                        onClick={() => {
                          setOppsordering(
                            oppsordering === "asc" ? "dsc" : "asc"
                          );
                          handleSort("count");
                        }}
                      >
                        # of Opps
                        {pointer === "count" ? (
                          <TableSortLabel
                            active={true}
                            direction={oppsordering}
                          ></TableSortLabel>
                        ) : null}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 90 }}
                        className={classes.repHeader}
                        colSpan={1}
                        onClick={() => {
                          setOppsordering(
                            oppsordering === "asc" ? "dsc" : "asc"
                          );
                          handleSort("acv");
                        }}
                      >
                        {/* ACV */}
                        {`${acvLabel}`}
                        {pointer === "acv" ? (
                          <TableSortLabel
                            active={true}
                            direction={oppsordering}
                          ></TableSortLabel>
                        ) : null}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: 70 }}
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        {props.avgDealSize ? props.avgDealSize : "% of Total"}
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow
                  key={"MajorEnterprise"}
                  className={classes.fontBold}
                >
                  <TableCell
                    align="left"
                    // className={classes.repText}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repText}`
                        : classes.repText
                    }
                    colSpan={1}
                  >
                    Major / Enterprise
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    return (
                      <React.Fragment>
                        <TableCell
                          align="right"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Major / Enterprise", cell },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {countOrACVFormatter(
                            tierASegment
                              .filter((item) => item[props.quarter] === cell)
                              .reduce((a, b) => a + b.count, 0),
                            false
                          )}
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Major / Enterprise", cell },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          align="right"
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {countOrACVFormatter(
                            tierASegment
                              .filter((item) => item[props.quarter] === cell)
                              .reduce((a, b) => a + b.acv, 0),
                            true
                          )}
                        </TableCell>

                        <TableCell
                          align="right"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Major / Enterprise", cell },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {props.avgDealSize
                            ? _isNaN(
                                tierASegment
                                  .filter(
                                    (item) => item[props.quarter] === cell
                                  )
                                  .reduce((a, b) => a + b.acv, 0) /
                                  tierASegment
                                    .filter(
                                      (item) => item[props.quarter] === cell
                                    )
                                    .reduce((a, b) => a + b.count, 0)
                              )
                              ? "-"
                              : countOrACVFormatter(
                                  tierASegment
                                    .filter(
                                      (item) => item[props.quarter] === cell
                                    )
                                    .reduce((a, b) => a + b.acv, 0) /
                                    tierASegment
                                      .filter(
                                        (item) => item[props.quarter] === cell
                                      )
                                      .reduce((a, b) => a + b.count, 0),
                                  true
                                )
                            : `${
                                _isNaN(
                                  Math.round(
                                    (tierASegment
                                      .filter(
                                        (item) => item[props.quarter] === cell
                                      )
                                      .reduce((a, b) => a + b.acv, 0) /
                                      props.winAcvData
                                        .filter(
                                          (item) => item[props.quarter] === cell
                                        )
                                        .reduce((a, b) => a + b.acv, 0)) *
                                      100
                                  )
                                )
                                  ? "-"
                                  : Math.round(
                                      (tierASegment
                                        .filter(
                                          (item) => item[props.quarter] === cell
                                        )
                                        .reduce((a, b) => a + b.acv, 0) /
                                        props.winAcvData
                                          .filter(
                                            (item) =>
                                              item[props.quarter] === cell
                                          )
                                          .reduce((a, b) => a + b.acv, 0)) *
                                        100
                                    ) + "%"
                              }`}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              row: "Major / Enterprise",
                              cell: props.isMonth ? uniqueQuarters : "Total",
                            },
                            "Acct_Segment_Rollup",
                            props.quarter
                          )
                        }
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {countOrACVFormatter(
                          tierASegment.reduce((a, b) => a + b.count, 0),
                          false
                        )}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              row: "Major / Enterprise",
                              cell: props.isMonth ? uniqueQuarters : "Total",
                            },
                            "Acct_Segment_Rollup",
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {countOrACVFormatter(
                          tierASegment.reduce((a, b) => a + b.acv, 0),
                          true
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              row: "Major / Enterprise",
                              cell: props.isMonth ? uniqueQuarters : "Total",
                            },
                            "Acct_Segment_Rollup",
                            props.quarter
                          )
                        }
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {props.avgDealSize
                          ? _isNaN(
                              tierASegment.reduce((a, b) => a + b.acv, 0) /
                                tierASegment.reduce((a, b) => a + b.count, 0)
                            )
                            ? "-"
                            : countOrACVFormatter(
                                tierASegment.reduce((a, b) => a + b.acv, 0) /
                                  tierASegment.reduce((a, b) => a + b.count, 0),
                                true
                              )
                          : `${Math.round(
                              (tierASegment.reduce((a, b) => a + b.acv, 0) /
                                props.winAcvData.reduce(
                                  (a, b) => a + b.acv,
                                  0
                                )) *
                                100
                            )}%`}
                      </TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>
                {tierAUniqueStages.map((row, i) => {
                  countinc = countinc + 1;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // style={{ paddingLeft: 25 }}
                        // className={classes.repText}
                        className={
                          isSticky
                            ? `${stickyClasses.sticky} ${classes.repText}`
                            : classes.repText
                        }
                        style={
                          isSticky && countinc % 2 === 1
                            ? { background: "#f5f5f5", paddingLeft: "25px" }
                            : { paddingLeft: "25px" }
                        }
                        colSpan={1}
                      >
                        {row}
                      </TableCell>
                      {uniqueQuarters.map((cell, j) => {
                        const cellData = props.winAcvData.filter(
                          (item) =>
                            item[props.quarter] === cell &&
                            item[props.type] === row
                        )[0];
                        return (
                          <React.Fragment key={j}>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row, cell },
                                  props.type,
                                  props.quarter
                                )
                              }
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {cellData !== undefined
                                ? `${cellData.count}`
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row, cell },
                                  props.type,
                                  props.quarter
                                )
                              }
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {cellData !== undefined
                                ? `$${d3.format(",")(Math.round(cellData.acv))}`
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer", fontWeight: 600 }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row, cell },
                                  props.type,
                                  props.quarter
                                )
                              }
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {cellData !== undefined
                                ? props.avgDealSize
                                  ? `$${d3.format(",")(
                                      Math.round(cellData.avgAcv)
                                    )}`
                                  : `${
                                      _isNaN(
                                        Math.round(
                                          (cellData.acv /
                                            props.winAcvData
                                              .filter(
                                                (item) =>
                                                  item[props.quarter] === cell
                                              )
                                              .map((item) => item.acv)
                                              .reduce((a, b) => a + b, 0)) *
                                            100
                                        )
                                      )
                                        ? "-"
                                        : `${Math.round(
                                            (cellData.acv /
                                              props.winAcvData
                                                .filter(
                                                  (item) =>
                                                    item[props.quarter] === cell
                                                )
                                                .map((item) => item.acv)
                                                .reduce((a, b) => a + b, 0)) *
                                              100
                                          )}%`
                                    }`
                                : "-"}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                      {uniqueQuarters.length > 1 && (
                        <React.Fragment>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  row,
                                  cell: props.isMonth
                                    ? uniqueQuarters
                                    : "Total",
                                },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >{`${props.winAcvData
                            .filter((item) => item[props.type] === row)
                            .map((item) => item.count)
                            .reduce((a, b) => a + b, 0)}`}</TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  row,
                                  cell: props.isMonth
                                    ? uniqueQuarters
                                    : "Total",
                                },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >{`$${d3.format(",")(
                            Math.round(
                              props.winAcvData
                                .filter((item) => item[props.type] === row)
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0)
                            )
                          )}`}</TableCell>
                          <TableCell
                            style={{ cursor: "pointer", fontWeight: 600 }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  row,
                                  cell: props.isMonth
                                    ? uniqueQuarters
                                    : "Total",
                                },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumberACV}
                            colSpan={1}
                          >
                            {props.avgDealSize
                              ? _isNaN(
                                  props.winAcvData
                                    .filter((item) => item[props.type] === row)
                                    .map((item) => item.acv)
                                    .reduce((a, b) => a + b, 0) /
                                    props.winAcvData
                                      .filter(
                                        (item) => item[props.type] === row
                                      )
                                      .map((item) => item.count)
                                      .reduce((a, b) => a + b, 0)
                                )
                                ? "-"
                                : `$${d3.format(",")(
                                    Math.round(
                                      props.winAcvData
                                        .filter(
                                          (item) => item[props.type] === row
                                        )
                                        .map((item) => item.acv)
                                        .reduce((a, b) => a + b, 0) /
                                        props.winAcvData
                                          .filter(
                                            (item) => item[props.type] === row
                                          )
                                          .map((item) => item.count)
                                          .reduce((a, b) => a + b, 0)
                                    )
                                  )}`
                              : `${
                                  _isNaN(
                                    Math.round(
                                      (props.winAcvData
                                        .filter(
                                          (item) => item[props.type] === row
                                        )
                                        .map((item) => item.acv)
                                        .reduce((a, b) => a + b, 0) /
                                        props.winAcvData
                                          .map((item) => item.acv)
                                          .reduce((a, b) => a + b, 0)) *
                                        100
                                    )
                                  )
                                    ? "-"
                                    : Math.round(
                                        (props.winAcvData
                                          .filter(
                                            (item) => item[props.type] === row
                                          )
                                          .map((item) => item.acv)
                                          .reduce((a, b) => a + b, 0) /
                                          props.winAcvData
                                            .map((item) => item.acv)
                                            .reduce((a, b) => a + b, 0)) *
                                          100
                                      )
                                }%`}
                          </TableCell>
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow key={"MidMarket"} className={classes.fontBold}>
                  <TableCell
                    align="left"
                    // className={classes.repText}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repText}`
                        : classes.repText
                    }
                    style={
                      isSticky && (countinc + 1) % 2 === 1
                        ? { background: "#f5f5f5" }
                        : {}
                    }
                    colSpan={1}
                  >
                    Mid-Market / SMB
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    return (
                      <React.Fragment>
                        <TableCell
                          align="right"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Mid-Market / SMB", cell },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {countOrACVFormatter(
                            tierBSegment
                              .filter((item) => item[props.quarter] === cell)
                              .reduce((a, b) => a + b.count, 0),
                            false
                          )}
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Mid-Market / SMB", cell },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          align="right"
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {countOrACVFormatter(
                            tierBSegment
                              .filter((item) => item[props.quarter] === cell)
                              .reduce((a, b) => a + b.acv, 0),
                            true
                          )}
                        </TableCell>

                        <TableCell
                          align="right"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Mid-Market / SMB", cell },
                              "Acct_Segment_Rollup",
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {props.avgDealSize
                            ? _isNaN(
                                tierBSegment
                                  .filter(
                                    (item) => item[props.quarter] === cell
                                  )
                                  .reduce((a, b) => a + b.acv, 0) /
                                  tierBSegment
                                    .filter(
                                      (item) => item[props.quarter] === cell
                                    )
                                    .reduce((a, b) => a + b.count, 0)
                              )
                              ? "_"
                              : countOrACVFormatter(
                                  tierBSegment
                                    .filter(
                                      (item) => item[props.quarter] === cell
                                    )
                                    .reduce((a, b) => a + b.acv, 0) /
                                    tierBSegment
                                      .filter(
                                        (item) => item[props.quarter] === cell
                                      )
                                      .reduce((a, b) => a + b.count, 0),
                                  true
                                )
                            : `${
                                _isNaN(
                                  Math.round(
                                    (tierBSegment
                                      .filter(
                                        (item) => item[props.quarter] === cell
                                      )
                                      .reduce((a, b) => a + b.acv, 0) /
                                      props.winAcvData
                                        .filter(
                                          (item) => item[props.quarter] === cell
                                        )
                                        .reduce((a, b) => a + b.acv, 0)) *
                                      100
                                  )
                                )
                                  ? "-"
                                  : Math.round(
                                      (tierBSegment
                                        .filter(
                                          (item) => item[props.quarter] === cell
                                        )
                                        .reduce((a, b) => a + b.acv, 0) /
                                        props.winAcvData
                                          .filter(
                                            (item) =>
                                              item[props.quarter] === cell
                                          )
                                          .reduce((a, b) => a + b.acv, 0)) *
                                        100
                                    ) + "%"
                              }`}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              row: "Mid-Market / SMB",
                              cell: props.isMonth ? uniqueQuarters : "Total",
                            },
                            "Acct_Segment_Rollup",
                            props.quarter
                          )
                        }
                        colSpan={1}
                      >
                        {countOrACVFormatter(
                          tierBSegment.reduce((a, b) => a + b.count, 0),
                          false
                        )}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              row: "Mid-Market / SMB",
                              cell: props.isMonth ? uniqueQuarters : "Total",
                            },
                            "Acct_Segment_Rollup",
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {countOrACVFormatter(
                          tierBSegment.reduce((a, b) => a + b.acv, 0),
                          true
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              row: "Mid-Market / SMB",
                              cell: props.isMonth ? uniqueQuarters : "Total",
                            },
                            "Acct_Segment_Rollup",
                            props.quarter
                          )
                        }
                        className={classes.repNumber}
                        colSpan={1}
                      >
                        {props.avgDealSize
                          ? _isNaN(
                              tierBSegment.reduce((a, b) => a + b.acv, 0) /
                                tierBSegment.reduce((a, b) => a + b.count, 0)
                            )
                            ? "-"
                            : countOrACVFormatter(
                                tierBSegment.reduce((a, b) => a + b.acv, 0) /
                                  tierBSegment.reduce((a, b) => a + b.count, 0),
                                true
                              )
                          : `${Math.round(
                              (tierBSegment.reduce((a, b) => a + b.acv, 0) /
                                props.winAcvData.reduce(
                                  (a, b) => a + b.acv,
                                  0
                                )) *
                                100
                            )}%`}
                      </TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>
                {tierBUniqueStages.map((row, i) => {
                  countinc = countinc + 1;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // style={{ paddingLeft: 25 }}
                        // className={classes.repText}
                        className={
                          isSticky
                            ? `${stickyClasses.sticky} ${classes.repText}`
                            : classes.repText
                        }
                        style={
                          isSticky && i % 2 === 1
                            ? { background: "#f5f5f5", paddingLeft: "25px" }
                            : { paddingLeft: "25px" }
                        }
                        colSpan={1}
                      >
                        {row}
                      </TableCell>
                      {uniqueQuarters.map((cell, j) => {
                        const cellData = props.winAcvData.filter(
                          (item) =>
                            item[props.quarter] === cell &&
                            item[props.type] === row
                        )[0];
                        return (
                          <React.Fragment key={j}>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row, cell },
                                  props.type,
                                  props.quarter
                                )
                              }
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {cellData !== undefined
                                ? `${d3.format(",")(cellData.count)}`
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row, cell },
                                  props.type,
                                  props.quarter
                                )
                              }
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {cellData !== undefined
                                ? `$${d3.format(",")(Math.round(cellData.acv))}`
                                : "-"}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer", fontWeight: 600 }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row, cell },
                                  props.type,
                                  props.quarter
                                )
                              }
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {cellData !== undefined
                                ? props.avgDealSize
                                  ? _isNaN(cellData.avgAcv)
                                    ? "-"
                                    : `$${d3.format(",")(
                                        Math.round(cellData.avgAcv)
                                      )}`
                                  : `${
                                      _isNaN(
                                        Math.round(
                                          (cellData.acv /
                                            props.winAcvData
                                              .filter(
                                                (item) =>
                                                  item[props.quarter] === cell
                                              )
                                              .map((item) => item.acv)
                                              .reduce((a, b) => a + b, 0)) *
                                            100
                                        )
                                      )
                                        ? "-"
                                        : `${Math.round(
                                            (cellData.acv /
                                              props.winAcvData
                                                .filter(
                                                  (item) =>
                                                    item[props.quarter] === cell
                                                )
                                                .map((item) => item.acv)
                                                .reduce((a, b) => a + b, 0)) *
                                              100
                                          )}%`
                                    }`
                                : "-"}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                      {uniqueQuarters.length > 1 && (
                        <React.Fragment>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  row,
                                  cell: props.isMonth
                                    ? uniqueQuarters
                                    : "Total",
                                },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >{`${d3.format(",")(
                            props.winAcvData
                              .filter((item) => item[props.type] === row)
                              .map((item) => item.count)
                              .reduce((a, b) => a + b, 0)
                          )}`}</TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  row,
                                  cell: props.isMonth
                                    ? uniqueQuarters
                                    : "Total",
                                },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >{`$${d3.format(",")(
                            Math.round(
                              props.winAcvData
                                .filter((item) => item[props.type] === row)
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0)
                            )
                          )}`}</TableCell>
                          <TableCell
                            style={{ cursor: "pointer", fontWeight: 600 }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  row,
                                  cell: props.isMonth
                                    ? uniqueQuarters
                                    : "Total",
                                },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumberACV}
                            colSpan={1}
                          >
                            {props.avgDealSize
                              ? _isNaN(
                                  props.winAcvData
                                    .filter((item) => item[props.type] === row)
                                    .map((item) => item.acv)
                                    .reduce((a, b) => a + b, 0) /
                                    props.winAcvData
                                      .filter(
                                        (item) => item[props.type] === row
                                      )
                                      .map((item) => item.count)
                                      .reduce((a, b) => a + b, 0)
                                )
                                ? "_"
                                : `$${d3.format(",")(
                                    Math.round(
                                      props.winAcvData
                                        .filter(
                                          (item) => item[props.type] === row
                                        )
                                        .map((item) => item.acv)
                                        .reduce((a, b) => a + b, 0) /
                                        props.winAcvData
                                          .filter(
                                            (item) => item[props.type] === row
                                          )
                                          .map((item) => item.count)
                                          .reduce((a, b) => a + b, 0)
                                    )
                                  )}`
                              : `${
                                  _isNaN(
                                    Math.round(
                                      (props.winAcvData
                                        .filter(
                                          (item) => item[props.type] === row
                                        )
                                        .map((item) => item.acv)
                                        .reduce((a, b) => a + b, 0) /
                                        props.winAcvData
                                          .map((item) => item.acv)
                                          .reduce((a, b) => a + b, 0)) *
                                        100
                                    )
                                  )
                                    ? "-"
                                    : Math.round(
                                        (props.winAcvData
                                          .filter(
                                            (item) => item[props.type] === row
                                          )
                                          .map((item) => item.acv)
                                          .reduce((a, b) => a + b, 0) /
                                          props.winAcvData
                                            .map((item) => item.acv)
                                            .reduce((a, b) => a + b, 0)) *
                                          100
                                      )
                                }%`}
                          </TableCell>
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    align="left"
                    // className={classes.repText}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repText}`
                        : classes.repText
                    }
                    style={
                      isSticky && countinc % 2 === 1
                        ? { background: "#f5f5f5" }
                        : {}
                    }
                    colSpan={1}
                  >
                    Total
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    const totalAcv = props.winAcvData
                      .filter((item) => item[props.quarter] === cell)
                      .map((item) => item.acv)
                      .reduce((a, b) => a + b, 0);
                    const totalOpps = props.winAcvData
                      .filter((item) => item[props.quarter] === cell)
                      .map((item) => item.count)
                      .reduce((a, b) => a + b, 0);
                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Total", cell },
                              props.type,
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {totalOpps !== undefined
                            ? `${d3.format(",")(totalOpps)}`
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Total", cell },
                              props.type,
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {totalAcv !== undefined
                            ? `$${d3.format(",")(Math.round(totalAcv))}`
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer", fontWeight: 600 }}
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Total", cell },
                              props.type,
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {totalAcv !== undefined
                            ? props.avgDealSize
                              ? _isNaN(totalAcv / totalOpps)
                                ? "-"
                                : `$${d3.format(",")(
                                    Math.round(totalAcv / totalOpps)
                                  )}`
                              : `100%`
                            : "-"}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              row: "Total",
                              cell: props.isMonth ? uniqueQuarters : "Total",
                            },
                            props.type,
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >{`${d3.format(",")(
                        props.winAcvData
                          .map((item) => item.count)
                          .reduce((a, b) => a + b, 0)
                      )}`}</TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              row: "Total",
                              cell: props.isMonth ? uniqueQuarters : "Total",
                            },
                            props.type,
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                      >{`$${d3.format(",")(
                        Math.round(
                          props.winAcvData
                            .map((item) => item.acv)
                            .reduce((a, b) => a + b, 0)
                        )
                      )}`}</TableCell>
                      <TableCell
                        style={{ cursor: "pointer", fontWeight: 600 }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              row: "Total",
                              cell: props.isMonth ? uniqueQuarters : "Total",
                            },
                            props.type,
                            props.quarter
                          )
                        }
                        align="right"
                        className={classes.repNumberACV}
                        colSpan={1}
                      >
                        {props.avgDealSize
                          ? _isNaN(
                              props.winAcvData
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0) /
                                props.winAcvData
                                  .map((item) => item.count)
                                  .reduce((a, b) => a + b, 0)
                            )
                            ? "-"
                            : `$${d3.format(",")(
                                Math.round(
                                  props.winAcvData
                                    .map((item) => item.acv)
                                    .reduce((a, b) => a + b, 0) /
                                    props.winAcvData
                                      .map((item) => item.count)
                                      .reduce((a, b) => a + b, 0)
                                )
                              )}`
                          : `100%`}
                      </TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
