import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import YTDSegmentTable from "./YTDSegmentTable";
import D3RepPerformanceBarWins from "../RepPerformanceAnalysis/Charts/D3RepPerformanceBarFYWins";
import Legend from "../AsOfDate/Legend";
import D3RepPerformanceStack from "../RepPerformanceAnalysis/Charts/D3RepPerformanceStack";
import { nearestCompletedFiscalQuarter } from "../../util/fiscalQuarterFunctions";
import addHeaderdescription from "../../components/HeaderDescription";
const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    color: "inherit",
  },
  table1: {
    background: "#FAFAFA",
    border: "none",
    width: 5,
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    color: "white",
    background: "#4472C4",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 5px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});
class SegmentPerformance extends Component {
  filters = ["closed_fiscal_quarter"];
  state = {
    year: ["all"],
    month: ["all"],
    quarter: ["all"],
    closed_fiscal_quarter: ["2021"],
    disabled: ["year", "month", "quarter"],
  };
  componentDidMount = () => {
    this.props.getSegmentPerformanceFilters(this.props.company);
    window.addEventListener("resize", this.onResize);
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      this.setState({ ...prevState, closed_fiscal_quarter: ["2021"] });
    }
    if (this.props.SegmentPerformanceData !== "") {
      D3RepPerformanceBarWins.destroy(this._rootNode1);
      D3RepPerformanceStack.destroy(this._rootNode2);
      this._chart1 = D3RepPerformanceBarWins.create(this._rootNode1, {
        data: this.props.SegmentPerformanceData,
        type: "Segment",
      });
      this._chart2 = D3RepPerformanceStack.create(this._rootNode2, {
        data: this.props.SegmentPerformanceData,
        type: "Segment",
      });
    }
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
    D3RepPerformanceBarWins.destroy(this._rootNode1);
  };
  onResize = async () => {
    D3RepPerformanceBarWins.destroy(this._rootNode1);
    D3RepPerformanceStack.destroy(this._rootNode2);
    this._chart1 = D3RepPerformanceBarWins.create(this._rootNode1, {
      data: this.props.SegmentPerformanceData,
      type: "Segment",
    });
    this._chart2 = D3RepPerformanceStack.create(this._rootNode2, {
      data: this.props.SegmentPerformanceData,
      type: "Segment",
    });
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }
  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }
  handleChange = (e) => {
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };
  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));

    this.props.getSegmentPerformanceData(this.props.company, filters);
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        {/* <Grid
          item
          container
          style={{
            marginBottom: 10,
            width: "60%",
            margin: "auto",
            padding: "1rem",
          }}
          xs={10}
        >
          <Grid item xs={12} justify="center">
            <Typography variant="h2" align="center">
              Segment Historical Performance
            </Typography>
          </Grid>
          <Grid item xs={12} justify="left">
            <Typography variant="body1" style={{}}>
              Explore the historical performance by segment teams.
            </Typography>
          </Grid>
        </Grid> */}
        {addHeaderdescription(
          "Segment Historical Performance",
          "Explore the historical performance by segment teams."
        )}
        <Grid
          item
          container
          xs={12}
          style={{ margin: "1rem", paddingLeft: "1rem" }}
        >
          <Grid
            justify="flex-start"
            item
            xs={3}
            container
            component={Paper}
            style={{ marginLeft: "7rem", marginTop: 10, padding: 10 }}
          >
            <Grid item xs={6} sm={12}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel
                  style={{
                    color: !this.state.closed_fiscal_quarter.includes("All")
                      ? "#4472c4"
                      : "#000",
                  }}
                  id="select-vfiscalQuarter-label"
                >
                  Time Frame:
                </InputLabel>
                <Select
                  labelId="select-vfiscalQuarter-label"
                  id="select-cfiscalQuarter"
                  // value={this.state.closed_fiscal_quarter}
                  value={["2021"]}
                  onChange={this.handleChange}
                  label="Closed Fiscal Quarter"
                  name="closed_fiscal_quarter"
                  multiple
                >
                  <MenuItem value={"2021"}>2021 YTD</MenuItem>
                  {/* {this.props.funnelCompareFilters === '' && <MenuItem value={nearestCompletedFiscalQuarter()} >{nearestCompletedFiscalQuarter()}</MenuItem>}
                                    {this.props.funnelCompareFilters !== '' && this.props.funnelCompareFilters.filter(y => y.type === "closed_fiscal_quarter" && y["closed_fiscal_quarter"] !== '')
                                        .map(y => <MenuItem key={y['closed_fiscal_quarter']} value={y['closed_fiscal_quarter']}>{y['closed_fiscal_quarter']}</MenuItem>)} */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: 20,
            }}
          >
            <Button variant="contained" color="primary" onClick={this.handleGo}>
              GO
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Grid
            component={Card}
            justify="center"
            item
            xs={10}
            id="grid-container"
            style={{
              marginBottom: 20,
              overflow: "auto",
              padding: "2rem",
              width: "60%",
            }}
          >
            {this.props.SegmentPerformanceData !== "" &&
            this.props.persistentAlert.message !== "Updating data..." ? (
              // <Typography>Loaded</Typography>
              <YTDSegmentTable data={this.props.SegmentPerformanceData} />
            ) : (
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="80%"
                  style={{ margin: "20px 0px" }}
                />
                <Divider style={{ width: "100%" }} />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={200}
                  style={{ margin: "20px 0px" }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ margin: "2rem" }}>
          <Card style={{ margin: "auto", width: "88%" }} raised={true}>
            <Typography
              variant="h2"
              style={{
                margin: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Contribution to Total
            </Typography>
            {this.props.SegmentPerformanceData !== "" ? (
              <Grid container>
                <Grid item container xs={12}>
                  <div
                    style={{
                      justifyContent: "center",
                      marginTop: 20,
                      margin: "auto",
                      width: "95%",
                    }}
                    className="line-container"
                    ref={this._setRef2.bind(this)}
                  />
                </Grid>
              </Grid>
            ) : (
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} style={{ margin: "2rem" }}>
          <Card style={{ margin: "auto", width: "88%" }} raised={true}>
            <Typography
              variant="h2"
              style={{
                margin: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Performance - Actual vs. Quota
            </Typography>

            {this.props.SegmentPerformanceData !== "" ? (
              <Grid container>
                <Grid item container xs={12}>
                  <div
                    style={{
                      justifyContent: "center",
                      marginTop: 20,
                      margin: "auto",
                      width: "95%",
                    }}
                    className="line-container"
                    ref={this._setRef1.bind(this)}
                  />
                </Grid>
                <Grid
                  item
                  container
                  justify="center"
                  style={{ margin: "2rem" }}
                  xs={12}
                >
                  <Legend
                    square={20}
                    legends={[
                      { text: "Actual", color: "#70AD47" },
                      { text: "Quota", color: "#BF9000" },
                    ]}
                    type={"segment"}
                  />
                </Grid>
              </Grid>
            ) : (
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  const { funnelCompareFilters, RegionPerformanceData, persistentAlert, user } =
    state.app;

  return {
    funnelCompareFilters,
    SegmentPerformanceData: RegionPerformanceData,
    persistentAlert,
    company: user.company,
  };
}
const mapDispatchToProps = (dispatch) => ({
  getSegmentPerformanceData: (company, filters) => {
    dispatch({ type: "get_region_performance_data_request" });
    appService
      .getChartData(
        company === "C0003" ? "C0003.CHID00000016" : `${company}.CHID00000020`,
        filters
      )
      .then(
        (json) => {
          dispatch({ type: "get_region_performance_data_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_region_performance_data_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_region_performance_data_failure", error });
        }
      );
  },
  getSegmentPerformanceFilters: (company) => {
    dispatch({ type: "get_funnelCompare_filters_request" });
    appService
      .getChartFilters(
        company === "C0003" ? "C0003.CHID0000003" : `${company}.CHID0000009`
      )
      .then(
        (json) => {
          dispatch({ type: "get_funnelCompare_filters_success", json });
          dispatch({ type: "get_rep_performance_analysis_data_request" });
          appService
            .getChartData(
              company === "C0003"
                ? "C0003.CHID00000016"
                : `${company}.CHID00000020`,
              json.message.length === 0
                ? []
                : [
                    {
                      name: "closed_fiscal_quarter",
                      value: [
                        json.message.filter(
                          (y) =>
                            y.type === "closed_fiscal_quarter" &&
                            y["closed_fiscal_quarter"] !== ""
                        )[1].closed_fiscal_quarter,
                        json.message.filter(
                          (y) =>
                            y.type === "closed_fiscal_quarter" &&
                            y["closed_fiscal_quarter"] !== ""
                        )[2].closed_fiscal_quarter,
                        json.message.filter(
                          (y) =>
                            y.type === "closed_fiscal_quarter" &&
                            y["closed_fiscal_quarter"] !== ""
                        )[3].closed_fiscal_quarter,
                        json.message.filter(
                          (y) =>
                            y.type === "closed_fiscal_quarter" &&
                            y["closed_fiscal_quarter"] !== ""
                        )[4].closed_fiscal_quarter,
                      ],
                      string: true,
                    },
                  ]
            )
            .then(
              (json) => {
                dispatch({ type: "get_region_performance_data_success", json });
              },
              (error) => {
                if (typeof error === "object")
                  dispatch({
                    type: "get_region_performance_data_failure",
                    error: "Something went wrong",
                  });
                else
                  dispatch({
                    type: "get_region_performance_data_failure",
                    error,
                  });
              }
            );
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_funnelCompare_filters_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_funnelCompare_filters_failure", error });
        }
      );
  },
});

const connectedSegmentPerformance = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SegmentPerformance));
export default connectedSegmentPerformance;
