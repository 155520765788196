import React, { Fragment } from "react";
import ReactGA from "react-ga";
import InsightsCard from "../components/InsightsCard";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../App/app.service";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  IconButton,
  InputAdornment,
} from "@material-ui/core";

import { version } from "../../package.json";
import { tr } from "date-fns/locale";
import Alert from "@material-ui/lab/Alert";
import AccountSettings from "./AccountSettings/AccountSettings";
import QuotaMaintenanceV3 from "./QuotaMaintainence/QuotaMaintainenceV3";
import PlatformInfo from "./PlatformInfo/PlatformInfo";

const styles = (theme) => ({
  dashOuter: {
    display: "flex",
    flexGrow: 1,
    margin: "auto",
  },
  dashInner: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  graph1: {
    flexGrow: 1,
    height: 250,
    margin: 10,
  },
});

class Settings extends React.Component {
  componentDidMount() {
    this.props.getSettings();
  }
  handleLocationChange = (loc) => {
    this.props.changeEnhancedInsightsPage(loc);
  };

  handleLocationChange = (loc) => {
    this.props.changeSettingsPage(loc);
  };

  render() {
    const { classes } = this.props;
    const baseURL = "https://skygeniwebapp.blob.core.windows.net/thumbnails/";
    const charts = [
      {
        title: "Change Password",
        component: (
          <InsightsCard
            header="Change Password"
            text="Change Password"
            image={`${baseURL}Change_Password.PNG`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Quota Maintenance",
        component: (
          <InsightsCard
            header="Quota Maintenance"
            text="Maintain Rep, Team and Company quota"
            image={`${baseURL}Stage Progression Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
    ];

    return (
      <Fragment>
        <div
          className={classes.dashOuter}
          style={
            this.props.location === "home"
              ? { maxWidth: 1100 }
              : { maxWidth: window.innerWidth <= 1368 ? 1200 : 1600 }
          }
        >
          {this.props.location === "home" && (
            <Grid
              container
              className={classes.grid}
              wrap="wrap"
              justify="flex-start"
              alignItems="center"
            >
              {charts !== undefined &&
                this.props.charts.map((it) =>
                  charts.filter((chart) => chart.title === it.Chart_Name)[0] !==
                  undefined
                    ? charts.filter((chart) => chart.title === it.Chart_Name)[0]
                        .component
                    : null
                )}
            </Grid>
          )}
          {this.props.location === "Change Password" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <AccountSettings />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Quota Maintenance" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <QuotaMaintenanceV3 />
              </Grid>
            </Grid>
          )}
        </div>
        {this.props.location === "home" && (
          <Grid container>
            <Grid item xs={12} container justifyContent="center">
              <PlatformInfo />
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { persistentAlert, alert, settings, version, settingsPage, user } =
    state.app;

  return {
    persistentAlert,
    location: settingsPage,
    alert,
    settings,
    version,
    charts: user.elements
      .filter((item) => item.navItem === "Settings")[0]
      .charts.sort((a, b) => a.Sequence - b.Sequence),
  };
}

const mapDispatchToProps = (dispatch) => ({
  changePass: (oldpassword, newpassword) => {
    dispatch({ type: "change_pass_loggedin_request" });
    appService.changePassLoggedIn(oldpassword, newpassword).then(
      (json) => {
        dispatch({ type: "change_pass_loggedin_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "change_password_loggedin_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "change_password_loggedin_failure", error });
      }
    );
  },
  getSettings: () => {
    dispatch({ type: "get_settings_request" });
    appService.getSettings().then(
      (json) => {
        dispatch({ type: "get_settings_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_settings_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_settings_failure", error });
      }
    );
  },
  changeSettingsPage: (loc) => {
    ReactGA.pageview(loc);
    dispatch({ type: "change_SettingsPage", loc });
    localStorage.setItem("skygeni-user-SettingsPage", loc);
    localStorage.removeItem("skygeni-user-repPage");
    localStorage.removeItem("skygeni-user-productPage");
  },
});

const connectedSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Settings));
export { connectedSettings as Settings };
