import { makeStyles } from "@material-ui/core";

const stickytableStyle = makeStyles(
  (theme) => ({
    tableHead: {
      position: "sticky",
      top: -1,
      zIndex: 5,
      background: "white",
    },
    tableRow: {
      fontWeight: 600,
      paddingLeft: 5,
      position: "sticky",
      outline: "lightgrey solid 1.5px",
      background: "white",
      // outlineOffset: "-1.5px",
    },

    // ::pseudo-element logic
    sticky: {
      position: "sticky",
      left: -1,
      background: "white",
      // outline: "0.5px solid lightgrey",
      // outlineOffset: "-1.5px",
      //  pseudo-element for the fixed right border
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "0.5px", // Adjust the thickness as needed
        background: "lightgrey", // Adjust the color as needed
      },
    },
  }),
  { index: 1 }
);
export default stickytableStyle;
