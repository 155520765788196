import * as d3 from 'd3'

const D3Funnel = {};

function wrap(text, width) {
	text.each(function () {
		var text = d3.select(this),
			words = text.text().split(/\s+/).reverse(),
			word,
			line = [],
			lineNumber = 0,
			lineHeight = 1.1, // ems
			y = text.attr("y"),
			dy = parseFloat(text.attr("dy")),
			tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
		while (word = words.pop()) {
			line.push(word);
			tspan.text(line.join(" "));
			if (tspan.node().getComputedTextLength() > width) {
				line.pop();
				tspan.text(line.join(" "));
				line = [word];
				tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
			}
		}
	});
}

D3Funnel.create = (el, config) => {


	const handleChangeLocation = (location, stage, name) => {
		config.handleChangeLocation(location, stage, name)
	}

	// D3 Code to create the chart
	if (config.data) {
		config.margin = { top: 30, right: d3.select(el).node().getBoundingClientRect().width < 400 ? 0 : 30, bottom: 50, left: d3.select(el).node().getBoundingClientRect().width < 400 ? 35 : 80 };
		if (!config.width) {
			config.width = d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right;
		}

		config.height = d3.select(el).node().getBoundingClientRect().height - config.margin.top - config.margin.bottom;
		config.padding = 0.3;
		const data = config.data
		const x = d3
			.scaleBand()
			.rangeRound([0, config.width])
			.padding(config.padding);

		const y = d3
			.scaleLinear()
			.range([config.height, 0]);

		const xAxis = d3.axisBottom(x)


		const yAxis = d3.axisLeft(y)
			.tickFormat(function (d) { return `$${d3.format(".2s")(d)}`.toUpperCase(); })

		x.domain(data.map((d) => {
			return d.name;
		}));

		y.domain([
			0,
			d3.max(data, (d) => d.end) + (d3.max(data, (d) => d.end) * 0.2)
		]);

		const chart = d3
			.select(el)
			.append('svg')
			.attr('width', config.width + config.margin.left + config.margin.right)
			.attr('height', config.height + config.margin.top + config.margin.bottom)
			.append('g')
			.attr('transform', `translate(${config.margin.left},${config.margin.top})`);

		chart.append("g")
			.attr("class", "xaxis")
			.attr("transform", "translate(0," + config.height + ")")
			.call(xAxis);

		chart.append("g")
			.attr("class", "yaxis")
			.call(yAxis);

		chart.selectAll(".xaxis text")
			.attr('font-size', '0.85rem')
			.call(wrap, x.bandwidth() + 10)
			.style("cursor", pipeline => config.enableStartingEndingPipeline ? config.company === 'C0001' ? "pointer" : pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline" ? null : "pointer" : pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline" ? null : "pointer")
			.on("click", pipeline => {
				if (config.enableStartingEndingPipeline) {
					if (config.company !== 'C0001') {
						if (!(pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline")) { handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name) }
					} else {
						handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name)
					}
				} else {
					if (!(pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline")) { handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name) }
				}
			})

		chart.selectAll(".yaxis text")
			.attr('font-size', '0.85rem')

		const bar = chart.selectAll('.bar')
			.data(data)
			.enter().append('g')
			.attr('transform', (d) => {
				return `translate(${x(d.name)},0)`;
			});

		bar
			.append('rect')
			.attr('fill', (d) => {
				return d.fill;
			})
			.attr('y', (d) => {
				return y(Math.max(d.start, d.end));
			})
			.attr('height', (d) => {
				return Math.abs(y(d.start) - y(d.end));
			})
			.attr('width', x.bandwidth())
			.style("cursor", pipeline => config.enableStartingEndingPipeline ? config.company === 'C0001' ? "pointer" : pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline" ? null : "pointer" : pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline" ? null : "pointer")
			.on("click", pipeline => {
				if (config.enableStartingEndingPipeline) {
					if (config.company !== 'C0001') {
						if (!(pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline")) { handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name) }
					} else {
						handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name)
					}
				} else {
					if (!(pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline")) { handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name) }
				}
			})

		bar
			.append('rect')
			.attr('fill', (d) => {
				return 'transparent';
			})
			.attr('y', (d) => {
				return y(Math.min(d.start, d.end));
			})
			.attr('height', (d) => {
				return Math.abs(y(0) - y(Math.min(d.start, d.end)));
			})
			.attr('width', x.bandwidth())
			.style("cursor", pipeline => config.enableStartingEndingPipeline ? config.company === 'C0001' ? "pointer" : pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline" ? null : "pointer" : pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline" ? null : "pointer")
			.on("click", pipeline => {
				if (config.enableStartingEndingPipeline) {
					if (config.company !== 'C0001') {
						if (!(pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline")) { handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name) }
					} else {
						handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name)
					}
				} else {
					if (!(pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline")) { handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name) }
				}
			})


		// Add the value on each bar
		bar
			.append('text')
			.attr('text-anchor', 'middle')
			.attr('x', x.bandwidth() / 2)
			.attr('y', (d) => {
				return y(Math.max(d.start, d.end))
			})
			.attr('dy', '-.5em')
			.text((d) => {
				return d.value / 1000 > 10 ? `$${d3.format(",")(Math.round(d.value / 1000))}K` : `$${d3.format(",")(Math.round(d.value))}`;
			})
			.style('text-transform', 'uppercase')
			.style('fill', 'black')
			.attr('font-size', '0.85rem')
			.style('text-transform', 'uppercase')
			.style("cursor", pipeline => config.enableStartingEndingPipeline ? config.company === 'C0001' ? "pointer" : pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline" ? null : "pointer" : pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline" ? null : "pointer")
			.on("click", pipeline => {
				if (config.enableStartingEndingPipeline) {
					if (config.company !== 'C0001') {
						if (!(pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline")) { handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name) }
					} else {
						handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name)
					}
				} else {
					if (!(pipeline.name === "Starting Pipeline" || pipeline.name === "Ending Pipeline")) { handleChangeLocation('drilldownOnGraph', pipeline.type, pipeline.name) }
				}
			})


		chart.append("text")
			.attr("transform", "rotate(-90)")
			.attr("y", 0 - config.margin.left)
			.attr("x", 0 - (config.height / 2))
			.attr("dy", "1em")
			.style("text-anchor", "middle")
			.attr('font-size', '0.85rem')
			.text("ACV");

	}

};

D3Funnel.update = (el, config) => {

};

D3Funnel.destroy = (el) => {
	// Cleaning code here
	d3.select(el).selectAll('svg').remove();
};

export default D3Funnel;