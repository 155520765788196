import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../../App/app.service";
import DataVisualizer from "../../../components/ChartBuilder/DataVisualizer";
import FiscalQuarterFilter from "../../../Filters/FiscalQuarterFilter2";
import {
  getCurrentFiscalQuarter,
  nearestCompletedFiscalQuarter,
} from "../../../util/fiscalQuarterFunctions";
import QuarterTrendsTable from "../../components/QuarterTrendsTable";
const styles = (theme) => ({
  gridItem: {
    margin: "1rem",
  },
});

class RenewalsOverDue extends Component {
  filters = ["closed_fiscal_quarter"];
  state = {
    closed_fiscal_quarter:
      this.props.funnelCompareFilters === ""
        ? [nearestCompletedFiscalQuarter()]
        : ["2021-Q4", "2021-Q3", "2021-Q2"],
    disabled: ["year", "month", "quarter"],
  };

  componentDidMount() {
    this.props.getRenewalsOverDueFilters();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const currentQuarter = getCurrentFiscalQuarter();
      this.setState({
        ...prevState,
        closed_fiscal_quarter: ["2021-Q3", "2021-Q2", "2021-Q1"],
      });
    }
  }
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    this.props.getRenewalsCompletedData(
      this.props.company,
      this.state.wonACVMix,
      filters
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer}>
        <Grid
          className={classes.gridItem}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Renewals Overdue
          </Typography>
          <Typography variant="body1">
            Analyze won ACV across multiple dimensions to spot trends and
            patterns.
          </Typography>
        </Grid>
        <Grid className={classes.gridItem} item container justify="flex-start">
          <FiscalQuarterFilter
            handleChange={this.handleChange}
            funnelCompareFilters={this.props.funnelCompareFilters}
            closed_fiscal_quarter={this.state.closed_fiscal_quarter}
            type="projected_close_fiscal_quarter"
          />

          <Grid
            item
            sm={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" color="primary" onClick={this.handleGo}>
              GO
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.gridItem} item container justify="flex-start">
          {this.props.RenewalsOverDueData !== "" ? (
            <DataVisualizer
              title={`Renewals still open but overdue (Renewal Contract Eff. Dt. occurs in the past)`}
              type={"Account_Type"}
              chartType="overDueAndopen"
              winACVMixAnalysisData={this.props.RenewalsOverDueData.map(
                (item) => ({ ...item, acv: item.CARR })
              )}
              handleChangeLocation={this.handleChangeLocation}
              quarter={"projected_close_fiscal_quarter"}
              page="Renewals"
              unit="acv"
              tableComponent={
                <QuarterTrendsTable
                  data={this.props.RenewalsOverDueData}
                  row="Account_Type"
                  column="projected_close_fiscal_quarter"
                  countKey="count"
                />
              }
            />
          ) : (
            <Grid
              item
              container
              xs={12}
              className={classes.gridItem}
              component={Card}
            >
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const { RenewalsOverDueFilters, RenewalsOverDueData, user } = state.app;

  return {
    funnelCompareFilters: RenewalsOverDueFilters,
    RenewalsOverDueData,
    company: user.company,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRenewalsOverDueFilters: (company) => {
    dispatch({ type: "get_RenewalsOverDue_filters_request" });
    appService.getChartFilters("C0003.CHID00000023").then(
      (json) => {
        dispatch({ type: "get_RenewalsOverDue_filters_success", json });
        dispatch({ type: "get_RenewalsOverDue_data_request" });
        appService
          .getChartData("C0003.CHID00000023", [
            {
              name: "closed_fiscal_quarter",
              value: ["2021-Q4", "2021-Q3", "2021-Q2"],
              string: true,
            },
          ])
          .then(
            (json) => {
              dispatch({ type: "get_RenewalsOverDue_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_RenewalsOverDue_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({ type: "get_RenewalsOverDue_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_RenewalsOverDue_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_RenewalsOverDue_filters_failure", error });
      }
    );
  },
  getRenewalsOverDueData: (company, filters = []) => {
    dispatch({ type: "get_RenewalsOverDue_data_request" });
    appService.getChartData("C0003.CHID00000023", filters).then(
      (json) => {
        dispatch({ type: "get_RenewalsOverDue_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_RenewalsOverDue_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_RenewalsOverDue_data_failure", error });
      }
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RenewalsOverDue));
