import { Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import InsightsCard from "../components/InsightsCard";
import React, { Component } from "react";
import { Fragment } from "react";
import ReactGA from "react-ga";
import AccountHealth from "./Pages/AccountHealth/AccountHealth";
import QuarterlyBusinessReview from "./Pages/QuarterlyBusinessReview/QuarterlyBusinessReview";
import QBROutReach from "./Pages/QBRExecutingOutReach/QBRExecutingOutReach";
import QBRF2F from "./Pages/QBRF2FMeetings/QBRF2FMeetings";
import QBROnBoard from "./Pages/QBROnBoardEvents/QBROnBoardEvents";
import RenewalsCompleted from "./Pages/RenewalsCompleted/RenewalsCompleted";
import RenewalsOverDue from "./Pages/RenewalsOverDue/RenewalsOverDue";
import GetWellSummary from "./Pages/GetWellSummary/GetWellSummary";
import ChangeOverTime from "./Pages/ChangeOverTime/ChangeOverTime";
import AccountHealthByTeam from "./Pages/AccountHealthByTeam/AccountHealthByTeam";
import RenewalsPriceIncrease from "./Pages/RenewalsPriceIncrease/RenewalsPriceIncrease";
import ChurnAnalysis from "./Pages/ChurnAnalysis/ChurnAnalysis";

const styles = (theme) => ({
  dashOuter: {
    display: "flex",
    flexGrow: 1,
    margin: "auto",
  },
  dashInner: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  graph1: {
    flexGrow: 1,
    height: 250,
    margin: 10,
  },
});
class CustomerSuccess extends Component {
  componentDidMount() {}

  handleLocationChange = (loc) => {
    this.props.changeCustomerSuccessPage(loc);
  };
  render() {
    const { classes } = this.props;
    const baseURL = "https://skygeniwebapp.blob.core.windows.net/thumbnails/";
    const charts = [
      {
        title: "Renewals Summary",
        component: (
          <InsightsCard
            header="Renewals Summary"
            text="Explore completed and open renewals by region, account segment over time."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Renewals Summary - Price Increase",
        component: (
          <InsightsCard
            header="Renewals Summary - Price Increase"
            text="Examine completed renewals along with price increases at renewal time."
            image={`${baseURL}Win Rate Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Account Health – Current Status",
        component: (
          <InsightsCard
            header="Account Health - Current Status"
            text="Examine the current health status of accounts by region and segment."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Account Health - Change over time",
        component: (
          <InsightsCard
            header="Account Health - Change over time"
            text="Analyze how the health status of accounts changed over time."
            image={`${baseURL}Win Rate Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Account Health - By Teams",
        component: (
          <InsightsCard
            header="Account Health - By Teams"
            text="Delve into the health status of accounts by team and segment."
            image={`${baseURL}Win Rate Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Account Health - Get Well Summary",
        component: (
          <InsightsCard
            header="Account Health - Get Well Summary"
            text="Analyze plans to improve the health of accounts by team and time frame."
            image={`${baseURL}Win Rate Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "QBRs Completed",
        component: (
          <InsightsCard
            header="QBRs Completed"
            text="Explore the QBRs completed by account segment and CARR."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
      {
        title: "Customer Churn Analysis",
        component: (
          <InsightsCard
            header="Customer Churn Analysis"
            text="Analyze customer churn and Net Retention."
            image={`${baseURL}Mix Analysis.png`}
            changeLocation={this.handleLocationChange}
            width={35}
          />
        ),
      },
    ];

    return (
      <Fragment>
        <div
          className={classes.dashOuter}
          style={
            this.props.location === "home"
              ? { maxWidth: 1100 }
              : { maxWidth: window.innerWidth <= 1368 ? 1200 : 1600 }
          }
        >
          {this.props.location === "home" && (
            <Grid
              container
              className={classes.grid}
              wrap="wrap"
              justify="flex-start"
              alignItems="center"
            >
              {charts !== undefined &&
                this.props.charts.map((it) =>
                  charts.filter((chart) => chart.title === it.Chart_Name)[0] !==
                  undefined
                    ? charts.filter((chart) => chart.title === it.Chart_Name)[0]
                        .component
                    : null
                )}

              {/* {
                charts !== undefined ? charts.filter(item=>this.props.charts.map(chart => chart.Chart_Name).includes(item.title)).map(item=>
                  item.component)
                :'Loading'
              }               */}
            </Grid>
          )}
          {this.props.location === "Account Health - Current Status" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <AccountHealth />
              </Grid>
            </Grid>
          )}
          {this.props.location === "QBRs Completed" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <QuarterlyBusinessReview />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Executive Outreach Events" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <QBROutReach />
              </Grid>
            </Grid>
          )}
          {this.props.location === "F2F Meetings" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <QBRF2F />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Onboarding Events" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <QBROnBoard />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Renewals Summary" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <RenewalsCompleted />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Renewals Summary - Price Increase" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <RenewalsPriceIncrease />
              </Grid>
            </Grid>
          )}

          {this.props.location === "Account Health - Get Well Summary" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <GetWellSummary />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Account Health - Change over time" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <ChangeOverTime />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Account Health - By Teams" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <AccountHealthByTeam />
              </Grid>
            </Grid>
          )}
          {this.props.location === "Customer Churn Analysis" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12} style={{ padding: "0px" }}>
                <ChurnAnalysis />
              </Grid>
            </Grid>
          )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { customerSuccessPage, user } = state.app;

  return {
    location: customerSuccessPage,
    company: user.company,
    charts: user.elements
      .filter((item) => item.navItem === "Customer Success")[0]
      .charts.sort((a, b) => a.Sequence - b.Sequence),
  };
}

const mapDispatchToProps = (dispatch) => ({
  changeCustomerSuccessPage: (loc) => {
    console.log("GA ->", loc);
    ReactGA.pageview(loc);
    dispatch({ type: "change_customerSuccessPage", loc });
    localStorage.setItem("skygeni-user-customerSuccessPage", loc);
    localStorage.removeItem("skygeni-user-repPage");
    localStorage.removeItem("skygeni-user-enhancedPage");
  },
});

const connectedCustomerSuccess = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CustomerSuccess));
export default connectedCustomerSuccess;
