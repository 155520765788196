import React from 'react'
import D3oppChangeTimeLine from './D3oppChangeTimeLine';
import {
  Card, CardHeader, CardContent, Divider, Typography, Grid, Radio, RadioGroup, FormControlLabel, FormLabel
} from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 700,
    minWidth: 500
  },
  chart: {
    height: '100%'
  }
})

class D3GraphoppChange extends React.Component {


//   chartData=[
//     {Change_Date : "1/18/2022 12:54:48",Stage:"Targeted",ACV:null,PCD:'4/1/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'None',PCD_Change_Direction:"None",colors:'none'},
//     {Change_Date : "1/19/2022 11:28:09",Stage:"Targeted",ACV:'65000',PCD:'5/31/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'Forward',PCD_Change_Direction:"Backward",colors:"grey"},
//     {Change_Date : "1/19/2022 11:34:35",Stage:"Qualified",ACV:'65000',PCD:'4/1/2022',Stage_Change_Direction:"Forward",ACV_Change_Direction:'None',PCD_Change_Direction:"Forward",colors:'green2'},
//     {Change_Date : "1/19/2022 11:39:09",Stage:"Qualified",ACV:'65000',PCD:'5/31/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'None',PCD_Change_Direction:"Backward",colors:'org1'},
//     {Change_Date : "2/2/2022 13:54:01",Stage:"Qualified",ACV:'65000',PCD:'3/31/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'None',PCD_Change_Direction:"Forward",colors:'green1'},
//     {Change_Date : "2/10/2022 13:22:44",Stage:"Proposed",ACV:'75600',PCD:'3/31/2022',Stage_Change_Direction:"Forward",ACV_Change_Direction:'Forward',PCD_Change_Direction:"None",colors:'green2'},
//     {Change_Date : "2/11/2022 10:31:59",Stage:"Proposed",ACV:'75600',PCD:'3/18/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'None',PCD_Change_Direction:"Forward",colors:'green1'},
//     {Change_Date : "3/14/2022 12:36:17",Stage:"Proposed",ACV:'75600',PCD:'3/25/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'None',PCD_Change_Direction:"Backward",colors:'org1'},
//     {Change_Date : "3/18/2022 12:49:37",Stage:"Proposed",ACV:'48000',PCD:'3/31/2022',Stage_Change_Direction:"None",ACV_Change_Direction:'Backward',PCD_Change_Direction:"Backward",colors:'org2'},

// ]



    chart2Data=[
        {Stage:'Suspect',Day:0,date_At_Stage:'11/01/2021'},
        {Stage:'Qualify',Day:10,date_At_Stage:'11/11/2021'},
        {Stage:'Demo',Day:30,date_At_Stage:'12/01/2021'},
        {Stage:'Proposal',Day:50,date_At_Stage:'12/21/2021'},
        {Stage:'Negotiate',Day:75,date_At_Stage:'01/11/2022'},
        {Stage:'Won',Day:85,date_At_Stage:'01/21/2022'}
        ]

    // chartData=[
    //     {Stage:'Suspect',acv:10000,close_dt:'03/31/22',date_At_Stage:'01/02/2020',Day:1},
    //     {Stage:'Qualify',acv:15000,close_dt:'03/31/22',date_At_Stage:'11/12/2021',Day:15},
    //     {Stage:'Demo',acv:15000,close_dt:'03/31/22',date_At_Stage:'11/15/2021',Day:25},
    //     {Stage:'Proposal',acv:15000,close_dt:'04/30/22',date_At_Stage:'11/25/2021',Day:45},
    //     // {Stage:'Proposal',acv:15000,close_dt:'04/30/22',date_At_Stage:'11/30/2021',Day:45},
    //     // {Stage:'Qualify',acv:15000,close_dt:'03/31/22',date_At_Stage:'12/10/2021',Day:15},
    //     {Stage:'Demo',acv:15000,close_dt:'03/31/22',date_At_Stage:'12/15/2021',Day:25},
    //     // {Stage:'Demo',acv:15000,close_dt:'03/31/22',date_At_Stage:'12/25/2021',Day:25},
    //     // {Stage:'Demo',acv:15000,close_dt:'03/31/22',date_At_Stage:'12/30/2021',Day:25},
    //     // {Stage:'Demo',acv:500,close_dt:'03/31/22',date_At_Stage:'02/05/2022',Day:25},
    //     // {Stage:'nano',acv:500,close_dt:'03/31/22',date_At_Stage:'03/28/2022',Day:25},
    //     {Stage:'Proposal',acv:15000,close_dt:'04/30/22',date_At_Stage:'11/25/2022',Day:45},
    //     {Stage:'Proposal',acv:15000,close_dt:'04/30/22',date_At_Stage:'11/25/2022',Day:45},




    //     {Stage:'Negotiate',acv:15000,close_dt:'04/30/22',date_At_Stage:'11/30/2023',Day:98},
        
        
    //     ]

  componentDidMount = () => {
    D3oppChangeTimeLine.destroy(this._rootNode1)
    // // D3TimeLine2.destroy(this._rootNode2)
    // D3TimeLine3.destroy(this._rootNode3)
    this._chart1 = D3oppChangeTimeLine.create(this._rootNode1, { data:this.props.data })
    // // this._chart2 = D3TimeLine2.create(this._rootNode2, { data:this.chart2Data })
    // this._chart3 = D3TimeLine3.create(this._rootNode3, { data:this.chartData })
    console.log(this.props.data)

  }

  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
}

_setRef2(componentNode) {
    this._rootNode2 = componentNode;
}

_setRef3(componentNode) {
  this._rootNode3 = componentNode;
}

  render() {
    const { classes } = this.props
    console.log(this.props)
    return (
      <Grid container spacing={2} style={{width:"100%",height:'150px'}}>
        <Grid item container spacing={2} justify='space-around'>
          <Grid item xs={12}>
            
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                                justifyContent: "center",
                                //margin: "auto",
                                //paddingLeft:'1rem',
                                
                                width: "100%",
                                }}
                                className="line-container"
                        ref={this._setRef1.bind(this)}
                    />
                </div>
          </Grid>
          <Grid item container spacing={2} justify='space-around'>
          <Grid item xs={12}>
            
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                                justifyContent: "center",
                                //margin: "auto",
                                //padding:'1rem',
                                width: "100%",
                                }}
                                className="line-container"
                        ref={this._setRef3.bind(this)}
                    />
                </div>
          </Grid>
        </Grid>

        <Grid item container spacing={2} justify='space-around'>
          <Grid item xs={12}>
            
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                                justifyContent: "center",
                                //margin: "auto",
                                //padding:'1rem',
                                width: "100%",
                                }}
                                className="line-container"
                        ref={this._setRef2.bind(this)}
                    />
                </div>
          </Grid>
        </Grid>
      </Grid>
      </Grid>
    )
  }
}


function mapStateToProps(state) {
  const {
    funnelFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate
  } = state.app

  return {
    funnelFilters, waterfallData, persistentAlert, waterfallMinDate, waterfallMaxDate,
  }
}

const mapDispatchToProps = (dispatch) => ({

})

const connectedBar = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(D3GraphoppChange))
export { connectedBar as D3MockupGraphsCycleTime }