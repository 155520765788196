import { Card, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import D3StackedAreaChart from "./D3StackAreaChart";

const StackAreaChartContainer = (props) => {
  let stackAreaContainer;

  useEffect(() => {
    stackAreaContainer = document.getElementById(props.id);
    window.addEventListener("resize", drawChart);
    return () => window.removeEventListener("resize", drawChart);
  }, []);

  useEffect(() => {
    stackAreaContainer = document.getElementById(props.id);
    window.addEventListener("resize", drawChart);
    drawChart();
    return () => window.removeEventListener("resize", drawChart);
  }, [
    props.id,
    props.cumulativeData,
    props.cumulativeGoalData,
    props.type,
    props.quarter,
    props.uniqueQuarters,
  ]);

  const drawChart = async () => {
    var _chart21;

    D3StackedAreaChart.destroy(stackAreaContainer);
    _chart21 = D3StackedAreaChart.create(stackAreaContainer, {
      data: props.cumulativeData,
      goalData: props.cumulativeGoalData,
      type: props.type,
      quarter: props.quarter,
      uniqueQuarters: props.uniqueQuarters,
    });
  };

  return (
    <Grid container item justifyContent="center" alignItems="center">
      <div
        id={props.id}
        style={{ padding: "1rem", marginBottom: "1rem", width: "95%" }}
      ></div>
    </Grid>
  );
};

export default StackAreaChartContainer;
