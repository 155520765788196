import {
    CardContent,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@material-ui/core";
  import PerfectScrollbar from "react-perfect-scrollbar";
  import React, { useState } from "react";
  import {
    getUniqueValues,
    _isNaN,
    countOrACVFormatter,
    percentFormatter,
    dateFormatterMomentV4,
  } from "../../util/customFunctions";
  import { getMixKey } from "../../util/generateHeadings";
  
  import StyledTableRow from "../../components/StyledTableRow";
  import * as d3 from "d3";
  import { deSnake } from "../../util/dataFormatter";
  import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
  // import RepBenchMarkExcelExportButton from "../../components/xlsx-export/RepBenchMark";
  const useStyle = makeStyles((theme) => ({
    root: {},
    content: { width: "100%" },
    table: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      width: "100%",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 7px",
      color: "inherit",
      fontWeight: "inherit",
    },
    segmentHeader: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
    },
    repHeader: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
    },
    repHeader1: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      fontWeight: "inherit",
    },
    repText: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      textAlign: "left",
      minWidth: "169px",
    },
    repNumber: {
      border: "0.5px solid lightgrey",
      padding: "5px",
      color: "inherit",
      textAlign: "right",
    },
    fontBold: {
      fontWeight: 600,
    },
    tableColWidth: {
      minWidth: 90,
    },
    drilldownCell: {
      cursor: "pointer",
    },
  }));
  export default function EnsembleModelTable(props) {
    const classes = useStyle();
    const tableRef = React.createRef();
const valueA = 50
const valueB = 50
    const [editA, setEditA] = useState(50);
  const [targetA, setTargetA] = useState(50);
  const [editB, setEditB] = useState(50);
  const [targetB, setTargetB] = useState(50);
  const [toggleA, setToggleA] = useState(true);
  const [toggleB, setToggleB] = useState(true);

    const handleChangeA = (e) => {
        // console.log(e.target.value);
        let newValue = e.target.value;

        // Convert the entered value to a number
        newValue = parseInt(newValue);
      
        // Check if the entered value is negative
        if (newValue < 0) {
          // If negative, set the value to 0 or any other desired behavior
          newValue = 0; // Set to 0 as an example, you can use a different behavior if needed
        }
      
        // Update the value
        setEditA(newValue);
      };
    
      const toggleInputA = () => {
        setToggleA(false);
      };
    
      const onSaveA = (target) => {
        // console.log(target)
        const val = parseFloat(target) > 100 ? 100 : parseFloat(target);
        setEditA(parseFloat(val));
        setTargetB(parseFloat((100 - val)));
        setTargetA(parseFloat(val));
        setEditB(parseFloat((100 - val)));
        setToggleA(true);
      };


      const handleChangeB = (e) => {
        let newValue = e.target.value;

        // Convert the entered value to a number
        newValue = parseInt(newValue);
      
        // Check if the entered value is negative
        if (newValue < 0) {
          // If negative, set the value to 0 or any other desired behavior
          newValue = 0; // Set to 0 as an example, you can use a different behavior if needed
        }
      
        // Update the value
        setEditB(newValue);
      };
    
      const toggleInputB = () => {
        setToggleB(false);
      };
    
      const onSaveB = (target) => {
        const val = parseFloat(target) > 100 ? 100 : parseFloat(target);
        setTargetA(parseFloat((100 - val)));
        setEditB(parseFloat(val));
        setEditA(parseFloat((100 - val)));
        setTargetB(parseFloat(val));
        setToggleB(true);
      };
  
    // console.log(props.showDifference, props, 'Difference')
    const ast = x => <><span>{x}</span> <span style={{ color: "#ed7d31" }}>*</span></>
    return (
      <div
        className={classes.root}
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CardContent className={classes.content} style={{ padding: 10 }}>
          <Grid container justify="center">
            <TableContainer style={{ width: "auto" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
              <Table
                ref={tableRef}
                data-test={"pipeline"}
                size="small"
                aria-label="html table"
                style={{ tableLayout: "auto", width: "initial" }}
              >
                <TableHead>
                  <TableRow
                    style={{ fontWeight: 600, paddingLeft: 5, height: 50 }}
                  >
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={3}
                    >
                      As of{" "}
                      {props.quarterLastDate !== undefined
                        ? dateFormatterMomentV4(props.quarterLastDate)
                        : ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={3}
                      style={{
                        background: "#5b9bd5",
                        color: "white",
                        minWidth: "40px",
                        maxWidth: "200px",
                      }}
                    >
                      80% confidence
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ fontWeight: 600 }}>
                  <TableCell
                      align="center"
                      className={`${classes.repHeader1} ${classes.fontBold}`}
                      colSpan={2}
                    >
                      Model
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${classes.repHeader1} ${classes.fontBold}`}
                      colSpan={1}
                    >
                      Weight
                    </TableCell>
                    <React.Fragment>
                      <TableCell
                        align="center"
                        className={classes.repHeader1}
                        colSpan={1}
                        style={{ minWidth: 100 }}
                      >
                        Low
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader1}
                        colSpan={1}
                        style={{ minWidth: 100 }}
                      >
                        Midpoint
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader1}
                        colSpan={1}
                        style={{ minWidth: 100 }}
                      >
                        High
                      </TableCell>
                    </React.Fragment>
                  </TableRow>
                </TableHead>
                <TableBody>
                <StyledTableRow key={123243457535435}>
                <TableCell
                              align="left"
                              className={`${classes.repText} ${classes.fontBold}`}
                              colSpan={2}
                            >
                              Time Series model (Top down based on historical macro factors)
                            </TableCell>
                            <TableCell
                    align="right"
                    className={classes.repHeader1}
                    colSpan={1}
                    style={{
                      border: "2px solid black",
                      width: '5rem',
                      background:
                        parseFloat(targetA) < parseFloat(valueA)
                          ? "#FFA257"
                          : parseFloat(targetA) > parseFloat(valueA)
                          ? "#9ACB77"
                          : "",
                    }}
                    onDoubleClick={toggleInputA}
                  >
                    {toggleA ? (
                      <span>{`${targetA}%`}</span>
                    ) : (
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          width: "4rem",
                          background: "none",
                        }}
                        type="number"
                        autoFocus
                        value={editA}
                        onChange={handleChangeA}
                        onBlur={() => onSaveA(editA)}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            onSaveA(editA);
                          }
                        }}
                        min={0}
                      />
                    )}
                  </TableCell>
                  <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                          >
                            {![null,undefined,''].includes(props.topDownData[0]["low"])
                              ? countOrACVFormatter(Math.round(props.topDownData[0]["low"]), true)
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                          >
                            {![null,undefined,''].includes(props.topDownData[0]["mid"])
                              ? countOrACVFormatter(Math.round(props.topDownData[0]["mid"]), true)
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                          >
                            {![null,undefined,''].includes(props.topDownData[0]["high"])
                              ? countOrACVFormatter(Math.round(props.topDownData[0]["high"]), true)
                              : "-"}
                          </TableCell>
                    </StyledTableRow>
                    <StyledTableRow key={1232434575354355}>
                <TableCell
                              align="left"
                              className={`${classes.repText} ${classes.fontBold}`}
                              colSpan={2}
                            >
                              Predictive Yield model (Bottom up based on health of each opp)
                            </TableCell>
                            <TableCell
                    align="right"
                    className={classes.repHeader1}
                    colSpan={1}
                    style={{
                      border: "2px solid black",
                      width: '5rem',
                      background:
                        parseFloat(targetB) < parseFloat(valueB)
                          ? "#FFA257"
                          : parseFloat(targetB) > parseFloat(valueB)
                          ? "#9ACB77"
                          : "",
                    }}
                    onDoubleClick={toggleInputB}
                  >
                    {toggleB ? (
                      <span>{`${targetB}%`}</span>
                    ) : (
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          width: "4rem",
                          background: "none",
                        }}
                        type="number"
                        autoFocus
                        value={editB}
                        onChange={handleChangeB}
                        onBlur={() => onSaveB(editB)}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            onSaveB(editB);
                          }
                        }}
                        min={0}
                      />
                    )}
                  </TableCell>
                  <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                          >
                            {![null,undefined,''].includes(props.bottomUpData[0]["projectedAttainmentLow"])
                              ? countOrACVFormatter(Math.round(Math.round(props.bottomUpData[0]["projectedAttainmentLow"] / 10000) * 10000), true)
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                          >
                            {![null,undefined,''].includes(props.bottomUpData[0]["projectedAttainmentMid"])
                              ? countOrACVFormatter(Math.round(Math.round(props.bottomUpData[0]["projectedAttainmentMid"] / 10000) * 10000), true)
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                          >
                            {![null,undefined,''].includes(props.bottomUpData[0]["projectedAttainmentHigh"])
                              ? countOrACVFormatter(Math.round(Math.round(props.bottomUpData[0]["projectedAttainmentHigh"] / 10000) * 10000), true)
                              : "-"}
                          </TableCell>
                    </StyledTableRow>
                    <StyledTableRow key={12324345753543556}>
                <TableCell
                              align="left"
                              className={`${classes.repText} ${classes.fontBold}`}
                              colSpan={2}
                            >
                              Total
                            </TableCell>
                            <TableCell
                    align="right"
                    className={classes.repHeader1}
                    colSpan={1}
                  >
                    100%
                  </TableCell>
                  <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                            style={{
                                height: 50,
                                fontWeight: 600,
                              }}
                          >
                            {![null,undefined,''].includes(props.topDownData[0]["low"] && ![null,undefined,''].includes(props.bottomUpData[0]["projectedAttainmentLow"]))
                              ? countOrACVFormatter(Math.round((props.topDownData[0]["low"] * (targetA/100)) + (Math.round(props.bottomUpData[0]["projectedAttainmentLow"] / 10000) * 10000) * (targetB/100)), true)
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                            style={{
                                background: "#C6e0b4",
                                height: 50,
                                fontWeight: 600,
                              }}
                          >
                           {![null,undefined,''].includes(props.topDownData[0]["mid"] && ![null,undefined,''].includes(props.bottomUpData[0]["projectedAttainmentMid"]))
                              ? countOrACVFormatter(Math.round((props.topDownData[0]["mid"] * (targetA/100)) + (Math.round(props.bottomUpData[0]["projectedAttainmentMid"] / 10000) * 10000) * (targetB/100)), true)
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                            style={{
                                height: 50,
                                fontWeight: 600,
                              }}
                          >
                            {![null,undefined,''].includes(props.topDownData[0]["high"] && ![null,undefined,''].includes(props.bottomUpData[0]["projectedAttainmentHigh"]))
                              ? countOrACVFormatter(Math.round((props.topDownData[0]["high"] * (targetA/100)) + (Math.round(props.bottomUpData[0]["projectedAttainmentHigh"] / 10000) * 10000) * (targetB/100)), true)
                              : "-"}
                          </TableCell>
                    </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      </div>
    );
  }
  