import React from "react";
import DataTable from "./DataTable";
import { Card, Grid, Typography, makeStyles } from "@material-ui/core";
import StackAreaChartContainer from "./StackAreaChartContainer";
import { getUniqueValues } from "../../util/customFunctions";

const styles = makeStyles((theme) => ({
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
}));

const BuildingPipelineCard = (props) => {
  const classes = styles();
  return (
    <Grid key={"ixixwer"} className={classes.grid} item xs={12}>
      <Grid container component={Card} raised>
        <Grid item style={{ padding: "1rem" }} xs={12}>
          <Typography align="center" variant="h2" style={{ margin: "0.5rem" }}>
            {props.title}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          // style={{ paddingRight: "5rem" }}
          // justify="flex-start"
          justifyContent="center"
          container
        >
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            xs={12}
            md={
              props.data.uniqueTimeFrames
                .filter(getUniqueValues)
                .filter((f) => f !== "Total").length <= 4
                ? 8
                : 12
            }
          >
            <StackAreaChartContainer
              cumulativeData={props.data.commulativeACVData}
              cumulativeGoalData={props.data.comulativeGoalData}
              type={props.data.type}
              quarter={props.data.timeFrame}
              uniqueQuarters={props.data.uniqueTimeFrames}
              acvCol="totalActual"
              id={`stackAreaChart${props.ind}`}
            />
          </Grid>

          <DataTable
            data={props.data.data}
            columns={props.data.uniqueTimeFrames.filter(getUniqueValues)}
            rows={props.data.uniqueCols}
            splits={props.data.uniqueSplits}
            gb={props.data.col}
            timeframe={props.data.timeFrame}
            type={props.data.type}
            handleChangeLocation={props.handleChangeLocation}
            dataTest={`Building-${props.data.type}`}
            tableID={`Pipeline_Build_By_${props.data.type === 'Opportunity_Record_Type' ? 'Channel': props.data.type}_table`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BuildingPipelineCard;
