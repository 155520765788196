import React from "react";
import { Grid, Typography } from "@material-ui/core";

const addHeaderdescription = (header, description) => {
  console.log("headerdescription", description, header);
  return (
    <Grid item xs={12} style={{ marginBottom: 20 }}>
      <Typography
        variant="h2"
        align="center"
        style={{ marginBottom: "0.313rem" }}
      >
        {`${header}`}
      </Typography>
      <Typography variant="body1" align="center">
        {`${description}`}
      </Typography>
    </Grid>
  );
};

export default addHeaderdescription;
