import { Card,Typography,Grid} from '@material-ui/core'
import React,{useEffect,useState} from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { connect } from 'react-redux'
import { appService } from '../../App/app.service';
import AttainmentTable from './AttainmentTable';
import Drilldown from './Drilldown';
import SingleLevelDrilldown from '../../components/Drilldown/SingleLevelDrilldown';
import AttainmentTableACVRange from './AttainmnetTableACVRange';


const styles = theme => ({
    gridItem: {
        width:'100%',
        padding:'1rem'
      }
})

const classes=styles()

const Attainment = (props) => {
    const [location, setlLocation] = useState('');
    const [filtersForDrilldown, setFiltersForDrilldown] = useState([]);

    useEffect(() => {
        console.log(props.filters, props.filters === undefined || props.filters === null, !([undefined, null].includes(props.filters)))
       if(!([undefined, null, ""].includes(props.filters))){
        props.getAttainmentData(props.filters,props.type)
       }
      }, [props.filters])

      const handleChangeLocationBack = () => {
        setlLocation('')
      }

      const handleChangeLocation = (location,bookingTypes,team) => {
        var filters=[...props.filters]
        let bookValue = bookingTypes
        let nonRenewals = props[`AttainmentData${props.type}`].nonRenewals
        if (nonRenewals){
          if(bookingTypes.length > 1){
            bookValue = bookingTypes.includes('Non Renewals') ? [...nonRenewals, ...bookingTypes.filter(c => ![...nonRenewals, 'Non Renewals'].includes(c))] : bookingTypes
          }else{
            bookValue = bookingTypes.includes('Non Renewals') ? [...nonRenewals] : bookingTypes
          }
        }
        // console.log(bookValue)
        filters=[...filters, {name: "Booking_Type_NL_CS_US",value: bookValue }]
        var drilldownfilter= [{ title: nonRenewals ? 'Sales Type' : 'Opportunity Type' ,value:bookingTypes.length>1 ? ['All'] : bookValue}]
        if(team !== undefined && team.length > 0) {
            var filter=props.selectedTeam.includes('All') && props.selectedRep.includes('All') ?{name:'Team',value:team} : {name:'Owner_Name',value:team}
            filters=[...filters, filter]
            drilldownfilter=[{title:props.selectedTeam.includes('All') && props.selectedRep.includes('All') ? 'Team' : 'Rep',value:team},...drilldownfilter]
        }
        setlLocation(location)
        setFiltersForDrilldown(drilldownfilter)
        props.getAttainmentSectionDrilldown(filters, props.type)
      }

    return (
        <Grid container justify="flex-start" >
            <Grid item xs={12} >
            {
                 props[`AttainmentData${props.type}`] !== '' ?
                <Grid item xs={12} style={props.isrepScoreCard ? {width: '80%'} : {}}>
                    <Typography variant='h2' style={{ width: '100%', margin: 'auto'}} align="center">{props.tableName}</Typography>
                    <Typography variant='body1' style={{ width: '100%', margin: 'auto', 'padding-bottom': "0.25rem",  fontWeight: 600 }} align="center">({props[`AttainmentData${props.type}`].fiscalQuarters !== undefined ?  Array.isArray(props[`AttainmentData${props.type}`].fiscalQuarters) ? props[`AttainmentData${props.type}`].fiscalQuarters.sort().join(', ') : props[`AttainmentData${props.type}`].fiscalQuarters : ''})</Typography>                    
                   <AttainmentTableACVRange AttainmentSegmentRow={ props[`AttainmentData${props.type}`].segmentRow} AttainmentTable={props[`AttainmentData${props.type}`].table} selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} 
                  //  handleChangeLocation={handleChangeLocation} 
                   tableHeader={props.tableHeader} goal={props.goal} dataTest={props.isrepScoreCard ?  `rep-Attainment${props.type}` : `Attainment${props.type}`} attainmentSplitUp={props[`AttainmentData${props.type}`].splitup}
                   nonRenewals={props[`AttainmentData${props.type}`].nonRenewals}
                   isrepScoreCard={props.isrepScoreCard}
                   tableID={props.tableID}
                   />
                 </Grid> : 
                <Grid component={Card} item container  justify="center">
                <Skeleton variant="react" animation="wave" height={120} width='95%' style={{ margin: '1rem' }} />
            </Grid> 
            }
            </Grid>
            {location === "drilldownOnGraph" &&
                    <Grid item xs={12} style={{ position: 'absolute', width: '100%', marginTop: '30px' }}>

                        <SingleLevelDrilldown
                            header={"Won Opportunities"}
                            back={handleChangeLocationBack}
                            filters={filtersForDrilldown}>

                            <Drilldown header={"Won Opportunities"} body={props.drilldownOnGraphData} />
                        </SingleLevelDrilldown>

                    </Grid >

                }
            </Grid>
        )
}
const mapStateToProps = state => {
    const { AttainmentDataYTDACVRange,AttainmentDataCFQACVRange, user,drilldownOnGraphData} = state.app;

    return {
      AttainmentDataYTD: AttainmentDataYTDACVRange,AttainmentDataCFQ: AttainmentDataCFQACVRange,user,drilldownOnGraphData
    }
  }
  const mapDispatchToProps = (dispatch) => ({
    getAttainmentData: (filters,type) => {
        dispatch({ type: `get_attainment_data_acv_range_${type}_request` })
        appService.getAttainmentDataACVRange(filters,type)
          .then(json => {
            dispatch({ type: `get_attainment_data_acv_range_${type}_success`, json })
          }, error => {
            if (typeof error === 'object')
              dispatch({ type:`get_attainment_data_acv_range_${type}_failure`, error: 'Something went wrong' })
            else
              dispatch({ type: `get_attainment_data_acv_range_${type}_failure`, error })
          })
    },
      getAttainmentSectionDrilldown: (filters, key) => {
      dispatch({ type: 'get_drilldown_on_graph_request' })
      appService.getAttainmentSectionDrilldown(filters, key)
          .then(json => {
            let tempOpportunities = json.message.opportunities[1].sort((a, b) => a.ACV > b.ACV ? -1 : 1)
            tempOpportunities = tempOpportunities.map((item, index) => {
              let temp = {
                ...item,
                OppNo: index + 1
              }
              return temp
            })
    
            json.message = {
              ...json,
                columns:json.message.columns,
                count_acv:json.message.opportunities[0],
                opportunities: tempOpportunities
              }
              dispatch({ type: 'get_drilldown_on_graph_success', json })
          }, error => {
              if (typeof error === 'object') dispatch({ type: 'get_drilldown_on_graph_failure', error: 'Something went wrong' })
              else
                  dispatch({ type: 'get_drilldown_on_graph_failure', error })
          })
  },
    clearDrilldownData: () => {
      dispatch({ type: 'clear_drilldown_data' })
    }
  })
const AttainmentSectionACVRange = connect(mapStateToProps, mapDispatchToProps)(Attainment)
export default AttainmentSectionACVRange