import * as d3 from "d3";
export const updatedScheme10 = d3.schemeCategory10.map((item) =>
  item === "#d62728" ? "#fcba03" : item === "#e377c2" ? "#43c2db" : item
);
export const updatedScheme10Sankey = d3.schemeTableau10;

export function getCustomContrastTextColor(hexColor) {
  const normalized = hexColor.toLowerCase();

  // Explicit override for this specific light green color
  if (normalized === "#c6e0b4") {
    return "black";
  }

  // Fallback rule for all other colors
  return "white";
}
