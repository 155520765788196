import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Paper, Button, Grid, Modal } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import {
  countOrACVFormatter,
  countOrACVFormatterMillion,
  countOrACVFormatterThousand,
} from "../../../util/customFunctions";
import { store } from "../../../util/store";
const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    paddingBottom: 10,
  },
}));

const HeaderDD = (props) => {
  const classes = useStyle();
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;

  // console.log(props.showOutliers)
  return (
    <div className={classes.root}>
      {props.showOutliers === true &&
        !["", null, undefined].includes(props.outliersData) && (
          <Typography
            align="center"
            style={{
              position: "absolute",
              left: 0,
              background: "#fee599ff",
              padding: "0rem 0.4rem",
              margin: "0rem 0.5rem",
              border: "1px solid lightgrey",
              color: "black",
              width: "14rem",
            }}
          >
            <span>{`Excluding outliers >${countOrACVFormatterThousand(
              props.outlierValue,
              true
            )}`}</span>{" "}
            <br />{" "}
            <span>{`${
              [null, undefined, ""].includes(
                props.outliersData.message.opportunities[2][0].count
              )
                ? 0
                : countOrACVFormatter(
                    props.outliersData.message.opportunities[2][0].count
                  )
            } / ${
              [null, undefined, ""].includes(
                props.outliersData.message.opportunities[2][0].acv
              )
                ? `${Currency_Symbol}0`
                : countOrACVFormatterThousand(
                    props.outliersData.message.opportunities[2][0].acv,
                    true
                  )
            }`}</span>
          </Typography>
        )}
      <Grid item container xs={12} justifyContent="center">
        <Grid item xs={12}>
          <Typography align="center" variant="h2">
            {props.title}{" "}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="body1">
            {props.description}{" "}
          </Typography>
        </Grid>
      </Grid>
      {props.stepBack && (
        <Button
          style={{ position: "absolute", left: 0 }}
          variant="contained"
          color="primary"
          data-test="back"
          onClick={() => props.stepBack()}
        >
          <KeyboardBackspace />
        </Button>
      )}
      <Button
        style={{ position: "absolute", right: 0 }}
        variant="contained"
        color="primary"
        data-test="drilldownCloseButton"
        onClick={() => props.back("overview", "", false)}
      >
        <Close />
      </Button>
    </div>
  );
};

export default HeaderDD;
