import React from "react";
import PipelineTrendsDrilldown from "./PipelineTrendsDrilldown";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Divider,
  Button,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import DynamicTableSegment from "../../components/DyanmicTableSegment";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Fragment } from "react";
import DatePicker from "../../components/DatePicker_v2";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import WaterfallDrilldownBody from "../NewWaterFall/WaterfallDrilldownBody";
import PipelineTrendsTable from "./PipelineTrendsTable";
import PipelineTrendsDetailTable from "./PipelineTrendsDetailTable";
import {
  customDateFormatter,
  customDateFormatterNew,
  dateFormatter,
  dateFormatterCalenderControl,
  dateFormatterMonthInWords,
  dateSubractorInISO,
  dateSubtractorMoment,
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
  getTimingArr,
  dateFormatterMoment,
  _isNaN,
  getStageNames,
  getDefaultSalesType,
} from "../../util/customFunctions";
import FilterContainer from "../../Filters/FilterContainer";
import moment from "moment";
import { fil } from "date-fns/locale";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  formControl: {
    maxWidth: 250,
  },
  datesFilterContainer: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    padding: 10,
    paddingBottom: 10,
    width: "32%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  repTeamFilterContainer: {
    padding: 10,
    margin: 5,
    width: "29%",
    [theme.breakpoints.down("md")]: {
      width: "37%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  acvFilterContainer: {
    padding: 10,
    margin: 5,
    paddingBottom: 2,
    width: "20%",
    [theme.breakpoints.down("md")]: {
      width: "35%",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
  stageFilterContainer: {
    padding: 5,
    paddingTop: 10,
    margin: 5,
    paddingBottom: 10,
    width: "10%",
    [theme.breakpoints.down("md")]: {
      width: "20%",
    },
  },
  buttonContainer: {
    padding: 5,
    width: "5%",
    alignSelf: "center",
  },
});

class PipelineTrends extends React.Component {
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  filters = [
    "rep",
    "team",
    "Projected_Close_Year_Month",
    "Projected_Close_Date_At_Time_Fiscal_Quarter",
    "Cust_Type",
    "Booking_Type_NL_CS_US",
    "deal_category__c",
    "cross_sell1__c",
    "Type",
    "Solution_Cat",
    "Record_Type_Name__c",
  ];

  formatDate = (dt) => {
    let date = dt.toString();
    return date.length < 2 ? `0${date}` : date;
  };

  state = {
    number: 4,
    activelast: 7,
    startingEnding: "Ending",
    Projected_Close_Year_Month: ["All"],
    timeFrame: "window",
    activeTimeFilter: "Projected_Close_Date_At_Time_Fiscal_Quarter",
    from: dateFormatter(this.props.waterfallMaxDate),
    to: dateFormatter(this.props.waterfallMaxDate),
    // fromDisplay: customDateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, 7)),
    // toDisplay: customDateFormatter(this.props.waterfallMaxDate),
    // activeFrom: dateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, 7)),
    // activeTo: dateFormatter(this.props.waterfallMaxDate),
    minDateForTo: this.props.waterfallMinDate,
    maxDateForTo: this.props.waterfallMaxDate,
    minDateForFrom: this.props.waterfallMinDate,
    maxDateForFrom: "",
    maxDate: this.props.waterfallMaxDate,
    trendInterval: "Weeks",
    errorTextFrom: "",
    errorTextTo: "",
    rep: ["All"],
    team: ["All"],
    Cust_Type: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Type: ["All"],
    disabled: [],
    repTeam: "rep",
    Projected_Close_Date_At_Time_Fiscal_Quarter: ["2021-Q1"],
    filtersForDrilldownpcfq: ["2021-Q1"],
    earliestOpenStage: "",
    location: "overview",
    filtersForDrilldown: [
      {
        title: "Time Frame",
        value: `4 Weeks`,
      },
      { title: "Rep", value: ["All"] },
      { title: "Customer Type", value: ["All"] },
    ],
    filtersForDrilldown2: [],
    activeFilters: [],
    selectedDrilldownStage: "",
    selectedDrilldownStageLabel: "",
    filtersForDrilldownEarliestOpenStage: "",
    filtersForDrilldownStaringEnding: "Ending",
    secondLevelLocation: "",
    Solution_Cat: ["All"],
    Record_Type_Name__c: ["All"],
  };

  orderRepsByLastName = () => {
    let reps = this.props.waterfallFilters.reps
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  componentDidMount() {
    this.props.getWaterfallMinDate(this.props.company);
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    // D3Funnel.destroy(this._rootNode)
    // this._chart = D3Funnel.create(
    //   this._rootNode,
    //   {
    //     data: this.props.waterfallData,
    //     handleChangeLocation: this.handleChangeLocation,
    //     company: this.props.company,
    //     disableStartingEndingPipeline: this.props.company === "C0003"?false:false,
    //     disableDrilldown:true
    //   }
    // )
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.waterfallMinDate !== prevProps.waterfallMinDate ||
      this.props.waterfallMaxDate !== prevProps.waterfallMaxDate
    ) {
      var [year, month, dt] = this.props.waterfallMaxDate
        .split("-")
        .map((s) => parseInt(s));
      const minDateSplit = this.props.waterfallMinDate.split("-");
      const minDate = new Date(
        parseInt(minDateSplit[0]),
        parseInt(minDateSplit[1]) - 1,
        parseInt(minDateSplit[2])
      );
      const maxDateSplit = this.props.waterfallMaxDate.split("-");
      const maxDate = new Date(
        parseInt(maxDateSplit[0]),
        parseInt(maxDateSplit[1]) - 1,
        parseInt(maxDateSplit[2])
      );
      const fromDateObj = new Date(
        parseInt(maxDateSplit[0]),
        parseInt(maxDateSplit[1]) - 1,
        parseInt(maxDateSplit[2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      var salesTypes = ["All"];
      let dealsTypes = ["All"];
      dealsTypes = getDefaultSalesType(this.props.waterfallFilters.oppTypeFilter, "Type")
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.waterfallFilters.salesType,
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.waterfallFilters.dealType,
          "Type"
        );
      }

      this.setState({
        ...this.state,
        minDateForTo: minDate,
        maxDateForTo: maxDate,
        minDateForFrom: minDate,
        maxDateForFrom: maxDate,
        maxFromDate: fromDate,
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
        Projected_Close_Date_At_Time_Fiscal_Quarter:
          getDefaultSelectedFiscalQuarters(
            this.props.waterfallFilters.pcfq,
            "Projected_Close_Fiscal_Quarter"
          ),
        filtersForDrilldownpcfq: getDefaultSelectedFiscalQuarters(
          this.props.waterfallFilters.pcfq,
          "Projected_Close_Fiscal_Quarter"
        ),
        earliestOpenStage: getDefaultSelectedStage(
          this.props.waterfallFilters.stages
        ),
        filtersForDrilldownEarliestOpenStage: getDefaultSelectedStage(
          this.props.waterfallFilters.stages
        ),
        from: fromDate,
        to: dateFormatterMoment({ year: year, month, date: dt }),
        maxDate: this.props.waterfallMaxDate,
        fromDisplay: customDateFormatter(
          dateSubractorInISO(this.props.waterfallMaxDate, 7)
        ),
        toDisplay: customDateFormatter(this.props.waterfallMaxDate),
        activeFrom: dateFormatter(
          dateSubractorInISO(this.props.waterfallMaxDate, 7)
        ),
        activeTo: dateFormatterMoment({ year, month, date: dt }),
      });
    }
  }

  /* componentWillUnmount() {
      D3Funnel.destroy(this._rootNode);
  } */

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  // handleChange = e => {
  //   if (e.target.value !== 1) {
  //     this.setState({ ...this.state, [e.target.name]: e.target.value, from: dateFormatter(this.props.waterfallMaxDate) })
  //     console.log(this.props.waterfallMaxDate,'maxdate')
  //   } else {
  //     this.setState({ ...this.statem, [e.target.name]: e.target.value })
  //   }
  // }

  handleChange = (e) => {
    if (this.state.trendInterval === "Months") {
      var date = new Date();
      var [year, month, dt] = getTimingArr(
        this.props.waterfallMaxDate,
        false,
        "T"
      );
      var dateSplit = { month: month, year: date.getFullYear(), date: 1 };
      var from = dateSplit.month + "-" + dateSplit.date + "-" + dateSplit.year;
      this.setState({ ...this.state, [e.target.name]: e.target.value, from });
    } else if (e.target.value === "Weeks" || e.target.value === "Days") {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        from: this.state.maxDateForFrom,
      });
    } else if (this.state.number) {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  };

  // write datepicker code inside this function
  trendIntervalChange = (e) => {
    var trendInterval = e.target.value;

    var date = new Date();
    if (e.target.value === "Months") {
      var [year, month, dt] = getTimingArr(
        this.props.waterfallMaxDate,
        false,
        "T"
      );
      var from = dateFormatterMoment({
        year: date.getFullYear(),
        month: month,
        date: 1,
      });
      console.log(from);
      this.setState({ ...this.state, [e.target.name]: e.target.value, from });
    } else if (e.target.value === "Weeks" || e.target.value === "Days") {
      console.log(this.state.maxFromDate);
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        from: this.state.maxFromDate,
      });
    } else if (this.sate.number) {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  };

  handleChangeFilter = (e) => {
    let activeTimeFilter = "Projected_Close_Date_At_Time_Fiscal_Quarter";
    if (e.target.name === "Projected_Close_Year_Month") {
      activeTimeFilter = "Projected_Close_Year_Month";
    }
    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      activeTimeFilter,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleChangeRepTeam = (e) => {
    this.setState({
      ...this.state,
      repTeam: e.target.value,
      disabled:
        e.target.value === "Rep"
          ? [...this.state.disabled.filter((f) => f !== "rep"), "team"]
          : [...this.state.disabled.filter((f) => f !== "team"), "rep"],
    });
  };

  setFromDate = (date) => {
    const dateSplit = {
      month: date.getMonth(),
      year: date.getFullYear(),
      date: date.getDate(),
    };
    const toDateSplit = this.state.to.split("-");
    const dateSplitTo = {
      month: parseInt(toDateSplit[0]) - 1,
      date: parseInt(toDateSplit[1]),
      year: parseInt(toDateSplit[2]),
    };

    if (
      dateSubtractorMoment(dateSplit, 0).utc().valueOf() >
      dateSubtractorMoment(dateSplitTo, 0).utc().valueOf()
    ) {
      this.setState({
        ...this.state,
        from: dateSubtractorMoment(dateSplit, 0).format("MM-DD-YYYY"),
        errorTextFrom: "Data not available",
      });
    } else {
      this.setState({
        ...this.state,
        from: dateSubtractorMoment(dateSplit, 0).format("MM-DD-YYYY"),
        errorTextFrom: "",
        errorTextTo: "",
      });
    }
  };

  setToDate = (date) => {
    const dateSplit = {
      month: date.getMonth(),
      year: date.getFullYear(),
      date: date.getDate(),
    };
    const fromDateSplit = this.state.from.split("-");
    const dateSplitFrom = {
      month: parseInt(fromDateSplit[0]) - 1,
      date: parseInt(fromDateSplit[1]),
      year: parseInt(fromDateSplit[2]),
    };

    if (
      dateSubtractorMoment(dateSplit, 0).utc().valueOf() <
      dateSubtractorMoment(dateSplitFrom, 0).utc().valueOf()
    ) {
      this.setState({
        ...this.state,
        to: dateSubtractorMoment(dateSplit, 0).format("MM-DD-YYYY"),
        errorTextFrom: "To date cannot be earlier than the From date",
      });
    } else {
      this.setState({
        ...this.state,
        to: dateSubtractorMoment(dateSplit, 0).format("MM-DD-YYYY"),
        errorTextTo: "",
        errorTextFrom: "",
      });
    }
  };

  plot = () => {
    const fromSplit = this.state.from.split("-");
    const toSplit = this.state.to.split("-");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          string: true,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? this.state.rep
                : this.state.rep.map(
                    (s) =>
                      this.props.waterfallFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.waterfallFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : // f === "Cust_Type" ? this.state[f].includes('All') ? this.state[f] : this.state[f].map(s => this.props.waterfallFilters.Cust_Type.filter(r => r.Cust_Type === s)[0].Cust_Type) :
                this.state[f],
        };
      })
      .filter((f) => !f.value.includes("All"))
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_namesOnwards = getStageNames(
      this.props.waterfallFilters.stages,
      this.state.earliestOpenStage
    );
    let stage_namesBefore = [];
    // this.props.waterfallFilters.stages.map((i) => {
    //   if (i.sequence !== undefined) {
    //     if (i.sequence >= this.state.earliestOpenStage)
    //       stage_namesOnwards = [...stage_namesOnwards, i.stage_name]
    //   }
    // })
    this.props.waterfallFilters.stages.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence < this.state.earliestOpenStage)
          stage_namesBefore = [...stage_namesBefore, i.stage_name];
      }
    });

    filters = [
      ...filters,
      {
        name: "timeFrame",
        value: [
          { name: "date", value: this.state.from },
          { name: "startingEnding", value: this.state.startingEnding },
          { name: "trendInterval", value: this.state.trendInterval },
          { name: "number", value: this.state.number },
        ],
      },
      {
        name: "Earliest_Open_Stage",
        value: [stage_namesOnwards[0]],
        string: true,
      },
      // {
      //   name: 'StagesBeforeEarliest',
      //   value: stage_namesBefore,
      //   string: true
      // }
    ];

    this.props.getPipelineData(filters, this.state.activeTimeFilter);
    this.setState({
      ...this.state,
      fromDisplay: customDateFormatterNew(this.state.from),
      toDisplay: customDateFormatterNew(this.state.to),
      activeFrom: this.state.from,
      // activeTo: this.state.to,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      filtersForDrilldownEarliestOpenStage: this.passFiltersForDrilldown2(),
      filtersForDrilldownStaringEnding: this.passFiltersForDrilldown3(),
      filtersForDrilldownpcfq: this.passFiltersForDrilldown4(),
      activeFilters: filters,
      // activelast: this.state.number
    });
  };

  handleChangeLocation = (location, stage, Open_Datetime) => {
    this.setState({
      ...this.state,
      location: location,
      selectedDrilldownStage: stage.x,
      selectedDrilldownStageLabel: stage.cell,
      selectedDrilldown: stage.y,
      secondLevelLocation: location,
    });
    if (location !== "overview") {
      const fromSplit = this.state.from.split("-");
      const toSplit = this.state.to.split("-");
      let stage_namesOnwards = [];
      let stage_namesBefore = [];
      this.props.waterfallFilters.stages.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence >= this.state.earliestOpenStage)
            stage_namesOnwards = [...stage_namesOnwards, i.stage_name];
        }
      });
      this.props.waterfallFilters.stages.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence < this.state.earliestOpenStage)
            stage_namesBefore = [...stage_namesBefore, i.stage_name];
        }
      });

      // let filters = this.filters.map(f => {
      //   return {
      //     name: f,
      //     string: true,
      //     value: f === 'Rep' ? this.state[f].includes('All') ? this.state.Rep : this.state.Rep.map(s => this.props.waterfallFilters.reps.filter(r => r.Full_Name === s)[0].UserID) :
      //       f === 'team' ? this.state[f].includes('All') ? this.state[f] : this.state[f].map(s => this.props.waterfallFilters.teams.filter(r => r.Display_Name === s)[0].Display_Name) :
      //       // f === "Cust_Type" ? this.state[f].includes('All') ? this.state[f] : this.state[f].map(s => this.props.waterfallFilters.Cust_Type.filter(r => r.Cust_Type === s)[0].Cust_Type) :
      //        this.state[f] ,

      //   }
      // }).filter(f => !f.value.includes('All')).filter(f => !this.state.disabled.includes(f.name))
      // filters = [...filters,  {
      //   name: 'Earliest_Open_Stage',
      //   value: [stage_namesOnwards[0]],
      //   string: true
      // }, {name:"Open_Datetime",value:stage.cell,string:true},{name:stage.x, value: stage.row ,string:true } ]
      let filters = [];
      filters = [
        ...filters,
        ...this.props.selectedFilters,
        {
          name: "Open_Datetime",
          value: typeof stage.cell === "string" ? [stage.cell] : stage.cell,
          string: true,
        },
        { name: stage.x, value: stage.row, string: true },
      ];
      console.log(this.props.selectedFilters);

      this.props.getWaterfallDrilldownOnGraph({ filters: filters });
    } else {
      this.props.clearDrilldownData();
    }
  };
  enableMonthStart(date) {
    return date.getDate() !== 1;
  }

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    // if(this.state.Projected_Close_Date_At_Time_Fiscal_Quarter){
    //   filtersArray = [...filtersArray, {title: 'Projected Close Fiscal Quarter', value: this.state.Projected_Close_Date_At_Time_Fiscal_Quarter}]
    // }

    if (this.state.number && this.state.trendInterval) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Time Frame",
          value: this.state.number + " " + this.state.trendInterval,
        },
      ];
    }

    if (this.state.disabled.filter((i) => i === "rep").length === 0) {
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];
    }
    if (this.state.disabled.filter((i) => i === "team").length === 0) {
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];
    }
    if (
      this.state.disabled.filter((i) => i === "Booking_Type_NL_CS_US")
        .length === 0
    ) {
      if (this.props.company === "C0003") {
        filtersArray = [
          ...filtersArray,
          {
            title: "Opportunity Type",
            value: this.state.Booking_Type_NL_CS_US,
          },
        ];
      }
    }
    if (!this.state.deal_category__c.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Deal Category", value: this.state.deal_category__c },
      ];
    }
    if (!this.state.cross_sell1__c.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Sales Type", value: this.state.cross_sell1__c },
      ];
    }
    if (!this.state.Type.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0017" ? "Opp. Type" : "Deal Type",
          value: this.state.Type,
        },
      ];
    }
    if (!this.state.Projected_Close_Year_Month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Projected Close Month",
          value: this.state.Projected_Close_Year_Month.sort(),
        },
      ];
    }
    if (!this.state.Cust_Type.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Customer Type", value: this.state.Cust_Type },
      ];
    }
    if (!this.state.Solution_Cat.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];
    }

    return filtersArray;
  };

  passFiltersForDrilldown2 = () => {
    if (this.state.earliestOpenStage) {
      return this.state.earliestOpenStage;
    }
  };

  passFiltersForDrilldown4 = () => {
    let filtersArray = [];
    if (this.state.Projected_Close_Date_At_Time_Fiscal_Quarter) {
      filtersArray = [
        ...filtersArray,
        this.state.Projected_Close_Date_At_Time_Fiscal_Quarter,
      ];
    }
    return filtersArray;
  };

  passFiltersForDrilldown3 = () => {
    if (this.state.startingEnding) {
      return this.state.startingEnding;
    }
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: "",
      secondLevelLocation: "",
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    //  this.props.current_fiscal_quarter !== "" && console.log(this.props.currentFiscalQuarter.current_fiscal_quarter)
    const { classes } = this.props;
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid item style={{ marginBottom: 5 }} xs={12}>
          <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Pipeline Trends</Typography>
          <Typography variant='body1'>
          Analyze the open pipeline by stage at various points in time to instantly spot changes and visualize trends.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Pipeline Trends",
          "Analyze the open pipeline by stage at various points in time to instantly spot changes and visualize trends."
        )}

        {this.props.waterfallFilters !== "" ? (
          <>
            <Grid
              container
              xs={12}
              alignItems="flex-start"
              style={{ marginBottom: 5 }}
            >
              <Grid
                xs={4}
                spacing={5}
                item
                container
                component={Paper}
                className={classes.repTeamFilterContainer}
              >
                <Grid item xs={4} style={{ padding: 0, paddingRight: 10 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.Projected_Close_Year_Month[0] !== "All"
                    }
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color:
                          !this.state.Projected_Close_Date_At_Time_Fiscal_Quarter.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        whiteSpace: "nowrap",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Proj. Close Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={
                        this.state.Projected_Close_Date_At_Time_Fiscal_Quarter
                      }
                      onChange={this.handleChangeFilter}
                      name="Projected_Close_Date_At_Time_Fiscal_Quarter"
                      data-test="select-Projected_Close_Date_At_Time_Fiscal_Quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.waterfallFilters !== "" &&
                        this.props.waterfallFilters.pcfq.map((y) => (
                          <MenuItem
                            key={y["Projected_Close_Fiscal_Quarter"]}
                            value={y["Projected_Close_Fiscal_Quarter"]}
                          >
                            {y["Projected_Close_Fiscal_Quarter"]}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} style={{ padding: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      !this.state.Projected_Close_Date_At_Time_Fiscal_Quarter.includes(
                        "All"
                      )
                    }
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.Projected_Close_Year_Month.includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Proj. Close Month:
                    </InputLabel>
                    <Select
                      value={this.state.Projected_Close_Year_Month}
                      onChange={this.handleChangeFilter}
                      name="Projected_Close_Year_Month"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.waterfallFilters !== "" &&
                        this.props.waterfallFilters.projectedYearMonthFilter.map(
                          (y) => (
                            <MenuItem
                              key={y["Projected_Close_Year_Month"]}
                              value={y["Projected_Close_Year_Month"]}
                            >
                              {y["Projected_Close_Year_Month"]}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} style={{ padding: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-stage-label"
                      style={{ color: "#4472c4", width: 144 }}
                    >
                      Earliest Open Stage:
                    </InputLabel>
                    <Select
                      labelId="select-stage-label"
                      id="select-stage"
                      value={this.state.earliestOpenStage}
                      onChange={this.handleChangeEarliestOpenStage}
                      label="Earliest Open Stage:"
                      name="earliestOpenStage"
                      data-test="select-earliestOpenStage"
                    >
                      {this.props.waterfallFilters !== "" &&
                        this.props.waterfallFilters.stages.map(
                          (y) =>
                            y.sequence && (
                              <MenuItem key={y.sequence} value={y.sequence}>
                                {y.stage_name}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                xs={4}
                item
                container
                className={classes.datesFilterContainer}
                component={Paper}
              >
                <Grid item xs={2} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-last-label"
                      style={{ color: "#4472c4", width: 200 }}
                    >
                      Time Frame:
                    </InputLabel>
                    <Select
                      labelId="select-last-label"
                      id="select-last"
                      value={this.state.number}
                      onChange={this.handleChange}
                      // label="Last:"
                      name="number"
                      data-test="select-number"
                      // disabled={this.state.timeFrame === 'custom'}
                    >
                      {Array(12)
                        .fill(1)
                        .map((y, i) => (
                          <MenuItem key={i + 1} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3} style={{ paddingLeft: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-last-label"
                      style={{ color: "#4472c4" }}
                    ></InputLabel>
                    <Select
                      labelId="select-last-label"
                      data-test="select-trendInterval"
                      id="select-weeks"
                      value={this.state.trendInterval}
                      onChange={this.trendIntervalChange}
                      // label="Last:"
                      name="trendInterval"
                      // disabled={this.state.timeFrame === 'custom'}
                    >
                      {[
                        { text: "Days" },
                        { text: "Weeks" },
                        { text: "Months" },
                      ].map((y) => (
                        <MenuItem key={y.text} value={y.text}>
                          {y.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3} style={{ paddingLeft: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-last-label"
                      style={{ color: "#4472c4" }}
                    ></InputLabel>
                    <Select
                      labelId="select-last-label"
                      data-test="select-startingEnding"
                      id="select-weeks"
                      value={this.state.startingEnding}
                      onChange={this.handleChange}
                      // label="Last:"
                      name="startingEnding"
                      // disabled={this.state.timeFrame === 'custom'}
                    >
                      {[{ text: "Ending" }, { text: "Starting" }].map((y) => (
                        <MenuItem key={y.text} value={y.text}>
                          {y.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 10, paddingTop: 15 }}>
                  <DatePicker
                    shouldDisableDate={
                      this.state.trendInterval === "Months"
                        ? this.enableMonthStart
                        : null
                    }
                    label=""
                    setDate={(date) => this.setFromDate(date)}
                    min={this.state.minDateForFrom}
                    max={this.state.maxDateForFrom}
                    current={this.state.from}
                    errorText={this.state.errorTextFrom}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={3}
                className={classes.repTeamFilterContainer}
                component={Paper}
              >
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    data-test="select-rep"
                    options={
                      this.props.waterfallFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Rep:"
                        variant="standard"
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      id="select-team-label"
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Team:
                    </InputLabel>
                    <Select
                      labelId="select-team-label"
                      id="select-team"
                      data-test="select-team"
                      value={this.state.team}
                      onChange={this.handleChangeFilter}
                      label="Team"
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.waterfallFilters !== "" &&
                        this.props.waterfallFilters.teams
                          .map((t) => t.Display_Name)
                          .sort()
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container className={classes.buttonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.plot}
                  disabled={
                    this.state.errorTextFrom !== "" ||
                    this.state.errorTextTo !== ""
                  }
                  data-test="go"
                >
                  GO
                </Button>
              </Grid>
              <Grid
                item
                container
                xs={this.props.company === "C0003" ? 4 : 2}
                className={classes.repTeamFilterContainer}
                component={Paper}
              >
                <Grid
                  item
                  xs={this.props.company === "C0003" ? 6 : 12}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-team-label"
                      style={{
                        color: !this.state.Cust_Type.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Customer Type:
                    </InputLabel>
                    <Select
                      labelId="select-team-label"
                      id="select-cust-type"
                      data-test="select-cust-type"
                      value={this.state.Cust_Type}
                      onChange={this.handleChangeFilter}
                      label=""
                      name="Cust_Type"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.waterfallFilters !== "" &&
                        this.props.waterfallFilters.custType
                          .map((t) => t.Cust_Type)
                          .sort()
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                {this.props.company === "C0003" && (
                  <Grid
                    item
                    xs={this.props.company === "C0003" ? 6 : 12}
                    style={{ paddingTop: 0 }}
                  >
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.Booking_Type_NL_CS_US.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Opportunity Type:
                      </InputLabel>
                      <Select
                        labelId="select-opp-label"
                        data-test="select-opp-type"
                        value={this.state.Booking_Type_NL_CS_US}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="Booking_Type_NL_CS_US"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.waterfallFilters !== "" &&
                          this.props.waterfallFilters.oppType
                            .map((t) => t.Booking_Type_NL_CS_US)
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              {this.props.company === "C0010" && (
                <Grid
                  item
                  container
                  xs={this.props.company === "C0003" ? 4 : 2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.Record_Type_Name__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-record-type"
                        data-test="select-record-type"
                        value={this.state.Record_Type_Name__c}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="Record_Type_Name__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.waterfallFilters !== "" &&
                          this.props.waterfallFilters.oppRecordTypeFilter
                            .map((t) => t.Record_Type_Name__c)
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {/* C0017 */}
              {this.props.company === "C0017" && (
                <Grid
                  item
                  container
                  xs={this.props.company === "C0003" ? 4 : 2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Opp. Type:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-record-type"
                        data-test="Type"
                        value={this.state.Type}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.waterfallFilters !== "" &&
                          this.props.waterfallFilters.oppTypeFilter
                            .map((t) => t.Type)
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {this.props.company === "C0001" && (
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.deal_category__c.includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Deal Category:
                        </InputLabel>
                        <Select
                          data-test="Product_Category"
                          value={this.state.deal_category__c}
                          onChange={this.handleChangeFilter}
                          name="deal_category__c"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.waterfallFilters !== "" &&
                            this.props.waterfallFilters.dealType.map((y) => (
                              <MenuItem
                                key={y.deal_category__c}
                                value={y.deal_category__c}
                              >
                                {y.deal_category__c}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {this.props.company === "C0015" && (
                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Solution_Cat.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Solution Category:
                      </InputLabel>
                      <Select
                        data-test="Solution_Cat"
                        value={this.state.Solution_Cat}
                        onChange={this.handleChangeFilter}
                        name="Solution_Cat"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.waterfallFilters !== "" &&
                          this.props.waterfallFilters.Solution_Cat.map((y) => (
                            <MenuItem
                              key={y.Solution_Cat}
                              value={y.Solution_Cat}
                            >
                              {y.Solution_Cat}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {this.props.company === "C0008" && (
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={4}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.cross_sell1__c.includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Sales Type:
                        </InputLabel>
                        <Select
                          data-test="cross_sell1__c"
                          value={this.state.cross_sell1__c}
                          onChange={this.handleChangeFilter}
                          name="cross_sell1__c"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.waterfallFilters !== "" &&
                            this.props.waterfallFilters.salesType.map((y) => (
                              <MenuItem
                                key={y.cross_sell1__c}
                                value={y.cross_sell1__c}
                              >
                                {y.cross_sell1__c}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.Type.includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Deal Type:
                        </InputLabel>
                        <Select
                          data-test="Type"
                          value={this.state.Type}
                          onChange={this.handleChangeFilter}
                          name="Type"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.waterfallFilters !== "" &&
                            this.props.waterfallFilters.dealType.map((y) => (
                              <MenuItem key={y.Type} value={y.Type}>
                                {y.Type}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid item container spacing={2} justify="space-around">
          <Grid item xs={12}>
            {this.props.PipelineAnalysisData !== "" ? (
              <>
                <Card className={clsx(classes.root)} raised={true}>
                  <Grid className={classes.grid} item xs={12}>
                    <DataVisualizer
                      chartName={`Pipeline Trends`}
                      title={"Pipeline Trends"}
                      type={"Stage_At_Time_T"}
                      chartType="acv"
                      winACVMixAnalysisData={this.props.openPipelineSummary.filter(
                        (x) => x.Stage_At_Time_T !== "total"
                      )}
                      handleChangeLocation={this.handleChangeLocation}
                      quarter={"Open_Datetime"}
                      // isDrilldown={false}
                      doughnutNoNeeded={true}
                      Value_Label={this.props.Value_Label}
                      tableComponent={
                        <PipelineTrendsTable
                          data={this.props.openPipelineSummary}
                          date={"Open_Datetime"}
                          stage={"Stage_At_Time_T"}
                          handleChangeLocation={this.handleChangeLocation}
                          Value_Label={this.props.Value_Label}
                        />
                      }
                      unit="acv"
                    />
                  </Grid>
                </Card>
                <Grid
                  item
                  xs={12}
                  className={classes.grid}
                  style={{ marginTop: "2rem" }}
                >
                  <Card style={{ margin: "auto", width: "100%" }}>
                    <Typography
                      variant="h2"
                      style={{
                        margin: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      Open Pipeline Detail
                    </Typography>

                    {this.props.openPipelineDetail !== undefined ? (
                      <Grid className={classes.grid} item xs={12}>
                        <PipelineTrendsDetailTable
                          data={this.props.openPipelineDetail}
                          handleChangeLocation={this.handleChangeLocation}
                          date={"Open_Datetime"}
                          stag={"Stage_At_Time_T"}
                          owner={"Owner_Name"}
                          company={this.props.company}
                          Value_Label={this.props.Value_Label}
                        />
                      </Grid>
                    ) : (
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="95%"
                        height={230}
                        style={{ margin: "2rem" }}
                      />
                    )}
                  </Card>
                </Grid>
              </>
            ) : (
              <Card
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                raised={true}
              >
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="60%"
                  style={{ margin: "20px 10px", alignSelf: "flex-start" }}
                />
                <Divider style={{ width: "100%" }} />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={350}
                  style={{ margin: "20px 0px" }}
                />
              </Card>
            )}
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" &&
          this.state.selectedDrilldownStageLabel !== "" && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={`${this.state.filtersForDrilldownStaringEnding} Pipeline`}
                back={this.handleChangeLocationBack}
                filters={[
                  {
                    title: "Projected Close Fiscal Qtr.",
                    value: this.state.filtersForDrilldownpcfq[0],
                  },
                  {
                    title: "Earliest Open Stage",
                    value: this.props.waterfallFilters.stages
                      .map((i) => {
                        if (i.sequence !== undefined) {
                          if (
                            i.sequence ===
                            this.state.filtersForDrilldownEarliestOpenStage
                          )
                            return i.stage_name;
                        }
                      })
                      .filter((item) => item !== undefined),
                  },
                  ...this.state.filtersForDrilldown,
                  // {
                  //   title: "Time Frame",
                  //   value: this.state.number +" " + this.state.trendInterval ,
                  // },
                  {
                    title: "Date",
                    value: customDateFormatter(
                      this.state.selectedDrilldownStageLabel
                    ),
                  },
                ]}
              >
                <Drilldown
                  body={this.props.drilldownOnGraphData}
                  company={this.props.company}
                  Value_Label={this.props.Value_Label}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                />
              </SingleLevelDrilldown>
            </Grid>
          )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const {
    waterfallFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate,
    waterfallError,
    alert,
    drilldownOnGraphData,
    updatingWaterfallData,
    selectedFilters,
    user,
    PipelineAnalysisData,
    ExpectedAcvData,
  } = state.app;
  const { openPipelineDetail, openPipelineSummary, stages } =
    PipelineAnalysisData;
  const { currentFiscalQuarter } = waterfallFilters;

  return {
    waterfallFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate,
    waterfallError,
    alert,
    drilldownOnGraphData,
    updatingWaterfallData,
    PipelineAnalysisData,
    selectedFilters,
    openPipelineDetail,
    openPipelineSummary,
    stages,
    currentFiscalQuarter,
    company: user.company,
    Value_Label: user.Value_Label,
    ExpectedAcvData,
    trend:
      waterfallData !== ""
        ? Math.round(
            waterfallData.filter((r) => r.name === "Ending Pipeline")[0].value -
              waterfallData.filter((r) => r.name === "Starting Pipeline")[0]
                .value
          )
        : 0,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getPipelineData: (filters, key) => {
    dispatch({ type: "get_pipelineTrends_analysis_data_request" });
    dispatch({ type: "selected_filters", selectedFilters: filters });
    // console.log(filters)
    appService.getPipelineAnalysisData(filters, key).then(
      (json) => {
        dispatch({ type: "get_pipelineTrends_analysis_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pipelineTrends_analysis_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_pipelineTrends_analysis_data_failure", error });
      }
    );
  },
  getWaterfallMinDate: (company) => {
    dispatch({ type: "get_waterfall_min_date_request" });
    dispatch({ type: "get_pipelineTrends_analysis_data_request" });
    appService.getPipelineAnalysisFilters().then(
      (json) => {
        dispatch({ type: "get_waterfall_min_date_success", json });
        dispatch({ type: "get_waterfall_data_request" });
        let stageSequence = getDefaultSelectedStage(
          json.message.filters.stages
        );
        let filters = [];
        let stage_namesOnwards = getStageNames(
          json.message.filters.stages,
          stageSequence
        );
        let stage_namesBefore = [];
        // json.message.filters.stages.map((i) => {
        //   if (i.sequence !== undefined) {
        //     if (i.sequence >= stageSequence)
        //       stage_namesOnwards = [...stage_namesOnwards, i.stage_name]
        //   }
        // })
        json.message.filters.stages.map((i) => {
          if (i.sequence !== undefined) {
            if (i.sequence < stageSequence)
              stage_namesBefore = [...stage_namesBefore, i.stage_name];
          }
        });
        var [year, month, dt] = getTimingArr(
          json.message.minMax.maxDate,
          false,
          "T"
        );
        const dat = dateFormatterMoment({ year, month, date: dt });
        filters = [
          ...filters,
          {
            name: "timeFrame",
            value: [
              { name: "date", value: dat },
              { name: "startingEnding", value: "Ending" },
              { name: "trendInterval", value: "Weeks" },
              { name: "number", value: 4 },
            ],
          },
          {
            name: "Projected_Close_Date_At_Time_Fiscal_Quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message.filters.pcfq,
              "Projected_Close_Fiscal_Quarter"
            ),
            string: true,
          },
          {
            name: "Earliest_Open_Stage",
            value: [stage_namesOnwards[0]],
            string: true,
          },
        ];
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filters.salesType,
            "cross_sell1__c"
          );
          const dealFilters = getDefaultSalesType(
            json.message.filters.dealType,
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealFilters }];
        }

        const defaultOppType = getDefaultSalesType(json.message.filters.oppTypeFilter, "Type")
        if(defaultOppType[0] !== 'All'){
          filters = [...filters, {name: "Type", value: defaultOppType}]
        }

        dispatch({ type: "selected_filters", selectedFilters: filters });

        appService
          .getPipelineAnalysisData(
            json.message.length === 0 ? [] : filters,
            "Projected_Close_Date_At_Time_Fiscal_Quarter"
          )
          .then(
            (json) => {
              dispatch({
                type: "get_pipelineTrends_analysis_data_success",
                json,
              });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_pipelineTrends_analysis_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_pipelineTrends_analysis_data_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_waterfall_min_date_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_waterfall_min_date_failure", error });
      }
    );
  },
  getWaterfallDrilldownOnGraph: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getPipelineAnalysisDrilldownOnGraph(filters).then(
      (json) => {
        // console.log(json.message)
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) =>
          a.Acv_At_Time_T > b.Acv_At_Time_T ? -1 : 1
        );

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});

const connectedPipelineTrends = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PipelineTrends));
export { connectedPipelineTrends as PipelineTrends };
