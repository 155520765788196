import * as d3 from "d3";
const classes = {
  tableHeader: {
    fontSize: "0.85rem",
    background: "#4472c4",
    paddingRight: "5rem",
    fontFamily: "Roboto/ Helvetica/ Aria/ sans-serif",
    width: "15rem",
    fontWeight: 600,
    color: "black",
  },
  tableCell: {
    fontSize: "1rem",
    whiteSpace: "normal",
    paddingRight: "10px",
    fontFamily: "Roboto/ Helvetica/ Aria/ sans-serif",
    fontSize: "0.85rem",
    fontWeight: 400,
    width: "15rem",
  },
  tablerow: {
    background: "red",
  },
};
export const repsColumnDefs = [
  {
    field: "FullName",
    sortable: true,
    filter: true,
    cellStyle: { textAlign: "left" },
    // cellClass: classes.tableCell,
    // headerClass: classes.tableHeader,
    // cellRenderer: "deltaIndicator",
  },
  {
    field: "FiscalQuarter",
    sortable: true,
    filter: true,
    cellStyle: { textAlign: "right" },
    cellClass: classes.tableCell,
    headerClass: classes.tableHeader,
  },
  {
    field: "Team",
    sortable: true,
    filter: true,
    cellStyle: { textAlign: "left" },
    cellClass: classes.tableCell,
    headerClass: classes.tableHeader,
  },
  {
    field: "Quota",
    sortable: true,
    filter: true,
    cellStyle: { textAlign: "right" },
    cellClass: classes.tableCell,
    headerClass: classes.tableHeader,
    editable: true,
    valueFormatter: (x) => {
      return "$ " + d3.format(",")(Math.ceil(x.value));
    },
  },
];
