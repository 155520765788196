import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import GetApp from "@material-ui/icons/GetApp";

import "./QuotaAttainmentDrilldown.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import {
  _isNaN,
  getCSVFileNameString,
  csvDateFormatter,
  agGridDateFormatter,
  dateFormatterv2,
  dateFormatterv3,
} from "../../util/customFunctions";
import HoverableIcon from "../../components/Buttons/HoverableIcon ";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    // fontSize: '15px',
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    // fontSize: '14px',
    whiteSpace: "normal",
  },
  titleContainer: {
    width: "100%",
  },
}));

const QuotaAttainmentDetailDrilldown = (props) => {
  const classes = useStyle();
  const containerHeight = props.data
    ? props.data[1].length * 40 < window.innerHeight - 300
      ? "-"
      : window.innerHeight - 300
    : window.innerHeight - 300;
  const domLayout = props.data
    ? props.data[1].length * 40 < window.innerHeight - 300
      ? "autoHeight"
      : "normal"
    : "normal";
  // const domLayout = "autoHeight"

  const defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  let gridApi, columnApi, params;

  const gridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params = params;
  };
  const onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const exportCSV = (params) => {
    var params = {
      skipHeader: false,
      skipFooters: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: true,
      processCellCallback: (cellParams) => {
        // const rowIndex = cellParams.column.colId;
        // if (
        //   (cellParams &&
        //     rowIndex === "Projected_Close_Date_At_Time_Fiscal_Quarter") ||
        //   rowIndex === "Created_Date" ||
        //   rowIndex === "Projected_Close_Date_At_Time"
        // ) {
        //   return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter
        // } else if (
        //   (cellParams && rowIndex === "OppName") ||
        //   rowIndex === "Account_Name" ||
        //   rowIndex === "Owner_Name"
        // ) {
        //   return `"${cellParams.value}"`; //apply your string formatter
        // } else if (cellParams && rowIndex === "acv") {
        //   return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value);
        // } else if (cellParams && rowIndex === "attainment") {
        //   return _isNaN(cellParams.value)
        //     ? 0
        //     : Math.round(cellParams.value) + "%";
        // } else return cellParams.value; // no formatting

        const rowIndex = cellParams.column.colId;
        // if(cellParams && rowIndex === "projected_close_date" ) {
        //     return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter
        //   } else if(cellParams && rowIndex === "OppName" || rowIndex === "Account_Name" || rowIndex === "Owner_Name") {
        //     return `"${cellParams.value}"`; //apply your string formatter
        //   } else if(cellParams && rowIndex === "ACV" || rowIndex === "acv"){
        //     return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value)
        //   }
        const dateTypes = props.body.columns
          .filter((f) => f.type === "date")
          .map((f) => f.field);
        const dateTimeTypes = props.body.columns
          .filter((f) => f.type === "datetime")
          .map((f) => f.field);
        const stringTypes = props.body.columns
          .filter((f) => f.type === "string")
          .map((f) => f.field);
        const integerTypes = props.body.columns
          .filter((f) => f.type === "integer")
          .map((f) => f.field);
        const currencyTypes = props.body.columns
          .filter((f) => f.type === "currency")
          .map((f) => f.field);
        // console.log(dateTypes,dateTimeTypes,stringTypes,integerTypes,currencyTypes, 'BODY123')
        if (cellParams && dateTypes.includes(rowIndex)) {
          return cellParams.value ? dateFormatterv2(cellParams.value) : null; //apply your timestamp formatter
        } else if (cellParams && stringTypes.includes(rowIndex)) {
          return `"${cellParams.value}"`; //apply your string formatter
        } else if (cellParams && dateTimeTypes.includes(rowIndex)) {
          return cellParams.value ? dateFormatterv3(cellParams.value) : null; //apply your timestamp formatter
        } else return cellParams.value; // no formatting
      },
      fileName: getCSVFileNameString(props.header),
      columnSeparator: ",",
    };
    gridApi.exportDataAsCsv(params);
  };

  return (
    <Grid container className={classes.root}>
      {props.data && props.dataAcv ? (
        <React.Fragment>
          <Grid
            container
            justify="space-betweeen"
            className={classes.titleContainer}
            xs={12}
          >
            <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="body1" style={{ fontWeight: "600" }}>
                Number of opportunities:{" "}
                <span data-test="count">{props.dataAcv[0].count}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ACV:{" "}
                <span data-test="acv">
                  {d3.format(",")(Math.round(props.dataAcv[0].total_acv))}
                </span>
              </Typography>
            </Grid>

            <Grid
              direction="column"
              item
              container
              xs={2}
              justify="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
            >
              <Grid item justify="flex-end" style={{ paddingBottom: "1rem" }}>
                {/* <Button variant="contained"
                                color="primary" onClick={exportCSV}><GetApp /></Button> */}
                <button style={{ border: "none" }} onClick={exportCSV}>
                  <HoverableIcon />
                </button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            className="ag-theme-custom-react_DB"
            style={{
              height: containerHeight,
              width: "100%",
            }}
          >
            <AgGridReact
              columnDefs={[
                {
                  headerName: "#",
                  field: "OppNo",
                  type: "rightAligned",
                  sortable: false,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "3px",
                  },
                  headerClass: classes.tableHeader,
                  maxWidth: 45,
                },
                {
                  headerName: "Opportunity Name",
                  autoHeight: true,
                  field: "OppName",
                  flex: 2,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Account ",
                  autoHeight: true,
                  field: "Account_Name",
                  flex: 1.8,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                //  { headerName: "Solution Category", field: "Solution_Cat",  flex: 0.8, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                {
                  headerName: "Customer Type",
                  field: "Cust_Type",
                  flex: 0.8,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },

                {
                  headerName: "ACV",
                  field: "acv",
                  flex: 0.7,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  sortingOrder: ["desc", "asc"],
                  valueFormatter: (x) => {
                    return `$${d3.format(",")(Math.round(x.value))}`;
                  },
                },

                {
                  headerName: "Created Date",
                  field: "Created_Date",
                  flex: 0.7,
                  type: "rightAligned",
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  valueFormatter: agGridDateFormatter,
                },
                {
                  headerName: "Closed Date",
                  field: "closed_date",
                  flex: 0.7,
                  type: "rightAligned",
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  valueFormatter: agGridDateFormatter,
                },
                // // { headerName: "Age", field: "cycle_time_closed_orders", flex: 0.9, type: 'rightAligned', sortable: true, comparator: numberSort, filter: 'agNumberColumnFilter', filterParams: { defaultOption: 'greaterThanOrEqual' }, cellStyle: { 'border-right-color': 'lightgrey', 'padding-right': '20px' }, headerClass: classes.tableHeader, sortingOrder: ['desc', 'asc'] },
                {
                  headerName: "Current Stage",
                  field: "Stage",
                  flex: 0.7,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Owner",
                  field: "Owner_Name",
                  flex: 1,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Team",
                  field: "Team",
                  sortable: true,
                  flex: 0.7,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
              ]}
              sizeColumnsToFit={true}
              headerHeight="55"
              rowData={props.data[1]
                .sort((a, b) => b.acv - a.acv)
                .map((item, index) => {
                  let temp = {
                    ...item,
                    OppNo: index + 1,
                  };
                  return temp;
                })}
              onFirstDataRendered={onFirstDataRendered}
              // onCellClicked={this.onCellClicked.bind(this)}
              // pagination={true}
              // paginationPageSize={6}
              defaultColDef={defaultColDef}
              onColumnResized={
                props.data[1].length <= 10 ? onColumnResized : null
              }
              enableCellTextSelection={true}
              // autoHeight={true}
              colResizeDefault={"shift"}
              onGridReady={gridReady}
              debounceVerticalScrollbar={true}
              domLayout={domLayout}
            ></AgGridReact>
          </Grid>
        </React.Fragment>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height={280}
          style={{ margin: "20px 0px" }}
        />
      )}
    </Grid>
  );
};

export default QuotaAttainmentDetailDrilldown;
