import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  Divider,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper,
  TextField,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Skeleton from "@material-ui/lab/Skeleton";
import { styles } from "../NewFunnel/FunnelStyles";
import Loading from "../../components/Loading";
import * as d3 from "d3";
import NumberFormat from "react-number-format";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  _isNaN,
  getDefaultSelectedFiscalQuarters,
  getDefaultACV,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";
import Drilldown from "../../components/Drilldown/DrilldownV4";
import DrilldownV1 from "../../components/Drilldown/DrilldownWithSLD";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";

import KeyMetricsTable from "./KeyMetricsTable";
import KeyMetricsPartnerDealsTable from "./KeyMetricsPartnerDealsTable";
import SALSQLTable from "./SALSQLTable";
import PipelineMetricsTable from "./PipelineKeyMetricsTable";
import addHeaderdescription from "../../components/HeaderDescription";

class KeyMetrics extends React.Component {
  state = {
    closed_fiscal_year: ["All"],
    quarter: ["All"],
    rep: ["All"],
    closed_fiscal_quarter: ["All"],
    Cust_Type: ["All"],
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["Cross Sell", "New Logo"],
    Acct_Vertical: ["All"],
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    Solution_Cat: ["All"],
    custType: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    team: ["All"],
    timeFrame: "quarter",
    disabled: [],
    acvPreset: "All",
    acv: 0,
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    location: "overview",
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
    selectedDrilldownStage: "",
    lastStage: false,
    activeFilters: ["All"],

    filtersForDrilldown: [],
    repFilter: ["All"],
    teamFilter: ["All"],
    activeTimeFilter: "closed_fiscal_quarter",
    tableType: "",
    qualifiedOut: "No",
    header: "",
  };

  filters = [
    "closed_fiscal_year",
    "quarter",
    "rep",
    "closed_fiscal_quarter",
    "team",
    "Cust_Type",
    "Acct_Vertical",
    "custType",
    "Booking_Type_NL_CS_US",
    "Acct_Size_Segment",
    "Acct_Segment",
    "Lead_Source",
    "Opp_Created_By",
    "Solution_Cat",
    "Region_Rollup",
  ];

  componentDidMount() {
    this.props.getFunnelFilters();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelFilters !== "" && prevProps.funnelFilters === "") {
      const defaultFQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelFilters.closedFiscalQuarters,
        "closed_fiscal_quarter"
      );
      var defaultQuarter =
        defaultFQuarter.length > 0 ? defaultFQuarter : ["All"];
      this.setState({
        ...prevState,
        closed_fiscal_quarter: defaultQuarter,
        filtersForDrilldown: [
          {
            title: "Closed Fiscal Quarter",
            value: defaultQuarter,
          },
          ...this.state.filtersForDrilldown.filter(
            (f) => f.title !== "Closed Fiscal Quarter"
          ),
        ],
        activeFilters: [
          { name: "closed_fiscal_quarter", value: defaultQuarter },
        ],
      });
    }
  }

  handleChange = (e) => {
    if (
      e.target.name === "closed_fiscal_year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (this.state.acv !== null && this.state.acv !== 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }
    const activeTimeFilter = !this.state.closed_fiscal_year.includes("All")
      ? "closed_fiscal_year"
      : "closed_fiscal_quarter";
    this.props.getFunnelDataNew(
      filters,
      activeTimeFilter,
      this.state.qualifiedOut
    );
    this.setState({
      ...this.state,
      activeFilters: filters,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      repFilter: this.state.rep,
      teamFilter: this.state.team,
      activeTimeFilter,
    });
  };

  orderRepsByLastName = () => {
    let reps = this.props.funnelFilters.reps
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[1]} ${res[0]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  handleChangeLocation = (location, obj, key) => {
    if (location !== "overview") {
      var drilldownFilters = [];
      var filters = this.props.currentFilters;
      var keyNames = [
        getMixKey(this.state.activeTimeFilter),
        "Partner Channel",
        "Account Segment Rollup",
        "Opportunity Type",
        "Open Fiscal Quarter",
        "Qualified Fiscal Quarter",
        "Fiscal Quarter",
      ];
      var filtersForDrilldown = this.state.filtersForDrilldown.filter(
        (i) => !keyNames.includes(i.title)
      );
      if (key === "Partner") {
        var partner = obj.partner;
        var quarters = obj.quarter;
        drilldownFilters = [
          {
            title: getMixKey(this.state.activeTimeFilter),
            value: quarters.length > 4 ? "All" : quarters,
          },
          { title: "Partner Channel", value: partner },
        ];

        filters = [
          ...filters,
          { name: this.state.activeTimeFilter, value: quarters },
          { name: "Partner_Channel__c", value: partner },
        ];
      } else if (key === "DemandGen") {
        var partner = obj.partner;
        var quarters = obj.quarter;
        drilldownFilters = [
          {
            title:
              partner === "SALs created"
                ? "Open Fiscal Quarter"
                : "Qualified Fiscal Quarter",
            value: quarters.length > 4 ? "All" : quarters,
          },
        ];
        filters = filters.filter((f) => f.name !== "closed_fiscal_quarter");
        filters = [
          ...filters,
          {
            name:
              partner === "SALs created"
                ? "Open_Fiscal_Quarter"
                : "Qualified_Fiscal_Quarter",
            value: quarters,
          },
        ];
      } else if (key === "Pipeline") {
        const stage = obj.oppType === "SAL" ? ["Qualifying"] : ["Solutioning"];
        const datetime = obj.datetime;
        const quarters = obj.quarter;
        filters = filters.filter((f) => f.name !== this.state.activeTimeFilter);
        filters = [
          ...filters,
          {
            name: "timeFrame",
            value: [
              { name: "projectedCloseRolling", value: "All" },
              { name: "timeFrame", value: 3 },
              { name: "durationType", value: "quarters" },
            ],
          },
          { name: "Earliest_Open_Stage", value: stage },
          { name: "Open_Datetime", value: datetime },
        ];
        drilldownFilters = [
          {
            title: "Fiscal Quarter",
            value: quarters.length > 4 ? "All" : quarters,
          },
        ];
      } else {
        var segments = obj.segment;
        var oppTypes = obj.oppType;
        var quarters = obj.quarter;
        var drilldownFilters = [
          {
            title: getMixKey(this.state.activeTimeFilter),
            value: quarters.length > 4 ? "All" : quarters,
          },
          { title: "Account Segment Rollup", value: segments },
          { title: "Opportunity Type", value: oppTypes },
        ];

        filters = [
          ...filters,
          { name: this.state.activeTimeFilter, value: quarters },
          { name: "Acct_Segment_Rollup", value: segments },
          { name: "Booking_Type_NL_CS_US", value: oppTypes },
        ];
      }

      this.setState({
        ...this.state,
        location: location,
        header:
          key === "Partner"
            ? "Partner Deals"
            : key === "attainment"
            ? "Attainment"
            : key === "DemandGen"
            ? "Demand Gen"
            : key === "Pipeline"
            ? "Open Pipeline"
            : "Average Deal Size and Cycle Time",
        filtersForDrilldown: [...filtersForDrilldown, ...drilldownFilters],
        tableType: key,
      });
      if (key === "Pipeline") {
        this.props.getWaterfallDrilldownOnGraph(
          { filters },
          { isRolling: true }
        );
      } else {
        this.props.getKeyMetricsDrilldown(filters, key);
      }
    } else {
      this.props.clearDrilldownData();
    }
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Fiscal Quarter",
          value: this.state.closed_fiscal_quarter.sort(),
        },
      ];
    }
    if (!this.state.closed_fiscal_year.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Fiscal Year",
          value: this.state.closed_fiscal_year.sort(),
        },
      ];
    }
    if (!this.state.rep.includes("All"))
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    if (!this.state.acv !== null && this.state.acv !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: "ACV Greater Than",
          value:
            "$ " +
            d3.format(",")(
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv
            ),
        },
      ];

    if (!this.state.Acct_Vertical.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value: this.state.Acct_Vertical,
        },
      ];
    if (!this.state.Acct_Size_Segment.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Customer Segment",
          value:
            this.props.company === "C0003"
              ? this.state.Acct_Vertical
              : this.state.Acct_Size_Segment,
        },
      ];
    if (!this.state.Cust_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value:
            this.props.company === "C0003"
              ? this.state.Region_Rollup
              : this.state.Cust_Type,
        },
      ];
    if (!this.state.Lead_Source.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Lead Source", value: this.state.Lead_Source },
      ];
    if (!this.state.Opp_Created_By.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state.Solution_Cat.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];

    return filtersArray;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: "",
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      location: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      location: "drilldownOnGraph",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ position: "relative" }}>
        {/* Caption and description */}
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Key Metrics
          </Typography>
          <Typography variant="body1">
            Examine a range of key operational metrics across various
            dimensions.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Key Metrics",
          "Examine a range of key operational metrics across various dimensions."
        )}

        {this.props.funnelFilters !== "" ? (
          <>
            {/* dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={6} sm={6} style={{ paddingTop: 10 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.closed_fiscal_year[0] !== "All"}
                  >
                    <InputLabel
                      id="select-fiscalQuarter-label"
                      style={{
                        color: !this.state.closed_fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Fiscal Quarter:
                    </InputLabel>
                    <Select
                      data-test="closed-fiscal-quarter"
                      labelId="select-fiscalQuarter-label"
                      id="select-fiscalQuarter"
                      value={this.state.closed_fiscal_quarter}
                      onChange={this.handleChange}
                      label="Closed Fiscal Quarter"
                      name="closed_fiscal_quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.closedFiscalQuarters
                          .filter((item) => item.closed_fiscal_quarter !== "")
                          .map((y) => (
                            <MenuItem
                              key={y.closed_fiscal_quarter}
                              value={y.closed_fiscal_quarter}
                            >
                              {y.closed_fiscal_quarter}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} style={{ paddingTop: 10 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.closed_fiscal_quarter[0] !== "All"}
                  >
                    <InputLabel
                      id="select-year-label"
                      style={{
                        color: !this.state.closed_fiscal_year.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Fiscal Year:
                    </InputLabel>
                    <Select
                      labelId="select-year-label"
                      id="select-year"
                      value={this.state.closed_fiscal_year}
                      onChange={this.handleChange}
                      label="Year"
                      name="closed_fiscal_year"
                      data-test="year"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.years
                          .sort()
                          .reverse()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* rep & team */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={4}
            >
              {this.props.funnelFilters !== "" ? (
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 10 }}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={
                        this.props.funnelFilters !== "" &&
                        this.orderRepsByLastName()
                      }
                      getOptionLabel={(option) => option}
                      value={this.state.rep[0]}
                      onChange={this.onRepChange}
                      name="rep"
                      data-test="rep"
                      disabled={!this.state.team.includes("All")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rep:"
                          variant="standard"
                          InputLabelProps={{
                            style: {
                              color: !this.state.rep.includes("All")
                                ? "#4472c4"
                                : "#000",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 10 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={!this.state.rep.includes("All")}
                    >
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.team.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Team:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-team"
                        value={this.state.team}
                        onChange={this.handleChange}
                        label="Team"
                        data-test="team"
                        name="team"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.teams
                            .map((t) => t.Display_Name)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                <Grid item container component={Paper} justify="center">
                  {" "}
                  <Skeleton
                    variant="text"
                    animation="wave"
                    height={40}
                    width={290}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={2}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="select-team-label">
                      Include Qualified Out:
                    </InputLabel>
                    <Select
                      labelId="select-qualified-out-label"
                      id="select-out"
                      value={this.state.qualifiedOut}
                      onChange={this.handleChange}
                      label="qualifiedOut"
                      data-test="qualifiedOut"
                      name="qualifiedOut"
                    >
                      {[
                        { name: "Yes", value: "Yes" },
                        { name: "No", value: "No" },
                      ].map((y) => (
                        <MenuItem key={y.name} value={y.value}>
                          {y.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* ACV and GO */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              lg={2}
            >
              <Grid item spacing={2} xs={8} xl={9} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ paddingTop: 10, height: "0px" }}
                >
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelFilters !== "" &&
                      this.props.funnelFilters.acv.map((d) =>
                        d3.format(",")(String(d.acv))
                      )
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    data-test="acv"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ACV >= :"
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: 4 }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== null && this.state.acv !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={4} xl={3} container justify="flex-end">
                <Button
                  data-test="go"
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                >
                  GO
                </Button>
              </Grid>
            </Grid>

            {/* extra filters 1 */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={12}
              md={12}
              lg={6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={4} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-Cust_Type-label"
                      style={{
                        color: !this.state.Acct_Vertical.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Industry:"}
                    </InputLabel>
                    <Select
                      labelId="select-Cust_Type-label"
                      id="select-Cust_Type"
                      value={this.state.Acct_Vertical}
                      onChange={this.handleChange}
                      name="Acct_Vertical"
                      data-test="Acct_Vertical"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.industries
                          .sort()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 10 }}>
                  {this.props.company === "C0003" ? (
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Acct_Vertical-label"
                        style={{
                          color: !this.state.Acct_Segment.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Customer Segment:
                      </InputLabel>
                      <Select
                        labelId="select-Acct_Vertical-label"
                        id="select-Acct_Vertical"
                        value={this.state.Acct_Segment}
                        onChange={this.handleChange}
                        name="Acct_Segment"
                        data-test="customer_segment"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.custSeg
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Acct_Vertical-label"
                        style={{
                          color: !this.state.Acct_Size_Segment.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Customer Segment:
                      </InputLabel>
                      <Select
                        labelId="select-Acct_Vertical-label"
                        id="select-Acct_Vertical"
                        value={this.state.Acct_Size_Segment}
                        onChange={this.handleChange}
                        name="Acct_Size_Segment"
                        data-test="customer_segment"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.custSeg
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 10 }}>
                  {this.props.company === "C0003" ? (
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Acct_Vertical-label"
                        style={{
                          color: !this.state.Region_Rollup.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        {"Region:"}
                      </InputLabel>
                      <Select
                        labelId="select-Acct_Vertical-label"
                        id="select-Acct_Vertical"
                        value={this.state.Region_Rollup}
                        onChange={this.handleChange}
                        name="Region_Rollup"
                        data-test="Cust_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.regions
                            .sort()
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Acct_Vertical-label"
                        style={{
                          color: !this.state.Cust_Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        {this.props.company === "C0003"
                          ? "Region:"
                          : "Customer Type:"}
                      </InputLabel>
                      <Select
                        labelId="select-Acct_Vertical-label"
                        id="select-Acct_Vertical"
                        value={this.state.Cust_Type}
                        onChange={this.handleChange}
                        name="Cust_Type"
                        data-test="Cust_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.regions
                            .sort()
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* extra filters 2 */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={12}
              md={12}
              lg={this.props.company === "C0003" ? 6 : 6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={this.props.company === "C0003" ? 4 : 4}
                  style={{ paddingTop: 10 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-region-label"
                      style={{
                        color: !this.state.Lead_Source.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Lead Source:
                    </InputLabel>
                    <Select
                      labelId="select-region-label"
                      id="select-region"
                      value={this.state.Lead_Source}
                      onChange={this.handleChange}
                      name="Lead_Source"
                      data-test="leadsource"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.leadSource
                          .sort()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={this.props.company === "C0003" ? 4 : 4}
                  style={{ paddingTop: 10 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-Acct_Vertical-label"
                      style={{
                        color: !this.state.Opp_Created_By.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Opportunity Created By:
                    </InputLabel>
                    <Select
                      labelId="select-Acct_Vertical-label"
                      id="select-Acct_Vertical"
                      value={this.state.Opp_Created_By}
                      onChange={this.handleChange}
                      name="Opp_Created_By"
                      data-test="opp_created_by"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.oppCreatedBy
                          .sort()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                {this.props.company !== "C0003" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 10 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Acct_Vertical-label"
                        style={{
                          color: !this.state.Solution_Cat.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Solution Category:
                      </InputLabel>
                      <Select
                        labelId="select-Acct_Vertical-label"
                        id="select-Acct_Vertical"
                        value={this.state.Solution_Cat}
                        onChange={this.handleChange}
                        name="Solution_Cat"
                        data-test="solution_category"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.solutionCat
                            .sort()
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {this.props.company === "C0003" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 10 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Booking_Type_NL_CS_US.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-Acct_Vertical-label"
                      >
                        Opportunity Type:
                      </InputLabel>
                      <Select
                        value={this.state.Booking_Type_NL_CS_US}
                        onChange={this.handleChange}
                        name="Booking_Type_NL_CS_US"
                        data-test="Booking_Type_NL_CS_US"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.Booking_Type_NL_CS_US.sort(
                            (a, b) =>
                              a["Booking_Type_NL_CS_US"] <
                              b["Booking_Type_NL_CS_US"]
                                ? -1
                                : a["Booking_Type_NL_CS_US"] >
                                  b["Booking_Type_NL_CS_US"]
                                ? 1
                                : 0
                          )
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item container justify="center" component={Card}>
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          className={classes.grid}
          container
          justify="center"
          style={{ marginTop: "1rem" }}
        >
          <Grid
            item
            xs={12}
            style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
          >
            <Card style={{ margin: "auto", width: "100%" }} raised={true}>
              {this.props.funnelData !== "" ? (
                <Grid item container xs={12} justify="left">
                  <Grid item xs={12}>
                    <Typography
                      variant="h2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      Attainment
                    </Typography>
                  </Grid>
                  <KeyMetricsTable
                    type={"attainment"}
                    accountSegmentRollupData={
                      this.props.funnelData.attainmentAcctSegmentRollupData
                    }
                    oppData={this.props.funnelData.attainmentOppData}
                    totalData={this.props.funnelData.totalAttainmnetData}
                    quarter={this.state.activeTimeFilter}
                    totalSegmentData={this.props.funnelData.totalSegmentData}
                    handleChangeLocation={this.handleChangeLocation}
                  />
                </Grid>
              ) : (
                <Grid item container justify="center">
                  <Skeleton
                    variant="react"
                    animation="wave"
                    height={120}
                    width="90%"
                    style={{ margin: "1rem" }}
                  />
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.grid} container justify="center">
          <Grid
            item
            xs={12}
            style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
          >
            <Card style={{ margin: "auto", width: "100%" }} raised={true}>
              {this.props.funnelData !== "" ? (
                <Grid item container xs={12} justify="left">
                  <Grid item xs={12}>
                    <Typography
                      variant="h2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      Average Deal Size and Cycle Time
                    </Typography>
                  </Grid>
                  <KeyMetricsTable
                    type={"avg-deal-size"}
                    accountSegmentRollupData={
                      this.props.funnelData.avgDealsAcctSegmentRollupData
                    }
                    oppData={this.props.funnelData.avgDealsOppData}
                    totalData={this.props.funnelData.totalAvgDealsData}
                    quarter={this.state.activeTimeFilter}
                    totalSegmentData={
                      this.props.funnelData.totalSegmentAvgDealsData
                    }
                    handleChangeLocation={this.handleChangeLocation}
                  />
                </Grid>
              ) : (
                <Grid item container justify="center">
                  <Skeleton
                    variant="react"
                    animation="wave"
                    height={120}
                    width="90%"
                    style={{ margin: "1rem" }}
                  />
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.grid} container justify="center">
          <Grid
            item
            xs={12}
            style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
          >
            <Card style={{ margin: "auto", width: "100%" }} raised={true}>
              {this.props.funnelData !== "" ? (
                <Grid item container xs={12} justify="left">
                  <Grid item xs={12}>
                    <Typography
                      variant="h2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      Partner Deals
                    </Typography>
                  </Grid>
                  <KeyMetricsPartnerDealsTable
                    type={"parent-deals"}
                    partenerDetailsData={
                      this.props.funnelData.partenerDetailsData
                    }
                    totalData={this.props.funnelData.totalPartenerData}
                    quarter={this.state.activeTimeFilter}
                    handleChangeLocation={this.handleChangeLocation}
                  />
                </Grid>
              ) : (
                <Grid item container justify="center">
                  <Skeleton
                    variant="react"
                    animation="wave"
                    height={120}
                    width="90%"
                    style={{ margin: "1rem" }}
                  />
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.grid} container justify="center">
          <Grid
            item
            xs={12}
            style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
          >
            <Card style={{ margin: "auto", width: "100%" }} raised={true}>
              {this.props.funnelData !== "" ? (
                <Grid item container xs={12} justify="left">
                  <Grid item xs={12}>
                    <Typography
                      variant="h2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      Demand Gen
                    </Typography>
                  </Grid>
                  <SALSQLTable
                    type={"DemandGen"}
                    demandGenData={this.props.funnelData.demandGenData}
                    quarter={this.state.activeTimeFilter}
                    handleChangeLocation={this.handleChangeLocation}
                  />
                </Grid>
              ) : (
                <Grid item container justify="center">
                  <Skeleton
                    variant="react"
                    animation="wave"
                    height={120}
                    width="90%"
                    style={{ margin: "1rem" }}
                  />
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.grid} container justify="center">
          <Grid
            item
            xs={12}
            style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
          >
            <Card style={{ margin: "auto", width: "100%" }} raised={true}>
              {this.props.funnelData !== "" ? (
                <Grid item container xs={12} justify="left">
                  <Grid item xs={12}>
                    <Typography
                      variant="h2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "1rem",
                      }}
                    >
                      Open Pipeline
                    </Typography>
                    <Typography
                      variant="h2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "1rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      (All Close Fiscal Quarters as of the last day of the
                      Fiscal Quarter)
                    </Typography>
                  </Grid>
                  <PipelineMetricsTable
                    type={"Pipeline"}
                    pipelineData={this.props.funnelData.piplineData}
                    quarter={this.state.activeTimeFilter}
                    handleChangeLocation={this.handleChangeLocation}
                  />
                </Grid>
              ) : (
                <Grid item container justify="center">
                  <Skeleton
                    variant="react"
                    animation="wave"
                    height={120}
                    width="90%"
                    style={{ margin: "1rem" }}
                  />
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.header}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              {["Partner", "DemandGen", "Pipeline"].includes(
                this.state.tableType
              ) ? (
                <DrilldownV1
                  header={this.state.header}
                  body={this.props.drilldownOnGraphData}
                  company={this.props.company}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                  Value_Label={this.props.Value_Label}
                />
              ) : (
                <Drilldown
                  header={this.state.header}
                  body={this.props.drilldownOnGraphData}
                  company={this.props.company}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                  Value_Label={this.props.Value_Label}
                />
              )}
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.location === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

function mapStateToProps(state) {
  const {
    funnelFilters,
    funnelData,
    persistentAlert,
    user,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelFilters,
    funnelData,
    persistentAlert,
    company: user.company,
    Value_Label: user.Value_Label,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    currentFilters,
    ExpectedAcvData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFunnelFilters: () => {
    dispatch({ type: "get_funnel_filters_request" });
    appService.getKeyMetricsFilters().then(
      (json) => {
        dispatch({ type: "get_funnel_filters_success", json });
        dispatch({ type: "get_funnel_data_request" });
        const defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message.closedFiscalQuarters,
          "closed_fiscal_quarter"
        );
        let filters = [];
        if (defaultFiscalQuarter.length > 0) {
          filters = [
            { name: "closed_fiscal_quarter", value: defaultFiscalQuarter },
            {
              name: "Booking_Type_NL_CS_US",
              value: ["Cross Sell", "New Logo"],
            },
          ];
        }
        dispatch({ type: "current_selected_filters", filters });

        appService
          .getKeyMetricsData(filters, "closed_fiscal_quarter", "No")
          .then(
            (json) => {
              dispatch({ type: "get_funnel_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_funnel_data_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_funnel_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_filters_failure", error });
      }
    );
  },
  getFunnelDataNew: (filters, key, key2) => {
    dispatch({ type: "get_funnel_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getKeyMetricsData(filters, key, key2).then(
      (json) => {
        dispatch({ type: "get_funnel_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_data_failure", error });
      }
    );
  },
  getKeyMetricsDrilldown: (filters, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getKeyMetricsDrilldown(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getWaterfallDrilldownOnGraph: (filters, isRolling) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService
      .getPipelineTrendsRollingDrilldownOnGraph(filters, isRolling)
      .then(
        (json) => {
          // console.log(json.message)
          let tempOpportunities = json.message.opportunities[1].map(
            (item, index) => {
              let x = {
                ...item,
              };
              return x;
            }
          );

          tempOpportunities.sort((a, b) =>
            a.Acv_At_Time_T > b.Acv_At_Time_T ? -1 : 1
          );

          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json = {
            ...json,
            message: {
              ...json.message,
              opportunities: tempOpportunities,
              count_acv: json.message.opportunities[0],
              columns: json.message.columns,
            },
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(TableRow);

const connectedKeyMetrics = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(KeyMetrics));
export { connectedKeyMetrics as KeyMetrics };
