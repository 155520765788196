import { ProtectedRouteProps } from "../GuardedRoute";

import { useSelector } from "react-redux";
import { connect } from 'react-redux'

function useIsAuthenticated(props) {

  const defaultProtectedRouteProps = {
    isAuthenticated: props.loggedIn,
    authenticationPath: '/login',
  };
  return defaultProtectedRouteProps
}
const mapStateToProps = state => {
  const { loggedIn } = state.app
  return {
    loggedIn
  }
};
const mapDispatchToProps = null;
export default connect(mapStateToProps, mapDispatchToProps)(useIsAuthenticated)