import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  withStyles,
  Grid,
} from "@material-ui/core";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {
  countOrACVFormatter,
  StyledTableRow,
  _isNaN,
  getUniqueValues,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const styles = makeStyles({
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px 5px",
    color: "inherit",
  },
  nonScoreCell: {
    fontWeight: 400,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  repHeader: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
    fontWeight: 600,
  },
  repHeaderDrilldown: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
    cursor: "pointer",
  },
  repNameMargin: {
    paddingLeft: "1.75rem",
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    padding: "0.25rem",
  },
  "& .MuiTableCell-sizeSmall": {
    padding: "6px 24px 6px 10px;",
  },
});

export default function PipelineCoverageCustTypeTable(props) {
  console.log(props);
  const classes = styles();
  const tableRef = React.createRef();

  const teams = props.table
    .filter((s) => !["company", "team"].includes(s.name))
    .map((s) => s.name)
    .filter(getUniqueValues)
    .sort();

  const splitup = props.splitup;

  const countAndOpps = (
    <>
      {Array(splitup.length + 1)
        .fill()
        .map((item) => (
          <>
            <TableCell
              align="center"
              className={classes.repHeader}
              colSpan={1}
              style={{
                background: "#4471C4",
                color: "white",
                border: "0.5px solid lightgrey",
              }}
            >
              # of Opps
            </TableCell>
            {props.showSeats === 'Y' && <TableCell
              align="center"
              className={classes.repHeader}
              colSpan={1}
              style={{
                background: "#4471C4",
                color: "white",
                border: "0.5px solid lightgrey",
              }}
            >
              Seats
            </TableCell>}

            <TableCell
              align="center"
              className={classes.repHeader}
              colSpan={1}
              style={{
                background: "#4471C4",
                color: "white",
                border: "0.5px solid lightgrey",
              }}
            >
              {/* ACV */}
              {`${props.Value_Label}`}
            </TableCell>
          </>
        ))}
    </>
  );

  return (
    <>
      {(props.table.length >= 1 || props.segmentRow.length >= 1) && (
        <>
          <TableContainer
            style={{ padding: "1rem", width: "100%" }}
            align="center"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
            <Table
              ref={tableRef}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto" }}
              data-test={
                props.isrepScoreCard
                  ? `rep-${props.tableName}`
                  : props.tableName
              }
            >
              <TableHead></TableHead>
              <TableBody>
                <StyledTableRow>
                  <TableCell
                    align="center"
                    className={classes.tableHeader}
                    rowSpan={2}
                    style={{
                      background: "#4471C4",
                      height: 70,
                      minWidth: 160,
                      maxWidth: 160,
                      border: "0.5px solid lightgrey",
                      color: "white",
                    }}
                  >
                    {props.isrepScoreCard ? "Rep" : "Team / Rep"}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableHeader}
                    colSpan={props.showSeats === 'Y' ? 3 : 2}
                    style={{
                      background: "#4471C4",
                      color: "white",
                      border: "0.5px solid lightgrey",
                    }}
                  >{`Qualified Pipeline ${
                    props.type === "CFQ" ? "Current" : "Next"
                  } Qtr.`}</TableCell>
                  {splitup.map((s) => (
                    <>
                      <TableCell
                        align="center"
                        className={classes.tableHeader}
                        colSpan={props.showSeats === 'Y' ? 3 : 2}
                        style={{
                          background: "#4471C4",
                          color: "white",
                          border: "0.5px solid lightgrey",
                        }}
                      >
                        {s}
                      </TableCell>
                    </>
                  ))}
                </StyledTableRow>
                <TableRow>{countAndOpps}</TableRow>
                {!props.isrepScoreCard && (
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={classes.repHeader}
                      style={{
                        border: "0.5px solid lightgrey",
                        fontWeight: 600,
                      }}
                      colSpan={1}
                    >
                      {props.selectedTeam.includes("All") &&
                      props.selectedRep.includes("All")
                        ? "Company"
                        : !props.selectedTeam.includes("All")
                        ? props.selectedTeam.length > 1
                          ? "Teams"
                          : "Team"
                        : "Total"}
                    </TableCell>
                    {["total", ...splitup].map((s) => {
                      const rowdata = props.segmentRow.filter(
                        (i) => i.type === s
                      );
                      const count = rowdata.length >= 1 ? rowdata[0].count : 0;
                      const acv = rowdata.length >= 1 ? rowdata[0].acv : 0;
                      const seats = rowdata.length >= 1 ? rowdata[0].seats : 0;

                      return (
                        <>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                teams,
                                "open",
                                props.type,
                                false,
                                s !== "total" ? [s] : undefined
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              paddingRight: "0.5rem",
                              cursor: "pointer",
                            }}
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(count)
                              ? countOrACVFormatter(count, false)
                              : 0}
                          </TableCell>
                          {
                            props.showSeats === 'Y' && <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                teams,
                                "open",
                                props.type,
                                false,
                                s !== "total" ? [s] : undefined
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              paddingRight: "0.5rem",
                              cursor: "pointer",
                            }}
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(count)
                              ? countOrACVFormatter(seats, false)
                              : 0}
                          </TableCell>
                          }
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                teams,
                                "open",
                                props.type,
                                false,
                                s !== "total" ? [s] : undefined
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              paddingRight: "0.5rem",
                              cursor: "pointer",
                            }}
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(acv)
                              ? countOrACVFormatter(Math.round(acv), true)
                              : 0}
                          </TableCell>
                        </>
                      );
                    })}
                  </StyledTableRow>
                )}
                {teams.map((team) => {
                  return (
                    <StyledTableRow>
                      <TableCell
                        align="left"
                        className={classes.repHeader}
                        style={{
                          border: "0.5px solid lightgrey",
                          paddingLeft: "1.75rem",
                          minWidth: 160,
                        }}
                        colSpan={1}
                      >
                        {team}
                      </TableCell>
                      {["total", ...splitup].map((s) => {
                        const rowdata = props.table.filter(
                          (i) => i.type === s && i.name === team
                        );
                        const count =
                          rowdata.length >= 1 ? rowdata[0].count : 0;
                        const acv = rowdata.length >= 1 ? rowdata[0].acv : 0;

                        const seats = rowdata.length >= 1 ? rowdata[0].seats : 0;

                        return (
                          <>
                            <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  [team],
                                  "open",
                                  props.type,
                                  false,
                                  s !== "total" ? [s] : undefined
                                )
                              }
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                paddingRight: "0.5rem",
                                cursor: "pointer",
                              }}
                              colSpan={1}
                            >
                              {![null, undefined, ""].includes(count)
                                ? countOrACVFormatter(count, false)
                                : 0}
                            </TableCell>
                            {
                              props.showSeats === 'Y' && <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  [team],
                                  "open",
                                  props.type,
                                  false,
                                  s !== "total" ? [s] : undefined
                                )
                              }
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                paddingRight: "0.5rem",
                                cursor: "pointer",
                              }}
                              colSpan={1}
                            >
                              {![null, undefined, ""].includes(count)
                                ? countOrACVFormatter(seats, false)
                                : 0}
                            </TableCell>
                            }
                            <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  [team],
                                  "open",
                                  props.type,
                                  false,
                                  s !== "total" ? [s] : undefined
                                )
                              }
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                paddingRight: "0.5rem",
                                cursor: "pointer",
                              }}
                              colSpan={1}
                            >
                              {![null, undefined, ""].includes(acv)
                                ? countOrACVFormatter(Math.round(acv), true)
                                : 0}
                            </TableCell>
                          </>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

// background:'#f4b183'
