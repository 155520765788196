import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import { appService } from "../../../App/app.service";
import { connect } from "react-redux";

import Drilldown from "../../../components/Drilldown/DilldownV5";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
  getDefaultSalesType,
} from "../../../util/customFunctions";
import SingleLevelDrilldown from "../../../components/Drilldown/SingleLevelDrilldown";

import DynamicTableSegment from "../../../components/DyanmicTableSegment";
import { deSnake } from "../../../util/dataFormatter";

import ExpectedAcvPage from "../../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import DataVisualizer from "../../../components/ChartBuilder/DataVisualizer";
import DataTable from "./DataTable";
import addHeaderdescription from "../../../components/HeaderDescription";

const styles = (theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
});
class ChurnAnalysis extends Component {
  filters = [
    "Financial_Impact_Date_Fiscal_Quarter",
    "Customer_Success_Manager_Name",
    "Customer_Health__c",
    "Reason__c",
  ];
  state = {
    Financial_Impact_Date_Fiscal_Quarter: ["All"],
    Customer_Success_Manager_Name: ["All"],
    disabled: ["month"],
    drilldownHeader: "Qualified Pipeline Created",
    Customer_Health__c: ["All"],
    Reason__c: ["All"],
  };

  componentDidMount() {
    this.props.getchurnAnalysisFilters();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.churnAnalysisFilters !== "" &&
      prevProps.churnAnalysisFilters === ""
    ) {
      //     const stageSequence =  getDefaultSelectedStage(this.props.churnAnalysisFilters.filter(f => f.type === 'stage'))
      //     let stage_names = []
      //     this.props.churnAnalysisFilters.map((i) => {
      //     if (i.sequence !== undefined) {
      //         if (i.sequence >= stageSequence)
      //             stage_names = [...stage_names, i.stage_name]
      //     }
      // })

      this.setState({
        ...prevState,
        Financial_Impact_Date_Fiscal_Quarter: getDefaultSelectedFiscalQuarters(
          this.props.churnAnalysisFilters.quarters,
          "Financial_Impact_Date_Fiscal_Quarter"
        ),
        filtersForDrilldownPcfq: getDefaultSelectedFiscalQuarters(
          this.props.churnAnalysisFilters.quarters,
          "Financial_Impact_Date_Fiscal_Quarter"
        ),
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  // handleChangeEarliestOpenStage = e => {
  //     this.setState({
  //         ...this.state,
  //         [e.target.name]: e.target.value,
  //     })
  // }

  handleChangeLocation = (location, key, quarter) => {
    if (location !== "overview") {
      this.props.setTable("ChurnAnalysis");
      let filters = [];

      filters = [
        {
          name: "Financial_Impact_Date_Fiscal_Quarter",
          value: typeof quarter === "string" ? [quarter] : quarter,
          string: true,
        },
      ];

      let reqKey =
        key === "Total ARR Managed"
          ? "total_ARR"
          : key === "Downsell / Churn confirmed"
          ? "confirmed"
          : key === "Risk of Downsell / Churn"
          ? "risk"
          : "qualified";
      let localFilters = this.props.currentFilters;
      filters = [...filters, ...localFilters];
      // console.log(filters.filter(f => f.name === 'Customer_Success_Manager_Name')[0].value)
      let filtersForDrill = [
        {
          title:
            key === "Qualified Pipeline Created"
              ? "Qualified Initial Fiscal Quarter"
              : "Churn / Downsell Quarter",
          value: key === "Total ARR Managed" ? ["All"] : quarter,
        },
        {
          title: "Customer Success Manager",
          value:
            filters.filter((f) => f.name === "Customer_Success_Manager_Name")
              .length > 0
              ? filters.filter(
                  (f) => f.name === "Customer_Success_Manager_Name"
                )[0].value
              : ["All"],
        },
        {
          title: "Customer Health",
          value:
            filters.filter((f) => f.name === "Customer_Health__c").length > 0
              ? filters.filter((f) => f.name === "Customer_Health__c")[0].value
              : ["All"],
        },
        {
          title: "Reason",
          value:
            filters.filter((f) => f.name === "Reason__c").length > 0
              ? filters.filter((f) => f.name === "Reason__c")[0].value
              : ["All"],
        },
      ];

      this.props.getChurnAnalysisDrilldown(filters, reqKey);

      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        drilldownHeader: key,
        filtersForDrilldown: filtersForDrill,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
      secondLevelLocation: "",
    });
    this.props.setTable("");
  };

  variableCols = {
    Financial_Impact_Date_Fiscal_Quarter:
      "Financial_Impact_Date_Fiscal_Quarter",
    Customer_Success_Manager_Name: "Customer_Success_Manager_Name",
    Customer_Health__c: "Customer_Health__c",
    Reason__c: "Reason__c",
  };

  staticCustSegment =
    this.props.company === "C0002"
      ? [
          { value: "<=$100M", displayValue: "<=$100M" },
          { value: "$100M-$1B", displayValue: "$100M-$1B" },
          { value: ">$1B", displayValue: ">$1B" },
        ]
      : [
          { value: "<=$25M", displayValue: "<=$25M" },
          { value: "$25-50M", displayValue: "$25M-50M" },
          { value: "$50-250M", displayValue: "$50M-250M" },
          { value: "$250-500M", displayValue: "$250M-500M" },
          { value: "$500M-2B", displayValue: "$500M-2B" },
          { value: ">$2B", displayValue: ">$2B" },
        ];

  orderRepsByLastName = () => {
    let reps = this.props.churnAnalysisFilters.CSMName.map(
      (r) => r.Customer_Success_Manager_Name
    ).map((rep) => {
      const res = rep.split(" ", 2);
      return {
        firstName: res[0],
        lastName: res[1],
        fullName: rep,
        sortName: `${res[0]} ${res[1]}`,
      };
    });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.churnAnalysisFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      Customer_Success_Manager_Name: [value ? value : "All"],
    });
  };

  onCreatedByNameChange = (event, value) => {
    this.setState({
      ...this.state,
      Created_by_name: [value ? value : "All"],
    });
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    var filters1 = this.filters;
    let filters = filters1
      .filter(getUniqueValues)
      .map((f) => {
        return {
          name: f,
          value:
            f === "sameTimePriorQtr"
              ? this.state[f] === "None"
                ? ["All"]
                : this.state[f]
              : f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.churnAnalysisFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.churnAnalysisFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));

    this.setState({
      ...this.state,
      filtersForDrilldownPcfq: this.passFiltersForDrilldown(),
    });
    this.props.getchurnAnalysisData(filters);
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.Financial_Impact_Date_Fiscal_Quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        this.state.Financial_Impact_Date_Fiscal_Quarter,
      ];
    }

    if (this.state.Financial_Impact_Date_Fiscal_Quarter.includes("All")) {
      filtersArray = [...filtersArray, "All"];
    }

    return filtersArray[0];
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    console.log(this.props.churnAnalysisFilters);
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid className={classes.grid} item xs={12} style={{ marginBottom: 10 }}>
                    <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Customer Churn Analysis</Typography>
                    <Typography variant='body1'>
                        Examine the open pipeline across multiple dimensions to spot trends and patterns.
                    </Typography>
                </Grid> */}
        {addHeaderdescription(
          "Customer Churn Analysis",
          "Analyze customer churn and Net Retention."
        )}
        {this.props.churnAnalysisFilters !== "" ? (
          <Grid item container xs={12}>
            <Grid container item style={{ padding: 10 }} xs={2} md={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={
                      this.props.churnAnalysisFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.Customer_Success_Manager_Name[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    data-test="rep"
                    // disabled={!this.state.team.includes('All')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer Success Manager:"
                        variant="standard"
                        InputLabelProps={{
                          style: {
                            color:
                              !this.state.Customer_Success_Manager_Name.includes(
                                "All"
                              )
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                      />
                    )}
                  />

                  {/* <FormControl fullWidth className={classes.formControl} >
                                    <InputLabel className={classes.filterLabel} style={{ color: !this.state[this.variableCols.Customer_Success_Manager_Name].includes('All') ? '#4472c4' : '#000' }} id="select-Customer-Success-Manager-Name-label">Customer Success Manager:</InputLabel>
                                    <Select
                                        value={this.state[this.variableCols.Customer_Success_Manager_Name]}
                                        onChange={this.handleChange}
                                        name={this.variableCols.Customer_Success_Manager_Name}
                                        data-test="Customer_Success_Manager_Name"
                                        multiple
                                    >
                                        <MenuItem value={'All'} >All</MenuItem>
                                        
                                        {this.props.churnAnalysisFilters !== '' && this.props.churnAnalysisFilters.CSMName.filter(item => item.Customer_Success_Manager_Name !== "").map(y => <MenuItem key={y.Customer_Success_Manager_Name} value={y.Customer_Success_Manager_Name}>{y.Customer_Success_Manager_Name}</MenuItem>)}
                                    </Select>
                                </FormControl> */}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.Financial_Impact_Date_Fiscal_Quarter
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Churn / Downsell Quarter:
                    </InputLabel>
                    <Select
                      value={
                        this.state[
                          this.variableCols.Financial_Impact_Date_Fiscal_Quarter
                        ]
                      }
                      onChange={this.handleChange}
                      name={
                        this.variableCols.Financial_Impact_Date_Fiscal_Quarter
                      }
                      data-test="Financial_Impact_Date_Fiscal_Quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>

                      {this.props.churnAnalysisFilters !== "" &&
                        this.props.churnAnalysisFilters.quarters
                          .filter(
                            (item) =>
                              item.Financial_Impact_Date_Fiscal_Quarter !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Financial_Impact_Date_Fiscal_Quarter}
                              value={y.Financial_Impact_Date_Fiscal_Quarter}
                            >
                              {y.Financial_Impact_Date_Fiscal_Quarter}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item style={{ padding: 10 }} xs={2} md={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.Customer_Health__c
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-customer-health-label"
                    >
                      Cutomer Health:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Customer_Health__c]}
                      onChange={this.handleChange}
                      name={this.variableCols.Customer_Health__c}
                      data-test="Customer_Health__c"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>

                      {this.props.churnAnalysisFilters !== "" &&
                        this.props.churnAnalysisFilters.customerHealth
                          .filter((item) => item.Customer_Health__c !== "")
                          .map((y) => (
                            <MenuItem
                              key={y.Customer_Health__c}
                              value={y.Customer_Health__c}
                            >
                              {y.Customer_Health__c}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.Reason__c
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-Reason-label"
                    >
                      Reason:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Reason__c]}
                      onChange={this.handleChange}
                      name={this.variableCols.Reason__c}
                      data-test="Reason__c"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>

                      {this.props.churnAnalysisFilters !== "" &&
                        this.props.churnAnalysisFilters.Reason.filter(
                          (item) => item.Reason__c !== ""
                        ).map((y) => (
                          <MenuItem key={y.Reason__c} value={y.Reason__c}>
                            {y.Reason__c}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid item xs={12} className={classes.grid} container justify="center">
          <Grid
            item
            xs={12}
            style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
          >
            <Card style={{ margin: "auto", width: "100%" }} raised={true}>
              {this.props.churnAnalysisData !== "" ? (
                <>
                  <Grid item container xs={12} justify="center">
                    <Typography
                      variant="h2"
                      style={{
                        margin: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      Customer Churn Analysis
                    </Typography>

                    <DataTable
                      data={this.props.churnAnalysisData.data}
                      uniqueQuarters={
                        this.props.churnAnalysisData.uniqueFiscalQuarters
                      }
                      uniqueLabels={this.props.churnAnalysisData.uniqueLabels}
                      totalArr={this.props.churnAnalysisData.total_Arr_managed}
                      handleChangeLocation={this.handleChangeLocation}
                      dataTest="churn-analysis-tabel"
                    />
                  </Grid>
                </>
              ) : (
                <Grid item container justify="center">
                  <Skeleton
                    variant="react"
                    animation="wave"
                    height={120}
                    width="90%"
                    style={{ margin: "1rem" }}
                  />
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.drilldownHeader}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
              countCol={
                this.state.drilldownHeader === "Qualified Pipeline Created"
                  ? "opportunities"
                  : "accounts"
              }
            >
              <Drilldown
                countCol={
                  this.state.drilldownHeader === "Qualified Pipeline Created"
                    ? "opportunities"
                    : "accounts"
                }
                header={this.state.drilldownHeader}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    churnAnalysisFilters,
    churnAnalysisData,
    user,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    churnAnalysisFilters,
    churnAnalysisData,
    company: user.company,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },
  getChurnAnalysisDrilldown: (filters, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getChurnAnalysisDrilldown(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              //closed_date: item.closed_date !== undefined ? item.closed_date.split('T')[0] : item.projected_close_date.split('T')[0],
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getchurnAnalysisFilters: () => {
    dispatch({ type: "get_churn_analysis_filters_request" });
    appService.getChurnAnalysisFilters().then(
      (json) => {
        dispatch({ type: "get_churn_analysis_filters_success", json });
        dispatch({ type: "get_churn_analysis_data_request" });
        const defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message.quarters,
          "Financial_Impact_Date_Fiscal_Quarter"
        );
        let filters = [];
        if (defaultFiscalQuarter.length > 0) {
          filters = [
            {
              name: "Financial_Impact_Date_Fiscal_Quarter",
              value: defaultFiscalQuarter,
            },
          ];
        }
        dispatch({ type: "current_selected_filters", filters });

        appService.getChurnAnalysisData(filters).then(
          (json) => {
            dispatch({ type: "get_churn_analysis_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_churn_analysis_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_churn_analysis_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_churn_analysis_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_churn_analysis_filters_failure", error });
      }
    );
  },
  getchurnAnalysisData: (filters) => {
    dispatch({ type: "get_churn_analysis_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getChurnAnalysisData(filters).then(
      (json) => {
        dispatch({ type: "get_churn_analysis_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_churn_analysis_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_churn_analysis_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedChurnAnalysis = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ChurnAnalysis));
export default connectedChurnAnalysis;
