import { Button, Grid } from '@material-ui/core'
import React from 'react'

export default function GoButton(props) {
    return (
        <Grid container item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button variant='contained' color='primary' onClick={props.handleGo}>GO</Button>
        </Grid>
    )
}
