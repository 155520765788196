import { Paper, Grid } from '@material-ui/core'
import React from 'react'

function FilterContainer(props) {
    return (
        <Grid justify="flex-start" spacing={2} item xs={props.filters.length === 1 ? 3 :12} container component={Paper} style={props.pipeStyle ? {paddingBottom: 5} : {}}>
            {props.filters.map(filter => <Grid container item xs={(12 / (props.filters.length))}>{filter}</Grid>)}
        </Grid>
    )
}

export default FilterContainer
