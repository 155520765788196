import { FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import React from 'react'
import { customSortedOppType } from '../util/customSortings';

export default function OppotunityType(props) {
    const sortedOppType = customSortedOppType
    const missingOppType = props.funnelCompareFilters.filter(y => y.type === "Booking_Type_NL_CS_US" && y["Booking_Type_NL_CS_US"] !== '').map(item => item.Booking_Type_NL_CS_US).filter(function (obj) { return sortedOppType.indexOf(obj) == -1; })
    const finalOppType = [...sortedOppType, ...missingOppType]
    return (
        <Grid item xs={12} >
            <FormControl fullWidth >
                <InputLabel style={{ color: !props.Booking_Type_NL_CS_US.includes('All') ? '#4472c4' : '#000' }} id="select-vfiscalQuarter-label">Opportunity Type:</InputLabel>
                <Select
                    labelId="select-vBooking_Type_NL_CS_US"
                    id="select-cBooking_Type_NL_CS_US"
                    value={props.Booking_Type_NL_CS_US}
                    onChange={props.handleChange}
                    label="Booking_Type_NL_CS_US"
                    name='Booking_Type_NL_CS_US'
                    data-test='Booking_Type_NL_CS_US'
                    multiple
                >
                    <MenuItem value={'All'} >All</MenuItem>

                    {finalOppType.map(opp => <MenuItem key={opp} value={opp}>{opp}</MenuItem>)}
                </Select>

            </FormControl>
        </Grid>

    )
}
