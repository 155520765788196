import { Grid, makeStyles, Typography } from '@material-ui/core';
import { LegendPosition } from 'ag-grid-community'
import React from 'react'
const useStyle = makeStyles(theme => ({
    square: {
        height: 20, width: 20
    },
}))
export default function CompetitorPerformanceLegend(props) {
    const { legends } = props;
    const classes = useStyle()



    return (
        <Grid container justify="center" >
            <Grid item container justify="flex-start" style={{ paddingLeft: "3rem", marginRight: "-5rem", marginBottom: "2rem" }} xs={window.innerWidth < 1366 ? 11 : 12}>
                {legends.map((item, i) => (
                    <Grid key={i} item xs={4} lg={3} container justify="flex-start" alignItems="center" style={{marginBottom: "1rem"}} >
                        <Grid item style={{ height: props.square, width: props.square, background: item.color, opacity: item.color === '#BF9000' ? 0.3 : props.legendOpacity === undefined ? 1 : props.legendOpacity }}></Grid>
                        <Grid item style={{marginLeft: "0.4rem"}}>
                            <Typography align="center">{item.text}</Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}
