import React from "react";
import {
  Card,
  Paper,
  CardContent,
  InputAdornment,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  CardHeader,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Link,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Fragment } from "react";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import NumberFormat from "react-number-format";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import FunnelDrilldownBody from "../CreatedFunnel/FunnelDrilldownBody";
import OpenDrilldownBody from "../CreatedFunnel/OpenDrilldownBody";
import OpenDrilldownBody2 from "../CreatedFunnel/OpenDrilldownBody2";
// import FunnelDrilldownOnOpenBody from './FunnelDrilldownOnOpenBody'
import { AgGridReact } from "ag-grid-react";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: { width: "100%" },
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  // filterLabel: {
  //     color: 'black', fontSize: '0.9375rem', paddingTop: 5
  // },
  // disabledFilterLabel: {
  //     color: 'black', fontSize: '0.9375rem', paddingTop: 5
  // },
  mainTable: {
    // maxWidth: '100%',
    // [theme.breakpoints.up(1250)]: {
    //     width: 1000,
    // },
    // [theme.breakpoints.up(1900)]: {
    //     width: 1400,
    // }
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  // formControl: {
  //     maxWidth: 250
  // },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  blackLabel: {
    color: "black",
  },
  removeMB: {
    marginBottom: 0,
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
  extraFilters: {
    [theme.breakpoints.up("xx")]: {
      maxWidth: "99%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "49%",
    },
  },
});

const nearestCompletedFiscalQuarter = () => {
  let year = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  if (currentMonth < 3) {
    return year - 1 + "-Q4";
  } else if (currentMonth > 2 && currentMonth < 6) {
    return year + "-Q1";
  } else if (currentMonth > 5 && currentMonth < 9) {
    return year + "-Q2";
  } else {
    return year + "-Q3";
  }
};

const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};

const formatter = (value) => {
  if (_isNaN(value) || value === null) return 0;
  return d3.format(",")(value.toFixed(1));
};

const stringNumFormatter = (value) => {
  if (_isNaN(value) || value === null) return 0;
  return d3.format(",")(Math.round(value));
};

const currencyFormatter = (value) => {
  if (_isNaN(value) || value === null) return 0;
  return "$ " + d3.format(",")(Math.round(value));
};

const dateAndTimeFormatter = (params) => {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
    year: "numeric",
  }).format(new Date(params));
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="$"
      allowNegative={false}
    />
  );
}

class Funnel extends React.Component {
  tableRef = React.createRef();

  state = {
    closed_year: ["All"],
    closed_month: ["All"],
    closed_fiscal_quarter: ["All"],
    projected_close_calendar_year: ["All"],
    projected_close_calendar_month: ["All"],
    projected_close_fiscal_quarter: ["All"],
    validated_year: ["All"],
    validated_month: ["All"],
    validated_fiscal_quarter: ["All"],
    rep: ["All"],
    Acct_Vertical: ["All"],
    Acct_Segment: ["All"],
    Region: ["All"],
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    Solution_Cat: ["All"],
    Week_Ending:
      this.props.funnelFilters === ""
        ? ["All"]
        : [
            this.props.funnelFilters.filter(
              (y) => y.type === "Week_Ending" && y["Week_Ending"] !== ""
            )[
              this.props.funnelFilters.filter(
                (y) => y.type === "Week_Ending" && y["Week_Ending"] !== ""
              ).length - 1
            ].Week_Ending,
          ],
    Old_Value: "0",
    New_Value: "0",
    team: ["All"],
    timeFrame: "quarter",
    disabled: [
      // 'year', 'month', 'quarter', 'team', 'acv'
    ],
    repTeam: "rep",
    acv: null,
    acvPreset: "All",
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
    uniqueQuarters: [],
    strengthOfSale: [],

    // data: {
    //     stages: [
    //         { label: 'Suspect', noOfOpps: 419, ACV: 17575757, avgACV: 41656, avgDaysOpen: 196 },
    //         { label: 'Qualified', noOfOpps: 193, ACV: 9845574, avgACV: 50451, avgDaysOpen: 140 },
    //         { label: 'Proposed', noOfOpps: 94, ACV: 2789457, avgACV: 29547, avgDaysOpen: 140 },
    //         { label: 'Selected', noOfOpps: 284, ACV: 50556656, avgACV: 1780, avgDaysOpen: 139 },
    //         { label: 'Comitted', noOfOpps: 45, ACV: 452205, avgACV: 11245, avgDaysOpen: 77 },
    //     ]
    // },
  };

  filters = [
    "closed_year",
    "closed_month",
    "closed_fiscal_quarter",
    "projected_close_calendar_year",
    "projected_close_calendar_month",
    "projected_close_fiscal_quarter",
    "validated_year",
    "validated_month",
    "validated_fiscal_quarter",
    "rep",
    "team",
    "Region",
    "Acct_Vertical",
    "Acct_Segment",
    "Lead_Source",
    "Opp_Created_By",
    "Solution_Cat",
    "Week_Ending",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  stages = [
    "Pain and Use case Understanding Score",
    "Relationship Score",
    "Vision and Value Score",
    "Collaboration Score",
    "CRT Score",
    "Commercials Score",
    "Strength of Sale",
  ];

  componentDidMount() {
    if (this.props.funnelFilters === "") this.props.getFunnelFilters();
    else
      this.props.getFunnelDataNew([
        {
          name: "Week_Ending",
          value: [
            this.props.funnelFilters.filter(
              (y) => y.type === "Week_Ending" && y["Week_Ending"] !== ""
            )[0].Week_Ending,
          ],
          string: true,
        },
      ]);
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelData !== prevProps.funnelData) {
      let uniqueQuarters = [];
      if (this.props.funnelData) {
        this.props.funnelData.map((i) => {
          if (!uniqueQuarters.includes(i.Week_Ending) && i.Week_Ending) {
            uniqueQuarters = [...uniqueQuarters, i.Week_Ending];
          }
        });

        this.setState({
          ...this.state,
          uniqueQuarters: uniqueQuarters.sort(),
        });
      }
    }
    if (this.props.funnelFilters !== prevProps.funnelFilters) {
      this.setState({
        ...this.state,
        Week_Ending:
          this.props.funnelFilters === ""
            ? ["All"]
            : [
                this.props.funnelFilters.filter(
                  (y) => y.type === "Week_Ending" && y["Week_Ending"] !== ""
                )[0].Week_Ending,
              ],
      });
    }
  }

  handleChange = (e) => {
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string:
            f === "team"
              ? true
              : this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0]
              ? this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0].string === "Y"
              : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (this.state.acv !== null) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }

    if (this.state.Old_Value && this.state.Old_Value !== "0") {
      filters = [
        ...filters,
        {
          name: "Old_Value",
          value: [this.state.Old_Value],
          string: true,
        },
      ];
    }
    if (this.state.New_Value && this.state.New_Value !== "0") {
      filters = [
        ...filters,
        {
          name: "New_Value",
          value: [this.state.New_Value],
          string: true,
        },
      ];
    }
    this.props.getFunnelDataNew(filters);
  };

  orderRepsByLastName = () => {
    let temp = this.props.funnelFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[1]} ${res[0]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  gridApi = null;
  columnApi = null;
  params = null;

  gridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.params = params;
  };
  onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      this.columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };
  changeOldValue = (event, value) => {
    this.setState({
      ...this.state,
      Old_Value: value ? value : 0,
    });
  };
  changeNewValue = (event, value) => {
    this.setState({
      ...this.state,
      New_Value: value ? value : 0,
    });
  };
  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid item xs={12}>
          <Typography variant="h2" align="center" style={{ marginBottom: 5 }}>
            Strength of Sale
          </Typography>
          <Typography variant="body1">Strength of Sale</Typography>
        </Grid> */}
        {addHeaderdescription("Strength of Sale", "Strength of Sale")}

        {this.props.funnelFilters !== "" ? (
          <>
            {/* closed dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={6}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.closed_year[0] !== "All" ||
                      this.state.closed_month[0] !== "All"
                    }
                  >
                    <InputLabel
                      style={{
                        color: !this.state.closed_fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-fiscalQuarter-label"
                    >
                      Closed Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state.closed_fiscal_quarter}
                      onChange={this.handleChange}
                      name="closed_fiscal_quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters === "" && (
                        <MenuItem value={nearestCompletedFiscalQuarter()}>
                          {nearestCompletedFiscalQuarter()}
                        </MenuItem>
                      )}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "closed_fiscal_quarter" ? (
                            <MenuItem
                              key={y["closed_fiscal_quarter"]}
                              value={y["closed_fiscal_quarter"]}
                            >
                              {y["closed_fiscal_quarter"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.closed_fiscal_quarter[0] !== "All"}
                  >
                    <InputLabel
                      style={{
                        color: !this.state.closed_year.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-year-label"
                    >
                      Closed Calendar Year:
                    </InputLabel>
                    <Select
                      value={this.state.closed_year}
                      onChange={this.handleChange}
                      name="closed_year"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "closed_year" ? (
                            <MenuItem
                              key={y["closed_year"]}
                              value={y["closed_year"]}
                            >
                              {y["closed_year"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    disabled={this.state.closed_fiscal_quarter[0] !== "All"}
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      style={{
                        color: !this.state.closed_month.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-month-label"
                    >
                      Closed Calendar Month:
                    </InputLabel>
                    <Select
                      value={this.state.closed_month}
                      onChange={this.handleChange}
                      name="closed_month"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                        <MenuItem key={y} value={y}>
                          {this.months[y - 1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Rep Team */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={
                      this.props.funnelFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                        label="Rep:"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) => y.type === "team" && y.Display_Name !== ""
                          )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}{" "}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* ACV and GO */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              lg={2}
            >
              <Grid item spacing={2} xs={8} xl={9} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ paddingTop: 0, height: "0px" }}
                >
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.company === "C0001"
                        ? [
                            "0",
                            "5,000",
                            "10,000",
                            "15,000",
                            "20,000",
                            "30,000",
                            "50,000",
                            "100,000",
                          ]
                        : [
                            "0",
                            "50,000",
                            "100,000",
                            "250,000",
                            "500,000",
                            "750,000",
                            "1,000,000",
                          ]
                    }
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ACV >= :"
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: 4 }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== "" && this.state.acv !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4} xl={3} container justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                  disabled={this.state.acvError !== ""}
                >
                  GO
                </Button>
              </Grid>
            </Grid>

            {/* projected close dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={6}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.projected_close_calendar_year[0] !== "All" ||
                      this.state.projected_close_calendar_month[0] !== "All"
                    }
                  >
                    <InputLabel
                      style={{
                        color:
                          !this.state.projected_close_fiscal_quarter.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-fiscalQuarter-label"
                    >
                      Proj. Close Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state.projected_close_fiscal_quarter}
                      onChange={this.handleChange}
                      name="projected_close_fiscal_quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters === "" && (
                        <MenuItem value={nearestCompletedFiscalQuarter()}>
                          {nearestCompletedFiscalQuarter()}
                        </MenuItem>
                      )}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "projected_close_fiscal_quarter" ? (
                            <MenuItem
                              key={y["projected_close_fiscal_quarter"]}
                              value={y["projected_close_fiscal_quarter"]}
                            >
                              {y["projected_close_fiscal_quarter"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.projected_close_fiscal_quarter[0] !== "All"
                    }
                  >
                    <InputLabel
                      style={{
                        color:
                          !this.state.projected_close_calendar_year.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-year-label"
                    >
                      Proj. Close Calendar Year:
                    </InputLabel>
                    <Select
                      value={this.state.projected_close_calendar_year}
                      onChange={this.handleChange}
                      name="projected_close_calendar_year"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "projected_close_calendar_year" ? (
                            <MenuItem
                              key={y["projected_close_calendar_year"]}
                              value={y["projected_close_calendar_year"]}
                            >
                              {y["projected_close_calendar_year"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    disabled={
                      this.state.projected_close_fiscal_quarter[0] !== "All"
                    }
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      style={{
                        color:
                          !this.state.projected_close_calendar_month.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-month-label"
                    >
                      Proj. Close Calendar Month:
                    </InputLabel>
                    <Select
                      value={this.state.projected_close_calendar_month}
                      onChange={this.handleChange}
                      name="projected_close_calendar_month"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                        <MenuItem key={y} value={y}>
                          {this.months[y - 1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* industry, customer segment, customer type */}
            <Grid
              container
              item
              className={classes.inputFieldContainers}
              justify="space-between"
              xs={12}
              lg={6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                {/* <Grid item xs={12} style={{ paddingBottom: 8 }}>
                            <Typography variant='body1'>Customer</Typography>
                        </Grid> */}

                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      style={{
                        color: !this.state.Acct_Vertical.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Industry:"}
                    </InputLabel>
                    <Select
                      value={this.state.Acct_Vertical}
                      onChange={this.handleChange}
                      name="Acct_Vertical"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === "Acct_Vertical" &&
                              y["Acct_Vertical"] !== ""
                          )
                          .sort((a, b) =>
                            a["Acct_Vertical"] < b["Acct_Vertical"]
                              ? -1
                              : a["Acct_Vertical"] > b["Acct_Vertical"]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y["Acct_Vertical"]}
                              value={y["Acct_Vertical"]}
                            >
                              {y["Acct_Vertical"]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      style={{
                        color: !this.state.Acct_Segment.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-region-label"
                    >
                      Customer Segment:
                    </InputLabel>
                    <Select
                      value={this.state.Acct_Segment}
                      onChange={this.handleChange}
                      name="Acct_Segment"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === "Acct_Segment" &&
                              y["Acct_Segment"] !== ""
                          )
                          .sort((a, b) =>
                            a["Acct_Segment"] < b["Acct_Segment"]
                              ? -1
                              : a["Acct_Segment"] > b["Acct_Segment"]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y["Acct_Segment"]}
                              value={y["Acct_Segment"]}
                            >
                              {y["Acct_Segment"]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      style={{
                        color: !this.state.Region.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Region:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      value={this.state.Region}
                      onChange={this.handleChange}
                      name="Region"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) => y.type === "Region" && y["Region"] !== ""
                          )
                          .sort((a, b) =>
                            a["Region"] < b["Region"]
                              ? -1
                              : a["Region"] > b["Region"]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem key={y["Region"]} value={y["Region"]}>
                              {y["Region"]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Open dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              lg={6}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.validated_year[0] !== "All" ||
                      this.state.validated_month[0] !== "All"
                    }
                  >
                    <InputLabel
                      style={{
                        color: !this.state.validated_fiscal_quarter.includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-fiscalQuarter-label"
                    >
                      {this.props.company === "C0003" ? "Qualifying " : "Open "}{" "}
                      Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state.validated_fiscal_quarter}
                      onChange={this.handleChange}
                      name="validated_fiscal_quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters === "" && (
                        <MenuItem value={nearestCompletedFiscalQuarter()}>
                          {nearestCompletedFiscalQuarter()}
                        </MenuItem>
                      )}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "validated_fiscal_quarter" ? (
                            <MenuItem
                              key={y["validated_fiscal_quarter"]}
                              value={y["validated_fiscal_quarter"]}
                            >
                              {y["validated_fiscal_quarter"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.validated_fiscal_quarter[0] !== "All"}
                  >
                    <InputLabel
                      style={{
                        color: !this.state.validated_year.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-year-label"
                    >
                      {this.props.company === "C0003" ? "Qualifying " : "Open "}
                      Calendar Year:
                    </InputLabel>
                    <Select
                      value={this.state.validated_year}
                      onChange={this.handleChange}
                      name="validated_year"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "validated_year" ? (
                            <MenuItem
                              key={y["validated_year"]}
                              value={y["validated_year"]}
                            >
                              {y["validated_year"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    disabled={this.state.validated_fiscal_quarter[0] !== "All"}
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      style={{
                        color: !this.state.validated_month.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-month-label"
                    >
                      {this.props.company === "C0003" ? "Qualifying " : "Open "}{" "}
                      Calendar Month:
                    </InputLabel>
                    <Select
                      value={this.state.validated_month}
                      onChange={this.handleChange}
                      name="validated_month"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                        <MenuItem key={y} value={y}>
                          {this.months[y - 1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Lead source, opportunity created by, solution cat */}
            <Grid
              container
              item
              className={classes.inputFieldContainers}
              justify="space-between"
              xs={12}
              lg={this.props.company === "C0003" ? 4 : 6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                {/* <Grid item xs={12} style={{ paddingBottom: 8 }}>
                            <Typography variant='body1'>Filters</Typography>
                        </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={this.props.company === "C0003" ? 6 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      style={{
                        color: !this.state.Lead_Source.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-region-label"
                    >
                      Lead Source:
                    </InputLabel>
                    <Select
                      value={this.state.Lead_Source}
                      onChange={this.handleChange}
                      name="Lead_Source"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === "Lead_Source" &&
                              y["Lead_Source"] !== ""
                          )
                          .sort((a, b) =>
                            a["Lead_Source"] < b["Lead_Source"]
                              ? -1
                              : a["Lead_Source"] > b["Lead_Source"]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y["Lead_Source"]}
                              value={y["Lead_Source"]}
                            >
                              {y["Lead_Source"]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={this.props.company === "C0003" ? 6 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      style={{
                        color: !this.state.Opp_Created_By.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-industry-label"
                    >
                      Opportunity created by:
                    </InputLabel>
                    <Select
                      value={this.state.Opp_Created_By}
                      onChange={this.handleChange}
                      name="Opp_Created_By"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === "Opp_Created_By" &&
                              y["Opp_Created_By"] !== ""
                          )
                          .sort((a, b) =>
                            a["Opp_Created_By"] < b["Opp_Created_By"]
                              ? -1
                              : a["Opp_Created_By"] > b["Opp_Created_By"]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y["Opp_Created_By"]}
                              value={y["Opp_Created_By"]}
                            >
                              {y["Opp_Created_By"]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                {this.props.company === "C0001" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        style={{
                          color: !this.state.Solution_Cat.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        className={classes.filterLabel}
                        id="select-industry-label"
                      >
                        Solution Category:
                      </InputLabel>
                      <Select
                        value={this.state.Solution_Cat}
                        onChange={this.handleChange}
                        name="Solution_Cat"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Solution_Cat" &&
                                y["Solution_Cat"] !== ""
                            )
                            .sort((a, b) =>
                              a["Solution_Cat"] < b["Solution_Cat"]
                                ? -1
                                : a["Solution_Cat"] > b["Solution_Cat"]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y["Solution_Cat"]}
                                value={y["Solution_Cat"]}
                              >
                                {y["Solution_Cat"]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/* Extra filters */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10, paddingRight: 0 }}
              xs={12}
              md={12}
            >
              <Grid
                item
                spacing={2}
                sm={12}
                lg={6}
                container
                component={Paper}
                justify="space-around"
                className={classes.extraFilters}
              >
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    style={{ marginTop: 4 }}
                    disabled={
                      this.state.validated_year[0] !== "All" ||
                      this.state.validated_month[0] !== "All"
                    }
                  >
                    <InputLabel
                      style={{
                        color: !this.state.Week_Ending.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      className={classes.filterLabel}
                      id="select-fiscalQuarter-label"
                    >
                      Week Ending
                    </InputLabel>
                    <Select
                      value={this.state.Week_Ending}
                      onChange={this.handleChange}
                      name="Week_Ending"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters === "" && (
                        <MenuItem value={nearestCompletedFiscalQuarter()}>
                          {nearestCompletedFiscalQuarter()}
                        </MenuItem>
                      )}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "Week_Ending" ? (
                            <MenuItem
                              key={y["Week_Ending"]}
                              value={y["Week_Ending"]}
                            >
                              {dateAndTimeFormatter(y["Week_Ending"])}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <Grid item xs={12} sm={12} style={{}}>
                    {/* {console.log(typeof this.state.Old_Value)} */}
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={["Blank", "Red", "Yellow", "Green"]}
                      onChange={this.changeOldValue}
                      value={this.state.Old_Value}
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Old Value :"
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4 }}
                          InputLabelProps={{
                            className: classes.blackLabel,
                            style: {
                              color: !(
                                this.state.Old_Value === "0" ||
                                this.state.Old_Value === 0 ||
                                this.state.Old_Value === null
                              )
                                ? "#4472c4"
                                : "#000",
                            },
                          }}
                          classes={{ root: classes.removeMB }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <Grid item xs={12} sm={12} style={{}}>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={["Blank", "Red", "Yellow", "Green"]}
                      onChange={this.changeNewValue}
                      value={this.state.New_Value}
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="New Value :"
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4 }}
                          InputLabelProps={{
                            className: classes.blackLabel,
                            style: {
                              color: !(
                                this.state.New_Value === "0" ||
                                this.state.New_Value === 0 ||
                                this.state.New_Value === null
                              )
                                ? "#4472c4"
                                : "#000",
                            },
                          }}
                          classes={{ root: classes.removeMB }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        {/* Main Table */}
        {this.props.funnelData && this.props.funnelData !== "" ? (
          <Grid
            item
            container
            spacing={1}
            justify="space-around"
            component={Paper}
            style={{
              marginTop: 0,
              width: "auto",
              maxWidth:
                window.innerWidth < 1600 ? window.innerWidth - 120 : 1600,
              marginLeft: 5,
            }}
          >
            <Grid item xs={12}>
              <div
                className={classes.root}
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CardContent
                  className={classes.content}
                  style={{ padding: 10 }}
                >
                  <Grid container justify="flex-start">
                    <TableContainer className={classes.mainTable}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "10px",
                          // marginRight: "10px",
                        }}
                      >
                        <CopyTablesObject tableRef={this.tableRef} />
                      </div>

                      <Table
                        ref={this.tableRef}
                        size="small"
                        aria-label="html table"
                        style={{ tableLayout: "auto", width: "initial" }}
                      >
                        <TableHead></TableHead>
                        <TableBody>
                          <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                            <TableCell
                              align="center"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              Week Ending
                            </TableCell>
                            {this.state.uniqueQuarters.map((i, index) => {
                              return (
                                <TableCell
                                  key={i}
                                  align="center"
                                  style={
                                    index % 2 === 0
                                      ? {
                                          background: "#4471c4",
                                          color: "white",
                                        }
                                      : {
                                          background: "#5b9bd5",
                                          color: "white",
                                        }
                                  }
                                  className={classes.repHeader}
                                  colSpan={3}
                                >
                                  {dateAndTimeFormatter(i)}
                                </TableCell>
                              );
                            })}
                            {this.state.uniqueQuarters.length === 0 && (
                              <TableCell
                                rowSpan={9}
                                className={classes.repHeader}
                                style={{
                                  width: 200,
                                  textAlign: "center",
                                  background: "white",
                                  color: "black",
                                }}
                              >
                                No data available
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                            <TableCell
                              align="center"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              Strength of Sale Dimension
                            </TableCell>
                            {this.state.uniqueQuarters.map((i) => {
                              return (
                                <React.Fragment>
                                  <TableCell
                                    align="center"
                                    className={classes.repHeader}
                                    colSpan={1}
                                  >
                                    Increase
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.repHeader}
                                    colSpan={1}
                                  >
                                    Decrease
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.repHeader}
                                    colSpan={1}
                                  >
                                    Initial
                                  </TableCell>
                                </React.Fragment>
                              );
                            })}
                          </TableRow>

                          {this.stages.map((i) => {
                            return (
                              <StyledTableRow key={i}>
                                <TableCell
                                  align="left"
                                  className={classes.repText}
                                  colSpan={1}
                                >
                                  {i === "Strength of Sale"
                                    ? "Overall Strength of Sale Score"
                                    : i === "CRT Score"
                                    ? "Compelling Reason to Act (CRT) Score"
                                    : i}
                                </TableCell>

                                {this.state.uniqueQuarters.map((j) => {
                                  let initialAssignment = 0;
                                  let decrease = 0;
                                  let increase = 0;
                                  this.props.funnelData.map((k) => {
                                    if (
                                      k.Week_Ending === j &&
                                      k.strength_of_sale_dimension === i
                                    ) {
                                      if (
                                        k.Initial_or_Increase_or_Decrease ===
                                        "Initial"
                                      )
                                        initialAssignment = k.count;
                                      if (
                                        k.Initial_or_Increase_or_Decrease ===
                                        "Decrease"
                                      )
                                        decrease = k.count;
                                      if (
                                        k.Initial_or_Increase_or_Decrease ===
                                        "Increase"
                                      )
                                        increase = k.count;
                                    }
                                  });

                                  // k.stage_name === i && k.closed_fiscal_quarter === j
                                  // let tempCount = temp.length > 0 ? formatter(temp[0].count) : 0
                                  // let tempACV = temp.length > 0 ? currencyFormatter(temp[0].acv) : currencyFormatter(0)
                                  // ?this.props.funnelData.filter(k => k.stage_name === "Closed" && k.closed_fiscal_quarter === j)[0].acv/tempACV:0
                                  return (
                                    <React.Fragment>
                                      <TableCell
                                        align="right"
                                        className={classes.repNumber}
                                        colSpan={1}
                                      >
                                        {formatter(increase)}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className={classes.repNumber}
                                        colSpan={1}
                                      >
                                        {formatter(decrease)}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className={classes.repNumber}
                                        colSpan={1}
                                      >
                                        {formatter(initialAssignment)}
                                      </TableCell>
                                    </React.Fragment>
                                  );
                                })}
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </CardContent>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            container
            spacing={1}
            component={Paper}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Skeleton
              animation="wave"
              variant="rect"
              width="80%"
              height={230}
              style={{ margin: "20px 0px" }}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(TableRow);

function mapStateToProps(state) {
  const {
    strengthOfSalesFilters,
    strengthOfSalesData,
    persistentAlert,
    user,
    updatingFunnelData,
  } = state.app;

  return {
    funnelFilters: strengthOfSalesFilters,
    funnelData: strengthOfSalesData,
    persistentAlert,
    company: user.company,
    updatingFunnelData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFunnelFilters: () => {
    dispatch({ type: "get_strength_of_sales_filters_request" });
    appService.getStrengthOfSalesFilters().then(
      (json) => {
        dispatch({ type: "get_strength_of_sales_filters_success", json });
        dispatch({ type: "get_strength_of_sales_data_request" });
        appService
          .getStrengthOfSalesData([
            {
              name: "Week_Ending",
              value: [
                json.message.filter(
                  (y) => y.type === "Week_Ending" && y["Week_Ending"] !== ""
                )[0].Week_Ending,
              ],
              string: true,
            },
          ])
          .then(
            (json) => {
              dispatch({ type: "get_strength_of_sales_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_strength_of_sales_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_strength_of_sales_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_strength_of_sales_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_strength_of_sales_filters_failure", error });
      }
    );
  },
  getFunnelDataNew: (filters) => {
    dispatch({ type: "get_strength_of_sales_data_request" });
    appService.getStrengthOfSalesData(filters).then(
      (json) => {
        dispatch({ type: "get_strength_of_sales_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_strength_of_sales_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_strength_of_sales_failure", error });
      }
    );
  },
});

const connectedFunnel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Funnel));
export { connectedFunnel as StrengthOfSales };
