import * as d3 from "d3";
import { updatedScheme10 } from "../../../util/chartColorScheme";
import { roundUp, stageFormattter } from "../../../util/customFunctions";
import "./D3RepPerformanceStack.scss";
import { store } from "../../../util/store";
// import './Labeler'

var D3BubbleChart = {};
D3BubbleChart.create = (el, config) => {
  const Symbol = store.getState();
  const Currency_Symbol = Symbol.app.user.Currency;

  const fontWeight = 400;
  var myColor = d3.scaleLinear().domain([0, 12]).range(["blue", "green"]);
  function wrap(d) {
    var text = d3.select(this),
      width = d.r * 2,
      x = d.x,
      y = d.y,
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1,
      tspan = text.text(null).append("tspan").attr("x", x).attr("y", y);
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + "em")
          .text(word);
      }
    }
  }

  d3.selection.prototype.last = function () {
    return d3.select(this.nodes()[this.size() - 1]);
  };
  if (config.data) {
    const wonLabel = config.label.filter((item) => item.type === "won")[0]
      .label;
    const firstLabel = config.label.filter((item) => item.type === "first")[0]
      .label;
    const secondLabel = config.label.filter((item) => item.type === "second")[0]
      .label;
    config.data = config.data
      .filter((item) => item.Name !== "total")
      .sort((a, b) => b[`${wonLabel}_ACV_Perc`] - a[`${wonLabel}_ACV_Perc`]);

    config.barColor = "#70ad47";
    config.margin = {
      top: 120,
      right: config.data
        ? d3.select(el).node().getBoundingClientRect().width < 400
          ? 50
          : 120
        : 10,
      bottom: 120,
      left: config.data
        ? d3.select(el).node().getBoundingClientRect().width < 400
          ? 10
          : 100
        : 10,
    };
    if (!config.width) {
      config.width = d3.select(el).node()
        ? d3.select(el).node().getBoundingClientRect().width -
          config.margin.left -
          config.margin.right
        : 100;
    }
    config.height = config.data ? 400 : 100;
    const xAxisText = `${stageFormattter(secondLabel)} To ${stageFormattter(
      wonLabel
    )} Conversion Rate`;
    const yAxisText = `${stageFormattter(firstLabel)} To ${stageFormattter(
      secondLabel
    )} Conversion Rate`;
    config.data = config.data.map((item) => ({
      qualifiedToclosedWon: parseFloat(
        item[`${secondLabel}_To_${wonLabel}_Conversion_Rate`]
      ),
      suspectToQualified: parseFloat(
        item[`${firstLabel}_To_${secondLabel}_Conversion_Rate`]
      ),
      rep: item.Name,
      acv: item[`${wonLabel}_ACV`],
      acvPercent: item[`${wonLabel}_ACV_Perc`],
    }));

    var diameter = 300, //max size of the bubbles
      format = d3.format(",d");
    var reversedScheme = [...d3.schemePiYG[11]];
    reversedScheme = reversedScheme.reverse();
    var color = d3.scaleOrdinal(updatedScheme10);

    var bubble = d3.pack().size([diameter, diameter]).padding(1.5);
    var chartContainer = d3
      .select(el)
      .append("svg")
      .attr("width", config.width + config.margin.left + config.margin.right)
      .attr("height", config.height + config.margin.top + config.margin.bottom);
    var chart = chartContainer
      .attr("id", "Rep_Performance_Analysis_Bubble_Chart_svg")
      .append("g")
      .attr(
        "transform",
        "translate(" + config.margin.left + "," + config.margin.top + ")"
      );
    var tooltip = d3
      .select(el)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
    var legend = d3.select(el).append("div").attr("class", "legend");
    let tempVar =
      0.01 *
        (Math.floor(
          ((d3.max(config.data, (d) => d.qualifiedToclosedWon) +
            d3.max(config.data, (d) => d.qualifiedToclosedWon) * 0.3) *
            100) /
            10
        ) *
          10) >
      1
        ? 1
        : 0.01 *
            (Math.floor(
              ((d3.max(config.data, (d) => d.qualifiedToclosedWon) +
                d3.max(config.data, (d) => d.qualifiedToclosedWon) * 0.3) *
                100) /
                10
            ) *
              10) ===
          0
        ? 1
        : 0.01 *
          (Math.floor(
            ((d3.max(config.data, (d) => d.qualifiedToclosedWon) +
              d3.max(config.data, (d) => d.qualifiedToclosedWon) * 0.3) *
              100) /
              10
          ) *
            10);

    //Take the maximum value and round it up to nearest single digit precision (eg. 0.1277 will become 0.2)
    //Pass the maximum of value computed tempVar or tempVar rounded up to the nearest single digit precision.
    //This will ensure max of x-axis will always be greater than the max value to be displayed
    //Date: Dec-01-2023 John Marshall MetricStream bug fix
    tempVar = Math.max(
      tempVar,
      roundUp(
        d3.max(config.data, (d) => d.qualifiedToclosedWon),
        1
      )
    );
    console.log(tempVar, "Max-width after max Function");

    const x = d3.scaleLinear().domain([0, tempVar]).range([0, config.width]);
    // const { lower_bound, upper_bound } = computeDomainLimits(d3.min(config.data, d => d.qualifiedToclosedWon ), d3.max(config.data, d => d.qualifiedToclosedWon ))
    // console.log(upper_bound, lower_bound, 'Lower Upper')
    // const buffer = upper_bound / 10
    // const x = d3
    //   .scaleLinear()
    //   .domain([
    //     0,
    //     upper_bound + buffer
    //   ])
    //   .range([0, config.width]);
    const y = d3
      .scaleLinear()
      .domain([
        0,
        0.01 *
          (Math.floor(
            ((d3.max(config.data, (d) => d.suspectToQualified) +
              d3.max(config.data, (d) => d.suspectToQualified) * 0.3) *
              100) /
              10
          ) *
            10) >
        1
          ? 1
          : 0.01 *
              (Math.floor(
                ((d3.max(config.data, (d) => d.suspectToQualified) +
                  d3.max(config.data, (d) => d.suspectToQualified) * 0.3) *
                  100) /
                  10
              ) *
                10) ===
            0
          ? 1
          : 0.01 *
            (Math.floor(
              ((d3.max(config.data, (d) => d.suspectToQualified) +
                d3.max(config.data, (d) => d.suspectToQualified) * 0.3) *
                100) /
                10
            ) *
              10),
      ])
      .range([config.height, 0]);

    const maxAcv = d3.max(config.data, (d) => d.acv);
    function make_x_gridlines() {
      return d3.axisBottom(x).ticks(5);
    }
    function make_y_gridlines() {
      return d3.axisLeft(y).ticks(5);
    }
    chart
      .append("g")
      .attr("class", "grid")
      .attr("z-index", -1)
      .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));
    chart
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0," + config.height + ")")
      .attr("z-index", -1)
      .call(make_x_gridlines().tickSize(-config.height).tickFormat(""));
    chart.append("g").call(
      d3
        .axisLeft(y)
        .tickFormat(function (d) {
          return `${Math.round(d * 100)}%`;
        })
        .ticks(5)
    );
    chart
      .append("g")
      .attr("transform", "translate(0," + config.height + ")")
      .call(
        d3
          .axisBottom(x)
          .tickFormat(function (d) {
            return `${Math.round(d * 100)}%`;
          })
          .ticks(5)
      );
    chart
      .append("g")
      .selectAll("dot")
      .data(config.data)
      .enter()
      .append("circle")
      .attr("r", (d) => (d.acv / maxAcv) * 100)
      .attr("cx", function (d) {
        return x(d.qualifiedToclosedWon);
      })
      .attr("cy", function (d) {
        return y(d.suspectToQualified);
      })
      // .attr("r", 10)
      .attr("fill", (d) => color(d.rep))
      .attr("opacity", 0.5)
      .on("mouseover", (d) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `
            <div style="border-top:10px solid ${color(
              d.rep
            )};border-radius:1rem;">
              <div class="tooltip-box">
                <h3 class="heading-style">${d.rep}</h3>
                <hr>
                <div class="container">
                  <h4 class="result-style">                    
                    ${stageFormattter(firstLabel)} to ${stageFormattter(
              secondLabel
            )} Conv. Rate : <span style="color:${color(d.rep)}">${Math.round(
              d.suspectToQualified * 100
            )}%</span>                    
                  </h4>
                  <h4 class="result-style">
                    ${stageFormattter(secondLabel)} to ${stageFormattter(
              wonLabel
            )} Conv. Rate :<span style="color:${color(d.rep)}"> ${Math.round(
              d.qualifiedToclosedWon * 100
            )}%</span>                    
                  </h4>                                       
                </div>
                <hr>
                <div class="container">
                <h3 class="heading-style">${Currency_Symbol}${d3.format(",")(
              Math.round(d.acv)
            )}</h3>
                </div>
              </div>
            </div>
                        
            `
          )
          //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
          .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(300).style("opacity", 0);
      })
      .attr("class", "dot");

    // chart
    //   .append("text")
    //   .attr("dy", "1em")
    //   .attr("font-size", "0.85rem")
    //   // .attr("font-weight", "500")
    //   .attr("y", config.margin.left - 175)
    //   .attr("x", -(config.height/1.20) )
    //   .attr("transform", "rotate(-90)") // although axis is rotated, text is not
    //   .attr("fill", "#00000")
    //   .text(yAxisText);
    chart
      .append("text")
      .attr("dy", "1em")
      .attr("font-size", "0.85rem")
      .attr("y", -(config.margin.left - 40)) // Move the y position up to center align
      .attr("x", -(config.height / 2)) // Move the x position to center align
      .attr("text-anchor", "middle") // Add text-anchor attribute for center alignment
      .attr("transform", "rotate(-90)")
      .attr("fill", "#00000")
      .text(yAxisText);

    // chart
    //   .append("text")
    //   .attr("y", config.height + 55)
    //   .attr("x", config.width / 2 - 100)
    //   .attr("dy", "1em")
    //   .attr("class", "axis-label")
    //   .attr("font-size", "0.85rem")
    //   // .attr("font-weight", "500")
    //   .attr("fill", "#000000")
    //   .text(xAxisText);
    chart
      .append("text")
      .attr("y", config.height + 40) // Move the y position down to center align
      .attr("x", config.width / 2) // Move the x position to center align
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      .attr("text-anchor", "middle") // Add text-anchor attribute for center alignment
      .attr("fill", "#000000")
      .text(xAxisText);

    var legends = legend
      .selectAll("div")
      .data(config.data)
      .enter()
      .append("div")
      .attr("class", "legend-flex");
    legends
      .append("div")
      .attr("class", "legend-circle")
      .style("background-color", (d) => color(d.rep))
      .style("opacity", 0.5)
      .style("border", "1px solid black");
    legends
      .append("div")
      .text((d) => d.rep)
      .style("padding-left", "1rem")
      .style("min-width", "14rem")
      .style("max-width", "14rem");

    legend
      .attr("width", config.width)
      .attr("height", config.height)
      .attr("class", "legend-flex")
      .style("justify-content", "left")
      .selectAll(".legend-circle")
      .data(config.data)
      .enter()
      .append("div")
      .attr("class", "legend-circle")
      .style("background-color", (d) => color(d.rep))
      .html((d) => `<div>${d.rep}</div>`);
  }
};
D3BubbleChart.destroy = (el) => {
  d3.select(el).selectAll("svg").remove();
  d3.select(".tooltip").remove();
  d3.select(".legend-flex").remove();
};
export default D3BubbleChart;
// ${d.rep}
// <br/>
// ${Math.round(d.qualifiedToclosedWon * 100)}%,${Math.round(d.suspectToQualified * 100)}%
// <br/>
// ACV:$${d3.format(",")(Math.round(d.acv))}
