import React from 'react'
import ScoreCardDrilldownBody from '../ScoreCardDrilldownBody';
import NQPDrilldownBody from '../NQPDrilldownBody';
import WaterfallDrilldownBody from '../../../EnhancedInsights/Waterfall/WaterfallDrilldownBody';
const FirstStageDrilldown = (props) => {
  return (
    <div>
      {
      props.tableType === 'ytd' || props.tableType === 'qtd' ?
        <ScoreCardDrilldownBody metaArr={props.metaArr} tableSection={props.tableSection} handleChangeLocation={props.openSecondLevelDrilldown} body={props.data} table={props.drilldownTable} col={props.col} /> :
          props.tableType === 'nqp' ? 
          <NQPDrilldownBody body={props.data} metaArr={props.nqpMetaData} /> :
           <WaterfallDrilldownBody metaArr={props.metaArr} body={props.data} handleChangeLocation={props.openSecondLevelDrilldown} />}
    </div>
  )
}

export default FirstStageDrilldown
