import React from "react";
import { CopyToClipboardTableContainer } from "../../components/Containers/CopyToClipboardTableContainer";
import {
  TableBody,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { countOrACVFormatter } from "../../util/customFunctions";
const useStyles = makeStyles((theme) => ({
  content: { width: "100%" },
  headerText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
    textAlign: "center",
    // width: "100px",
  },
  fixedWidthCell: {
    width: "100px", // Fixed width for all table cells
  },
  emptyCell: {
    border: "none",
    background: "white",
    // width: "10px",
    maxWidth: "10px",
    zIndex: 999999,
  },

  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontSize: "1.3rem",
    fontWeight: "inherit",
    width: "100px",
  },
  fontBold: {
    fontWeight: 600,
  },
}));
const AccountExpansionContractSummary = (props) => {
  const classes = useStyles();
  const summary = props.data.summaryInfo;
  return (
    <CopyToClipboardTableContainer datatest="Summary-ExpansionContraction">
      <TableHead className={classes.fontBold}>
        <TableRow className={classes.fontBold}>
          <TableCell
            style={{ background: "#93c47d" }}
            className={`${classes.headerText} ${classes.fixedWidthCell}`}
            colSpan={2}
          >
            Expansion
          </TableCell>
          <TableCell
            style={{ background: "#ff9900" }}
            className={`${classes.headerText} ${classes.fixedWidthCell}`}
            colSpan={2}
          >
            Contraction
          </TableCell>
          <TableCell
            style={{ background: "#c9daf8" }}
            className={`${classes.headerText} ${classes.fixedWidthCell}`}
            colSpan={2}
          >
            No change
          </TableCell>
        </TableRow>
        <TableRow className={classes.fontBold}>
          <TableCell
            className={`${classes.headerText} ${classes.fixedWidthCell}`}
          >
            # of Accounts
          </TableCell>
          <TableCell
            className={`${classes.headerText} ${classes.fixedWidthCell}`}
          >
            ACV
          </TableCell>
          <TableCell
            className={`${classes.headerText} ${classes.fixedWidthCell}`}
          >
            # of Accounts
          </TableCell>
          <TableCell
            className={`${classes.headerText} ${classes.fixedWidthCell}`}
          >
            ACV
          </TableCell>
          <TableCell
            className={`${classes.headerText} ${classes.fixedWidthCell}`}
          >
            # of Accounts
          </TableCell>
          <TableCell
            className={`${classes.headerText} ${classes.fixedWidthCell}`}
          >
            ACV
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.fontBold}>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.positiveDeltaAccount, false)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.positiveDeltaAccountACV, true)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.negativeDeltaAccount, false)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.negativeDeltaAccountACV, true)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.zeroDeltaAccount, false)}
          </TableCell>
          <TableCell
            className={`${classes.repNumber} ${classes.fixedWidthCell}`}
          >
            {countOrACVFormatter(summary.zeroDeltaAccountACV, true)}
          </TableCell>
        </TableRow>
      </TableBody>
    </CopyToClipboardTableContainer>
  );
};

export default AccountExpansionContractSummary;
