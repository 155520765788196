import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
} from "../../util/customFunctions";
import * as d3 from "d3";
import { getMixKey } from "../../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
} from "../../util/customSortings";
import { deSnake } from "../../util/dataFormatter";
import { color } from "d3";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CustomWidthTooltip from "../../components/Buttons/Expand_Collapse_Tooltip";

const useStyle = makeStyles((theme) => ({
  root: {},
  sticky: {
    position: "sticky",
    left: -1,
    background: "white",
    // outline: "0.5px solid lightgrey",
    // outlineOffset: "-1.5px",
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "1.5px", // Adjust the thickness as needed
      background: "lightgrey", // Adjust the color as needed
    },
  },
  stickyHeader: {
    position: "sticky",
    top: -1,
    background: "white",
    // outline: "0.2px solid lightgrey",
    // outlineOffset: "-1.5px",
    outline: "lightgrey solid 1px",
    outlineOffset: "-1.5px",
    zIndex: 5,
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px", // Adjust the thickness as needed
      // background: "lightgrey", // Adjust the color as needed
    },
  },
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
    fontWeight: "inherit",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: "inherit",
  },
  repNumberACV: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "92px",
    fontWeight: "inherit",
  },
  repNumberPercent: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "90px",
    fontWeight: 600,
    cursor: "pointer",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    width: "5%",
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  indentName: {
    paddingLeft: 30,
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
  stickyColumn: {
    minWidth: 200,
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}));
export default function QTDetailTableMonthly(props) {
  const classes = useStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [rowPin, setrowPin] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const [parentWidth, setparentWidth] = useState();

  const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "rgb(255,255,255,0.8)",
        color: "black",
        fontFamily: "roboto",
        fontSize: theme.typography.pxToRem(14),
        borderRadius: "1rem",
        padding: "0px",
        boxShadow: "0 0.1rem 0.1rem 0",
        marginTop: "0.4rem",
      },
    }))(Tooltip);


    const projectedYieldTooltip = (
        <>
          {Array(1)
            .fill()
            .map((item) => (
              <>
                <div
                  style={{
                    // borderTop: "solid #4471C4",
                    borderRadius: "0.3rem",
                    padding: "0px",
                    margin: 0,
                    borderWidth: "0.3rem",
                  }}
                >
                  <div className="container">
                    <Typography
                      style={{ padding: "0.1rem" }}
                      className="heading-style"
                    >{`(Attainment / Quota) / # of Reps in team`}</Typography>
                  </div>
                </div>
              </>
            ))}
        </>
      );

  let countinc = 0;
  let globalExpand = [];

  const handleExpandClick = (rowId) => {
    setExpandedRows((prevExpandedRows) => {
      const isRowExpanded = prevExpandedRows.includes(rowId);
      if (isRowExpanded) {
        return prevExpandedRows.filter((expandedRow) => expandedRow !== rowId);
      } else {
        return [...prevExpandedRows, rowId];
      }
    });
  };

  useEffect(() => {
    setExpandedRows(globalExpand);
  }, []);

  const globalExpandData = (con) => {
    if (con === true) {
      setExpandedRows(globalExpand);
      // setglobalExpandArrow(true);
    } else {
      setExpandedRows([]);
      // setglobalExpandArrow(false);
    }
  };

  const quarter = props.data.filter(
    (item) => item[props.quarters] !== "total" && item[props.quarters] !== null
  );

  // const uniqueQuarters = quarter.map(item => item[props.quarters]).filter(getUniqueValues)
  const uniqueQuarters = props.uniqueQuarters;

  const uniqueAttainment = props.data
    .filter((item) => item[props.quarters] === "total")
    .sort((a, b) =>
      b.attainment === a.attainment
        ? b.acv - a.acv
        : b.attainment - a.attainment
    );

  const uniqueTeamAttainment = props.teamData
    .filter((item) => item[props.quarters] === "total")
    .sort((a, b) =>
      b.attainment === a.attainment
        ? b.acv - a.acv
        : b.attainment - a.attainment
    );
  // console.log(props.toggleValue)
  let uniqueOwner = uniqueAttainment
    .map((item) => item.Owner_Name)
    .filter(getUniqueValues)
    .filter((item) => item !== null);
  uniqueOwner =
    props.toggleValue === "PercSort" ? uniqueOwner : uniqueOwner.sort();

  let uniqueTeams = uniqueTeamAttainment
    .map((item) => item[props.groupbyColumn])
    .filter(getUniqueValues)
    .filter((item) => item !== null);
  uniqueTeams =
    props.toggleValue === "PercSort"
      ? uniqueTeams
      : props.logicalTeams
          .filter((e) => uniqueTeams.indexOf(e) !== -1)
          .filter(Boolean);
  const uniqueTeamsForDrilldown =
    props.groupby === "Team, Ramp Status"
      ? uniqueAttainment
          .map((item) => item["team"])
          .filter(getUniqueValues)
          .filter((item) => item !== null)
      : uniqueTeamAttainment
          .map((item) => item["team"])
          .filter(getUniqueValues)
          .filter((item) => item !== null);

  // console.log((uniqueOwner.map(owner => quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQuarters.length ) ))

  const ast = (x) => (
    <>
      <span>{x}</span> <span style={{ color: "#ed7d31" }}>*</span>
    </>
  );
  const ast2 = (x) => (
    <>
      <span style={{ color: "#ed7d31" }}>*</span>&nbsp; <span>{x}</span>{" "}
    </>
  );
  //     const xyz  = uniqueOwner.map(owner => quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQuarters.length &&  owner).filter(x => x !== false)
  //  console.log(xyz)
  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-second");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (tableContainer.scrollTop > 0) {
        setrowPin(true);
      } else {
        setrowPin(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  useEffect(() => {
    // Get the width of the parent TableContainer
    const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(parentWidth);
  }, [parentWidth]);
  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: "1rem" }}>
        {/* {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null} */}
        {parentWidth !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
              width: `${parentWidth}px`,
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
         <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="body2" style={{ paddingLeft: "1rem" }}>
              Note: Team level Quota may not match the the sum of the quotas of the reps shown. Team level Actual may include attainment from reps without an explicit quota but still mapped to a team.
              </Typography>
            </Grid>
        <Grid container justify="flex-start" style={{ padding: "1rem" }}>
          <TableContainer
            className={classes.mainTable}
            id="myTableContainer-second"
            ref={tableContainerRef}
            style={{
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
              borderTop: rowPin ? "0.5px solid lightgrey" : "",
              maxHeight: "85vh",
              // height: "85vh",
            }}
          >
            {/* {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null} */}
            <Table
              ref={tableRef}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              data-test={
                props.dataTest
                  ? props.dataTest
                  : "quota-attainment-detail-table"
              }
            >
              <TableHead style={{ position: "sticky", top: -1, zIndex: 5 }}>
                <TableRow
                  style={{
                    fontWeight: 600,
                    paddingLeft: 5,
                    position: "sticky",
                    outline: "lightgrey solid 0.5px",
                    outlineOffset: "-1.5px",
                  }}
                  // className={rowPin ? classes.stickyHeader : ""}
                >
                  <TableCell
                    align="center"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ minWidth: 150, background: "white" }}
                  >
                    {props.quarters === "closed_fiscal_quarter"
                      ? "Closed Fiscal Quarter"
                      : "Closed Fiscal Year"}
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                padding: "10px 0",
                              }
                        }
                        className={classes.repHeader}
                        colSpan={3}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueQuarters.length >= 1 &&
                          uniqueQuarters.length % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        align="center"
                        className={classes.repHeader}
                        colSpan={props.showReps !== true ? 6 : 5}
                      >
                        {"Total"}
                      </TableCell>
                    </React.Fragment>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 400, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
                <TableRow
                  style={{
                    fontWeight: 600,
                    paddingLeft: 5,
                    outline: "lightgrey solid 1px",
                    outlineOffset: "-1.5px",
                  }}
                  // className={rowPin ? classes.stickyHeader : ""}
                >
                  <TableCell
                    align="center"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{
                      outline: "lightgrey solid 0.5px",
                      outlineOffset: "-1.5px",
                      background: "white",
                    }}
                  >
                    {/* {"Rep"} */}
                    <div style={{ display: "flex" }}>
                      <div style={{ marginTop: "2.5px", marginLeft: "30px" }}>
                        {"Rep"}
                      </div>
                      {props.showReps === true ? (
                        <>
                          <CustomWidthTooltip
                            title={"Expand All"}
                            placement="top-start"
                            style={{ marginTop: "13rem" }}
                          >
                            <KeyboardArrowDownIcon
                              onClick={() => globalExpandData(true)}
                              style={{ color: "gray" }}
                            />
                          </CustomWidthTooltip>
                          <CustomWidthTooltip
                            title={"Collapse All"}
                            placement="top-start"
                          >
                            <KeyboardArrowUpIcon
                              onClick={() => globalExpandData(false)}
                              style={{ color: "gray" }}
                            />
                          </CustomWidthTooltip>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </TableCell>
                  {uniqueQuarters.map((i) => {
                    return (
                      <React.Fragment key={i}>
                        <TableCell
                          align="center"
                          className={classes.repHeader}
                          colSpan={1}
                          style={{ background: "white" }}
                        >
                          Quota
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.repHeader}
                          colSpan={1}
                          style={{ background: "white" }}
                        >
                          Actual
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.repHeader}
                          colSpan={1}
                          style={{ background: "white" }}
                        >
                          Attain.%
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ background: "white" }}
                      >
                        Quota
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ background: "white" }}
                      >
                        Actual
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ background: "white" }}
                      >
                        Attain.%
                      </TableCell>
                      <HtmlTooltip title={projectedYieldTooltip}>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ background: "white" }}
                      >
                        Rep Productivity / Month
                      </TableCell>
                      </HtmlTooltip>
                      {props.showReps !== true && (
                        <TableCell
                          align="center"
                          className={classes.repHeader}
                          colSpan={1}
                          style={{ background: "white" }}
                        >
                          Capacity
                        </TableCell>
                      )}
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.groupby === "Team" ||
                props.groupby === "Team, Ramp Status"
                  ? uniqueTeams.map((team, i) => {
                      globalExpand.push(i);
                      countinc = countinc + 1;
                      const teamData = props.teamData;
                      let repData = props.data
                        .filter((item) => item[props.groupbyColumn] === team)
                        .sort((a, b) =>
                          b.attainment === a.attainment
                            ? b.acv - a.acv
                            : b.attainment - a.attainment
                        );
                      const teamForDrilldown =
                        props.groupby === "Team"
                          ? team
                          : props.data
                              .filter(
                                (item) => item[props.groupbyColumn] === team
                              )
                              .map((c) => c.team)[0];
                      let reps = props.data
                        .filter(
                          (item) =>
                            item[props.groupbyColumn] === team &&
                            !["team", "total"].includes(item.Owner_Name)
                        )
                        .sort((a, b) =>
                          b.attainment === a.attainment
                            ? b.acv - a.acv
                            : b.attainment - a.attainment
                        )
                        .map((item) => item.Owner_Name)
                        .filter(getUniqueValues);
                        if(props.groupby === 'Team, Ramp Status'){
                          const r = team.match(/Ramped|Ramping/); // No need for 'g' if only checking for first match
                          let rs = r ? r[0] : ''; // If r is found, use r[0], otherwise set to empty string
                          repData = props.data
                          .filter((item) => item[props.groupbyColumn] === team && item.rampStatus === rs)
                          .sort((a, b) =>
                            b.attainment === a.attainment
                              ? b.acv - a.acv
                              : b.attainment - a.attainment
                          );

                          let data =  repData.reduce((acc, curr) => {
                            if (!acc.some(item => item[props.groupbyColumn] === team && 
                              !["team", "total"].includes(item.Owner_Name) &&
                              rs === curr.Ramp_Status)) {
                              acc.push({
                                Full_Name: curr.Owner_Name,
                                Ramp_Status: curr.rampStatus
                              });
                            }
                            return acc;
                          }, []);

                          reps = data.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                              t.Full_Name === value.Full_Name && t.Ramp_Status === value.Ramp_Status
                            ))
                          );

                         

                        // console.log(repData, team, data, reps, 'REPPPPDATA')
                        }
                      return (
                        <>
                          {" "}
                          <StyledTableRow
                            key={i}
                            style={
                              props.showReps === true
                                ? { background: "#dae3f3" }
                                : {}
                            }
                          >
                            <TableCell
                              align="left"
                              // className={`${classes.table} ${classes.repHeader}`}
                              className={
                                isSticky
                                  ? `${classes.sticky} ${classes.table} ${classes.repHeader}`
                                  : `${classes.table} ${classes.repHeader}`
                              }
                              // style={
                              //   isSticky && countinc % 2 === 0
                              //     ? {
                              //         background: "#f5f5f5",
                              //         paddingLeft: "25px",
                              //       }
                              //     : { paddingLeft: "25px" }
                              // }
                              style={
                                props.showReps === true
                                  ? { background: "#dae3f3" }
                                  : isSticky && countinc % 2 === 0
                                  ? { background: "#f5f5f5" }
                                  : {}
                              }
                              colSpan={1}
                              onClick={() => handleExpandClick(i)}
                            >
                              {/* {team} */}
                              <div style={{ display: "flex" }}>
                                {props.showReps === true ? (
                                  expandedRows.includes(i) ? (
                                    <KeyboardArrowUpIcon
                                      style={{ color: "gray" }}
                                    />
                                  ) : (
                                    <KeyboardArrowDownIcon
                                      style={{ color: "gray" }}
                                    />
                                  )
                                ) : null}
                                <div style={{ marginTop: "3px" }}>{team}</div>
                              </div>
                            </TableCell>

                            {uniqueQuarters.map((qtr, j) => {
                              const QuotaData = teamData
                                .filter(
                                  (item) =>
                                    item[props.quarters] === qtr &&
                                    item[props.groupbyColumn] === team
                                )
                                .map((item) => item.Quota)[0];
                              // console.log(props.data.filter(item => item.Owner_Name === owner).map(item => item.Quota)[0] === 0)
                              const acvData = teamData
                                .filter(
                                  (item) =>
                                    item[props.quarters] === qtr &&
                                    item[props.groupbyColumn] === team
                                )
                                .map((item) => item.acv)[0];
                              const attainmentData = teamData
                                .filter(
                                  (item) =>
                                    item[props.quarters] === qtr &&
                                    item[props.groupbyColumn] === team
                                )
                                .map((item) => item.attainment)[0];

                                const rampStatus = teamData
                                .filter(
                                  (item) =>
                                    item[props.quarters] === qtr &&
                                    item[props.groupbyColumn] === team
                                )[0].rampStatus
                              return (
                                <>
                                  <TableCell
                                    align="right"
                                    className={classes.repHeader}
                                    colSpan={1}
                                  >
                                    {QuotaData === "undefiend" ||
                                    QuotaData === null ||
                                    _isNaN(QuotaData)
                                      ? "-"
                                      : countOrACVFormatter(
                                          QuotaData,
                                          true
                                        )}{" "}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    className={classes.repHeader}
                                    colSpan={1}
                                    onClick={() => {
                                      if (props.groupby === 'Team') {
                                        props.handleChangeLocation(
                                          "drilldownOnGraph2",
                                          {
                                            row: '',
                                            cell: qtr,
                                            quarter: props.quarters,
                                            rampStatus: rampStatus
                                          },
                                          team,
                                          'team'
                                        );
                                      }
                                    }}
                                    style={{cursor: props.groupby === 'Team' ? 'pointer' : 'default'}}
                                  >
                                    {acvData === "undefiend" ||
                                    acvData === null ||
                                    _isNaN(acvData)
                                      ? "-"
                                      : countOrACVFormatter(acvData, true)}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    className={classes.repHeader}
                                    colSpan={1}
                                  >
                                    {attainmentData === "undefiend" ||
                                    attainmentData === null ||
                                    _isNaN(attainmentData)
                                      ? "-"
                                      : Math.round(attainmentData * 100) + "%"}
                                  </TableCell>
                                </>
                              );
                            })}

                            {uniqueQuarters.length >= 1 && (
                              <React.Fragment>
                                <TableCell
                                  align="right"
                                  className={classes.repHeader}
                                  colSpan={1}
                                >
                                  {teamData
                                    .filter(
                                      (item) =>
                                        item[props.quarters] === "total" &&
                                        item[props.groupbyColumn] === team
                                    )
                                    .map((item) =>
                                      item.Quota === "undefiend" ||
                                      item.Quota === null
                                        ? "-"
                                        : countOrACVFormatter(item.Quota, true)
                                    )}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.repHeader}
                                  colSpan={1}
                                  onClick={() =>
                                  {
                                    if(props.groupby === 'Team'){
                                      props.handleChangeLocation(
                                        "drilldownOnGraph2",
                                        {
                                          row: '',
                                          cell: 'total',
                                          quarter: props.quarters,
                                          uniqueQuarters: uniqueQuarters.filter(f => f !== 'Total'),
                                          rampStatus: teamData
                                          .filter(
                                            (item) =>
                                              item[props.quarters] === "total" &&
                                              item[props.groupbyColumn] === team
                                          )[0].rampStatus
                                        },
                                        team,
                                        'team'
                                      )
                                    }
                                  }
                                    
                                    
                                  }
                                  style={ {cursor: props.groupby === 'Team' ? 'pointer' : 'default'}}
                                >
                                  {teamData
                                    .filter(
                                      (item) =>
                                        item[props.quarters] === "total" &&
                                        item[props.groupbyColumn] === team
                                    )
                                    .map((item) =>
                                      item.acv === "undefiend" ||
                                      item.acv === null
                                        ? "-"
                                        : countOrACVFormatter(item.acv, true)
                                    )}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.repHeader}
                                  colSpan={1}
                                >
                                  {teamData
                                    .filter(
                                      (item) =>
                                        item[props.quarters] === "total" &&
                                        item[props.groupbyColumn] === team
                                    )
                                    .map((item) =>
                                      item.attainment === "undefiend" ||
                                      item.attainment === null
                                        ? "-"
                                        : Math.round(item.attainment * 100) +
                                          "%"
                                    )}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.repHeader}
                                  colSpan={1}
                                >
                                  {teamData
                                    .filter(
                                      (item) =>
                                        item[props.quarters] === "total" &&
                                        item[props.groupbyColumn] === team
                                    )
                                    .map((item) =>
                                      item.repProductivity === "undefiend" ||
                                      item.repProductivity === null
                                        ? "-"
                                        : countOrACVFormatter(
                                            item.repProductivity,
                                            true
                                          )
                                    )}
                                </TableCell>
                                {/* <TableCell
                                  align="right"
                                  className={classes.repHeader}
                                  colSpan={1}
                                >
                                  {teamData
                                    .filter(
                                      (item) =>
                                        item[props.quarters] === "total" &&
                                        item[props.groupbyColumn] === team
                                    )
                                    .map((item) =>
                                      item.repProductivity === "undefiend" ||
                                      item.repProductivity === null
                                        ? "-"
                                        : countOrACVFormatter(
                                            item.repProductivity / 3,
                                            true
                                          )
                                    )}
                                </TableCell> */}
                                {props.showReps !== true && (
                                  <TableCell
                                    align="right"
                                    className={classes.repHeader}
                                    colSpan={1}
                                  >
                                    {teamData
                                      .filter(
                                        (item) =>
                                          item[props.quarters] === "total" &&
                                          item[props.groupbyColumn] === team
                                      )
                                      .map((item) =>
                                        item.repCount === "undefiend" ||
                                        item.repCount === null
                                          ? "-"
                                          : countOrACVFormatter(
                                              item.repCount,
                                              false
                                            )
                                      )}
                                  </TableCell>
                                )}
                              </React.Fragment>
                            )}
                          </StyledTableRow>
                          {expandedRows.includes(i) &&
                            props.showReps === true &&
                              (
                                props.groupby === 'Team, Ramp Status' ? 
                                reps.map((rep, i) => {
                                  countinc = countinc + 1;
                                  const repsData = repData.filter(
                                    (item) => item.Owner_Name === rep.Full_Name && item.rampStatus === rep.Ramp_Status
                                  );
                                  // console.log(reps,team, repData, repsData, 'REPPPPDATA')
                                  return (
                                    <StyledTableRow key={i}>
                                      <TableCell
                                        align="left"
                                        // className={`${classes.table} ${classes.indentName}`}
                                        className={
                                          isSticky
                                            ? `${classes.sticky} ${classes.table} ${classes.indentName}`
                                            : `${classes.table} ${classes.indentName}`
                                        }
                                        style={
                                          isSticky && countinc % 2 === 0
                                            ? {
                                                background: "#f5f5f5",
                                                paddingLeft: "25px",
                                              }
                                            : { paddingLeft: "25px" }
                                        }
                                        colSpan={1}
                                      >
                                        {quarter.filter(
                                          (x) =>
                                            x.Owner_Name === rep.Full_Name && x.Quota !== null
                                          &&  x.rampStatus === rep.Ramp_Status
                                        ).length < uniqueQuarters.length
                                          ? ast(rep.Full_Name)
                                          : rep.Full_Name}
                                      </TableCell>
                                      {uniqueQuarters.map((qtr, j) => {
                                        const QuotaData = repsData
                                          .filter(
                                            (item) =>
                                              item[props.quarters] === qtr &&
                                              item.Owner_Name === rep.Full_Name && item.rampStatus === rep.Ramp_Status
                                          )
                                          .map((item) => item.Quota)[0];
                                        // console.log(props.data.filter(item => item.Owner_Name === owner).map(item => item.Quota)[0] === 0)
                                        const acvData = repsData
                                          .filter(
                                            (item) =>
                                              item[props.quarters] === qtr &&
                                              item.Owner_Name === rep.Full_Name && item.rampStatus === rep.Ramp_Status
                                          )
                                          .map((item) => item.acv)[0];
                                        const attainmentData = repsData
                                          .filter(
                                            (item) =>
                                              item[props.quarters] === qtr &&
                                              item.Owner_Name === rep.Full_Name && item.rampStatus === rep.Ramp_Status
                                          )
                                          .map((item) => item.attainment)[0];
                                        return (
                                          <>
                                            <TableCell
                                              align="right"
                                              className={classes.repNumber}
                                              colSpan={1}
                                            >
                                              {QuotaData === "undefiend" ||
                                              QuotaData === null ||
                                              _isNaN(QuotaData)
                                                ? "-"
                                                : countOrACVFormatter(
                                                    QuotaData,
                                                    true
                                                  )}{" "}
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              className={classes.repNumber}
                                              colSpan={1}
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                props.handleChangeLocation(
                                                  "drilldownOnGraph2",
                                                  {
                                                    row: rep.Full_Name,
                                                    cell: qtr,
                                                    quarter: props.quarters,
                                                    rampStatus: rep.Ramp_Status
                                                  },
                                                  teamForDrilldown,
                                                )
                                              }
                                            >
                                              {acvData === "undefiend" ||
                                              acvData === null ||
                                              _isNaN(acvData)
                                                ? "-"
                                                : countOrACVFormatter(
                                                    acvData,
                                                    true
                                                  )}
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              className={classes.repNumber}
                                              colSpan={1}
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                props.handleChangeLocation(
                                                  "drilldownOnGraph2",
                                                  {
                                                    row: rep.Full_Name,
                                                    cell: qtr,
                                                    quarter: props.quarters,
                                                    rampStatus: rep.Ramp_Status
                                                  },
                                                  teamForDrilldown
                                                )
                                              }
                                            >
                                              {attainmentData === "undefiend" ||
                                              attainmentData === null ||
                                              _isNaN(attainmentData)
                                                ? "-"
                                                : Math.round(attainmentData * 100) +
                                                  "%"}
                                            </TableCell>
                                          </>
                                        );
                                      })}
    
                                      {uniqueQuarters.length >= 1 && (
                                        <React.Fragment>
                                          <TableCell
                                            align="right"
                                            className={classes.repNumber}
                                            colSpan={1}
                                          >
                                            {repData
                                              .filter(
                                                (item) =>
                                                  item[props.quarters] ===
                                                    "total" &&
                                                  item.Owner_Name === rep.Full_Name && item.rampStatus === rep.Ramp_Status
                                              )
                                              .map((item) =>
                                                item.Quota === "undefiend" ||
                                                item.Quota === null
                                                  ? "-"
                                                  : countOrACVFormatter(
                                                      item.Quota,
                                                      true
                                                    )
                                              )}
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            className={classes.repNumber}
                                            colSpan={1}
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              props.handleChangeLocation(
                                                "drilldownOnGraph2",
                                                {
                                                  row: rep.Full_Name,
                                                  cell: "total",
                                                  quarter: props.quarters,
                                                  uniqueQuarters: repData
                                                    .filter(
                                                      (item) =>
                                                        item[props.quarters] ===
                                                          "total" &&
                                                        item.Owner_Name === rep.Full_Name && item.rampStatus === rep.Ramp_Status
                                                    )
                                                    .map((c) => c.quarter)[0],
                                                    rampStatus: rep.Ramp_Status
                                                },
                                                teamForDrilldown
                                              )
                                            }
                                          >
                                            {repData
                                              .filter(
                                                (item) =>
                                                  item[props.quarters] ===
                                                    "total" &&
                                                  item.Owner_Name === rep.Full_Name && item.rampStatus === rep.Ramp_Status
                                              )
                                              .map((item) =>
                                                item.acv === "undefiend" ||
                                                item.acv === null
                                                  ? "-"
                                                  : countOrACVFormatter(
                                                      item.acv,
                                                      true
                                                    )
                                              )}
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            className={classes.repNumber}
                                            colSpan={1}
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              props.handleChangeLocation(
                                                "drilldownOnGraph2",
                                                {
                                                  row: rep.Full_Name,
                                                  cell: "total",
                                                  quarter: props.quarters,
                                                  uniqueQuarters: repData
                                                    .filter(
                                                      (item) =>
                                                        item[props.quarters] ===
                                                          "total" &&
                                                        item.Owner_Name === rep.Full_Name && item.rampStatus === rep.Ramp_Status
                                                    )
                                                    .map((c) => c.quarter)[0],
                                                    rampStatus: rep.Ramp_Status
                                                },
                                                teamForDrilldown
                                              )
                                            }
                                          >
                                            {repData
                                              .filter(
                                                (item) =>
                                                  item[props.quarters] ===
                                                    "total" &&
                                                  item.Owner_Name === rep.Full_Name && item.rampStatus === rep.Ramp_Status
                                              )
                                              .map((item) =>
                                                item.attainment === "undefiend" ||
                                                item.attainment === null
                                                  ? "-"
                                                  : Math.round(
                                                      item.attainment * 100
                                                    ) + "%"
                                              )}
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            className={classes.repNumber}
                                            colSpan={1}
                                          >
                                            {repData
                                              .filter(
                                                (item) =>
                                                  item[props.quarters] ===
                                                    "total" &&
                                                  item.Owner_Name === rep.Full_Name && item.rampStatus === rep.Ramp_Status
                                              )
                                              .map((item) =>
                                                item.repProductivity ===
                                                  "undefiend" ||
                                                item.repProductivity === null
                                                  ? "-"
                                                  : countOrACVFormatter(
                                                      item.repProductivity,
                                                      true
                                                    )
                                              )}
                                          </TableCell>
                                          {/* <TableCell
                                            align="right"
                                            className={classes.repNumber}
                                            colSpan={1}
                                          >
                                            {repData
                                              .filter(
                                                (item) =>
                                                  item[props.quarters] ===
                                                    "total" &&
                                                  item.Owner_Name === rep.Full_Name && item.rampStatus === rep.Ramp_Status
                                              )
                                              .map((item) =>
                                                item.repProductivity ===
                                                  "undefiend" ||
                                                item.repProductivity === null
                                                  ? "-"
                                                  : countOrACVFormatter(
                                                      item.repProductivity / 3,
                                                      true
                                                    )
                                              )}
                                          </TableCell> */}
                                        </React.Fragment>
                                      )}
                                    </StyledTableRow>
                                  );
                                })
                                
                                :
                                reps.map((rep, i) => {
                              countinc = countinc + 1;
                              const repsData = repData.filter(
                                (item) => item.Owner_Name === rep
                              );
                              return (
                                <StyledTableRow key={i}>
                                  <TableCell
                                    align="left"
                                    // className={`${classes.table} ${classes.indentName}`}
                                    className={
                                      isSticky
                                        ? `${classes.sticky} ${classes.table} ${classes.indentName}`
                                        : `${classes.table} ${classes.indentName}`
                                    }
                                    style={
                                      isSticky && countinc % 2 === 0
                                        ? {
                                            background: "#f5f5f5",
                                            paddingLeft: "25px",
                                          }
                                        : { paddingLeft: "25px" }
                                    }
                                    colSpan={1}
                                  >
                                    {quarter.filter(
                                      (x) =>
                                        x.Owner_Name === rep && x.Quota !== null
                                      
                                    ).length < uniqueQuarters.length
                                      ? ast(rep)
                                      : rep}
                                  </TableCell>
                                  {uniqueQuarters.map((qtr, j) => {
                                    const QuotaData = repsData
                                      .filter(
                                        (item) =>
                                          item[props.quarters] === qtr &&
                                          item.Owner_Name === rep
                                      )
                                      .map((item) => item.Quota)[0];
                                    // console.log(props.data.filter(item => item.Owner_Name === owner).map(item => item.Quota)[0] === 0)
                                    const acvData = repsData
                                      .filter(
                                        (item) =>
                                          item[props.quarters] === qtr &&
                                          item.Owner_Name === rep
                                      )
                                      .map((item) => item.acv)[0];
                                    const attainmentData = repsData
                                      .filter(
                                        (item) =>
                                          item[props.quarters] === qtr &&
                                          item.Owner_Name === rep
                                      )
                                      .map((item) => item.attainment)[0];
                                    return (
                                      <>
                                        <TableCell
                                          align="right"
                                          className={classes.repNumber}
                                          colSpan={1}
                                        >
                                          {QuotaData === "undefiend" ||
                                          QuotaData === null ||
                                          _isNaN(QuotaData)
                                            ? "-"
                                            : countOrACVFormatter(
                                                QuotaData,
                                                true
                                              )}{" "}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className={classes.repNumber}
                                          colSpan={1}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            props.handleChangeLocation(
                                              "drilldownOnGraph2",
                                              {
                                                row: rep,
                                                cell: qtr,
                                                quarter: props.quarters,
                                              },
                                              teamForDrilldown
                                            )
                                          }
                                        >
                                          {acvData === "undefiend" ||
                                          acvData === null ||
                                          _isNaN(acvData)
                                            ? "-"
                                            : countOrACVFormatter(
                                                acvData,
                                                true
                                              )}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className={classes.repNumber}
                                          colSpan={1}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            props.handleChangeLocation(
                                              "drilldownOnGraph2",
                                              {
                                                row: rep,
                                                cell: qtr,
                                                quarter: props.quarters,
                                              },
                                              teamForDrilldown
                                            )
                                          }
                                        >
                                          {attainmentData === "undefiend" ||
                                          attainmentData === null ||
                                          _isNaN(attainmentData)
                                            ? "-"
                                            : Math.round(attainmentData * 100) +
                                              "%"}
                                        </TableCell>
                                      </>
                                    );
                                  })}

                                  {uniqueQuarters.length >= 1 && (
                                    <React.Fragment>
                                      <TableCell
                                        align="right"
                                        className={classes.repNumber}
                                        colSpan={1}
                                      >
                                        {repData
                                          .filter(
                                            (item) =>
                                              item[props.quarters] ===
                                                "total" &&
                                              item.Owner_Name === rep
                                          )
                                          .map((item) =>
                                            item.Quota === "undefiend" ||
                                            item.Quota === null
                                              ? "-"
                                              : countOrACVFormatter(
                                                  item.Quota,
                                                  true
                                                )
                                          )}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className={classes.repNumber}
                                        colSpan={1}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          props.handleChangeLocation(
                                            "drilldownOnGraph2",
                                            {
                                              row: rep,
                                              cell: "total",
                                              quarter: props.quarters,
                                              uniqueQuarters: repData
                                                .filter(
                                                  (item) =>
                                                    item[props.quarters] ===
                                                      "total" &&
                                                    item.Owner_Name === rep
                                                )
                                                .map((c) => c.quarter)[0],
                                            },
                                            teamForDrilldown
                                          )
                                        }
                                      >
                                        {repData
                                          .filter(
                                            (item) =>
                                              item[props.quarters] ===
                                                "total" &&
                                              item.Owner_Name === rep
                                          )
                                          .map((item) =>
                                            item.acv === "undefiend" ||
                                            item.acv === null
                                              ? "-"
                                              : countOrACVFormatter(
                                                  item.acv,
                                                  true
                                                )
                                          )}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className={classes.repNumber}
                                        colSpan={1}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          props.handleChangeLocation(
                                            "drilldownOnGraph2",
                                            {
                                              row: rep,
                                              cell: "total",
                                              quarter: props.quarters,
                                              uniqueQuarters: repData
                                                .filter(
                                                  (item) =>
                                                    item[props.quarters] ===
                                                      "total" &&
                                                    item.Owner_Name === rep
                                                )
                                                .map((c) => c.quarter)[0],
                                            },
                                            teamForDrilldown
                                          )
                                        }
                                      >
                                        {repData
                                          .filter(
                                            (item) =>
                                              item[props.quarters] ===
                                                "total" &&
                                              item.Owner_Name === rep
                                          )
                                          .map((item) =>
                                            item.attainment === "undefiend" ||
                                            item.attainment === null
                                              ? "-"
                                              : Math.round(
                                                  item.attainment * 100
                                                ) + "%"
                                          )}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className={classes.repNumber}
                                        colSpan={1}
                                      >
                                        {repData
                                          .filter(
                                            (item) =>
                                              item[props.quarters] ===
                                                "total" &&
                                              item.Owner_Name === rep
                                          )
                                          .map((item) =>
                                            item.repProductivity ===
                                              "undefiend" ||
                                            item.repProductivity === null
                                              ? "-"
                                              : countOrACVFormatter(
                                                  item.repProductivity,
                                                  true
                                                )
                                          )}
                                      </TableCell>
                                      {/* <TableCell
                                        align="right"
                                        className={classes.repNumber}
                                        colSpan={1}
                                      >
                                        {repData
                                          .filter(
                                            (item) =>
                                              item[props.quarters] ===
                                                "total" &&
                                              item.Owner_Name === rep
                                          )
                                          .map((item) =>
                                            item.repProductivity ===
                                              "undefiend" ||
                                            item.repProductivity === null
                                              ? "-"
                                              : countOrACVFormatter(
                                                  item.repProductivity / 3,
                                                  true
                                                )
                                          )}
                                      </TableCell> */}
                                    </React.Fragment>
                                  )}
                                </StyledTableRow>
                              );
                            })
                              )
                            }
                        </>
                      );
                    })
                  : uniqueOwner.map((owner, i) => {
                      countinc = countinc + 1;
                      return (
                        <StyledTableRow key={i}>
                          <TableCell
                            align="left"
                            className={`${classes.table}`}
                            colSpan={1}
                          >
                            {quarter.filter(
                              (x) => x.Owner_Name === owner && x.Quota !== null
                            ).length < uniqueQuarters.length
                              ? ast(owner)
                              : owner}
                          </TableCell>

                          {uniqueQuarters.map((qtr, j) => {
                            const QuotaData = props.data
                              .filter(
                                (item) =>
                                  item[props.quarters] === qtr &&
                                  item.Owner_Name === owner
                              )
                              .map((item) => item.Quota)[0];
                            // console.log(props.data.filter(item => item.Owner_Name === owner).map(item => item.Quota)[0] === 0)
                            const acvData = props.data
                              .filter(
                                (item) =>
                                  item[props.quarters] === qtr &&
                                  item.Owner_Name === owner
                              )
                              .map((item) => item.acv)[0];
                            const attainmentData = props.data
                              .filter(
                                (item) =>
                                  item[props.quarters] === qtr &&
                                  item.Owner_Name === owner
                              )
                              .map((item) => item.attainment)[0];
                            return (
                              <>
                                <TableCell
                                  align="right"
                                  className={classes.repNumber}
                                  colSpan={1}
                                >
                                  {QuotaData === "undefiend" ||
                                  QuotaData === null ||
                                  _isNaN(QuotaData)
                                    ? "-"
                                    : countOrACVFormatter(QuotaData, true)}{" "}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.repNumber}
                                  colSpan={1}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph2",
                                      {
                                        row: owner,
                                        cell: qtr,
                                        quarter: props.quarters,
                                      }
                                    )
                                  }
                                >
                                  {acvData === "undefiend" ||
                                  acvData === null ||
                                  _isNaN(acvData)
                                    ? "-"
                                    : countOrACVFormatter(acvData, true)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.repNumber}
                                  colSpan={1}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph2",
                                      {
                                        row: owner,
                                        cell: qtr,
                                        quarter: props.quarters,
                                      }
                                    )
                                  }
                                >
                                  {attainmentData === "undefiend" ||
                                  attainmentData === null ||
                                  _isNaN(attainmentData)
                                    ? "-"
                                    : Math.round(attainmentData * 100) + "%"}
                                </TableCell>
                              </>
                            );
                          })}

                          {uniqueQuarters.length >= 1 && (
                            <React.Fragment>
                              <TableCell
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {props.data
                                  .filter(
                                    (item) =>
                                      item[props.quarters] === "total" &&
                                      item.Owner_Name === owner
                                  )
                                  .map((item) =>
                                    item.Quota === "undefiend" ||
                                    item.Quota === null
                                      ? "-"
                                      : countOrACVFormatter(item.Quota, true)
                                  )}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph2",
                                    {
                                      row: owner,
                                      cell: "total",
                                      quarter: props.quarters,
                                      uniqueQuarters: uniqueQuarters,
                                    }
                                  )
                                }
                              >
                                {props.data
                                  .filter(
                                    (item) =>
                                      item[props.quarters] === "total" &&
                                      item.Owner_Name === owner
                                  )
                                  .map((item) =>
                                    item.acv === "undefiend" ||
                                    item.acv === null
                                      ? "-"
                                      : countOrACVFormatter(item.acv, true)
                                  )}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph2",
                                    {
                                      row: owner,
                                      cell: "total",
                                      quarter: props.quarters,
                                      uniqueQuarters: uniqueQuarters,
                                    }
                                  )
                                }
                              >
                                {props.data
                                  .filter(
                                    (item) =>
                                      item[props.quarters] === "total" &&
                                      item.Owner_Name === owner
                                  )
                                  .map((item) =>
                                    item.attainment === "undefiend" ||
                                    item.attainment === null
                                      ? "-"
                                      : Math.round(item.attainment * 100) + "%"
                                  )}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {props.data
                                  .filter(
                                    (item) =>
                                      item[props.quarters] === "total" &&
                                      item.Owner_Name === owner
                                  )
                                  .map((item) =>
                                    item.repProductivity === "undefiend" ||
                                    item.repProductivity === null
                                      ? "-"
                                      : countOrACVFormatter(
                                          item.repProductivity,
                                          true
                                        )
                                  )}
                              </TableCell>
                              {/* <TableCell
                                align="right"
                                className={classes.repNumber}
                                colSpan={1}
                              >
                                {props.data
                                  .filter(
                                    (item) =>
                                      item[props.quarters] === "total" &&
                                      item.Owner_Name === owner
                                  )
                                  .map((item) =>
                                    item.repProductivity === "undefiend" ||
                                    item.repProductivity === null
                                      ? "-"
                                      : countOrACVFormatter(
                                          item.repProductivity / 3,
                                          true
                                        )
                                  )}
                              </TableCell> */}
                            </React.Fragment>
                          )}
                        </StyledTableRow>
                      );
                    })}

                {/* <StyledTableRow
                  className={classes.fontBold}
                  style={
                    props.showReps === true ? { background: "#dae3f3" } : {}
                  }
                >
                  <TableCell
                    align="left"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    // style={
                    //   isSticky && (countinc + 1) % 2 === 0
                    //     ? {
                    //         background: "#f5f5f5",
                    //         fontWeight: 600,
                    //       }
                    //     : { fontWeight: 600 }
                    // }
                    style={
                      props.showReps === true
                        ? { background: "#dae3f3", fontWeight: 600 }
                        : isSticky && (countinc + 1) % 2 === 0
                        ? { background: "#f5f5f5", fontWeight: 600 }
                        : {}
                    }
                    colSpan={1}
                    // style={{ fontWeight: 600 }}
                  >
                    Total for Teams
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    const totalQuota = props.teamData
                      .filter((item) => item[props.quarters] === cell)
                      .map((item) => item.Quota)
                      .reduce((a, b) => a + b, 0);
                    const totalActual = props.teamData
                      .filter((item) => item[props.quarters] === cell)
                      .map((item) => item.acv)
                      .reduce((a, b) => a + b, 0);
                    const totalAttainment = props.teamData
                      .filter((item) => item[props.quarters] === cell)
                      .map((item) => item.attainment)
                      .reduce((a, b) => a + b, 0);
                    const uniqueRep = props.data
                      .filter((item) => item[props.quarters] === cell)
                      .map((item) => item.Owner_Name);
                    // const  totalQ2 = props.winAcvData.filter(item => item[props.quarter] === cell).map(item => item.count).reduce((a, b) => a + b, 0)
                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          align="right"
                          className={classes.repHeader}
                          colSpan={1}
                          style={{ fontWeight: 600 }}
                        >
                          {countOrACVFormatter(totalQuota, true)}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.repHeader}
                          colSpan={1}
                          style={{ fontWeight: 600, cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph2",
                              {
                                row: "total",
                                cell: cell,
                                quarter: props.quarters,
                                uniqueRep: uniqueRep,
                              },
                              props.groupby === "Team" ||
                                props.groupby === "Team, Ramp Status"
                                ? uniqueTeamsForDrilldown
                                : undefined
                            )
                          }
                        >
                          {countOrACVFormatter(totalActual, true)}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.repHeader}
                          colSpan={1}
                          style={{ fontWeight: 600, cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph2",
                              {
                                row: "total",
                                cell: cell,
                                quarter: props.quarters,
                                uniqueRep: uniqueRep,
                              },
                              props.groupby === "Team" ||
                                props.groupby === "Team, Ramp Status"
                                ? uniqueTeamsForDrilldown
                                : undefined
                            )
                          }
                        >
                          {_isNaN(totalActual / totalQuota)
                            ? 0
                            : Math.round((totalActual / totalQuota) * 100)}
                          %
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ fontWeight: 600 }}
                      >
                        {props.teamData
                          .filter((item) => item[props.quarters] === "total")
                          .map((item) => item.Quota)
                          .reduce((a, b) => a + b, 0) === undefined
                          ? "-"
                          : countOrACVFormatter(
                              props.teamData
                                .filter(
                                  (item) => item[props.quarters] === "total"
                                )
                                .map((item) => item.Quota)
                                .reduce((a, b) => a + b, 0),
                              true
                            )}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ fontWeight: 600, cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph2",
                            {
                              row: "total",
                              cell: "total",
                              quarter: props.quarters,
                              uniqueRep: uniqueOwner,
                              uniqueQuarters: uniqueQuarters,
                            },
                            props.groupby === "Team" ||
                              props.groupby === "Team, Ramp Status"
                              ? uniqueTeamsForDrilldown
                              : undefined
                          )
                        }
                      >
                        {props.teamData
                          .filter((item) => item[props.quarters] === "total")
                          .map((item) => item.acv)
                          .reduce((a, b) => a + b, 0) === undefined
                          ? "-"
                          : countOrACVFormatter(
                              props.teamData
                                .filter(
                                  (item) => item[props.quarters] === "total"
                                )
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0),
                              true
                            )}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ fontWeight: 600, cursor: "pointer" }}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph2",
                            {
                              row: "total",
                              cell: "total",
                              quarter: props.quarters,
                              uniqueRep: uniqueOwner,
                              uniqueQuarters: uniqueQuarters,
                            },
                            props.groupby === "Team" ||
                              props.groupby === "Team, Ramp Status"
                              ? uniqueTeamsForDrilldown
                              : undefined
                          )
                        }
                      >
                        {_isNaN(
                          props.teamData
                            .filter((item) => item[props.quarters] === "total")
                            .map((item) => item.acv)
                            .reduce((a, b) => a + b, 0) /
                            props.teamData
                              .filter(
                                (item) => item[props.quarters] === "total"
                              )
                              .map((item) => item.Quota)
                              .reduce((a, b) => a + b, 0)
                        )
                          ? 0
                          : Math.round(
                              (props.teamData
                                .filter(
                                  (item) => item[props.quarters] === "total"
                                )
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0) /
                                props.teamData
                                  .filter(
                                    (item) => item[props.quarters] === "total"
                                  )
                                  .map((item) => item.Quota)
                                  .reduce((a, b) => a + b, 0)) *
                                100
                            )}
                        %
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ fontWeight: 600 }}
                      >
                        {props.data
                          .filter((item) => item[props.quarters] === "total")
                          .map((item) => item.acv)
                          .reduce((a, b) => a + b, 0) === undefined
                          ? "-"
                          : countOrACVFormatter(
                              props.data
                                .filter(
                                  (item) => item[props.quarters] === "total"
                                )
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0) /
                                uniqueQuarters.length /
                                uniqueOwner.length,
                              true
                            )}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ fontWeight: 600 }}
                      >
                        {props.data
                          .filter((item) => item[props.quarters] === "total")
                          .map((item) => item.acv)
                          .reduce((a, b) => a + b, 0) === undefined
                          ? "-"
                          : countOrACVFormatter(
                              props.data
                                .filter(
                                  (item) => item[props.quarters] === "total"
                                )
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0) /
                                uniqueQuarters.length /
                                uniqueOwner.length /
                                3,
                              true
                            )}
                      </TableCell>
                      {props.showReps !== true && (
                        <TableCell
                          align="right"
                          className={classes.repHeader}
                          colSpan={1}
                          style={{ fontWeight: 600 }}
                        >
                          {countOrACVFormatter(
                            props.teamData
                              .filter(
                                (item) => item[props.quarters] === "total"
                              )
                              .map((item) => item.repCount)
                              .filter(Boolean)
                              .reduce((a, b) => a + b, 0),
                            false
                          )}
                        </TableCell>
                      )}
                    </React.Fragment>
                  )}
                </StyledTableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {uniqueOwner
          .map((owner) =>
            quarter.filter((x) => x.Owner_Name === owner && x.Quota !== null)
              .length < uniqueQuarters.length
              ? quarter.filter(
                  (x) => x.Owner_Name === owner && x.Quota !== null
                ).length
              : 0
          )
          .reduce((a, b) => a + b, 0) !== 0 &&
          uniqueOwner
            .map((owner) =>
              quarter.filter((x) => x.Owner_Name === owner && x.Quota !== null)
                .length < uniqueQuarters.length
                ? quarter.filter(
                    (x) => x.Owner_Name === owner && x.Quota !== null
                  ).length
                : 0
            )
            .reduce((a, b) => a + b, 0) !== 0 &&
          props.showReps === true && (
            <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="body2" style={{ paddingLeft: "1rem" }}>
                <span style={{ color: "#ED7D31" }}>*</span>&nbsp; Excluded from
                analysis for time periods where no quota data is available.
              </Typography>
            </Grid>
          )}
      </CardContent>
    </div>
  );
}
