import { Grid, makeStyles, withStyles } from "@material-ui/core";
import React from "react";
import BackdropContainer from "./Backdrop";
import MediaCard from "./InsightCardV2";
import ConfifmModal from "../Favourites/ConfirmModal";
import { MAC_BROWSER_WIDTH } from "../util/utils";
const useStyles = makeStyles((theme) => ({
  dynamicWidth: {
    margin: "auto",
    paddingTop: "1.5rem",
    maxWidth: 1700,
    width: "98%",
    [theme.breakpoints.down("lg")]: {
      maxWidth: window.self !== window.parent ? 1700 : 1200,
      width: "98%",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: 1700,
      width: "99%",
    },
  },
  dashOuter: {
    display: "flex",
    flexGrow: 1,
    margin: "auto",
  },
  dashInner: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  graph1: {
    flexGrow: 1,
    height: 250,
    margin: 10,
  },
}));
const NavItemMenuComponent = (props) => {
  const classes = useStyles();
  let maxWidth = 1100;
  if (window.innerWidth <= 1368) {
    maxWidth = 1100;
  }
  if (window.innerWidth > 1368) {
    maxWidth = 1100;
  }
  return (
    <Grid
    // className="NavItemMenu"
    // style={{
    //   maxWidth,
    // }}
    >
      {props.location === "home" && (
        <div
          className={classes.dashOuter}
          style={
            props.location === "home" && {
              maxWidth: 1200,
              justifyContent: "center",
              marginTop: 15,
              display: "flow",
            }
          }
        >
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "flex-start",
              flexWrap: "wrap",
              marginLeft: "9rem",
            }}
          >
            {/* <Grid
              container
              className={classes.grid}
              wrap="wrap"
              // textAlign="left"
              justifyContent="center"
              style={{ alignItems: "flex-start" }}
            > */}

            {props.charts !== undefined &&
              props.charts
                .sort((a, b) => a.Sequence - b.Sequence)
                .map((it, index) => (
                  <MediaCard
                    obj={it}
                    setCharts={props.setCharts}
                    curr_chart={it}
                    charts={props.charts}
                    favourites={{}}
                    chartID={it.ChartID}
                    header={it.Chart_Name}
                    text={it.Description}
                    image={`${props.baseURL}${it.URL_for_Thumbnail}`}
                    changeLocation={props.handleLocationChange}
                    width={35}
                    dashboard_id={it.dashboard_id}
                    addFavourite={props.addFavourite}
                    removeFavourite={props.removeFavourite}
                    Position={it.Position}
                    navItem={it.Nav_Item}
                    handleOpen={props.handleOpen}
                    confirmSignal={props.confirmSignal}
                    selectedDashboard={props.selectedDashboard}
                    cleanAfterConfirm={props.cleanAfterConfirm}
                  />
                ))}
            {/* </Grid> */}
          </div>
        </div>
      )}
      <Grid
        style={
          navigator.platform.toLowerCase().includes("mac")
            ? {
                maxWidth:
                  Math.round((window.innerWidth * MAC_BROWSER_WIDTH) / 100) *
                  100,
              }
            : {}
        }
        container
        className={classes.dynamicWidth}
        justifyContent="center"
      >
        {props.routesComponent}
      </Grid>

      <ConfifmModal
        selectedDashboard={props.selectedDashboard}
        handleConfirmClose={props.handleConfirmClose}
        setConfirmSignal={props.setConfirmSignal}
        open={props.open}
        handleClose={props.handleClose}
        handleOpen={props.handleOpen}
      />
      <BackdropContainer showLoading={props.isDeleting} />
    </Grid>
  );
};

export default NavItemMenuComponent;
