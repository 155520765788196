import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  percentageFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

export default function AttchRateTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  let total;

  const uniqueTBoldypes = [
    "Total Opportunities Won",
    "Total Opportunities Lost",
  ];
  const uniqueWonTypes = props.tableData
    .filter((a) => a.name === "Won")
    .map((s) => s.type)
    .filter(getUniqueValues);
  const uniqueLostTypes = props.tableData
    .filter((a) => a.name === "Lost")
    .map((s) => s.type)
    .filter(getUniqueValues);
  const uniqueQuarters = props.tableData
    .map((s) => s.closed_fiscal_quarter)
    .filter(getUniqueValues);

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);
  return (
    <div style={{ maxWidth: "98%", justifyContent: "center", padding: "2rem" }}>
      <Grid xs={12}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{ width: "auto", padding: "2rem", width: "auto" }}
        id={"myTableContainer"}
        ref={tableContainerRef}
      >
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.tableType}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow
              style={{ fontWeight: 600, paddingLeft: 5 }}
              className={classes.fontBold}
            >
              <TableCell
                align="center"
                className={classes.repHeader}
                rowSpan={2}
                style={{ minWidth: 150 }}
              ></TableCell>
              {uniqueQuarters.map((i, index) => {
                return (
                  <TableCell
                    colSpan={4}
                    key={i}
                    align="center"
                    style={
                      index % 2 === 0
                        ? { background: "#4471c4", color: "white" }
                        : { background: "#5b9bd5", color: "white" }
                    }
                    className={classes.repHeader}
                  >
                    {i}
                  </TableCell>
                );
              })}

              {uniqueQuarters.length === 0 && (
                <TableCell
                  rowSpan={8}
                  className={classes.repHeader}
                  style={{ width: 200, textAlign: "center" }}
                >
                  No data available
                </TableCell>
              )}
            </TableRow>
            <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
              {uniqueQuarters.map((i) => {
                return (
                  <React.Fragment key={i}>
                    <TableCell
                      align="center"
                      style={{ minWidth: 60, maxWidth: 60 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      # of Opps
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        props.tableType === "radar"
                          ? { minWidth: 100, maxWidth: 100 }
                          : { minWidth: 70, maxWidth: 70 }
                      }
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      {props.tableType === "radar" ? "ACV" : "# of connectors"}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 80, maxWidth: 80 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      <>Attach Rate</>
                      <br />
                      <>% (by Opps)</>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 80, maxWidth: 80 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      {props.tableType === "radar"
                        ? "Avg. Attach Price"
                        : "Avg. # of Connectors"}
                    </TableCell>
                  </React.Fragment>
                );
              })}
            </TableRow>
            {uniqueWonTypes.map((oppType) => {
              return (
                <>
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={
                        uniqueTBoldypes.includes(oppType)
                          ? classes.repHeader
                          : classes.cellStyle
                      }
                      colSpan={1}
                    >
                      {oppType}
                    </TableCell>
                    {uniqueQuarters.map((q) => {
                      const rowData = props.tableData.filter(
                        (i) =>
                          i.closed_fiscal_quarter === q &&
                          i.name === "Won" &&
                          i.type === oppType
                      );
                      const count = rowData.length === 0 ? 0 : rowData[0].count;
                      const acv =
                        rowData.length === 0
                          ? 0
                          : rowData[0][
                              props.tableType === "radar"
                                ? "acv"
                                : "connectorCount"
                            ];
                      const attachRate =
                        rowData.length === 0 ? 0 : rowData[0].attachRate;
                      const avg =
                        rowData.length === 0
                          ? 0
                          : rowData[0][
                              props.tableType === "radar"
                                ? "averageAttach"
                                : "averageConnectors"
                            ];
                      return (
                        <>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { quarter: [q], oppType: oppType },
                                "Won",
                                props.tableType
                              )
                            }
                            className={
                              uniqueTBoldypes.includes(oppType)
                                ? classes.cellHeader
                                : classes.cellNormal
                            }
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(count)
                              ? countOrACVFormatter(count, false)
                              : ""}
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { quarter: [q], oppType: oppType },
                                "Won",
                                props.tableType
                              )
                            }
                            className={
                              uniqueTBoldypes.includes(oppType)
                                ? classes.cellHeader
                                : classes.cellNormal
                            }
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(acv)
                              ? countOrACVFormatter(
                                  acv,
                                  props.tableType === "radar" ? true : false
                                )
                              : ""}
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { quarter: [q], oppType: oppType },
                                "Won",
                                props.tableType
                              )
                            }
                            className={
                              uniqueTBoldypes.includes(oppType)
                                ? classes.cellHeader
                                : classes.cellNormal
                            }
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(attachRate)
                              ? percentageFormatter(attachRate)
                              : ""}
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { quarter: [q], oppType: oppType },
                                "Won",
                                props.tableType
                              )
                            }
                            className={
                              uniqueTBoldypes.includes(oppType)
                                ? classes.cellHeader
                                : classes.cellNormal
                            }
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(avg)
                              ? props.tableType === "radar"
                                ? countOrACVFormatter(avg, true)
                                : parseFloat(avg).toFixed(1)
                              : ""}
                          </TableCell>
                        </>
                      );
                    })}
                  </StyledTableRow>
                </>
              );
            })}
            <StyledTableRow>
              {Array(uniqueQuarters.length * 4 + 1)
                .fill()
                .map((item) => (
                  <>
                    <TableCell className={classes.cellStyle}>&nbsp;</TableCell>
                  </>
                ))}
            </StyledTableRow>
            {uniqueLostTypes.map((oppType) => {
              return (
                <>
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={
                        uniqueTBoldypes.includes(oppType)
                          ? classes.repHeader
                          : classes.cellStyle
                      }
                      colSpan={1}
                    >
                      {oppType}
                    </TableCell>
                    {uniqueQuarters.map((q) => {
                      const rowData = props.tableData.filter(
                        (i) =>
                          i.closed_fiscal_quarter === q &&
                          i.name === "Lost" &&
                          i.type === oppType
                      );
                      const count = rowData.length === 0 ? 0 : rowData[0].count;
                      const acv =
                        rowData.length === 0
                          ? 0
                          : rowData[0][
                              props.tableType === "radar"
                                ? "acv"
                                : "connectorCount"
                            ];
                      const attachRate =
                        rowData.length === 0 ? 0 : rowData[0].attachRate;
                      const avg =
                        rowData.length === 0
                          ? 0
                          : rowData[0][
                              props.tableType === "radar"
                                ? "averageAttach"
                                : "averageConnectors"
                            ];
                      return (
                        <>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { quarter: [q], oppType: oppType },
                                "Lost",
                                props.tableType
                              )
                            }
                            className={
                              uniqueTBoldypes.includes(oppType)
                                ? classes.cellHeader
                                : classes.cellNormal
                            }
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(count)
                              ? countOrACVFormatter(count, false)
                              : ""}
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { quarter: [q], oppType: oppType },
                                "Lost",
                                props.tableType
                              )
                            }
                            className={
                              uniqueTBoldypes.includes(oppType)
                                ? classes.cellHeader
                                : classes.cellNormal
                            }
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(acv)
                              ? countOrACVFormatter(
                                  acv,
                                  props.tableType === "radar" ? true : false
                                )
                              : ""}
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { quarter: [q], oppType: oppType },
                                "Lost",
                                props.tableType
                              )
                            }
                            className={
                              uniqueTBoldypes.includes(oppType)
                                ? classes.cellHeader
                                : classes.cellNormal
                            }
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(attachRate)
                              ? percentageFormatter(attachRate)
                              : ""}
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { quarter: [q], oppType: oppType },
                                "Lost",
                                props.tableType
                              )
                            }
                            className={
                              uniqueTBoldypes.includes(oppType)
                                ? classes.cellHeader
                                : classes.cellNormal
                            }
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(avg)
                              ? props.tableType === "radar"
                                ? countOrACVFormatter(acv, true)
                                : parseFloat(avg).toFixed(1)
                              : ""}
                          </TableCell>
                        </>
                      );
                    })}
                  </StyledTableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
