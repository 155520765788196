import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Rnd } from "react-rnd";
import {
  Card,
  Grid,
  Typography,
  IconButton,
  TextField,
  Hidden,
  Dialog,
  Slide,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import "./AIWindow.scss";
import { appService } from "../../App/app.service";
import ErrorBoundary from "../ErrorBoundary";
import {
  getDefaultSalesType,
  getDefaultSelectedFiscalQuarters,
} from "../../util/customFunctions";
import { mapDispatchToPropsClosedFunnel } from "../../EnhancedInsights/Funnel/Funnel";
import { extractFilters } from "../../App/conv_ai/extract-filters";
import { requestedFunnel } from "../../App/conv_ai/requested-funnel";
import { getFilters } from "../../App/conv_ai/getFilters";
import { extractDashboards } from "../../App/conv_ai/extract-dashboards";
import { dashboardToFunctionMap } from "./dasboardToFunction.map";
import { metric_extraction } from "../../App/conv_ai/metric-extraction";
import { buildRecommendationResponse } from "../../App/conv_ai/extract-dashboards";
import { dashboardToRouteMap } from "../../App/conv_ai/dashboardToRoute.map";
import { Skeleton } from "@mui/material";
import MessageContainer from "./MessageContainer";
import ChatInput from "./ChatInput";
import ChatLoading from "./ChatLoading";
import MessageTemplate from "./MessageTemplate";
import { dashboardRecommnedationString } from "../../util/utils";
const useStyles = makeStyles((theme) => ({
  cardRoot: {
    height: "100%",
    cursor: "auto",
  },
  chatWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%", // or a specific height
  },
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    // flex: 1,
    // overflowY: "auto",
  },
  // chatContainer: {
  //   // flexDirection: "column",
  //   // display: "flex",
  //   // padding: theme.spacing(1),
  //   display: "flex",
  //   flex: 1,
  //   overflowY: "auto",
  // },
  typingContainer: {
    // padding: theme.spacing(2),
    // marginTop: 10,
    // borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RndStyle = {
  alignItems: "center",
  justifyContent: "center",
  border: "solid 1px #ddd",
  background: "#d90d0d",
  zIndex: "2903809183019283019238019238019283019283091283091283091",
  padding: "50000",
  position: "fixed",
  minWidth: "400px",
  minHeight: "400px",
};
const AIWindow = (props) => {
  const {
    funnelData,
    funnelFilters,
    addMessage,
    clearData,
    lkpStages,
    CONV_AI_CONSTANTS,
    textFieldQuestion,
    logConvAI,
    startWaiting,
    addWaitingMessage,
  } = props;
  const dashboardToFunctionMap = {
    SG_Dashboard_Closed_Funnel: props.getFunnelFilters,
  };
  const dashboardToFiltersFunctionMap = {
    SG_Dashboard_Closed_Funnel: props.getFunnelDataNew,
  };

  const classes = useStyles();
  const [loweredText, setLoweredText] = useState("");
  const [extractFiltersObject, setExtractedFilters] = useState();
  const [detectedDashboard, setDetectedDashboard] = useState("");
  const [detectedCharts, setDetectedCharts] = useState([]);
  const [metricObject, setMetricObject] = useState(null);
  const [extractedFiltersFlag, setExtractedFiltersFlag] = useState(false);
  const [stopInitialProcessing, setStopInitialProcessing] = useState(false);
  useState(false);
  const resetFunction = () => {
    // setLoweredText("");
    // setMetricObject(null);
    // setDetectedDashboard([]);
    // setDetectedCharts([]);
    // props.resetFilters();
  };
  useEffect(() => {
    props.getLKPStages();
    props.getDashboardKeywords();
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processQuestion = (value, addMessageToRedux = true) => {
    const currentQ = value.text;
    // e.target.value = "";

    //   props.askQuestion(currentQ);
    if (addMessageToRedux) {
      addMessage(currentQ, null, null, true);
    }

    console.log(currentQ);
    const loweredTextQuestion = currentQ.toLowerCase();
    setLoweredText(loweredTextQuestion);
    setExtractedFiltersFlag(false);
    let _metricObjects;
    try {
      _metricObjects = metric_extraction(
        loweredTextQuestion,
        false,
        lkpStages,
        props.customerMetrics
      );
    } catch (err) {
      console.error(err);
    }
    setMetricObject(_metricObjects);

    if (
      _metricObjects &&
      _metricObjects.metric !== null &&
      _metricObjects.hasMetric === true
    ) {
      const dashboardToUse = "SG_Dashboard_Closed_Funnel";
      setDetectedDashboard([dashboardToUse]);
      dashboardToFunctionMap[dashboardToUse](props.user.company, true);
    } else {
      // no natively supported metric found. we attempt to extract keywords and dashboards that mapped to the keywords
      const charts = extractDashboards(
        currentQ.toLowerCase(),
        props.chartsKeywordsMap,
        { CONV_AI_CONSTANTS }
      );
      const dashboards = extractDashboards(
        currentQ.toLowerCase(),
        props.dashboardKeywordsMap,
        { CONV_AI_CONSTANTS }
      );
      if (dashboards.length > 0) {
        props.startWaiting();
        setTimeout(() => {
          props.addWaitingMessage(
            ``,
            dashboards,
            charts,
            null,
            true,
            "recommendation"
          );
          logConvAI({
            question: currentQ,
            answer: dashboardRecommnedationString(dashboards),
            answerType: "recommendation",
            timestamp: new Date().toISOString(),
          });
          resetFunction();
        }, 1.5 * 1000);
      } else {
        props.startWaiting();
        setTimeout(() => {
          props.addWaitingMessage(
            "Please specify a metric like win rate, conversion rate, pipeline moved out etc.",
            null,
            null,
            null,
            true,
            "recommendation"
          );
          logConvAI({
            question: currentQ,
            answer:
              "Please specify a metric like win rate, conversion rate, pipeline moved out etc.",
            answerType: "error",
            timestamp: new Date().toISOString(),
          });
          resetFunction();
        }, 1.5 * 1000);
      }
    }
  };
  useEffect(() => {
    if (
      lkpStages &&
      CONV_AI_CONSTANTS &&
      textFieldQuestion !== "" &&
      stopInitialProcessing === false
    ) {
      processQuestion({ text: textFieldQuestion });
      props.removeTextFieldQuestion();
      setStopInitialProcessing(true);
    }
  }, [
    lkpStages,
    CONV_AI_CONSTANTS,
    props.conversationalAIMessages,
    processQuestion,
  ]);
  useEffect(() => {
    if (
      loweredText !== "" &&
      funnelFilters !== "" &&
      metricObject !== null &&
      metricObject.metric !== null &&
      detectedDashboard !== "" &&
      extractedFiltersFlag === false
    ) {
      let filters;
      try {
        filters = extractFilters(loweredText, metricObject, {
          closedFunnelFilters: funnelFilters,
          lkpStages: lkpStages,
          CONV_AI_CONSTANTS: CONV_AI_CONSTANTS,
        });
      } catch (err) {
        console.error(err);
      }

      console.log(filters, "metric_extraction");
      const extractedFiltersObject = { ...filters };
      if (!filters || filters.type === "error") {
        startWaiting();
        setTimeout(() => {
          addWaitingMessage(
            ``,
            detectedDashboard,
            [],
            null,
            true,
            "recommendation"
          );
          logConvAI({
            question: loweredText,
            answer: dashboardRecommnedationString(detectedDashboard),
            answerType: "recommendation",
            timestamp: new Date().toISOString(),
          });
          resetFunction();
        }, 1.5 * 1000);
        console.log(detectedDashboard, "metric_extraction");
        setMetricObject(null);
        setExtractedFiltersFlag(false);
      } else {
        let filtersBody, isError;
        try {
          filtersBody = getFilters(filters.expectedOutput, {
            closedFunnelFilters: funnelFilters,
          });
        } catch (err) {
          isError = true;
        }
        if (isError) {
          startWaiting();
          setTimeout(() => {
            addWaitingMessage(
              ``,
              detectedDashboard,
              [],
              null,
              true,
              "recommendation"
            );
            logConvAI({
              question: loweredText,
              answer: dashboardRecommnedationString(detectedDashboard),
              answerType: "recommendation",
              timestamp: new Date().toISOString(),
            });
            resetFunction();
          }, 1.5 * 1000);
          console.log(detectedDashboard, "metric_extraction");
          setMetricObject(null);
          setExtractedFiltersFlag(false);
        } else {
          console.log(filtersBody.filters, "metric_extraction");
          setExtractedFilters(extractedFiltersObject);

          console.log(detectedDashboard, "metric_extraction");
          dashboardToFiltersFunctionMap[detectedDashboard[0]](
            filtersBody.filters,
            true
          );
          setExtractedFiltersFlag(true);
        }
      }
    }
  }, [
    funnelFilters,
    loweredText,
    addWaitingMessage,
    startWaiting,
    metricObject,
    detectedDashboard,
    addMessage,
    dashboardToFiltersFunctionMap,
    lkpStages,
    logConvAI,
    extractedFiltersFlag,
    CONV_AI_CONSTANTS,
  ]);

  useEffect(() => {
    console.log(funnelData, "metric_extraction");
    let message = "",
      isAnswered = false,
      _dashboards = Array.isArray(detectedDashboard)
        ? detectedDashboard
        : [detectedDashboard];
    if (
      funnelData !== "" &&
      funnelFilters !== "" &&
      extractFiltersObject !== undefined &&
      detectedDashboard !== "" &&
      extractedFiltersFlag === true
    ) {
      try {
        message = requestedFunnel(extractFiltersObject, funnelData, {
          closedFunnelFilters: funnelFilters,
        });
        _dashboards = [_dashboards[0]];
        isAnswered = true;
      } catch (err) {
        console.error(err);
        message = null;
      }
    }
    // if (funnelData !== "" && detectedCharts.length > 0) {
    //   setChartLinks(detectedDashboard);
    //   message += `\nHere are some dashboards that you might find interesting:\n${detectedCharts.join(
    //     " "
    //   )}`;
    // }
    if (message !== "") {
      console.log(message, "metric_extraction");
      if (message === null) {
        startWaiting();
        setTimeout(() => {
          addWaitingMessage(
            ``,
            detectedDashboard,
            [],
            null,
            true,
            "recommendation"
          );
          logConvAI({
            question: loweredText,
            answer: dashboardRecommnedationString(detectedDashboard),
            answerType: "recommendation",
            timestamp: new Date().toISOString(),
          });
          resetFunction();
        }, 1.5 * 1000);
      } else {
        addMessage(
          message,
          _dashboards,
          null,
          null,
          isAnswered,
          "natively answered"
        );
        logConvAI({
          question: loweredText,
          answer: message + dashboardRecommnedationString(detectedDashboard),
          answerType: "natively answered",
          timestamp: new Date().toISOString(),
        });
        resetFunction();
      }

      clearData();
    }
  }, [
    funnelData,
    funnelFilters,
    detectedDashboard,
    extractFiltersObject,
    addMessage,
    clearData,
    logConvAI,
    extractedFiltersFlag,
  ]);

  const keyPress = async (value) => {
    console.log(value, "metric_extraction");
    processQuestion(value);
  };
  const handleLocationChange = (loc, navItem) => {
    console.log("location change", "metric_extraction");
    props.handleLocationChange(loc, navItem);
  };

  const AiCard = () => {
    return (
      <ErrorBoundary>
        <Card
          raised
          className={classes.cardRoot}
          style={{ minWidth: 400, minHeight: 400 }}
        >
          <div className="box" style={{ minWidth: 400, minHeight: 400 }}>
            <Grid className="title cursor">
              <div id="marketingName" style={{ paddingBottom: 10 }}>
                <Grid
                  container
                  justifyContent="flex-start"
                  style={{ flexWrap: "nowrap" }}
                >
                  <Grid item container xs={11}>
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography
                        display="inline"
                        variant="h2"
                        style={{
                          fontSize: "2.75rem",
                          fontWeight: "600",
                          textDecoration: "underline",
                          textDecorationColor: "#4673C5",
                          textDecorationThickness: "5px",
                        }}
                      >
                        {"Geni "}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="h2"
                        style={{
                          fontSize: "1.5rem",
                          color: "#666666",
                          fontWeight: "400",
                          textDecoration: "underline",
                          textDecorationColor: "#d1f2bb",
                          textDecorationThickness: "5px",
                          // textDeco,
                        }}
                      >
                        {" AI Assistant"}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="h3"
                        style={{
                          fontSize: "1rem",
                          background: "#fee599ff",
                          padding: "0 0.4rem",
                          // paddingLeft: "0.7rem",
                          margin: "0 0.5rem",
                          border: "1px solid lightgrey",
                          color: "black",
                          // textDeco,
                        }}
                      >
                        {" Beta"}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid container justifyContent="flex-end" item xs={2}>
                    <IconButton
                      style={{
                        minWidth: "35px",
                        width: "35px",
                        height: "32px",
                      }}
                      variant="contained"
                      onClick={props.toggleConversationalAIWindow}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <div
              id="chatborder"
              style={{
                position: "relative",
                display: "flex",
                flexDirection:
                  props.customerMetrics !== "" ? "column-reverse" : "column",
                // background: " rgb(255,255,255)",
                background: "rgba(220,248,198,0.20)",
                // opacity: 0.65,
              }}
            >
              {props.customerMetrics !== "" ? (
                <div
                  id="paragraphs"
                  // className={classes.chatWrapper}
                >
                  <Grid container className={classes.chatContainer}>
                    <MessageTemplate
                      userStages={props.userStages}
                      currentFiscalQuarter={props.currentFiscalQuarter}
                    />
                    {props.conversationalAIMessages.map((it) => (
                      <MessageContainer
                        handleLocationChange={handleLocationChange}
                        text={it.text}
                        isAnswered={it.isAnswered}
                        botMessage={it.botMessage}
                        dashboards={it.dashboards}
                        dashboardChartMapping={props.dashboardChartMapping}
                        dashbaordIdNavItemMap={props.dashbaordIdNavItemMap}
                      />
                    ))}
                    {props.waitingForAiResponse && <ChatLoading />}
                    <ChatInput
                      keyPress={keyPress}
                      convAIConstants={CONV_AI_CONSTANTS}
                    />
                  </Grid>

                  {/* <Grid className={classes.typingContainer} container item>
                    {props.waitingForAiResponse && <ChatLoading />}
                    <ChatInput keyPress={keyPress} />
                  </Grid> */}
                </div>
              ) : (
                <Skeleton height={300} />
              )}
            </div>
          </div>
        </Card>
      </ErrorBoundary>
    );
  };
  return (
    <React.Fragment>
      <Hidden mdUp>
        <Dialog fullScreen open={true} TransitionComponent={Transition}>
          <div>
            <AiCard />
          </div>
        </Dialog>
      </Hidden>
      <Hidden smDown>
        <Rnd
          style={RndStyle}
          // minWidth={400}
          // minHeight={400}
          // lockAspectRatio={true}
          default={{
            x: 250,
            y: window.scrollY + 75,
            width: 550,
            height: 550,
          }}
          bounds="body"
          // minWidth={500}
          // minHeight={250}
          // maxWidth={900}
          // maxHeight={600}

          dragHandleClassName="title"
        >
          <AiCard />
        </Rnd>
      </Hidden>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    geniBotFilters,
    user,
    lkpStages,
    geniBotData,
    dashboardKeywords,
    chartsKeywordsMap,
    dashboardKeywordsMap,
    enhancedInsightsPage,
    dashboardChartMapping,
    customerMetrics,
    waitingForAiResponse,
    conversationalAIMessages,
    dashbaordIdNavItemMap,
    CONV_AI_CONSTANTS,
    textFieldQuestion,
  } = state.app;
  return {
    funnelFilters: geniBotFilters,
    user,
    location: enhancedInsightsPage,
    lkpStages,
    dashboardKeywords,
    chartsKeywordsMap,
    dashboardKeywordsMap,
    dashboardChartMapping,
    funnelData: geniBotData,
    customerMetrics,
    CONV_AI_CONSTANTS,
    waitingForAiResponse,
    conversationalAIMessages,
    dashbaordIdNavItemMap,
    userStages: user.stages,
    currentFiscalQuarter: user.currentFiscalQuarter,
    textFieldQuestion,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getLKPStages: () => {
    dispatch({ type: "get_lkp_stages_request" });
    appService.getLKPStages().then(
      (json) => {
        dispatch({ type: "get_lkp_stages_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_lkp_stages_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_lkp_stages_failure", error });
      }
    );
  },
  getDashboardKeywords: () => {
    dispatch({ type: "get_dashboard_keywords_request" });
    appService.getDashboardKeywords().then(
      (json) => {
        dispatch({ type: "get_dashboard_keywords_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_dashboard_keywords_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_dashboard_keywords_failure", error });
      }
    );
  },
  ...mapDispatchToPropsClosedFunnel(dispatch),

  getFunnelFilters: (company, geniBot = true) => {
    dispatch({ type: "get_funnel_filters_request", geniBot });
    appService.getFunnelFiltersNew().then(
      (json) => {
        dispatch({ type: "get_funnel_filters_success", json, geniBot });
        const defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message.closedFiscalQuarters,
          "closed_fiscal_quarter"
        );
        let filters = [];
        if (defaultFiscalQuarter.length > 0) {
          filters = [{ name: "cfq", value: defaultFiscalQuarter }];
        }
        if (company === "C0008") {
          filters =
            getDefaultSalesType(json.message.salesType, "cross_sell1__c")[0] ===
            "All"
              ? [...filters]
              : [
                  ...filters,
                  {
                    name: "cross_sell1__c",
                    value: getDefaultSalesType(
                      json.message.salesType,
                      "cross_sell1__c"
                    ),
                  },
                ];
          filters =
            getDefaultSalesType(json.message.dealType, "Type")[0] === "All"
              ? [...filters]
              : [
                  ...filters,
                  {
                    name: "Type",
                    value: getDefaultSalesType(json.message.dealType, "Type"),
                  },
                ];
          filters =
            getDefaultSalesType(
              json.message.priorYearDealInclude,
              "Prior_Year_Opp_Manually_Inserted"
            )[0] === "All"
              ? [...filters]
              : [
                  ...filters,
                  {
                    name: "Prior_Year_Opp_Manually_Inserted",
                    value: getDefaultSalesType(
                      json.message.priorYearDealInclude,
                      "Prior_Year_Opp_Manually_Inserted"
                    ),
                  },
                ];
          //  filters = salesFilters === undefined ?   [...filters,salesFilters, dealtype]
        }
        dispatch({ type: "current_selected_filters", filters });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_filters_failure", error });
      }
    );
  },
  toggleConversationalAIWindow: () => {
    dispatch({ type: "toggle_conversational_ai_window" });
  },
  askQuestion: (question) => {
    // dispatch({ type: "ask_question" })
    console.log(question);
    dispatch({
      type: "add_chat_message",
      botMessage: false,
      text: question,
    });
    appService.askQuestion(question).then(
      (resp) => {
        dispatch({
          type: "add_chat_message",
          botMessage: true,
          text: resp.text,
        });
      },
      (err) => {
        console.log("AI error occurred", err);
      }
    );
  },
  addMessage: (
    message,
    dashboards,
    charts,
    userQuestion = false,
    isAnswered,
    answerType
  ) => {
    console.log(message, userQuestion);
    dispatch({
      type: "add_chat_message",
      text: message,
      dashboards,
      isAnswered,
      botMessage: !userQuestion,
    });
    const logObject = {
      [!userQuestion ? "answer" : "question"]: message,
      timestamp: new Date().toISOString(),
      answerType,
    };
    // appService.logConvAI(logObject);
  },
  addWaitingMessage: (
    message,
    dashboards,
    charts,
    userQuestion = false,
    isAnswered,
    answerType
  ) => {
    dispatch({
      type: "add_waiting_chat_message",
      text: message,
      dashboards,
      isAnswered,
      botMessage: !userQuestion,
    });
    const logObject = {
      [!userQuestion ? "answer" : "question"]: message,
      timestamp: new Date().toISOString(),
      answerType,
    };
    // appService.logConvAI(logObject);
  },
  stopWaiting: () => {
    dispatch({ type: "update_toggle_waiting", shouldWait: false });
  },
  startWaiting: () => {
    dispatch({ type: "update_toggle_waiting", shouldWait: true });
  },
  resetFilters: () => {
    dispatch({ type: "reset_filters" });
  },
  handleLocationChange: (loc, navItem) => {
    console.log(navItem, "metric_extraction");
    if (navItem === "Enhanced Insights") {
      dispatch({ type: "change_enhancedInsightsPage", loc });
      localStorage.setItem("skygeni-user-enhancedPage", loc);
      localStorage.removeItem("skygeni-user-repPage");
    } else if (navItem === "Rep Performance") {
      dispatch({ type: "change_repPerformancePage", loc });
      localStorage.removeItem("skygeni-user-enhancedPage");
      localStorage.setItem("skygeni-user-repPage", loc);
    }
    ReactGA.pageview(loc);

    localStorage.setItem("skygeni-user-page", navItem);
    localStorage.removeItem("skygeni-user-productPage");
  },
  clearData: () => {
    dispatch({ type: "clear_conv_ai_data" });
  },
  removeTextFieldQuestion: () => {
    dispatch({ type: "clear_text_field_question" });
  },
  logConvAI: (logObject) => {
    appService.logConvAI(logObject);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AIWindow);
