import { Typography,capitalize } from '@material-ui/core'
import React from 'react'
import { countOrACVFormatter } from '../../util/customFunctions'

const CleoMetaData = (props) => {
    console.log(props)
  return (
    
      <> <span >
        <Typography variant="body1" style={{ fontWeight: '600' }}> Expected Yield</Typography>
        
      </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {Object.keys(props.metaArr).map((item,i)=> <Typography variant="body1" style={{ fontWeight: '600' }}>{capitalize(item)}: <span data-test={item}>{countOrACVFormatter(props.metaArr[`${item}`],true)}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>)}
      </>
    
  )
}

export default CleoMetaData
