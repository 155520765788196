import { Table, TableContainer, TableRow, TableCell } from "@material-ui/core";
import React from "react";
import tableStyle from "../../../CustomerSuccess/styles/tableStyle";
import {
  customDateFormatter,
  dateFormatterMomentV4,
  dateFormatter,
  getUniqueValues,
} from "../../../util/customFunctions";
import CopyTablesObject from "../../../components/xlsx-export/CopyTablesObject";

const ExpectedAcvDescriptionTable = (props) => {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const sumamryData = props.data
    .sort((a, b) => a.sequence - b.sequence)
    .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

  const sumamryHeader = props.data
    .sort((a, b) => a.sequence - b.sequence)
    .map((c) => c.key)
    .filter(getUniqueValues);

  return (
    <TableContainer style={{ padding: "0.5rem" }}>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
          // marginRight: "10px",
        }}
      >
        <CopyTablesObject tableRef={tableRef} />
      </div> */}
      <Table ref={tableRef} data-test="ChangeEvents-Summary">
        <TableRow>
          <TableRow colspan={8}></TableRow>
        </TableRow>
        <TableRow>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {sumamryHeader[0]}:
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={3}
            align="left"
            style={{ width: "37.5%" }}
          >
            {sumamryData[sumamryHeader[0]]}{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {sumamryHeader[1]}:{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {sumamryData[sumamryHeader[1]]}{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {sumamryHeader[2]}:{" "}
          </TableCell>
          <TableCell className={classes.dataCell} colspan={1} align="left">
            {sumamryData[sumamryHeader[2]]}{" "}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {sumamryHeader[3]}:{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={3}
            align="left"
            style={{ width: "37.5%" }}
          >
            {sumamryData[sumamryHeader[3]]}{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {sumamryHeader[4]}:{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {sumamryData[sumamryHeader[4]]}{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {[sumamryHeader[5]]}:{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {sumamryData[sumamryHeader[5]]}{" "}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {sumamryHeader[6]}:{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={3}
            align="left"
            style={{ width: "37.5%" }}
          >
            {[undefined, null, ""].includes(sumamryData[sumamryHeader[6]])
              ? "-"
              : dateFormatterMomentV4(sumamryData[sumamryHeader[6]])}{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {sumamryHeader[7]}:{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {[undefined, null, ""].includes(sumamryData[sumamryHeader[7]])
              ? "-"
              : dateFormatterMomentV4(sumamryData[sumamryHeader[7]])}{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {sumamryHeader[8]}:{" "}
          </TableCell>
          <TableCell
            className={classes.dataCell}
            colspan={1}
            align="left"
            style={{ width: "12.5%" }}
          >
            {[undefined, null, ""].includes(sumamryData[sumamryHeader[8]])
              ? "-"
              : dateFormatterMomentV4(sumamryData[sumamryHeader[8]])}{" "}
          </TableCell>
        </TableRow>
      </Table>
    </TableContainer>
  );
};

export default ExpectedAcvDescriptionTable;
