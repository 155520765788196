import { Button, Grid, Typography, Card } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import * as d3 from "d3";
import TooltipAGGrid from "./TooltipAgGrid";
import { useSnackbar } from "notistack";
import NotUpdatedLengend from "./NotUpdatedLengend";
import { isEqual } from "lodash"; // Ensure lodash is installed: npm install lodash
import "./MaintainenenceTable.scss";

const MaintainenenceTable = (props) => {
  const [rowData, setRowData] = useState(null);
  const [initialData, setInitialData] = useState(null);
  const [updatedObjects, setUpdatedObjects] = useState(null);
  const [updatedObjectMap, setUpdatedObjectMap] = useState({});
  const [someUpdateFailed, setSomeUpdateFailed] = useState(false);
  const [disableUpdateButton, setDisableUpdateButton] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [containerHeight, setContainerHeight] = useState(500);

  const previousDataRef = useRef(); // Track previous props.data

  useEffect(() => {
    props.setTouched(false);
  }, []);

  useEffect(() => {
    // Only update if data has truly changed (deep equality check)
    if (!isEqual(props.data, previousDataRef.current)) {
      let data = props.data.map((it, idx) => ({ ...it, id: idx }));
      setRowData(data);
      setInitialData(props.data);
      previousDataRef.current = props.data; // Update the ref to the latest data

      const FIXED_REPS = 20;
      let _containerHeight =
        60 + props.data.length * 25 <= window.innerHeight - 255
          ? 60 + props.data.length * 25
          : 60 + FIXED_REPS * 25;

      if (props.data.length === 0) {
        _containerHeight = 150;
      }
      setContainerHeight(_containerHeight);

      if (props.data !== null) {
        setIsDataLoaded(true);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (isDataLoaded) {
      enqueueSnackbar("Data loaded", { variant: "success" });
    } else {
      enqueueSnackbar("Data loading", { variant: "info" });
    }
  }, [isDataLoaded]);

  useEffect(() => {
    if (Array.isArray(props.udpatedRecords)) {
      const isUpdatedArr = props.udpatedRecords.map((it) => it.isUpdated);
      setSomeUpdateFailed(Boolean(isUpdatedArr.indexOf(false) >= 0));
    }
    setUpdatedObjects(props.udpatedRecords);
    if (props.udpatedRecords !== null) {
      enqueueSnackbar("Data Updated", { variant: "success" });
    }
  }, [props.udpatedRecords]);

  const handleSave = () => {
    let updateData = [];
    for (let updated in updatedObjectMap) {
      const value = updatedObjectMap[updated];
      updateData = [...updateData, value];
    }
    console.log(updateData, updatedObjectMap, "updateObject_debug");
    props.handleSubmit(updateData, props.tab);
    enqueueSnackbar("Data updating", { variant: "info" });
    props.setTouched(false);
  };

  const deltaIndicator = (params) => {
    const element = document.createElement("span");
    const imageElement = document.createElement("span");
    let uniqueData;
    console.log(params);
  };

  const onCellValueChanged = (event) => {
    props.setTouched(true);
    const data = event.data;
    const field = event.colDef.field;
    let oldObject = initialData.filter(
      (it) =>
        it.UserId === event.data.UserId &&
        it.FiscalQuarter === event.data.FiscalQuarter
    )[0];
    if (props.tab === "team") {
      oldObject = initialData.filter(
        (it) =>
          it.Team === event.data.Team &&
          it.FiscalQuarter === event.data.FiscalQuarter
      )[0];
    } else if (props.tab === "company") {
      oldObject = initialData.filter(
        (it) => it.FiscalQuarter === event.data.FiscalQuarter
      )[0];
    }
    let newValue = event.newValue;
    newValue =
      typeof oldObject[event.column.colId] === "number"
        ? parseInt(newValue)
        : newValue;
    const rowIndex = event.rowIndex;
    const newItem = { ...data, rowIndex };
    const newUpdatedData = newItem;
    newItem[field] = event.newValue;

    const previouslyUpdatedFields = updatedObjectMap[event.data.id]
      ? updatedObjectMap[event.data.id].updatedFields
      : [];

    console.log(
      oldObject,
      oldObject[event.column.colId] === parseInt(newValue),
      newValue,
      "oldObject"
    );
    let _updatedFields = [...new Set([...previouslyUpdatedFields])];
    console.log("updateObject_debug", _updatedFields);
    _updatedFields.push(event.colDef.field);
    console.log("updateObject_debug", _updatedFields);
    let updateObject = {
      ...event.data,
      [`new${event.colDef.field}`]:
        typeof event.oldValue === "number" ? parseInt(newValue) : newValue,
      [`old${event.colDef.field}`]: event.oldValue,
      updatedFields: _updatedFields,
      isRamp_Status_Updated: event.colDef.field === "Ramp_Status",
      [`${event.column.colId}_Updated`]:
        oldObject[event.column.colId] === newValue ? false : true,
    };
    console.log(
      updateObject,
      "updateObject_debug",
      event.colDef.field,
      event.data.id,
      event.column.colId
    );

    if (event.oldValue !== event.newValue) {
      event.data[`${event.column.colId}_Updated`] =
        oldObject[event.column.colId] === newValue ? false : true;
    }
    if (updateObject[`${event.column.colId}_Updated`] === false) {
      // remove event.colDef.field from  the updatedFields array
      updateObject.updatedFields = updateObject.updatedFields.filter(
        (updatedField) => updatedField !== event.colDef.field
      );
    }
    event.api.refreshCells({
      force: true,
      columns: [event.column.getId()],
      rowNodes: [event.node],
    });

    setUpdatedObjectMap({
      ...updatedObjectMap,
      [event.data.id]: { ...updatedObjectMap[event.data.id], ...updateObject },
    });
    console.log(updateObject, "updateObject_debug");

    if (updateObject.updatedFields.length > 0) {
      setDisableUpdateButton(false);
    } else if (updateObject.updatedFields.length === 0) {
      setDisableUpdateButton(true);
    }
    // setDisableUpdateButton(false);
  };

  const rowClassRules = useMemo(() => {
    return {
      "not-updated": (params) => {
        const isUpdated = params.data.isUpdated;
        return isUpdated !== undefined && isUpdated === false;
      },
      "edit-bg": (params) => {
        return params.data.isEdited;
      },
    };
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      minWidth: 100,
      filter: true,
      tooltipComponent: TooltipAGGrid,
    }),
    []
  );

  let columnApi;
  const gridReady = (params) => {
    columnApi = params.columnApi;
  };

  const onFirstDataRendered = (params) => {
    params.api.resetRowHeights();
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
    params.api.refreshCells();
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const getRowId = useCallback((params) => params.data.id);

  return (
    <Grid container>
      <Grid
        xs={12}
        container
        item
        component={Card}
        style={{ marginBottom: 10, height: "100%" }}
      >
        <Grid item xs={12}>
          <Typography
            align="center"
            style={{
              margin: "1rem",
              fontSize: "1.2rem",
              fontFamily: "Roboto/Helvetica/Arial/sans-serif",
              fontWeight: 500,
            }}
          >
            {props.title}
          </Typography>
        </Grid>

        <Grid container item xs={12} justifyContent="flex-start">
          <Grid item xs={12} container>
            <div
              className="quota_maintance"
              style={{
                height: containerHeight,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AgGridReact
                components={{ deltaIndicator }}
                rowData={rowData}
                columnDefs={props.columnDefs}
                defaultColDef={defaultColDef}
                getRowId={getRowId}
                onCellValueChanged={onCellValueChanged}
                enableCellTextSelection={true}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                headerHeight={55}
                colResizeDefault={"shift"}
                onGridReady={gridReady}
                onColumnResized={onColumnResized}
                onFirstDataRendered={onFirstDataRendered}
                enableBrowserTooltips={true}
                rowClassRules={rowClassRules}
                domLayout={props.domLayout}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent="flex-start"
        component={Card}
        elevation={4}
        style={{
          position: "sticky",
          bottom: 0,
          zIndex: 999,
          padding: "10px 0px 10px 20px",
        }}
      >
        <Grid item container>
          <Grid item xs={1} container style={{ padding: "0.5rem" }}>
            <Button
              disabled={disableUpdateButton}
              onClick={() => handleSave()}
              data-test="go"
              variant="contained"
              color="primary"
              align="center"
            >
              Update
            </Button>
          </Grid>
          <Grid item xs={11}>
            {someUpdateFailed && (
              <Grid item container xs={12} justifyContent="center">
                <NotUpdatedLengend
                  text={
                    "A cell in this row was recently updated by another user. Please review the current values and submit your update again."
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MaintainenenceTable;
