import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { countOrACVFormatter, countOrACVFormatterMillionFixedOne } from "../../util/customFunctions";





const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    // fontSize: '14px',
    whiteSpace: "normal",
  },
  titleContainer: {
    width: "100%",
  },
  datacell: {
    fontSize: "1rem",
    fontWeight: 600,
    padding: "5px",
    paddingRight: "0px",
    border: "none",
  },
  headerCell: {
    border: "0.5px solid lightgrey",
    fontWeight: 600,
  //   padding: '6px 13px 6px 16px'
  width: '14rem'
  },
  tableSpacer: {
  //   margin: "1rem",
    marginTop: "0rem",
  },
}));
export default function GrowthModelDescriptionTable(props) {
  const classes = useStyle();

  const uniqueRows = props.rows1
  const uniqeRows2 = props.rows2
  const uniqeRows3 = props.rows3
  return (
      <TableContainer
      className={classes.mainTable}
      style={{ width: "100%" }}
    >
      <Table
        size="small"
        aria-label="html table"
        style={{ tableLayout: "auto", width: "100%" }}
        data-test="growth-description-table"
      >
        <TableBody>
          <TableRow>
          <TableCell
              align="left"
              colSpan={1}
              rowSpan={2}
              className={classes.headerCell}
              style={{width: '40rem', border: 'none', background: 'none'}}
            >
                <span
              style={{ background: "#E2EFDA", padding: "0.2rem" }}
            >{`${countOrACVFormatterMillionFixedOne(
              props.data1.needed,
              true
            )}`}</span>{" "} of qualified pipeline entering the quarter
            </TableCell>
              {uniqueRows.map((f, i) => {
                  return <TableCell
                  align="center"
                  className={classes.headerCell}
                  colSpan={1}
                  style={

                    i % 2 === 0
                      ? {
                          background: "#4471c4",
                          color: "white",
                        }
                      : {
                          background: "#5b9bd5",
                          color: "white",
                        }
                  }
                >
                  {f}
                </TableCell>
              })
          }
          </TableRow>
          <TableRow>
          <TableCell
              align="right"
              colSpan={1}
              className={classes.headerCell}
              style={{background: "#E2EFDA"}}
            >
              {countOrACVFormatter(props.data1.needed, true)}
            </TableCell>
            <TableCell
              align="right"
              colSpan={1}
              className={classes.headerCell}
            >
              {countOrACVFormatter(props.data1.actual, true)}
            </TableCell>
            <TableCell
              align="right"
              colSpan={1}
              className={classes.headerCell}
            >
              {countOrACVFormatter(props.data1.delta, true)}
            </TableCell>
          </TableRow>
          <TableRow>
          <TableCell
              align="left"
              colSpan={1}
              className={classes.headerCell}
              style={{width: '45rem', border: 'none', background: 'none'}}
            >
              plus
            </TableCell>
              {uniqueRows.map((f, i) => {
                  return <TableCell
                  align="center"
                  // className={classes.headerCell}
                  colSpan={1}
                  style={{
                   background: "none",
                   border: "none"
                  }}
                >
                </TableCell>
              })
          }
          </TableRow>

          <TableRow>
          <TableCell
              align="left"
              colSpan={1}
              rowSpan={2}
              className={classes.headerCell}
              style={{width: '18rem', border: 'none', background: 'none'}}
            >The capacity to create
                <span
              style={{ background: "#E2EFDA", padding: "0.2rem" }}
            >{`${countOrACVFormatterMillionFixedOne(
              props.data2.needed,
              true
            )}`}</span>{" "} of qualified pipeline during the quarter
            </TableCell>
              {uniqeRows2.map((f, i) => {
                  return <TableCell
                  align="center"
                  className={classes.headerCell}
                  colSpan={1}
                  style={
                    i % 2 === 0
                      ? (f === 'Avg. of Qualified Pipe Created per qtr. (past 4 qtrs.)' ? {
                          background: "#4471c4",
                          color: "white",
                          paddingLeft: '5px',
                          paddingRight: '5px'
                        } : {
                          background: "#4471c4",
                          color: "white",
                        })
                      : (f === 'Avg. of Qualified Pipe Created per qtr. (past 4 qtrs.)' ? {
                        background: "#5b9bd5",
                        color: "white",
                        paddingLeft: '5px',
                          paddingRight: '5px'
                      } : {
                          background: "#5b9bd5",
                          color: "white",
                        })
                  }
                >
                  {f}
                </TableCell>
              })
          }
          </TableRow>
          <TableRow>
          
          <TableCell
              align="right"
              colSpan={1}
              className={classes.headerCell}
              style={{background: "#E2EFDA"}}
            >
              {countOrACVFormatter(props.data2.needed, true)}
            </TableCell>
            <TableCell
              align="right"
              colSpan={1}
              className={classes.headerCell}
            >
              {countOrACVFormatter(props.data2.actual, true)}
            </TableCell>
            <TableCell
              align="right"
              colSpan={1}
              className={classes.headerCell}
            >
              {countOrACVFormatter(props.data2.delta, true)}
            </TableCell>
          </TableRow>
          <TableRow>
          <TableCell
              align="left"
              colSpan={1}
              className={classes.headerCell}
              style={{width: '18rem', border: 'none', background: 'none'}}
            >
              plus
            </TableCell>
              {uniqeRows2.map((f, i) => {
                  return <TableCell
                  align="center"
                  className={classes.headerCell}
                  colSpan={1}
                  style={{
                   background: "none",
                   border: "none"
                  }}
                >
                </TableCell>
              })
          }
          </TableRow>
          <TableRow>
          <TableCell
              align="left"
              colSpan={1}
              rowSpan={2}
              className={classes.headerCell}
              style={{width: '18rem', border: 'none', background: 'none'}}
            >
                <span
              style={{ background: "#E2EFDA", padding: "0.2rem" }}
            >{`${countOrACVFormatterMillionFixedOne(
              props.data3.needed,
              true
            )}`}</span>{" "} of qualified pipeline entering the quarter with projected close in qtrs. less than {props.qtr}
            </TableCell>
              {uniqeRows3.map((f, i) => {
                  return <TableCell
                  align="center"
                  className={classes.headerCell}
                  colSpan={1}
                  style={
                    i % 2 === 0
                      ? {
                          background: "#4471c4",
                          color: "white",
                        }
                      : {
                          background: "#5b9bd5",
                          color: "white",
                        }
                  }
                >
                  {f}
                </TableCell>
              })
          }
          </TableRow>
          <TableRow>
          
          <TableCell
              align="right"
              colSpan={1}
              className={classes.headerCell}
              style={{background: "#E2EFDA"}}
            >
              {countOrACVFormatter(props.data3.needed, true)}
            </TableCell>
            <TableCell
              align="right"
              colSpan={1}
              className={classes.headerCell}
            >
              {countOrACVFormatter(props.data3.actual, true)}
            </TableCell>
            <TableCell
              align="right"
              colSpan={1}
              className={classes.headerCell}
            >
              {countOrACVFormatter(props.data3.delta, true)}
            </TableCell>
          </TableRow>
          
        </TableBody>
      </Table>
    </TableContainer>
  );
}