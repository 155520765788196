import {
  Card,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { StyledTableRow } from "../util/customFunctions";
import { formatDataValuesNew, camelize } from "../util/dataFormatter";
import CopyTablesObject from "./xlsx-export/CopyTablesObject";
// import ExcelExportButton from "./xlsx-export/xlsxexport";
const useStyle = makeStyles((theme) => ({
  colHeader: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 7px",
    color: "inherit",
    fontWeight: "inherit",
  },
  funnelTableWidth: {
    width: "10%",
  },
  drilldownCell: {
    cursor: "pointer",
  },
  fontBold: {
    fontWeight: 600,
  },
}));
export default function FunnelTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();
  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleDrilldown = (location, stage, key) => {
    console.log(stage, key);
    if (key !== null) {
      props.handleChangeLocation(location, stage, key);
    }
  };
  const cameToStageDrilldown = (location, stage, key) => {
    console.log(stage, key);
    if (key !== null) {
      props.handleChangeLocation(location, stage, key, true);
    }
  };

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);
  return (
    <Grid item xs={12}>
      {isOverflowing ? (
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "10px",
            // marginRight: "2rem",
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
      ) : null}
      <Grid item xs={12} component={Card} justify="center" container>
        <TableContainer style={{ padding: "2rem" }} id="myTableContainer">
          {isOverflowing === false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null}
          <Table ref={tableRef} size="small" data-test={props.data_test}>
            <TableHead></TableHead>
            <TableBody>
              <TableRow className={classes.fontBold}>
                <TableCell
                  className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                  style={{ minWidth: 80 }}
                >
                  Stage
                </TableCell>
                <TableCell
                  className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                  align="center"
                >
                  Came to Stage
                </TableCell>
                <TableCell
                  className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                  style={{ background: "#c65911", color: "white" }}
                  align="center"
                >
                  Lost / Disqualified from Stage
                </TableCell>
                <TableCell
                  className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                  style={{ background: "#4472c4", color: "white" }}
                  align="center"
                >
                  Active at Stage
                </TableCell>
                <TableCell
                  className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                  style={{ background: "#70ad47", color: "white" }}
                  align="center"
                >
                  Moved to next stage
                </TableCell>
                <TableCell
                  className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                  align="center"
                >
                  {/* Win Rate % */}
                  {`${props.Win_Rate_Prefix} %`}
                </TableCell>
                {props.showTeamData && (
                  <TableCell
                    className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                    align="center"
                  >
                    {/* Company Avg. Win Rate % */}
                    {`Company Avg. ${props.Win_Rate_Prefix} %`}
                  </TableCell>
                )}
              </TableRow>
              {props.data.map((item, i) => (
                <StyledTableRow key={i}>
                  <TableCell className={classes.colHeader} align="left">
                    {item.stage}
                  </TableCell>
                  <TableCell
                    className={`${classes.colHeader} ${classes.drilldownCell}`}
                    onClick={() =>
                      cameToStageDrilldown(
                        "drilldownOnGraph",
                        item.stage === props.firstStage
                          ? props.wonLabel
                          : item.stage,
                        item.stage === props.wonLabel
                          ? "closedWon"
                          : item.stage === props.firstStage
                          ? "movedForwardFromAll"
                          : `movedForward${props.data[i - 1].stage}`
                      )
                    }
                    style={
                      item.stage === props.wonLabel
                        ? {
                            background: "#548236",
                            color: "white",
                            cursor: "pointer",
                          }
                        : {}
                    }
                    align="right"
                  >
                    {formatDataValuesNew(
                      item[
                        props.isAcv
                          ? "cameToStageAndmovedForward_acv"
                          : "cameToStageAndmovedForward"
                      ],
                      props.type,
                      props.company
                    )}
                  </TableCell>
                  {item.stage !== props.wonLabel ? (
                    <>
                      <TableCell
                        className={classes.colHeader}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDrilldown(
                            "drilldownOnGraph",
                            item.stage,
                            "orange"
                          )
                        }
                        align="right"
                      >
                        {formatDataValuesNew(
                          item[
                            props.isAcv
                              ? "lostDisqualifiedFromStage_acv"
                              : "lostDisqualifiedFromStage"
                          ],
                          props.type,
                          props.company
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.colHeader}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDrilldown(
                            "drilldownOnGraph",
                            item.stage,
                            "blue"
                          )
                        }
                        align="right"
                      >
                        {formatDataValuesNew(
                          item[
                            props.isAcv ? "activeAtStage_acv" : "activeAtStage"
                          ],
                          props.type,
                          props.company
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.colHeader}
                        style={
                          item.stage !== props.wonLabel
                            ? { cursor: "pointer" }
                            : {}
                        }
                        onClick={() =>
                          handleDrilldown(
                            "drilldownOnGraph",
                            item.stage,
                            item.stage !== props.wonLabel
                              ? `movedForward${item.stage}`
                              : null
                          )
                        }
                        align="right"
                      >
                        {formatDataValuesNew(
                          item[
                            props.isAcv
                              ? "movedToNextStage_acv"
                              : "movedToNextStage"
                          ],
                          props.type,
                          props.company
                        )}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell
                        className={classes.colHeader}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDrilldown(
                            "drilldownOnGraph",
                            item.stage,
                            "orange"
                          )
                        }
                        align="right"
                      >
                        {"-"}
                      </TableCell>
                      <TableCell
                        className={classes.colHeader}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDrilldown(
                            "drilldownOnGraph",
                            item.stage,
                            "blue"
                          )
                        }
                        align="right"
                      >
                        {"-"}
                      </TableCell>
                      <TableCell
                        className={classes.colHeader}
                        style={
                          item.stage !== props.wonLabel
                            ? { cursor: "pointer" }
                            : {}
                        }
                        onClick={() =>
                          handleDrilldown(
                            "drilldownOnGraph",
                            item.stage,
                            item.stage !== props.wonLabel
                              ? `movedForward${item.stage}`
                              : null
                          )
                        }
                        align="right"
                      >
                        {"-"}
                      </TableCell>
                    </>
                  )}
                  <TableCell
                    className={classes.colHeader}
                    align="right"
                  >{`${Math.round(
                    item[
                      props.isAcv ? "winRateFromStage_acv" : "winRateFromStage"
                    ]
                  )}%`}</TableCell>
                  {props.showTeamData && (
                    <TableCell
                      className={classes.colHeader}
                      align="right"
                    >{`${Math.round(
                      props.companyData.filter(
                        (i) => i.stageCompany === item.stage
                      )[0][
                        `winRateFromStage${
                          props.isAcv ? "Company_acv" : "Company"
                        }`
                      ]
                    )}%`}</TableCell>
                  )}
                </StyledTableRow>
              ))}
              <StyledTableRow className={classes.fontBold} key={"Total"}>
                <TableCell className={classes.colHeader} align="left">
                  {"Total"}
                </TableCell>
                <TableCell className={classes.colHeader} align="right">
                  {"-"}
                </TableCell>
                <TableCell
                  className={`${classes.colHeader} ${classes.drilldownCell}`}
                  onClick={() =>
                    handleDrilldown(
                      "drilldownOnGraph",
                      props.data.map((item) => item.stage),
                      "orange"
                    )
                  }
                  align="right"
                >
                  {formatDataValuesNew(
                    props.data
                      .map(
                        (item, i) =>
                          item[
                            props.isAcv
                              ? "lostDisqualifiedFromStage_acv"
                              : "lostDisqualifiedFromStage"
                          ]
                      )
                      .filter((item) => item !== undefined)
                      .reduce((a, b) => a + b, 0),
                    props.type,
                    props.company
                  )}
                </TableCell>
                <TableCell
                  className={`${classes.colHeader} ${classes.drilldownCell}`}
                  onClick={() =>
                    handleDrilldown(
                      "drilldownOnGraph",
                      props.data.map((item) => item.stage),
                      "blue"
                    )
                  }
                  align="right"
                >
                  {formatDataValuesNew(
                    props.data
                      .map(
                        (item, i) =>
                          item[
                            props.isAcv ? "activeAtStage_acv" : "activeAtStage"
                          ]
                      )
                      .filter((item) => item !== undefined)
                      .reduce((a, b) => a + b, 0),
                    props.type,
                    props.company
                  )}
                </TableCell>
                <TableCell className={classes.colHeader} align="right">
                  {"-"}
                </TableCell>
                <TableCell className={classes.colHeader} align="right">
                  {"-"}
                </TableCell>
                {props.showTeamData && (
                  <TableCell className={classes.colHeader} align="right">
                    {"-"}
                  </TableCell>
                )}
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
