import { metric_extraction } from "./metric-extraction";
import { get_highest_score_and_longest_string, word_tokenize } from "./utils";
import * as fuzzball from "fuzzball";
export const extractFilters = (
  text,
  metricObjects,
  { closedFunnelFilters, lkpStages, CONV_AI_CONSTANTS }
) => {
  if (!text) {
    throw new Error("Question Not found");
  }

  let loweredText = text.toLowerCase();
  let rep_names;
  let rep;
  let rep_name_found = false;
  if (text.includes("my")) {
    rep_names = closedFunnelFilters["reps"].map((it) => it.Full_Name);
    rep_name_found = true;
    loweredText = loweredText.replace("my", "");
  }

  let { text: newText, metric, hasMetric } = metricObjects;
  const metricData = { ...metric };
  console.log(metric, metricObjects, "metric_extraction");
  if (!hasMetric) {
    return {
      type: "error",
      message:
        "Sorry, I could not find a metric in this question. Please specify one of the following metrics: 'ACV', 'Win Rate', 'Bookings'",
    };
  }
  text = newText;
  // Step 2
  text = text.replace("what is", "");
  text = text.replace("'s", "");
  text = text.replace("closed won", "");
  text = text.replace("?", "");
  text = text.replace(".", "");
  text = text.replace(">=", "greater than");
  text = text.replace(",", "");
  text = text.toLowerCase();
  let new_words = word_tokenize(text);
  new_words = new_words.map((n) => {
    if (n === ">" || n == ">=") {
      return "greater than";
    }
    return n;
  });
  text = new_words.join(" ");
  var words;
  words = new_words;
  let acvPreset = "";

  if (text.includes("greater than")) {
    const regex = /\b\d+\b/g;
    acvPreset = text.split("greater than")[1].match(regex);
    if (acvPreset.length > 0) {
      acvPreset = acvPreset[0];
      text = text.replace(`greater than ${acvPreset}`, "");
      acvPreset = acvPreset.replace("$", "").replace(",", "");
    } else {
      return {
        type: "error",
        message:
          "Sorry I could not find the ACV Preset. Please include a $ before the preset value",
      };
    }
  }
  let all_reps_and_teams = false;
  let months = "",
    team = "",
    team_names = "";
  if (
    text.includes("all reps") ||
    text.includes("all teams") ||
    text.includes("our")
  ) {
    let rep = "",
      teams = "";
    all_reps_and_teams = true;
  }
  if (!all_reps_and_teams && !rep_name_found) {
    if (new_words.includes("team") || new_words.includes("team's")) {
      team_names = closedFunnelFilters["teams"].map((team) =>
        team["Display_Name"].toLowerCase()
      );
      let exactMatch = false;
      if (!exactMatch) {
        let idx = text.indexOf("team");
        if (idx > 0) {
          let left_text = text.slice(0, idx);
          const exTeam = fuzzball.extract(
            text.replace(metricObjects.metric, ""),
            team_names,
            {
              scorer: fuzzball.token_set_ratio,
            }
          );
          team = [
            get_highest_score_and_longest_string(exTeam, {
              CONV_AI_CONSTANTS_THRESHOLD:
                CONV_AI_CONSTANTS.NAME_MATCHING_FUZZY_THRESHOLD,
            }),
          ];
          text = text.replace(team[0], "");
        }
      }
      rep = "";
    } else {
      rep_names = closedFunnelFilters["reps"].map((r) =>
        r["Full_Name"].toLowerCase()
      );
      let exactMatch = false;
      if (!exactMatch) {
        const ex_rep = fuzzball.extract(
          text.replace(metricObjects.metric, ""),
          rep_names,
          {
            scorer: fuzzball.token_set_ratio,
          }
        );
        rep = get_highest_score_and_longest_string(ex_rep, {
          CONV_AI_CONSTANTS_THRESHOLD:
            CONV_AI_CONSTANTS.NAME_MATCHING_FUZZY_THRESHOLD,
        });
      }
      if (rep === "") {
        return {
          type: "error",
          message:
            "Sorry I could not find the specified rep. Please use both the first and last name of the rep. If you're using a team's name, please add the keyword 'team'. For company wide data please add the keyword 'all reps' or 'all teams'.",
        };
      } else {
        rep = closedFunnelFilters["reps"][rep_names.indexOf(rep)];
      }
      team = "";
    }
  } else if (rep_name_found) {
    if (!rep_names.includes(rep)) {
      return {
        type: "error",
        message: `Sorry I could not find the rep named ${rep}. Please use both the first and last name of the rep. If you're using a team's name, please add the keyword 'team'. For company wide data please add the keyword 'all reps' or 'all teams'.`,
      };
    } else {
      rep = closedFunnelFilters["reps"][rep_names.indexOf(rep)];
    }
    let team = "";
  }
  const month = { month: [], month_nums: [] };
  for (const m in months) {
    if (new_words.includes(m)) {
      month.month.push(m);
      month.month_nums.push(months[m]);
    }
  }

  let quarter = [];
  let possiblequarter = "";
  let possibleyear = "";
  const regexFY = /\b[Ff][Yy][ -]?\d+\b/g;
  const regexQuarter1 = /[^- ][q]\d\b/g;
  const regexYear = /\b20\d\d\b/g;
  const regexTwoDigits = /\b\d{2}\b/g;
  const regexQuarter2 = /\d\d\d\d-[q]\d/g;
  const regexQuarter3 = /[q]\d-\d\d\d\d/g;

  const matchesFY = text.match(regexFY);
  const matchesQuarter1 = text.match(regexQuarter1);
  const matchesYear = text.match(regexYear);
  const matchesTwoDigits = text.match(regexTwoDigits);
  const matchesQuarter2 = text.match(regexQuarter2);
  const matchesQuarter3 = text.match(regexQuarter3);

  if (matchesFY && matchesFY.length > 0) {
    const fiscalyear = matchesFY[0].match(/\d+/g).join("");
    console.log(fiscalyear);
  }

  if (matchesQuarter1 && matchesQuarter1.length > 0) {
    possiblequarter = matchesQuarter1;
  }

  if (matchesYear && matchesYear.length > 0) {
    possibleyear = matchesYear;
  }

  if (matchesTwoDigits && matchesTwoDigits.length > 0) {
    possibleyear = matchesTwoDigits.map((year) =>
      year.length === 2 ? `20${year}` : year
    );
  }

  if (matchesQuarter2 && matchesQuarter2.length > 0) {
    quarter = matchesQuarter2;
  }

  if (matchesQuarter3 && matchesQuarter3.length > 0) {
    quarter.push(...matchesQuarter3);
  }
  let industryNames = closedFunnelFilters["industries"].map((r) => r);
  let qIndustry = null;
  let industry = "";

  if (text.includes("industry") || text.includes("vertical")) {
    qIndustry = text.includes("industry") ? "industry" : "vertical";

    let exIndustry = fuzzball.extract(text, industryNames, {
      scorer: fuzzball.token_set_ratio,
    }); // Assuming you have a similar function in JS
    industry = [
      get_highest_score_and_longest_string(exIndustry, { CONV_AI_CONSTANTS }),
    ]; // Assuming you have a similar function in JS

    if (industry[0] === "") {
      return {
        type: "error",
        message: "Sorry I could not find the specified industry",
      };
    }
  }
  // Quarter processing
  quarter = quarter.map((q) => q.toUpperCase());
  if (Array.isArray(possiblequarter)) {
    possiblequarter = possiblequarter.map((q) => q.trim().toUpperCase());
  }

  if (possibleyear.length !== 0) {
    possibleyear = possibleyear.map((y) => (y.length === 2 ? `20${y}` : y));
    if (possiblequarter.length !== 0) {
      for (let i = 0; i < possiblequarter.length; i++) {
        if (!possiblequarter[i].startsWith("Q")) {
          possiblequarter[i] = `Q${possiblequarter[i]}`;
        }
      }

      let whichYearareweon = 0;
      for (let i = 0; i < possiblequarter.length; i++) {
        const q = possiblequarter[i];
        if (q === "Q1" || q === "Q2" || q === "Q3" || q === "Q4") {
          let they;
          if (possibleyear.length > 0) {
            they = possibleyear[whichYearareweon];
          } else {
            they = 2020;
          }
          quarter.push(`${they}-${q}`);
        }
        whichYearareweon++;
      }
    }
  } else if (possibleyear.length === 0 && possiblequarter.length > 0) {
    possiblequarter = possiblequarter.join(" ").split();
    quarter = possiblequarter.map((x) => `2020-${x}`);
  }

  let tempq = [...quarter];
  quarter = [];
  for (let q of tempq) {
    try {
      q = q.replace("_", "-");
      q = q.replace("'", "-");
      if (typeof q !== "object" && q.length === 4) {
        q = [q.substring(0, 2), q.substring(2, 4)];
      } else if (typeof q !== "object" && q.length === 6 && q.startsWith("2")) {
        q = [q.substring(0, 4), q.substring(4, 6)];
      } else {
        q = q.split("-");
      }
      if (q[0].startsWith("Q")) {
        if (q.length === 3) {
          q = [q[1], q[2], q[3]];
        } else {
          q = [q[1], q[0]];
        }
      } else {
        if (q[0].length === 2) {
          q[0] = `20${q[0]}`;
        }
      }
      quarter.push(q.join("-"));
    } catch (error) {
      throw new Error("Invalid Quarter");
    }
  }

  quarter = quarter.map((q) => q.replace(/\s/g, ""));

  acvPreset = acvPreset.replace(/,/g, "");
  acvPreset = acvPreset.trim();

  // Clean up month data
  if (month.month.length > 0) {
    if (possibleyear.length === 0) {
      possibleyear = [2020];
    }
  }

  const returnD = {};

  if (possibleyear.length > 0 && quarter.length === 0) {
    // write code here
    const years = possibleyear.filter((item) =>
      closedFunnelFilters["years"]
        .map((it) => it.closed_fiscal_quarter)
        .includes(item)
    );
    if (years.length > 0) {
      returnD.year = years;
    }
  }

  if (rep !== "") {
    returnD.rep = rep;
  }

  if (quarter.length > 0) {
    const result = quarter.filter((item) =>
      closedFunnelFilters["closedFiscalQuarters"]
        .map((it) => it.closed_fiscal_quarter)
        .includes(item)
    );

    // console.log(result); // Should log [] because there is no matching year

    if (result.length > 0) {
      returnD.cfq = result;
    }
  }
  console.log(metric, "metric_extraction");
  if (metric !== "") {
    console.log("metric_extraction", "assigning metric");
    returnD["metric"] = metric;
  }

  if (acvPreset !== "") {
    returnD.acvPreset = acvPreset;
  }

  if (month.month.length > 0) {
    returnD.month = month;
  }

  if (typeof team === "string" && team !== "") {
    returnD.team = team;
  } else if (typeof team === "boolean" && team) {
    returnD.team = true;
  } else if (Array.isArray(team) && team.length > 0) {
    returnD.team = team.map(
      (t) => closedFunnelFilters.teams[team_names.indexOf(t)]
    );
  } else if (qIndustry !== null) {
    returnD["q_industry"] = industry;
  }
  console.log(returnD, "metric_extraction");
  let expectedOutput = {
    type: "success",
    filters: returnD,
    lkpStages: lkpStages,
  };
  console.log(expectedOutput, "metric_extraction");
  if (
    !("cfq" in expectedOutput.filters) &&
    !("year" in expectedOutput.filters)
  ) {
    console.log("doesnot contain quarter string");
    if (text.includes("year")) {
      expectedOutput.filters = {
        ...expectedOutput.filters,
        year: [closedFunnelFilters.currentFiscalYear],
      };
    } else if (text.includes("quarter")) {
      if (text.includes("this quarter") || text.includes("current quarter")) {
        expectedOutput.filters = {
          ...expectedOutput.filters,
          cfq: [closedFunnelFilters.currentFiscalQuarter],
        };
      } else {
        expectedOutput.filters = {
          ...expectedOutput.filters,
        };
      }
    }
  }
  console.log(expectedOutput, "metric_extraction");
  expectedOutput = {
    ...expectedOutput,
    filters: { ...expectedOutput.filters, metric: metricData },
  };
  console.log(expectedOutput, "metric_extraction");
  return { expectedOutput, metric };
};
