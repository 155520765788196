import { Card, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import Drilldown from "./WaterfallDrilldown";
import { countOrACVFormatter } from "../../util/customFunctions";

const MovedOutDataSection = (props) => {
  useEffect(() => {
    props.getManagerSummaryWaterfallData();
  }, []);

  return (
    <Grid>
      {props.managerSummaryWaterfallData !== "" ? (
        props.managerSummaryWaterfallData.opportunities.length >= 1 ? (
          <>
            <Typography
              variant="h2"
              style={{ padding: "1rem", textAlign: "center" }}
            >
              High value ({`>= `}
              {countOrACVFormatter(
                props.managerSummaryWaterfallData.thresholdACV,
                true
              )}
              ) opportunities moved out of the current quarter
            </Typography>
            <Drilldown
              body={props.managerSummaryWaterfallData}
              header={"MovedOut"}
            />{" "}
          </>
        ) : (
          <Typography
            variant="h2"
            style={{ padding: "1rem", textAlign: "center" }}
          >
            No High value ({`>= `}
            {countOrACVFormatter(
              props.managerSummaryWaterfallData.thresholdACV,
              true
            )}
            ) opportunities moved out of the current quarter
          </Typography>
        )
      ) : (
        <Skeleton variant="react" animation="wave" height={120} width="100%" />
      )}
    </Grid>
  );
};
const mapStateToProps = (state) => {
  const { user, managerSummaryWaterfallData } = state.app;
  return {
    user,
    company: user.company,
    managerSummaryWaterfallData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getManagerSummaryWaterfallData: () => {
    dispatch({ type: "get_managerSummary_waterfall_data_request" });
    appService.getManagerSummaryWaterfallData().then(
      (json) => {
        let tempOpportunities = json.message.opportunities.map(
          (item, index) => {
            let x = {
              ...item,
              acv_at_time: ["", null, undefined, NaN].includes(
                item["acv_at_time"]
              )
                ? 0
                : item["acv_at_time"],
            };
            return x;
          }
        );
        tempOpportunities.sort((a, b) =>
          a["acv_at_time"] > b["acv_at_time"] ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            opportunities: tempOpportunities,
          },
        };

        console.log(json, "json");
        dispatch({ type: "get_managerSummary_waterfall_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_data_failure", error });
      }
    );
  },
});
const MovedOutSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(MovedOutDataSection);
export default MovedOutSection;
