import React from "react";
import {
  funnelDataFormatter,
  funnelWhatIfDataFormatter,
} from "../../util/dataFormatter";

import D3Funnel from "./D3Funnel";
import D3FunnelACV from "./D3FunnelACV";
import FunnelTableClosedV2 from "./closedFunnelTableV2";
import {
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  Divider,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper,
  TextField,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Skeleton from "@material-ui/lab/Skeleton";
import { styles } from "../NewFunnel/FunnelStyles";
import Loading from "../../components/Loading";
import FunnelTableClosed from "./closedFunnelTable";
import * as d3 from "d3";
import NumberFormat from "react-number-format";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  _isNaN,
  getwhatidData,
  getDefaultSelectedFiscalQuarters,
  getDefaultSalesType,
  getUniqueValues,
  countOrACVFormatterThousand,
  customDateFormatter,
  customDateFormatterNew,
} from "../../util/customFunctions";
import OppotunityType from "../../Filters/OpportunityType";
//import Drilldown from '../../components/Drilldown/DrilldownV2'
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";
import DatePicker from "../../components/DatePicker_v3";
import DatePickerv2 from "../../components/DatePicker_v2";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";
import LocationRangeFilter from "../../components/LocationRangeFilter";
// import { computeClosedFunnelMap } from "./utils";

class Funnel extends React.Component {
  state = {
    Opportunity_Record_Type: ["All"],
    Record_Type_Name__c: ["All"],
    OppType: ["All"],
    year: ["All"],
    Closed_Year_Month: ["All"],
    month: ["All"],
    quarter: ["All"],
    rep: ["All"],
    cfq: ["All"],
    region: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    industry: ["All"],
    leadSource: ["All"],
    oppCreatedBy: ["All"],
    solutionCat: ["All"],
    custType: ["All"],
    custSeg: ["All"],
    team: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Type: ["All"],
    timeFrame: "quarter",
    disabled: [],
    acvPreset: "All",
    acv: 0,
    acvlt: 0,
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    location: "overview",
    ACV_Range: ["All"],
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
    selectedDrilldownStage: "",
    lastStage: false,
    activeFilters: ["All"],

    filtersForDrilldown: [],
    repFilter: ["All"],
    teamFilter: ["All"],
    tab: "Summary",
    showTeamData: false,
    whatifData: "",
    filterSelected: false,
    updateCount: 0,
    secondLevelLocation: null,
    sameTimePriorQtr: "None",
    Executive_Proposal__c: ["All"],
    Prior_Year_Opp_Manually_Inserted: ["All"],
    Product_Line: ["All"],
    include_outliers: "Yes",
    last: "",
    activelast: "",
    timeFrame: "window",
    from: "",
    to: "",
    fromDisplay: "",
    toDisplay: "",
    activeFrom: "",
    activeTo: "",
    minDateForTo: "",
    maxDateForTo: "",
    minDateForFrom: "",
    maxDateForFrom: "",
    errorTextFrom: "",
    errorTextTo: "",
    Account_Designation__c: ["All"],
    Of_Locations_Range: ["All"],
  };

  filters = [
    "year",
    "month",
    "quarter",
    "rep",
    "cfq",
    "team",
    "region",
    "industry",
    "custType",
    "Booking_Type_NL_CS_US",
    "custSeg",
    "leadSource",
    "oppCreatedBy",
    "solutionCat",
    "deal_category__c",
    "cross_sell1__c",
    "Type",
    "ACV_Range",
    "sameTimePriorQtr",
    "Product_Line",
    "Executive_Proposal__c",
    "Prior_Year_Opp_Manually_Inserted",
    "include_outliers",
    "Account_Designation__c",
    "Opportunity_Record_Type",
    "Record_Type_Name__c",
    "Closed_Year_Month",
    "Of_Locations_Range",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  staticCustSegment = [
    { value: "<=$25M", displayValue: "<=$25M" },
    { value: "$25-50M", displayValue: "$25M-50M" },
    { value: "$50-250M", displayValue: "$50M-250M" },
    { value: "$250-500M", displayValue: "$250M-500M" },
    { value: "$500M-2B", displayValue: "$500M-2B" },
    { value: ">$2B", displayValue: ">$2B" },
  ];

  componentDidMount() {
    this.props.getFunnelFilters(this.props.company);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = async () => {
    console.log(this.state.updateCount);
    D3Funnel.destroy(this._rootNode);
    D3Funnel.destroy(this._rootNode2);
    this._chart = D3Funnel.create(this._rootNode, {
      data: this.props.funnelData,
      handleChangeLocation: this.handleChangeLocation,
      showTeamData:
        this.state.repFilter.includes("All") &&
        this.state.teamFilter.includes("All")
          ? false
          : true,
      tab: this.state.tab,
      Value_Label: this.props.Value_Label,
      Win_Rate_Prefix: this.props.Win_Rate_Prefix,
    });
    this._chart = D3FunnelACV.create(this._rootNode2, {
      data:
        this.state.tab === "Summary" ||
        this.state.filterSelected === false ||
        this.state.updateCount === 0
          ? this.props.funnelData
          : funnelWhatIfDataFormatter(
              this.state.whatifData,
              this.state.filterSelected
            ),
      handleChangeLocation: this.handleChangeLocation,
      showTeamData:
        this.state.repFilter.includes("All") &&
        this.state.teamFilter.includes("All")
          ? false
          : true,
      tab: this.state.tab,
      Value_Label: this.props.Value_Label,
      Win_Rate_Prefix: this.props.Win_Rate_Prefix,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelFilters !== "" && prevProps.funnelFilters === "") {
      const fromDateComponents =
        this.props.funnelFilters.dateFilters.prior3Month.split("-");
      const toDateComponents =
        this.props.funnelFilters.dateFilters.currentDate.split("-");
      const fromDateObj = new Date(
        parseInt(fromDateComponents[0]),
        parseInt(fromDateComponents[1]) - 1,
        parseInt(fromDateComponents[2])
      );
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      const minDateSplit = this.props.funnelFilters.minMax.minDate.split("-");
      const minDate = new Date(
        parseInt(minDateSplit[0]),
        parseInt(minDateSplit[1]) - 1,
        parseInt(minDateSplit[2])
      );
      const maxDateSplit = this.props.funnelFilters.minMax.maxDate.split("-");
      const maxDate = new Date(
        parseInt(maxDateSplit[0]),
        parseInt(maxDateSplit[1]) - 1,
        parseInt(maxDateSplit[2])
      );

      const defaultFQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelFilters.closedFiscalQuarters,
        "closed_fiscal_quarter"
      );
      var defaultQuarter =
        defaultFQuarter.length > 0 ? defaultFQuarter : ["All"];
      var salesTypes = ["All"];
      let dealTypes = ["All"];
      let priorYearDeal = ["All"];
      let solutionCat = ["All"];
      let activeFilters = [{ name: "cfq", value: defaultQuarter }];
      dealTypes = getDefaultSalesType(this.props.funnelFilters.Type, "Type");
      solutionCat = getDefaultSalesType(
        this.props.funnelFilters.solutionCat,
        "Solution_Cat"
      );
      if (dealTypes[0] !== "All") {
        activeFilters = [...activeFilters, { name: "Type", value: dealTypes }];
      }
      if (solutionCat[0] !== "All") {
        activeFilters = [
          ...activeFilters,
          { name: "solutionCat", value: solutionCat },
        ];
      }
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelFilters.salesType,
          "cross_sell1__c"
        );
        activeFilters =
          salesTypes[0] === "All"
            ? activeFilters
            : [...activeFilters, { name: "cross_sell1__c", value: salesTypes }];
        dealTypes = getDefaultSalesType(
          this.props.funnelFilters.dealType,
          "Type"
        );
        activeFilters =
          dealTypes[0] === "All"
            ? activeFilters
            : [...activeFilters, { name: "Type", value: dealTypes }];
        priorYearDeal = getDefaultSalesType(
          this.props.funnelFilters.priorYearDealInclude,
          "Prior_Year_Opp_Manually_Inserted"
        );
        activeFilters =
          priorYearDeal[0] === "All"
            ? activeFilters
            : [
                ...activeFilters,
                {
                  name: "Prior_Year_Opp_Manually_Inserted",
                  value: priorYearDeal,
                },
              ];
      }
      this.setState({
        ...prevState,
        cfq: defaultQuarter,
        cross_sell1__c: salesTypes,
        Type: dealTypes,
        solutionCat: solutionCat,
        Prior_Year_Opp_Manually_Inserted: priorYearDeal,
        minDateForTo: minDate,
        maxDateForTo: maxDate,
        minDateForFrom: minDate,
        maxDateForFrom: maxDate,
        from: fromDate,
        to: toDate,
        fromDisplay: customDateFormatterNew(fromDate),
        toDisplay: customDateFormatterNew(toDate),
        activeFrom: fromDate,
        activeTo: toDate,
        last: this.props.funnelFilters.timeFrame,
        filtersForDrilldown: [
          {
            title: "Closed Fiscal Qtr.",
            value: defaultQuarter,
          },
          ...this.state.filtersForDrilldown.filter(
            (f) => f.title !== "Closed Fiscal Quarter"
          ),
        ],
        activeFilters: activeFilters,
      });
    }
    if (this.props.funnelData !== "" && prevProps.funnelData === "") {
      // console.log(
      //   computeClosedFunnelMap(this.props.funnelData),
      //   "funnelData_map"
      // );
      this._chart = D3Funnel.create(this._rootNode, {
        data: this.props.funnelData,
        handleChangeLocation: this.handleChangeLocation,
        showTeamData:
          this.state.repFilter.includes("All") &&
          this.state.teamFilter.includes("All")
            ? false
            : true,
        tab: this.state.tab,
        Value_Label: this.props.Value_Label,
        Win_Rate_Prefix: this.props.Win_Rate_Prefix,
      });
      this._chart = D3FunnelACV.create(this._rootNode2, {
        data: this.props.funnelData,
        handleChangeLocation: this.handleChangeLocation,
        showTeamData:
          this.state.repFilter.includes("All") &&
          this.state.teamFilter.includes("All")
            ? false
            : true,
        tab: this.state.tab,
        Value_Label: this.props.Value_Label,
        Win_Rate_Prefix: this.props.Win_Rate_Prefix,
      });
      console.log(this.state.whatifData);
    }
    if (
      this.props.funnelData !== prevProps.funnelData &&
      prevProps.funnelData !== ""
    ) {
      // D3 Code to update the chart
      // console.log(
      //   computeClosedFunnelMap(this.props.funnelData),
      //   "funnelData_map"
      // );
      D3Funnel.destroy(this._rootNode);
      D3Funnel.destroy(this._rootNode2);
      this._chart = D3Funnel.create(this._rootNode, {
        data: this.props.funnelData,
        handleChangeLocation: this.handleChangeLocation,
        showTeamData:
          this.state.repFilter.includes("All") &&
          this.state.teamFilter.includes("All")
            ? false
            : true,
        tab: this.state.tab,
        Value_Label: this.props.Value_Label,
        Win_Rate_Prefix: this.props.Win_Rate_Prefix,
      });
      this._chart = D3FunnelACV.create(this._rootNode2, {
        data: this.props.funnelData,
        handleChangeLocation: this.handleChangeLocation,
        showTeamData:
          this.state.repFilter.includes("All") &&
          this.state.teamFilter.includes("All")
            ? false
            : true,
        tab: this.state.tab,
        Value_Label: this.props.Value_Label,
        Win_Rate_Prefix: this.props.Win_Rate_Prefix,
      });
      console.log(this.state.whatifData);
    }
  }

  /* componentWillUnmount() {
      D3Funnel.destroy(this._rootNode);
  } */

  getdataValue = (d) => {
    console.log(d);
    let count = this.state.updateCount;
    this.setState({
      ...this.state,
      whatifData: d,
      filterSelected: true,
      updateCount: count + 1,
    });
    console.log(this.state.updateCount);

    D3Funnel.destroy(this._rootNode);
    D3Funnel.destroy(this._rootNode2);
    this._chart = D3Funnel.create(this._rootNode, {
      data: this.props.funnelData,
      handleChangeLocation: this.handleChangeLocation,
      showTeamData:
        this.state.repFilter.includes("All") &&
        this.state.teamFilter.includes("All")
          ? false
          : true,
      tab: this.state.tab,
      Value_Label: this.props.Value_Label,
      Win_Rate_Prefix: this.props.Win_Rate_Prefix,
    });
    this._chart = D3FunnelACV.create(this._rootNode2, {
      data:
        this.state.tab === "Summary"
          ? this.props.funnelData
          : this.state.whatifData,
      handleChangeLocation: this.handleChangeLocation,
      showTeamData:
        this.state.repFilter.includes("All") &&
        this.state.teamFilter.includes("All")
          ? false
          : true,
      tab: this.state.tab,
      filterSelected: this.state.filterSelected,
      Value_Label: this.props.Value_Label,
      Win_Rate_Prefix: this.props.Win_Rate_Prefix,
    });
  };

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }

  handleChangeDateTimeFrame = (e) => {
    console.log(e.target.value, "ZERO");
    if (![0, 1].includes(e.target.value)) {
      const projectedClosedDateMapping = {
        30: this.props.funnelFilters.dateFilters.prior1Month.split("-"),
        60: this.props.funnelFilters.dateFilters.prior2Month.split("-"),
        90: this.props.funnelFilters.dateFilters.prior3Month.split("-"),
      };
      const fromDateObj = new Date(
        parseInt(projectedClosedDateMapping[e.target.value][0]),
        parseInt(projectedClosedDateMapping[e.target.value][1]) - 1,
        parseInt(projectedClosedDateMapping[e.target.value][2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDateComponents =
        this.props.funnelFilters.dateFilters.currentDate.split("-");
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        to: toDate,
        from: fromDate,
        errorTextFrom: "",
        errorTextTo: "",
      });
    } else {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  };

  setFromDate = (date) => {
    const split = this.state.to.split("-");
    const toDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) > toDate) {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "From date cannot be later than the To date",
      });
    } else {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "",
        errorTextTo: "",
      });
    }
  };

  setToDate = (date) => {
    const split = this.state.from.split("-");
    const fromDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) < fromDate) {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "To date cannot be earlier than the From date",
      });
    } else {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextTo: "",
        errorTextFrom: "",
      });
    }
  };

  handleChange = (e) => {
    // console.log(e.target)
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
        sameTimePriorQtr: "None",
      });
    } else if (
      e.target.name === "month" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      // console.log('x')
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "sameTimePriorQtr"
            ? e.target.value === "None"
              ? "None"
              : "None"
            : e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        sameTimePriorQtr: "None",
      });
    } else if (
      e.target.name === "cfq" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "sameTimePriorQtr"
            ? e.target.value === "None"
              ? "None"
              : "None"
            : e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        sameTimePriorQtr: "None",
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "sameTimePriorQtr"
            ? e.target.value === "None"
              ? "None"
              : e.target.value
            : e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    // console.log(this.state.sameTimePriorQtr)
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .filter(getUniqueValues)
      .map((f) => {
        return {
          name: f,
          value:
            f === "sameTimePriorQtr"
              ? this.state[f] === "None"
                ? ["All"]
                : this.state[f]
              : f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (this.state.include_outliers === "Yes") {
      filters = filters.filter((f) => f.name !== "include_outliers");
    }
    if (this.state.acv !== null && this.state.acv !== 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }

    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }

    if (this.state.cfq.includes("All")) {
      if (this.state.from !== "" && this.state.to !== "") {
        const from = this.state.from;
        const fromDate = `${from.split("-")[2]}-${
          from.split("-")[0].length > 1
            ? from.split("-")[0]
            : `0${from.split("-")[0]}`
        }-${
          from.split("-")[1].length > 1
            ? from.split("-")[1]
            : `0${from.split("-")[1]}`
        }`;
        const to = this.state.to;
        const toDate = `${to.split("-")[2]}-${
          to.split("-")[0].toString().length > 1
            ? to.split("-")[0]
            : `0${to.split("-")[0]}`
        }-${
          to.split("-")[1].length > 1
            ? to.split("-")[1]
            : `0${to.split("-")[1]}`
        }`;
        const timeFrames = `${fromDate}_to_${toDate}`;
        if (this.state.last !== 0) {
          filters = [
            ...filters,
            {
              name: "TimeFrame",
              value: timeFrames,
            },
          ];
        }
      }
    }

    this.props.getFunnelDataNew(filters);
    this.setState({
      ...this.state,
      activeFilters: filters,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      repFilter: this.state.rep,
      teamFilter: this.state.team,
      showTeamData:
        this.state.rep.includes("All") && this.state.team.includes("All")
          ? false
          : true,
      updateCount: 0,
    });
    console.log(this.state.whatifData);
  };

  // handleLocationFilter = (e) => {
  //   console.log("handleLocationFilter", e.target.value);
  //   this.setState({
  //     ...this.state,
  //     Of_Locations_Range:
  //   })
  // };

  orderRepsByLastName = () => {
    let reps = this.props.funnelFilters.reps
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  handleChangeTimeFrame = (e) => {
    this.setState({
      ...this.state,
      timeFrame: e.target.value,
      disabled:
        e.target.value === "custom"
          ? [
              ...this.state.disabled.filter(
                (f) => f !== "year" && f !== "month" && f !== "quarter"
              ),
              "cfq",
            ]
          : [
              ...this.state.disabled.filter((f) => f !== "cfq"),
              "year",
              "month",
              "quarter",
            ],
    });
  };

  handleChangeLocation = (location, stage, lastStage, key) => {
    var wonLabel =
      this.props.funnelData[this.props.funnelData.length - 1].label;
    var header =
      lastStage || stage === wonLabel
        ? `${stage} Opportunities`
        : key === "lost"
        ? `Lost / Disqualified from ${
            Array.isArray(stage) ? "Open" : stage
          } stage`
        : key === "cameTo"
        ? `Came to ${stage} stage`
        : key === "movedOn"
        ? `Moved Forward from ${stage} stage`
        : `Opportunities lost at ${stage} stage`;

    this.setState({
      ...this.state,
      location: location,
      selectedDrilldownStage: stage,
      lastStage: lastStage,
      header,
    });
    if (location !== "overview") {
      this.props.getClosedDrilldownOnGraph(key, {
        stage: Array.isArray(stage) ? stage : [stage],
        filters: this.state.activeFilters,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (this.state.last !== 0 && this.state.cfq.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Time Frame", value: `${this.state.last} days` },
        {
          title: "From start of",
          value: customDateFormatterNew(this.state.from),
        },
        { title: "To end of", value: customDateFormatterNew(this.state.to) },
      ];
    }
    if (!this.state.Account_Designation__c.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Account Designation",
          value: this.state.Account_Designation__c.sort(),
        },
      ];
    }
    if (!this.state.cfq.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Closed Fiscal Qtr.", value: this.state.cfq.sort() },
      ];
    }
    if (!this.state.Closed_Year_Month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Closed Month", value: this.state.Closed_Year_Month.sort() },
      ];
    }
    if (!this.state.year.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Closed Calendar Year", value: this.state.year.sort() },
      ];
    }
    if (!this.state.month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Calendar Month",
          value: this.state.month
            .sort((a, b) => (a < b ? -1 : 1))
            .map((i) => this.months[i - 1]),
        },
      ];
    }
    if (!this.state.rep.includes("All"))
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    if (!this.state.acv !== null && this.state.acv !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Greater Than`,
          value:
            `${this.props.Currency_Symbol}` +
            d3.format(",")(
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv
            ),
        },
      ];

    if (!this.state.acvlt !== null && this.state.acvlt !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Less Than`,
          value:
            `${this.props.Currency_Symbol}` +
            d3.format(",")(
              this.state.acvlt && typeof this.state.acvlt === "string"
                ? this.state.acvlt.match(/,/g)
                  ? this.state.acvlt.match(/,/g).length === 1
                    ? parseInt(this.state.acvlt) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acvlt) * 1000000
                    : parseInt(this.state.acvlt)
                  : parseInt(this.state.acvlt)
                : this.state.acvlt
            ),
        },
      ];

    if (!this.state.cross_sell1__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Sales Type", value: this.state.cross_sell1__c },
      ];
    if (!this.state.industry.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value: this.state.industry,
        },
      ];
    if (!this.state.custSeg.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Customer Segment", value: this.state.custSeg },
      ];
    if (!this.state.region.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value: this.state.region,
        },
      ];
    if (!this.state.leadSource.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Lead Source", value: this.state.leadSource },
      ];
    if (!this.state.oppCreatedBy.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.oppCreatedBy },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state.solutionCat.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.solutionCat },
      ];

    if (!this.state.deal_category__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Deal Category", value: this.state.deal_category__c },
      ];

    if (!this.state.Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0017" ? "Opp. Type" : "Deal Type",
          value: this.state.Type,
        },
      ];

    if (!this.state.ACV_Range.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Range`,
          value: this.state.ACV_Range,
        },
      ];

    if (!this.state.Record_Type_Name__c.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Opp Record Type", value: this.state.Record_Type_Name__c },
      ];
    }

    if (!this.state.Executive_Proposal__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proposal Desk Review",
          value: this.state.Executive_Proposal__c,
        },
      ];

    if (!this.state.Prior_Year_Opp_Manually_Inserted.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Prior Year Deals",
          value: this.state.Prior_Year_Opp_Manually_Inserted,
        },
      ];

    if (!this.state.Opportunity_Record_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Opportunity Record Type",
          value: this.state.Opportunity_Record_Type,
        },
      ];

    if (!this.state.OppType.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Opp. Type", value: this.state.OppType },
      ];
    }

    if (!this.state.Product_Line.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0008" ? "Product Line" : "Product Cat.",
          value: this.props.funnelFilters.Product_Line.filter((s) =>
            this.state.Product_Line.includes(s.Product_Line)
          ).map((item) => item.label),
        },
      ];

    return filtersArray;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  handleChangeTab = (e) => {
    this.handleGo();
    console.log(e.target.value);
    this.setState({
      ...this.state,
      tab: e.target.value,
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    const isTimeFrameEnabled =
      this.state.cfq.includes("All") &&
      this.state.Closed_Year_Month.includes("All");
    console.table(
      this.state.from,
      this.state.to,
      this.state.minDateForFrom,
      this.state.maxDateForFrom,
      this.state.minDateForTo,
      this.state.maxDateForTo,
      "Time Frame"
    );

    console.log("LocationRange", this.props.funnelFilters.LocationRange);

    return (
      <Grid container style={{ position: "relative" }}>
        {/* Caption and description */}
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Win Rate Cohort Analysis – Closed Deals
          </Typography>
          <Typography variant="body1">
            The Win Rate cohort analysis for Closed Deals enables you to examine
            all the deals that closed in a time frame and analyze the win rate
            as opportunities moved down the funnel. Visualizing where
            opportunities drop off can pinpoint specific challenges that need
            corrective action. By analyzing deals that have already closed, the
            uncertainty of how opportunities that are still open may perform is
            eliminated. This analysis provides you insights by opportunity count
            as well as ACV.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          `${this.props.Win_Rate_Prefix} Cohort Analysis – Closed Deals (New)`,
          `The ${this.props.Win_Rate_Prefix} cohort analysis for Closed Deals enables you to examine all the deals that closed in a time frame and analyze the ${this.props.Win_Rate_Prefix} as opportunities moved down the funnel. Visualizing where opportunities drop off can pinpoint specific challenges that need corrective action. By analyzing deals that have already closed, the uncertainty of how opportunities that are still open may perform is eliminated. This analysis provides you insights by opportunity count as well as ${this.props.Value_Label}.`
        )}

        {this.props.funnelFilters ? (
          <>
            {/* dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.year[0] !== "All" ||
                      this.state.month[0] !== "All" ||
                      this.state.Closed_Year_Month[0] !== "All"
                    }
                  >
                    <InputLabel
                      id="select-fiscalQuarter-label"
                      style={{
                        color: !this.state.cfq.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Closed Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      data-test="closed-fiscal-quarter"
                      labelId="select-fiscalQuarter-label"
                      id="select-fiscalQuarter"
                      value={this.state.cfq}
                      onChange={this.handleChange}
                      label="Closed Fiscal Quarter"
                      name="cfq"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.closedFiscalQuarters
                          .filter((item) => item.closed_fiscal_quarter !== "")
                          .map((y) => (
                            <MenuItem
                              key={y.closed_fiscal_quarter}
                              value={y.closed_fiscal_quarter}
                            >
                              {y.closed_fiscal_quarter}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.cfq[0] !== "All"}
                  >
                    <InputLabel
                      id="select-year-label"
                      style={{
                        color: !this.state.year.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Closed Month:
                    </InputLabel>
                    <Select
                      labelId="select-year-label"
                      id="select-Closed_Year_Month"
                      value={this.state.Closed_Year_Month}
                      onChange={this.handleChange}
                      label="Closed Month"
                      name="Closed_Year_Month"
                      data-test="Closed_Year_Month"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.Closed_Year_Month.map(
                          (f) => f.Closed_Year_Month
                        )
                          .sort()
                          .reverse()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/*<Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    disabled={this.state.cfq[0] !== "All"}
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      id="select-month-label"
                      style={{
                        color: !this.state.month.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Closed Calendar Month:
                    </InputLabel>
                    <Select
                      labelId="select-month-label"
                      id="select-month"
                      data-test="month"
                      value={this.state.month}
                      onChange={this.handleChange}
                      label="Month"
                      name="month"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                        <MenuItem key={y} value={y}>
                          {this.months[y - 1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={4}
              md={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                style={{ paddingTop: 0 }}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-last-label"
                      style={
                        isTimeFrameEnabled && this.state.last !== 0
                          ? { color: "#4472c4", whiteSpace: "nowrap" }
                          : { whiteSpace: "nowrap" }
                      }
                    >
                      Time Frame - Last:
                    </InputLabel>
                    <Select
                      labelId="select-last-label"
                      id="select-last"
                      value={isTimeFrameEnabled ? this.state.last : 0}
                      onChange={this.handleChangeDateTimeFrame}
                      label="Last:"
                      name="last"
                      disabled={
                        this.state.timeFrame === "custom" || !isTimeFrameEnabled
                      }
                    >
                      {[
                        { text: "None", value: 0 },
                        { text: "Custom", value: 1 },
                        { text: "1 month", value: 30 },
                        { text: "2 months", value: 60 },
                        { text: "3 months", value: 90 },
                      ].map((y) => (
                        <MenuItem key={y.text} value={y.value}>
                          {y.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 0, paddingTop: 0 }}>
                  {!isTimeFrameEnabled || this.state.last === 0 ? (
                    <DatePicker
                      label="From start of:"
                      setDate={(date) => this.setFromDate(date)}
                      disabled={this.state.last !== 1 || !isTimeFrameEnabled}
                      min={this.state.minDateForFrom}
                      max={this.state.maxDateForFrom}
                      current={""}
                      errorText={
                        this.state.to === "" || this.state.from === ""
                          ? ""
                          : this.state.errorTextFrom
                      }
                    />
                  ) : (
                    <DatePickerv2
                      label="From start of:"
                      setDate={(date) => this.setFromDate(date)}
                      disabled={this.state.last !== 1 || !isTimeFrameEnabled}
                      min={this.state.minDateForFrom}
                      max={this.state.maxDateForFrom}
                      current={this.state.from}
                      errorText={this.state.errorTextFrom}
                    />
                  )}
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 0, paddingTop: 0 }}>
                  {!isTimeFrameEnabled || this.state.last === 0 ? (
                    <DatePicker
                      label="To end of:"
                      setDate={(date) => this.setToDate(date)}
                      disabled={this.state.last !== 0 || !isTimeFrameEnabled}
                      min={this.state.minDateForTo}
                      max={this.state.maxDateForTo}
                      current={""}
                      errorText={undefined}
                    />
                  ) : (
                    <DatePickerv2
                      label="To end of:"
                      setDate={(date) => this.setToDate(date)}
                      disabled={this.state.last !== 1 || !isTimeFrameEnabled}
                      min={this.state.minDateForTo}
                      max={this.state.maxDateForTo}
                      current={this.state.to}
                      errorText={this.state.errorTextTo}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* rep & team */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={4}
            >
              {this.props.funnelFilters !== "" ? (
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={
                        this.props.funnelFilters !== "" &&
                        this.orderRepsByLastName()
                      }
                      getOptionLabel={(option) => option}
                      value={this.state.rep[0]}
                      onChange={this.onRepChange}
                      name="rep"
                      data-test="rep"
                      disabled={!this.state.team.includes("All")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rep:"
                          variant="standard"
                          InputLabelProps={{
                            style: {
                              color: !this.state.rep.includes("All")
                                ? "#4472c4"
                                : "#000",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={!this.state.rep.includes("All")}
                    >
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.team.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Team:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-team"
                        value={this.state.team}
                        onChange={this.handleChange}
                        label="Team"
                        data-test="team"
                        name="team"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.teams
                            .map((t) => t.Display_Name)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                <Grid item container component={Paper} justify="center">
                  {" "}
                  <Skeleton
                    variant="text"
                    animation="wave"
                    height={40}
                    width={290}
                  />
                </Grid>
              )}
            </Grid>

            {/* extra filters 1 */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={12}
              md={12}
              lg={6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-region-label"
                      style={{
                        color: !this.state.industry.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Industry:"}
                    </InputLabel>
                    <Select
                      labelId="select-region-label"
                      id="select-region"
                      value={this.state.industry}
                      onChange={this.handleChange}
                      name="industry"
                      data-test="industry"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.industries
                          .sort()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-industry-label"
                      style={{
                        color: !this.state.custSeg.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Customer Segment:
                    </InputLabel>
                    <Select
                      labelId="select-industry-label"
                      id="select-industry"
                      value={this.state.custSeg}
                      onChange={this.handleChange}
                      name="custSeg"
                      data-test="customer_segment"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.custSeg
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-industry-label"
                      style={{
                        color: !this.state.region.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      {this.props.company === "C0003"
                        ? "Region:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      labelId="select-industry-label"
                      id="select-industry"
                      value={this.state.region}
                      onChange={this.handleChange}
                      name="region"
                      data-test="region"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.regions
                          .sort()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* extra filters 2 */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={12}
              md={12}
              lg={this.props.company === "C0003" ? 6 : 6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={this.props.company === "C0003" ? 4 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-region-label"
                      style={{
                        color: !this.state.leadSource.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Lead Source:
                    </InputLabel>
                    <Select
                      labelId="select-region-label"
                      id="select-region"
                      value={this.state.leadSource}
                      onChange={this.handleChange}
                      name="leadSource"
                      data-test="leadsource"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.leadSource
                          .sort()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={this.props.company === "C0003" ? 4 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-industry-label"
                      style={{
                        color: !this.state.oppCreatedBy.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Opportunity Created By:
                    </InputLabel>
                    <Select
                      labelId="select-industry-label"
                      id="select-industry"
                      value={this.state.oppCreatedBy}
                      onChange={this.handleChange}
                      name="oppCreatedBy"
                      data-test="opp_created_by"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.oppCreatedBy
                          .sort()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                {!["C0003", "C0008"].includes(this.props.company) && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-industry-label"
                        style={{
                          color: !this.state.solutionCat.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Solution Category:
                      </InputLabel>
                      <Select
                        labelId="select-industry-label"
                        id="select-industry"
                        value={this.state.solutionCat}
                        onChange={this.handleChange}
                        name="solutionCat"
                        data-test="solution_category"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.solutionCat
                            .map((item) => item.Solution_Cat)
                            .sort()
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {this.props.company === "C0003" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Booking_Type_NL_CS_US.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Opportunity Type:
                      </InputLabel>
                      <Select
                        value={this.state.Booking_Type_NL_CS_US}
                        onChange={this.handleChange}
                        name="Booking_Type_NL_CS_US"
                        data-test="Booking_Type_NL_CS_US"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.Booking_Type_NL_CS_US.sort(
                            (a, b) =>
                              a["Booking_Type_NL_CS_US"] <
                              b["Booking_Type_NL_CS_US"]
                                ? -1
                                : a["Booking_Type_NL_CS_US"] >
                                  b["Booking_Type_NL_CS_US"]
                                ? 1
                                : 0
                          )
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {this.props.company === "C0008" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Product_Line-label"
                        style={{
                          color: !this.state.Product_Line.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Product Line:
                      </InputLabel>
                      <Select
                        labelId="select-Product_Line-label"
                        id="select-Product_Line"
                        value={this.state.Product_Line}
                        onChange={this.handleChange}
                        name="Product_Line"
                        data-test="Product_Line"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.Product_Line.map((y) => (
                            <MenuItem
                              key={y.Product_Line}
                              value={y.Product_Line}
                            >
                              {y.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* {this.props.company === "C0013" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                // justify="space-between"
                xs={2}
                // md={12}
                // lg={4}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  // style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Opportunity_Record_Type.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opportunity Record Type:
                      </InputLabel>
                      <Select
                        data-test="Opportunity_Record_Type"
                        value={this.state.Opportunity_Record_Type}
                        onChange={this.handleChange}
                        name="Opportunity_Record_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters.OpportunityRecordType !==
                          "" &&
                          this.props.funnelFilters.OpportunityRecordType.filter(
                            (y) =>
                              y.type === "Opportunity_Record_Type" &&
                              y.Opportunity_Record_Type !== ""
                          ).map((y) => (
                            <MenuItem
                              key={y.Opportunity_Record_Type}
                              value={y.Opportunity_Record_Type}
                            >
                              {y.Opportunity_Record_Type}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )} */}

            {/* C0010 */}
            {this.props.company === "C0010" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                // justify="space-between"
                xs={2}
                // md={12}
                // lg={4}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  // style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Record_Type_Name__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        data-test="Record_Type_Name__c"
                        value={this.state.Record_Type_Name__c}
                        onChange={this.handleChange}
                        name="Record_Type_Name__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters.Record_Type_Name__c !== "" &&
                          this.props.funnelFilters.Record_Type_Name__c.filter(
                            (y) =>
                              y.type === "Record_Type_Name__c" &&
                              y.Record_Type_Name__c !== ""
                          ).map((y) => (
                            <MenuItem
                              key={y.Record_Type_Name__c}
                              value={y.Record_Type_Name__c}
                            >
                              {y.Record_Type_Name__c}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/*ACV >= and ACV <= */}
            {this.props.company === "C0017" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                // justify="space-between"
                xs={2}
                // md={12}
                // lg={4}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  // style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opp. Type:
                      </InputLabel>
                      <Select
                        data-test="OppType"
                        value={this.state.Type}
                        onChange={this.handleChange}
                        name="Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters.Type !== "" &&
                          this.props.funnelFilters.Type.filter(
                            (y) => y.type === "Type" && y.Type !== ""
                          ).map((y) => (
                            <MenuItem key={y.Type} value={y.Type}>
                              {y.Type}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={12}
              md={12}
              lg={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 5 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelFilters !== "" &&
                      this.props.funnelFilters.acv
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    data-test="acv"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== null && this.state.acv !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 5 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelFilters !== "" &&
                      this.props.funnelFilters.acv
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    data-test="acvlt"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== null &&
                              this.state.acvlt !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={2}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.ACV_Range.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      {this.props.Value_Label} Range:
                    </InputLabel>
                    <Select
                      data-test="ACV_Range"
                      value={this.state.ACV_Range}
                      onChange={this.handleChange}
                      name="ACV_Range"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.ACV_Range.filter(
                          (item) => item !== ""
                        ).map((y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {this.props.company === "C0001" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.deal_category__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state.deal_category__c}
                        onChange={this.handleChange}
                        name="deal_category__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.dealType.map((y) => (
                            <MenuItem
                              key={y.deal_category__c}
                              value={y.deal_category__c}
                            >
                              {y.deal_category__c}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0001" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Executive_Proposal__c.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Proposal Desk Review:
                      </InputLabel>
                      <Select
                        data-test="Executive_Proposal__c"
                        value={this.state.Executive_Proposal__c}
                        onChange={this.handleChange}
                        name="Executive_Proposal__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.executiveproposal.map(
                            (y) => (
                              <MenuItem
                                key={y.Executive_Proposal__c}
                                value={y.Executive_Proposal__c}
                              >
                                {y.Executive_Proposal__c}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0008" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={4}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.cross_sell1__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Sales Type:
                      </InputLabel>
                      <Select
                        data-test="cross_sell1__c"
                        value={this.state.cross_sell1__c}
                        onChange={this.handleChange}
                        name="cross_sell1__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.salesType.map((y) => (
                            <MenuItem
                              key={y.cross_sell1__c}
                              value={y.cross_sell1__c}
                            >
                              {y.cross_sell1__c}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Type:
                      </InputLabel>
                      <Select
                        data-test="cross_sell1__c"
                        value={this.state.Type}
                        onChange={this.handleChange}
                        name="Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.dealType.map((y) => (
                            <MenuItem key={y.Type} value={y.Type}>
                              {y.Type}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {["C0003"].includes(this.props.company) && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Product_Line.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Product Cat.:
                      </InputLabel>
                      <Select
                        data-test="Product_Line"
                        value={this.state.Product_Line}
                        onChange={this.handleChange}
                        name="Product_Line"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.Product_Line.map((y) => (
                            <MenuItem
                              key={y["Product_Line"]}
                              value={y["Product_Line"]}
                            >
                              {y["label"]}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {["C0010"].includes(this.props.company) && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Account_Designation__c.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Account Designation:
                      </InputLabel>
                      <Select
                        data-test="Account_Designation__c"
                        value={this.state.Account_Designation__c}
                        onChange={this.handleChange}
                        name="Account_Designation__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.AccountDesignation.map(
                            (y) => (
                              <MenuItem
                                key={y["Account_Designation__c"]}
                                value={y["Account_Designation__c"]}
                              >
                                {y["Account_Designation__c"]}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0008" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color:
                            !this.state.Prior_Year_Opp_Manually_Inserted.includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                        }}
                        id="select-region-label"
                      >
                        Prior Year Deals:
                      </InputLabel>
                      <Select
                        data-test="Prior_Year_Opp_Manually_Inserted"
                        value={this.state.Prior_Year_Opp_Manually_Inserted}
                        onChange={this.handleChange}
                        name="Prior_Year_Opp_Manually_Inserted"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.priorYearDealInclude.map(
                            (y) => (
                              <MenuItem
                                key={y.Prior_Year_Opp_Manually_Inserted}
                                value={y.Prior_Year_Opp_Manually_Inserted}
                              >
                                {y.Prior_Year_Opp_Manually_Inserted}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={2}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color:
                          this.state.sameTimePriorQtr !== "None"
                            ? "#4472c4"
                            : "#000",
                      }}
                      id="select-region-label"
                    >
                      Same Time Prior Period :
                    </InputLabel>
                    <Select
                      data-test="sameTimePriorQtr"
                      value={this.state.sameTimePriorQtr}
                      onChange={this.handleChange}
                      name="sameTimePriorQtr"
                      disabled={
                        (this.state.year.includes("All") &&
                          this.state.month.includes("All") &&
                          this.state.cfq.includes("All")) ||
                        (this.state.year.includes("All") &&
                          !this.state.month.includes("All")) ||
                        (!this.state.year.includes("All") &&
                          this.state.month.includes("All"))
                      }
                    >
                      <MenuItem value={"None"}>None</MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={2}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color:
                          this.state.include_outliers !== "Yes"
                            ? "#4472c4"
                            : "#000",
                        whiteSpace: "nowrap",
                      }}
                      id="select-region-label"
                    >{`Include Outliers ${
                      ![null, undefined, "", 0].includes(
                        this.props.funnelFilters.outlierValue
                      )
                        ? `(>${countOrACVFormatterThousand(
                            this.props.funnelFilters.outlierValue,
                            true
                          )})`
                        : ""
                    }:`}</InputLabel>
                    <Select
                      data-test="include_outliers"
                      value={this.state.include_outliers}
                      onChange={this.handleChange}
                      name="include_outliers"
                      // disabled={(this.state.year.includes('All') && this.state.month.includes('All') && this.state.cfq.includes('All')) || (this.state.year.includes('All') && !this.state.month.includes('All')) || (!this.state.year.includes('All') && this.state.month.includes('All')) }
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {this.props.company === "C0020" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={2}
              >
                <LocationRangeFilter
                  classes={classes}
                  options={this.props.funnelFilters.LocationRange}
                  value={this.state.Of_Locations_Range}
                  onChange={this.handleChange}
                />
              </Grid>
            )}

            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              lg={2}
            >
              <Grid item xs={4} xl={3} container justify="flex-end">
                <Button
                  data-test="go"
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                  disabled={this.state.acvError !== ""}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid item xs={12} md={12} id="grid-container">
          <Paper style={{ marginBottom: "-7px" }}>
            <ToggleButtonGroup
              color="primary"
              indicatorColor="primary"
              value={this.state.tab}
              exclusive
              size="large"
              onChange={this.handleChangeTab}
            >
              <ToggleButton
                style={{
                  background: this.state.tab === "Summary" ? "#4472C4" : "",
                  color: this.state.tab === "Summary" ? "white" : "",
                  fontSize: "1rem",
                  fontWeight: 600,
                  height: "3.5rem",
                  width: "8rem",
                }}
                value="Summary"
              >
                Summary
              </ToggleButton>
              <ToggleButton
                style={{
                  background: this.state.tab === "What-if" ? "#4472C4" : "",
                  color: this.state.tab === "What-if" ? "white" : "",
                  fontSize: "1rem",
                  fontWeight: 600,
                  height: "3.5rem",
                  width: "8rem",
                }}
                value="What-if"
              >
                What-if
              </ToggleButton>
            </ToggleButtonGroup>
          </Paper>
        </Grid>

        {/* chrats */}
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          style={{ paddingTop: "0.5rem" }}
        >
          <Grid item xs={12} md={6} style={{ padding: "0.5rem" }}>
            {this.props.funnelData !== "" &&
            this.props.updatingFunnelData == false ? (
              <Card raised={true}>
                {this.state.tab === "Summary" ? (
                  <CardHeader
                    disableTypography
                    title={`${
                      this.props.Win_Rate_Prefix
                    } by opportunity count: ${
                      this.props.funnelData !== "" &&
                      Math.round(
                        this.props.funnelData[this.props.funnelData.length - 1]
                          .rate * 100
                      )
                    }%`}
                  />
                ) : (
                  <CardHeader
                    disableTypography
                    title={`${this.props.Win_Rate_Prefix} by ${
                      this.props.Value_Label
                    }: ${
                      this.props.funnelData !== "" &&
                      Math.round(
                        this.props.funnelData[this.props.funnelData.length - 1]
                          .acvRate * 100
                      )
                    }%`}
                  />
                )}

                <Divider />
                <CardContent className={classes.content}>
                  <Grid
                    item
                    xs={12}
                    style={
                      this.props.funnelData.map((c) => c.label).length > 7
                        ? { height: "19rem" }
                        : { marginBottom: "1rem", height: "18rem" }
                    }
                  >
                    <div
                      style={{ display: "flex", width: "100%", height: "100%" }}
                    >
                      <div
                        style={{ marginTop: 20, width: "100%" }}
                        className="line-container"
                        ref={this._setRef.bind(this)}
                      />
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Loading raised={true} />
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "0.5rem" }}>
            {this.props.funnelData !== "" &&
            this.props.updatingFunnelData === false ? (
              <Card raised={true}>
                {
                  this.state.tab === "Summary" ? (
                    <CardHeader
                      disableTypography
                      title={`${this.props.Win_Rate_Prefix} by ${
                        this.props.Value_Label
                      }: ${
                        this.props.funnelData !== "" &&
                        Math.round(
                          this.props.funnelData[
                            this.props.funnelData.length - 1
                          ].acvRate * 100
                        )
                      }%`}
                    />
                  ) : this.state.showTeamData ? (
                    <CardHeader
                      disableTypography
                      title={`By ${this.props.Value_Label}-What if scneario: `}
                    />
                  ) : (
                    <CardHeader
                      disableTypography
                      title={`By ${this.props.Value_Label}-What if scneario`}
                    />
                  )
                  // ${this.state.whatifData !== '' && Math.round(funnelWhatIfDataFormatter(this.state.whatifData)[this.state.whatifData - 1].diffacvRateCompany * 100)}%
                }

                <Divider />
                <CardContent className={classes.content}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={
                      this.props.funnelData.map((c) => c.label).length > 7
                        ? { height: "19rem" }
                        : { marginBottom: "1rem", height: "18rem" }
                    }
                  >
                    <div
                      style={{ display: "flex", width: "100%", height: "100%" }}
                    >
                      <div
                        style={{ marginTop: 20, width: "100%" }}
                        className="line-container"
                        ref={this._setRef2.bind(this)}
                      />
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Loading raised={true} />
            )}
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          justify="space-between"
          style={{ paddingTop: "0.5rem" }}
        >
          {/* <Grid item xs={12} md={6} style={{padding:'0.5rem'}}>
                        {this.props.funnelData !== '' ?
                            <FunnelTableClosed data={this.props.funnelData} firstStage={this.props.funnelData[0].label} wonLabel={this.props.funnelData[this.props.funnelData.length-1].label} isAcv={this.state.tab === 'Summary' ? false : true} type={this.state.tab === 'Summary' ? "count" : ""} company={this.props.company} showTeamData={(this.state.repFilter.includes('All') && this.state.teamFilter.includes('All')) ? false : true}  
                            handleChangeLocation={this.handleChangeLocation} data_test="closed-funnel_opportunity_table" showTotal={this.state.tab === 'Summary' ? true : null}  /> :
                            <Loading raised={false} />}
                    </Grid> */}
          <Grid
            item
            xs={12}
            md={this.state.tab === "Summary" ? 6 : 12}
            style={{ padding: "0.1rem" }}
          >
            {this.props.funnelData !== "" ? (
              this.state.tab === "Summary" ? (
                <Grid item xs={12} md={12} style={{ padding: "0.5rem" }}>
                  <FunnelTableClosed
                    data={this.props.funnelData}
                    firstStage={this.props.funnelData[0].label}
                    wonLabel={
                      this.props.funnelData[this.props.funnelData.length - 1]
                        .label
                    }
                    isAcv={false}
                    type={"count"}
                    company={this.props.company}
                    handleChangeLocation={this.handleChangeLocation}
                    data_test="closed-funnel_opp_table"
                    showTeamData={
                      this.state.repFilter.includes("All") &&
                      this.state.teamFilter.includes("All")
                        ? false
                        : true
                    }
                    showTotal={true}
                    Win_Rate_Prefix={this.props.Win_Rate_Prefix}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={12} style={{ padding: "0.5rem" }}>
                  <FunnelTableClosedV2
                    data={funnelDataFormatter(
                      this.props.funnelData,
                      this.props.funnelData[this.props.funnelData.length - 1]
                        .label,
                      this.state.showTeamData
                    )}
                    firstStage={this.props.funnelData[0].label}
                    type={"acv"}
                    handleChangeLocation={this.handleChangeLocation}
                    data_test="closed-funnel_opp_table"
                    wonLabel={
                      this.props.funnelData[this.props.funnelData.length - 1]
                        .label
                    }
                    getdataValue={this.getdataValue}
                    showTeamData={
                      this.state.repFilter.includes("All") &&
                      this.state.teamFilter.includes("All")
                        ? false
                        : true
                    }
                    Win_Rate_Prefix={this.props.Win_Rate_Prefix}
                  />
                </Grid>
              )
            ) : (
              <Loading raised={false} />
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "0.5rem" }}>
            {this.props.funnelData !== "" ? (
              this.state.tab === "Summary" ? (
                <FunnelTableClosed
                  data={this.props.funnelData}
                  firstStage={this.props.funnelData[0].label}
                  wonLabel={
                    this.props.funnelData[this.props.funnelData.length - 1]
                      .label
                  }
                  isAcv={true}
                  type={"count"}
                  company={this.props.company}
                  handleChangeLocation={this.handleChangeLocation}
                  data_test="closed-funnel_acv_table"
                  showTeamData={
                    this.state.repFilter.includes("All") &&
                    this.state.teamFilter.includes("All")
                      ? false
                      : true
                  }
                  showTotal={true}
                  Win_Rate_Prefix={this.props.Win_Rate_Prefix}
                />
              ) : null
            ) : (
              <Loading raised={false} />
            )}
          </Grid>
        </Grid>

        {this.state.location === "drilldownOnGraph" &&
          this.state.selectedDrilldownStage !== "" && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={this.state.header}
                back={this.handleChangeLocationBack}
                filters={this.state.filtersForDrilldown}
              >
                <Drilldown
                  header={this.state.header}
                  Value_Label={this.props.Value_Label}
                  body={this.props.drilldownOnGraphData}
                  company={this.props.company}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                />
              </SingleLevelDrilldown>
            </Grid>
          )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

function mapStateToProps(state) {
  const {
    funnelFilters,
    funnelData,
    persistentAlert,
    user,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelFilters,
    funnelData,
    persistentAlert,
    company: user.company,
    Value_Label: user.Value_Label,
    Currency_Symbol: user.Currency,
    Win_Rate_Prefix: user.Win_Rate_Prefix,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    currentFilters,
    ExpectedAcvData,
  };
}

export const mapDispatchToPropsClosedFunnel = (dispatch) => ({
  getFunnelFilters: (company, geniBot = false) => {
    console.log(geniBot);
    dispatch({ type: "get_funnel_filters_request" });
    appService.getFunnelFiltersNew().then(
      (json) => {
        dispatch({ type: "get_funnel_filters_success", json, geniBot });
        dispatch({ type: "get_funnel_data_request" });
        const defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message.closedFiscalQuarters,
          "closed_fiscal_quarter"
        );
        const defaultOppType = getDefaultSalesType(json.message.Type, "Type");
        const defaultSolutionCat = getDefaultSalesType(
          json.message.solutionCat,
          "Solution_Cat"
        );
        let filters = [];
        if (defaultFiscalQuarter.length > 0) {
          filters = [{ name: "cfq", value: defaultFiscalQuarter }];
        }
        if (company === "C0008") {
          filters =
            getDefaultSalesType(json.message.salesType, "cross_sell1__c")[0] ===
            "All"
              ? [...filters]
              : [
                  ...filters,
                  {
                    name: "cross_sell1__c",
                    value: getDefaultSalesType(
                      json.message.salesType,
                      "cross_sell1__c"
                    ),
                  },
                ];
          filters =
            getDefaultSalesType(json.message.dealType, "Type")[0] === "All"
              ? [...filters]
              : [
                  ...filters,
                  {
                    name: "Type",
                    value: getDefaultSalesType(json.message.dealType, "Type"),
                  },
                ];
          filters =
            getDefaultSalesType(
              json.message.priorYearDealInclude,
              "Prior_Year_Opp_Manually_Inserted"
            )[0] === "All"
              ? [...filters]
              : [
                  ...filters,
                  {
                    name: "Prior_Year_Opp_Manually_Inserted",
                    value: getDefaultSalesType(
                      json.message.priorYearDealInclude,
                      "Prior_Year_Opp_Manually_Inserted"
                    ),
                  },
                ];
          //  filters = salesFilters === undefined ?   [...filters,salesFilters, dealtype]
        }

        if (defaultOppType[0] !== "All") {
          filters = [...filters, { name: "Type", value: defaultOppType }];
        }
        if (defaultSolutionCat[0] !== "All") {
          filters = [
            ...filters,
            { name: "solutionCat", value: defaultSolutionCat },
          ];
        }

        dispatch({ type: "current_selected_filters", filters });

        appService.getFunnelDataNew(filters).then(
          (json) => {
            dispatch({ type: "get_funnel_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_funnel_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_funnel_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_filters_failure", error });
      }
    );
  },
  getFunnelDataNew: (filters, geniBot = false) => {
    console.log(geniBot);
    dispatch({ type: "get_funnel_data_request", geniBot });
    dispatch({ type: "current_selected_filters", filters });
    appService.getFunnelDataNew(filters).then(
      (json) => {
        dispatch({ type: "get_funnel_data_success", json, geniBot });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_data_failure", error });
      }
    );
  },
  getClosedDrilldownOnGraph: (key, filters) => {
    console.log(key, filters);
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.closedDrilldownOnGraph(key, filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(TableRow);

const connectedFunnel = connect(
  mapStateToProps,
  mapDispatchToPropsClosedFunnel
)(withStyles(styles)(Funnel));
export { connectedFunnel as Funnel };
