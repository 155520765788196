import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import {
  StyledTableRow,
  countOrACVFormatter,
  percentFormatter,
} from "../../util/customFunctions";
import { CopyToClipboardTableContainer } from "../../components/Containers/CopyToClipboardTableContainer";
const useStyles = makeStyles((theme) => ({
  content: { width: "100%" },
  sticky: {
    position: "sticky",
    left: -1,
    background: "white",
    // outline: "0.5px solid lightgrey",
    // outlineOffset: "-1.5px",
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "1.5px", // Adjust the thickness as needed
      background: "lightgrey", // Adjust the color as needed
    },
  },
  stickyHeader: {
    position: "sticky",
    top: -1,
    background: "white",
    // outline: "0.2px solid lightgrey",
    // outlineOffset: "-1.5px",
    outline: "lightgrey solid 0.5px",
    outlineOffset: "-1.5px",
    zIndex: 5,
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px", // Adjust the thickness as needed
      // background: "lightgrey", // Adjust the color as needed
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));

const PipelineProgressionTable = (props) => {
  const classes = useStyles();
  const openStagesSet = props.data.openStagesSet.sort(
    (a, b) => props.data.sequenceStageMap[a] < props.data.sequenceStageMap[b]
  );
  console.log(openStagesSet, "openStagesSet ");
  const currentStageLength =
    (props.data.nonOpenStagesSet.length + openStagesSet.length) * 2;
  console.log(props);
  const availableStages = [
    ...new Set(props.data.data.map((it) => it.Stage)),
  ].sort(
    (stageA, stageB) =>
      props.data.sequenceStageMap[stageA] - props.data.sequenceStageMap[stageB]
  );
  console.log(availableStages, props.data.sequenceStageMap, "availableStages");
  return (
    <Grid container item justifyContent="center">
      <CopyToClipboardTableContainer
        datatest={`pipeline_progression_table_qtr${props.id + 1}`}
      >
        <React.Fragment style={{ padding: "1rem" }}>
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell
                style={{ background: "#ffe598" }}
                colSpan={currentStageLength}
                align="center"
                className={`${classes.fontBold} ${classes.repHeader}`}
              >
                Current Stage
              </TableCell>
              <TableCell> </TableCell>
              {/* Add other headers as needed */}
            </TableRow>
            <TableRow className={classes.fontBold}>
              <TableCell
                rowSpan={2}
                align="center"
                className={classes.repHeader}
              >
                Current Projected Close{" "}
                {String(props.data.timeline).includes("_Month")
                  ? "Month"
                  : "Qtr."}
              </TableCell>
              {/* {openStagesSet.map((stage, index) => (
                <TableCell
                  align="center"
                  colSpan={2}
                  style={
                    index % 2 === 0
                      ? {
                          background: "#4471c4",
                          color: "white",
                          minWidth: 100,
                        }
                      : {
                          background: "#5b9bd5",
                          color: "white",
                          padding: "10px 0",
                          minWidth: 100,
                        }
                  }
                  className={classes.repHeader}
                >
                  {stage}
                </TableCell>
              ))} */}

              {availableStages.map((stage, index) => {
                let style =
                  index % 2 === 0
                    ? {
                        background: "#4471c4",
                        color: "white",
                        minWidth: 100,
                      }
                    : {
                        background: "#5b9bd5",
                        color: "white",
                        padding: "10px 0",
                        minWidth: 100,
                      };
                if (stage === props.data.closedLostStage.stage_name) {
                  style.background = props.data.closedLostStage.color;
                } else if (stage === props.data.wonStage.stage_name) {
                  style.background = props.data.wonStage.color;
                }
                return (
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={style}
                    className={classes.repHeader}
                  >
                    {stage}
                  </TableCell>
                );
              })}
              <TableCell
                align="center"
                style={
                  1 % 2 === 0
                    ? {
                        background: "#4471c4",
                        color: "white",
                        minWidth: 100,
                      }
                    : {
                        background: "#5b9bd5",
                        color: "white",
                        padding: "10px 0",
                        minWidth: 100,
                      }
                }
                colSpan={2}
                className={classes.repHeader}
              >
                Grand Total
              </TableCell>
              <TableCell
                align="center"
                rowSpan={2}
                style={
                  2 % 2 === 0
                    ? {
                        background: "#4471c4",
                        color: "white",
                        minWidth: 50,
                      }
                    : {
                        background: "#5b9bd5",
                        color: "white",
                        padding: "10px 0",
                        minWidth: 50,
                      }
                }
                className={classes.repHeader}
              >
                % of Total
              </TableCell>
              {/* Add other headers as needed */}
            </TableRow>
            <TableRow className={classes.fontBold}>
              {/* {openStagesSet.map((stage, index) => (
                <>
                  <TableCell
                    align="center"
                    style={
                      index % 2 === 0
                        ? {
                            background: "#4471c4",
                            color: "white",
                            minWidth: 50,
                          }
                        : {
                            background: "#5b9bd5",
                            color: "white",
                            padding: "10px 0",
                            minWidth: 50,
                          }
                    }
                    className={classes.repHeader}
                  >
                    #
                  </TableCell>
                  <TableCell
                    align="center"
                    style={
                      index % 2 === 0
                        ? {
                            background: "#4471c4",
                            color: "white",
                            minWidth: 100,
                          }
                        : {
                            background: "#5b9bd5",
                            color: "white",
                            padding: "10px 0",
                            minWidth: 100,
                          }
                    }
                    className={classes.repHeader}
                  >
                    ACV
                  </TableCell>
                </>
              ))} */}

              {availableStages.map((stage, index) => {
                let style =
                  index % 2 === 0
                    ? {
                        background: "#4471c4",
                        color: "white",
                        minWidth: 50,
                      }
                    : {
                        background: "#5b9bd5",
                        color: "white",
                        padding: "10px 0",
                        minWidth: 50,
                      };
                if (stage === props.data.closedLostStage.stage_name) {
                  style.background = props.data.closedLostStage.color;
                } else if (stage === props.data.wonStage.stage_name) {
                  style.background = props.data.wonStage.color;
                }
                return (
                  <>
                    <TableCell
                      align="center"
                      style={style}
                      className={classes.repHeader}
                    >
                      #
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ ...style, minWidth: 100 }}
                      className={classes.repHeader}
                    >
                      {/* ACV */}
                      {props.Value_Label}
                    </TableCell>
                  </>
                );
              })}
              <TableCell
                align="center"
                style={
                  1 % 2 === 0
                    ? {
                        background: "#4471c4",
                        color: "white",
                        minWidth: 50,
                      }
                    : {
                        background: "#5b9bd5",
                        color: "white",
                        padding: "10px 0",
                        minWidth: 50,
                      }
                }
                className={classes.repHeader}
              >
                #
              </TableCell>
              <TableCell
                align="center"
                style={
                  1 % 2 === 0
                    ? {
                        background: "#4471c4",
                        color: "white",
                        minWidth: 100,
                      }
                    : {
                        background: "#5b9bd5",
                        color: "white",
                        padding: "10px 0",
                        minWidth: 100,
                      }
                }
                className={classes.repHeader}
              >
                {/* ACV */}
                {props.Value_Label}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.pcfqListSet.map((qtr) => {
              const totalQtr = props.data.totalForQtrs.filter(
                (it) => it[props.data.timeline] === qtr
              )[0];
              return (
                <StyledTableRow>
                  <TableCell
                    align="left"
                    className={[classes.repText, classes.fontBold].join(" ")}
                  >
                    {qtr}
                  </TableCell>
                  {/* {openStagesSet.map((stage) => {
                    const data = props.data.data.filter(
                      (it) =>
                        it[props.data.timeline] === qtr &&
                        it.Stage === stage
                    )[0];
                    console.log(data, "pipelineDebug");
                    return (
                      <>
                        <TableCell
                          style={
                            data && data.pastQuarter === true
                              ? {
                                  background:
                                    props.data.chartConfig
                                      .openOppsHighlightColor,
                                }
                              : {}
                          }
                          onClick={() =>
                            props.handleChangeLocation(
                              [stage],
                              [qtr],
                              props.data[props.data.fqType],props.data.fqType,props.data.timeline
                            )
                          }
                          className={`${classes.repNumber} ${classes.drilldownCell}`}
                        >
                          {data
                            ? countOrACVFormatter(data.Opp_Count, false)
                            : ""}
                        </TableCell>
                        <TableCell
                          style={
                            data && data.pastQuarter === true
                              ? {
                                  background:
                                    props.data.chartConfig
                                      .openOppsHighlightColor,
                                }
                              : {}
                          }
                          onClick={() =>
                            props.handleChangeLocation(
                              [stage],
                              [qtr],
                              props.data[props.data.fqType],props.data.fqType,props.data.timeline
                            )
                          }
                          className={`${classes.repNumber} ${classes.drilldownCell}`}
                        >
                          {data ? countOrACVFormatter(data.ACV, true) : ""}
                        </TableCell>
                      </>
                    );
                  })} */}
                  {availableStages.map((stage) => {
                    const data = props.data.data.filter(
                      (it) =>
                        it[props.data.timeline] === qtr && it.Stage === stage
                    )[0];
                    return (
                      <>
                        <TableCell
                          onClick={() =>
                            props.handleChangeLocation(
                              [stage],
                              [qtr],
                              props.data[props.data.fqType],
                              props.data.fqType,
                              props.data.timeline
                            )
                          }
                          className={`${classes.repNumber} ${classes.drilldownCell}`}
                          style={
                            data && data.pastQuarter === true
                              ? {
                                  background:
                                    props.data.chartConfig
                                      .openOppsHighlightColor,
                                }
                              : {}
                          }
                        >
                          {data
                            ? countOrACVFormatter(data.Opp_Count, false)
                            : ""}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            props.handleChangeLocation(
                              [stage],
                              [qtr],
                              props.data[props.data.fqType],
                              props.data.fqType,
                              props.data.timeline
                            )
                          }
                          className={`${classes.repNumber} ${classes.drilldownCell}`}
                          style={
                            data && data.pastQuarter === true
                              ? {
                                  background:
                                    props.data.chartConfig
                                      .openOppsHighlightColor,
                                }
                              : {}
                          }
                        >
                          {data ? countOrACVFormatter(data.ACV, true) : ""}
                        </TableCell>
                      </>
                    );
                  })}
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        [...openStagesSet, ...props.data.nonOpenStagesSet],
                        [qtr],
                        props.data[props.data.fqType],
                        props.data.fqType,
                        props.data.timeline
                      )
                    }
                    className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
                  >
                    {totalQtr
                      ? countOrACVFormatter(totalQtr.Opp_Count, false)
                      : ""}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        [...openStagesSet, ...props.data.nonOpenStagesSet],
                        [qtr],
                        props.data[props.data.fqType],
                        props.data.fqType,
                        props.data.timeline
                      )
                    }
                    className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
                  >
                    {totalQtr ? countOrACVFormatter(totalQtr.ACV, true) : ""}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      props.handleChangeLocation(
                        [...openStagesSet, ...props.data.nonOpenStagesSet],
                        [qtr],
                        props.data[props.data.fqType],
                        props.data.fqType,
                        props.data.timeline
                      )
                    }
                    className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
                  >
                    {totalQtr ? percentFormatter(totalQtr.percent) : ""}
                  </TableCell>
                </StyledTableRow>
              );
            })}
            <StyledTableRow>
              <TableCell
                className={`${classes.fontBold} ${classes.repText}`}
                align="left"
              >
                Grand Total
              </TableCell>
              {/* {openStagesSet.map((stage) => {
                const data = props.data.totalForStages.filter(
                  (it) => it.Stage === stage
                )[0];
                return (
                  <>
                    <TableCell
                      onClick={() =>
                        props.handleChangeLocation(
                          [stage],
                          [...props.data.pcfqListSet],
                          props.data[props.data.fqType],props.data.fqType,props.data.timeline
                        )
                      }
                      className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
                      align="right"
                    >
                      {data ? countOrACVFormatter(data.Opp_Count, false) : ""}
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        props.handleChangeLocation(
                          [stage],
                          [...props.data.pcfqListSet],
                          props.data[props.data.fqType],props.data.fqType,props.data.timeline
                        )
                      }
                      className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
                      align="right"
                    >
                      {data ? countOrACVFormatter(data.ACV, true) : ""}
                    </TableCell>
                  </>
                );
              })} */}
              {availableStages.map((stage) => {
                const data = props.data.totalForStages.filter(
                  (it) => it.Stage === stage
                )[0];
                return (
                  <>
                    <TableCell
                      onClick={() =>
                        props.handleChangeLocation(
                          [stage],
                          [...props.data.pcfqListSet],
                          props.data[props.data.fqType],
                          props.data.fqType,
                          props.data.timeline
                        )
                      }
                      className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
                      align="right"
                    >
                      {data ? countOrACVFormatter(data.Opp_Count, false) : ""}
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        props.handleChangeLocation(
                          [stage],
                          [...props.data.pcfqListSet],
                          props.data[props.data.fqType],
                          props.data.fqType,
                          props.data.timeline
                        )
                      }
                      className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
                      align="right"
                    >
                      {data ? countOrACVFormatter(data.ACV, true) : ""}
                    </TableCell>
                  </>
                );
              })}
              <TableCell
                onClick={() =>
                  props.handleChangeLocation(
                    [...openStagesSet, ...props.data.nonOpenStagesSet],
                    [...props.data.pcfqListSet],
                    props.data[props.data.fqType],
                    props.data.fqType,
                    props.data.timeline
                  )
                }
                className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
              >
                {props.data.GrandTotal
                  ? countOrACVFormatter(props.data.GrandTotalCount, false)
                  : ""}
              </TableCell>
              <TableCell
                onClick={() =>
                  props.handleChangeLocation(
                    [...openStagesSet, ...props.data.nonOpenStagesSet],
                    [...props.data.pcfqListSet],
                    props.data[props.data.fqType],
                    props.data.fqType,
                    props.data.timeline
                  )
                }
                className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
              >
                {props.data.GrandTotal
                  ? countOrACVFormatter(props.data.GrandTotal, true)
                  : ""}
              </TableCell>
              <TableCell
                onClick={() =>
                  props.handleChangeLocation(
                    [...openStagesSet, ...props.data.nonOpenStagesSet],
                    [...props.data.pcfqListSet],
                    props.data[props.data.fqType],
                    props.data.fqType,
                    props.data.timeline
                  )
                }
                className={` ${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
              >
                {props.data.GrandTotal ? percentFormatter(1) : ""}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell className={`${classes.fontBold} ${classes.repHeader}`}>
                % of Total
              </TableCell>
              {/* {openStagesSet.map((stage) => {
                const data = props.data.totalForStages.filter(
                  (it) => it.Stage === stage
                )[0];
                return (
                  <>
                    <TableCell
                      className={`${classes.fontBold} ${classes.repNumber}`}
                    ></TableCell>
                    <TableCell
                      style={
                        data.highlight === true ? { background: "#b4c6e7" } : {}
                      }
                      onClick={() =>
                        props.handleChangeLocation(
                          [stage],
                          [...props.data.pcfqListSet],
                          props.data[props.data.fqType],props.data.fqType,props.data.timeline
                        )
                      }
                      className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
                    >
                      {data ? percentFormatter(data.percent) : ""}
                    </TableCell>
                  </>
                );
              })} */}
              {availableStages.map((stage) => {
                const data = props.data.totalForStages.filter(
                  (it) => it.Stage === stage
                )[0];
                console.log(data.highlight, "data.highlight");
                return (
                  <>
                    <TableCell
                      className={`${classes.fontBold} ${classes.repNumber}`}
                    ></TableCell>
                    <TableCell
                      onClick={() =>
                        props.handleChangeLocation(
                          [stage],
                          [...props.data.pcfqListSet],
                          props.data[props.data.fqType],
                          props.data.fqType,
                          props.data.timeline
                        )
                      }
                      style={
                        data.highlight === true ? { background: "#b4c6e7" } : {}
                      }
                      className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
                    >
                      {data ? percentFormatter(data.percent) : ""}
                    </TableCell>
                  </>
                );
              })}
              <>
                <TableCell
                  className={`${classes.fontBold} ${classes.repNumber}`}
                ></TableCell>
                <TableCell
                  onClick={() =>
                    props.handleChangeLocation(
                      [...openStagesSet, ...props.data.nonOpenStagesSet],
                      [...props.data.pcfqListSet],
                      props.data[props.data.fqType],
                      props.data.fqType,
                      props.data.timeline
                    )
                  }
                  className={`${classes.fontBold} ${classes.drilldownCell} ${classes.repNumber}`}
                >
                  {props.data.GrandTotal ? percentFormatter(1) : ""}
                </TableCell>
              </>
            </StyledTableRow>
          </TableBody>
        </React.Fragment>
      </CopyToClipboardTableContainer>
    </Grid>
  );
};

export default PipelineProgressionTable;
