import React, { Fragment } from "react";
import ReactGA from "react-ga";
import InsightsCard from "../components/InsightsCard";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../App/app.service";

import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import GeneratePDF from "../Dashboard/GeneratePdf";

const styles = (theme) => ({
  dashOuter: {
    display: "flex",
    flexGrow: 1,
    margin: "auto",
  },
  dashInner: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  graph1: {
    flexGrow: 1,
    height: 250,
    margin: 10,
  },
});

class QuotaLetters extends React.Component {
  //   componentDidMount() {
  //     this.props.getSettings();
  //   }
  //   handleLocationChange = (loc) => {
  //     this.props.changeEnhancedInsightsPage(loc);
  //   };

  handleLocationChange = (loc) => {
    console.log("Location_Page".loc);
    this.props.changeSettingsPage(loc);
  };

  render() {
    const { classes } = this.props;
    console.log("this.props.location", this.props.location);
    const baseURL = "https://skygeniwebapp.blob.core.windows.net/thumbnails/";
    var charts = [
      {
        title: "Generate Quota Letters",
        component: (
          <InsightsCard
            header="Generate Quota Letters"
            text="Generate customized quota letters for all reps in a fully automated manner."
            image={`${baseURL}Rep Performance.png`}
            changeLocation={this.handleLocationChange}
          />
        ),
      },
    ];

    return (
      <Fragment>
        <div
          className={classes.dashOuter}
          style={
            this.props.location === "home"
              ? { maxWidth: 1100 }
              : { maxWidth: window.innerWidth <= 1368 ? 1200 : 1600 }
          }
        >
          {this.props.location === "home" && (
            <Grid
              container
              className={classes.grid}
              wrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              {charts !== undefined &&
                this.props.charts.map((it) =>
                  charts.filter((chart) => chart.title === it.Chart_Name)[0] !==
                  undefined
                    ? charts.filter((chart) => chart.title === it.Chart_Name)[0]
                        .component
                    : null
                )}
              {/* {charts !== undefined && charts[0].component} */}
            </Grid>
          )}
          {this.props.location === "Generate Quota Letters" && (
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12}>
                <GeneratePDF />
              </Grid>
            </Grid>
          )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    persistentAlert,
    alert,
    version,
    quotaGenerationPage,
    QuotaDatapdf,
    QuotaUrls,
    user,
  } = state.app;

  return {
    persistentAlert,
    location: quotaGenerationPage,
    alert,
    version,
    QuotaDatapdf,
    QuotaUrls,
    charts: user.elements
      .filter((item) => item.navItem === "Sales Compensation")[0]
      .charts.sort((a, b) => a.Sequence - b.Sequence),
  };
}

const mapDispatchToProps = (dispatch) => ({
  changeSettingsPage: (loc) => {
    ReactGA.pageview(loc);
    dispatch({ type: "change_QuotasPage", loc });
    localStorage.setItem("skygeni-user-quotaGenerationPage", loc);
    localStorage.removeItem("skygeni-user-repPage");
    localStorage.removeItem("skygeni-user-productPage");
  },
});

const connectedSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QuotaLetters));
export { connectedSettings as QuotaLetters };
