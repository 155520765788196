import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useState, useEffect, useRef } from "react";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";

import StyledTableRow from "../../components/StyledTableRow";
import * as d3 from "d3";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const useStyle = makeStyles((theme) => ({
  root: {},
  sticky: {
    position: "sticky",
    left: -1,
    background: "white",
    // outline: "0.5px solid lightgrey",
    // outlineOffset: "-1.5px",
    //  pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px", // Adjust the thickness as needed
      background: "lightgrey", // Adjust the color as needed
    },
  },
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    width: "100%",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    //minWidth: "169px",
    fontWeight: "inherit",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: "inherit",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function PCDMovedByQuartersTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  let total;

  let componentData = props.tableData.trendsData;
  let tableData = componentData.FQMovedResults;
  const debug_key = "trends_debug";

  const uniqueQuarters = componentData.fisaclQuarterSet.sort();

  let uniqueQuarterMoved = [
    ...componentData.qtrsMovedSet
      .filter((it) => it !== "Grand Total")
      .sort((a, b) => a - b),
    "Grand Total",
  ];
  console.log(debug_key, uniqueQuarters, tableData);
  let uniqueFQ = uniqueQuarterMoved;
  uniqueQuarterMoved = [...uniqueQuarterMoved];
  console.log("debug_quarter_moved", uniqueQuarterMoved);
  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-trends");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="center">
          {/* <Grid style={{ padding: "1rem" }} item xs={12}> */}
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id="myTableContainer-trends"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              ref={tableRef}
              data-test={"pcd-table-quarters-moved"}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    rowSpan={2}
                    style={{ minWidth: 120, maxWidth: 120 }}
                  >
                    Qtrs. Moved
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 100,
                                maxWidth: 100,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                minWidth: 100,
                                maxWidth: 100,
                              }
                        }
                        className={classes.repHeader}
                        colSpan={2}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  {/* <TableCell
                      align="center"
                      className={classes.repHeader}
                      colSpan={1}
                    >
                    </TableCell> */}
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <>
                        <TableCell
                          align="center"
                          style={{
                            // background: "#4471c4",
                            // color: "white",
                            minWidth: 70,
                            maxWidth: 70,
                          }}
                          className={classes.repHeader}
                        >
                          # of Opps
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            // background: "#4471c4",
                            // color: "white",
                            minWidth: 100,
                            maxWidth: 100,
                          }}
                          className={classes.repHeader}
                        >
                          {props.Value_Label}
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>

                {uniqueQuarterMoved
                  .filter((it) => it !== "Grand Total")
                  .map((row, i) => {
                    total = i + 1;
                    return (
                      <StyledTableRow key={i}>
                        <TableCell
                          align="left"
                          // className={classes.repText}
                          // style={{
                          //   textAlign: row !== "Grand Total" ? "right" : "left",
                          //   paddingRight: "3rem",
                          // }}
                          colSpan={1}
                          className={
                            isSticky
                              ? `${classes.sticky} ${classes.repText}`
                              : classes.repText
                          }
                          style={{
                            ...(isSticky && i % 2 === 1
                              ? { background: "#f5f5f5" }
                              : {}),
                            textAlign: row !== "Grand Total" ? "right" : "left",
                            paddingRight: "3rem",
                          }}
                        >
                          {row}
                        </TableCell>
                        {uniqueQuarters.map((cell, j) => {
                          let rowData = tableData.filter(
                            (item) =>
                              item["fiscal_quarter"] == cell &&
                              item["qtrsMoved"] == row
                          );
                          console.log("debug_drilldown", rowData);
                          rowData =
                            rowData.length <= 0
                              ? { opps: 0, acv: 0, quarters: [] }
                              : rowData[0];
                          const oldQ = [];
                          const newQ = [];

                          rowData.quarters.map((qtrString) => {
                            const [oldQtr, newQtr] = qtrString.split(",");
                            oldQ.push(oldQtr);
                            newQ.push(newQtr);
                          });
                          console.log(
                            newQ,
                            oldQ,
                            row,
                            cell,
                            "drilldown_filters",
                            "debug_drilldown"
                          );
                          return (
                            <>
                              <TableCell
                                align="left"
                                className={classes.repNumber}
                                colSpan={1}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    {
                                      oldQtr: oldQ,
                                      newQtr: newQ,
                                      quarters: rowData.quarters,
                                    },
                                    "actual",
                                    true,
                                    [cell]
                                  )
                                }
                              >
                                {rowData.opps}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.repNumber}
                                colSpan={1}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    {
                                      oldQtr: oldQ,
                                      newQtr: newQ,
                                      quarters: rowData.quarters,
                                    },
                                    "actual",
                                    true,
                                    [cell]
                                  )
                                }
                              >
                                {countOrACVFormatter(rowData.acv, true)}
                              </TableCell>
                            </>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}

                <StyledTableRow>
                  <TableCell
                    align="left"
                    // className={classes.repText}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repText}`
                        : classes.repText
                    }
                    style={
                      isSticky && total % 2 === 1
                        ? { background: "#f5f5f5" }
                        : {}
                    }
                    colSpan={1}
                  >
                    {`Grand Total`}
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    let rowData = tableData.filter(
                      (item) =>
                        item.qtrsMoved === "Grand Total" &&
                        item.fiscal_quarter == cell
                    );
                    console.log(rowData, "debig_drilldown");
                    rowData =
                      rowData.length <= 0
                        ? { opps: 0, acv: 0, quarters: [] }
                        : {
                            opps: rowData.reduce((a, b) => a + b.opps, 0),
                            acv: rowData.reduce((a, b) => a + b.acv, 0),
                            quarters: rowData.reduce(
                              (a, b) => [...a, ...b.quarters],
                              []
                            ),
                          };
                    const oldQ = [];
                    const newQ = [];

                    rowData.quarters.map((qtrString) => {
                      const [oldQtr, newQtr] = qtrString.split(",");
                      oldQ.push(oldQtr);
                      newQ.push(newQtr);
                    });

                    return (
                      <>
                        <TableCell
                          align="left"
                          className={classes.repNumber}
                          colSpan={1}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                oldQtr: oldQ,
                                newQtr: newQ,
                                quarters: rowData.quarters,
                              },
                              "actual",
                              true,
                              [cell]
                            )
                          }
                        >
                          {rowData.opps}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.repNumber}
                          colSpan={1}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                oldQtr: oldQ,
                                newQtr: newQ,
                                quarters: rowData.quarters,
                              },
                              "actual",
                              true,
                              [cell]
                            )
                          }
                        >
                          {countOrACVFormatter(rowData.acv, true)}
                        </TableCell>
                      </>
                    );
                  })}
                </StyledTableRow>

                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    align="left"
                    // className={classes.repText}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repText}`
                        : classes.repText
                    }
                    style={
                      isSticky && (total + 1) % 2 === 1
                        ? { background: "#f5f5f5" }
                        : {}
                    }
                    colSpan={1}
                  >
                    {`Total (>=1 Qtr.)`}
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    let rowData = tableData.filter(
                      (item) =>
                        item.fiscal_quarter === cell && item.qtrsMoved >= 1
                    );

                    rowData =
                      rowData.length <= 0
                        ? { opps: 0, acv: 0, quarters: [] }
                        : {
                            opps: rowData.reduce((a, b) => a + b.opps, 0),
                            acv: rowData.reduce((a, b) => a + b.acv, 0),
                            quarters: rowData.reduce(
                              (a, b) => [...a, ...b.quarters],
                              []
                            ),
                          };
                    console.log(rowData, "GTE1QTR");
                    const oldQ = [];
                    const newQ = [];

                    rowData.quarters.map((qtrString) => {
                      const [oldQtr, newQtr] = qtrString.split(",");
                      oldQ.push(oldQtr);
                      newQ.push(newQtr);
                    });
                    return (
                      <>
                        <TableCell
                          align="left"
                          className={classes.repNumber}
                          colSpan={1}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                oldQtr: oldQ,
                                newQtr: newQ,
                                quarters: rowData.quarters,
                              },
                              "actual",
                              true,
                              [cell]
                            )
                          }
                        >
                          {rowData.opps}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.repNumber}
                          colSpan={1}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                oldQtr: oldQ,
                                newQtr: newQ,
                                quarters: rowData.quarters,
                              },
                              "actual",
                              true,
                              [cell]
                            )
                          }
                        >
                          {countOrACVFormatter(rowData.acv, true)}
                        </TableCell>
                      </>
                    );
                  })}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* </Grid> */}
        </Grid>
      </CardContent>
    </div>
  );
}
