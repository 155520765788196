import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { countOrACVFormatter, StyledTableRow } from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import CopyTablesObjectv2 from "../../components/xlsx-export/CopyTablesObjectv2";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      margin: "0.5rem",
      padding: "0.5rem",
    },
    sticky: {
      position: "sticky",
      left: -1,
      background: "white",
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "0.5px",
        background: "lightgrey",
      },
    },
    colHeaderNew: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      fontWeight: "inherit",
      backgroundColor: "inherit",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      fontWeight: 600,
      cursor: "pointer",
    },
    dataCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      fontWeight: "inherit",
      // width: '120px'
    },
    dataCell2: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      fontWeight: "inherit",
    },
    drilldownCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    drilldownCellNQP: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: "3%",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    cellMargin: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      fontWeight: "inherit",
      paddingLeft: "2rem",
    },
    fontBold: {
      fontWeight: 600,
    },
    indentName: {
      paddingLeft: 30,
    },
    noBorder: {
      border: "none",
    },
    primaryColumnColor: {
      backgroundColor: "#4472C4",
      color: "white",
    },
  }),
  { index: 1 }
);

function DataTablev2({ columns, data, dataTest }) {
  const classes = useStyles();
  const tableRef = useRef();
  const tableContainerRef = useRef();

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [isSticky, setIsSticky] = useState(false);
  const [rowPin, setrowPin] = useState(false);
  const [parentWidth, setparentWidth] = useState();

  const handleSort = (field) => {
    let direction = 'asc';
    if (sortConfig.key === field && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: field, direction });
  };

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle null or undefined values
      aValue = aValue ?? '';
      bValue = bValue ?? '';

      // Numeric sorting
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // Date sorting
      if (columns.find(col => col.field === sortConfig.key)?.type === 'date') {
        return sortConfig.direction === 'asc'
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      // String sorting
      return sortConfig.direction === 'asc'
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });
  }, [data, sortConfig, columns]);

  useEffect(() => {
      const tableContainer = tableContainerRef.current;
  
      const handleScroll = () => {
        if (tableContainer.scrollLeft > 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
        if (tableContainer.scrollTop > 0) {
          setrowPin(true);
        } else {
          setrowPin(false);
        }
      };
  
      tableContainer.addEventListener("scroll", handleScroll);
  
      return () => {
        tableContainer.removeEventListener("scroll", handleScroll);
      };
    }, [classes.sticky]);

      useEffect(() => {
        const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
        setparentWidth(parentWidth);
      }, [parentWidth]);
  
    return (
      <div style={{ maxWidth: "100%", justifyContent: "center", padding: "2rem", paddingTop: 0 }}>
         <Grid container spacing={2} alignItems="center">
          <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Typography align="center" variant="h2" style={{ fontWeight: "600" }}>
          Sold to the following accounts
          </Typography>
        </Grid>
        <Grid item xs={1} style={{ textAlign: "right" }}>
          {parentWidth !== undefined && <CopyTablesObjectv2 tableRef={tableRef} />}
        </Grid>
      </Grid>
        <TableContainer
           style={{
            width: "auto",
            borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            borderTop: rowPin ? "0.5px solid lightgrey" : "",
            maxHeight: "85vh",
            paddingBottom: '1rem'
          }}
          id="myTableContainer-Second"
          ref={tableContainerRef}
        >
          <Table ref={tableRef} data-test={dataTest}>
            <TableHead>
              <TableRow>
              <TableCell
        key={'123x123'}
        align="center"
        className={`${classes.sticky} ${classes.colHeader}`}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 6, // Ensure it stays above other cells when scrolling
          backgroundColor: "#4471c4" ,
          color: "white",
          cursor: "default"
        }}
      >
        #
        </TableCell>
    {columns.map((col, idx) => (
      <TableCell
        key={idx}
        align="center"
        onClick={() => handleSort(col.field)}
        className={`${classes.sticky} ${classes.colHeader}`}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 6, // Ensure it stays above other cells when scrolling
          backgroundColor: idx % 2 === 0 ? "#5b9bd5" : "#4471c4",
          color: "white",
          width: col.label === 'Product' && '45rem'
        }}
      >
        {col.label}
        <TableSortLabel
          active={sortConfig.key === col.field}
          direction={sortConfig.direction}
        />
      </TableCell>
    ))}
  </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, idx) => (
                <StyledTableRow key={idx}>
                     <TableCell key={idx} className={classes.dataCell2} align="center"
                     >
                     {idx+1}
                    </TableCell>
                  {columns.map((col, colIdx) => (
                    <TableCell key={colIdx} className={classes.dataCell} align={col.type === 'string' ? "left" : "right"}
                    style={{width : col.label === 'SkyGeni estimate of Propensity to Buy' && '120px'}}
                    >
                      {col.field === "List_price" 
                        ? countOrACVFormatter(row[col.field], true)
                        : row[col.field] || "-"}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
               <StyledTableRow>
                     <TableCell className={classes.dataCell} style={{fontWeight: 600, border: "0.5px solid lightgrey"}}>
                    
                    </TableCell>
                    <TableCell className={classes.dataCell} style={{fontWeight: 600, border: "0.5px solid lightgrey"}}>
                    Total
                    </TableCell>
                    <TableCell className={classes.dataCell} style={{fontWeight: 600, border: "0.5px solid lightgrey"}} align="right">
                    {
                        data.length > 0 ? countOrACVFormatter(data.length, false) : '-'
                    }
                    </TableCell>
                    <TableCell className={classes.dataCell} style={{fontWeight: 600, border: "0.5px solid lightgrey"}}>
                    
                    </TableCell>
                    <TableCell className={classes.dataCell} style={{fontWeight: 600, border: "0.5px solid lightgrey"}}>
                    
                    </TableCell>
                 
                </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  
  export default DataTablev2;
  