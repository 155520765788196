import { capitalize, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import NoDataCell from '../../AccountHealth/components/NoDataCell'
import tableStyle from '../../../styles/tableStyle'
import { countOrACVFormatter, countOrACVFormatterThousand, getUniqueValues, _isNaN } from '../../../../util/customFunctions'
import StyledTableRow from '../../../../components/StyledTableRow'
export default function PriceIncreaseTable(props) {

    const classes = tableStyle()
    const columns = props.data.map(item => item[props.column]).filter(getUniqueValues)

    const higherRows = props.data.map(item => item[props.higherRows]).filter(getUniqueValues).sort()
    const rowSecondLevel = props.data.map(item => item[props.rowSecondLevel]).filter(getUniqueValues)
    const drilldownHandler = (location, filters, metaData) => {
        let drilldownFilter;
        if (metaData !== 'disable') {
            if (props.title === "priceIncrease") {

                drilldownFilter = [
                    { name: props.column, value: typeof (filters.col) === "object" ? filters.col : [filters.col], string: true },
                    { name: props.higherRows, value: typeof (filters.row) === "object" ? filters.row : [filters.row], string: true },
                    { name: props.rowSecondLevel, value: typeof (filters.rowSecond) === "object" ? filters.rowSecond : [filters.rowSecond], string: true },
                ]

                props.handleChangeLocation("drilldownOnGraph", drilldownFilter)
            }
        }

    }

    return (
        <TableContainer className={classes.root}>
            <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                    {
                        props.data.length > 0 ? <>
                            <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black', paddingTop: 15, paddingBottom: 15 }}>{props.column.split("_").map(item => capitalize(item)).join(" ")}</TableCell>
                                {columns.map((item, index) => <TableCell colSpan={4} align="center" style={index % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} className={classes.colHeader}>{item}</TableCell>)}
                                {columns.length > 1 && <TableCell colSpan={4} align="center" style={columns.length > 1 && columns.length % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} className={classes.colHeader}>{`Total`}</TableCell>}
                            </TableRow>
                            <StyledTableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black' }}></TableCell>
                                {columns.map(item => <>
                                    <TableCell align="center" className={classes.colHeader}>{`${props.countColumnHeading !== undefined ? props.countColumnHeading : `# of Ren.`}`}</TableCell>
                                    <TableCell align="center" className={classes.colHeader}>{`${props.currencyColumnHeading !== undefined ? props.currencyColumnHeading : 'Renewal Value'}`}</TableCell>
                                    <TableCell align="center" className={classes.colHeader}>{`${props.currencyColumnHeading !== undefined ? props.currencyColumnHeading : 'ACV'}`}</TableCell>
                                    <TableCell align="center" className={classes.colHeader}>{`Price Inc. %`}</TableCell>
                                </>)}
                                {
                                    columns.length > 1 &&
                                    <>
                                        <TableCell align="center" className={classes.colHeader}>{`${props.countColumnHeading !== undefined ? props.countColumnHeading : `# of Ren.`}`}</TableCell>
                                        <TableCell align="center" className={classes.colHeader}>{`${props.currencyColumnHeading !== undefined ? props.currencyColumnHeading : 'Renewal Value'}`}</TableCell>
                                        <TableCell align="center" className={classes.colHeader}>{`${props.currencyColumnHeading !== undefined ? props.currencyColumnHeading : 'ACV'}`}</TableCell>
                                        <TableCell align="center" className={classes.colHeader}>{`Price Inc. %`}</TableCell>
                                    </>
                                }
                            </StyledTableRow>
                            {
                                higherRows.map(higherRow => <>
                                    <StyledTableRow style={{ fontWeight: 600 }} key={higherRow}>
                                        <TableCell style={{ minWidth: '13rem' }} className={classes.dataCell}>{higherRow}</TableCell>
                                        {
                                            columns.map(col => {
                                                const cellData = props.data.filter(item => item[props.column] === col && item[props.higherRows] === higherRow)
                                                const renewals = cellData.reduce((a, b) => a + b.count, 0)
                                                const renewalsValue = cellData.reduce((a, b) => a + b.Renewal_Value, 0)
                                                const renewalsAcv = cellData.reduce((a, b) => a + b.acv, 0)
                                                // console.log(renewals, renewalsValue, colTotal)
                                                return <>
                                                    <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRow, rowSecond: rowSecondLevel })} align="right" className={classes.drilldownCell}>{countOrACVFormatter(renewals, false)}</TableCell>
                                                    <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRow, rowSecond: rowSecondLevel })} align="right" className={classes.drilldownCell}>{countOrACVFormatterThousand(renewalsValue, true)}</TableCell>
                                                    <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRow, rowSecond: rowSecondLevel }, 'disable')} align="right" className={classes.dataCell}>{countOrACVFormatterThousand(renewalsAcv, true)}</TableCell>
                                                    <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRow, rowSecond: rowSecondLevel })} align="right" className={classes.drilldownCell}>{_isNaN(Math.round((renewalsAcv / renewalsValue) * 100)) ? `0%` : `${((renewalsAcv / renewalsValue) * 100).toFixed(1)}%`}</TableCell>
                                                </>
                                            })
                                        }
                                        {
                                            columns.length > 1 &&
                                            <>
                                                <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRow, rowSecond: rowSecondLevel })} align="right" className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.higherRows] === higherRow).reduce((a, b) => a + b.count, 0), false)}</TableCell>
                                                <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRow, rowSecond: rowSecondLevel })} align="right" className={classes.drilldownCell}>{countOrACVFormatterThousand(props.data.filter(item => item[props.higherRows] === higherRow).reduce((a, b) => a + b.Renewal_Value, 0), true)}</TableCell>
                                                <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRow, rowSecond: rowSecondLevel }, 'disable')} align="right" className={classes.dataCell}>{countOrACVFormatterThousand(props.data.filter(item => item[props.higherRows] === higherRow).reduce((a, b) => a + b.acv, 0), true)}</TableCell>
                                                <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRow, rowSecond: rowSecondLevel })} align="right" className={classes.drilldownCell}>{_isNaN(Math.round((props.data.filter(item => item[props.higherRows] === higherRow).reduce((a, b) => a + b.acv, 0) / props.data.filter(item => item[props.higherRows] === higherRow).reduce((a, b) => a + b.Renewal_Value, 0)) * 100)) ? '0%' : `${((props.data.filter(item => item[props.higherRows] === higherRow).reduce((a, b) => a + b.acv, 0) / props.data.filter(item => item[props.higherRows] === higherRow).reduce((a, b) => a + b.Renewal_Value, 0)) * 100).toFixed(1)}%`}</TableCell>
                                            </>
                                        }
                                    </StyledTableRow>
                                    {rowSecondLevel.map(rowSecond => <StyledTableRow>
                                        <TableCell className={classes.cellMargin}>{parseInt(rowSecond) === 0 ? "No price Increase" : "Price Increase"}</TableCell>
                                        {
                                            columns.map(col => {
                                                const cellData = props.data.filter(item => item[props.column] === col && item[props.higherRows] === higherRow && item[props.rowSecondLevel] === rowSecond)
                                                const renewals = cellData.reduce((a, b) => a + b.count, 0)
                                                const renewalsValue = cellData.reduce((a, b) => a + b.Renewal_Value, 0)
                                                const renewalsAcv = cellData.reduce((a, b) => a + b.acv, 0)

                                                return <>
                                                    <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRow, rowSecond })} align="right" className={classes.drilldownCell}>{countOrACVFormatter(renewals, false)}</TableCell>
                                                    <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRow, rowSecond })} align="right" className={classes.drilldownCell}>{countOrACVFormatterThousand(renewalsValue, true)}</TableCell>
                                                    <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRow, rowSecond }, 'disable')} align="right" className={classes.dataCell}>{countOrACVFormatterThousand(renewalsAcv, true)}</TableCell>
                                                    <TableCell onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRow, rowSecond })} align="right" className={classes.drilldownCell}>{_isNaN(Math.round((renewalsAcv / renewalsValue) * 100)) ? `0%` : `${((renewalsAcv / renewalsValue) * 100).toFixed(1)}%`}</TableCell>
                                                </>

                                            })

                                        }
                                        {
                                            columns.length > 1 &&
                                            <>
                                                <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRow, rowSecond })} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.higherRows] === higherRow && item[props.rowSecondLevel] === rowSecond).reduce((a, b) => a + b.count, 0), false)}</TableCell>
                                                <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRow, rowSecond })} className={classes.drilldownCell}>{countOrACVFormatterThousand(props.data.filter(item => item[props.higherRows] === higherRow && item[props.rowSecondLevel] === rowSecond).reduce((a, b) => a + b.Renewal_Value, 0), true)}</TableCell>
                                                <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRow, rowSecond }, 'disable')} className={classes.dataCell}>{countOrACVFormatterThousand(props.data.filter(item => item[props.higherRows] === higherRow && item[props.rowSecondLevel] === rowSecond).reduce((a, b) => a + b.acv, 0), true)}</TableCell>
                                                <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRow, rowSecond })} className={classes.drilldownCell}>{_isNaN(Math.round((props.data.filter(item => item[props.higherRows] === higherRow && item[props.rowSecondLevel] === rowSecond).reduce((a, b) => a + b.acv, 0) / props.data.filter(item => item[props.higherRows] === higherRow && item[props.rowSecondLevel] === rowSecond).reduce((a, b) => a + b.Renewal_Value, 0)) * 100)) ? '0%' : `${((props.data.filter(item => item[props.higherRows] === higherRow && item[props.rowSecondLevel] === rowSecond).reduce((a, b) => a + b.acv, 0) / props.data.filter(item => item[props.higherRows] === higherRow && item[props.rowSecondLevel] === rowSecond).reduce((a, b) => a + b.Renewal_Value, 0)) * 100).toFixed(1)}%`}</TableCell>
                                            </>
                                        }
                                    </StyledTableRow>)}


                                </>)
                            }
                            <StyledTableRow key={"totalRow"} style={{ fontWeight: 600 }}>
                                <TableCell className={classes.colHeader}>Total</TableCell>
                                {
                                    columns.map(col => {
                                        const cellData = props.data.filter(item => item[props.column] === col)
                                        const renewals = cellData.reduce((a, b) => a + b.count, 0)
                                        const renewalsValue = cellData.reduce((a, b) => a + b.Renewal_Value, 0)
                                        const renewalsAcv = cellData.reduce((a, b) => a + b.acv, 0)
                                        // console.log(renewals, renewalsValue, colTotal)
                                        return <>
                                            <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRows, rowSecond: rowSecondLevel })} className={classes.drilldownCell}>{countOrACVFormatter(renewals, false)}</TableCell>
                                            <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRows, rowSecond: rowSecondLevel })} className={classes.drilldownCell}>{countOrACVFormatterThousand(renewalsValue, true)}</TableCell>
                                            <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRows, rowSecond: rowSecondLevel }, 'disable')} className={classes.dataCell}>{countOrACVFormatterThousand(renewalsAcv, true)}</TableCell>
                                            <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col, row: higherRows, rowSecond: rowSecondLevel })} className={classes.drilldownCell}>{_isNaN(Math.round((renewalsAcv / renewalsValue) * 100)) ? `0%` : `${((renewalsAcv / renewalsValue) * 100).toFixed(1)}%`}</TableCell>
                                        </>
                                    })
                                }
                                {
                                    columns.length > 1 &&
                                    <>
                                        <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRows, rowSecond: rowSecondLevel })} className={classes.drilldownCell}>{countOrACVFormatter(props.data.reduce((a, b) => a + b.count, 0), false)}</TableCell>
                                        <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRows, rowSecond: rowSecondLevel })} className={classes.drilldownCell}>{countOrACVFormatterThousand(props.data.reduce((a, b) => a + b.Renewal_Value, 0), true)}</TableCell>
                                        <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRows, rowSecond: rowSecondLevel }, 'disable')} className={classes.dataCell}>{countOrACVFormatterThousand(props.data.reduce((a, b) => a + b.acv, 0), true)}</TableCell>
                                        <TableCell align="right" onClick={() => drilldownHandler("drilldownOnGraph", { col: columns, row: higherRows, rowSecond: rowSecondLevel })} className={classes.drilldownCell}>{_isNaN(Math.round((props.data.reduce((a, b) => a + b.acv, 0) / props.data.reduce((a, b) => a + b.Renewal_Value, 0)) * 100)) ? '0%' : `${((props.data.reduce((a, b) => a + b.acv, 0) / props.data.reduce((a, b) => a + b.Renewal_Value, 0)) * 100).toFixed(1)}%`}</TableCell>
                                    </>
                                }
                            </StyledTableRow>


                        </> : <NoDataCell />
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
