import {
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@material-ui/core";
  import React, { useEffect, useRef, useState } from "react";
  import {
    countOrACVFormatterMillion,
    customDateFormatter,
    getUniqueValues,
    StyledTableRow,
  } from "../../util/customFunctions";
  
  import { makeStyles } from "@material-ui/core";
  import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
  
  const tableStyle = makeStyles(
    (theme) => ({
      root: {
        margin: "0.5rem",
        padding: "0.5rem",
      },
      colHeaderNew: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
        backgroundColor: "inherit",
      },
      colHeader: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: 600,
      },
      dataCell: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
      },
      drilldownCell: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
        // cursor: "pointer",
      },
      drilldownCellNQP: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: "3%",
        fontWeight: "inherit",
        cursor: "pointer",
      },
      cellMargin: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
        paddingLeft: "2rem",
      },
      fontBold: {
        fontWeight: 600,
      },
      indentName: {
        paddingLeft: 30,
      },
      noBorder: {
        border: "none",
      },
      primaryColumnColor: {
        backgroundColor: "#4472C4",
        color: "white",
      },
    }),
    { index: 1 }
  );
  
  function SummaryTablev2(props) {
    const tableRef = React.createRef();
    const tableContainerRef = useRef();
  
    const [parentWidth, setparentWidth] = useState();
  
   
  
    useEffect(() => {
      // Get the width of the parent TableContainer
      const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
      setparentWidth(parentWidth);
    }, [parentWidth]);
  
    const classes = tableStyle();

    const cols = ['Goal', 'Actual', 'Delta']
  console.log(props.columns, 'CCCCCCCCCCCCCCCC')
    return (
      <div
        style={{
          maxWidth: "100%",
          justifyContent: "center",
          padding: "2.5rem",
          paddingTop: 0,
        }}
      >
        {/* <Grid container component={Card} raised> */}
        <Grid xs={12}>
          {parentWidth !== undefined ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginBottom: "0.6rem",
                // marginRight: "1rem",
                width: `${
                  tableContainerRef.current.getBoundingClientRect().width
                }px`,
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null}
        </Grid>
        <TableContainer
          style={{
            width: "auto",
          }}
          id="myTableContainer-Second"
          ref={tableContainerRef}
        >
          <Table ref={tableRef} data-test={props.dataTest} id={props.tableID}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.colHeader}
                  style={{
                    minWidth: 150,
                    whiteSpace: "nowrap",
                    background: "white",
                  }}
                >
                  {/* Team / Rep ↓ / Week Ending → */}
                </TableCell>
                {props.columns.map((col, index) => (
                  <TableCell
                    colSpan={cols.length}
                    style={
                      index % 2 === 0
                        ? {
                            background: "#4471c4",
                            color: "white",
                            minWidth: "7rem",
                          }
                        : {
                            background: "#5b9bd5",
                            color: "white",
                            minWidth: "7rem",
                          }
                    }
                    align="center"
                    className={classes.colHeader}
                  >
                   {col}
                  </TableCell>
                ))}

<TableCell
                    colSpan={2}
                    style={
                      {
                            background: "#5b9bd5",
                            color: "white",
                            minWidth: "7rem",
                          }
                       
                    }
                    align="center"
                    className={classes.colHeader}
                  >
                   {props.totalWeekText}
                  </TableCell>

              </TableRow>
              <TableRow>
              <TableCell
                  className={classes.colHeader}
                  style={{
                    minWidth: 150,
                    whiteSpace: "nowrap",
                    background: "white",
                  }}
                >
                  {/* Team / Rep ↓ / Week Ending → */}
                </TableCell>
                {
                    props.columns.map(c => 
                        cols.map(col => 
                            <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          {col}
                                                        </TableCell>
                        )
                    )
                }
                 <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          {'Total Goal'}
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                         {'Gap to Total Goal'}
                                                        </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <StyledTableRow key={'uuii22'}>
                <TableCell
                                                          align="left"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          
                                                        >
                                                          {'Global'}
                                                        </TableCell>
                                                        {
                                                            props.columns.map((col, idx) => {
                                                                const data = props.data.globalData.filter(f => f['type'] === col)
                                                                const goal = data.length > 0 ? countOrACVFormatterMillion(data.reduce((a,b) => a+b.Goal, 0), true) : '-'
                                                                const actual = data.length > 0 ? countOrACVFormatterMillion(data.reduce((a,b) => a+b.Actual, 0), true) : '-'
                                                                 const delta = data.length > 0 ? countOrACVFormatterMillion(data.reduce((a,b) => a+b.Delta, 0), true) : '-'

                                                                 const g = data.length > 0 ? data.reduce((a,b) => a+b.Goal, 0) : null
                                                                 const a = data.length > 0 ? data.reduce((a,b) => a+b.Actual, 0) : null
                                                                 const d = data.length > 0 ? data.reduce((a,b) => a+b.Delta, 0) : null

                                                                 let bg = ''
                                                                 let color= ''

                                                                 if(g > 0 && a >= g){
                                                                    bg = '#e2efda'
                                                                 }

                                                                 if(d < 0){
                                                                    color= 'red'
                                                                 }
                                                                return(
                                                                    <>
                                                                    <TableCell  align="right"
                                                                                 className={classes.colHeader}
                                                                                    colSpan={1}>{goal}</TableCell>
                                                                    <TableCell  
                                                                     align="right"
                                                                     className={classes.colHeader}
                                                                     colSpan={1}
                                                                    style={  bg ? {background: bg, cursor: 'pointer'} : {cursor: 'pointer'}}
                                                                    onClick={() =>
                                                                        props.handleChangeLocation(
                                                                          "drilldownOnGraph",
                                                                          {
                                                                            row: props.splits,
                                                                            cell:
                                                                              data[0][props.timeframe],
                                                                            repOrTeam:
                                                                             props.rows,
                                                                          },
                                                                          props.type,
                                                                          props.timeframe,
                                                                          props.gb
                                                                        )
                                                                      }
                                                                    >
                                                                        {actual}</TableCell>
                                                                    <TableCell  
                                                                     align="right"
                                                                     className={classes.colHeader}
                                                                     colSpan={1}
                                                                    style={  color ? {color: color} : {}}>{delta}</TableCell>
                                                                    </>
                                                                )
                                                                 
                                                            })
                                                        }

<TableCell
                                                          align="right"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          
                                                        >
                                                          {countOrACVFormatterMillion(props.data.totalWeekGlobal.reduce((a,b) => a+b.Goal, 0), true)}
                                                        </TableCell>

                                                        <TableCell
                                                          align="right"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          
                                                        >
                                                          {countOrACVFormatterMillion(props.data.totalWeekGlobal.reduce((a,b) => a+b.Gap, 0), true)}
                                                        </TableCell>
                </StyledTableRow>

                <StyledTableRow key={'uuii22'}>
                <TableCell
                                                          align="left"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          style={{height: '31px'}}
                                                        >
                                                          {''}
                                                        </TableCell>
                                                        {
                    props.columns.map(c => 
                        cols.map(col => 
                            <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          
                                                        </TableCell>
                        )
                    )
                }
                <TableCell
                                                          align="left"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          style={{height: '31px'}}
                                                        >
                                                          {''}
                                                        </TableCell>
                                                        <TableCell
                                                          align="left"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          style={{height: '31px'}}
                                                        >
                                                          {''}
                                                        </TableCell>
                </StyledTableRow>

                <StyledTableRow key={'uuii22'}>
                <TableCell
                                                          align="left"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          style={{height: '31px'}}
                                                        >
                                                          {'By Team'}
                                                        </TableCell>
                                                        {
                    props.columns.map(c => 
                        cols.map(col => 
                            <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          
                                                        </TableCell>
                        )
                    )
                }
                <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          
                                                        </TableCell>
                </StyledTableRow>

                {
                props.rows.map((row,i) => 
                    <StyledTableRow key={i}>
                    <TableCell
                    className={classes.colHeader}
                    style={{
                      minWidth: 150,
                      whiteSpace: "nowrap",
                      
                    }}
                  >
                    {row}
                  </TableCell>
                  {
                    props.columns.map(c => 
                        {
                            const data = props.data.data.filter(f => f.type === c && f[props.gb] === row)
                            // console.log(data, 'DATTATATATATAT')
                            const goal = data.length > 0 ? data[0].Goal : null
                            const actual = data.length > 0 ? data[0].Actual : null
                            const delta = data.length > 0 ? data[0].Delta : null


                           

                            let bg = ''
                            let color = ''
                            if(delta < 0){
                                color= 'red'
                            }

                            if(actual >= goal && goal > 0){
                                bg= '#e2efda'
                            }


                          
                           return (
                           <>
                           <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                          >
                            {[null, undefined, ''].includes(goal) ? '-' : countOrACVFormatterMillion(goal, true)}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                            style={  bg ? {background: bg, cursor: 'pointer'} : {cursor: 'pointer'}}
                                                                    onClick={() =>
                                                                        props.handleChangeLocation(
                                                                          "drilldownOnGraph",
                                                                          {
                                                                            row: props.splits,
                                                                            cell:
                                                                              data[0][props.timeframe],
                                                                            repOrTeam:
                                                                             [row],
                                                                          },
                                                                          props.type,
                                                                          props.timeframe,
                                                                          props.gb
                                                                        )
                                                                      }
                          >
                            {[null, undefined, ''].includes(actual) ? '-' : countOrACVFormatterMillion(actual, true)}
                          </TableCell>

                          <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                            style={
                              
                            color ? {color: color} : {}}
                          >
                            {[null, undefined, ''].includes(delta) ? '-' : countOrACVFormatterMillion(delta, true)}
                          </TableCell>
                          </>
                    )}
                           
                        )
                    
                } 

<TableCell
                                                          align="right"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          
                                                        >
                                                          {countOrACVFormatterMillion(props.data.totaldata.filter(f => f[props.gb] === row).reduce((a,b) => a+b.Goal, 0), true)}
                                                        </TableCell>

                                                        <TableCell
                                                          align="right"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          
                                                        >
                                                          {countOrACVFormatterMillion(props.data.totaldata.filter(f => f[props.gb] === row).reduce((a,b) => a+b.Gap, 0), true)}
                                                        </TableCell>
                  </StyledTableRow>
                )
              }

<StyledTableRow key={'uuii22'}>
                <TableCell
                                                          align="left"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          style={{height: '31px'}}
                                                        >
                                                          {''}
                                                        </TableCell>
                                                        {
                    props.columns.map(c => 
                        cols.map(col => 
                            <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          
                                                        </TableCell>
                        )
                    )
                }
                <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          
                                                        </TableCell>
                </StyledTableRow>


                <StyledTableRow key={'uuii22'}>
                <TableCell
                                                          align="left"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          style={{height: '31px'}}
                                                        >
                                                          {props.source}
                                                        </TableCell>
                                                        {
                    props.columns.map(c => 
                        cols.map(col => 
                            <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          
                                                        </TableCell>
                        )
                    )
                }
                <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                        >
                                                          
                                                        </TableCell>
                </StyledTableRow>

                {
                props.splits.map((row,i) => 
                    <StyledTableRow key={i}>
                    <TableCell
                    className={classes.colHeader}
                    style={{
                      minWidth: 150,
                      whiteSpace: "nowrap",
                      
                    }}
                  >
                    {row}
                  </TableCell>
                  {
                    props.columns.map(c => 
                        {
                            const data = props.data.splitData.filter(f => f.type === c && f[props.type] === row)
                            // console.log(data, 'DATTATATATATAT')
                            const goal = data.length > 0 ? data[0].Goal : null
                            const actual = data.length > 0 ? data[0].Actual : null
                            const delta = data.length > 0 ? data[0].Delta : null


                           

                            let bg = ''
                            let color = ''
                            if(delta < 0){
                                color= 'red'
                            }

                            if(actual >= goal && goal > 0){
                                bg= '#e2efda'
                            }


                          
                           return (
                           <>
                           <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                          >
                            {[null, undefined, ''].includes(goal) ? '-' : countOrACVFormatterMillion(goal, true)}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                            style={  bg ? {background: bg, cursor: 'pointer'} : {cursor: 'pointer'}}
                            onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  {
                                    row: [row],
                                    cell:
                                      data[0][props.timeframe],
                                    repOrTeam:
                                     props.rows,
                                  },
                                  props.type,
                                  props.timeframe,
                                  props.gb
                                )
                              }
                          >
                            {[null, undefined, ''].includes(actual) ? '-' : countOrACVFormatterMillion(actual, true)}
                          </TableCell>

                          <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                            style={
                              
                            color ? {color: color} : {}}
                          >
                            {[null, undefined, ''].includes(delta) ? '-' : countOrACVFormatterMillion(delta, true)}
                          </TableCell>
                          </>
                    )}
                           
                        )
                    
                } 

<TableCell
                                                          align="right"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          
                                                        >
                                                          {countOrACVFormatterMillion(props.data.totalSplitData.filter(f => f[props.type] === row).reduce((a,b) => a+b.Goal, 0), true)}
                                                        </TableCell>

                                                        <TableCell
                                                          align="right"
                                                          className={classes.colHeader}
                                                          colSpan={1}
                                                          
                                                        >
                                                          {countOrACVFormatterMillion(props.data.totalSplitData.filter(f => f[props.type] === row).reduce((a,b) => a+b.Gap, 0), true)}
                                                        </TableCell>
                  </StyledTableRow>
                )
              }

            </TableBody>
           
          </Table>
        </TableContainer>
        {/* </Grid> */}
      </div>
    );
  }
  export default SummaryTablev2;
  