import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Typography,
    TableHead,
    TableRow,
    withStyles,
    Grid,
  } from "@material-ui/core";
  import React from "react";
  import {
    countOrACVFormatter,
    StyledTableRow,
    _isNaN,
    getUniqueValues,
  } from "../../util/customFunctions";
  import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
  // import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
  
  const styles = makeStyles({
    tableHeader: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "5px 5px",
      color: "inherit",
    },
    nonScoreCell: {
      fontWeight: 400,
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
    },
    repHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    repHeaderDrilldown: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      cursor: "pointer",
    },
    repNameMargin: {
      paddingLeft: "1.75rem",
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
    },
    mainTable: {
      width: "100%",
      maxWidth: "100%",
      padding: "0.25rem",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 24px 6px 10px;",
    },
  });
  
  export default function AttainmentTableACVRange(props) {
    const classes = styles();
    const tableRef = React.createRef();
    const teams = props.AttainmentTable.map((s) => s.name)
      .filter(getUniqueValues)
      .sort();
    // const bookingTypes=props.AttainmentTable.map(s=>s.type).filter(getUniqueValues).filter(a=>a !== 'total')
    const bookingTypes = props.attainmentSplitUp;
    // const uniqueNames=["New Logo","Cross Sell","Upsell","Price Increase"]
    const uniqueNames = props.attainmentSplitUp;
    const countAndOpps = (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <TableCell
                align="center"
                className={`${classes.repHeader} ${classes.tableHeader}`}
                colSpan={1}
                style={{
                  background: "#70AD47",
                  color: "white",
                  border: "0.5px solid lightgrey",
                  minWidth: 40,
                  maxWidth: 40,
                }}
              >
                # of Opps
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.repHeader} ${classes.tableHeader}`}
                colSpan={1}
                style={{
                  background: "#70AD47",
                  color: "white",
                  border: "0.5px solid lightgrey",
                  minWidth: 70,
                  maxWidth: 70,
                }}
              >
                ACV
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.repHeader} ${classes.tableHeader}`}
                colSpan={1}
                style={{
                  background: "#70AD47",
                  color: "white",
                  border: "0.5px solid lightgrey",
                  minWidth: 70,
                  maxWidth: 70,
                }}
              >
                Avg. ACV
              </TableCell>
            </>
          ))}
      </>
    );
    const countAndOppsAndPercentage = (
      <>
        {uniqueNames.map((item, i) => (
          <>
            <TableCell
              align="center"
              className={`${classes.repHeader} ${classes.tableHeader}`}
              colSpan={1}
              style={{
                background: i % 2 == 0 ? "#4471C4" : "#5B9BD5",
                color: "white",
                border: "0.5px solid lightgrey",
                minWidth: 40,
                maxWidth: 40,
              }}
            >
              # of Opps
            </TableCell>
            <TableCell
              align="center"
              className={`${classes.repHeader} ${classes.tableHeader}`}
              colSpan={1}
              style={{
                background: i % 2 == 0 ? "#4471C4" : "#5B9BD5",
                color: "white",
                border: "0.5px solid lightgrey",
                minWidth: 70,
                maxWidth: 70,
              }}
            >
              ACV
            </TableCell>
            
            <TableCell
              align="center"
              className={`${classes.repHeader} ${classes.tableHeader}`}
              colSpan={1}
              style={{
                background: i % 2 == 0 ? "#4471C4" : "#5B9BD5",
                color: "white",
                border: "0.5px solid lightgrey",
                minWidth: 50,
                maxWidth: 50,
              }}
            >
              % of Tot.
            </TableCell>
          </>
        ))}
      </>
    );
    return (
      <>
        {props.AttainmentTable !== "" &&
          props.selectedTeam !== undefined &&
          props.selectedRep !== undefined && (
            <>
              <TableContainer style={{ paddingBottom: "2rem" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                    // marginRight: "10px",
                  }}
                >
                  <CopyTablesObject tableRef={tableRef} />
                </div>
                <Table
                  ref={tableRef}
                  size="small"
                  aria-label="html table"
                  style={{ tableLayout: "auto" }}
                  data-test={props.dataTest}
                  id={props.tableID}
                >
                  <TableHead></TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <TableCell
                        align="center"
                        rowSpan={2}
                        className={`${classes.repHeader} ${classes.tableHeader}`}
                        colSpan={1}
                        style={{
                          background: "#DDECB9",
                          height: 90,
                          minWidth: 160,
                          border: "0.5px solid lightgrey",
                        }}
                      >
                        {props.tableHeader}
                      </TableCell>
                     
                      
                    
                      {uniqueNames.map((s, i) => {
                        return (
                          <>
                            <TableCell
                              align="center"
                              className={`${classes.repHeader} ${classes.tableHeader}`}
                              colSpan={3}
                              style={{
                                background: i % 2 == 0 ? "#4471C4" : "#5B9BD5",
                                color: "white",
                                border: "0.5px solid lightgrey",
                                minWidth: 150,
                                maxWidth: 150,
                              }}
                            >
                              {s}
                            </TableCell>
                          </>
                        );
                      })}
                    </StyledTableRow>
                    <TableRow>
                      {countAndOppsAndPercentage}
                    </TableRow>
                    {!props.isrepScoreCard && 
                    <>
                    <StyledTableRow>
                      <TableCell
                        align="left"
                        className={classes.repHeader}
                        style={{
                          border: "0.5px solid lightgrey",
                          fontWeight: 600,
                        }}
                        colSpan={1}
                      >
                        {props.selectedTeam.includes("All") &&
                        props.selectedRep.includes("All")
                          ? "Company"
                          : !props.selectedTeam.includes("All")
                          ? props.selectedTeam.length > 1
                            ? "Teams"
                            : "Team"
                          : "Total"}
                      </TableCell>
                     
                     
                    
                    
                      
                      {uniqueNames.map((s) => {
                        return (
                          <>
                            <TableCell
                              align="right"
                              // onClick={() =>
                              //   props.handleChangeLocation(
                              //     "drilldownOnGraph",
                              //     [s],
                              //     teams
                              //   )
                              // }
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                // cursor: "pointer",
                                paddingRight: "0.5rem",
                              }}
                              colSpan={1}
                            >
                              {countOrACVFormatter(
                                props.AttainmentSegmentRow.filter(
                                  (i) => i.type === s
                                )[0].count
                              )}
                            </TableCell>
                            <TableCell
                              align="right"
                              // onClick={() =>
                              //   props.handleChangeLocation(
                              //     "drilldownOnGraph",
                              //     [s],
                              //     teams
                              //   )
                              // }
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                // cursor: "pointer",
                                paddingRight: "0.5rem",
                              }}
                              colSpan={1}
                            >
                              $
                              {countOrACVFormatter(
                                props.AttainmentSegmentRow.filter(
                                  (i) => i.type === s
                                )[0].acv
                              )}
                            </TableCell>
                            
                           
                            <TableCell
                              align="right"
                              // onClick={() =>
                              //   props.handleChangeLocation(
                              //     "drilldownOnGraph",
                              //     [s],
                              //     teams
                              //   )
                              // }
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                // cursor: "pointer",
                                paddingRight: "0.5rem",
                              }}
                              colSpan={1}
                            >
                              {Math.round(
                                props.AttainmentSegmentRow.filter(
                                  (i) => i.type === s
                                )[0].perc * 100
                              )}
                              %
                            </TableCell>
                          </>
                        );
                      })}
                    </StyledTableRow>
  
                    <StyledTableRow>
                      <TableCell
                        align="left"
                        className={classes.nonScoreCell}
                        style={{
                          border: "0.5px solid lightgrey",
                          fontWeight: 600,
                        }}
                        colSpan={1}
                      >
                        {props.selectedTeam.includes("All") &&
                        props.selectedRep.includes("All")
                          ? "Teams"
                          : "Reps"}
                      </TableCell>
                     
                     
                     
                      {uniqueNames.map((item, i) => (
                        <>
                          <TableCell
                            align="center"
                            className={`${classes.repHeader} ${classes.tableHeader}`}
                            colSpan={1}
                            style={{
                              color: "white",
                              border: "0.5px solid lightgrey",
                              minWidth: 40,
                              maxWidth: 40,
                            }}
                          ></TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.repHeader} ${classes.tableHeader}`}
                            colSpan={1}
                            style={{
                              color: "white",
                              border: "0.5px solid lightgrey",
                              minWidth: 70,
                              maxWidth: 70,
                            }}
                          ></TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.repHeader} ${classes.tableHeader}`}
                            colSpan={1}
                            style={{
                              color: "white",
                              border: "0.5px solid lightgrey",
                              minWidth: 50,
                              maxWidth: 50,
                            }}
                          ></TableCell>
                         
                        </>
                      ))}
                    </StyledTableRow>
                    </>
        }
                    {teams.map((team) => {
                      const teamData = props.AttainmentTable.filter(
                        (s) => s.name === team
                      );
                      return (
                        <StyledTableRow>
                          <TableCell
                            align="left"
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              textIndent: "0",
                              minWidth: 160,
                              paddingLeft: "1.75rem",
                            }}
                            colSpan={1}
                          >
                            {team}
                          </TableCell>
                          
                          
                        
                         
                          
                          {uniqueNames.map((a) => {
                            return (
                              <>
                                <TableCell
                                  align="right"
                                  // onClick={() =>
                                  //   props.handleChangeLocation(
                                  //     "drilldownOnGraph",
                                  //     [a],
                                  //     [team]
                                  //   )
                                  // }
                                  className={classes.repHeader}
                                  style={{
                                    border: "0.5px solid lightgrey",
                                    // cursor: "pointer",
                                    paddingRight: "0.5rem",
                                  }}
                                  colSpan={1}
                                >
                                  {countOrACVFormatter(
                                    teamData.filter(
                                      (i) => i.type === a && i.name === team
                                    )[0].count
                                  )}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  // onClick={() =>
                                  //   props.handleChangeLocation(
                                  //     "drilldownOnGraph",
                                  //     [a],
                                  //     [team]
                                  //   )
                                  // }
                                  className={classes.repHeader}
                                  style={{
                                    border: "0.5px solid lightgrey",
                                    // cursor: "pointer",
                                    paddingRight: "0.5rem",
                                  }}
                                  colSpan={1}
                                >
                                  $
                                  {countOrACVFormatter(
                                    teamData.filter(
                                      (i) => i.type === a && i.name === team
                                    )[0].acv
                                  )}
                                </TableCell>
                              
                                <TableCell
                                  align="right"
                                  // onClick={() =>
                                  //   props.handleChangeLocation(
                                  //     "drilldownOnGraph",
                                  //     [a],
                                  //     [team]
                                  //   )
                                  // }
                                  className={classes.repHeader}
                                  style={{
                                    border: "0.5px solid lightgrey",
                                    // cursor: "pointer",
                                    paddingRight: "0.5rem",
                                  }}
                                  colSpan={1}
                                >
                                  {Math.round(
                                    teamData.filter(
                                      (i) => i.type === a && i.name === team
                                    )[0].perc * 100
                                  )}
                                  %
                                </TableCell>
                              </>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
      </>
    );
  }