import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import { useHistory } from "react-router-dom";
let PlatformInfo = (props) => {
  const history = useHistory();
  useEffect(() => {
    props.getSettings();
  }, []);

  return (
    <>
      {props.settings !== "" ? (
        <Grid item container md={7} style={{ padding: "25px 0px" }}>
          <Grid item xs={10}>
            <Divider variant="fullWidth" />
          </Grid>
          <Box data-test="data-sync-date-section" style={{ padding: 5 }}>
            <Typography variant="h2">SkyGeni Platform Info</Typography>
            <Typography variant="body1" style={{ marginTop: 5 }}>
              The last data sync. between the SkyGeni platform and Salesforce
              was at:
            </Typography>
            <Typography
              variant="body1"
              style={{ marginTop: 5, fontWeight: 600 }}
              data-test="last-pull-date"
            >
              {props.settings.lastPull}
            </Typography>
            <Typography
              variant="body1"
              style={{ marginTop: 5, marginBottom: 20 }}
            >
              All data in Salesforce by this time will be reflected in the
              SkyGeni platform.
            </Typography>
            <Typography variant="body1">
              The next scheduled data sync. between the SkyGeni platform and
              Salesforce will be at:
            </Typography>
            <Typography
              variant="body1"
              style={{ marginTop: 5, marginBottom: 5, fontWeight: 600 }}
              data-test="next-pull-date"
            >
              {props.settings.nextPull}
            </Typography>
          </Box>
          <Grid item xs={10}>
            <Divider variant="fullWidth" />
          </Grid>
        </Grid>
      ) : (
        <Grid container item md={7} justifyContent="flex-start">
          <Skeleton width={"90%"} height={170} />
        </Grid>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getSettings: () => {
    dispatch({ type: "get_settings_request" });
    appService.getSettings().then(
      (json) => {
        dispatch({ type: "get_settings_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_settings_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_settings_failure", error });
      }
    );
  },
});
function mapStateToProps(state) {
  const { settings } = state.app;

  return {
    settings,
  };
}
PlatformInfo = connect(mapStateToProps, mapDispatchToProps)(PlatformInfo);
export default PlatformInfo;