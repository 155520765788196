import * as d3 from "d3";
import { range } from "d3";
import { store } from "../../util/store";

const D3StackedBar = {};

D3StackedBar.create = (el, config) => {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;

  console.log(config);
  d3.selection.prototype.last = function () {
    return d3.select(this.nodes()[this.size() - 1]);
  };

  const handleChangeLocation = (location, stage) => {
    config.handleChangeLocation(location, stage);
  };

  function wrap(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy + "em");
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }
  // D3 Code to create the chart
  if (config.data) {
    config.barColor = "#70ad47";
    config.barHeight = 50;
    config.margin = {
      top: 0,
      right: 25,
      bottom: 20,
      left: 60,
    };
    config.axisTicks = { qty: 5, outerSize: 0, dateFormat: "%m-%d" };
    config.barPadding = 0.2;

    if (!config.width) {
      config.width = d3.select(el).node()
        ? d3.select(el).node().getBoundingClientRect().width -
          config.margin.left -
          config.margin.right
        : 100;
    }
    config.height = config.data ? config.data.length * 35 : 100; //(config.barHeight * config.data.length + (config.data.length * 5) < 200 ? 200 : config.barHeight * config.data.length + (config.data.length * 5) + 60) - config.margin.top - config.margin.bottom;

    const fontWeight = 400;

    let opps = {};
    let acv = {};
    let subgroups = [];
    config.data.map((i) => {
      opps = {
        ...opps,
        [i.stage_name]: i.count,
      };
      acv = {
        ...acv,
        [i.stage_name]: i.acv,
      };
      subgroups = [...subgroups, i.stage_name];
    });

    //normalizing
    let totalOpps = 0;
    let totalACV = 0;

    subgroups.map((i) => {
      totalOpps += opps[i];
      totalACV += acv[i];
    });

    subgroups.map((i) => {
      opps[i] = (opps[i] / totalOpps) * 100;
      acv[i] = (acv[i] / totalACV) * 100;
    });

    //add gruoup to array after normalizing
    opps = {
      ...opps,
      group: "# of Opps",
    };
    acv = {
      ...acv,
      // group: "ACV ($ M)",
      group: `${config.Value_Label} (${Currency_Symbol} M)`,
    };

    const graphData = [acv, opps];

    var groups = d3
      .map(graphData, function (d) {
        return d.group;
      })
      .keys();
    var stackedData = d3.stack().keys(subgroups)(graphData);

    //graph plotting

    var chartContainer = d3
      .select(el)
      .append("svg")
      .attr("width", config.width + config.margin.left + config.margin.right)
      .attr(
        "height",
        config.height + config.margin.top + config.margin.bottom + 30
      );
    var chart = chartContainer
      .append("g")
      .attr(
        "transform",
        "translate(" + config.margin.left + "," + config.margin.top + ")"
      );

    // var x = d3.scaleBand()
    //     .domain(groups)
    //     .range([0, config.width])
    //     .padding([0.2])
    // chart.append("g")
    //     .attr("transform", "translate(0," + config.height + ")")
    //     .call(d3.axisBottom(x).tickSizeOuter(0));

    // // Add Y axis
    // var y = d3.scaleLinear()
    //     .domain([0, 100])
    //     .range([config.height, 0]);
    // chart.append("g")
    //     .call(d3.axisLeft(y));

    var x = d3.scaleLinear().domain([0, 100]).range([0, config.width]);
    chart
      .append("g")
      .attr("transform", "translate(0," + config.height + ")")
      .style("font-size", "0.8125rem")
      .call(
        d3
          .axisBottom(x)
          .tickSizeOuter(0)
          .tickFormat((i) => i + "%")
      );

    var y = d3
      .scaleBand()
      .domain(groups)
      .range([config.height, 0])
      .padding([0.2]);
    chart
      .append("g")
      .style("font-size", "0.8125rem")
      .style("font-weight", fontWeight)
      .call(d3.axisLeft(y))
      .selectAll("text")
      .call(wrap, 30)
      .attr("transform", "translate(-20,0)");

    /*  var color = d3.scaleOrdinal(config.stage.map(i=>i.backward_color_hash));
        color.domain(subgroups)
 */

    var color = d3
      .scaleOrdinal()
      .domain(config.stage.map((s) => s.stage_name))
      .range(config.stage.map((s) => s.backward_color_hash));

    chart
      .append("g")
      .selectAll("g")
      // Enter in the stack data = loop key per key = group per group
      .data(stackedData)
      .enter()
      .append("g")
      .attr("fill", function (d) {
        return color(d.key);
      })
      .attr("cursor", "pointer")
      .on("click", (d) => handleChangeLocation("drilldownOnGraph", [d.key]))
      .selectAll("rect")
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data(function (d) {
        return d;
      })
      .enter()
      .append("rect")
      .attr("y", function (d) {
        return y(d.data.group);
      })
      .attr("x", function (d) {
        return x(d[0]);
      })
      .attr("width", function (d) {
        return x(d[1]) - x(d[0]);
      })
      .attr("height", y.bandwidth());
    let barLabels = [...Array(stackedData.length).keys()].map((i) => {
      chart
        .selectAll(`.bartext${i}`)
        .data(stackedData[i])
        .enter()
        .append("text")
        .attr("class", `.bartext${i}`)
        .attr("text-anchor", "middle")
        .attr("fill", "black")
        .style("cursor", "pointer")
        .on("click", (d) =>
          handleChangeLocation("drilldownOnGraph", [stackedData[i].key])
        )
        .style("font-size", "0.8125rem")
        .style("font-weight", fontWeight)
        .attr("y", function (d) {
          return y(d.data.group) + 35;
        })
        .attr("x", function (d) {
          return x((d[1] + d[0]) / 2);
        })
        .text(function (d) {
          let temp = Math.round(d[1] - d[0]);

          if (temp >= 3) return `${temp}%`;
        });
    });
    let offset = config.width > 600 ? 110 : 70;
    let offset2 = offset - (config.width > 600 ? 20 : 15);
  }
};

D3StackedBar.update = (el, config) => {
  config.barColor = "#4caf50";
  config.barHeight = 20;
  config.margin = {
    top: 0,
    right: 25,
    bottom: 20,
    left: 60,
  };
  if (!config.width) {
    config.width =
      d3.select(el).node().getBoundingClientRect().width -
      config.margin.left -
      config.margin.right;
  }
  config.height = 300;
  // D3 Code to update the chart
  var chartContainer = d3.select(el);
  var chart = chartContainer.select("g");
  var rateRectsGroup = chart
    .selectAll("g.rates")
    .data(config.data)
    .exit()
    .remove()
    .enter();

  var rateRectsFull = rateRectsGroup
    .append("rect")
    .attr("height", config.barHeight)
    .attr("width", config.width)
    .attr("class", "compare-bar")
    .attr("fill", "#ccc")
    .attr("transform", "translate(0, 0)");

  var rateRects = rateRectsGroup
    .append("rect")
    .attr("height", config.barHeight)
    .attr("width", function (d) {
      return config.width * d.acvRate;
    })
    .attr("class", "actual-bar")
    .style("fill", config.barColor)
    .attr("transform", function (d) {
      return (
        "translate(" + (config.width - config.width * d.acvRate) / 2 + ", 0)"
      );
    });

  var rateRectsLabel = rateRectsGroup
    .append("text")
    .attr("text-anchor", "middle")
    .attr("class", "category-value")
    .attr("transform", "translate(" + config.width / 2 + ", 0)")
    .append("tspan")
    .attr("dy", "1em")
    .text(function (d) {
      return `${d.acv} - ${(d.acvRate * 100).toFixed(2)}%`;
    });

  rateRectsGroup
    .select("rect.actual-bar")
    .transition()
    .duration(500)
    .attr("width", function (d) {
      return config.width * d.acvRate;
    })
    .attr("transform", function (d) {
      return (
        "translate(" + (config.width - config.width * d.acvRate) / 2 + ", 0)"
      );
    });

  rateRectsGroup
    .select("text.category-value")
    .select("tspan")
    .transition()
    .duration(500)
    .text(function (d) {
      return `${d.acv} - ${(d.acvRate * 100).toFixed(2)}%`;
    });
};

D3StackedBar.destroy = (el) => {
  // Cleaning code here
  d3.select(el).selectAll("svg").remove();
};

export default D3StackedBar;
