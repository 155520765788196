import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import D3RepPerformanceBarWins from "../RepPerformanceAnalysis/Charts/D3RepPerformanceBarFYWins";
import Legend from "../AsOfDate/Legend";
import D3RepPerformanceStack from "../RepPerformanceAnalysis/Charts/D3RepPerformanceStack";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
} from "../../util/customFunctions";
import C0001_TeamAttainmentTable from "./C0001_TeamAttainmentTable";
import TeamAttainmentTable from "./TeamAttainmentTable";
import Drilldown from "../RepCertification/Drilldown";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import TeamAttainmentDrilldown from "./TeamAttainmentDrilldown";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    color: "inherit",
  },
  table1: {
    background: "#FAFAFA",
    border: "none",
    width: 5,
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    color: "white",
    background: "#4472C4",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 5px",
    width: "20%",
    color: "inherit",
  },
  datesFilterContainer: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    padding: 10,
    paddingBottom: 10,
    width: "32%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});
class TeamAttainment extends Component {
  filters = [
    "closed_fiscal_quarter",
    "closed_fiscal_year",
    "team",
    "Opportunity_Record_Type",
  ];
  state = {
    Opportunity_Record_Type: ["All"],
    year: ["all"],
    month: ["all"],
    quarter: ["all"],
    closed_fiscal_quarter: ["All"],
    closed_fiscal_year: ["All"],
    disabled: ["year", "month", "quarter"],
    activeTimeFilter: "closed_fiscal_quarter",
    team: ["All"],
    drilldownFilters: [],
    key: "",
    secondLevelLocation: null,
  };
  componentDidMount = () => {
    this.props.getSegmentHistoricalPerformanceFilters();
    window.addEventListener("resize", this.onResize);
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const currentQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelCompareFilters,
        "closed_fiscal_quarter"
      );
      this.setState({
        ...prevState,
        closed_fiscal_quarter: currentQuarter,
        key: "closed_fiscal_quarter",
      });
    }
    if (this.props.SegmentPerformanceData !== "") {
      D3RepPerformanceBarWins.destroy(this._rootNode1);
      D3RepPerformanceStack.destroy(this._rootNode2);
      this._chart1 = D3RepPerformanceBarWins.create(this._rootNode1, {
        data: this.props.SegmentPerformanceData,
        type: "Segment",
      });
      this._chart2 = D3RepPerformanceStack.create(this._rootNode2, {
        data: this.props.SegmentPerformanceData,
        type: "Segment",
      });
    }
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
    D3RepPerformanceBarWins.destroy(this._rootNode1);
  };
  onResize = async () => {
    D3RepPerformanceBarWins.destroy(this._rootNode1);
    D3RepPerformanceStack.destroy(this._rootNode2);
    this._chart1 = D3RepPerformanceBarWins.create(this._rootNode1, {
      data: this.props.SegmentPerformanceData,
      type: "Segment",
    });
    this._chart2 = D3RepPerformanceStack.create(this._rootNode2, {
      data: this.props.SegmentPerformanceData,
      type: "Segment",
    });
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }
  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }
  handleChange = (e) => {
    if (
      e.target.name === "closed_fiscal_year" &&
      (e.target.value.length === 0 || e.target.value.includes("All"))
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
      });
    } else if (e.target.name === "closed_fiscal_year") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
      });
    } else if (e.target.name === "closed_fiscal_quarter") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_quarter",
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };
  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));

    let key = "";
    if (this.state.closed_fiscal_year.includes("All")) {
      key = "closed_fiscal_quarter";
    } else {
      key = "closed_fiscal_year";
    }

    this.props.getSegmentPerformanceData(filters, key);
    this.setState({ key });
  };

  handleChangeLocationBack = () => {
    this.setState({
      location: "",
      secondLevelLocation: "",
    });
  };

  handleChangeLocation = (location, quarter, team) => {
    if (location === "drilldownOnGraph") {
      let filters = [];
      let drilldownFilters = [];
      let qtr = quarter;
      if (this.state.key === "closed_fiscal_year") {
        qtr = quarter.map((c) => parseInt(c));
      }
      filters = [
        { name: this.state.key, value: qtr, string: true },
        { name: "team", value: team, string: true },
      ];
      drilldownFilters = [
        {
          title:
            this.state.key === "closed_fiscal_year"
              ? "Fiscal Year"
              : "Fiscal Qtr.",
          value: quarter,
        },
        { title: "Team", value: team },
      ];
      this.props.getTeamAttainmentDrilldown(filters);
      this.setState({
        location,
        drilldownFilters,
        secondLevelLocation: location,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer}>
        {/* <Grid
          item
          container
          className={classes.grid}
          style={{ marginBottom: 10, marginLeft: 25 }}
          xs={12}
        >
          <Grid item xs={12} justify="center">
            <Typography variant="h2" align="center">
              Team Attainment
            </Typography>
          </Grid>
          <Grid item xs={12} justify="left" style={{ margintop: 10 }}>
            <Typography variant="body1" style={{}}>
              Explore the historical performance by segment teams.
            </Typography>
          </Grid>
        </Grid> */}
        {addHeaderdescription(
          "Team Attainment",
          "Explore the historical performance by segment teams."
        )}
        {this.props.funnelCompareFilters !== undefined ? (
          <Grid
            className={classes.grid}
            item
            container
            justify="flex-start"
            spacing={2}
            xs={12}
            style={{ margin: 10, padding: 10 }}
          >
            <Grid item spacing={2} xs={4} container component={Paper}>
              <Grid item xs={6} style={{ paddingTop: 0 }}>
                <FormControl
                  fullWidth
                  disabled={!this.state.closed_fiscal_year.includes("All")}
                >
                  <InputLabel
                    style={{
                      color: !this.state.closed_fiscal_quarter.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-vfiscalQuarter-label"
                  >
                    Fiscal Qtr.:
                  </InputLabel>
                  <Select
                    labelId="select-vRenewal_Contract_Eff_Dt_Fiscal_Quarter-label"
                    id="select-cclosed_fiscal_quarter"
                    value={this.state.closed_fiscal_quarter}
                    onChange={this.handleChange}
                    label="closed_fiscal_quarter"
                    name="closed_fiscal_quarter"
                    data-test="closed-fiscal-quarter"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter(
                          (y) =>
                            y.type === "closed_fiscal_quarter" &&
                            y["closed_fiscal_quarter"] !== "" &&
                            y["closed_fiscal_quarter"] !== null
                        )
                        .map((y) => (
                          <MenuItem
                            key={y["closed_fiscal_quarter"]}
                            value={y["closed_fiscal_quarter"]}
                          >
                            {y["closed_fiscal_quarter"]}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ paddingTop: 0 }}>
                <FormControl
                  fullWidth
                  disabled={!this.state.closed_fiscal_quarter.includes("All")}
                >
                  <InputLabel
                    style={{
                      color: !this.state.closed_fiscal_year.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-vfiscalQuarter-label"
                  >
                    Fiscal Year:
                  </InputLabel>
                  <Select
                    labelId="select-vprojected_close_fiscal_quarter-label"
                    id="select-cclosed_fiscal_year"
                    value={this.state.closed_fiscal_year}
                    onChange={this.handleChange}
                    label="closed_fiscal_year"
                    name="closed_fiscal_year"
                    data-test="closed-fiscal-year"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter(
                          (y) =>
                            y.type === "closed_fiscal_year" &&
                            y["closed_fiscal_year"] !== "" &&
                            y["closed_fiscal_year"] !== null
                        )
                        .map((y) => (
                          <MenuItem
                            key={y["closed_fiscal_year"]}
                            value={y["closed_fiscal_year"]}
                          >
                            {y["closed_fiscal_year"]}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              spacing={2}
              xs={2}
              container
              component={Paper}
              style={{ marginLeft: 15 }}
            >
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel
                    id="select-team-label"
                    style={{
                      color: !this.state.team.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                  >
                    Team:
                  </InputLabel>
                  <Select
                    labelId="select-team-label"
                    id="select-team"
                    value={this.state.team}
                    onChange={this.handleChange}
                    label="Team"
                    data-test="team"
                    name="team"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((i) => i.type === "team")
                        .map((t) => t.Display_Name)
                        .sort()
                        .map((y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {/* OppType */}
            {/* {this.props.company === "C0013" && (
              <Grid
                item
                spacing={2}
                xs={2}
                container
                component={Paper}
                style={{ marginLeft: 15 }}
              >
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-region-label"
                      style={{
                        color: !this.state.Opportunity_Record_Type.includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Opportunity Record Type:
                    </InputLabel>
                    <Select
                      data-test="Opportunity_Record_Type"
                      value={this.state.Opportunity_Record_Type}
                      onChange={this.handleChange}
                      name="Opportunity_Record_Type"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === "Opportunity_Record_Type" &&
                              y.Opportunity_Record_Type !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Opportunity_Record_Type}
                              value={y.Opportunity_Record_Type}
                            >
                              {y.Opportunity_Record_Type}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )} */}
            {/*  */}
            <Grid
              item
              sm={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleGo}
                data-test="go"
              >
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        <Grid item xs={12} container justify="center">
          <Grid
            align="center"
            component={Card}
            justify="center"
            item
            xs={12}
            id="grid-container"
            style={{
              marginBottom: 20,
              overflow: "auto",
              padding: "1rem",
              width: "100%",
            }}
          >
            {this.props.TeamAttainmentData !== "" ? (
              // <Typography>Loaded</Typography>
              this.props.company === "C0001" ? (
                <C0001_TeamAttainmentTable
                  data={this.props.TeamAttainmentData}
                  handleChangeLocation={this.handleChangeLocation}
                  Value_Label={this.props.Value_Label}
                />
              ) : (
                <TeamAttainmentTable
                  data={this.props.TeamAttainmentData}
                  handleChangeLocation={this.handleChangeLocation}
                  Value_Label={this.props.Value_Label}
                />
              )
            ) : (
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="80%"
                  style={{ margin: "20px 0px" }}
                />
                <Divider style={{ width: "100%" }} />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={200}
                  style={{ margin: "20px 0px" }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ margin: "1rem" }}>
          <Card style={{ margin: "auto", width: "88%" }} raised={true}>
            <Typography
              variant="h2"
              style={{
                margin: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Contribution to Total
            </Typography>
            {this.props.SegmentPerformanceData !== "" ? (
              <Grid container>
                <Grid item container xs={12}>
                  <div
                    style={{
                      justifyContent: "center",
                      marginTop: 20,
                      margin: "auto",
                      width: "95%",
                    }}
                    className="line-container"
                    ref={this._setRef2.bind(this)}
                  />
                </Grid>
              </Grid>
            ) : (
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} style={{ margin: "1rem" }}>
          <Card style={{ margin: "auto", width: "88%" }} raised={true}>
            <Typography
              variant="h2"
              style={{
                margin: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Performance - Actual vs. Quota
            </Typography>

            {this.props.SegmentPerformanceData !== "" ? (
              <Grid container>
                <Grid item container xs={12}>
                  <div
                    style={{
                      justifyContent: "center",
                      marginTop: 20,
                      margin: "auto",
                      width: "95%",
                    }}
                    className="line-container"
                    ref={this._setRef1.bind(this)}
                  />
                </Grid>
                <Grid
                  item
                  container
                  justify="center"
                  style={{ margin: "2rem" }}
                  xs={12}
                >
                  <Legend
                    square={20}
                    legends={[
                      { text: "Actual", color: "#70AD47" },
                      { text: "Quota", color: "#BF9000" },
                    ]}
                    type={"segment"}
                  />
                </Grid>
              </Grid>
            ) : (
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            )}
          </Card>
        </Grid>
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Team Attainment`}
              back={this.handleChangeLocationBack}
              filters={this.state.drilldownFilters}
            >
              <Drilldown
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  const {
    funnelCompareFilters,
    TeamAttainmentData,
    persistentAlert,
    user,
    currentFilters,
    drilldownOnGraphData,
    ExpectedAcvData,
  } = state.app;
  return {
    funnelCompareFilters,
    currentFilters,
    drilldownOnGraphData,
    SegmentPerformanceData: TeamAttainmentData.rowTotal,
    persistentAlert,
    company: user.company,
    Value_Label: user.Value_Label,
    TeamAttainmentData,
    ExpectedAcvData,
  };
}
const mapDispatchToProps = (dispatch) => ({
  getSegmentPerformanceData: (filters, key) => {
    dispatch({ type: "get_team_attainment_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getTeamAttainmentData(filters, key).then(
      (json) => {
        dispatch({ type: "get_team_attainment_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_team_attainment_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_team_attainment_data_failure", error });
      }
    );
  },
  getSegmentHistoricalPerformanceFilters: () => {
    dispatch({ type: "get_funnelCompare_filters_request" });
    appService.getTeamAttainmentFilters().then(
      (json) => {
        dispatch({ type: "get_funnelCompare_filters_success", json });
        dispatch({ type: "get_team_attainment_data_request" });
        const filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
        ];
        dispatch({ type: "current_selected_filters", filters });
        appService
          .getTeamAttainmentData(
            json.message.length === 0 ? [] : filters,
            "closed_fiscal_quarter"
          )
          .then(
            (json) => {
              dispatch({ type: "get_team_attainment_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_team_attainment_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({ type: "get_rteam_attainment_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnelCompare_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnelCompare_filters_failure", error });
      }
    );
  },
  getTeamAttainmentDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getTeamAttainmentDrilldown(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.ACV > b.ACV ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };

        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});

const connectedTeamAttainment = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TeamAttainment));
export default connectedTeamAttainment;
