import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useRef, useState } from "react";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";

import StyledTableRow from "../../components/StyledTableRow";
import * as d3 from "d3";
import { deSnake } from "../../util/dataFormatter";
import d3SankeyChartBuilder from "../SankeyPoc/D3SankeyChart";
import D3SankeyContainer from "./D3SankeyContainer";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import stickytableStyle from "../../StickyTableStyles/Sticky_Table_Styles";
// import { originalData } from "../SankeyPoc/originalData";
const useStyle = makeStyles((theme) => ({
  root: {},
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    width: "100%",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function PCDMovedTable(props) {
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = useRef(null);
  // const tableRef2 = useRef(null);
  const tableContainerRef = useRef();
  // const tableContainerRef2 = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  // const [isOverflowing2, setIsOverflowing2] = useState(false);

  const [isSticky, setIsSticky] = useState(false);
  // const [isSticky2, setIsSticky2] = useState(false);

  let total;
  let total2;

  const tableData = props.tableData.countOppsData;
  const nettedData = props.tableData.sankeyFormattedData;
  const uniqueNewQuarters = tableData
    .map((item) => item.Projected_Close_Date_New_Fiscal_Quarter)
    .filter(getUniqueValues)
    .sort();
  const uniqueOldQuarters = tableData
    .map((item) => item.Projected_Close_Date_Old_Fiscal_Quarter)
    .filter(getUniqueValues)
    .sort();
  const uniqueTargets = nettedData
    .map((item) => item.target)
    .filter(getUniqueValues)
    .sort();
  const uniqueSources = nettedData
    .map((item) => item.source)
    .filter(getUniqueValues)
    .sort();

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");
    const tableContainer2 = document.getElementById("myTableContainer-2");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
      // if (tableContainer2) {
      //   setIsOverflowing2(
      //     tableContainer2.scrollWidth > tableContainer2.offsetWidth
      //   );
      // }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    // const tableContainer2 = tableContainerRef2.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
      // if (tableContainer2.scrollLeft > 0) {
      //   setIsSticky2(true);
      // } else {
      //   setIsSticky2(false);
      // }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);
    // tableContainer2.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
      // tableContainer2.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);
  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "98%",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="center">
          {/* <Grid item xs={12} style={{ padding: "2rem 0.5rem" }}>
            <D3SankeyContainer tableData={props.tableData} />
          </Grid> */}
          {/* <Grid style={{ padding: "0.5rem" }} item xs={12}> */}
          <TableContainer
            style={{
              width: "auto",
              // padding: "0.5rem",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id="myTableContainer"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              ref={tableRef}
              data-test={"pcd_moved_table-actual"}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ minWidth: 100, maxWidth: 100 }}
                    rowSpan={2}
                  >
                    From <span style={{ fontSize: "0.85rem" }}> &#8595; </span>{" "}
                    / To <span style={{ fontSize: "0.85rem" }}> &#8594; </span>{" "}
                  </TableCell>
                  {uniqueNewQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 100,
                                maxWidth: 100,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                minWidth: 100,
                                maxWidth: 100,
                              }
                        }
                        className={classes.repHeader}
                        colspan={2}
                      >
                        {i}
                      </TableCell>
                    );
                  })}

                  {uniqueNewQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueNewQuarters.length > 1 &&
                          uniqueNewQuarters.length % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 100,
                                maxWidth: 100,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                minWidth: 100,
                                maxWidth: 100,
                              }
                        }
                        align="center"
                        className={classes.repHeader}
                        colspan={2}
                      >
                        {"Grand Total"}
                      </TableCell>
                    </React.Fragment>
                  )}
                  {uniqueNewQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 200, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  {uniqueNewQuarters.map((i, index) => {
                    return (
                      <>
                        <TableCell
                          align="center"
                          style={{
                            // background: "#4471c4",
                            // color: "white",
                            minWidth: 70,
                            maxWidth: 70,
                          }}
                          className={classes.repHeader}
                        >
                          # of Opps
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            // background: "#4471c4",
                            // color: "white",
                            minWidth: 100,
                            maxWidth: 100,
                          }}
                          className={classes.repHeader}
                        >
                          {/* ACV at Start */}
                          {`${props.Value_Label} at Start`}
                        </TableCell>
                      </>
                    );
                  })}

                  {uniqueNewQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        align="center"
                        style={{
                          // background: "#4471c4",
                          // color: "white",
                          minWidth: 70,
                          maxWidth: 70,
                        }}
                        className={classes.repHeader}
                      >
                        # of Opps
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          // background: "#4471c4",
                          // color: "white",
                          minWidth: 100,
                          maxWidth: 100,
                        }}
                        className={classes.repHeader}
                      >
                        {/* ACV at Start */}
                        {`${props.Value_Label} at Start`}
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueOldQuarters.map((row, i) => {
                  total = i + 1;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // className={classes.repText}
                        className={
                          isSticky
                            ? `${stickyClasses.sticky} ${classes.repText}`
                            : classes.repText
                        }
                        style={
                          isSticky && i % 2 === 1
                            ? { background: "#f5f5f5" }
                            : {}
                        }
                        colSpan={1}
                      >
                        {row}
                      </TableCell>
                      {uniqueNewQuarters.map((cell, j) => {
                        let cellData = tableData.filter(
                          (item) =>
                            item.Projected_Close_Date_New_Fiscal_Quarter ===
                              cell &&
                            item.Projected_Close_Date_Old_Fiscal_Quarter === row
                        );
                        cellData =
                          cellData.length <= 0
                            ? { count: 0, acv: 0 }
                            : cellData[0];
                        return (
                          <React.Fragment key={j}>
                            <TableCell
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  {
                                    oldQtr: [row],
                                    newQtr: [cell],
                                  },
                                  "actual"
                                )
                              }
                            >
                              {cellData !== undefined
                                ? countOrACVFormatter(cellData.count, false)
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  {
                                    oldQtr: [row],
                                    newQtr: [cell],
                                  },
                                  "actual"
                                )
                              }
                            >
                              {cellData !== undefined
                                ? countOrACVFormatter(cellData.acv, true)
                                : "-"}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                      {uniqueNewQuarters.length > 1 && (
                        <React.Fragment>
                          <TableCell
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  oldQtr: [row],
                                  newQtr: uniqueNewQuarters,
                                },
                                "actual"
                              )
                            }
                          >
                            {countOrACVFormatter(
                              tableData
                                .filter(
                                  (item) =>
                                    item.Projected_Close_Date_Old_Fiscal_Quarter ===
                                    row
                                )
                                .map((item) => item.count)
                                .reduce((a, b) => a + b, 0),
                              false
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  oldQtr: [row],
                                  newQtr: uniqueNewQuarters,
                                },
                                "actual"
                              )
                            }
                          >
                            {countOrACVFormatter(
                              tableData
                                .filter(
                                  (item) =>
                                    item.Projected_Close_Date_Old_Fiscal_Quarter ===
                                    row
                                )
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0),
                              true
                            )}
                          </TableCell>
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    // style={{ fontWeight: 600 }}
                    align="left"
                    // className={classes.repText}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repText}`
                        : classes.repText
                    }
                    style={
                      isSticky && total % 2 === 1
                        ? { background: "#f5f5f5", fontWeight: 600 }
                        : { fontWeight: 600 }
                    }
                    colSpan={1}
                  >
                    Grand Total
                  </TableCell>
                  {uniqueNewQuarters.map((cell, j) => {
                    const columnValue = tableData
                      .filter(
                        (item) =>
                          item.Projected_Close_Date_New_Fiscal_Quarter === cell
                      )
                      .map((item) => item.count)
                      .reduce((a, b) => a + b, 0);
                    const columnAcv = tableData
                      .filter(
                        (item) =>
                          item.Projected_Close_Date_New_Fiscal_Quarter === cell
                      )
                      .map((item) => item.acv)
                      .reduce((a, b) => a + b, 0);

                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          className={classes.repNumber}
                          style={{ cursor: "pointer" }}
                          colSpan={1}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                oldQtr: uniqueOldQuarters,
                                newQtr: [cell],
                              },
                              "actual"
                            )
                          }
                        >
                          {columnValue !== undefined
                            ? countOrACVFormatter(columnValue, false)
                            : "-"}
                        </TableCell>
                        <TableCell
                          className={classes.repNumber}
                          style={{ cursor: "pointer" }}
                          colSpan={1}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                oldQtr: uniqueOldQuarters,
                                newQtr: [cell],
                              },
                              "actual"
                            )
                          }
                        >
                          {columnValue !== undefined
                            ? countOrACVFormatter(columnAcv, true)
                            : "-"}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueNewQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        style={{ cursor: "pointer" }}
                        colSpan={1}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              oldQtr: uniqueOldQuarters,
                              newQtr: uniqueNewQuarters,
                            },
                            "actual"
                          )
                        }
                      >
                        {countOrACVFormatter(
                          tableData
                            .map((item) => item.count)
                            .reduce((a, b) => a + b, 0),
                          false
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        style={{ cursor: "pointer" }}
                        colSpan={1}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            {
                              oldQtr: uniqueOldQuarters,
                              newQtr: uniqueNewQuarters,
                            },
                            "actual"
                          )
                        }
                      >
                        {countOrACVFormatter(
                          tableData
                            .map((item) => item.acv)
                            .reduce((a, b) => a + b, 0),
                          true
                        )}
                      </TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* </Grid> */}
        </Grid>
      </CardContent>
    </div>
  );
}
