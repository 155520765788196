import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const LocationRangeFilter = ({
  value,
  onChange,
  options,
  classes,
  paddingAvailable = false,
}) => {
  //   const classes = useStyles();

  return (
    <Grid item xs={12} container component={Paper} spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        style={paddingAvailable ? {} : { paddingTop: 0 }}
      >
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel
            className={classes.filterLabel}
            style={{ color: !value.includes("All") ? "#4472c4" : "#000" }}
            id="select-region-label"
          >
            Location Range:
          </InputLabel>
          <Select
            data-test="Of_Locations_Range"
            value={value}
            onChange={onChange}
            name="Of_Locations_Range"
            multiple
          >
            <MenuItem value={"All"}>All</MenuItem>
            {options &&
              options
                .sort((a, b) => a.sequence - b.sequence)
                .map((item) => (
                  <MenuItem key={item.sequence} value={item.Of_Locations_Range}>
                    {item.Of_Locations_Range}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default LocationRangeFilter;
