import { capitalize, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { format as commaFormatter, map } from 'd3'
import { countOrACVFormatter, getUniqueValues, StyledTableRow, _isNaN } from '../../../../util/customFunctions'
import tableStyle from '../../../styles/tableStyle'
import NoDataCell from '../../AccountHealth/components/NoDataCell'
import { customRow, renewalsRow } from './utils'
import { decamelize } from '../../../../util/dataFormatter'

export default function RenewalTrendsTable(props) {
    const classes = tableStyle()

    let uniqueRows = props.data.map(item => item[props.row]).filter(getUniqueValues)
    let missingCustomRows = uniqueRows.filter(obj => customRow.indexOf(obj) == -1)
    let rows = [...customRow, ...missingCustomRows]
    const columns = props.data.map(item => item[props.column]).filter(getUniqueValues)

    const uniqueTypes = props.data.map(item => item[props.type]).filter(getUniqueValues)
    let types;
    if (props.type === "Account_Segment") {
        let missingRows = uniqueTypes.filter(function (obj) { return renewalsRow.indexOf(obj) == -1; })
        types = [...renewalsRow, ...missingRows]
    } else {
        types = uniqueTypes
    }

    const drilldownhandler = (row, column, type) => {
        const convertedType = decamelize(type).split(" ").map(type => capitalize(type)).join(" ")
        let currentStatus, renewalOverDue, quarterFilter, rowFilter;
        if (row === "CompletedOnTime" || row === "CompletedDelayed") {
            currentStatus = { name: 'current_status', value: ['Win'], string: true }
            if (row === "CompletedOnTime") {
                renewalOverDue = { name: 'Renewal_Overdue', value: ["N"], string: true }
            } else if (row === "CompletedDelayed") {
                renewalOverDue = { name: 'Renewal_Overdue', value: ["Y"], string: true }
            }
        } else if (row === "Open" || row === "OpenOverdue") {
            currentStatus = { name: 'current_status', value: ['Open', 'MQL'], string: true }
            if (row === "Open") {
                renewalOverDue = { name: 'Renewal_Overdue', value: ["N"], string: true }
            } else if (row === "OpenOverdue") {
                renewalOverDue = { name: 'Renewal_Overdue', value: ["Y"], string: true }
            }
        } else if (type === "Renewal" || row === null) {
            currentStatus = { name: 'current_status', value: ['Open', 'MQL', 'Win'], string: true }
            renewalOverDue = { name: 'Renewal_Overdue', value: ["Y", "N"], string: true }
        }

        if (types.includes(type)) {
            rowFilter = { name: props.type, value: [type], string: true }
        }
        quarterFilter = { name: props.column, value: typeof (column) === "string" ? [column] : column, string: true }
        let drilldownFilters = [currentStatus, renewalOverDue, quarterFilter, rowFilter]
        console.log(drilldownFilters, drilldownFilters.filter(item => item !== undefined))
        props.handleChangeLocation("drilldownOnGraph", drilldownFilters.filter(item => item !== undefined), convertedType)
    }


    if (props.row === "Account_Segment") {
        const missingRows = rows.filter(function (obj) { return renewalsRow.indexOf(obj) == -1; });
        rows = [...renewalsRow, ...missingRows]
        rows = rows.filter(item => item !== "Prime")
        rows.unshift("Prime")
    }
    return (
        <TableContainer className={classes.root}>
            <Table data-test={props.type} size="small">
                <TableHead></TableHead>
                <TableBody>
                    {
                        props.data.length > 0 ? <>
                            {
                                props.changeFromPriorWeek ? <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                    <TableCell style={{ background: 'white' }}></TableCell>


                                    <TableCell align="center" colSpan={3 * columns.length} className={classes.colHeader} style={{ backgroundColor: '#bf8f00' }}>Get Well by Prior Week</TableCell>
                                </TableRow> : null
                            }
                            <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black', paddingTop: 15, paddingBottom: 15, minWidth: 150 }}>{props.column.split("_").map(item => capitalize(item)).join(" ")}</TableCell>
                                {columns.map((item, index) => <TableCell colSpan={3} align="center" style={index % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} className={classes.colHeader}>{item}</TableCell>)}
                                {columns.length > 1 && <TableCell colSpan={3} align="center" style={columns.length > 1 && columns.length % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} className={classes.colHeader}>{`Total`}</TableCell>}
                            </TableRow>
                            <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black' }}>{props.typeLabel.split("_").map(it => capitalize(it)).join(" ")}</TableCell>
                                {columns.map(item => <>
                                    <TableCell align="center" className={classes.colHeader}>{`${props.countColumnHeading !== undefined ? props.countColumnHeading : `# of Renewals`}`}</TableCell>
                                    <TableCell align="center" className={classes.colHeader}>{`${props.currencyColumnHeading !== undefined ? props.currencyColumnHeading : 'Renewal Value'}`}</TableCell>
                                    <TableCell align="center" className={classes.colHeader}>{`% of Total`}</TableCell>
                                </>)}
                                {
                                    columns.length > 1 &&
                                    <>
                                        <TableCell align="center" className={classes.colHeader}>{`${props.countColumnHeading !== undefined ? props.countColumnHeading : `# of Renewals`}`}</TableCell>
                                        <TableCell align="center" className={classes.colHeader}>{`${props.currencyColumnHeading !== undefined ? props.currencyColumnHeading : 'Renewal Value'}`}</TableCell>
                                        <TableCell align="center" className={classes.colHeader}>{`% of Total`}</TableCell>
                                    </>
                                }
                            </TableRow>
                            {
                                types.map(type => {
                                    const typeSegmentedRow = props.data.filter(item => item[props.type] === type)
                                    const totalData = props.data
                                    return <>
                                        <StyledTableRow key={type} className={classes.fontBold}>
                                            <TableCell className={classes.dataCell}>{type}</TableCell>
                                            {
                                                columns.map(quarter => {
                                                    const cellData = typeSegmentedRow.filter(item => item[props.column] === quarter)
                                                    const totalDataByQuarter = props.data.filter(item => item[props.column] === quarter)
                                                    return <>
                                                        <TableCell onClick={() => drilldownhandler(null, quarter, type)} align="right" className={`${classes.drilldownCell}`}>{countOrACVFormatter(cellData.map(item => item.count).reduce((a, b) => a + b, 0), false)}</TableCell>
                                                        <TableCell onClick={() => drilldownhandler(null, quarter, type)} align="right" className={`${classes.drilldownCell}`}>{countOrACVFormatter(cellData.map(item => item.CARR).reduce((a, b) => a + b, 0), false)}</TableCell>
                                                        <TableCell onClick={() => drilldownhandler(null, quarter, type)} align="right" className={`${classes.drilldownCell}`}>{_isNaN(cellData.reduce((a, b) => a + b.CARR, 0) / totalDataByQuarter.reduce((a, b) => a + b.CARR, 0)) ? 0 : `${Math.round((cellData.reduce((a, b) => a + b.CARR, 0) / totalDataByQuarter.reduce((a, b) => a + b.CARR, 0)) * 100)}%`}</TableCell>
                                                    </>
                                                })
                                            }
                                            {
                                                columns.length > 1 && <>
                                                    <TableCell onClick={() => drilldownhandler(null, columns, type)} align="right" className={`${classes.drilldownCell}`}>{countOrACVFormatter(typeSegmentedRow.map(item => item.count).reduce((a, b) => a + b, 0), false)}</TableCell>
                                                    <TableCell onClick={() => drilldownhandler(null, columns, type)} align="right" className={`${classes.drilldownCell}`}>{countOrACVFormatter(typeSegmentedRow.map(item => item.CARR).reduce((a, b) => a + b, 0), false)}</TableCell>
                                                    <TableCell onClick={() => drilldownhandler(null, columns, type)} align="right" className={`${classes.drilldownCell}`}>{_isNaN(typeSegmentedRow.reduce((a, b) => a + b.CARR, 0) / totalData.reduce((a, b) => a + b.CARR, 0)) ? 0 : `${Math.round((typeSegmentedRow.reduce((a, b) => a + b.CARR, 0) / totalData.reduce((a, b) => a + b.CARR, 0)) * 100)}%`}</TableCell>
                                                </>
                                            }
                                        </StyledTableRow>
                                        {
                                            rows.map(segment => {
                                                const rowData = typeSegmentedRow.filter(item => item[props.row] === segment)
                                                console.log()
                                                return <>
                                                    <StyledTableRow key={segment}>
                                                        <TableCell className={`${classes.dataCell} ${classes.indentName}`}>{decamelize(segment).split(" ").map(item => capitalize(item)).join(" ")}</TableCell>
                                                        {
                                                            columns.map(quarter => {
                                                                const cellData = rowData.filter(item => item[props.column] === quarter)
                                                                const totalByQuarter = typeSegmentedRow.filter(item => item[props.column] === quarter)
                                                                return <>
                                                                    <TableCell align="right" onClick={() => drilldownhandler(segment, quarter, type)} className={`${classes.drilldownCell}`}>{countOrACVFormatter(cellData.map(item => item.count).reduce((a, b) => a + b, 0), false)}</TableCell>
                                                                    <TableCell onClick={() => drilldownhandler(segment, quarter, type)} align="right" className={`${classes.drilldownCell}`}>{countOrACVFormatter(cellData.map(item => item.CARR).reduce((a, b) => a + b, 0), false)}</TableCell>
                                                                    <TableCell onClick={() => drilldownhandler(segment, quarter, type)} align="right" className={`${classes.drilldownCell}`}>{_isNaN(cellData.reduce((a, b) => a + b.CARR, 0) / totalByQuarter.reduce((a, b) => a + b.CARR, 0)) ? 0 : `${Math.round((cellData.reduce((a, b) => a + b.CARR, 0) / totalByQuarter.reduce((a, b) => a + b.CARR, 0)) * 100)}%`}</TableCell>
                                                                </>

                                                            })
                                                        }
                                                        {
                                                            columns.length > 1 && <>
                                                                <TableCell align="right" onClick={() => drilldownhandler(segment, columns, type)} className={`${classes.drilldownCell}`}>{countOrACVFormatter(rowData.map(item => item.count).reduce((a, b) => a + b, 0), false)}</TableCell>
                                                                <TableCell onClick={() => drilldownhandler(segment, columns, type)} align="right" className={`${classes.drilldownCell}`}>{countOrACVFormatter(rowData.map(item => item.CARR).reduce((a, b) => a + b, 0), false)}</TableCell>
                                                                <TableCell onClick={() => drilldownhandler(segment, columns, type)} align="right" className={`${classes.drilldownCell}`}>{_isNaN(rowData.reduce((a, b) => a + b.CARR, 0) / typeSegmentedRow.reduce((a, b) => a + b.CARR, 0)) ? 0 : `${Math.round((rowData.reduce((a, b) => a + b.CARR, 0) / typeSegmentedRow.reduce((a, b) => a + b.CARR, 0)) * 100)}%`}</TableCell>
                                                            </>
                                                        }

                                                    </StyledTableRow>
                                                </>
                                            })
                                        }

                                    </>
                                })
                            }
                            <StyledTableRow key={"Total"} className={classes.fontBold}>
                                <TableCell className={classes.dataCell}>{`Total`}</TableCell>
                                {
                                    columns.map(quarter => {
                                        const cellData = props.data.filter(item => item[props.column] === quarter)
                                        const totalByQuarter = props.data.filter(item => item[props.column] === quarter)
                                        return <>
                                            <TableCell align="right" onClick={() => drilldownhandler(null, quarter, "Renewal")} className={`${classes.drilldownCell}`}>{countOrACVFormatter(cellData.map(item => item.count).reduce((a, b) => a + b, 0), false)}</TableCell>
                                            <TableCell align="right" onClick={() => drilldownhandler(null, quarter, "Renewal")} className={`${classes.drilldownCell}`}>{countOrACVFormatter(cellData.map(item => item.CARR).reduce((a, b) => a + b, 0), false)}</TableCell>
                                            <TableCell align="right" onClick={() => drilldownhandler(null, quarter, "Renewal")} className={`${classes.drilldownCell}`}>{_isNaN(cellData.reduce((a, b) => a + b.CARR, 0) / totalByQuarter.reduce((a, b) => a + b.CARR, 0)) ? 0 : `${Math.round((cellData.reduce((a, b) => a + b.CARR, 0) / totalByQuarter.reduce((a, b) => a + b.CARR, 0)) * 100)}%`}</TableCell>
                                        </>

                                    })
                                }
                                {
                                    columns.length > 1 && <>
                                        <TableCell align="right" onClick={() => drilldownhandler(null, columns, "Renewal")} className={`${classes.drilldownCell}`}>{countOrACVFormatter(props.data.map(item => item.count).reduce((a, b) => a + b, 0), false)}</TableCell>
                                        <TableCell onClick={() => drilldownhandler(null, columns, "Renewal")} align="right" className={`${classes.drilldownCell}`}>{countOrACVFormatter(props.data.map(item => item.CARR).reduce((a, b) => a + b, 0), false)}</TableCell>
                                        <TableCell onClick={() => drilldownhandler(null, columns, "Renewal")} align="right" className={`${classes.drilldownCell}`}>{_isNaN(props.data.reduce((a, b) => a + b.CARR, 0) / props.data.reduce((a, b) => a + b.CARR, 0)) ? 0 : `${Math.round((props.data.reduce((a, b) => a + b.CARR, 0) / props.data.reduce((a, b) => a + b.CARR, 0)) * 100)}%`}</TableCell>
                                    </>
                                }
                            </StyledTableRow>
                        </> : <NoDataCell />
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
