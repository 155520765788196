import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import ViewIcon from "@material-ui/icons/Visibility";
import OffIcon from "@material-ui/icons/VisibilityOff";
const viz_url = process.env.REACT_APP_VIZ_URL;

const LoginForm = ({
  onSubmit,
  classes,
  errorMessage,
  open,
  setErrorMessage,
  persistentAlert,
  handleChangeLocation,
}) => (
  <Formik
    initialValues={{
      email: "",
      password: "",
      checked: false,
      visible: false,
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email("Must be a valid email.")
        .max(255)
        .required("Email is required."),
      password: Yup.string().max(255).required("Password is required."),
    })}
    onSubmit={onSubmit}
  >
    {({
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      touched,
      values,
      setFieldValue,
    }) => (
      <form
        noValidate
        onSubmit={handleSubmit}
        className={classes.formStyle}
        style={{ paddingTop: "10%" }}
      >
        {errorMessage && (
          <Collapse in={open}>
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setErrorMessage("")}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <Typography variant="body1">{errorMessage}</Typography>
            </Alert>
          </Collapse>
        )}
        <TextField
          error={Boolean(touched.email && errors.email)}
          fullWidth
          helperText={touched.email && errors.email}
          label="Email Address"
          margin="normal"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          variant="outlined"
        />
        <TextField
          error={Boolean(touched.password && errors.password)}
          fullWidth
          helperText={touched.password && errors.password}
          label="Password"
          margin="normal"
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password}
          variant="outlined"
          type={values.visible ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => setFieldValue("visible", !values.visible, false)}
              >
                {values.visible ? <OffIcon /> : <ViewIcon />}
              </IconButton>
            ),
          }}
        />
        <Box mt={2}>
          <Button
            color="primary"
            disabled={persistentAlert === "Signing in..."}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Sign in
          </Button>
        </Box>
        <Box mt={2}>
          <Checkbox
            checked={values.checked}
            color="primary"
            className={classes.rememberMeCheck}
            onChange={handleChange}
            name="checked"
          />
          Remember me
        </Box>

        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12} mt={2} className={classes.backButtonContainer}>
            <Button
              disabled={
                persistentAlert !== "" &&
                persistentAlert.message === "Signing in..."
              }
              size="small"
              variant="small"
              onClick={() => handleChangeLocation("forgotPass")}
              className={classes.backButton}
            >
              Forgot Your Password?
            </Button>
          </Grid>
          <Grid item xs={12} mt={2} className={classes.backButtonContainer}>
            <Button
              size="small"
              variant="small"
              href={`${viz_url}/api/auth/salesforce`}
              className={classes.backButton}
            >
              Sign in with Salesforce
            </Button>
          </Grid>
          <Grid item xs={12} mt={2} className={classes.backButtonContainer}>
            <Button
              size="small"
              variant="small"
              href={`${viz_url}/api/auth/activeDirectory`}
              className={classes.backButton}
            >
              Sign in with Microsoft
            </Button>
          </Grid>
        </Grid>
      </form>
    )}
  </Formik>
);

export default LoginForm;
