import React from 'react'
import D3TimeLine2 from './D3TimeLineChart2'
import D3TimeLine from './D3TimeLineChart'
import {
  Card, CardHeader, CardContent, Divider, Typography, Grid, Radio, RadioGroup, FormControlLabel, FormLabel
} from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 375,
    minWidth: 500
  },
  chart: {
    height: '100%'
  }
})

class D3Graphs extends React.Component {

    chart2Data=[
        {Stage:'Suspect',Day:0},
        {Stage:'Qualify',Day:10},
        {Stage:'Demo',Day:30},
        {Stage:'Proposal',Day:50},
        {Stage:'Negotiate',Day:75},
        {Stage:'Won',Day:85}
        ]

    chartData=[
        {Stage:'Suspect',acv:10000,close_dt:'03/31/22',date_At_Stage:'11/01/2021',Day:1},
        {Stage:'Qualify',acv:15000,close_dt:'03/31/22',date_At_Stage:'11/15/2021',Day:15},
        {Stage:'Demo',acv:15000,close_dt:'03/31/22',date_At_Stage:'12/10/2021',Day:25},
        {Stage:'Proposal',acv:15000,close_dt:'04/30/22',date_At_Stage:'12/30/2021',Day:45},
        {Stage:'Negotiate',acv:15000,close_dt:'04/30/22',date_At_Stage:'02/21/2022',Day:98}
        ]

  componentDidMount = () => {
    D3TimeLine.destroy(this._rootNode1)
    D3TimeLine2.destroy(this._rootNode2)
    this._chart1 = D3TimeLine.create(this._rootNode1, { data:this.chartData })
    this._chart2 = D3TimeLine2.create(this._rootNode2, { data:this.chart2Data })
  }

  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
}

_setRef2(componentNode) {
    this._rootNode2 = componentNode;
}

  render() {
    const { classes } = this.props
    return (
      <Grid container spacing={2}>
        <Grid item container spacing={2} justify='space-around'>
          <Grid item xs={12}>
            
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                                justifyContent: "center",
                                //margin: "auto",
                                //paddingLeft:'1rem',
                                
                                width: "100%",
                                }}
                                className="line-container"
                        ref={this._setRef1.bind(this)}
                    />
                </div>
          </Grid>
        <Grid item container spacing={2} justify='space-around'>
          <Grid item xs={12}>
            
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                                justifyContent: "center",
                                //margin: "auto",
                                //padding:'1rem',
                                width: "100%",
                                }}
                                className="line-container"
                        ref={this._setRef2.bind(this)}
                    />
                </div>
          </Grid>
        </Grid>
      </Grid>
      </Grid>
    )
  }
}


function mapStateToProps(state) {
  const {
    funnelFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate
  } = state.app

  return {
    funnelFilters, waterfallData, persistentAlert, waterfallMinDate, waterfallMaxDate,
  }
}

const mapDispatchToProps = (dispatch) => ({

})

const connectedBar = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(D3Graphs))
export { connectedBar as D3MockupGraphs }