import {
  Button,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import { NumberFormatCustom } from "../../components/CustomNumberFormat";
import {
  getDefaultSelectedFiscalQuarters,
  getDefaultSalesType,
} from "../../util/customFunctions";
import { styles } from "./FunnelStyles";
import * as d3 from "d3";
import FunnelTable from "../../components/FunnelTable";
import Loading from "../../components/Loading";
import FunnelInfoCard from "../../components/FunnelInfoCard";
import D3FunnelStack from "./D3FunnelStack";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import FunnelDrilldownBody from "./FunnelDrilldownBody";
import FunnelDrilldownBodyOpen from "./FunnelDrilldownBodyOpen";
import OpenDrilldownBody from "../CreatedFunnel/OpenDrilldownBody";
import OpenDrilldownBody2 from "../CreatedFunnel/OpenDrilldownBody2";
import { filter } from "d3";
import { camelize, decamelize } from "../../util/dataFormatter";
import Skeleton from "@material-ui/lab/Skeleton";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";
import addHeaderdescription from "../../components/HeaderDescription";
import { nearestCompletedFiscalQuarter } from "../../util/fiscalQuarterFunctions";
import LocationRangeFilter from "../../components/LocationRangeFilter";

class Funnel extends Component {
  state = {
    Opportunity_Record_Type: ["All"],
    Record_Type_Name__c: ["All"],
    Type: ["All"],
    closed_year: ["All"],
    Open_Year_Month: ["All"],
    closed_month: ["All"],
    closed_fiscal_quarter: ["All"],
    projected_close_calendar_year: ["All"],
    projected_close_calendar_month: ["All"],
    projected_close_fiscal_quarter: ["All"],
    projected_close_date_year: ["All"],
    projected_close_date_month: ["All"],
    projected_close_date_fiscal_quarter: ["All"],
    Open_Calendar_Year: ["All"],
    Open_Calendar_Month: ["All"],
    Open_Fiscal_Quarter: ["All"],
    Validated_Year: ["All"],
    Validated_Month: ["All"],
    Validated_Fiscal_Quarter: ["All"],
    Qualified_Date_Calendar_Month: ["All"],
    Qualified_Fiscal_Quarter: ["All"],
    Qualified_Date_Calendar_Year: ["All"],
    rep: ["All"],
    Acct_Industry: ["All"],
    Acct_Vertical: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    Cust_Type: ["All"],
    Region: ["All"],
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    Solution_Cat: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    team: ["All"],
    timeFrame: "quarter",
    disabled: [
      // 'year', 'month', 'quarter', 'team', 'acv'
    ],
    repTeam: "rep",
    acv: null,
    acvlt: 0,
    ACV_Range: ["All"],
    acvPreset: "All",
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
    uniqueQuarters: ["All"],
    drilldownStage: "",
    drilldownQuarter: "",
    location: "",
    Booking_Type_NL_CS_US: ["All"],
    repFilter: ["All"],
    teamFilter: ["All"],
    secondLevelLocation: null,
    Executive_Proposal__c: ["All"],
    Product_Line: ["All"],
    Account_Designation__c: ["All"],
    Closed_Year_Month: ["All"],
    Qualified_Year_Month: ["All"],
    Projected_Close_Year_Month: ["All"],
    Of_Locations_Range: ["All"],
    // data: {

    // },
  };
  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Acct_Vertical",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Region",
          LeadSource: "Lead_Source",
          quarter: this.props.currentQuarter.type,
          year: "Open_Calendar_Year",
          month: "Open_Calendar_Month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
          Product_Line: "Product_Line",
          closed_fiscal_quarter: "closed_fiscal_quarter",
          Closed_Year_Month: "Closed_Year_Month",
          Qualified_Year_Month: "Qualified_Year_Month",
          Projected_Close_Year_Month: "Projected_Close_Year_Month",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          LeadSource: "Lead_Source",
          quarter: "Open_Fiscal_Quarter",
          year: "Open_Calendar_Year",
          month: "Open_Calendar_Month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Product_Line: "Product_Line",
          Executive_Proposal__c: "Executive_Proposal__c",
          closed_fiscal_quarter: "closed_fiscal_quarter",
          Account_Designation__c: "Account_Designation__c",
          Closed_Year_Month: "Closed_Year_Month",
          Qualified_Year_Month: "Qualified_Year_Month",
          Projected_Close_Year_Month: "Projected_Close_Year_Month",
          Of_Locations_Range: "Of_Locations_Range",
        };

  filters = [
    this.variableCols.year,
    this.variableCols.month,
    this.props.currentQuarter.type,
    "rep",
    "team",
    "ACV_Range",
    this.variableCols.CustomerSegment,
    this.variableCols.CustomerType,
    this.variableCols.Industry,
    "Acct_Segment",
    this.variableCols.LeadSource,
    "Opp_Created_By",
    "Solution_Cat",
    "Booking_Type_NL_CS_US",
    "deal_category__c",
    "Qualified_Fiscal_Quarter",
    "Qualified_Date_Calendar_Year",
    "Qualified_Date_Calendar_Month",
    "cross_sell1__c",
    "Type",
    "Product_Line",
    "Executive_Proposal__c",
    "closed_fiscal_quarter",
    "projected_close_fiscal_quarter",
    "Account_Designation__c",
    "Opportunity_Record_Type",
    "Record_Type_Name__c",
    "Open_Year_Month",
    "Closed_Year_Month",
    "Qualified_Year_Month",
    "Projected_Close_Year_Month",
    "Of_Locations_Range",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  stages =
    this.props.funnelData !== ""
      ? this.props.funnelData.newTable.map((item) => item.stage)
      : null;

  staticCustSegment =
    this.props.company === "C0002"
      ? [
          { value: "<=$100M", displayValue: "<=$100M" },
          { value: "$100M-$1B", displayValue: "$100M-$1B" },
          { value: ">$1B", displayValue: ">$1B" },
        ]
      : [
          { value: "<=$25M", displayValue: "<=$25M" },
          { value: "$25-50M", displayValue: "$25M-50M" },
          { value: "$50-250M", displayValue: "$50M-250M" },
          { value: "$250-500M", displayValue: "$250M-500M" },
          { value: "$500M-2B", displayValue: "$500M-2B" },
          { value: ">$2B", displayValue: ">$2B" },
        ];

  componentDidMount() {
    this.props.getFunnelFilters(this.props.company);
    window.addEventListener("resize", this.onResize);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelFilters !== "" && prevProps.funnelFilters === "") {
      // let temp = this.props.funnelFilters.filter(y => y.type === this.props.currentQuarter.type && y[this.props.currentQuarter.type] !== '')[1][this.props.currentQuarter.type]

      let temp = this.props.currentQuarter.current_fiscal_quarter;
      let defaultQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelFilters,
        this.props.currentQuarter.type
      );

      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      let solutioncat = ["All"];
      dealsTypes = getDefaultSalesType(
        this.props.funnelFilters.filter((s) => s.type === "Type"),
        "Type"
      );
      solutioncat = getDefaultSalesType(
        this.props.funnelFilters.filter((s) => s.type === "Solution_Cat"),
        "Solution_Cat"
      );
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelFilters.filter((s) => s.type === "cross_sell1__c"),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelFilters.filter((s) => s.type === "Type"),
          "Type"
        );
      }

      this.setState({
        ...prevState,
        [this.props.currentQuarter.type]: defaultQuarter,
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
        Solution_Cat: solutioncat,
        filtersForDrilldown: [
          { title: "Open Fiscal Qtr.", value: defaultQuarter },
        ],
        uniqueQuarters: [temp],
      });
    }
    if (this.props.funnelData !== "") {
      D3FunnelStack.destroy(this._rootNode1);
      D3FunnelStack.destroy(this._rootNode2);

      this._chart1 = D3FunnelStack.create(this._rootNode1, {
        data: this.props.funnelData.newTable,
        handleChangeLocation: this.handleChangeLocation,
        handleChangeLocationNew: this.handleChangeLocationNew,
        isAcv: false,
        company: this.props.company,
        type: "count",
        wonLabel: this.props.funnelData.wonLabel,
        companyData: this.props.funnelData.newTableCompany,
        Win_Rate_Prefix: this.props.Win_Rate_Prefix,
        showTeamData:
          this.state.repFilter.includes("All") &&
          this.state.teamFilter.includes("All")
            ? false
            : true,
      });
      this._chart2 = D3FunnelStack.create(this._rootNode2, {
        data: this.props.funnelData.newTable,
        handleChangeLocation: this.handleChangeLocation,
        handleChangeLocationNew: this.handleChangeLocationNew,
        isAcv: true,
        company: this.props.company,
        type: "acv",
        wonLabel: this.props.funnelData.wonLabel,
        companyData: this.props.funnelData.newTableCompany,
        Value_Label: this.props.Value_Label,
        Win_Rate_Prefix: this.props.Win_Rate_Prefix,
        showTeamData:
          this.state.repFilter.includes("All") &&
          this.state.teamFilter.includes("All")
            ? false
            : true,
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    D3FunnelStack.destroy(this._rootNode1);
    D3FunnelStack.destroy(this._rootNode2);
  }
  handleChange = (e) => {
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string:
            f === "team"
              ? true
              : this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0]
              ? this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0].string === "Y"
              : true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    console.log(filters);
    if (this.state.acv !== null) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }
    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }
    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      repFilter: this.state.rep,
      teamFilter: this.state.team,
    });
    this.props.getFunnelDataNew(filters);
  };

  orderRepsByLastName = () => {
    let temp = this.props.funnelFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  gridApi = null;
  columnApi = null;
  params = null;

  gridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.params = params;
  };
  stepBack = () => {
    this.setState({
      ...this.state,
      selectedDrilldownStage: "stage1",
    });
    this.props.clearDrilldownOpen2Data();
  };

  stepBack1 = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      this.columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };
  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };
  handleChangeTimeFrame = (e) => {
    this.setState({
      ...this.state,
      timeFrame: e.target.value,
      disabled:
        e.target.value === "custom"
          ? [
              ...this.state.disabled.filter(
                (f) => f !== "year" && f !== "month" && f !== "quarter"
              ),
              "Open_Fiscal_Quarter",
            ]
          : [
              ...this.state.disabled.filter((f) => f !== "Open_Fiscal_Quarter"),
              "year",
              "month",
              "quarter",
            ],
    });
  };

  handleChangeLocationNew = (location, drilldownFilters, key, cameTo) => {
    console.log(location, drilldownFilters, key, cameTo);
    // console.log(this.passFiltersForDrilldown())
    let filters = this.props.currentFilters;
    var closedStage;
    if (key === "orange") {
      // console.log(drilldownFilters)
      this.props.setTable(
        `Lost / Disqualified from ${
          typeof drilldownFilters !== "string"
            ? "Open stages"
            : drilldownFilters
        }`
      );
      filters = [
        ...filters,
        {
          name: "final_stage_for_closed",
          value:
            typeof drilldownFilters === "string"
              ? [drilldownFilters]
              : drilldownFilters,
          string: true,
        },
      ];
    } else if (key === "blue") {
      this.props.setTable(
        `Active at ${
          typeof drilldownFilters !== "string"
            ? "Open stages"
            : drilldownFilters
        }`
      );
      filters = [
        ...filters,
        {
          name: "stage",
          value:
            typeof drilldownFilters === "string"
              ? [drilldownFilters]
              : drilldownFilters,
          string: true,
        },
      ];
    } else if (key === "movedForwardFromAll" && cameTo !== true) {
      this.props.setTable("Moved forward");
    } else if (key === "movedForwardFromAll" && cameTo === true) {
      this.props.setTable(
        `Came to ${this.props.funnelData.firstStage} and moved forward`
      );
    } else if (key === "backToNonOpenStages") {
      this.props.setTable("Back to non-open stages - Alive");
    } else if (key === "closedWon") {
      this.props.setTable(`${this.props.funnelData.wonLabel} Opportunities`);
    } else if (key === "lostOrDisqualifiedFromNonOpenStages") {
      this.props.setTable("Lost / Disqualified from non-open stages");
    } else if (key === "totalOpportunities") {
      this.props.setTable("Total Opportunities Opened");
    } else if (cameTo === true) {
      closedStage = key.replace("movedForward", "");
      key = "movedForward";
      var tableName = `Came to ${drilldownFilters} and moved forward`;
      this.props.setTable(tableName);
    } else {
      //let tableName = decamelize(key).split(" ").map(it => it !== "forward" ? capitalize(it) : it)
      let tableName = decamelize(key)
        .split(" ")
        .map((it) => (it.includes("forward") ? "forward" : capitalize(it)));
      console.log(tableName);
      key = decamelize(tableName[0]) + capitalize(tableName[1]);
      console.log(key);
      closedStage = drilldownFilters;
      if (tableName.join(" ") !== this.props.funnelData.wonLabel) {
        tableName.splice(tableName.length - 1, 0, "from");
        this.props.setTable(`Moved forward from ${drilldownFilters}`);
      }
    }

    this.props.getChartDrilldown(this.props.company, filters, key, closedStage);
    this.setState({
      ...this.state,
      location: location,
      locationRep: location,
    });
  };

  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  handleChangeLocation = (location, stage, lastStage) => {
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f].map((item) => item),
          string:
            f === "team"
              ? true
              : this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0]
              ? this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0].string === "Y"
              : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (this.state.acv !== null) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }
    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }
    this.setState({
      ...this.state,
      location: location,
      selectedDrilldownStage: stage,
      lastStage: lastStage,
      locationRep: location,
    });

    if (location !== "overview" && location === "drilldownOnGraph") {
      this.props.getCreatedDrilldownOnGraph({ stage: stage, filters });
    } else if (location === "drilldownOnOpen" && stage === "stage1") {
      this.props.getCreatedDrilldownOnOpen({ filters: filters });
    } else if (location === "drilldownOnOpen" && stage && stage !== "stage1") {
      console.log("open drilldown 2 ");
      this.props.getCreatedDrilldownOnOpen2({ stage: stage, filters: filters });
    } else {
      this.props.clearDrilldownData();
      this.props.clearDrilldownOpenData();
      this.props.clearDrilldownOpen2Data();
    }
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    if (!this.state.rep.includes("All"))
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    if (!this.state.Account_Designation__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Account Designation",
          value: this.state.Account_Designation__c,
        },
      ];
    if (!this.state.cross_sell1__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Sales Type", value: this.state.cross_sell1__c },
      ];

    if (!this.state.ACV_Range.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Range`,
          value: this.state.ACV_Range,
        },
      ];

    if (!this.state.Open_Year_Month.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Open Month",
          value: this.state.Open_Year_Month,
        },
      ];

    if (!this.state.Product_Line.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Product Cat.",
          value: this.props.funnelFilters
            .filter(
              (y) =>
                y.type === this.variableCols.Product_Line &&
                y[this.variableCols.Product_Line] !== "" &&
                this.state.Product_Line.includes(y.Product_Line)
            )
            .map((item) => item.label),
        },
      ];

    if (!this.state.Executive_Proposal__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proposal Desk Review",
          value: this.state.Executive_Proposal__c,
        },
      ];

    if (!this.state.Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0017" ? "Opp. Type" : "Deal Type",
          value: this.state.Type,
        },
      ];

    if (this.state.acv !== null && this.state.acv !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Greater Than`,
          value:
            `${this.props.Currency_Symbol}` +
            d3.format(",")(
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv
            ),
        },
      ];
    if (!this.state.acvlt !== null && this.state.acvlt !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Less Than`,
          value:
            `${this.props.Currency_Symbol}` +
            d3.format(",")(
              this.state.acvlt && typeof this.state.acvlt === "string"
                ? this.state.acvlt.match(/,/g)
                  ? this.state.acvlt.match(/,/g).length === 1
                    ? parseInt(this.state.acvlt) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acvlt) * 1000000
                    : parseInt(this.state.acvlt)
                  : parseInt(this.state.acvlt)
                : this.state.acvlt
            ),
        },
      ];

    if (!this.state[this.variableCols.projectedQuarter].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Fiscal Qtr.",
          value: this.state[this.variableCols.projectedQuarter].sort(),
        },
      ];

    if (!this.state[this.variableCols.closed_fiscal_quarter].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Fiscal Qtr.",
          value: this.state[this.variableCols.closed_fiscal_quarter].sort(),
        },
      ];

    if (!this.state[this.variableCols.Closed_Year_Month].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Month",
          value: this.state[this.variableCols.Closed_Year_Month].sort(),
        },
      ];

    if (!this.state[this.variableCols.Qualified_Year_Month].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Qualified Month",
          value: this.state[this.variableCols.Qualified_Year_Month].sort(),
        },
      ];

    if (
      !this.state[this.variableCols.Projected_Close_Year_Month].includes("All")
    )
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Closed Month",
          value:
            this.state[this.variableCols.Projected_Close_Year_Month].sort(),
        },
      ];

    if (!this.state[this.variableCols.projectedYear].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Calendar Year",
          value: this.state[this.variableCols.projectedYear].sort(),
        },
      ];

    if (!this.state[this.variableCols.projectedMonth].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Calendar Month",
          value: this.state[this.variableCols.projectedMonth]
            .sort((a, b) => (a < b ? -1 : 1))
            .map((i) => this.months[i - 1]),
        },
      ];

    if (!this.state.Qualified_Fiscal_Quarter.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Qualified Fiscal Qtr.",
          value: this.state.Qualified_Fiscal_Quarter.sort(),
        },
      ];

    if (!this.state.Qualified_Date_Calendar_Year.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Qualified Calendar Year",
          value: this.state.Qualified_Date_Calendar_Year.sort(),
        },
      ];

    if (!this.state.Qualified_Date_Calendar_Month.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Qualified Calendar Month",
          value: this.state.Qualified_Date_Calendar_Month.sort((a, b) =>
            a < b ? -1 : 1
          ).map((i) => this.months[i - 1]),
        },
      ];

    if (!this.state[this.variableCols.Industry].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value: this.state[this.variableCols.Industry],
        },
      ];

    if (!this.state[this.variableCols.CustomerSegment].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Customer Segment",
          value: this.state[this.variableCols.CustomerSegment],
        },
      ];

    if (!this.state[this.variableCols.CustomerType].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value: this.state[this.variableCols.CustomerType],
        },
      ];

    if (!this.state[this.props.currentQuarter.type].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Open Fiscal Qtr."
              : "Open Fiscal Qtr.",
          value: this.state[this.props.currentQuarter.type].sort(),
        },
      ];

    if (!this.state[this.variableCols.year].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Open Fiscal Year"
              : "Open Fiscal Year",
          value: this.state[this.variableCols.year].sort(),
        },
      ];

    if (!this.state[this.variableCols.month].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Open Fiscal Month"
              : "Open Fiscal Month",
          value: this.state[this.variableCols.month]
            .sort((a, b) => (a < b ? -1 : 1))
            .map((i) => this.months[i - 1]),
        },
      ];

    if (!this.state[this.variableCols.LeadSource].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Lead Source",
          value: this.state[this.variableCols.LeadSource],
        },
      ];

    if (!this.state.Opp_Created_By.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state.Solution_Cat.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];

    if (
      this.props.company === "C0013" &&
      !this.state.Opportunity_Record_Type.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        {
          title: "Opportunity Record Type",
          value: this.state.Opportunity_Record_Type,
        },
      ];

    if (
      this.props.company === "C0010" &&
      !this.state.Record_Type_Name__c.includes("All")
    ) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Opportunity Record Type",
          value: this.state.Record_Type_Name__c,
        },
      ];
    }

    if (
      this.props.company !== "C0003" &&
      !this.state[this.variableCols.deal_category__c].includes("All")
    )
      filtersArray = [
        ...filtersArray,
        {
          title: "Deal Category",
          value: this.state[this.variableCols.deal_category__c].sort(),
        },
      ];

    return filtersArray;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }

  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }
  handleChangeLocationOpen = (location) => {
    if (location === "") {
    }
  };

  onResize = async () => {
    D3FunnelStack.destroy(this._rootNode1);
    D3FunnelStack.destroy(this._rootNode2);

    this._chart1 = D3FunnelStack.create(this._rootNode1, {
      data: this.props.funnelData.newTable,
      handleChangeLocation: this.handleChangeLocation,
      handleChangeLocationNew: this.handleChangeLocationNew,
      isAcv: false,
      company: this.props.company,
      type: "count",
      wonLabel: this.props.funnelData.wonLabel,
      companyData: this.props.funnelData.newTableCompany,
      Win_Rate_Prefix: this.props.Win_Rate_Prefix,
      showTeamData:
        this.state.repFilter.includes("All") &&
        this.state.teamFilter.includes("All")
          ? false
          : true,
    });
    this._chart2 = D3FunnelStack.create(this._rootNode2, {
      data: this.props.funnelData.newTable,
      handleChangeLocation: this.handleChangeLocation,
      handleChangeLocationNew: this.handleChangeLocationNew,
      isAcv: true,
      company: this.props.company,
      type: "acv",
      wonLabel: this.props.funnelData.wonLabel,
      companyData: this.props.funnelData.newTableCompany,
      Value_Label: this.props.Value_Label,
      Win_Rate_Prefix: this.props.Win_Rate_Prefix,
      showTeamData:
        this.state.repFilter.includes("All") &&
        this.state.teamFilter.includes("All")
          ? false
          : true,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={1} style={{ position: "relative" }}>
        {/* Caption and description */}
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center" style={{ marginBottom: 5 }}>
            Win Rate Cohort Analysis – Created Deals
          </Typography>
          <Typography variant="body1">
            The Win Rate cohort analysis for Created Deals enables you to
            examine all the deals that were created during a given time frame
            and analyze the win rate as opportunities moved down the funnel.
            Visualizing where opportunities drop off can pinpoint specific
            challenges that need corrective action. This analysis provides you
            insights by opportunity count as well as ACV.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          `${this.props.Win_Rate_Prefix} Cohort Analysis – Created Deals`,
          `The ${this.props.Win_Rate_Prefix} cohort analysis for Created Deals enables you to examine all the deals that were created during a given time frame and analyze the ${this.props.Win_Rate_Prefix} as opportunities moved down the funnel. Visualizing where opportunities drop off can pinpoint specific challenges that need corrective action. This analysis provides you insights by opportunity count as well as ${this.props.Value_Label}.`
        )}
        {this.props.funnelFilters !== "" ? (
          <>
            <Grid item container xs={12}>
              <Grid item container xs={12}>
                {/* dates */}
                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  xs={12}
                  lg={4}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    justify="space-around"
                  >
                    <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={
                          this.state.Open_Year_Month[0] !== "All" ||
                          this.state.Open_Year_Month[0] !== "All"
                        }
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.props.currentQuarter.type
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-fiscalQuarter-label"
                        >
                          {this.props.company === "C0003" ? "Open " : "Open "}{" "}
                          Fiscal Qtr.:
                        </InputLabel>
                        <Select
                          value={this.state[this.props.currentQuarter.type]}
                          onChange={this.handleChange}
                          name={this.props.currentQuarter.type}
                          multiple
                          data-test="open-fiscal-quarter"
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === this.props.currentQuarter.type &&
                                  y[this.props.currentQuarter.type] !==
                                    undefined
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.props.currentQuarter.type]}
                                  value={y[this.props.currentQuarter.type]}
                                >
                                  {y[this.props.currentQuarter.type]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        disabled={
                          !this.state[this.props.currentQuarter.type].includes(
                            "All"
                          )
                        }
                      >
                        <InputLabel
                          style={{
                            color:
                              this.state.activeTimeFilter ===
                              this.props.currentQuarter.type
                                ? "#4472c4"
                                : "#000",
                          }}
                          id="select-vYearMonth-label"
                        >
                          Open Month:
                        </InputLabel>
                        <Select
                          labelId="select-vprojected_close_year_month-label"
                          id="select-cClosed_Year_Month"
                          value={this.state.Open_Year_Month}
                          onChange={this.handleChange}
                          label="Open_Year_Month"
                          name="Open_Year_Month"
                          data-test="open-year-month"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters === "" && (
                            <MenuItem value={nearestCompletedFiscalQuarter()}>
                              {nearestCompletedFiscalQuarter()}
                            </MenuItem>
                          )}
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === "Open_Year_Month" &&
                                  y["Open_Year_Month"] !== "" &&
                                  y["Open_Year_Month"] !== null
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y["Open_Year_Month"]}
                                  value={y["Open_Year_Month"]}
                                >
                                  {y["Open_Year_Month"]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={
                          this.state[this.props.currentQuarter.type][0] !==
                          "All"
                        }
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[this.variableCols.year].includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-year-label"
                        >
                          {this.props.company === "C0003" ? "Open " : "Open "}{" "}
                          Calendar Year:
                        </InputLabel>
                        <Select
                          value={this.state[this.variableCols.year]}
                          onChange={this.handleChange}
                          name={this.variableCols.year}
                          data-test="year"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters.map((y) =>
                              y.type === this.variableCols.year ? (
                                <MenuItem
                                  key={y[this.variableCols.year]}
                                  value={y[this.variableCols.year]}
                                >
                                  {y[this.variableCols.year]}
                                </MenuItem>
                              ) : null
                            )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                      <FormControl
                        disabled={
                          this.state[this.props.currentQuarter.type][0] !==
                          "All"
                        }
                        fullWidth
                        className={classes.formControl}
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.month
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-month-label"
                        >
                          {this.props.company === "C0003" ? "Open " : "Open "}{" "}
                          Calendar Month:
                        </InputLabel>
                        <Select
                          value={this.state[this.variableCols.month]}
                          onChange={this.handleChange}
                          name={this.variableCols.month}
                          data-test="month"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                            <MenuItem key={y} value={y}>
                              {this.months[y - 1]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}
                  </Grid>
                </Grid>

                {/* Rep Team */}
                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  xs={12}
                  lg={4}
                >
                  <Grid item spacing={2} xs={12} container component={Paper}>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <Autocomplete
                        id="combo-box-demo"
                        data-test="rep"
                        options={
                          this.props.funnelFilters !== "" &&
                          this.orderRepsByLastName()
                        }
                        getOptionLabel={(option) => option}
                        value={this.state.rep[0]}
                        onChange={this.onRepChange}
                        name="rep"
                        disabled={!this.state.team.includes("All")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              style: {
                                color: !this.state.rep.includes("All")
                                  ? "#4472c4"
                                  : "#000",
                              },
                            }}
                            label="Rep:"
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={!this.state.rep.includes("All")}
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.team.includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-team-label"
                        >
                          Team:
                        </InputLabel>
                        <Select
                          value={this.state.team}
                          onChange={this.handleChange}
                          name="team"
                          multiple
                          data-test="team"
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === "team" && y.Display_Name !== ""
                              )
                              .sort((a, b) =>
                                a.Display_Name < b.Display_Name
                                  ? -1
                                  : a.Display_Name > b.Display_Name
                                  ? 1
                                  : 0
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y.Display_Name}
                                  value={y.Display_Name}
                                >
                                  {y.Display_Name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {/* ACV and GO */}
                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  justify="space-between"
                  xs={3}
                  lg={3}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={8}
                    xl={9}
                    container
                    component={Paper}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{ paddingTop: 0, height: "0px" }}
                    >
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.ACV_Range.includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          {this.props.Value_Label} Range:
                        </InputLabel>
                        <Select
                          data-test="ACV_Range"
                          value={this.state.ACV_Range}
                          onChange={this.handleChange}
                          name="ACV_Range"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === "ACV_Range" && y.ACV_Range !== ""
                              )
                              .map((y) => (
                                <MenuItem key={y.ACV_Range} value={y.ACV_Range}>
                                  {y.ACV_Range}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Button
                    data-test="go"
                    variant="contained"
                    color="primary"
                    onClick={this.handleGo}
                    disabled={this.state.acvError !== ""}
                  >
                    GO
                  </Button>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                {/* dates */}
                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  xs={12}
                  lg={4}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    justify="space-around"
                  >
                    <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        disabled={this.state.Closed_Year_Month[0] !== "All"}
                        className={classes.formControl}
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.closed_fiscal_quarter.includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-closedFiscalQuarter-label"
                        >
                          Closed Fiscal Qtr.:
                        </InputLabel>
                        <Select
                          value={this.state.closed_fiscal_quarter}
                          onChange={this.handleChange}
                          name={"closed_fiscal_quarter"}
                          multiple
                          data-test="closed_fiscal_quarter-fiscal-quarter"
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === "closed_fiscal_quarter" &&
                                  y["closed_fiscal_quarter"] !== undefined
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y["closed_fiscal_quarter"]}
                                  value={y["closed_fiscal_quarter"]}
                                >
                                  {y["closed_fiscal_quarter"]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={this.state.closed_fiscal_quarter[0] !== "All"}
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.Closed_Year_Month.includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-fiscalQuarter-label"
                        >
                          Closed Month:
                        </InputLabel>
                        <Select
                          value={this.state.Closed_Year_Month}
                          onChange={this.handleChange}
                          name={"Closed_Year_Month"}
                          multiple
                          data-test="Closed_Year_Month"
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === "Closed_Year_Month" &&
                                  y["Closed_Year_Month"] !== undefined
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y["Closed_Year_Month"]}
                                  value={y["Closed_Year_Month"]}
                                >
                                  {y["Closed_Year_Month"]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  xs={12}
                  lg={4}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    justify="space-around"
                  >
                    <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={this.state.Qualified_Year_Month[0] !== "All"}
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color:
                              !this.state.Qualified_Fiscal_Quarter.includes(
                                "All"
                              )
                                ? "#4472c4"
                                : "#000",
                          }}
                          id="select-fiscalQuarter-label"
                        >
                          Qualified Fiscal Qtr.:
                        </InputLabel>
                        <Select
                          value={this.state.Qualified_Fiscal_Quarter}
                          onChange={this.handleChange}
                          name={"Qualified_Fiscal_Quarter"}
                          multiple
                          data-test="qualified-fiscal-quarter"
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === "Qualified_Fiscal_Quarter" &&
                                  y["Qualified_Fiscal_Quarter"] !== undefined
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y["Qualified_Fiscal_Quarter"]}
                                  value={y["Qualified_Fiscal_Quarter"]}
                                >
                                  {y["Qualified_Fiscal_Quarter"]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={
                          this.state.Qualified_Fiscal_Quarter[0] !== "All"
                        }
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.Qualified_Year_Month.includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-fiscalQuarter-label"
                        >
                          Qualified Month:
                        </InputLabel>
                        <Select
                          value={this.state.Qualified_Year_Month}
                          onChange={this.handleChange}
                          name={"Qualified_Year_Month"}
                          multiple
                          data-test="Qualified_Year_Month"
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === "Qualified_Year_Month" &&
                                  y["Qualified_Year_Month"] !== undefined
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y["Qualified_Year_Month"]}
                                  value={y["Qualified_Year_Month"]}
                                >
                                  {y["Qualified_Year_Month"]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* 
                    <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color:
                              !this.state.projected_close_fiscal_quarter.includes(
                                "All"
                              )
                                ? "#4472c4"
                                : "#000",
                          }}
                          id="select-projectedcloseFiscalQuarter-label"
                        >
                          Proj. Close Fiscal Quarter:
                        </InputLabel>
                        <Select
                          value={this.state.projected_close_fiscal_quarter}
                          onChange={this.handleChange}
                          name={"projected_close_fiscal_quarter"}
                          multiple
                          data-test="projected_close_fiscal_quarter-fiscal-quarter"
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === "projected_close_fiscal_quarter" &&
                                  y["projected_close_fiscal_quarter"] !==
                                    undefined
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y["projected_close_fiscal_quarter"]}
                                  value={y["projected_close_fiscal_quarter"]}
                                >
                                  {y["projected_close_fiscal_quarter"]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid> */}
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  style={{ marginBottom: 10, padding: 10 }}
                  xs={12}
                  lg={4}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    justify="space-around"
                  >
                    <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={
                          this.state.Projected_Close_Year_Month[0] !== "All"
                        }
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color:
                              !this.state.projected_close_fiscal_quarter.includes(
                                "All"
                              )
                                ? "#4472c4"
                                : "#000",
                          }}
                          id="select-projectedcloseFiscalQuarter-label"
                        >
                          Proj. Close Fiscal Qtr.:
                        </InputLabel>
                        <Select
                          value={this.state.projected_close_fiscal_quarter}
                          onChange={this.handleChange}
                          name={"projected_close_fiscal_quarter"}
                          multiple
                          data-test="projected_close_fiscal_quarter-fiscal-quarter"
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === "projected_close_fiscal_quarter" &&
                                  y["projected_close_fiscal_quarter"] !==
                                    undefined
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y["projected_close_fiscal_quarter"]}
                                  value={y["projected_close_fiscal_quarter"]}
                                >
                                  {y["projected_close_fiscal_quarter"]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={
                          this.state.projected_close_fiscal_quarter[0] !== "All"
                        }
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color:
                              !this.state.Projected_Close_Year_Month.includes(
                                "All"
                              )
                                ? "#4472c4"
                                : "#000",
                          }}
                          id="select-fiscalQuarter-label"
                        >
                          Proj. Close Month:
                        </InputLabel>
                        <Select
                          value={this.state.Projected_Close_Year_Month}
                          onChange={this.handleChange}
                          name={"Projected_Close_Year_Month"}
                          multiple
                          data-test="Projected_Close_Year_Month"
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === "Projected_Close_Year_Month" &&
                                  y["Projected_Close_Year_Month"] !== undefined
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y["Projected_Close_Year_Month"]}
                                  value={y["Projected_Close_Year_Month"]}
                                >
                                  {y["Projected_Close_Year_Month"]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {/* industry, customer segment, customer type */}
                <Grid
                  container
                  item
                  className={classes.inputFieldContainers}
                  justify="space-between"
                  xs={12}
                  lg={6}
                >
                  <Grid item spacing={2} xs={12} container component={Paper}>
                    <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.Industry
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-industry-label"
                        >
                          {this.props.company === "C0003"
                            ? "Vertical:"
                            : "Industry:"}
                        </InputLabel>
                        <Select
                          value={this.state[this.variableCols.Industry]}
                          onChange={this.handleChange}
                          name={this.variableCols.Industry}
                          data-test="industry"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.Industry &&
                                  y[this.variableCols.Industry] !== ""
                              )
                              .sort((a, b) =>
                                a[this.variableCols.Industry] <
                                b[this.variableCols.Industry]
                                  ? -1
                                  : a[this.variableCols.Industry] >
                                    b[this.variableCols.Industry]
                                  ? 1
                                  : 0
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.Industry]}
                                  value={y[this.variableCols.Industry]}
                                >
                                  {y[this.variableCols.Industry]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.CustomerSegment
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Customer Segment:
                        </InputLabel>
                        <Select
                          value={this.state[this.variableCols.CustomerSegment]}
                          onChange={this.handleChange}
                          name={this.variableCols.CustomerSegment}
                          data-test="customer_segment"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type ===
                                    this.variableCols.CustomerSegment &&
                                  y[this.variableCols.CustomerSegment] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.CustomerSegment]}
                                  value={y[this.variableCols.CustomerSegment]}
                                >
                                  {y[this.variableCols.CustomerSegment]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        {" "}
                        {console.log(
                          this.variableCols.CustomerType,
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.CustomerType &&
                                y[this.variableCols.CustomerType] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.CustomerType] <
                              b[this.variableCols.CustomerType]
                                ? -1
                                : a[this.variableCols.CustomerType] >
                                  b[this.variableCols.CustomerType]
                                ? 1
                                : 0
                            )
                        )}
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.CustomerType
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-industry-label"
                        >
                          {this.props.company === "C0003"
                            ? "Region:"
                            : "Customer Type:"}
                        </InputLabel>
                        <Select
                          value={this.state[this.variableCols.CustomerType]}
                          onChange={this.handleChange}
                          name={this.variableCols.CustomerType}
                          data-test="customer_type"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.CustomerType &&
                                  y[this.variableCols.CustomerType] !== ""
                              )
                              .sort((a, b) =>
                                a[this.variableCols.CustomerType] <
                                b[this.variableCols.CustomerType]
                                  ? -1
                                  : a[this.variableCols.CustomerType] >
                                    b[this.variableCols.CustomerType]
                                  ? 1
                                  : 0
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.CustomerType]}
                                  value={y[this.variableCols.CustomerType]}
                                >
                                  {y[this.variableCols.CustomerType]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {/*  */}
                {this.props.company === "C0013" && (
                  <Grid
                    container
                    item
                    className={classes.inputFieldContainers}
                    // style={{ padding: 10, paddingTop: 15 }}
                    xs={2}
                  >
                    <Grid
                      item
                      spacing={2}
                      xs={12}
                      container
                      component={Paper}
                      style={{ paddingTop: 0 }}
                    >
                      <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            // style={{ color: "#4472c4" }}
                            style={{
                              color:
                                !this.state.Opportunity_Record_Type.includes(
                                  "All"
                                )
                                  ? "#4472c4"
                                  : "#000",
                            }}
                            id="select-region-label"
                          >
                            Opportunity Record Type:
                          </InputLabel>
                          <Select
                            data-test="Opportunity_Record_Type"
                            value={this.state.Opportunity_Record_Type}
                            onChange={this.handleChange}
                            name="Opportunity_Record_Type"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) =>
                                    y.type === "Opportunity_Record_Type" &&
                                    y.Opportunity_Record_Type !== ""
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y.Opportunity_Record_Type}
                                    value={y.Opportunity_Record_Type}
                                  >
                                    {y.Opportunity_Record_Type}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {/* C0010 */}
                {this.props.company === "C0010" && (
                  <Grid
                    container
                    item
                    className={classes.inputFieldContainers}
                    // style={{ padding: 10, paddingTop: 15 }}
                    xs={2}
                  >
                    <Grid
                      item
                      spacing={2}
                      xs={12}
                      container
                      component={Paper}
                      style={{ paddingTop: 0 }}
                    >
                      <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            // style={{ color: "#4472c4" }}
                            style={{
                              color: !this.state.Record_Type_Name__c.includes(
                                "All"
                              )
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-region-label"
                          >
                            Opp Record Type:
                          </InputLabel>
                          <Select
                            data-test="Record_Type_Name__c"
                            value={this.state.Record_Type_Name__c}
                            onChange={this.handleChange}
                            name="Record_Type_Name__c"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) =>
                                    y.type === "Record_Type_Name__c" &&
                                    y.Record_Type_Name__c !== ""
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y.Record_Type_Name__c}
                                    value={y.Record_Type_Name__c}
                                  >
                                    {y.Record_Type_Name__c}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {/*  */}
                {this.props.company === "C0017" && (
                  <Grid
                    container
                    item
                    className={classes.inputFieldContainers}
                    // style={{ padding: 10, paddingTop: 15 }}
                    xs={2}
                  >
                    <Grid
                      item
                      spacing={2}
                      xs={12}
                      container
                      component={Paper}
                      style={{ paddingTop: 0 }}
                    >
                      <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            // style={{ color: "#4472c4" }}
                            style={{
                              color: !this.state.Type.includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-region-label"
                          >
                            Opp. Type:
                          </InputLabel>
                          <Select
                            data-test="OppType"
                            value={this.state.Type}
                            onChange={this.handleChange}
                            name="Type"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) => y.type === "Type" && y.Type !== ""
                                )
                                .map((y) => (
                                  <MenuItem key={y.Type} value={y.Type}>
                                    {y.Type}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  item
                  className={classes.inputFieldContainers}
                  // style={{ marginBottom: 10, padding: 10 }}
                  justify="space-between"
                  xs={12}
                  md={12}
                  lg={4}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{
                      paddingTop: this.props.company === "C0013" ? 0 : 0,
                    }}
                  >
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={
                          this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) => y.type === "acvFilter" && y.acv !== ""
                            )
                            .map((y) => y.acv)[0]
                        }
                        onChange={this.onAcvChange}
                        value={this.state.acv}
                        data-test="acv"
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${this.props.Value_Label} >= :`}
                            margin="normal"
                            variant="standard"
                            style={{ marginTop: "4px", marginBottom: "0px" }}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color:
                                  this.state.acv !== null &&
                                  this.state.acv !== 0
                                    ? "#4472c4"
                                    : "#000",
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              inputComponent: NumberFormatCustom,
                              startAdornment: (
                                <>
                                  <InputAdornment position="start">
                                    {/* $ */}
                                    {this.props.Currency_Symbol}
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={
                          this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) => y.type === "acvFilter" && y.acv !== ""
                            )
                            .map((y) => y.acv)[0]
                        }
                        // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                        //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                        onChange={this.onAcvLtChange}
                        value={this.state.acvlt}
                        data-test="acvlt"
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${this.props.Value_Label} < :`}
                            margin="normal"
                            variant="standard"
                            style={{ marginTop: "4px", marginBottom: "0px" }}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color:
                                  this.state.acvlt !== null &&
                                  this.state.acvlt !== 0
                                    ? "#4472c4"
                                    : "#000",
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              inputComponent: NumberFormatCustom,
                              startAdornment: (
                                <>
                                  <InputAdornment position="start">
                                    {/* $ */}
                                    {this.props.Currency_Symbol}
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {this.props.company === "C0001" && (
                  <Grid
                    container
                    item
                    className={classes.inputFieldContainers}
                    xs={2}
                  >
                    <Grid
                      item
                      spacing={2}
                      xs={12}
                      container
                      component={Paper}
                      style={{ marginRight: "20px" }}
                    >
                      <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.deal_category__c
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-region-label"
                          >
                            Deal Category:
                          </InputLabel>
                          <Select
                            data-test="Product_Category"
                            value={
                              this.state[this.variableCols.deal_category__c]
                            }
                            onChange={this.handleChange}
                            name={this.variableCols.deal_category__c}
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) =>
                                    y.type ===
                                      this.variableCols.deal_category__c &&
                                    y[this.variableCols.deal_category__c] !== ""
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y[this.variableCols.deal_category__c]}
                                    value={
                                      y[this.variableCols.deal_category__c]
                                    }
                                  >
                                    {y[this.variableCols.deal_category__c]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {["C0003"].includes(this.props.company) && (
                  <Grid
                    container
                    item
                    className={classes.inputFieldContainers}
                    xs={2}
                  >
                    <Grid item spacing={2} xs={12} container component={Paper}>
                      <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.Product_Line
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-product-line-label"
                          >
                            Product Cat.:
                          </InputLabel>
                          <Select
                            data-test="Product_Line"
                            value={this.state[this.variableCols.Product_Line]}
                            onChange={this.handleChange}
                            name={this.variableCols.Product_Line}
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) =>
                                    y.type === this.variableCols.Product_Line &&
                                    y[this.variableCols.Product_Line] !== ""
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y[this.variableCols.Product_Line]}
                                    value={y[this.variableCols.Product_Line]}
                                  >
                                    {y.label}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {/* Lead source, opportunity created by, solution cat */}
                <Grid
                  container
                  item
                  className={classes.inputFieldContainers}
                  justify="space-between"
                  xs={12}
                  lg={this.props.company === "C0003" ? 6 : 6}
                >
                  <Grid item spacing={2} xs={12} container component={Paper}>
                    {/* <Grid item xs={12} style={{ paddingBottom: 8 }}>
                            <Typography variant='body1'>Filters</Typography>
                        </Grid> */}
                    <Grid
                      item
                      xs={12}
                      sm={this.props.company === "C0003" ? 4 : 4}
                      // style={{ paddingTop: 0 }}
                      style={{
                        paddingTop: this.props.company === "C0013" ? 0 : 0,
                      }}
                    >
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.LeadSource
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Lead Source:
                        </InputLabel>
                        <Select
                          value={this.state[this.variableCols.LeadSource]}
                          onChange={this.handleChange}
                          name={this.variableCols.LeadSource}
                          data-test="lead_source"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.LeadSource &&
                                  y[this.variableCols.LeadSource] !== ""
                              )
                              .sort((a, b) =>
                                a[this.variableCols.LeadSource] <
                                b[this.variableCols.LeadSource]
                                  ? -1
                                  : a[this.variableCols.LeadSource] >
                                    b[this.variableCols.LeadSource]
                                  ? 1
                                  : 0
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.LeadSource]}
                                  value={y[this.variableCols.LeadSource]}
                                >
                                  {y[this.variableCols.LeadSource]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={this.props.company === "C0003" ? 4 : 4}
                      style={{ paddingTop: 0 }}
                    >
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state.Opp_Created_By.includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-industry-label"
                        >
                          Opportunity created by:
                        </InputLabel>
                        <Select
                          value={this.state.Opp_Created_By}
                          onChange={this.handleChange}
                          name="Opp_Created_By"
                          data-test="opp_created_by"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelFilters !== "" &&
                            this.props.funnelFilters
                              .filter(
                                (y) =>
                                  y.type === "Opp_Created_By" &&
                                  y["Opp_Created_By"] !== ""
                              )
                              .sort((a, b) =>
                                a["Opp_Created_By"] < b["Opp_Created_By"]
                                  ? -1
                                  : a["Opp_Created_By"] > b["Opp_Created_By"]
                                  ? 1
                                  : 0
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y["Opp_Created_By"]}
                                  value={y["Opp_Created_By"]}
                                >
                                  {y["Opp_Created_By"]}
                                </MenuItem>
                              ))}{" "}
                        </Select>
                      </FormControl>
                    </Grid>

                    {!["C0003", "C0008"].includes(this.props.company) && (
                      <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state.Solution_Cat.includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-industry-label"
                          >
                            Solution Category:
                          </InputLabel>
                          <Select
                            value={this.state.Solution_Cat}
                            onChange={this.handleChange}
                            name="Solution_Cat"
                            data-test="solution_category"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) =>
                                    y.type === "Solution_Cat" &&
                                    y["Solution_Cat"] !== ""
                                )
                                .sort((a, b) =>
                                  a["Solution_Cat"] < b["Solution_Cat"]
                                    ? -1
                                    : a["Solution_Cat"] > b["Solution_Cat"]
                                    ? 1
                                    : 0
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y["Solution_Cat"]}
                                    value={y["Solution_Cat"]}
                                  >
                                    {y["Solution_Cat"]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {this.props.company === "C0003" && (
                      <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state.Booking_Type_NL_CS_US.includes(
                                "All"
                              )
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-industry-label"
                          >
                            Opportunity Type:
                          </InputLabel>
                          <Select
                            value={this.state.Booking_Type_NL_CS_US}
                            onChange={this.handleChange}
                            name="Booking_Type_NL_CS_US"
                            data-test="Booking_Type_NL_CS_US"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) =>
                                    y.type === "Booking_Type_NL_CS_US" &&
                                    y["Booking_Type_NL_CS_US"] !== ""
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y["Booking_Type_NL_CS_US"]}
                                    value={y["Booking_Type_NL_CS_US"]}
                                  >
                                    {y["Booking_Type_NL_CS_US"]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {this.props.company === "C0008" && (
                      <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state.Product_Line.includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-industry-label"
                          >
                            Product Line:
                          </InputLabel>
                          <Select
                            value={this.state.Product_Line}
                            onChange={this.handleChange}
                            name="Product_Line"
                            data-test="Product_Line"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) =>
                                    y.type === "Product_Line" &&
                                    y["Product_Line"] !== ""
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y["Product_Line"]}
                                    value={y["Product_Line"]}
                                  >
                                    {y["label"]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {this.props.company === "C0008" && (
                  <Grid
                    container
                    item
                    className={classes.inputFieldContainers}
                    xs={4}
                  >
                    <Grid item spacing={2} xs={12} container component={Paper}>
                      <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.cross_sell1__c
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-region-label"
                          >
                            Sales Type:
                          </InputLabel>
                          <Select
                            data-test="cross_sell1__c"
                            value={this.state[this.variableCols.cross_sell1__c]}
                            onChange={this.handleChange}
                            name={this.variableCols.cross_sell1__c}
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) =>
                                    y.type ===
                                      this.variableCols.cross_sell1__c &&
                                    y[this.variableCols.cross_sell1__c] !== ""
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y[this.variableCols.cross_sell1__c]}
                                    value={y[this.variableCols.cross_sell1__c]}
                                  >
                                    {y[this.variableCols.cross_sell1__c]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.Type
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-region-label"
                          >
                            Deal Type:
                          </InputLabel>
                          <Select
                            data-test="Type"
                            value={this.state[this.variableCols.Type]}
                            onChange={this.handleChange}
                            name={this.variableCols.Type}
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) =>
                                    y.type === this.variableCols.Type &&
                                    y[this.variableCols.Type] !== ""
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y[this.variableCols.Type]}
                                    value={y[this.variableCols.Type]}
                                  >
                                    {y[this.variableCols.Type]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {this.props.company === "C0001" && (
                  <Grid
                    container
                    item
                    className={classes.inputFieldContainers}
                    xs={2}
                  >
                    <Grid item spacing={2} xs={12} container component={Paper}>
                      <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.Executive_Proposal__c
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-region-label"
                          >
                            Proposal Desk Review:
                          </InputLabel>
                          <Select
                            data-test="Executive_Proposal__c"
                            value={
                              this.state[
                                this.variableCols.Executive_Proposal__c
                              ]
                            }
                            onChange={this.handleChange}
                            name={this.variableCols.Executive_Proposal__c}
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) =>
                                    y.type ===
                                      this.variableCols.Executive_Proposal__c &&
                                    y[
                                      this.variableCols.Executive_Proposal__c
                                    ] !== ""
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={
                                      y[this.variableCols.Executive_Proposal__c]
                                    }
                                    value={
                                      y[this.variableCols.Executive_Proposal__c]
                                    }
                                  >
                                    {y[this.variableCols.Executive_Proposal__c]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {["C0010"].includes(this.props.company) && (
                  <Grid
                    container
                    item
                    className={classes.inputFieldContainers}
                    xs={2}
                  >
                    <Grid item spacing={2} xs={12} container component={Paper}>
                      <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.Account_Designation__c
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-product-line-label"
                          >
                            Account Designation:
                          </InputLabel>
                          <Select
                            data-test="Account_Designation__c"
                            value={
                              this.state[
                                this.variableCols.Account_Designation__c
                              ]
                            }
                            onChange={this.handleChange}
                            name={this.variableCols.Account_Designation__c}
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelFilters !== "" &&
                              this.props.funnelFilters
                                .filter(
                                  (y) =>
                                    y.type ===
                                      this.variableCols
                                        .Account_Designation__c &&
                                    y[
                                      this.variableCols.Account_Designation__c
                                    ] !== ""
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={
                                      y[
                                        this.variableCols.Account_Designation__c
                                      ]
                                    }
                                    value={
                                      y[
                                        this.variableCols.Account_Designation__c
                                      ]
                                    }
                                  >
                                    {y.Account_Designation__c}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {this.props.company === "C0020" && (
                  <Grid
                    container
                    item
                    style={{ marginBottom: 10, padding: 10 }}
                    xs={2}
                  >
                    <LocationRangeFilter
                      classes={classes}
                      options={this.props.funnelFilters.filter(
                        (y) =>
                          y.type === this.variableCols.Of_Locations_Range &&
                          y[this.variableCols.Of_Locations_Range] !== ""
                      )}
                      value={this.state.Of_Locations_Range}
                      onChange={this.handleChange}
                    />
                  </Grid>
                )}
              </Grid>
              {/* Adding Acv Value */}
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {console.log(this.props.funnelData, "this.props.funnelData")}
        <Grid container item xs={12} justify="space-between">
          <Grid
            item
            xs={12}
            md={6}
            className={classes.gridItem}
            justify="center"
          >
            {this.props.funnelData !== "" ? (
              <FunnelInfoCard
                funnelStages={this.props.funnelData.newTable.map(
                  (item) => item.stage
                )}
                wonKey={camelize(this.props.funnelData.wonLabel)}
                wonLabel={this.props.funnelData.wonLabel}
                handleChangeLocationNew={this.handleChangeLocationNew}
                handleChangeLocation={this.handleChangeLocation}
                data={this.props.funnelData}
                type={"count"}
                isAcv={false}
                company={this.props.company}
                data_test={"top_created_funnel_opportunity_summary"}
              />
            ) : (
              <Loading raised={true} />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={classes.gridItem}
            justify="center"
          >
            {this.props.funnelData !== "" ? (
              <FunnelInfoCard
                funnelStages={this.props.funnelData.newTable.map(
                  (item) => item.stage
                )}
                wonKey={camelize(this.props.funnelData.wonLabel)}
                wonLabel={this.props.funnelData.wonLabel}
                handleChangeLocationNew={this.handleChangeLocationNew}
                data={this.props.funnelData}
                handleChangeLocation={this.handleChangeLocation}
                type={"acv"}
                isAcv={true}
                company={this.props.company}
                data_test={"top_created_funnel_acv_summary"}
              />
            ) : (
              <Loading raised={true} />
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          style={{ paddingTop: "0.5rem" }}
        >
          <Grid item xs={12} md={6} style={{ padding: "0.5rem" }}>
            {this.props.funnelData !== "" ? (
              <Card raised={true}>
                <CardHeader
                  disableTypography
                  title={`${this.props.Win_Rate_Prefix} by opportunity count: ${
                    this.props.funnelData !== "" &&
                    Math.round(this.props.funnelData.closedWonCent)
                  }%`}
                />
                <Divider />
                <CardContent className={classes.content}>
                  <Grid
                    item
                    xs={12}
                    style={
                      this.props.funnelData.newTable.map((l) => l.stage)
                        .length > 7
                        ? { height: "19rem" }
                        : { marginBottom: "1rem", height: "18rem" }
                    }
                  >
                    <div
                      style={{ display: "flex", width: "100%", height: "100%" }}
                    >
                      <div
                        style={{ marginTop: 20, width: "100%" }}
                        className="line-container"
                        ref={this._setRef1.bind(this)}
                      />
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Loading raised={true} />
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "0.5rem" }}>
            {this.props.funnelData !== "" ? (
              <Card raised={true}>
                <CardHeader
                  disableTypography
                  title={`${this.props.Win_Rate_Prefix} by ${
                    this.props.Value_Label
                  }: ${
                    this.props.funnelData !== "" &&
                    Math.round(this.props.funnelData.closedWon_acvCent)
                  }%`}
                />
                <Divider />
                <CardContent className={classes.content}>
                  <Grid
                    item
                    xs={12}
                    style={
                      this.props.funnelData.newTable.map((l) => l.stage)
                        .length > 7
                        ? { height: "19rem" }
                        : { marginBottom: "1rem", height: "18rem" }
                    }
                  >
                    <div
                      style={{ display: "flex", width: "100%", height: "100%" }}
                    >
                      <div
                        style={{ marginTop: 20, width: "100%" }}
                        className="line-container"
                        ref={this._setRef2.bind(this)}
                      />
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Loading raised={true} />
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          style={{ paddingTop: "0.5rem" }}
        >
          <Grid item xs={12} md={6} style={{ padding: "0.5rem" }}>
            {this.props.funnelData !== "" ? (
              <FunnelTable
                firstStage={this.props.funnelData.firstStage}
                wonLabel={this.props.funnelData.wonLabel}
                handleChangeLocation={this.handleChangeLocationNew}
                data={this.props.funnelData.newTable}
                companyData={this.props.funnelData.newTableCompany}
                isAcv={false}
                type={"count"}
                company={this.props.company}
                data_test={"created_funnel_opportunity_count"}
                showTeamData={
                  this.state.repFilter.includes("All") &&
                  this.state.teamFilter.includes("All")
                    ? false
                    : true
                }
                Win_Rate_Prefix={this.props.Win_Rate_Prefix}
              />
            ) : (
              <Loading raised={false} />
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "0.5rem" }}>
            {this.props.funnelData !== "" ? (
              <FunnelTable
                firstStage={this.props.funnelData.firstStage}
                wonLabel={this.props.funnelData.wonLabel}
                data={this.props.funnelData.newTable}
                companyData={this.props.funnelData.newTableCompany}
                handleChangeLocation={this.handleChangeLocationNew}
                isAcv={true}
                type={"acv"}
                company={this.props.company}
                data_test={"created_funnel_acv_count"}
                showTeamData={
                  this.state.repFilter.includes("All") &&
                  this.state.teamFilter.includes("All")
                    ? false
                    : true
                }
                Win_Rate_Prefix={this.props.Win_Rate_Prefix}
              />
            ) : (
              <Loading raised={false} />
            )}
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            {}
            <SingleLevelDrilldown
              header={this.props.tableType}
              back={this.handleChangeLocation}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={this.props.tableType}
                clearDrilldownData={this.props.clearDrilldownData}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                Value_Label={this.props.Value_Label}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.location === "drilldownOnOpen" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Open Opportunities by stage ${
                this.state.selectedDrilldownStage &&
                this.state.selectedDrilldownStage !== "stage1"
                  ? " - " + this.state.selectedDrilldownStage
                  : ""
              }`}
              back={this.handleChangeLocation}
              filters={this.state.filtersForDrilldown}
              stepBack={
                this.state.selectedDrilldownStage &&
                this.state.selectedDrilldownStage !== "stage1"
                  ? this.stepBack
                  : null
              }
            >
              {this.state.selectedDrilldownStage === "stage1" ? (
                <OpenDrilldownBody
                  Value_Label={this.props.Value_Label}
                  data={this.props.drilldownOnOpenData}
                  handleChangeLocation={this.handleChangeLocation}
                />
              ) : (
                <Drilldown
                  header={`Open Opportunities by stage ${
                    this.state.selectedDrilldownStage &&
                    this.state.selectedDrilldownStage !== "stage1"
                      ? " - " + this.state.selectedDrilldownStage
                      : ""
                  }`}
                  body={this.props.drilldownOnOpenData2}
                  company={this.props.company}
                  Value_Label={this.props.Value_Label}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                />
              )}
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack1}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  const {
    funnelFilters,
    funnelData,
    persistentAlert,
    user,
    tableType,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    drilldownOnOpenData,
    drilldownOnOpenData2,
    currentFilters,
    ExpectedAcvData,
  } = state.app;
  var currentQuarter =
    user.company === "C0003"
      ? { type: "Open_Fiscal_Quarter" }
      : { type: "Open_Fiscal_Quarter" };
  if (funnelFilters !== "") {
    currentQuarter = funnelFilters.filter(
      (item) => item.quarter === "quarter"
    )[0];
  }
  return {
    funnelFilters,
    funnelData,
    persistentAlert,
    company: user.company,
    Value_Label: user.Value_Label,
    Currency_Symbol: user.Currency,
    Win_Rate_Prefix: user.Win_Rate_Prefix,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    company: user.company,
    drilldownOnOpenData,
    drilldownOnOpenData2,
    tableType,
    currentFilters,
    currentQuarter,
    ExpectedAcvData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFunnelFilters: (company) => {
    dispatch({ type: "get_funnel_filters_request" });
    appService.getCreatedFunnelFilters().then(
      (json) => {
        dispatch({ type: "get_funnel_filters_success", json });
        dispatch({ type: "get_funnel_data_request" });
        const currentQuarter = json.message.filter(
          (item) => item.quarter === "quarter"
        )[0];
        const deaultOppType = getDefaultSalesType(
          json.message.filter((i) => i.type === "Type"),
          "Type"
        );
        const defaultSolutionCat = getDefaultSalesType(
          json.message.filter((i) => i.type === "Solution_Cat"),
          "Solution_Cat"
        );
        let filters = [
          {
            name: currentQuarter.type,
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              currentQuarter.type
            ),
            string: true,
          },
        ];
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((i) => i.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealFilters = getDefaultSalesType(
            json.message.filter((i) => i.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealFilters }];
        }

        filters =
          deaultOppType[0] === "All"
            ? filters
            : [...filters, { name: "Type", value: deaultOppType }];
        filters =
          defaultSolutionCat[0] === "All"
            ? filters
            : [...filters, { name: "Solution_Cat", value: defaultSolutionCat }];
        dispatch({ type: "current_selected_filters", filters });
        appService.getCreatedFunnelData(filters).then(
          (json) => {
            dispatch({ type: "get_funnel_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_funnel_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_funnel_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_filters_failure", error });
      }
    );
  },
  getFunnelDataNew: (filters = []) => {
    dispatch({ type: "get_funnel_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    dispatch({ type: "current_selected_filters", filters });
    appService.getCreatedFunnelData(filters).then(
      (json) => {
        dispatch({ type: "get_funnel_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_data_failure", error });
      }
    );
  },
  getCreatedDrilldownOnOpen: (filters) => {
    dispatch({ type: "get_drilldown_on_open_request" });
    appService.createdDrilldownOnOpenNew(filters).then(
      (json) => {
        dispatch({ type: "get_drilldown_on_open_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_open_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_open_failure", error });
      }
    );
  },
  getCreatedDrilldownOnGraph: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.createdDrilldownOnGraph(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities.map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        console.log("sorting");
        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            opportunities: tempOpportunities,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getChartDrilldown: (company, filters, key, closedStage) => {
    dispatch({ type: "get_drilldown_on_graph_request" });

    appService.getCreatedFunnelDrilldown(filters, key, closedStage).then(
      (json) => {
        //let tempOpportunities = json.message.recordsets[0];
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            // console.log(item.ACV ?? 0, item.Account_Name)
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );
        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: [
              {
                count: json.message.opportunities[0]
                  .map((s) => s.count)
                  .reduce((a, b) => a + b, 0),
                acv: json.message.opportunities[0]
                  .map((s) => s.acv)
                  .reduce((a, b) => a + b, 0),
              },
            ],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getCreatedDrilldownOnOpen2: (filters) => {
    dispatch({ type: "get_drilldown_on_open2_request" });
    appService.createdDrilldownOnOpen2New(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            // console.log(item.ACV ?? 0, item.Account_Name)
            let x = {
              ...item,
              //   closed_date: item.closed_date !== undefined ? item.closed_date.split('T')[0] : item.projected_close_date.split('T')[0],
              //   cycle_time_closed_orders: parseInt(item.cycle_time_closed_orders),
              OppNo: index + 1,
            };
            return x;
          }
        );
        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });
        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_open2_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_open2_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_open2_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  clearDrilldownOpenData: () => {
    dispatch({ type: "clear_drilldown_data_open" });
  },
  clearDrilldownOpen2Data: () => {
    dispatch({ type: "clear_drilldown_data_open2" });
  },
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedFunnel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Funnel));
export default connectedFunnel;
