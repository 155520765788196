import React, { Suspense } from "react";
import { Grid, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import RegionPerformance from "./RegionPerformance/RegionPerformance";
import SegmentPerformance from "./RegionPerformance/SegmentPerformance";
import TeamAttainment from "./TeamAttainment/TeamAttainment";
import { Bar } from "./Bar/Bar";
import { MultiXAxisBarWithMarker } from "./MultiXAxisBarWithMarker/MultiXAxisBarWithMarker";
import { ConnectedManagerSummary } from "./ManagerSummary/ManagerSummary";
import { PerformanceBySalesStage } from "./PerformanceBySalesStage/PerformanceBySalesStage";
import NewQualifiedPipeline from "./NewQualifiedPipelineHTML/NewQualifiedPipeline";
import NewQualifiedPipelinev2 from "./NewQualifiedPipelineHTML/NewQualifiedPipelinev2";
import NewQualifiedPipelinev3 from "./NewQualifiedPipelineHTML/NewQualifiedPipelinev3";
import { AsOfDate } from "./AsOfDate/AsOfDate";

import { RepPerformanceAnalysis } from "./RepPerformanceAnalysis/RepPerformanceAnalysis";

import QuotaAttainment from "./QuotaAttainment/QuotaAttainment";
import QuotaAttainmentV2 from "./QuotaAttainment/QuotaAttainmentV2";
import QuotaAttainmentV3 from "./QuotaAttainment/QuotaAttainmentV3";
import QuotaAttainmentMonthly from './QuotaAttainment/QuotaAttainmentMonthly'
import RampAnalysis from "./RampAnalysis/RampAnalysis";
import RepBenchmarking from "./FunnelCompare/RepBenchmarking";
import ShowPlotly from "../EnhancedInsights/OppotunityPyramid/ShowPlotly";
import RepCertification from "./RepCertification/RepCertification";
import { TimeSeriesPrediction } from "./TimeSeriesPrediction/TimeSeriesPrediction";
import { TimeSeriesPredictionv2 } from "./TimeSeriesPrediction/TimeSeriesPredictionv2";
import { ConversionEfficiencyBySource } from "./TimeSeriesPrediction/ConversionEfficiencyBySource";
import { EnablementEffectiveness } from "./EnablementEffectiveness/EnablementEffectiveness";
import GenericBalancedScoreCard from "./GenericScorecard/BalancedScorecardComponent";
const C0001_ScoreCard = React.lazy(() => import("./ScoreCard/ScoreCard"));
const C0002_ScoreCard = React.lazy(() => import("./ScoreCard/C0002_ScoreCard"));
const C0004_ScoreCard = React.lazy(() => import("./ScoreCard/C0004_ScoreCard"));
const ScoreCardNew = React.lazy(() => import("./ScoreCard/ScoreCardNew"));
const ScoreCardNew_v2 = React.lazy(() =>
  import("./ScoreCard/Performance_Scorecard_v2")
);
const GenericScorecard = React.lazy(() =>
  import("./GenericScorecard/PerformanceScorecard")
);
const GenericScorecardCT = React.lazy(() =>
  import("./GenericScorecard/PerformanceScorecardCT")
);
const GenericRepScorecard = React.lazy(() =>
  import("./GenericScorecard/RepScorecard")
);
const styles = (theme) => ({
  dashOuter: {
    display: "flex",
    flexGrow: 1,
    margin: "auto",
  },
  dashInner: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  graph1: {
    flexGrow: 1,
    height: 250,
    margin: 10,
  },
});
const RepPerformanceRoutes = (props) => {
  const { classes } = props;
  return (
    <>
      {props.location === "Rep Level Scorecard" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <MultiXAxisBarWithMarker />
          </Grid>
        </Grid>
      )}
      {props.location === "Factors driving top reps" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <Bar />
          </Grid>
        </Grid>
      )}
      {props.location === "Performance by sales stage" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <PerformanceBySalesStage />
          </Grid>
        </Grid>
      )}
      {props.location === "Manager Summary" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <ConnectedManagerSummary />
          </Grid>
        </Grid>
      )}
      {(props.location === "New Qualified pipeline" && props.company !== 'C0013') && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <NewQualifiedPipeline />
          </Grid>
        </Grid>
      )}

{(props.location === "New Qualified pipeline" && props.company === 'C0013') && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <NewQualifiedPipelinev3 />
          </Grid>
        </Grid>
      )}
      {props.location === "New Pipeline Creation and Cadence" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <NewQualifiedPipelinev2 />
          </Grid>
        </Grid>
      )}
      {props.location === "Rep Performance Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <RepPerformanceAnalysis />
          </Grid>
        </Grid>
      )}
      {props.location === "Rep Performance YTD" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <AsOfDate />
          </Grid>
        </Grid>
      )}
      {props.location === "Region Performance YTD" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <RegionPerformance />
          </Grid>
        </Grid>
      )}
      {props.location === "Rep Benchmarking Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <RepBenchmarking />
          </Grid>
        </Grid>
      )}
      {/* {props.location === "Performance Scorecard" &&
            ["C0001"].includes(props.company) && (
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  {
                    <Suspense
                      fallback={
                        <Grid
                          item
                          container
                          component={Card}
                          style={{ margin: "1rem" }}
                          justify="center"
                        >
                          <Skeleton
                            variant="react"
                            animation="wave"
                            height={120}
                            width="90%"
                            style={{ margin: "1rem" }}
                          />
                        </Grid>
                      }
                    >
                      <C0001_ScoreCard />
                    </Suspense>
                  }
                </Grid>
              </Grid>
            )}
          {props.location === "Performance Scorecard" &&
            ["C0004"].includes(props.company) && (
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  {
                    <Suspense
                      fallback={
                        <Grid
                          item
                          container
                          component={Card}
                          style={{ margin: "1rem" }}
                          justify="center"
                        >
                          <Skeleton
                            variant="react"
                            animation="wave"
                            height={120}
                            width="90%"
                            style={{ margin: "1rem" }}
                          />
                        </Grid>
                      }
                    >
                      <C0004_ScoreCard />
                    </Suspense>
                  }
                </Grid>
              </Grid>
            )}
          {props.location === "Performance Scorecard" &&
            ["C0002"].includes(props.company) && (
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  {
                    <Suspense
                      fallback={
                        <Grid
                          item
                          container
                          component={Card}
                          style={{ margin: "1rem" }}
                          justify="center"
                        >
                          <Skeleton
                            variant="react"
                            animation="wave"
                            height={120}
                            width="90%"
                            style={{ margin: "1rem" }}
                          />
                        </Grid>
                      }
                    >
                      <C0002_ScoreCard />
                    </Suspense>
                  }
                </Grid>
              </Grid>
            )}

          {props.location === "Performance Scorecard" &&
            props.company === "C0003" && (
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  {
                    <Suspense
                      fallback={
                        <Grid
                          item
                          container
                          component={Card}
                          style={{ margin: "1rem" }}
                          justify="center"
                        >
                          <Skeleton
                            variant="react"
                            animation="wave"
                            height={120}
                            width="90%"
                            style={{ margin: "1rem" }}
                          />
                        </Grid>
                      }
                    >
                      <ScoreCardNew />
                    </Suspense>
                  }
                </Grid>
              </Grid>
            )} */}
      {props.location === "Segment Historical Performance" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            <SegmentPerformance />
          </Grid>
        </Grid>
      )}
      {props.location === "Show Plotly" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <ShowPlotly />
          </Grid>
        </Grid>
      )}
      {/* {props.location === "Quota Attainment Distribution" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                <QuotaAttainment />
              </Suspense>
            }
          </Grid>
        </Grid>
      )} */}
      {props.location === "Quota Attainment Distribution" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                <QuotaAttainmentV2 />
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location === "Beginning Of Quarter Qualified Pipe Attainment" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                <QuotaAttainmentV3 />
              </Suspense>
            }
          </Grid>
        </Grid>
      )}

{props.location === "Quota Attainment Distribution (Monthly)" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                <QuotaAttainmentMonthly />
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location === "Performance Scorecard" &&
        props.company === "C0003" && (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} style={{ padding: "0px" }}>
              {
                <Suspense
                  fallback={
                    <Grid
                      item
                      container
                      component={Card}
                      style={{ margin: "1rem" }}
                      justify="center"
                    >
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  }
                >
                  {<ScoreCardNew_v2 />}
                </Suspense>
              }
            </Grid>
          </Grid>
        )}
      {props.location === "Certification vs. Performance Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                <RepCertification />
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location === "Performance Scorecard (Generic)" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                {<GenericScorecard />}
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location === "Performance Scorecard (RC)" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                {<GenericScorecard />}
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location === "Performance Scorecard" &&
        props.company !== "C0003" && (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} style={{ padding: "0px" }}>
              {
                <Suspense
                  fallback={
                    <Grid
                      item
                      container
                      component={Card}
                      style={{ margin: "1rem" }}
                      justify="center"
                    >
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  }
                >
                  {<GenericScorecard />}
                </Suspense>
              }
            </Grid>
          </Grid>
        )}

{props.location === "Performance Scorecard (CT)" &&
        props.company !== "C0003" && (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} style={{ padding: "0px" }}>
              {
                <Suspense
                  fallback={
                    <Grid
                      item
                      container
                      component={Card}
                      style={{ margin: "1rem" }}
                      justify="center"
                    >
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  }
                >
                  {<GenericScorecardCT />}
                </Suspense>
              }
            </Grid>
          </Grid>
        )}

{props.location === "Balanced Scorecard" &&
         (
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} style={{ padding: "0px" }}>
              {
                <Suspense
                  fallback={
                    <Grid
                      item
                      container
                      component={Card}
                      style={{ margin: "1rem" }}
                      justify="center"
                    >
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  }
                >
                  {<GenericBalancedScoreCard />}
                </Suspense>
              }
            </Grid>
          </Grid>
        )}

      {props.location === "Rep Performance Scorecard" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                {<GenericRepScorecard />}
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location === "Team Attainment" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                {<TeamAttainment />}
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location ===
        "Time Series based prediction of range of Closed Won" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                {<TimeSeriesPrediction />}
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location ===
        "Time Series based prediction of range of Closed Won v2" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                {<TimeSeriesPredictionv2 />}
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location === "Conversion Efficiency by Source" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                {<ConversionEfficiencyBySource />}
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
      {props.location === "Ramp Analysis" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                {<RampAnalysis />}
              </Suspense>
            }
          </Grid>
        </Grid>
      )}

{props.location === "Enablement Effectiveness" && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} style={{ padding: "0px" }}>
            {
              <Suspense
                fallback={
                  <Grid
                    item
                    container
                    component={Card}
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                }
              >
                {<EnablementEffectiveness />}
              </Suspense>
            }
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(RepPerformanceRoutes);
