export default {
  ScoreCardData: '',
  location: '',
  ScoreCardFilters: '',
  YTDScorecardData: '',
  YTDScorecardDrilldown: '',
  QTDScorecardDrilldown: '',
  Current_QuarterDrilldown: '',
  Next_QuarterDrilldown:'',
  QTDScorecardData: '',
  CurrentQuarterScorecardData: '',
  NextQuarterScorecardData: '',
  NewQualifiedData: '',
  PipelineCoverageData: '',
  MQODataCustType: '',
  MQOData: '',
  alert: {
    id: 0,
    type: '',
    message: ''
  },
  persistentAlert: '',
}