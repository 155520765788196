import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react'
import { countOrACVFormatter, getUniqueValues, StyledTableRow, _isNaN } from '../../../../util/customFunctions';
import tableStyle from '../../../styles/tableStyle';
import NoDataCell from '../../AccountHealth/components/NoDataCell';
import { renewalsRow } from '../../RenewalsCompleted/components/utils';

export default function QBRTable(props) {
    const classes = tableStyle()
    const [data, totalData] = props.data;
    let rows = data.map(item => item[props.row]).filter(getUniqueValues)
    const columns = data.map(item => item[props.column]).filter(getUniqueValues)
    const missingRows = rows.filter(function (obj) { return renewalsRow.indexOf(obj) === -1; });
    rows = [...renewalsRow, ...missingRows]
    const drilldownHandler = (col, row, meta) => {

        if (props.chartName === "QBRSummary") {
            props.handleChangeLocation("drilldownOnGraph", { col, row }, meta)
        }
    }

    return (
        <TableContainer style={{ padding: '2rem' }} className={classes.root}>
            <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                    {
                        data.length > 0 ? <>
                            {
                                props.changeFromPriorWeek ? <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                    <TableCell style={{ background: 'white' }}></TableCell>


                                    <TableCell align="center" colSpan={3 * columns.length} className={classes.colHeader} style={{ backgroundColor: '#bf8f00' }}>Get Well by Prior Week</TableCell>
                                </TableRow> : null
                            }
                            <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black', minWidth: 170 }}>QBR Fiscal Quarter</TableCell>
                                {columns.map((item, index) => <TableCell key={index} style={index % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} colSpan={3} align="center" className={classes.colHeader}>{item}</TableCell>)}
                                {columns.length > 1 && <TableCell style={columns.length > 1 && columns.length % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} colSpan={3} align="center" className={classes.colHeader}>{`Total`}</TableCell>}
                            </TableRow>
                            <TableRow >
                                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black' }}>Segment</TableCell>
                                {columns.map(item => <>
                                    <TableCell align="center" className={classes.colHeader}># of QBRs</TableCell>
                                    <TableCell align="center" className={classes.colHeader}>Total # of Accounts</TableCell>
                                    <TableCell align="center" className={classes.colHeader}>Coverage</TableCell>
                                </>)}
                                {
                                    columns.length > 1 &&
                                    <>
                                        <TableCell align="center" className={classes.colHeader}># of QBRs</TableCell>
                                        <TableCell align="center" className={classes.colHeader}>Total # of Accounts</TableCell>
                                        <TableCell align="center" className={classes.colHeader}>Coverage</TableCell>
                                    </>
                                }
                            </TableRow>
                            {
                                rows.map(row =>
                                    <>
                                        <StyledTableRow key={row}>
                                            <TableCell align="left" className={classes.dataCell}>{row}</TableCell>
                                            {columns.map(col => {
                                                let cellData = data.filter(item => item[props.column] === col && item[props.row] === row)[0]

                                                const totalAccounts = totalData.filter(item => item[props.row] === row)[0]

                                                return <>
                                                    <TableCell align="right" onClick={() => drilldownHandler(col, row)} className={classes.drilldownCell}>{cellData !== undefined ? cellData === "-" ? cellData : cellData !== undefined ? countOrACVFormatter(cellData.count, false) : "- " : 0}</TableCell>
                                                    <TableCell align="right" onClick={() => drilldownHandler(col, row)} className={classes.drilldownCell}>{countOrACVFormatter(totalAccounts === undefined ? '0' : totalAccounts.count, false)}</TableCell>
                                                    <TableCell align="right" onClick={() => drilldownHandler(col, row)} className={classes.drilldownCell}>{cellData !== undefined && totalAccounts !== undefined ?
                                                        _isNaN((Math.round((cellData.count / totalAccounts.count) * 100))) ?
                                                            '0%' : `${(Math.round((cellData.count / totalAccounts.count) * 100))}%`
                                                        : `0%`}</TableCell>
                                                </>
                                            })}
                                            {
                                                columns.length > 1 &&
                                                <>
                                                    <TableCell align="right" onClick={() => drilldownHandler(columns, row, 'totalCol')} className={classes.drilldownCell}>{data.filter(item => item[props.row] === row).length > 0 ? data.filter(item => item !== undefined && item[props.row] === row).reduce((a, b) => a + b.count, 0) : `0`}</TableCell>
                                                    <TableCell align="right" onClick={() => drilldownHandler(columns, row, 'totalCol')} className={classes.drilldownCell}>{totalData.filter(item => item !== undefined && item[props.row] === row).length > 0 ? totalData.filter(item => item !== undefined && item[props.row] === row)[0].count : `0`}</TableCell>
                                                    <TableCell align="right" onClick={() => drilldownHandler(columns, row, 'totalCol')} className={classes.drilldownCell}>{data.filter(item => item[props.row] === row).length > 0 && totalData.filter(item => item[props.row] === row).length > 0 ?

                                                        _isNaN((Math.round((data.filter(item => item !== undefined && item[props.row] === row).map(item => item.count).reduce((a, b) => a + b, 0) / totalData.filter(item => item[props.row] === row)[0].count) * 100))) ? `0%` : `${(Math.round((data.filter(item => item[props.row] === row).map(item => item.count).reduce((a, b) => a + b, 0) / totalData.filter(item => item !== undefined && item[props.row] === row)[0].count) * 100))}%`
                                                        : `0%`}</TableCell>
                                                </>
                                            }
                                        </StyledTableRow>

                                    </>
                                )
                            }
                            <StyledTableRow style={{ fontWeight: 600 }} key={"total"}>
                                <TableCell align="left" className={classes.dataCell}>{`Total`}</TableCell>
                                {
                                    columns.map(col => {
                                        const totalCRR = props.data.filter(item => item[props.column] === col).map(item => item.count).reduce((a, b) => a + b, 0)

                                        return <>

                                            <TableCell align="right" onClick={() => drilldownHandler(col, rows, 'totalRow')} className={classes.drilldownCell}>{countOrACVFormatter(data.filter(item => item[props.column] === col).map(item => item.count).reduce((a, b) => a + b, 0), false)}</TableCell>
                                            <TableCell align="right" onClick={() => drilldownHandler(col, rows, 'totalRow')} className={classes.drilldownCell}>{countOrACVFormatter(totalData.map(item => item.count).reduce((a, b) => a + b, 0), false)}</TableCell>
                                            <TableCell align="right" onClick={() => drilldownHandler(col, rows, 'totalRow')} className={classes.drilldownCell}>{`${Math.round((data.filter(item => item[props.column] === col).map(item => item.count).reduce((a, b) => a + b, 0) / totalData.map(item => item.count).reduce((a, b) => a + b, 0)) * 100)}%`}</TableCell>
                                        </>
                                    })
                                }
                                {
                                    columns.length > 1 &&
                                    <>
                                        <TableCell align="right" onClick={() => drilldownHandler(columns, rows, 'totalAll')} className={classes.drilldownCell}>{countOrACVFormatter(data.map(item => item.count).reduce((a, b) => a + b, 0), false)}</TableCell>
                                        <TableCell align="right" onClick={() => drilldownHandler(columns, rows, 'totalAll')} className={classes.drilldownCell}>{countOrACVFormatter(totalData.map(item => item.count).reduce((a, b) => a + b, 0), false)}</TableCell>
                                        <TableCell align="right" onClick={() => drilldownHandler(columns, rows, 'totalAll')} className={classes.drilldownCell}>{`${(Math.round((data.map(item => item.count).reduce((a, b) => a + b, 0) / totalData.map(item => item.count).reduce((a, b) => a + b, 0)) * 100))}%`}</TableCell>
                                    </>
                                }
                            </StyledTableRow>
                        </> : <NoDataCell />
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
