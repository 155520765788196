import { Card, Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { appService } from '../../App/app.service';
import YTDTable from './YTDTable';
import D3RepPerformanceBarWins from '../RepPerformanceAnalysis/Charts/D3RepPerformanceBarFYWins'
import Legend from '../AsOfDate/Legend';
const styles = theme => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  table: {
    border: '0.5px solid lightgrey', padding: '5px 10px', color: 'inherit'
  },
  table1: {
    background: '#FAFAFA', border: 'none', width: 5
  },
  rep: {
    border: '0.5px solid lightgrey', padding: '0px 10px', width: '20%', color: 'inherit'
  },
  tableHeader: {
    fontWeight: 600, border: '0.5px solid lightgrey', padding: '0px 10px', color: 'white', background: '#4472C4'
  },
  repHeader: {
    fontWeight: 600, border: '0.5px solid lightgrey', padding: '0px 5px', width: '20%', color: 'inherit'
  },
  mainTable: {
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    }
  },
  mainTable2: {
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    }
  }
})
class RegionPerformance extends Component {
  componentDidMount = () => {
    this.props.getRegionPerformanceFilters(this.props.company)
    window.addEventListener('resize', this.onResize)
  }
  componentDidUpdate = (prevProps, prevState) => {
    // if (this.props.AsOfDateFilters !== '' && prevProps.AsOfDateFilters === '') {
    //   this.setState({ ...prevState, cfq: [this.props.AsOfDateFilters.closedFiscalQuarters[1]] })
    // }
    if (this.props.RegionPerformanceData !== "") {
      D3RepPerformanceBarWins.destroy(this._rootNode1)
      console.log(this.props.RegionPerformanceData)
      this._chart1 = D3RepPerformanceBarWins.create(this._rootNode1, { data: this.props.RegionPerformanceData, type: 'Region' })

    }
  }
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onResize)
    D3RepPerformanceBarWins.destroy(this._rootNode1)
  }
  onResize = async () => {
    D3RepPerformanceBarWins.destroy(this._rootNode1)
    this._chart1 = D3RepPerformanceBarWins.create(this._rootNode1, { data: this.props.RegionPerformanceData, type: 'Region' })
  }
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }
  render() {
    const { classes } = this.props
    return (
      <Grid container>
        <Grid item container style={{ marginBottom: 10, width: '60%', margin: 'auto', padding: '1rem' }} xs={10}>
          <Grid item xs={12} justify="center">
            <Typography variant='h2' align='center'>Region Performance YTD</Typography>
          </Grid>
          <Grid item xs={12} justify="left">
            <Typography variant='body1'>
              Explore the YTD performance by region.
            </Typography>
          </Grid>


        </Grid>
        <Grid item xs={12} container justify="center">
          <Grid component={Card} justify="center" item xs={10} id='grid-container' style={{ marginBottom: 20, overflow: 'auto', padding: '2rem', width: '60%' }}>
            {this.props.RegionPerformanceData !== '' && this.props.persistentAlert.message !== 'Updating data...' ?
              // <Typography>Loaded</Typography>
              <YTDTable data={this.props.RegionPerformanceData} />
              :
              <Grid
                style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}

              >
                <Skeleton animation="wave" variant="text" width='80%' style={{ margin: '20px 0px' }} />
                <Divider style={{ width: '100%' }} />
                <Skeleton animation="wave" variant="rect" width='80%' height={200} style={{ margin: '20px 0px' }} />
              </Grid>}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ margin: "2rem" }}>
          <Card style={{ margin: "auto", width: "88%" }} raised={true}>
            <Typography variant='h2' style={{ margin: '1rem', display: 'flex', justifyContent: 'center' }}>FY21 Region Performance YTD</Typography>

            {this.props.RegionPerformanceData !== '' ? (
              <Grid container>
                <Grid item container xs={12}>
                  <div
                    style={{
                      justifyContent: "center",
                      marginTop: 20,
                      margin: "auto",
                      width: "95%",
                    }}
                    className="line-container"
                    ref={this._setRef1.bind(this)}
                  />
                </Grid>
                <Grid item container justify="center" style={{ margin: '2rem' }} xs={12}>
                  <Legend square={15} legends={[{ text: 'Total FY 21 Win', color: '#70AD47' }, { text: 'Total FY 21 Quota', color: '#BF9000' }]} />
                </Grid>
              </Grid>
            ) : (<Skeleton animation="wave" variant="rect" width='95%' height={230} style={{ margin: '2rem' }} />)}
          </Card>
        </Grid>
      </Grid>
    )
  }
}
function mapStateToProps(state) {
  const {
    RegionPerformanceFilters,
    RegionPerformanceData,
    persistentAlert,
    user,
  } = state.app

  return {
    RegionPerformanceFilters, RegionPerformanceData, persistentAlert, company: user.company
  }
}
const mapDispatchToProps = (dispatch) => ({

  getRegionPerformanceData: (filters) => {
    dispatch({ type: 'get_region_performance_data_request' })
    appService.getChartData(filters)
      .then(json => {
        dispatch({ type: 'get_region_performance_data_success', json })
      }, error => {
        if (typeof error === 'object') dispatch({ type: 'get_region_performance_data_failure', error: 'Something went wrong' })
        else
          dispatch({ type: 'get_region_performance_data_failure', error })
      })
  },
  getRegionPerformanceFilters: (company) => {
    /* dispatch({ type: 'get_AsOfDate_filters_request' })
    appService.getAsOfDateFilters()
      .then(json => {
        dispatch({ type: 'get_AsOfDate_filters_success', json })
        dispatch({ type: 'get_AsOfDate_data_request' }) */
    appService.getChartData(company === 'C0003' ? 'C0003.CHID00000016' : 'C0001.CHID00000020', [/* { name: 'cfq', value: [json.message.closedFiscalQuarters[1]]/* [nearestCompletedFiscalQuarter()]  } */])
      .then(json => {
        dispatch({ type: 'get_region_performance_data_success', json })
      }, error => {
        if (typeof error === 'object') dispatch({ type: 'get_region_performance_data_failure', error: 'Something went wrong' })
        else
          dispatch({ type: 'get_region_performance_data_failure', error })
      })
    /*  }, error => {
               if(typeof error === 'object') dispatch({type: 'get_AsOfDate_filters_failure', error: 'Something went wrong' })
               else 
       dispatch({ type: 'get_AsOfDate_filters_failure', error })
     }) */
  },


})

const connectedRegionPerformance = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegionPerformance))
export default connectedRegionPerformance;