import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: { width: "100%" },

  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
    textAlign: "center",
  },
  fixedWidthCell: {
    width: "100px", // Fixed width for all table cells
  },
  emptyCell: {
    border: "none",
    background: "white",
    width: "10px",
    maxWidth: "10px",
    zIndex: 999999,
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontSize: "1.3rem",
    fontWeight: "inherit",
    width: "100px", // Fixed width for repNumber cells
  },
  fontBold: {
    fontWeight: 600,
  },
}));
export const genericUseStyles = makeStyles((theme) => ({
  content: { width: "100%" },

  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
    textAlign: "center",
  },
  fixedWidthCell: {
    width: "100px", // Fixed width for all table cells
  },
  emptyCell: {
    border: "none",
    background: "white",
    width: "10px",
    maxWidth: "10px",
    zIndex: 999999,
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: "inherit",
    width: "100px", // Fixed width for repNumber cells
  },
  fontBold: {
    fontWeight: 600,
  },
}));
