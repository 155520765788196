import React, { useState, useEffect } from "react";
import { Snackbar, IconButton, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
const PLATFORM_LOAD_TIME = 3;
const VERSION_CHANGE_ALERT_SHOW_TIME = 10;
const AutoUpdateAlert = () => {
  const autoUpdateKey = "skygeni-auto-update";
  const [showAlert, setShowAlert] = useState(
    localStorage.getItem(autoUpdateKey) !== null
  );
  const message = localStorage.getItem(autoUpdateKey);
  console.log(message, "auto_udpate");
  useEffect(() => {
    console.log("change detected at autoUpdateKey");
    handleStorageChange();
  }, []);

  const handleStorageChange = () => {
    const isAutoUpdateKeyPresent = localStorage.getItem(autoUpdateKey) !== null;
    if (isAutoUpdateKeyPresent) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  };

  const handleClose = () => {
    localStorage.removeItem(autoUpdateKey);
    setShowAlert(false);
  };

  return (
    <>
      {showAlert && (
        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleClose}
          autoHideDuration={
            (PLATFORM_LOAD_TIME + VERSION_CHANGE_ALERT_SHOW_TIME) * 1000
          }
          style={{ zIndex: 1300 }}
        >
          <Alert
            severity="info"
            variant="filled"
            icon={<InfoIcon />}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            style={{
              backgroundColor: "#FFD700", // Custom orange color
              color: "#404040", // Black font color
              fontWeight: "bold",
            }}
          >
            <Typography
              variant="body1"
              style={{ color: "#404040", fontWeight: "bold" }}
            >
              Latest version of SkyGeni loaded. If you made a request, please
              resubmit it.
            </Typography>
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AutoUpdateAlert;
