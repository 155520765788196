import * as fuzz from "fuzzball";
import { formattedString } from "./formatted-string";
import { _isNaN, countOrACVFormatter } from "../../util/customFunctions";
import { metrics } from "./data";
export const requestedFunnel = (
  userInput,
  funnelChartData,
  { closedFunnelFilters }
) => {
  console.log(
    userInput,
    funnelChartData,
    closedFunnelFilters,
    "metric_extraction"
  );
  let metric = userInput.metric;
  const filters = new Object(userInput.expectedOutput.filters);
  const lkpStages = userInput.expectedOutput.lkpStages;
  const wonStageIdx = lkpStages.findIndex(
    (it) => it.opp_status === "won" && it.stage_type === "closed"
  );

  // Mappings of the pipeline stages to indices in the JSON return data from the filters funnel:
  const pipelineStagesMapping = {};

  lkpStages.forEach((stage) => {
    pipelineStagesMapping[stage["stage_name"].toLowerCase()] = stage["index"];
  });
  let pipelineStage1,
    pipelineStage_2Object,
    pipelineStage2 = null;
  if (typeof userInput["metric"] === "object") {
    pipelineStage1 = lkpStages.filter(
      (it) =>
        it.stage_name.toLowerCase() === userInput["metric"]["pipeline_stage_1"]
    )[0].stage_name;
    pipelineStage_2Object = lkpStages.filter(
      (it) =>
        it.stage_name.toLowerCase() === userInput["metric"]["pipeline_stage_2"]
    )[0];

    if (pipelineStage_2Object) {
      pipelineStage2 = pipelineStage_2Object.stage_name;
    }
  }

  // TODO: Add your code here also
  userInput["metric"] = metric;
  let metricDict = userInput["metric"];
  let metricWanted;

  if (typeof userInput["metric"] === "object") {
    if (
      ["win rate", "rate win", "wr", "winrate"].includes(
        metricDict["calculation_type"].toLowerCase()
      )
    ) {
      metricWanted = winRateCalc(
        metricDict["value_type"],
        metricDict["pipeline_stage_1"],
        funnelChartData,
        pipelineStagesMapping,
        wonStageIdx
      );
      userInput["metric"] =
        "Win Rate from " +
        pipelineStage1 +
        " by " +
        returnStringFormat(metricDict);
      metric = userInput.metric;
    } else if (metricDict["calculation_type"].toLowerCase().includes("came")) {
      if (metricDict["value_type"].toLowerCase().includes("opps")) {
        metricDict["value_type"] = "count";
      }
      metricWanted = Math.round(
        funnelChartData[pipelineStagesMapping[metricDict["pipeline_stage_1"]]][
          metricDict["value_type"]
        ]
      );
      metricWanted = metricWanted.toLocaleString("en-US");
      if (metricDict["value_type"] === "acv") {
        metricWanted = "$" + metricWanted;
      }
      userInput["metric"] =
        returnStringFormat(metricDict) + " came to " + pipelineStage1;
      metric = userInput.metric;
    } else if (
      ["conversion rate", "conversionrate", "rate conversion", "cr"].includes(
        metricDict["calculation_type"].toLowerCase()
      )
    ) {
      metricWanted =
        Math.round(
          100 *
            funnelChartData[
              pipelineStagesMapping[metricDict["pipeline_stage_2"]]
            ][metricDict["value_type"] === "acv" ? "diffacvRate" : "diffRate"]
        ) + "%";
      userInput[
        "metric"
      ] = `${pipelineStage1} to ${pipelineStage2} conversion rate ${getValueType(
        metricDict["value_type"]
      )}`;
      metric = userInput.metric;
      // return formattedString(metricWanted, { ...userInput.filters });
    }
  } else {
    const lowerCaseMetric = metric.toLowerCase();
    const wonStage = lkpStages[wonStageIdx].stage_name;
    if (
      [
        `${wonStage.toLowerCase()}`,
        `${wonStage.toLowerCase()} acv`,
        ...metrics,
      ].includes(lowerCaseMetric) ||
      fuzz.ratio(lowerCaseMetric, "closed won") > 50 ||
      fuzz.ratio(lowerCaseMetric, "bookings") > 50
    ) {
      metricWanted = countOrACVFormatter(
        funnelChartData[funnelChartData.length - 1]["acv"],
        true
      );
      userInput["metric"] = `${wonStage} acv`;
      metric = userInput.metric;
    } else if (
      ["win rate", "win-rate", "win_rate", "winrate"].includes(lowerCaseMetric)
    ) {
      metricWanted = funnelChartData[wonStageIdx]["acvRate"]
        ? (funnelChartData[wonStageIdx]["acvRate"] * 100).toFixed(0) + "%"
        : "0%";
      userInput["metric"] = "Win Rate";
      metric = userInput.metric;
    }
  }
  if (userInput.expectedOutput.filters.rep) {
    userInput.expectedOutput.filters.rep = closedFunnelFilters.reps.filter(
      (it) => it.UserID === userInput.expectedOutput.filters.rep[0]
    )[0];
  } else if (userInput.expectedOutput.filters.team) {
    userInput.expectedOutput.filters.team = closedFunnelFilters.teams.filter(
      (it) => it.Display_Name === userInput.expectedOutput.filters.team[0]
    );
  }
  if (metricWanted === undefined) {
    throw new Error("Cannot generate the natural response");
  }
  return formattedString(metricWanted, {
    ...userInput.expectedOutput.filters,
    metric,
  });
};
function winRateCalc(
  valueType,
  pipelineStage,
  funnelChartData,
  pipelineStagesMapping,
  wonStageIdx
) {
  const val =
    funnelChartData[wonStageIdx][valueType] /
    funnelChartData[pipelineStagesMapping[pipelineStage]][valueType];
  const percentVal = 100 * (_isNaN(val) ? 0 : val);
  return Math.round(percentVal) + "%";
}

function returnStringFormat(metricDict) {
  return metricDict["value_type"] === "acv"
    ? metricDict["value_type"].toUpperCase()
    : metricDict["value_type"].charAt(0).toUpperCase() +
        metricDict["value_type"].slice(1);
}
const getValueType = (valueType) => {
  if (["acv", "ACV", "Acv"].includes(valueType)) {
    return "by ACV";
  } else if (["count", "Count"].includes(valueType)) {
    return "by count";
  }
  return "";
};
