import { json } from "d3";
import { act } from "react-dom/test-utils";
import { customDateFormatter, getUniqueValues } from "../util/customFunctions";
let alert;
let data;
let pcdMovedAnalysisDataErrorMessage;
const initialState = {
  currentPage: localStorage.getItem("skygeni-user-page")
    ? localStorage.getItem("skygeni-user-page")
    : "Home",
  loggedIn: /* false, // */ JSON.parse(localStorage.getItem("skygeni-user"))
    ? true
    : false,
  user: JSON.parse(localStorage.getItem("skygeni-user"))
    ? JSON.parse(localStorage.getItem("skygeni-user"))
    : "",
  alert: {
    id: 0,
    type: "",
    message: "",
  },
  persistentAlert: "",
  forgotPassToken: "",
  funnelFilters: "",
  funnelData: "",
  createdFunnelFilters: "",
  createdFunnelData: "",
  closedLostOppFilters: "",
  closedLostOppData: "",
  strengthOfSalesFilters: "",
  strengthOfSalesData: "",
  winRateFilters: "",
  winRateData: "",
  winRateDrilldownData: "",
  waterfallData: "",
  drilldownOnGraphData: "",
  drilldownOnOpenData: "",
  drilldownOnOpenData2: "",
  pipelineMinMax: "",
  pipelineDateFilters: "",
  enhancedInsightsPage: localStorage.getItem("skygeni-user-enhancedPage")
    ? localStorage.getItem("skygeni-user-enhancedPage")
    : "home",
  repPerformancePage: localStorage.getItem("skygeni-user-repPage")
    ? localStorage.getItem("skygeni-user-repPage")
    : "home",
  productInsightsPage: localStorage.getItem("skygeni-user-productPage")
    ? localStorage.getItem("skygeni-user-productPage")
    : "home",
  customerSuccessPage: localStorage.getItem("skygeni-user-customerSuccessPage")
    ? localStorage.getItem("skygeni-user-customerSuccessPage")
    : "home",
  settingsPage: localStorage.getItem("skygeni-user-SettingsPage")
    ? localStorage.getItem("skygeni-user-SettingsPage")
    : "home",
  quotaGenerationPage: localStorage.getItem("skygeni-user-quotaGenerationPage")
    ? localStorage.getItem("skygeni-user-quotaGenerationPage")
    : "home",

  waterfallMinDate: null,
  waterfallMaxDate: null,
  waterfallFilters: "",
  performance_by_industryData: "",
  performance_by_industryFilters: "",
  waterfallError: false,
  checked: JSON.parse(localStorage.getItem("skygeni-user-checked"))
    ? JSON.parse(localStorage.getItem("skygeni-user-checked"))
    : "",
  openConversationalAIWindow: false,
  conversationalAIMessages: [],
  waitingForAiResponse: false,
  updatingFunnelData: false,
  updatingWaterfallData: false,
  updatingPBIData: false,
  updatingDrillDownData: false,
  settings: {
    lastPull: "",
    nextPull: "",
  },
  homepageData: "",
  NewQualifiedPipelineData: "",
  NewQualifiedPipelineDrilldownData: "",
  NewQualifiedPipelineFilters: "",
  ScoreCardData: "",
  ScoreCardTeam: "",
  ScorecardTeamFilter: "",
  ScoreCardFilters: "",
  updatingNQPData: false,
  CurrentStateData: "",
  CurrentStateFilters: "",
  updatingCSData: false,
  AsOfDateData: "",
  RepPerformanceYTDFilters: "",
  funnelCompareData: "",
  funnelCompareFilters: "",
  projectedPipelineData: "",
  projectedPipelineFilters: "",
  projectedPipelineMinDate: null,
  projectedPipelineMaxDate: null,
  projectedPipelineError: false,
  updatingProjectedPipelineData: false,
  repPerformanceData: "",
  winAcvMixFilters: "",
  lkpFilters: "",
  winAcvAnalysisData: "",
  pipelineMixFilters: "",
  pipelineMixAnalysisData: "",
  scatterpoint: "",
  descatterpoint: "",
  version: "",
  serviceWorkerInitialized: false,
  serviceWorkerUpdate: false,
  appUpdate: false,
  seviceWorkerUpdateObject: "",
  tableType: "",
  PBIPipelineFilters: "",
  PBIPipelineData: "",
  PBISummaryFilters: "",
  PBISummaryData: "",
  RegionPerformanceData: "",
  NewWinAcvMixFilters: "",
  NewWinAcvMixData: "",
  accountHealthFilters: "",
  accountHealthData: "",
  QBRFilters: "",
  QBRData: "",
  QBROutReachFilters: "",
  QBROutReachData: "",
  QBRF2FFilters: "",
  QBRF2FData: "",
  QBROnBoardFilters: "",
  QBROnBoardData: "",
  RenewalsCompletedFilters: "",
  RenewalsCompletedData: "",
  RenewalsOverDueFilters: "",
  RenewalsOverDueData: "",
  accountHealthChangeOverTimeData: "",
  accountHealthChangeOverTimeFilters: "",
  accountHealthByTeamFilters: "",
  accountHealthByTeamData: "",
  accountHealthGetWellSummaryFilters: "",
  accountHealthGetWellSummaryData: "",
  RenewalsPriceIncreaseData: "",
  RenewalsPriceIncreaseFilters: "",
  RenewalsCompletedDataRegion: "",
  RenewalDataCombinedAccountSegment: "",
  RenewalDataCombinedRegionRollup: "",
  currentFilters: "",
  selectedFilters: "",
  QuotaAttainmentData: "",
  QuotaAttainmentDataV2: "",
  quotaAttainmentCompareFilters: "",
  quotaAttainmentCompareFiltersV2: "",
  winRateStages: "",
  CompetitorAnalysisData: "",
  competitorAnalysisFilters: "",
  ExpectedAcvData: "",
  PipelineAnalysisData: "",
  plotlyFilters: "",
  OpportunityPyramidData: "",
  updatingOPData: false,
  OpportunityPyramidFilters: "",
  PipelineTrendsRollingData: "",
  userLoaded: false,
  passwordUpdated: false,
  scorecardFilters_v2: "",
  genericScorecardFilters: "",
  genericRepScorecardFilters: "",
  NQPDataSAL: "",
  NQPDataSQL: "",
  NQPDataSQLCQC: "",
  NQPDataSQLV2: "",
  NQPDataNew: "",
  NQPDataSegment: "",
  NQPDataDeal: "",
  NQPDataExisting: "",
  AttainmentNQPData: "",
  PipelineCoverageData: "",
  StageProgressionData: "",
  StageProgressionFilters: "",
  RepCertificationData: "",
  RepCertificationFilters: "",
  AttainmentDataYTD: "",
  AttainmentRepDataYTD: "",
  AttainmentDataCFQ: "",
  AttainmentDatacompletedFQ: "",
  AttainmentRepDataCFQ: "",
  AttainmentRepDatacompletedFQ: "",
  TeamAttainmentData: "",
  CompetitorColumns: "",
  PipelineCoverageCFQ: "",
  PipelineCoverageCFQV2: "",
  PipelineCoverageNFQ: "",
  PipelineCoverageDeals20KCFQ: "",
  PipelineCoverageDeals20KNFQ: "",
  MQOData: "",
  AllAEsData: "",
  ACVCreatedData: "",
  QTDGreaterthan20kData: "",
  MekkoData: "",
  averageDealSizeFilters: "",
  averageDealSizeData: "",
  pipelineCoverageCustTypeCFQData: "",
  pipelineCoverageCustTypeNFQData: "",
  repBenchMarkingFilters: "",
  managerSummaryCustomData: "",
  managerSummaryWaterfallData: "",
  pcdMovedAnalysisFilters: "",
  pcdMovedAnalysisDataErrorMessage: "",
  pcdMovedAnalysisData: "",
  pcdMovedAnalysisTrendsData: "",
  pcdMovedMinMaxdate: "",
  RampAnalysisFilters: "",
  RampAnalysisData: "",
  pipelineCreatedFilters: "",
  pipelineCreatedAnalysisData: "",
  churnAnalysisFilters: "",
  churnAnalysisData: "",
  AttainmentHomeDataCFQ: "",
  AttainmentHomeDataYTD: "",
  PipelineCoverageHomeCFQ: "",
  NQPDataNon: "",
  NQPDataRenewals: "",
  PipelineCoverageNFQ2: "",
  PipelineCoverageNFQ3: "",
  PipelineCoverageDeals20KNFQ2: "",
  PipelineCoverageDeals20KNFQ3: "",
  pipelineCoverageCustTypeNFQ2Data: "",
  pipelineCoverageCustTypeNFQ3Data: "",
  pipelineCreatedMixAnalysisData: "",
  pipelineCreatedMixFilters: "",
  lkpStages: "",
  dashboardKeywords: "",
  chartsKeywordsMap: "",
  dashboardKeywordsMap: "",
  dashboardChartMapping: "",
  dashbaordIdNavItemMap: "",
  geniBotData: "",
  geniBotFilters: "",
  customerMetrics: "",
  CONV_AI_CONSTANTS: "",
  repPerformanceFilters: "",
  textFieldQuestion: "",
  executionGuidenceData: "",
  executionGuidenceFilters: "",
  PipelineCoverageEGCFQ: "",
  PipelineCoverageEGNFQ: "",
  PipelineCoverageEGFY: "",
  PipelineCoverageRepNFQ: "",
  PipelineCoverageRepCFQ: "",
  pipelineCoverageCustTypeCFQRepData: "",
  pipelineCoverageCustTypeNFQRepData: "",
  CallsCompletedData: "",
  CallsCompletedFilters: "",
  CustomerInteractionData: "",
  NewQualifiedPipelineDatav2: "",
  NewQualifiedPipelineFiltersv2: "",
  NQPDataSQLOpen: "",
  NQPDataNonOpen: "",
  NQPDataRenewalsOpen: "",
  NQPDataSQLCreated: "",
  NQPDataNonCreated: "",
  NQPDataRenewalsCreated: "",
  QuotaDatapdf: "",
  QuotaUrls: "",
  quotaLetterErrorMessage: "",
  EnablementEffectivenessFilters: "",
  EnablementEffictivenessData: "",
  AttainmentDataYTDACVRange: "",
  AttainmentDataCFQACVRange: "",
  PipelineCoverageShapeData: "",
  AttainmentDataCFQWRCR: "",
  AttainmentDataYTDWRCR: "",
  NQPDataUpsell: "",
  NQPDataCrossSell: "",
  pipelineCoverageDealCategoryCFQData: "",
  buildingPipelineData: "",
  buildingPipelineFilters: "",
  accountExpansionData: "",
  accountExpansionFilters: "",
  PipelineProgressionFilters: "",
  PipelineProgression: "",
  PipelineProgressionDrilldown: "",
  currentStateOfQualifiedPipelineData: "",
  currentStateOfQualifiedPipelineDrilldown: "",
  NQPDataCrossSell: "",
  pipelineCoverageDealCategoryCFQData: "",
  buildingPipelineData: "",
  buildingPipelineFilters: "",
  BSData: "",
  PipelineCoverageShapeDatav2: "",
  POCSSummaryFilters: "",
  POCSSummaryData: "",
  milestoneInfo: "",
  AttainmentDataNRRYTD: "",
  AttainmentDataNRRCFQ: "",
  AttainmentDataNRRcompletedFQ: "",
  ProductsByAccountFilters: "",
  ProductsByAccountData: "",
  ProductsByAccountDataByRegion: "",
  NQPData1st_rmContract: "",
  NQPData1st_rmYear_rmA_slash_B: "",
  NQPDataAdditional_rmBusiness: "",
  NQPDataNew_rmProspect: "",
  NQPDataPoweredBy: "",
  NQPDataRenewal: "",
  NQPDataNew_rmBusiness: "",
  buildingPipelineDatav2: "",
  changePasswordSuccess: false,
  forgotPasswordSuccess: false,
  EnsembleData: "",
  YTDMonthlyData: ""
};

export function app(state, action) {
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case "SW_INIT":
      return {
        ...state,
        serviceWorkerInitialized: true,
      };
    case "SW_UPDATE":
      return {
        ...state,
        serviceWorkerUpdate: true,
        seviceWorkerUpdateObject: action.payload,
      };

    case "get_pcd_Moved_filters_request":
      return {
        ...state,
        pcdMovedAnalysisFilters: "",
        pcdMovedMinMaxdate: "",
        selectedFilters: "",
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
      };
    case "get_pcd_Moved_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        pcdMovedAnalysisFilters: action.json.message.filters,
        pcdMovedMinMaxdate: action.json.message.minMax,
        persistentAlert: "",
      };

    case "get_pcd_Moved_data_request":
      return {
        ...state,
        pcdMovedAnalysisData: "",
        pcdMovedAnalysisDataErrorMessage: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_pcd_Moved_data_success":
      if (action.json.message.formattedData.countOppsData.length === 0) {
        alert = {
          id: state.alert.id + 1,
          type: "error",
          message: "Data not available for selected filters",
        };
        data = "";
        pcdMovedAnalysisDataErrorMessage =
          "Data not available for selected filters";
      } else {
        alert = {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        };
        data = action.json.message;
      }
      return {
        ...state,
        alert,
        pcdMovedAnalysisData: data,
        pcdMovedAnalysisDataErrorMessage,
        persistentAlert: "",
      };
    case "get_pcd_Moved_trends_data_request":
      return {
        ...state,
        pcdMovedAnalysisTrendsData: "",
        pcdMovedAnalysisDataErrorMessage: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_pcd_Moved_trends_data_success":
      if (action.json.message.trendsData.FQMovedResults.length === 0) {
        alert = {
          id: state.alert.id + 1,
          type: "error",
          message: "Data not available for selected filters",
        };
        data = "";
        pcdMovedAnalysisDataErrorMessage =
          "Data not available for selected filters";
      } else {
        alert = {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        };
        data = action.json.message;
      }
      return {
        ...state,
        alert,
        pcdMovedAnalysisTrendsData: data,
        pcdMovedAnalysisDataErrorMessage,
        persistentAlert: "",
      };

    case "get_managerSummary_waterfall_data_request":
      return {
        ...state,
        managerSummaryCustomData: "",
        currentFilters: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_managerSummary_waterfall_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        managerSummaryWaterfallData: action.json.message,
        persistentAlert: "",
      };

    case "get_managerSummary_custom_data_request":
      return {
        ...state,
        managerSummaryCustomData: "",
        currentFilters: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_managerSummary_custom_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        managerSummaryCustomData: action.json.message,
        persistentAlert: "",
      };
    case "get_managerSummary_custom_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_average_deal_size_filters_request":
      return {
        ...state,
        averageDealSizeFilters: "",
        currentFilters: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_average_deal_size_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        averageDealSizeFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_average_deal_size_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_average_deal_size_data_request":
      return {
        ...state,
        averageDealSizeData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_average_deal_size_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        averageDealSizeData: action.json.message,
        persistentAlert: "",
      };
    case "get_average_deal_size_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_repbenchmarking_filters_request":
      return {
        ...state,
        repBenchMarkingFilters: "",
        currentFilters: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_repbenchmarking_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        repBenchMarkingFilters: action.json.message,
        persistentAlert: "",
      };

    case "get_projected_pipeline_filters_request":
      return {
        ...state,
        updatingProjectedPipelineData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
      };
    case "get_version":
      return {
        ...state,
      };
    case "get_version_success":
      return {
        ...state,
        persistentAlert: "",
        version: action.version,
      };
    case "get_version_failure":
      return {
        ...state,
        version: "",
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating version",
        },
      };
    case "show_version_alert":
      return {
        ...state,
        persistentAlert: "",
        appUpdate: true,
        version: action.version,
        alert: {
          id: state.alert.id + 1,
          type: "warning",
          message: "New App Version available",
        },
      };

    case "get_projected_pipeline_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        projectedPipelineMinDate: action.json.message.minMax.minDate,
        projectedPipelineMaxDate: action.json.message.minMax.maxDate,
        projectedPipelineFilters: action.json.message.filters,
        persistentAlert: "",
      };

    case "get_projected_pipeline_filters_failure":
      return {
        ...state,
        updatingProjectedPipelineData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };

    case "get_ScoreCard_filters_v2_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        scorecardFilters_v2: "",
      };

    case "get_ScoreCard_filters_v2_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        scorecardFilters_v2: action.json.message,
      };

    //generic scorecard
    case "get_Generic_ScoreCard_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        genericScorecardFilters: "",
      };

    case "get_Generic_ScoreCard_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        genericScorecardFilters: action.json.message,
      };

    //generic rep scorecard
    case "get_Generic_Rep_ScoreCard_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        genericRepScorecardFilters: "",
      };

    case "get_Generic_Rep_ScoreCard_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        genericRepScorecardFilters: action.json.message,
      };

    //MS attainment Table data
    case "get_attainmentnqp_data_request":
      return {
        ...state,
        AttainmentNQPData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainmentnqp_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentNQPData: action.json.message,
        persistentAlert: "",
      };
    case "get_attainmentnqp_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_data_CFQ_request":
      return {
        ...state,
        PipelineCoverageCFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_data_CFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageCFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_data_CFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //Pipeline Coverage Home Page
    case "get_generic_pipeline_coverage_data_CFQ_home_request":
      return {
        ...state,
        PipelineCoverageHomeCFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_data_CFQ_home_success":
      console.log(action.json.message);
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageHomeCFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_data_CFQ_home_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //Manager Summary
    case "get_generic_pipeline_coverage_data_CFQ_v2_request":
      return {
        ...state,
        PipelineCoverageCFQV2: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_data_CFQ_v2_success":
      console.log(action.json.message);
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageCFQV2: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_data_CFQ_v2_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_data_NFQ_request":
      return {
        ...state,
        PipelineCoverageNFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_data_NFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageNFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_data_NFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_data_NFQ2_request":
      return {
        ...state,
        PipelineCoverageNFQ2: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_data_NFQ2_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageNFQ2: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_data_NFQ2_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_data_NFQ3_request":
      return {
        ...state,
        PipelineCoverageNFQ3: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_data_NFQ3_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageNFQ3: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_data_NFQ3_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_custType_data_CFQ_request":
      return {
        ...state,
        pipelineCoverageCustTypeCFQData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_custType_data_CFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        pipelineCoverageCustTypeCFQData: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_custType_data_CFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_custType_data_NFQ_request":
      return {
        ...state,
        pipelineCoverageCustTypeNFQData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_custType_data_NFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        pipelineCoverageCustTypeNFQData: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_custType_data_NFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_custType_data_NFQ2_request":
      return {
        ...state,
        pipelineCoverageCustTypeNFQ2Data: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_custType_data_NFQ2_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        pipelineCoverageCustTypeNFQ2Data: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_custType_data_NFQ2_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_custType_data_NFQ3_request":
      return {
        ...state,
        pipelineCoverageCustTypeNFQ3Data: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_custType_data_NFQ3_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        pipelineCoverageCustTypeNFQ3Data: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_custType_data_NFQ3_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_deals20_data_CFQ_request":
      return {
        ...state,
        PipelineCoverageDeals20KCFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_deals20_data_CFQ_success":
      console.log(action.json.message);
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageDeals20KCFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_deals20_data_CFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_deals20_data_NFQ_request":
      return {
        ...state,
        PipelineCoverageDeals20KNFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_deals20_data_NFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageDeals20KNFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_deals20_data_NFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_deals20_data_NFQ2_request":
      return {
        ...state,
        PipelineCoverageDeals20KNFQ2: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_deals20_data_NFQ2_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageDeals20KNFQ2: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_deals20_data_NFQ2_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_deals20_data_NFQ3_request":
      return {
        ...state,
        PipelineCoverageDeals20KNFQ3: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_deals20_data_NFQ3_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageDeals20KNFQ3: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_deals20_data_NFQ3_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_SQL_request":
      return {
        ...state,
        NQPDataSQL: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_SQL_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataSQL: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_SQL_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_SQLCQC_request":
      return {
        ...state,
        NQPDataSQLCQC: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_SQLCQC_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataSQLCQC: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_SQLCQC_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //Manager Summary
    case "get_msnqp_data_SQL_v2_request":
      return {
        ...state,
        NQPDataSQLV2: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_SQL_v2_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataSQLV2: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_SQL_v2_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_SAL_request":
      return {
        ...state,
        NQPDataSAL: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_SAL_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataSAL: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_SAL_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_New_request":
      return {
        ...state,
        NQPDataNew: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_New_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataNew: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_New_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //Sql Non data
    case "get_msnqp_data_Non_request":
      return {
        ...state,
        NQPDataNon: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_Non_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataNon: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_Non_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //Sql Renewals
    case "get_msnqp_data_Renewals_request":
      return {
        ...state,
        NQPDataRenewals: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_Renewals_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataRenewals: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_Renewals_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_Segment_request":
      return {
        ...state,
        NQPDataSegment: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_Segment_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataSegment: action.json.message,
        persistentAlert: "",
      };

    case "get_msnqp_data_Segment_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_Deal_request":
      return {
        ...state,
        NQPDataDeal: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_Deal_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataDeal: action.json.message,
        persistentAlert: "",
      };

    case "get_msnqp_data_Deal_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_scorecard_mqo_data_request":
      return {
        ...state,
        MQOData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_scorecard_mqo_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        MQOData: action.json.message,
        persistentAlert: "",
      };
    case "get_scorecard_mqo_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_scorecard_ACVCreated_data_request":
      return {
        ...state,
        ACVCreatedData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_scorecard_ACVCreated_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        ACVCreatedData: action.json.message,
        persistentAlert: "",
      };
    case "get_scorecard_ACVCreated_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_Existing_request":
      return {
        ...state,
        NQPDataExisting: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_Existing_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataExisting: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_Existing_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //attainment section
    case "get_attainment_data_YTD_request":
      return {
        ...state,
        AttainmentDataYTD: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_YTD_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentDataYTD: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_YTD_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_attainment_rep_data_YTD_request":
      return {
        ...state,
        AttainmentRepDataYTD: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_rep_data_YTD_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentRepDataYTD: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_rep_data_YTD_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //attainment Home Section
    //attainment section
    case "get_attainment_data_YTD_home_request":
      return {
        ...state,
        AttainmentHomeDataYTD: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_YTD_home_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentHomeDataYTD: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_YTD_home_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_attainment_data_completedFQ_request":
      return {
        ...state,
        AttainmentDatacompletedFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_completedFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentDatacompletedFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_completedFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_attainment_data_CFQ_request":
      return {
        ...state,
        AttainmentDataCFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_CFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentDataCFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_CFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_attainment_rep_data_completedFQ_request":
      return {
        ...state,
        AttainmentRepDatacompletedFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_rep_data_completedFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentRepDatacompletedFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_rep_data_completedFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_attainment_rep_data_CFQ_request":
      return {
        ...state,
        AttainmentRepDataCFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_rep_data_CFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentRepDataCFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_rep__data_CFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //attainment Section Home CFQ
    case "get_attainment_data_CFQ_home_request":
      return {
        ...state,
        AttainmentHomeDataCFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_CFQ_home_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentHomeDataCFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_CFQ_home_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_scorecard_pipeline_coverage_request":
      return {
        ...state,
        PipelineCoverageData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_scorecard_pipeline_coverage_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageData: action.json.message,
        persistentAlert: "",
      };
    case "get_scorecard_pipeline_coverage_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_projected_pipeline_data_request":
      return {
        ...state,
        updatingProjectedPipelineData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_projected_pipeline_data_success":
      return {
        ...state,
        updatingProjectedPipelineData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        projectedPipelineData: action.json.message,
        persistentAlert: "",
      };
    case "get_qtd_greaterthan20k_data_request":
      return {
        ...state,
        QTDGreaterthan20kData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_qtd_greaterthan20k_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        QTDGreaterthan20kData: action.json.message,
        persistentAlert: "",
      };
    case "get_qtd_greaterthan20k_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_stageProgression_filters_request":
      return {
        ...state,
        StageProgressionFilters: "",
        currentFilters: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_stageProgression_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        StageProgressionFilters: action.json.message,
        persistentAlert: "",
      };

    case "get_stageProgression_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating filters",
        },
        persistentAlert: "",
      };
    case "get_rep_certification_data_request":
      return {
        ...state,
        RepCertificationData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_rep_certification_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        RepCertificationData: action.json.message,
        persistentAlert: "",
      };

    case "get_rep_certification_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_rep_certification_filters_request":
      return {
        ...state,
        RepCertificationFilters: "",
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
      };

    case "get_rep_certification_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        RepCertificationFilters: action.json.message,
        persistentAlert: "",
      };

    case "get_rep_certification_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating filters.",
        },
        persistentAlert: "",
      };

    case "get_stageProgression_data_request":
      return {
        ...state,
        StageProgressionData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_stageProgression_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        StageProgressionData: action.json.message,
        persistentAlert: "",
      };

    case "get_stageProgression_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "selected_scatter":
      return {
        ...state,
        scatterpoint: action.scatterpoint,
      };
    case "deselected_scatter":
      return {
        ...state,
        descatterpoint: action.descatterpoint,
        scatterpoint: "",
      };
    case "get_projected_pipeline_data_failure":
      return {
        ...state,
        updatingProjectedPipelineData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };

    case "get_funnelCompare_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        funnelCompareFilters: "",
      };
    case "get_win_acv_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        winAcvMixFilters: "",
      };

    case "get_funnelCompare_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        funnelCompareFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_win_acv_mix_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        winAcvMixFilters: action.json.message,
        lkpFilters: action.json.lkpFilters,
        persistentAlert: "",
      };

    case "get_funnelCompare_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_win_acv_mix_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_AccountHealth_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        accountHealthFilters: "",
      };
    case "get_AccountHealth_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        accountHealthFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_AccountHealth_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_AccountHealthGetWellSummary_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        accountHealthGetWellSummaryFilters: "",
      };
    case "get_AccountHealthGetWellSummary_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        accountHealthGetWellSummaryFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_AccountHealthGetWellSummary_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_AccountHealthByTeam_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        accountHealthByTeamFilters: "",
      };
    case "get_AccountHealthByTeam_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        accountHealthByTeamFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_AccountHealthByTeam_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_AccountHealthChangeOverTime_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        accountHealthChangeOverTimeFilters: "",
      };
    case "get_AccountHealthChangeOverTime_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        accountHealthChangeOverTimeFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_AccountHealthChangeOverTime_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_QBR_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        QBRFilters: "",
      };
    case "get_QBR_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        QBRFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_QBR_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_QBRF2F_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        QBRF2FFilters: "",
      };
    case "get_QBRF2F_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        QBRF2FFilters: action.json.message,
        persistentAlert: "",
      };
    case "selected_filters":
      return {
        ...state,
        selectedFilters: action.selectedFilters,
      };

    case "get_QBRF2F_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_QBROnBoard_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        QBROnBoardFilters: "",
      };
    case "get_QBROnBoard_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        QBROnBoardFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_QBROnBoard_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_QBROutReach_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        QBROutReachFilters: "",
      };
    case "get_QBROutReach_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        QBROutReachFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_QBROutReach_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_RenewalsCompleted_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        RenewalsCompletedFilters: "",
      };
    case "get_RenewalsCompleted_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        RenewalsCompletedFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_RenewalsCompleted_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_RenewalsPriceIncrease_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        RenewalsPriceIncreaseFilters: "",
      };
    case "get_RenewalsPriceIncrease_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        RenewalsPriceIncreaseFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_RenewalsPriceIncrease_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_RenewalsOverDue_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        RenewalsOverDueFilters: "",
      };
    case "get_RenewalsOverDue_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        RenewalsOverDueFilters: action.json.message,
        persistentAlert: "",
      };
    case "get_RenewalsOverDue_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };

    case "get_funnelCompare_data_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        funnelCompareData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_funnelCompare_data_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        funnelCompareData: action.json.message,
        persistentAlert: "",
      };

    case "get_funnelCompare_data_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_AsOfDate_data_request":
      return {
        ...state,
        AsOfDateData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_AsOfDate_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AsOfDateData: action.json.message,
        persistentAlert: "",
      };

    case "get_AsOfDate_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_AsOfDate_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        RepPerformanceYTDFilters: "",
      };

    case "get_AsOfDate_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        RepPerformanceYTDFilters: action.json.message,
      };
    case "get_AsOfDate_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };

    case "get_win_rate_analysis_data_request":
      return {
        ...state,
        updatingFunnelData: true,
        winRateData: "",
        winRateStages: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_win_rate_analysis_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        winRateData: action.json.message,
        winRateStages: action.json.message.result
          .map((item) => item.stage_name)
          .filter(getUniqueValues),
        persistentAlert: "",
      };

    case "get_win_rate_analysis_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_AccountHealth_data_request":
      return {
        ...state,
        accountHealthData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_AccountHealth_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        accountHealthData: action.json.message,
        persistentAlert: "",
      };

    case "get_AccountHealth_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_AccountHealthGetWellSummary_data_request":
      return {
        ...state,
        accountHealthGetWellSummaryData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_AccountHealthGetWellSummary_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        accountHealthGetWellSummaryData: action.json.message,
        persistentAlert: "",
      };

    case "get_AccountHealthGetWellSummary_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_AccountHealthByTeam_data_request":
      return {
        ...state,
        accountHealthByTeamData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_AccountHealthByTeam_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        accountHealthByTeamData: action.json.message,
        persistentAlert: "",
      };

    case "get_AccountHealthByTeam_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_AccountHealthChangeOverTime_data_request":
      return {
        ...state,
        accountHealthChangeOverTimeData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_AccountHealthChangeOverTime_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        accountHealthChangeOverTimeData: action.json.message,
        persistentAlert: "",
      };

    case "get_AccountHealthChangeOverTime_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_QBR_data_request":
      return {
        ...state,
        QBRData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_QBR_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        QBRData: action.json.message,
        persistentAlert: "",
      };

    case "get_QBR_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_RenewalsCompleted_data_request":
      return {
        ...state,
        RenewalsCompletedData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_RenewalsCompleted_data_success":
      const renewalDataCompleted = action.json.message[0].map((item) => ({
        ...item,
        type: "Completed",
      }));
      const renewalDataOpen = action.json.message[1].map((item) => ({
        ...item,
        type: "Open",
      }));
      const renewalDataCompletedRegion = action.json.message[2].map((item) => ({
        ...item,
        type: "Completed",
      }));
      const renewalDataOpenRegion = action.json.message[3].map((item) => ({
        ...item,
        type: "Open",
      }));
      let renewalDataAccountSegment = action.json.message[0].map((item) => {
        if (item.Renewal_Overdue === "N") {
          return { ...item, type: "CompletedOnTime" };
        }
        if (item.Renewal_Overdue === "Y") {
          return { ...item, type: "CompletedDelayed" };
        }
      });

      renewalDataAccountSegment = [
        ...renewalDataAccountSegment,
        ...action.json.message[1].map((item) => {
          if (item.Renewal_Overdue === "N") {
            return { ...item, type: "Open" };
          }
          if (item.Renewal_Overdue === "Y") {
            return { ...item, type: "OpenOverdue" };
          }
        }),
      ].filter((item) => item !== undefined);
      let renewalDataRegionRollup = action.json.message[2].map((item) => {
        if (item.Renewal_Overdue === "N") {
          return { ...item, type: "CompletedOnTime" };
        }
        if (item.Renewal_Overdue === "Y") {
          return { ...item, type: "CompletedDelayed" };
        }
      });

      renewalDataRegionRollup = [
        ...renewalDataRegionRollup,
        ...action.json.message[3].map((item) => {
          if (item.Renewal_Overdue === "N") {
            return { ...item, type: "Open" };
          }
          if (item.Renewal_Overdue === "Y") {
            return { ...item, type: "OpenOverdue" };
          }
        }),
      ].filter((item) => item !== undefined);

      // console.log(action.json.message[0].filter(item => item.Renewal_Overdue === ""), action.json.message[1].filter(item => item.Renewal_Overdue === ""), action.json.message[2].filter(item => item.Renewal_Overdue === ""), action.json.message[3].filter(item => item.Renewal_Overdue === ""))
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        RenewalsCompletedData: [...renewalDataCompleted, ...renewalDataOpen],
        RenewalsCompletedDataRegion: [
          ...renewalDataCompletedRegion,
          ...renewalDataOpenRegion,
        ],
        RenewalDataCombinedAccountSegment: renewalDataAccountSegment,
        RenewalDataCombinedRegionRollup: renewalDataRegionRollup,
        persistentAlert: "",
      };

    case "get_RenewalsCompleted_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_RenewalsPriceIncrease_data_request":
      return {
        ...state,
        RenewalsPriceIncreaseData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_RenewalsPriceIncrease_data_success":
      // const renewalDataCompleted = action.json.message[0].map(item => ({ ...item, type: "Completed" }))
      // const renewalDataOpen = action.json.message[1].map(item => ({ ...item, type: "Open" }))

      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        RenewalsPriceIncreaseData: action.json.message[0],
        persistentAlert: "",
      };

    case "get_RenewalsPriceIncrease_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_RenewalsOverDue_data_request":
      return {
        ...state,
        RenewalsOverDueData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_RenewalsOverDue_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        RenewalsOverDueData: action.json.message[0],
        persistentAlert: "",
      };

    case "get_RenewalsOverDue_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_QBROutReach_data_request":
      return {
        ...state,
        QBROutReachData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_QBROutReach_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        QBROutReachData: action.json.message[0],
        persistentAlert: "",
      };

    case "get_QBROutReach_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_QBRF2F_data_request":
      return {
        ...state,
        QBRF2FData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_QBRF2F_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        QBRF2FData: action.json.message[0],
        persistentAlert: "",
      };

    case "get_QBRF2F_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_QBROnBoard_data_request":
      return {
        ...state,
        QBROnBoardData: "",
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_QBROnBoard_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        QBROnBoardData: action.json.message[0],
        persistentAlert: "",
      };

    case "get_QBROnBoard_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_win_rate_analysis_drilldown_data_request":
      return {
        ...state,
        winRateDrilldownData: "",
        updatingFunnelData: true,

        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_win_rate_analysis_drilldown_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        winRateDrilldownData: action.json.message,
        persistentAlert: "",
      };

    case "get_win_rate_analysis_drilldown_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_win_rate_analysis_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        winRateFilters: "",
      };

    case "get_win_rate_analysis_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        winRateFilters: action.json.message,
      };

    case "get_win_rate_analysis_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };
    case "get_pbi_pipeline_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        PBIPipelineFilters: "",
      };

    case "get_pbi_pipeline_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        PBIPipelineFilters: action.json.message,
      };
    case "get_pbi_pipeline_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };
    case "get_pbi_summary_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        PBISummaryFilters: "",
      };

    case "get_pbi_summary_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        PBISummaryFilters: action.json.message,
      };
    case "get_pbi_summary_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_pipeline_mix_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        pipelineMixFilters: "",
        lkpFilters: "",
      };

    case "get_pipeline_mix_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        pipelineMixFilters: action.json.message,
        lkpFilters: action.json.lkpFilters,
      };
    case "get_pipeline_mix_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };
    case "get_plotly_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
      };
    case "get_plotly_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        pipelineMixFilters: action.json.message,
        lkpFilters: action.json.lkpFilters,
      };
    case "get_SegmentPerformance_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
      };

    case "get_SegmentPerformance_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        pipelineMixFilters: action.json.message,
      };
    case "get_SegmentPerformance_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };
    case "get_new_win_acv_mix_analysis_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
      };

    case "get_new_win_acv_mix_analysis_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        NewWinAcvMixFilters: action.json.message,
      };
    case "get_new_win_acv_mix_analysis_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_new_win_acv_mix_analysis_data_request":
      return {
        ...state,
        NewWinAcvMixData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_new_win_acv_mix_analysis_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NewWinAcvMixData: action.json.message,
        persistentAlert: "",
      };
    case "get_new_win_acv_mix_analysis_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_pbi_pipeline_data_request":
      return {
        ...state,
        PBIPipelineData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_pbi_pipeline_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PBIPipelineData: action.json.message[0],
        persistentAlert: "",
      };
    case "get_pbi_pipeline_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_pbi_summary_data_request":
      return {
        ...state,
        PBISummaryData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_pbi_summary_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PBISummaryData: action.json.message[0],
        persistentAlert: "",
      };
    case "get_pbi_summary_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_pbi_mekkochart_data_request":
      return {
        ...state,
        MekkoData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_pbi_mekkochart_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        MekkoData: action.json.message,
        persistentAlert: "",
      };
    case "get_pbi_mekkochart_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_region_performance_data_request":
      return {
        ...state,
        RegionPerformanceData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_region_performance_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        RegionPerformanceData: action.json.message[0],
        persistentAlert: "",
      };
    case "get_region_performance_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_team_attainment_data_request":
      return {
        ...state,
        TeamAttainmentData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_team_attainment_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        TeamAttainmentData: action.json.message[0],
        persistentAlert: "",
      };
    case "get_team_attainment_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_rep_performance_analysis_data_request":
      return {
        ...state,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_win_acv_mix_analysis_data_request":
      return {
        ...state,
        winAcvAnalysisData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_pipeline_mix_analysis_data_request":
      return {
        ...state,
        pipelineMixAnalysisData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_rep_performance_analysis_data_success":
      let repPerformanceData;
      if (action.json.message.errorMessage !== undefined) {
        repPerformanceData = action.json.message.errorMessage;
      } else {
        repPerformanceData = action.json.message;
      }
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        repPerformanceData,
        persistentAlert: "",
      };
    case "get_win_acv_mix_analysis_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        winAcvAnalysisData: action.json.message,
        persistentAlert: "",
      };
    case "get_pipeline_mix_analysis_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        pipelineMixAnalysisData: action.json.message,
        persistentAlert: "",
      };
    case "get_rep_performance_analysis_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_win_acv_mix_analysis_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_pipeline_mix_analysis_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_closed_lost_opp_analysis_data_request":
      return {
        ...state,
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
        closedLostOppData: "",
      };

    case "get_closed_lost_opp_analysis_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        closedLostOppData: action.json.message,
        persistentAlert: "",
      };

    case "get_closed_lost_opp_analysis_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_closed_lost_opp_analysis_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        closedLostOppFilters: "",
      };

    case "get_closed_lost_opp_analysis_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        closedLostOppFilters: action.json.message,
      };

    case "get_closed_lost_opp_analysis_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };
    case "get_strength_of_sales_data_request":
      return {
        ...state,
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_strength_of_sales_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        strengthOfSalesData: action.json.message[0],
        persistentAlert: "",
      };

    case "get_strength_of_sales_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_strength_of_sales_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
      };

    case "get_strength_of_sales_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        strengthOfSalesFilters: action.json.message,
      };

    case "get_strength_of_sales_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_CurrentState_data_request":
      return {
        ...state,
        updatingNQPData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_CurrentState_data_success":
      return {
        ...state,
        updatingNQPData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        CurrentStateData: action.json.message,
        persistentAlert: "",
      };

    case "get_CurrentState_data_failure":
      return {
        ...state,
        updatingNQPData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_CurrentState_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
      };

    case "get_CurrentState_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        CurrentStateFilters: action.json.message,
      };

    case "get_CurrentState_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_NewQualifiedPipeline_data_request":
      return {
        ...state,
        updatingNQPData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
        NewQualifiedPipelineData: "",
      };

    case "get_NewQualifiedPipeline_data_success":
      return {
        ...state,
        updatingNQPData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NewQualifiedPipelineData: action.json.message,
        persistentAlert: "",
      };

    case "get_NewQualifiedPipeline_data_failure":
      return {
        ...state,
        updatingNQPData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //Oppotunity Pyramid Chart
    case "get_OpportunityPyramid_data_request":
      return {
        ...state,
        OpportunityPyramidData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
        OpportunityPyramidData: "",
      };

    case "get_OpportunityPyramid_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        OpportunityPyramidData: action.json.message,
        persistentAlert: "",
      };

    case "get_OpportunityPyramid_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_OpportunityPyramid_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        OpportunityPyramidFilters: "",
      };

    case "get_OpportunityPyramid_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        OpportunityPyramidFilters: action.json.message,
        lkpFilters: action.json.lkpFilters,
      };
    case "get_OpportunityPyramid_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_NewQualifiedPipeline_drilldown_data_request":
      return {
        ...state,
        NewQualifiedPipelineDrilldownData: "",
        updatingNQPData: true,
        persistentAlert: {
          type: "info",
          message: "Updating drilldown data...",
        },
      };

    case "clear_NewQualifiedPipeline_drilldown_data":
      return {
        ...state,
        NewQualifiedPipelineDrilldownData: "",
      };
    case "get_NewQualifiedPipeline_drilldown_data_success":
      return {
        ...state,
        updatingNQPData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NewQualifiedPipelineDrilldownData: action.json.message,
        persistentAlert: "",
      };

    case "get_NewQualifiedPipeline_drilldown_data_failure":
      return {
        ...state,
        updatingNQPData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_NewQualifiedPipeline_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
      };

    case "get_NewQualifiedPipeline_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        NewQualifiedPipelineFilters: action.json.message,
      };

    case "get_NewQualifiedPipeline_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_homepage_data_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating data...",
        },
        persistentAlert: "",
      };
    case "get_homepage_data_success":
      return {
        ...state,
        homepageData: action.homepageData,
        persistentAlert: "",
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
      };
    case "get_homepage_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "add_waiting_chat_message":
      return {
        ...state,
        conversationalAIMessages: [
          ...state.conversationalAIMessages,
          {
            botMessage: action.botMessage,
            text: action.text,
            isAnswered: action.isAnswered,
            dashboards: action.dashboards,
          },
        ],
        waitingForAiResponse: false,
      };
    case "reset_filters":
      return {
        ...state,
        geniBotFilters: "",
        geniBotData: "",
      };
    case "update_toggle_waiting":
      return {
        ...state,
        waitingForAiResponse: action.shouldWait,
      };
    case "add_chat_message_undocked":
      return {
        ...state,
        textFieldQuestion: action.text,
      };
    case "clear_text_field_question":
      return {
        ...state,
        textFieldQuestion: "",
      };
    case "add_chat_message":
      const shouldWait = !action.botMessage;
      console.log(state.conversationalAIMessages);
      if (action.unProcessed) {
        return {
          ...state,
          conversationalAIMessages: [
            ...state.conversationalAIMessages,
            {
              botMessage: action.botMessage,
              text: action.text,
              isAnswered: action.isAnswered,
              dashboards: action.dashboards,
              unProcessed: action.unProcessed,
            },
          ],
          waitingForAiResponse: shouldWait,
        };
      }
      return {
        ...state,
        conversationalAIMessages: [
          ...state.conversationalAIMessages,
          {
            botMessage: action.botMessage,
            text: action.text,
            isAnswered: action.isAnswered,
            dashboards: action.dashboards,
          },
        ],
        waitingForAiResponse: shouldWait,
      };
    case "toogle_waiting":
      return { ...state, waitingForAiResponse: action.shouldWait };
    case "toggle_conversational_ai_window":
      return {
        ...state,
        openConversationalAIWindow: !state.openConversationalAIWindow,
      };

    case "get_settings_request":
      return {
        ...state,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
        settings: "",
      };

    case "get_settings_success":
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let lastPull = action.json.message.filter(
        (m) => m.settings_key === "last_sfdc_pull_datetime"
      )[0].settings_datetime;
      let tz = action.json.message.filter(
        (m) => m.settings_key === "timezone"
      )[0].settings_value;
      const lastTimePull = new Intl.DateTimeFormat("en", {
        timeZone: "UTC",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }).format(new Date(lastPull));
      console.log(lastTimePull);
      let lastPullString = `${lastTimePull} ${tz}, ${new Intl.DateTimeFormat(
        "en",
        { timeZone: "UTC", weekday: "long" }
      ).format(new Date(lastPull))}, ${customDateFormatter(lastPull)}`;
      let nextPull = action.json.message.filter(
        (m) => m.settings_key === "next_sfdc_pull_datetime"
      )[0].settings_datetime;
      const nextTimePull = new Intl.DateTimeFormat("en", {
        timeZone: "UTC",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }).format(new Date(nextPull));
      let nextPullString = `${nextTimePull} ${tz}, ${new Intl.DateTimeFormat(
        "en",
        { timeZone: "UTC", weekday: "long" }
      ).format(new Date(nextPull))}, ${customDateFormatter(nextPull)}`;

      return {
        ...state,
        settings: {
          lastPull: lastPullString,
          nextPull: nextPullString,
        },
        persistentAlert: "",
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
      };
    case "get_settings_request_score_card":
      return {
        ...state,
        settings: "",
      };

    case "get_settings_success_score_card":
      return {
        ...state,
        settings: {
          lastPull: action.json.message.filter(
            (m) => m.settings_key === "last_sfdc_pull_datetime"
          )[0].settings_value,
          nextPull: action.json.message.filter(
            (m) => m.settings_key === "next_sfdc_pull_datetime"
          )[0].settings_value,
        },
        persistentAlert: "",
      };

    case "get_settings_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "add_checked":
      return {
        ...state,
        checked: action.username,
      };

    case "remove_checked":
      return {
        ...state,
        checked: "",
      };

    case "soft_login":
      return {
        ...state,
        loggedIn: JSON.parse(localStorage.getItem("skygeni-user"))
          ? true
          : false,
        user: JSON.parse(localStorage.getItem("skygeni-user"))
          ? JSON.parse(localStorage.getItem("skygeni-user"))
          : "",
        currentPage: "Home",
        enhancedInsightsPage: "home",
        repPerformancePage: "home",
        productInsightsPage: "home",
      };

    case "get_performance_by_industry_data_request":
      return {
        ...state,
        updatingPBIData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_performance_by_industry_data_success":
      return {
        ...state,
        updatingPBIData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        performance_by_industryData: action.json.message,
        pbiNew: action.json.final,
        persistentAlert: "",
      };

    case "get_performance_by_industry_data_failure":
      return {
        ...state,
        updatingPBIData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_performance_by_industry_filters_request":
      return {
        ...state,
        performance_by_industryFilters: "",
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
      };

    case "get_performance_by_industry_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        performance_by_industryFilters: action.json.message,
      };

    case "get_performance_by_industry_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_waterfall_min_date_request":
      return {
        ...state,
        waterfallMinDate: null,
        waterfallMaxDate: null,
        waterfallFilters: "",
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
      };

    case "get_waterfall_min_date_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        waterfallMinDate: action.json.message.minMax.minDate,
        waterfallMaxDate: action.json.message.minMax.maxDate,
        waterfallFilters: action.json.message.filters,
        persistentAlert: "",
      };

    case "get_waterfall_min_date_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };

    case "get_waterfall_data_request":
      return {
        ...state,
        updatingWaterfallData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_waterfall_data_success":
      return {
        ...state,
        updatingWaterfallData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        waterfallData: action.json.message,
        persistentAlert: "",
        waterfallError: false,
      };

    case "get_waterfall_data_failure":
      if (action.error === "Negative starting pipeline") {
        return {
          ...state,
          updatingWaterfallData: false,
          persistentAlert: "",
          waterfallError: true,
        };
      } else {
        return {
          ...state,
          updatingWaterfallData: false,
          alert: {
            id: state.alert.id + 1,
            type: "error",
            message: "Error updating data",
          },
          persistentAlert: "",
          waterfallError: false,
        };
      }

    case "signout":
      localStorage.removeItem("skygeni-user");
      localStorage.removeItem("skygeni-user-page");
      localStorage.removeItem("skygeni-user-enhancedPage");
      localStorage.removeItem("skygeni-user-repPage");
      localStorage.removeItem("skygeni-user-customerSuccessPage");
      localStorage.removeItem("params");
      return {
        ...initialState,
        loggedIn: false,
        userLoaded: true,
        user: "",
        checked: JSON.parse(localStorage.getItem("skygeni-user-checked"))
          ? JSON.parse(localStorage.getItem("skygeni-user-checked"))
          : "",
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: action.payload ? action.payload.message : "Signed out",
        },
      };
    case "unprovisioned_user":
      return {
        ...state,
        loggedIn: false,
        userLoaded: true,
        user: "",
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.payload ? action.payload.message : "Signed out",
          returnCode: action.payload.errorCode,
        },
      };
    case "signout_inactivity":
      localStorage.removeItem("skygeni-user");
      localStorage.removeItem("skygeni-user-page");
      localStorage.removeItem("skygeni-user-enhancedPage");
      localStorage.removeItem("skygeni-user-repPage");
      localStorage.removeItem("skygeni-user-customerSuccessPage");
      localStorage.removeItem("params");
      return {
        ...initialState,
        loggedIn: false,
        userLoaded: true,
        user: "",
        checked: JSON.parse(localStorage.getItem("skygeni-user-checked"))
          ? JSON.parse(localStorage.getItem("skygeni-user-checked"))
          : "",
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: action.payload ? action.payload.message : "Signed out",
          returnCode: action.payload.errorCode,
        },
      };
    case "change_SettingsPage":
      return {
        ...state,
        funnelData: "",
        createdFunnelData: "",
        waterfallData: "",
        settingsPage: action.loc,
      };
    case "change_QuotasPage":
      return {
        ...state,
        funnelData: "",
        createdFunnelData: "",
        waterfallData: "",
        quotaGenerationPage: action.loc,
      };
    case "change_enhancedInsightsPage":
      return {
        ...state,
        funnelData: "",
        createdFunnelData: "",
        waterfallData: "",
        repPerformancePage: "",
        currentPage: "Enhanced Insights",
        enhancedInsightsPage: action.loc,
      };

    case "change_repPerformancePage":
      return {
        ...state,
        funnelData: "",
        createdFunnelData: "",
        waterfallData: "",
        enhancedInsightsPage: "",
        currentPage: "Rep Performance",
        repPerformancePage: action.loc,
      };

    case "change_productInsightsPage":
      return {
        ...state,
        funnelData: "",
        createdFunnelData: "",
        waterfallData: "",
        productInsightsPage: action.loc,
      };

    case "change_customerSuccessPage":
      return {
        ...state,
        funnelData: "",
        createdFunnelData: "",
        waterfallData: "",
        customerSuccessPage: action.loc,
      };

    case "change_page":
      return {
        ...state,
        currentPage: action.text,
        funnelFilters: "",
        funnelData: "",
        createdFunnelData: "",
        waterfallData: "",
        waterfallMinDate: null,
        waterfallMaxDate: null,
        waterfallFilters: "",
        enhancedInsightsPage: "home",
        repPerformancePage: "home",
        customerSuccessPage: "home",
        productInsightsPage: "home",
        settingsPage: "home",
        quotaGenerationPage: "home",
        projectedPipelineData: "",
        projectedPipelineFilters: "",
        projectedPipelineMinDate: null,
        projectedPipelineMaxDate: null,
      };
    case "get_created_funnel_data_request":
      return {
        ...state,
        updatingFunnelData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_created_funnel_data_success":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        createdFunnelData: action.json.message,
        persistentAlert: "",
      };

    case "get_created_funnel_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_created_funnel_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
      };

    case "get_created_funnel_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        createdFunnelFilters: action.json.message,
      };

    case "get_created_funnel_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_funnel_data_request":
      if (action.geniBot) {
        return {
          ...state,

          geniBotData: "",
        };
      }
      return {
        ...state,
        updatingFunnelData: true,
        funnelData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_funnel_data_success":
      if (action.geniBot) {
        return {
          ...state,

          geniBotData: action.json.message,
          persistentAlert: "",
        };
      }
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        funnelData: action.json.message,
        persistentAlert: "",
      };

    case "get_funnel_data_failure":
      return {
        ...state,
        updatingFunnelData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_funnel_filters_request":
      if (action.geniBot) {
        return {
          ...state,
          geniBotFilters: "",
        };
      }
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        funnelFilters: "",
      };

    case "get_funnel_filters_success":
      if (action.geniBot) {
        return {
          ...state,
          geniBotFilters: action.json.message,
        };
      }
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        funnelFilters: action.json.message,
      };

    case "get_funnel_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "change_pass_loggedin_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating password...",
        },
      };

    case "change_pass_loggedin_success":
      return {
        ...state,
        persistentAlert: "",
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Password updated",
        },
      };

    case "change_pass_loggedin_failure":
      console.log(action);
      return {
        ...state,
        persistentAlert: "",
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.payload
            ? action.payload.message
            : "Invalid Current password.",
        },
      };

    case "change_password_loggedin_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.payload
            ? action.payload.message
            : "Invalid Current password.",
        },
      };

    case "update_pass_request":
      return {
        ...state,
        persistentAlert: {
          type: "info",
          message: "Updating password...",
        },
        passwordUpdated: false,
      };

    case "update_pass_success":
      return {
        ...state,
        persistentAlert: "",
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Password updated",
        },
        passwordUpdated: true,
      };

    case "update_pass_failure":
      return {
        ...state,
        persistentAlert: "",
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "change_pass_request":
      return {
        ...state,
        changePasswordSuccess: false,
        forgotPasswordSuccess: false,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Changing password...",
        },
      };

    case "change_pass_success":
      return {
        ...state,
        persistentAlert: "",
        changePasswordSuccess: true,
        forgotPassToken: "",
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Password changed. Please sign in with the new password.",
          returnCode: 999,
        },
      };

    case "change_pass_failure":
      return {
        ...state,
        persistentAlert: "",
        forgotPassToken: "",
        changePasswordSuccess: false,
        forgotPasswordSuccess: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error.message,
          returnCode: 999,
        },
      };

    case "forgot_pass_request":
      return {
        ...state,
        forgotPassToken: "",
        changePasswordSuccess: false,
        forgotPasswordSuccess: false,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Sending code...",
        },
      };

    case "forgot_pass_success":
      return {
        ...state,
        forgotPassToken: action.json.message,
        changePasswordSuccess: false,
        forgotPasswordSuccess: true,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Email sent with code",
        },
        persistentAlert: "",
      };

    case "forgot_pass_failure":
      return {
        ...state,
        changePasswordSuccess: false,
        forgotPasswordSuccess: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error.message,
          returnCode: 999,
        },
        persistentAlert: "",
      };

    case "login_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Signing in...",
          status: 401,
        },
        persistentAlert: "",
      };

    case "login_success":
      localStorage.setItem("skygeni-user", JSON.stringify(action.json.message));
      if (
        action.json.message.loginRedirectUrl !== undefined &&
        action.json.message.loginRedirectUrl !== null
      ) {
        console.log("redirect_url_message");
        window.location.href = `${action.json.message.loginRedirectUrl}`;
        return;
      }
      localStorage.setItem("skygeni-user-page", "Home");

      // localStorage.setItem(
      //   "skygeni-viz-version",
      //   action.json.message.serverInfo.version
      // );
      return {
        ...state,
        persistentAlert: "",
        loggedIn: true,
        user: action.json.message,
        currentPage: action.json.message.elements[0].navItem,
        enhancedInsightsPage: "home",
        favouritesPage: "home",
        repPerformancePage: "home",
        productInsightsPage: "home",
      };

    case "login_failure":
      console.log(action.error);
      return {
        ...state,
        persistentAlert: "",
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error.message ? action.error.message : action.error,
          status: 401,
          returnCode: action.error.returnCode || action.error.errorCode,
        },
      };

    case "get_drilldown_on_graph_request":
      return {
        ...state,
        drilldownOnGraphData: "",
        updatingDrillDownData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_drilldown_on_graph_success":
      return {
        ...state,
        updatingDrillDownData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        drilldownOnGraphData: action.json.message,
        persistentAlert: "",
      };

    case "get_drilldown_on_graph_failure":
      return {
        ...state,
        updatingDrillDownData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_atdrilldown_on_graph_request":
      return {
        ...state,
        drilldownOnGraphData: "",
        updatingDrillDownData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_atdrilldown_on_graph_success":
      return {
        ...state,
        updatingDrillDownData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        drilldownOnGraphData: action.json.message,
        persistentAlert: "",
      };

    case "get_atdrilldown_on_graph_failure":
      return {
        ...state,
        updatingDrillDownData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "clear_drilldown_data": {
      return {
        ...state,
        drilldownOnGraphData: "",
      };
    }

    case "clear_manager_summary_data": {
      return {
        ...state,
        managerSummaryCustomData: "",
        PipelineCoverageCFQV2: "",
        NQPDataSQLV2: "",
      };
    }

    case "get_drilldown_on_open_request":
      return {
        ...state,
        updatingDrillDownData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_drilldown_on_open_success":
      return {
        ...state,
        updatingDrillDownData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        drilldownOnOpenData: action.json.message.recordset,
        persistentAlert: "",
      };

    case "get_drilldown_on_open_failure":
      return {
        ...state,
        updatingDrillDownData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "clear_drilldown_data_open": {
      return {
        ...state,
        drilldownOnOpenData: "",
      };
    }
    case "get_drilldown_on_open2_request":
      return {
        ...state,
        updatingDrillDownData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_drilldown_on_open2_success":
      return {
        ...state,
        updatingDrillDownData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        drilldownOnOpenData2: action.json.message,
        persistentAlert: "",
      };

    case "get_drilldown_on_open2_failure":
      return {
        ...state,
        updatingDrillDownData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "clear_drilldown_data_open2": {
      return {
        ...state,
        drilldownOnOpenData2: "",
      };
    }

    case "get_ScoreCard_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
      };

    case "get_ScoreCard_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        ScoreCardFilters: {
          teams: [...action.json.message.filters.map((y) => y.Team)],
        },
        ScorecardTeamFilter: action.json.ScorecardTeamFilter,
      };
    case "current_selected_filters":
      return {
        ...state,
        currentFilters: action.filters,
      };
    case "get_ScoreCard_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_allAEs_data_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        AllAEsData: "",
      };

    case "get_allAEs_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        AllAEsData: action.json.message,
      };

    case "get_allAEs_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    // Getting quota Maintance
    case "get_quota_data_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        QuotaData: "",
      };

    case "get_quota_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        QuotaData: action.json.message,
      };

    case "get_quota_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };
    //

    // Updatinng quota Maintance

    case "update_quota_data_update_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Quota...",
        },
        updatedQuotaRecords: "",
        QuotaData: "",
      };

    case "update_quota_data_update_success":
      return {
        ...state,
        updatedQuotaRecords: action.json.message.updatedQuotaRecords,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Quota updated",
        },
      };

    case "update_quota_data_update_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };
    //

    case "update_table":
      return {
        ...state,
        tableType: action.table,
      };

    case "get_ScoreCard_data_request":
      return {
        ...state,
        updatingNQPData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
        ScoreCardData: "",
        ScoreCardTeam: "",
        AllAEsData: "",
      };

    case "get_ScoreCard_data_success":
      let lastPull1 = action.json.message.settings.filter(
        (m) => m.settings_key === "last_sfdc_pull_datetime"
      )[0].settings_datetime;
      let tz1 = action.json.message.settings.filter(
        (m) => m.settings_key === "timezone"
      )[0].settings_value;
      const lastTimePull2 = new Intl.DateTimeFormat("en", {
        timeZone: "UTC",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }).format(new Date(lastPull1));
      console.log(lastTimePull2);
      let lastPullString1 = `${lastTimePull2} ${tz1}, ${new Intl.DateTimeFormat(
        "en",
        { timeZone: "UTC", weekday: "long" }
      ).format(new Date(lastPull1))}, ${customDateFormatter(lastPull1)}`;
      let nextPull1 = action.json.message.settings.filter(
        (m) => m.settings_key === "next_sfdc_pull_datetime"
      )[0].settings_datetime;
      const nextTimePull2 = new Intl.DateTimeFormat("en", {
        timeZone: "UTC",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }).format(new Date(nextPull1));
      let nextPullString2 = `${nextTimePull2} ${tz1}, ${new Intl.DateTimeFormat(
        "en",
        { timeZone: "UTC", weekday: "long" }
      ).format(new Date(nextPull1))}, ${customDateFormatter(nextPull1)}`;
      return {
        ...state,
        updatingNQPData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        ScoreCardData: action.json.message,
        ScoreCardTeam: action.json.team,
        persistentAlert: "",
        settings: {
          lastPull: lastPullString1,
          nextPull: nextPullString2,
        },
      };

    case "get_ScoreCard_data_failure":
      return {
        ...state,
        updatingNQPData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_allAEs_data_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        AllAEsData: "",
      };

    case "get_allAEs_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        AllAEsData: action.json.message,
      };

    case "get_allAEs_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_quota_attainment_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_quota_attainment_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        quotaAttainmentCompareFilters: action.json.message,
      };
    case "get_quota_attainment_filters_request":
      return {
        ...state,
        persistentAlert: {
          type: "info",
          message: "Updating Filters...",
        },
        quotaAttainmentCompareFilters: "",
      };
    case "get_quota_attainment_filters_failure":
      console.log(action);
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_quota_attainment_data_request":
      return {
        ...state,
        QuotaAttainmentData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_quota_attainment_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        QuotaAttainmentData: action.json.message,
        persistentAlert: "",
      };
    case "get_quota_attainment_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //Quota Attainment V2
    case "get_quota_attainment_data_v2_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_quota_attainment_filters_v2_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        quotaAttainmentCompareFiltersV2: action.json.message,
      };
    case "get_quota_attainment_filters_v2_request":
      return {
        ...state,
        persistentAlert: {
          type: "info",
          message: "Updating Filters...",
        },
        quotaAttainmentCompareFiltersV2: "",
      };
    case "get_quota_attainment_filters_v2_failure":
      console.log(action);
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_quota_attainment_data_v2_request":
      return {
        ...state,
        QuotaAttainmentDataV2: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_quota_attainment_data_v2_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        QuotaAttainmentDataV2: action.json.message,
        persistentAlert: "",
      };
    case "get_quota_attainment_data_v2_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //Competitor Analaysis
    case "get_competitor_analysis_data_request":
      return {
        ...state,
        CompetitorAnalysisData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_competitor_analysis_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        CompetitorAnalysisData: action.json.message.computeResult,
        CompetitorColumns: action.json.message.columns,
        persistentAlert: "",
      };
    case "get_competitor_analysis_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "clear_expected_acv_data":
      return {
        ...state,
        ExpectedAcvData: "",
      };
    case "get_expectedAcv_drilldown_data_request":
      return {
        ...state,
        ExpectedAcvData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_expectedAcv_drilldown_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        ExpectedAcvData: action.json.message,
        persistentAlert: "",
      };
    case "get_expectedAcv_drilldown_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_competitor_analysis_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        competitorAnalysisFilters: action.json.message,
      };
    case "get_competitor_analysis_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    //New Pipeline Coverage
    case "get_pipelineTrends_analysis_data_request":
      return {
        ...state,
        PipelineAnalysisData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_pipelineTrends_analysis_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineAnalysisData: action.json.message,
        persistentAlert: "",
      };
    case "get_pipelineTrends_analysis_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    //New Pipelinetrends Rolling
    case "get_pipelineTrends_Rolling_data_request":
      return {
        ...state,
        PipelineTrendsRollingData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_pipelineTrends_Rolling_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineTrendsRollingData: action.json.message,
        persistentAlert: "",
      };
    case "get_pipelineTrends_Rolling_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_waterfall_min_date_request":
      return {
        ...state,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
      };

    case "get_pipelineTrends_analysis_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        PipelineMinDate: action.json.message.minMax.minDate,
        PipelineMaxDate: action.json.message.minMax.maxDate,
        PipelineFilters: action.json.message.filters,
        PipelineCustomerType: action.json.message.custType,
        persistentAlert: "",
      };

    case "get_pipelineTrends_analysis_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    case "get_nav_items_check_request":
      return {
        ...state,
        // persistentAlert: {
        //   type: 'info',
        //   message: 'Updating Nav Items...'
        // }
      };
    case "get_nav_items_check_success":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.json.message,
          // elements: [],
          // logo: ''
        },
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Nav Items updated",
        },
      };

    case "get_nav_items_check_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };

    case "get_user_data_request":
      return {
        ...state,
        userLoaded: false,
      };

    case "get_user_data_success":
      const userObject = {
        ...state.user,
        ...action.user,
      };
      localStorage.setItem("skygeni-user", JSON.stringify(userObject));
      const version = action.user.serverInfo.version;
      localStorage.setItem("skygeni-viz-version", version);
      // localStorage.setItem(
      //   "skygeni-viz-version",
      //   action.user.serverInfo.version
      // );

      return {
        ...state,
        favouritesPage: "home",
        loggedIn: action.user ? true : false,
        user: {
          ...state.user,
          ...action.user,
        },
        version: action.user.version,
        userLoaded: true,
        chartsLoaded: true,
      };
    case "get_user_data_failure":
      return {
        ...state,
        loggedIn: false,
        user: "",
        userLoaded: true,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };
    //Ramp Analysis
    case "get_ramp_analysis_data_request":
      return {
        ...state,
        RampAnalysisData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_ramp_analysis_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        RampAnalysisData: action.json.message,
        persistentAlert: "",
      };

    case "get_ramp_analysis_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_ramp_analysis_filters_request":
      return {
        ...state,
        RampAnalysisFilters: "",
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
      };

    case "get_ramp_analysis_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        RampAnalysisFilters: action.json.message,
        persistentAlert: "",
      };

    case "get_ramp_analysis_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating filters.",
        },
        persistentAlert: "",
      };

    //Pipeline Created Analysis

    case "get_pipeline_created_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        pipelineCreatedFilters: "",
        lkpFilters: "",
      };

    case "get_pipeline_created_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        pipelineCreatedFilters: action.json.message,
        lkpFilters: action.json.lkpFilters,
      };
    case "get_pipeline_created_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_pipeline_created_analysis_data_request":
      return {
        ...state,
        pipelineCreatedAnalysisData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_pipeline_created_analysis_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        pipelineCreatedAnalysisData: action.json.message,
        persistentAlert: "",
      };

    case "get_pipeline_created_analysis_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //Churn  Analysis

    case "get_churn_analysis_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        churnAnalysisFilters: "",
        lkpFilters: "",
      };

    case "get_churn_analysis_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        churnAnalysisFilters: action.json.message,
      };
    case "get_churn_analysis_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_churn_analysis_data_request":
      return {
        ...state,
        churnAnalysisData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_churn_analysis_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        churnAnalysisData: action.json.message,
        persistentAlert: "",
      };

    case "get_churn_analysis_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_pipeline_created_mix_analysis_data_request":
      return {
        ...state,
        pipelineCreatedMixAnalysisData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_pipeline_created_mix_analysis_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        pipelineCreatedMixAnalysisData: action.json.message,
        persistentAlert: "",
      };

    case "get_pipeline_created_mix_analysis_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_pipeline_created_mix_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        pipelineCreatedMixFilters: "",
        lkpFilters: "",
        pipelineMinMax: "",
        pipelineDateFilters: "",
      };

    case "get_pipeline_created_mix_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        pipelineCreatedMixFilters: action.json.message,
        lkpFilters: action.json.lkpFilters,
        pipelineMinMax: action.json.minMax,
        pipelineDateFilters: action.json.dateFilters,
      };
    case "get_pipeline_created_mix_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    //Building Pipeline
    case "get_building_pipeline_analysis_data_request":
      return {
        ...state,
        buildingPipelineData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_building_pipeline_analysis_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        buildingPipelineData: action.json.message,
        persistentAlert: "",
      };

    case "get_building_pipeline_analysis_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_building_pipeline_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        buildingPipelineFilters: "",
        lkpFilters: "",
      };

    case "get_building_pipeline_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        buildingPipelineFilters: action.json.message,
        lkpFilters: action.json.lkpFilters,
      };
    case "get_building_pipeline_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "clear_count_acv_data":
      return {
        ...state,
        buildingPipelineFilters: "",
      };

    case "delete_favourite_request":
      return {
        ...state,
        isDeleting: true,
        alert: {
          id: state.alert.id + 1,
          type: "warning",
          message: "Removing Favorite...",
        },
      };
    case "delete_favourite_failure":
      return {
        ...state,
        isDeleting: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Something went wrong",
        },
      };
    case "delete_favourite_success":
      const favorites = state.user.elements.filter(
        (it) => it.navItem === "Favorites"
      )[0].charts;
      const navItem = state.user.elements.filter(
        (it) => it.navItem === action.payload.navItem
      )[0].charts;
      const navItemIndex = state.user.elements.findIndex(
        (it) => it.navItem === action.payload.navItem
      );
      const updatedFavorites = favorites.filter(
        (it) => it.dashboard_id !== action.payload.dashboard_id
      );

      const updatedNavItem = navItem.map((it) => {
        if (it.dashboard_id === action.payload.dashboard_id) {
          return {
            ...it,
            Position: null,
          };
        }
        return {
          ...it,
        };
      });
      const updatedElements = state.user.elements.filter(
        (it) =>
          it.navItem !== "Favorites" && it.navItem !== action.payload.navItem
      );
      updatedElements.splice(1, 0, {
        navItem: "Favorites",
        charts: updatedFavorites,
      });
      updatedElements.splice(navItemIndex, 0, {
        navItem: action.payload.navItem,
        charts: updatedNavItem,
      });
      const updatedUser = { ...state.user, elements: updatedElements };

      console.log(
        "Performing update",
        updatedElements,
        updatedUser,
        action.payload
      );
      return {
        ...state,
        isDeleting: false,
        user: updatedUser,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Removed Favorite...",
        },
      };
    case "add_favourite_failure":
      return {
        ...state,
        isAdding: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };
    case "add_favourite_success":
      return {
        ...state,
        isAdding: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Favorite Added",
        },
      };
    case "add_favourite_request":
      return {
        ...state,
        isAdding: true,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Adding Favorite...",
        },
      };

    case "get_execution_guidence_data_request":
      return {
        ...state,
        executionGuidenceData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_execution_guidence_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        executionGuidenceData: action.json.message,
        persistentAlert: "",
      };

    case "get_execution_guidence_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_execution_guidence_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        executionGuidenceFilters: "",
      };

    case "get_execution_guidence_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        executionGuidenceFilters: action.json.message,
      };
    case "get_execution_guidence_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_rep_performance_analysis_filters_request":
      return {
        ...state,
        updatingFunnelCompareData: true,
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
        repPerformanceFilters: "",
      };

    case "get_rep_performance_analysis_filters_success":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        repPerformanceFilters: action.json.message,
        persistentAlert: "",
      };

    case "get_rep_performance_analysis_filters_failure":
      return {
        ...state,
        updatingFunnelCompareData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
        persistentAlert: "",
      };

    case "get_lkp_stages_request":
      return {
        ...state,
        lkpStages: "",
      };
    case "get_lkp_stages_success":
      const lkpStages = action.json.message;
      return {
        ...state,
        lkpStages,
      };
    case "get_lkp_stages_failure":
      return {
        ...state,
      };
    case "get_dashboard_keywords_request":
      return {
        ...state,
        lkpStages: "",
      };
    case "get_dashboard_keywords_success":
      const {
        dashboardKeywords,
        chartsKeywordsMap,
        dashboardKeywordsMap,
        dashboardChartMapping,
        dashbaordIdNavItemMap,
        metrics,
        config,
      } = action.json.message;

      return {
        ...state,
        dashboardChartMapping,
        CONV_AI_CONSTANTS: config.CONV_AI_CONSTANTS,
        dashboardKeywords,
        dashbaordIdNavItemMap,
        chartsKeywordsMap,
        dashboardKeywordsMap,
        customerMetrics: metrics,
      };
    case "get_dashboard_keywords_failure":
      return {
        ...state,
      };
    case "clear_conv_ai_data":
      return {
        ...state,
        geniBotFilters: "",
        geniBotData: "",
      };

    //Pipeline Coverage EG Page
    case "get_generic_pipeline_coverage_data_CFQ_EG_request":
      return {
        ...state,
        PipelineCoverageEGCFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_data_CFQ_EG_success":
      // console.log(action.json.message);
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageEGCFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_data_CFQ_EG_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_data_NFQ_EG_request":
      return {
        ...state,
        PipelineCoverageEGNFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_data_NFQ_EG_success":
      // console.log(action.json.message);
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageEGNFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_data_NFQ_EG_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_data_FY_EG_request":
      return {
        ...state,
        PipelineCoverageEGFY: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_data_FY_EG_success":
      // console.log(action.json.message);
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageEGFY: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_data_FY_EG_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_data_CFQ_rep_request":
      return {
        ...state,
        PipelineCoverageRepCFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_data_CFQ_rep_success":
      // console.log(action.json.message);
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageRepCFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_data_CFQ_rep_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_data_NFQ_rep_request":
      return {
        ...state,
        PipelineCoverageRepNFQ: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_data_NFQ_rep_success":
      // console.log(action.json.message);
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageRepNFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_data_NFQ_rep_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_custType_data_CFQ_rep_request":
      return {
        ...state,
        pipelineCoverageCustTypeCFQRepData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_custType_data_CFQ_rep_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        pipelineCoverageCustTypeCFQRepData: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_custType_data_CFQ_rep_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_custType_data_NFQ_rep_request":
      return {
        ...state,
        pipelineCoverageCustTypeNFQRepData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_custType_data_NFQ_rep_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        pipelineCoverageCustTypeNFQRepData: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_custType_data_NFQ_rep_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_callsCompleted_data_request":
      return {
        ...state,
        CallsCompletedData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_callsCompleted_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        CallsCompletedData: action.json.message,
        persistentAlert: "",
      };
    case "get_callsCompleted_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_callsCompleted_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        CallsCompletedFilters: "",
      };

    case "get_callsCompleted_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        CallsCompletedFilters: action.json.message,
      };
    case "get_callsCompleted_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_CustomerInteraction_data_request":
      return {
        ...state,
        CustomerInteractionData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_CustomerInteraction_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        CustomerInteractionData: action.json.message,
        persistentAlert: "",
      };
    case "get_CustomerInteraction_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_nqp_nop_data_request":
      return {
        ...state,
        updatingNQPData: true,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
        NewQualifiedPipelineDatav2: "",
      };

    case "get_nqp_nop_data_success":
      return {
        ...state,
        updatingNQPData: false,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NewQualifiedPipelineDatav2: action.json.message,
        persistentAlert: "",
      };

    case "get_nqp_nop_data_failure":
      return {
        ...state,
        updatingNQPData: false,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_nqp_nop_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        NewQualifiedPipelineFiltersv2: "",
      };

    case "get_nqp_nop_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        NewQualifiedPipelineFiltersv2: action.json.message,
      };
    case "get_nqp_nop_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_msnqp_data_SQLOpen_request":
      return {
        ...state,
        NQPDataSQLOpen: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_SQLOpen_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataSQLOpen: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_SQLOpen_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_NonOpen_request":
      return {
        ...state,
        NQPDataNonOpen: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_NonOpen_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataNonOpen: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_NonOpen_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_msnqp_data_RenewalsOpen_request":
      return {
        ...state,
        NQPDataRenewalsOpen: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_RenewalsOpen_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataRenewalsOpen: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_RenewalsOpen_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_SQLCreated_request":
      return {
        ...state,
        NQPDataSQLCreated: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_SQLCreated_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataSQLCreated: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_SQLCreated_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_NonCreated_request":
      return {
        ...state,
        NQPDataNonCreated: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_NonCreated_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataNonCreated: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_NonCreated_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_msnqp_data_RenewalsCreated_request":
      return {
        ...state,
        NQPDataRenewalsCreated: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_RenewalsCreated_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataRenewalsCreated: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_RenewalsCreated_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "quota_letter_failure":
      console.log(action);
      return {
        ...state,
        QuotaDatapdf: "",
        quotaLetterErrorMessage: action.payload.message,
      };

    case "generate_quota_letters":
      return {
        ...state,
        quotaLetterErrorMessage: "",
        QuotaDatapdf: action.json,
      };

    case "get_URLS":
      return {
        ...state,
        quotaLetterErrorMessage: "",
        QuotaDatapdf: "",
        QuotaUrls: action.json.message,
      };

    case "delete_docs":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Docs Deletes",
        },
      };

    case "get_enablement_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        EnablementEffectivenessFilters: "",
      };

    case "get_enablement_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        EnablementEffectivenessFilters: action.json.message,
      };
    case "get_enablement_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_enablement_data_request":
      return {
        ...state,
        EnablementEffictivenessData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_enablement_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        EnablementEffictivenessData: action.json.message,
        persistentAlert: "",
      };
    case "get_enablement_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //attainment section
    case "get_attainment_data_acv_range_YTD_request":
      return {
        ...state,
        AttainmentDataYTDACVRange: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_acv_range_YTD_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentDataYTDACVRange: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_acv_range_YTD_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_attainment_data_acv_range_CFQ_request":
      return {
        ...state,
        AttainmentDataCFQACVRange: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_acv_range_CFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentDataCFQACVRange: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_acv_range_CFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_PSCA_data_request":
      return {
        ...state,
        PipelineCoverageShapeData: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_PSCA_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageShapeData: action.json.message,
        persistentAlert: "",
      };
    case "get_PSCA_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_attainment_data_wrcr_YTD_request":
      return {
        ...state,
        AttainmentDataYTDWRCR: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_wrcr_YTD_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentDataYTDWRCR: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_wrcr_YTD_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_attainment_data_wrcr_CFQ_request":
      return {
        ...state,
        AttainmentDataCFQWRCR: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_wrcr_CFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentDataCFQWRCR: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_wrcr_CFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_Upsell_request":
      return {
        ...state,
        NQPDataUpsell: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_Upsell_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataUpsell: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_Upsell_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_Cross-sell_request":
      return {
        ...state,
        NQPDataCrossSell: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_Cross-sell_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataCrossSell: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_Cross-sell_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_generic_pipeline_coverage_deal_data_CFQ_request":
      return {
        ...state,
        pipelineCoverageDealCategoryCFQData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_generic_pipeline_coverage_deal_data_CFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        pipelineCoverageDealCategoryCFQData: action.json.message,
        persistentAlert: "",
      };
    case "get_generic_pipeline_coverage_deal_data_CFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_account_expansion_filters_request":
      return {
        ...state,
        accountExpansionFilters: "",
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
      };
    case "get_account_expansion_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        accountExpansionFilters: action.json,
        persistentAlert: "",
      };
    case "get_account_expansion_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_account_expansion_data_request":
      return {
        ...state,
        accountExpansionData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_account_expansion_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        accountExpansionData: action.json.message,
        persistentAlert: "",
      };
    case "get_account_expansion_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_account_expansion_filters_request":
      return {
        ...state,
        accountExpansionFilters: "",
        persistentAlert: {
          type: "info",
          message: "Updating filters...",
        },
      };
    case "get_account_expansion_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        accountExpansionFilters: action.json,
        persistentAlert: "",
      };
    case "get_account_expansion_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_pipeline_progression_data_request":
      return {
        ...state,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
        PipelineProgression: "",
      };
    case "get_pipeline_progression_data_success":
      if (!action.json.success) {
        alert = {
          id: state.alert.id + 1,
          type: "error",
          message: "Data not available for selected filters",
        };
        data = "";
      } else {
        alert = {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        };
        data = action.json.message;
      }
      return {
        ...state,
        alert: alert,
        PipelineProgression: data,
        persistentAlert: "",
      };
    case "get_pipeline_progression_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_pipeline_progression_drilldown_request":
      return {
        ...state,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
        PipelineProgressionDrilldown: "",
      };
    case "get_pipeline_progression_drilldown_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineProgressionDrilldown: action.json.message,
        persistentAlert: "",
      };
    case "get_pipeline_progression_drilldown_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_pipeline_progression_filters_request":
      return {
        ...state,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
        PipelineProgressionFilters: "",
        milestoneInfo: "",
      };
    case "get_pipeline_progression_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineProgressionFilters: action.json.message,
        persistentAlert: "",
        lkpFilters: action.json.lkpFilters,
        milestoneInfo: action.json.milestoneInfo,
      };
    case "get_pipeline_progression_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
        PipelineProgressionFilters: "",
      };
    case "get_current_state_of_qualified_pipeline_data_request":
      return {
        ...state,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
        currentStateOfQualifiedPipelineData: "",
      };
    case "get_current_state_of_qualified_pipeline_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        persistentAlert: "",
        currentStateOfQualifiedPipelineData: action.json.message,
      };
    case "get_current_state_of_qualified_pipeline_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };
    case "get_current_state_of_qualified_pipeline_drilldown_request":
      return {
        ...state,
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
        currentStateOfQualifiedPipelineDrilldown: "",
      };
    case "get_current_state_of_qualified_pipeline_drilldown_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        persistentAlert: "",
        currentStateOfQualifiedPipelineDrilldown: action.json.message,
      };
    case "get_current_state_of_qualified_pipeline_drilldown_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_scorecard_BS_data_request":
      return {
        ...state,
        BSData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_scorecard_BS_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        BSData: action.json.message,
        persistentAlert: "",
      };
    case "get_scorecard_BS_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_PSCA_data_v2_request":
      return {
        ...state,
        PipelineCoverageShapeDatav2: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_PSCA_data_v2_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        PipelineCoverageShapeDatav2: action.json.message,
        persistentAlert: "",
      };
    case "get_PSCA_data_v2_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_POCSSummary_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        POCSSummaryFilters: "",
      };

    case "get_POCSSummary_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        POCSSummaryFilters: action.json.message,
      };
    case "get_POCSSummary_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_POCSSummary_data_request":
      return {
        ...state,
        POCSSummaryData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_POCSSummary_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        POCSSummaryData: action.json.message,
        persistentAlert: "",
      };
    case "get_POCSSummary_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    //attainment section
    case "get_attainment_data_NRR_YTD_request":
      return {
        ...state,
        AttainmentDataNRRYTD: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_NRR_YTD_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentDataNRRYTD: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_NRR_YTD_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_attainment_data_NRR_CFQ_request":
      return {
        ...state,
        AttainmentDataNRRCFQ: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_NRR_CFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentDataNRRCFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_NRR_CFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_attainment_data_NRR_completedFQ_request":
      return {
        ...state,
        AttainmentDataNRRcompletedFQ: "",
        AllAEsData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_attainment_data_NRR_completedFQ_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        AttainmentDataNRRcompletedFQ: action.json.message,
        persistentAlert: "",
      };
    case "get_attainment_data_NRR_completedFQ_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "udpating_app":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Loading updated app",
        },
      };

    case "get_ProductsByAccount_filters_request":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "info",
          message: "Updating Filters...",
        },
        ProductsByAccountFilters: "",
      };

    case "get_ProductsByAccount_filters_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Filters updated",
        },
        ProductsByAccountFilters: action.json.message,
      };
    case "get_ProductsByAccount_filters_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: action.error,
        },
      };

    case "get_ProductsByAccount_data_request":
      return {
        ...state,
        ProductsByAccountData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_ProductsByAccount_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        ProductsByAccountData: action.json.message,
        persistentAlert: "",
      };
    case "get_ProductsByAccount_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_ProductsByAccount_update_data_request":
      return {
        ...state,
        ProductsByAccountData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_ProductsByAccount_update_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        ProductsByAccountData: action.json.message,
        persistentAlert: "",
      };
    case "get_ProductsByAccount_update_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_ProductsByAccountByRegion_data_request":
      return {
        ...state,
        ProductsByAccountDataByRegion: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_ProductsByAccountByRegion_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        ProductsByAccountDataByRegion: action.json.message,
        persistentAlert: "",
      };
    case "get_ProductsByAccountByRegion_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_1st_rmContract_request":
      return {
        ...state,
        NQPData1st_rmContract: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_1st_rmContract_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPData1st_rmContract: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_1st_rmContract_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_1st_rmYear_rmA_slash_B_request":
      return {
        ...state,
        NQPData1st_rmYear_rmA_slash_B: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_1st_rmYear_rmA_slash_B_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPData1st_rmYear_rmA_slash_B: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_1st_rmYear_rmA_slash_B_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_Additional_rmBusiness_request":
      return {
        ...state,
        NQPDataAdditional_rmBusiness: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_Additional_rmBusiness_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataAdditional_rmBusiness: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_Additional_rmBusiness_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_PoweredBy_request":
      return {
        ...state,
        NQPDataPoweredBy: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_PoweredBy_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataPoweredBy: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_PoweredBy_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    case "get_msnqp_data_New_rmProspect_request":
      return {
        ...state,
        NQPDataNew_rmProspect: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_New_rmProspect_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataNew_rmProspect: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_New_rmProspect_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

      case "get_msnqp_data_Renewal_request":
        return {
          ...state,
          NQPDataRenewal: "",
          persistentAlert: {
            type: "info",
            message: "Updating data...",
          },
        };
      case "get_msnqp_data_Renewal_success":
        return {
          ...state,
          alert: {
            id: state.alert.id + 1,
            type: "success",
            message: "Data updated",
          },
          NQPDataRenewal: action.json.message,
          persistentAlert: "",
        };
      case "get_msnqp_data_Renewal_failure":
        return {
          ...state,
          alert: {
            id: state.alert.id + 1,
            type: "error",
            message: "Error updating data",
          },
          persistentAlert: "",
        };

        case "get_msnqp_data_New_rmBusiness_request":
          return {
            ...state,
            NQPDataNew_rmBusiness: "",
            persistentAlert: {
              type: "info",
              message: "Updating data...",
            },
          };
        case "get_msnqp_data_New_rmBusiness_success":
          return {
            ...state,
            alert: {
              id: state.alert.id + 1,
              type: "success",
              message: "Data updated",
            },
            NQPDataNew_rmBusiness: action.json.message,
            persistentAlert: "",
          };
        case "get_msnqp_data_New_rmBusiness_failure":
          return {
            ...state,
            alert: {
              id: state.alert.id + 1,
              type: "error",
              message: "Error updating data",
            },
            persistentAlert: "",
          };


        case "get_msnqp_data_New_rmBusiness_request":
      return {
        ...state,
        NQPDataNew_rmBusiness: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_msnqp_data_New_rmBusiness_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        NQPDataNew_rmBusiness: action.json.message,
        persistentAlert: "",
      };
    case "get_msnqp_data_New_rmBusiness_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };


        case "get_building_pipeline_analysis_data_v2_request":
      return {
        ...state,
        buildingPipelineDatav2: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };

    case "get_building_pipeline_analysis_data_v2_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        buildingPipelineDatav2: action.json.message,
        persistentAlert: "",
      };

    case "get_building_pipeline_analysis_data_v2_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };


      case "get_ensemble_home_request":
        return {
          ...state,
          EnsembleData: "",
          AllAEsData: "",
          persistentAlert: {
            type: "info",
            message: "Updating data...",
          },
        };
      case "get_ensemble_home_success":
        return {
          ...state,
          alert: {
            id: state.alert.id + 1,
            type: "success",
            message: "Data updated",
          },
          EnsembleData: action.json.message,
          persistentAlert: "",
        };
      case "get_ensemble_home_failure":
        return {
          ...state,
          alert: {
            id: state.alert.id + 1,
            type: "error",
            message: "Error updating data",
          },
          persistentAlert: "",
        };

        case "get_scorecard_YTDMonthly_data_request":
      return {
        ...state,
        YTDMonthlyData: "",
        persistentAlert: {
          type: "info",
          message: "Updating data...",
        },
      };
    case "get_scorecard_YTDMonthly_data_success":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "success",
          message: "Data updated",
        },
        YTDMonthlyData: action.json.message,
        persistentAlert: "",
      };
    case "get_scorecard_YTDMonthly_data_failure":
      return {
        ...state,
        alert: {
          id: state.alert.id + 1,
          type: "error",
          message: "Error updating data",
        },
        persistentAlert: "",
      };

    default:
      return state;
  }
}
