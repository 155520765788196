import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Typography,
    TableHead,
    TableRow,
    withStyles,
    Grid,
  } from "@material-ui/core";
  import React, { useEffect, useRef, useState } from "react";
  import Tooltip from "@material-ui/core/Tooltip";
  import {
    countOrACVFormatter,
    StyledTableRow,
    _isNaN,
    getUniqueValues,
  } from "../../util/customFunctions";
  import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
  
  const styles = makeStyles({
    tableHeader: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "5px 5px",
      color: "inherit",
    },
    stickyHeader: {
      position: "sticky",
      // top: -1,
      background: "white",
      outline: "0.1px solid lightgrey",
      outlineOffset: "-1.5px",
      // zIndex: 5,
      // Create a pseudo-element for the fixed right border
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "0.1px", // Adjust the thickness as needed
        // background: "lightgrey", // Adjust the color as needed
      },
    },
    nonScoreCell: {
      fontWeight: 400,
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
    },
    repHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    repHeaderDrilldown: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      cursor: "pointer",
    },
    repNameMargin: {
      paddingLeft: "1.75rem",
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
    },
    mainTable: {
      width: "100%",
      maxWidth: "100%",
      padding: "0.25rem",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 24px 6px 10px;",
    },
  });
  
  const ast = (x, y) => {
    return x === y ? (
      <span>${countOrACVFormatter(x)}</span>
    ) : (
      <>
        <span>${countOrACVFormatter(x)}</span>{" "}
        <span style={{ color: "#FF0000" }}>*</span>
      </>
    );
  };
  
  export default function PipelineCoverageSectionDealTable(props) {
    console.log(props);
    const classes = styles();
    const tableRef = React.createRef();
    const tableContainerRef = useRef();
  
    const [rowPin, setrowPin] = useState(false);
    const [parentWidth, setparentWidth] = useState();
  
    useEffect(() => {
      const tableContainer = tableContainerRef.current;
  
      const handleScroll = () => {
        // if (tableContainer.scrollLeft > 0) {
        //   setIsSticky(true);
        // } else {
        //   setIsSticky(false);
        // }
  
        if (tableContainer.scrollTop > 0) {
          setrowPin(true);
        } else {
          setrowPin(false);
        }
      };
  
      // Attach a scroll event listener to the TableContainer
      tableContainer.addEventListener("scroll", handleScroll);
  
      return () => {
        // Clean up the event listener when the component unmounts
        tableContainer.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    useEffect(() => {
      // Get the width of the parent TableContainer
      const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
      setparentWidth(parentWidth);
    }, [parentWidth]);
  
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "rgb(255,255,255,0.8)",
        color: "black",
        fontFamily: "roboto",
        fontSize: theme.typography.pxToRem(14),
        borderRadius: "1rem",
        padding: "0px",
        boxShadow: "0 0.1rem 0.1rem 0",
        marginTop: "0.4rem",
      },teams
    }))(Tooltip);
  
    const teams = props.data
      .filter((s) => !["Total"].includes(s.name))
      .map((s) => s.name)
      .filter(getUniqueValues)
      .sort();
    // const bookingTypes=props.AttainmentTable.map(s=>s.type).filter(getUniqueValues).filter(a=>a !== 'total')
    // const splitup = props.db === 'C0003' ? ["New Logo", "Upsell", "Price Increase",	"Cross Sell"] : ['New Customer', 'Renewal', 'Existing Customer']
    const splitup = props.splitup;
    //qualifiedTooltip
    const qualifiedTooltip = (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <div
                style={{
                  borderTop: "solid #4471C4",
                  borderRadius: "0.3rem",
                  padding: "0px",
                  margin: 0,
                  borderWidth: "0.3rem",
                }}
              >
                <div className="container">
                  <Typography
                    style={{ padding: "0.1rem" }}
                    className="heading-style"
                  >
                    `Qualified Pipeline $
                    {props.type === "CFQ" ? "current" : "next"} quarter``
                  </Typography>
                </div>
              </div>
            </>
          ))}
      </>
    );
    //forecast tooltip
    const forecastTooltip = (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <div
                style={{
                  borderTop: "solid #4471C4",
                  borderRadius: "0.3rem",
                  padding: "0px",
                  margin: 0,
                  borderWidth: "0.3rem",
                }}
              >
                <div className="container">
                  <Typography
                    style={{ padding: "0.1rem" }}
                    className="heading-style"
                  >
                    Opportunities with Sales Executive Deal Forecast = Forecast to
                    Go for current qtr.
                  </Typography>
                </div>
              </div>
            </>
          ))}
      </>
    );
    //projected yield toottip
    const projectedYieldTooltip = (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <div
                style={{
                  borderTop: "solid #4471C4",
                  borderRadius: "0.3rem",
                  padding: "0px",
                  margin: 0,
                  borderWidth: "0.3rem",
                }}
              >
                <div className="container">
                  <Typography
                    style={{ padding: "0.1rem" }}
                    className="heading-style"
                  >{`SkyGeni Projected Yield from opps. in ${props.qualifiedHeader}`}</Typography>
                </div>
              </div>
            </>
          ))}
      </>
    );
  
    //projected attainment tooltip
    const projectedAttainmentTooltip = (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <div
                style={{
                  borderTop: "solid #4471C4",
                  borderRadius: "0.3rem",
                  padding: "0px",
                  margin: 0,
                  borderWidth: "0.3rem",
                }}
              >
                <div className="container">
                  <Typography
                    style={{ padding: "0.1rem" }}
                    className="heading-style"
                  >{`Won + SkyGeni Projected Yield from opps. in ${props.qualifiedHeader}`}</Typography>
                </div>
              </div>
            </>
          ))}
      </>
    );
  
    const countAndOpps = (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <TableCell
                align="center"
                className={classes.repHeader}
                // className={
                //   rowPin
                //     ? `${classes.stickyHeader}${classes.repHeader}`
                //     : `${classes.repHeader}`
                // }
                colSpan={1}
                style={{
                  background: "#4471C4",
                  color: "white",
                  border: "0.5px solid lightgrey",
                  minWidth: 50,
                  maxWidth: 50,
                  outline: "0.4px solid lightgrey",
                  outlineOffset: "-1.5px",
                }}
              >
                # of Opps
              </TableCell>
  
              <TableCell
                align="center"
                className={classes.repHeader}
                // className={
                //   rowPin
                //     ? `${classes.stickyHeader}${classes.repHeader}`
                //     : `${classes.repHeader}`
                // }
                colSpan={1}
                style={{
                  background: "#4471C4",
                  color: "white",
                  border: "0.5px solid lightgrey",
                  minWidth: 50,
                  maxWidth: 50,
                  outline: "0.4px solid lightgrey",
                  outlineOffset: "-1.5px",
                }}
              >
                {props.Value_Label}
              </TableCell>
            </>
          ))}
      </>
    );
  
    const countAndOppsCustom = (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <HtmlTooltip title={qualifiedTooltip}>
                <TableCell
                  align="center"
                  className={classes.repHeader}
                  colSpan={1}
                  style={{
                    background: "#4471C4",
                    color: "white",
                    border: "0.5px solid lightgrey",
                    minWidth: 50,
                    maxWidth: 50,
                  }}
                >
                  # of Opps
                </TableCell>
              </HtmlTooltip>
              <HtmlTooltip title={qualifiedTooltip}>
                <TableCell
                  align="center"
                  className={classes.repHeader}
                  colSpan={1}
                  style={{
                    background: "#4471C4",
                    color: "white",
                    border: "0.5px solid lightgrey",
                    minWidth: 50,
                    maxWidth: 50,
                  }}
                >
                  ${props.Value_Label}
                </TableCell>
              </HtmlTooltip>
            </>
          ))}
      </>
    );
  
    let cellNumber = props.type === "CFQ" ? 2 : 1;
    cellNumber = props.ShowLMH === false ? 0 : cellNumber;
  
    const countAndOppsAndPercentage = (
      <>
        {Array(cellNumber)
          .fill()
          .map((item, i) => (
            <>
              <HtmlTooltip
                title={
                  i === 0 ? projectedYieldTooltip : projectedAttainmentTooltip
                }
              >
                <TableCell
                  align="center"
                  className={classes.repHeader}
                  colSpan={1}
                  style={{
                    background: "#4471C4",
                    color: "white",
                    border: "0.5px solid lightgrey",
                    minWidth: 70,
                    maxWidth: 70,
                    // outline: "0.1px solid lightgrey",
                    // outlineOffset: "-1.5px",
                    outline: "0.4px solid lightgrey",
                    outlineOffset: "-1.5px",
                  }}
                >
                  Low
                </TableCell>
              </HtmlTooltip>
              <HtmlTooltip
                title={
                  i === 0 ? projectedYieldTooltip : projectedAttainmentTooltip
                }
              >
                <TableCell
                  align="center"
                  className={classes.repHeader}
                  colSpan={1}
                  style={{
                    background: "#4471C4",
                    color: "white",
                    border: "0.5px solid lightgrey",
                    minWidth: 70,
                    maxWidth: 70,
  
                    outline: "0.4px solid lightgrey",
                    outlineOffset: "-1.5px",
                  }}
                >
                  Mid
                </TableCell>
              </HtmlTooltip>
              <HtmlTooltip
                title={
                  i === 0 ? projectedYieldTooltip : projectedAttainmentTooltip
                }
              >
                <TableCell
                  align="center"
                  className={classes.repHeader}
                  colSpan={1}
                  style={{
                    background: "#4471C4",
                    color: "white",
                    border: "0.5px solid lightgrey",
                    minWidth: 70,
                    maxWidth: 70,
                    outline: "0.4px solid lightgrey",
                    outlineOffset: "-1.5px",
                  }}
                >
                  High
                </TableCell>
              </HtmlTooltip>
            </>
          ))}
      </>
    );
    return (
      <>
        {parentWidth !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
              width: `${parentWidth}px`,
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        {props.AttainmentTable !== "" && (
          <>
            <TableContainer
              style={{
                // padding: "1rem",
  
                width: '75%',
                borderTop: rowPin ? "0.5px solid lightgrey" : "",
                maxHeight: "85vh",
              }}
              align="center"
              ref={tableContainerRef}
            >
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} /> <p>ThisTable</p>
              </div> */}
              <Table
                ref={tableRef}
                size="small"
                aria-label="html table"
                style={{ tableLayout: "auto" }}
                data-test={
                  props.isrepScoreCard
                    ? `rep-${props.tableName}`
                    : props.tableName
                }
              >
                <TableHead style={{ position: "sticky", top: -1, zIndex: 5 }}>
                  <StyledTableRow>
                    <TableCell
                      align="center"
                      rowSpan={2}
                      className={classes.tableHeader}
                      colSpan={1}
                      style={{
                        background: "#4471C4",
                        height: 70,
                        minWidth: 160,
                        maxWidth: 160,
                        border: "0.5px solid lightgrey",
                        color: "white",
                      }}
                    >
                      {'Deal Category'}
                    </TableCell>
                   
                    {props.type === "CFQ" && (
                      <>
                        <TableCell
                          align="center"
                          rowSpan={2}
                          className={classes.tableHeader}
                          colSpan={1}
                          style={{
                            background: "#70AD47",
                            color: "white",
                            border: "0.5px solid lightgrey",
                            minWidth: 60,
                            maxWidth: 60,
                          }}
                        >
                          {"Won"}
                        </TableCell>
                      </>
                    )}
                     
                      <TableCell
                        align="center"
                        colSpan={2}
                        className={classes.tableHeader}
                        style={{
                          background: "#4471C4",
                          color: "white",
                          border: "0.5px solid lightgrey",
                          minWidth: 100,
                          maxWidth: 100,
                          outline: "0.4px solid lightgrey",
                          outlineOffset: "-1.5px",
                        }}
                      >
                        {props.qualifiedHeader}
                      </TableCell>
                    
  
                    {props.type === "CFQ" && (
                      <>
                        {/* <TableCell align='center' rowSpan={2}  className={classes.tableHeader} colSpan={1} style={{ background: '#4471C4', color: 'white',border: '0.5px solid lightgrey',minWidth:60}}>{"Expected Won + Forecast"}</TableCell>
                          <TableCell align='center' rowSpan={2} className={classes.tableHeader} colSpan={1} style={{ background: '#BF8F00', color: 'white',border: '0.5px solid lightgrey',minWidth:60}}>{"Expected Attainment %"}</TableCell> */}
                      </>
                    )}
                    {props.ShowLMH !== false && (
                      <>
                        <HtmlTooltip title={projectedYieldTooltip}>
                          <TableCell
                            align="center"
                            className={classes.tableHeader}
                            colSpan={3}
                            style={{
                              background: "#4471C4",
                              color: "white",
                              border: "0.5px solid lightgrey",
                              minWidth: 230,
                              maxWidth: 230,
                              outline: "0.4px solid lightgrey",
                              outlineOffset: "-1.5px",
                            }}
                          >
                            SkyGeni Projected Yield{" "}
                            <span
                              style={{
                                background: "#fee599ff",
                                padding: "0 0.4rem",
                                paddingLeft: "0.7rem",
                                margin: "0 0.5rem",
                                border: "1px solid lightgrey",
                                color: "black",
                              }}
                            >
                              Beta
                            </span>
                          </TableCell>
                        </HtmlTooltip>
                        {props.type === "CFQ" && (
                          <HtmlTooltip title={projectedAttainmentTooltip}>
                            <TableCell
                              align="center"
                              className={classes.tableHeader}
                              colSpan={3}
                              style={{
                                background: "#4471C4",
                                color: "white",
                                border: "0.5px solid lightgrey",
                                minWidth: 230,
                                maxWidth: 230,
                                outline: "0.4px solid lightgrey",
                                outlineOffset: "-1.5px",
                              }}
                            >
                              SkyGeni Projected Attainment{" "}
                              <span
                                style={{
                                  background: "#fee599ff",
                                  padding: "0 0.4rem",
                                  paddingLeft: "0.7rem",
                                  margin: "0 0.5rem",
                                  border: "1px solid lightgrey",
                                  color: "black",
                                  outline: "0.5px solid lightgrey",
                                  outlineOffset: "-1.5px",
                                }}
                              >
                                Beta
                              </span>
                            </TableCell>
                          </HtmlTooltip>
                        )}
                      </>
                    )}
                  </StyledTableRow>
                  <TableRow>
                    {props.db === "C0003" ? countAndOppsCustom : countAndOpps}
                    {countAndOppsAndPercentage}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!props.isrepScoreCard && (
                    <>
                      <StyledTableRow>
                        <TableCell
                          align="left"
                          className={classes.repHeader}
                          style={{
                            border: "0.5px solid lightgrey",
                            fontWeight: 600,
                          }}
                          colSpan={1}
                        >
                          {"Total"}
                        </TableCell>
                       
                        
                        {props.type === "CFQ" && (
                          <>
                            <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocationWon(
                                  "drilldownOnGraph",
                                  teams,
                                  splitup
                                )
                              }
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                cursor: "pointer",
                                paddingRight: "0.5rem",
                              }}
                              colSpan={1}
                            >
                              $
                              {countOrACVFormatter(
                                props.data.filter((i) =>
                                  ["Total"].includes(i.name)
                                )[0].won
                              )}
                            </TableCell>
                           
                          </>
                        )}
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              teams,
                              "open",
                              props.type,
                              true
                            )
                          }
                          className={classes.repHeader}
                          style={{
                            border: "0.5px solid lightgrey",
                            cursor: "pointer",
                            paddingRight: "0.5rem",
                          }}
                          colSpan={1}
                        >
                          {countOrACVFormatter(
                            props.data.filter((i) =>
                              ["Total"].includes(i.name)
                            )[0].count
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              teams,
                              "open",
                              props.type,
                              true
                            )
                          }
                          className={classes.repHeader}
                          style={{
                            border: "0.5px solid lightgrey",
                            cursor: "pointer",
                            paddingRight: "0.5rem",
                          }}
                          colSpan={1}
                        >
                          $
                          {countOrACVFormatter(
                            props.data.filter((i) =>
                              ["Total"].includes(i.name)
                            )[0].acv
                          )}
                        </TableCell>
                        {props.type === "CFQ" && (
                          <>
                            {/* <TableCell align='right'  className={classes.repHeader} style={{border: '0.5px solid lightgrey',paddingRight:'0.5rem' }} colSpan={1}>${countOrACVFormatter(props.data.filter(i=>['company','team'].includes(i.name))[0].expectedWonAcv)}</TableCell>
                          <TableCell align='right' className={classes.repHeader} style={{border: '0.5px solid lightgrey',paddingRight:'0.5rem' }} colSpan={1}>{Math.round(props.data.filter(i=>['company','team'].includes(i.name))[0].attainmentPerc)}%</TableCell> */}
                          </>
                        )}
                        {/* Skygeni Projected yield low-mid-high */}
  
                        <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                teams,
                                "open",
                                props.type,
                                true
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              cursor: "pointer",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {ast(
                              props.data.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].low,
                              props.datav2.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].low
                            )}
                          </TableCell>
  
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                teams,
                                "open",
                                props.type,
                                true
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              cursor: "pointer",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {ast(
                              props.data.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].mid,
                              props.datav2.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].mid
                            )}
                          </TableCell>
  
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                teams,
                                "open",
                                props.type,
                                true
                              )
                            }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                              cursor: "pointer",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {ast(
                              props.data.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].high,
                              props.datav2.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].high
                            )}
                          </TableCell>
                        {/* Skygeni Projected attainment low-mid-high */}
                        {props.type === "CFQ" && (
                          <>
                            <TableCell
                            align="right"
                            
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                            //   cursor: "pointer",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {ast(
                              props.data.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].projectedAttainmentLow,
                              props.datav2.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].projectedAttainmentLow
                            )}
                          </TableCell>
  
                          <TableCell
                            align="right"
                            // onClick={() =>
                            //   props.handleChangeLocation(
                            //     "drilldownOnGraph",
                            //     teams,
                            //     "open",
                            //     props.type,
                            //     true
                            //   )
                            // }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                            //   cursor: "pointer",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {ast(
                              props.data.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].projectedAttainmentMid,
                              props.datav2.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].projectedAttainmentMid
                            )}
                          </TableCell>
  
                          <TableCell
                            align="right"
                            // onClick={() =>
                            //   props.handleChangeLocation(
                            //     "drilldownOnGraph",
                            //     teams,
                            //     "open",
                            //     props.type,
                            //     true
                            //   )
                            // }
                            className={classes.repHeader}
                            style={{
                              border: "0.5px solid lightgrey",
                            //   cursor: "pointer",
                              paddingRight: "0.5rem",
                            }}
                            colSpan={1}
                          >
                            {ast(
                              props.data.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].projectedAttainmentHigh,
                              props.datav2.filter((i) =>
                                ["Total"].includes(i.name)
                              )[0].projectedAttainmentHigh
                            )}
                          </TableCell>
                          </>
                        )}
                      </StyledTableRow>
                      {/* <StyledTableRow>
                        <TableCell
                          align="left"
                          className={classes.nonScoreCell}
                          style={{
                            border: "0.5px solid lightgrey",
                            fontWeight: 600,
                          }}
                          colSpan={1}
                        >
                          
                        </TableCell>
                        {props.type === "CFQ"
                          ? Array(props.db === "C0003" ? 11 : 13)
                              .fill()
                              .map((item) => (
                                <TableCell
                                  align="center"
                                  className={classes.repHeader}
                                  style={{ border: "0.5px solid lightgrey" }}
                                  colSpan={1}
                                ></TableCell>
                              ))
                          : Array(props.db === "C0003" ? 6 : 8)
                              .fill()
                              .map((item) => (
                                <TableCell
                                  align="center"
                                  className={classes.repHeader}
                                  style={{ border: "0.5px solid lightgrey" }}
                                  colSpan={1}
                                ></TableCell>
                              ))}
                      </StyledTableRow> */}
                    </>
                  )}
                  {teams.map((team) => {
                    const teamData = props.data.filter((s) => s.name === team);
                    const teamDatav2 = props.datav2.filter(
                      (s) => s.name === team
                    );
                    return (
                      <StyledTableRow>
                        <TableCell
                          align="left"
                          className={classes.repHeader}
                          style={{
                            border: "0.5px solid lightgrey",
                            textIndent: "0rem",
                            paddingLeft: "1.75rem",
                            minWidth: 160,
                          }}
                          colSpan={1}
                        >
                          {team}
                        </TableCell>
                       
                      
                        {props.type === "CFQ" && (
                          <>
                            <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocationWon(
                                  "drilldownOnGraph",
                                  [team],
                                  splitup
                                )
                              }
                              className={classes.repHeader}
                              style={{
                                border: "0.5px solid lightgrey",
                                cursor: "pointer",
                                paddingRight: "0.5rem",
                              }}
                              colSpan={1}
                            >
                              $
                              {![null, undefined, ""].includes(teamData[0].won)
                                ? countOrACVFormatter(teamData[0].won)
                                : 0}
                            </TableCell>
                           
                          </>
                        )}
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              [team],
                              "open",
                              props.type,
                              true
                            )
                          }
                          className={classes.repHeader}
                          style={{
                            border: "0.5px solid lightgrey",
                            cursor: "pointer",
                            paddingRight: "0.5rem",
                          }}
                          colSpan={1}
                        >
                          {![null, undefined, ""].includes(teamData[0].count)
                            ? countOrACVFormatter(teamData[0].count)
                            : 0}
                        </TableCell>
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              [team],
                              "open",
                              props.type,
                              true
                            )
                          }
                          className={classes.repHeader}
                          style={{
                            border: "0.5px solid lightgrey",
                            cursor: "pointer",
                            paddingRight: "0.5rem",
                          }}
                          colSpan={1}
                        >
                          $
                          {![null, undefined, ""].includes(teamData[0].acv)
                            ? countOrACVFormatter(teamData[0].acv)
                            : 0}
                        </TableCell>
  
                        {props.type === "CFQ" && (
                          <>
                            {/* <TableCell align='right' className={classes.repHeader} style={{border: '0.5px solid lightgrey',paddingRight:'0.5rem' }} colSpan={1}>${![null,undefined,''].includes(teamData[0].expectedWonAcv) ? countOrACVFormatter(teamData[0].expectedWonAcv) : 0}</TableCell>
                                       <TableCell align='right' className={classes.repHeader} style={{border: '0.5px solid lightgrey',paddingRight:'0.5rem' }} colSpan={1}>{![null,undefined,''].includes(teamData[0].attainmentPerc) ? Math.round(teamData[0].attainmentPerc) : 0}%</TableCell> */}
                          </>
                        )}
                        {/* Skygeni Projected yield low-mid-high */}
                        {props.ShowLMH !== false && (
                          <>
                           <TableCell
                                align="right"
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    [team],
                                    "open",
                                    props.type,
                                    true
                                  )
                                }
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                  cursor: "pointer",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(teamData[0].low)
                                  ? ast(teamData[0].low, teamDatav2[0].low)
                                  : "$0"}
                              </TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    [team],
                                    "open",
                                    props.type,
                                    true
                                  )
                                }
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                  cursor: "pointer",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(teamData[0].mid)
                                  ? ast(teamData[0].mid, teamDatav2[0].mid)
                                  : "$0"}
                              </TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    [team],
                                    "open",
                                    props.type,
                                    true
                                  )
                                }
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                  cursor: "pointer",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(teamData[0].high)
                                  ? ast(teamData[0].high, teamDatav2[0].high)
                                  : "$0"}
                              </TableCell>

                              <TableCell
                                align="right"
                                // onClick={() =>
                                //   props.handleChangeLocation(
                                //     "drilldownOnGraph",
                                //     [team],
                                //     "open",
                                //     props.type,
                                //     true
                                //   )
                                // }
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                //   cursor: "pointer",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(teamData[0].projectedAttainmentLow)
                                  ? ast(teamData[0].projectedAttainmentLow, teamDatav2[0].projectedAttainmentLow)
                                  : "$0"}
                              </TableCell>
                              <TableCell
                                align="right"
                                // onClick={() =>
                                //   props.handleChangeLocation(
                                //     "drilldownOnGraph",
                                //     [team],
                                //     "open",
                                //     props.type,
                                //     true
                                //   )
                                // }
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                //   cursor: "pointer",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(teamData[0].projectedAttainmentMid)
                                  ? ast(teamData[0].projectedAttainmentMid, teamDatav2[0].projectedAttainmentMid)
                                  : "$0"}
                              </TableCell>
                              <TableCell
                                align="right"
                                // onClick={() =>
                                //   props.handleChangeLocation(
                                //     "drilldownOnGraph",
                                //     [team],
                                //     "open",
                                //     props.type,
                                //     true
                                //   )
                                // }
                                className={classes.repHeader}
                                style={{
                                  border: "0.5px solid lightgrey",
                                //   cursor: "pointer",
                                  paddingRight: "0.5rem",
                                }}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(teamData[0].projectedAttainmentHigh)
                                  ? ast(teamData[0].projectedAttainmentHigh, teamDatav2[0].projectedAttainmentHigh)
                                  : "$0"}
                              </TableCell>
                          </>
                        )}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </>
    );
  }
  
  // background:'#f4b183'
  