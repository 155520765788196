import * as d3 from 'd3'

const D3Funnel = {};



D3Funnel.create = (el, config) => {
	// D3 Code to create the chart

	config.margin = { top: 20, right: 20, bottom: 30, left: 300 }
	if (!config.width) {
		config.width = d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right;
	}
	config.height = d3.select(el).node().getBoundingClientRect().height - config.margin.top - config.margin.bottom;
	const data = config.data

	var y = d3.scaleBand()
		.range([config.height, 0])
		.padding(0.1);

	var x = d3.scaleLinear()
		.range([0, config.width]);

	var make_x_axis = () => {
		return d3.axisBottom(x)
	}

	var svg = d3.select(el).append("svg")
		.attr("width", config.width + config.margin.left + config.margin.right)
		.attr("height", config.height + config.margin.top + config.margin.bottom)
		.append("g")
		.attr("transform",
			"translate(" + config.margin.left + "," + config.margin.top + ")");

	// format the data
	data.forEach(function (d) {
		d.influenceFactor = +d.influenceFactor;
	});

	// Scale the range of the data in the domains
	x.domain([0.74, 0.94])
	y.domain(data.map(function (d) { return d.category; }));
	//y.domain([0, d3.max(data, function(d) { return d.influenceFactor; })]);

	svg.append("g")
		.attr("class", "grid")
		.attr("transform", "translate(0," + config.height + ")")
		.call(make_x_axis()
			.tickSize(-config.height, 0, 0)
			.tickFormat("")
		)

	svg.selectAll('g.grid').selectAll('g.tick').selectAll('line')
		.attr("stroke", "rgb(127, 127, 127")
		.attr('opacity', 0.6)

	// append the rectangles for the bar chart
	svg.selectAll(".bar")
		.data(data)
		.enter().append("rect")
		.attr("class", "bar")
		.attr("fill", "#4472c4")
		//.attr("x", function(d) { return x(d.influenceFactor); })
		.attr("width", function (d) { return x(d.influenceFactor); })
		.attr("y", function (d) { return y(d.category); })
		.attr("height", y.bandwidth() - 10);

	// add the x Axis
	svg.append("g")
		.attr("class", "xaxis")
		.attr("transform", "translate(0," + config.height + ")")
		.call(d3.axisBottom(x));

	// add the y Axis
	svg.append("g")
		.attr("class", "yaxis")
		.call(d3.axisLeft(y));


	svg.selectAll(".xaxis text")
		.attr('font-size', '16px')

	svg.selectAll(".yaxis text")
		.attr('font-size', '16px')



};

D3Funnel.update = (el, config) => {

};

D3Funnel.destroy = (el) => {
	// Cleaning code here
	d3.select(el).selectAll('svg').remove();
};

export default D3Funnel;