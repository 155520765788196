import { CardContent, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react'
import { getUniqueValues, _isNaN,countOrACVFormatter,percentFormatter } from '../../util/customFunctions';
import { getMixKey } from '../../util/generateHeadings';

import StyledTableRow from '../../components/StyledTableRow';
import * as d3 from "d3"
import { deSnake } from '../../util/dataFormatter';
const useStyle = makeStyles(theme => ({
    root: {},
  content: { width: '100%' },
  table: {
    border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', width: '100%'
  },
  segmentHeader: {
    fontWeight: 600, border: '0.5px solid lightgrey', padding: '5px', color: 'inherit'
  },
  repHeader: {
    border: '0.5px solid lightgrey', padding: '5px', color: 'inherit'
  },
  repHeader1: {
    border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', fontWeight: 'inherit'
  },
  repText: {
    border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'left', minWidth: '169px'
  },
  repNumber: {
    border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'right',
  },
  fontBold: {
    fontWeight: 600
  },
  tableColWidth: {
    minWidth: 90
  },
  drilldownCell: {
    cursor: 'pointer'
  }
}))
export default function NextQuarterConversionTable(props) {
  const classes = useStyle()

  return (
    <div className={classes.root}
      style={{ height: '100%', display: 'flex', alignItems: 'center', width: '100%' }}>
      <CardContent className={classes.content} style={{ padding: 10 }}>
                
                <Grid container justify="flex-start">

                    <TableContainer className={classes.mainTable} >

                        <Table data-test={'pipeline'} size="small" aria-label="html table" style={{ tableLayout: 'auto', width: 'initial' }}>

                            <TableHead>
                            </TableHead>
                            <TableBody>
                                 <TableRow style={{fontWeight:600}} >
                                        <TableCell align='center' className={classes.repHeader1} colSpan={1} >Stage</TableCell>
                                      <React.Fragment>
                                          <TableCell align='center' className={classes.repHeader1} colSpan={1} style={{minWidth:120}}>ACV at Time T</TableCell>
                                          <TableCell align='center' className={classes.repHeader1} colSpan={1} style={{minWidth:120}} >Low-End</TableCell>
                                          <TableCell align='center' className={classes.repHeader1} colSpan={1} style={{minWidth:120}} >Midpoint</TableCell>
                                          <TableCell align='center' className={classes.repHeader1} colSpan={1} style={{minWidth:120}} >High-End</TableCell>
                                        </React.Fragment>
                                    </TableRow>
                                
                                {props.winAcvData.map((row, i) => {
                                 const cellData = props.winAcvData.filter(item => item[props.splitType] === row[props.splitType])[0]
                                  return (
                                    <StyledTableRow key={i}>
                                    <TableCell align='left' className={classes.repText} colSpan={1} >{cellData[props.splitType]}</TableCell>
                                   
                                            <React.Fragment>
                                                <TableCell style={{ cursor: "pointer" }} onClick={() => props.handleChangeLocation("drilldownOnGraph", {  })} align='right' className={classes.repNumber} colSpan={1}>{cellData !== undefined ? countOrACVFormatter(Math.round(cellData['acv']),true) : "-"}</TableCell>
                                                <TableCell style={{ cursor: "pointer" }} onClick={() => props.handleChangeLocation("drilldownOnGraph", {  })} align='right' className={classes.repNumber} colSpan={1}>{cellData !== undefined ? countOrACVFormatter(Math.round(cellData['low']),true) : "-"}</TableCell>
                                                <TableCell style={{ cursor: "pointer" }} onClick={() => props.handleChangeLocation("drilldownOnGraph", {  })} align='right' className={classes.repNumber} colSpan={1}>{cellData !== undefined ? countOrACVFormatter(Math.round(cellData['mid']),true) : "-"}</TableCell>
                                                <TableCell style={{ cursor: "pointer" }} onClick={() => props.handleChangeLocation("drilldownOnGraph", {  })} align='right' className={classes.repNumber} colSpan={1}>{cellData !== undefined ? countOrACVFormatter(Math.round(cellData['high']),true) : "-"}</TableCell>
                                            </React.Fragment>
                                </StyledTableRow>

                                  )
                                }
                                   
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            
        </CardContent>
    </div>
  )
}
