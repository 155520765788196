import * as React from 'react';
import Box from '@material-ui/core/Box';
import './OppPyramid.css'
import { Typography, Grid } from '@material-ui/core';

const commonStyles = {
  backgroundColor: 'black',
  borderColor: 'text.primary',
  m: 1,
  border: "2px solid black",
  width: '20px',
  height: '20px',
};

export default function OppPyramidLegend(props) {
  console.log(props)
  return (
    <Grid container justify="center">
      {
        props.stages !== undefined &&
        <Grid container justify="center">
     <Box style={{display: 'flex', justifyContent: 'center'}} xs={4}>
     <Box style={{ ...commonStyles, borderRadius: '50%' }} className="circle-half" />
     <Typography style={{marginLeft: "0.5rem",}}>{props.stages[0].stage_name}</Typography>
     </Box>
     <Box style={{display: 'flex', justifyContent: 'center', marginLeft: '3rem'}} xs={4}>
      <Box style={{ ...commonStyles, borderRadius: '50%', backgroundColor: 'grey' }} />
      <Typography style={{marginLeft: "0.5rem",}}>{props.stages[1].stage_name}</Typography>
     </Box>
     {
       props.stages[2] !== undefined &&
       <Box style={{display: 'flex', justifyContent: 'center', marginLeft: '3rem'}} xs={4}>
         <Box style={{ ...commonStyles, borderRadius: '50%' }} />
         <Typography style={{marginLeft: "0.5rem",}}>{props.stages[2].stage_name}</Typography>
      </Box>
     }
     
     </Grid>
}
    </Grid>
  );
}
