import { FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core'
import React from 'react'
import { nearestCompletedFiscalQuarter } from '../util/fiscalQuarterFunctions'

export default function AccountSegmentFilter(props) {
    return (
        <Grid justify="flex-start" item xs={12} container component={Paper} style={{ padding: 10, marginRight: 10 }}>
            <Grid item xs={12}>
                <FormControl fullWidth >
                    <InputLabel style={{ color: !props.selectedAccount_Segment.includes('All') ? '#4472c4' : '#000' }} id="select-vfiscalQuarter-label">Acct Segment:</InputLabel>
                    <Select
                        labelId="select-vfiscalQuarter-label"
                        id="select-cAccount_Segment"
                        value={props.selectedAccount_Segment}
                        onChange={props.handleChange}
                        label="Account_Segment"
                        name='Account_Segment'
                        multiple
                    >
                        <MenuItem value={'All'} >All</MenuItem>

                        {props.accountSegment
                            .map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}