import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import CopyTablesObject from "../../../components/xlsx-export/CopyTablesObject";
import {
  countOrACVFormatter,
  getUniqueValues,
  StyledTableRow,
} from "../../../util/customFunctions";

const useStyle = makeStyles((theme) => ({
  headerCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  dataCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function PCTablev2(props) {
  const classes = useStyle();
  const tableRef = React.createRef();

  const uniqueCols = props.cols;
  let uniqeRows = props.data.map((l) => l.subject).filter(getUniqueValues);
  const total = uniqeRows.filter(f => ['Team', 'Company', 'Rep'].includes(f))[0]
  uniqeRows = [total, ...uniqeRows.filter(f => !['Team', 'Company', 'Rep'].includes(f)).sort()];
  return (
    <div>
      <TableContainer
        style={{
          padding: "1rem",
          paddingBottom: "3rem",
          margin: "auto",
          width: "80%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
            // marginRight: "10px",
          }}
          align="center"
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                style={{ width: 100, height: 50 }}
                colSpan={1}
                className={classes.headerCell}
              >
                Team / Rep
              </TableCell>
              {uniqueCols.map((l, i) => {
                return (
                  <TableCell
                    align="center"
                    className={classes.headerCell}
                    colSpan={1}
                    style={{ background: l.bg, color: l.cl, width: 100 }}
                  >
                    {l.label}
                  </TableCell>
                );
              })}
            </TableRow>

            {uniqeRows.map((row) => {
              const d = props.data.filter((f) => f.subject === row);
              let pipeline = "-";
              let goal = "-";
              let pcperc = "-";
              let met = "-";
              let cperc = "-";
              let avgFive = "-";
              let bestFivePerc = "-";
              if (d.length > 0) {
                pipeline = countOrACVFormatter(
                  d.map((f) => f.acv).reduce((a, b) => a + b, 0),
                  true
                );
                goal = countOrACVFormatter(
                  d.map((f) => f.goal).reduce((a, b) => a + b, 0),
                  true
                );
                pcperc = `${Math.round(
                  d
                    .map((f) => f.pipelineCreationPerc)
                    .reduce((a, b) => a + b, 0) * 100
                )}%`;
                met = d
                  .map((f) => f.noOfPeriodCreationGoalMet)
                  .reduce((a, b) => a + b, 0);
                cperc = `${Math.round(
                  d
                    .map((f) => f.consistencyPercOfPeriods)
                    .reduce((a, b) => a + b, 0) * 100
                )}%`;
                avgFive =  typeof d.map((f) => f.averageOfFiveBestPipeCreators).reduce((a, b) => a + b, 0) === 'string' ? 'N/A' : countOrACVFormatter(
                  d
                    .map((f) => f.averageOfFiveBestPipeCreators)
                    .reduce((a, b) => a + b, 0),
                  true
                );
                bestFivePerc = typeof d.map((f) => f.bestPipeCreatorsPerc).reduce((a, b) => a + b, 0) === 'string' ? 'N/A' : `${Math.round(
                  d
                    .map((f) => f.bestPipeCreatorsPerc)
                    .reduce((a, b) => a + b, 0) * 100
                )}%`;
              }
              return (
                <StyledTableRow key={row}>
                  <TableCell
                    className={classes.headerCell}
                    align="left"
                    style={ { paddingLeft: "1.75rem" } }
                  >
                    {row}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right">
                    {pipeline}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right">
                    {goal}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right">
                    {pcperc}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right">
                    {met}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right">
                    {cperc}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right">
                    {avgFive}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="right">
                    {bestFivePerc}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}