import React from "react";
import C0001_Attainmnet from "./Custom/C0001/C0001_Attainment";
import Attainment from "./Attainment";

const components = {
  C0001: C0001_Attainmnet,
  generic: Attainment,
};

function CustomOrgenericComponentFinder(props) {
  console.log(props);
  // Correct! JSX type can be a capitalized variable.
  const AttainmentComponent = components[props.type];
  return (
    <AttainmentComponent
      filters={props.filters}
      selectedTeam={props.selectedTeam}
      selectedRep={props.selectedRep}
      isCTScorecard={props.isCTScorecard}
      isSplitByNonRenewalsRenewals={props.isSplitByNonRenewalsRenewals}
    />
  );
}

export default CustomOrgenericComponentFinder;
