import * as d3 from "d3";
import "./D3OpenOppurtunities.scss";
import {
  getUniqueValues,
  getWeekStartOrMonthStart,
  getYaxisFormatter,
  stageSortingFunction,
} from "../../util/customFunctions";
import { store } from "../../util/store";
import { computeDomainLimits, computeDomainLimitsDate } from "./utils";
const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};
const D3OpenOpp = {};

D3OpenOpp.create = (el, config) => {
  console.log(config);
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;

  const fontWeight = 400;
  const getTicks = (acv) => parseInt(String(acv)[0]);
  var formatter = new Intl.NumberFormat("en-US", {
    // style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  var formatClosedDate = (closedDate) =>
    new Date(closedDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  // Define our scales

  if (config.data) {
    const uniqueStages = config.stage
      .map((item) => item.stage)
      .filter(getUniqueValues);
    // console.log(uniqueStages)
    // console.log(config.stage.map(stage => stage.stage_name))
    config.data = config.data
      .map((item) => {
        if (item.Projected_Close_Date !== undefined) {
          if (item.Projected_Close_Date !== null)
            var [closedYear, closedMonth, closedDate] =
              item.Projected_Close_Date.split("-");
        }
        if (item.Qualified_Date !== undefined) {
          if (item.Qualified_Date !== null)
            var [qualifiedYear, qualifiedMonth, qualifiedDate] =
              item.Qualified_Date.split("-");
        }
        return {
          ACV: item.ACV,
          closed_date: new Date(
            new Date(
              parseInt(closedYear),
              parseInt(closedMonth) - 1,
              parseInt(closedDate.substr(0, 2))
            ).setHours(0)
          ).setMinutes(0),
          qualified_date:
            item.Qualified_Date !== undefined
              ? item.Qualified_Date !== null
                ? formatClosedDate(
                    new Date(
                      parseInt(qualifiedYear),
                      parseInt(qualifiedMonth) - 1,
                      parseInt(qualifiedDate.substr(0, 2))
                    ).getTime()
                  )
                : "-"
              : undefined,
          stage: item.Stage,
          history: item.Stage_Change_Sequence,
          account: item.Account_Name,
          team: item.Team,
          owner: item.Owner_Name,
          competitor_primary:
            item.Primary_Competitor === null ? "-" : item.Primary_Competitor,
          anamoly: item.Is_Anomaly !== null ? item.Anomaly_Code : 0,
          opp_name: item.OppName,
          Opp_ID: item.Opp_ID,
          personas_iden:
            item.Personas_Identified !== null
              ? item.Personas_Identified === undefined
                ? undefined
                : item.Personas_Identified === "1"
                ? "Yes"
                : "No"
              : "-",
        };
      })
      .filter((item) => isNotOlderThan90Days(item));
    config.data = stageSortingFunction(
      config.data,
      config.company,
      config.stage
    );
    // config.data = config.data.sort((a,b) => (a.stage) - (b.stage) )
    const stages = new Set(config.data.map((item) => item.stage));
    const stagesList = [...stages];
    const stagesColor = d3
      .scaleLinear()
      .domain([1, stagesList.length])
      .range(["#4472c4", "#70ad47"]);
    d3.selection.prototype.last = () =>
      d3.select(this.nodes()[this.size() - 1]);
    if (config.acv) {
      config.acv = parseInt(config.acv.replace(/,/g, ""));
      config.acvLimit = parseInt(config.acvLimit);
    }
    var getMonthIntervals = () => {
      var minDateFormat =
        new Date(d3.min(config.data, (d) => d.closed_date)) <
        new Date().getTime()
          ? new Date(d3.min(config.data, (d) => d.closed_date))
          : new Date();
      var maxDateFormat = new Date(d3.max(config.data, (d) => d.closed_date));
      // var newMinDate = minDateFormat.getDate() < 31 ? minDateFormat.setDate(1) : minDateFormat
      var newMaxDate;
      if (maxDateFormat.getDate() >= 20) {
        newMaxDate = maxDateFormat.setDate(1);
        maxDateFormat.setMonth(maxDateFormat.getMonth() + 2);
      } else {
        newMaxDate = maxDateFormat.setDate(1);
        maxDateFormat.setMonth(maxDateFormat.getMonth() + 1);
      }
      return [minDateFormat.getTime(), maxDateFormat.getTime()];
    };

    config.barColor = "#70ad47";

    // compute bar height
    const computeBarHeight = () => {
      if (window.innerWidth > 1920) {
        return 480;
      } else if (window.innerWidth > 1368 && window.innerWidth < 1700) {
        return 320;
      } else {
        return 370;
      }
    };
    const _barHeight = computeBarHeight();
    config.barHeight = _barHeight;
    config.margin = {
      top: 30,
      right: config.data
        ? d3.select(el).node().getBoundingClientRect().width < 400
          ? 30
          : 50
        : 10,
      bottom: config.data
        ? d3.select(el).node().getBoundingClientRect().width < 550
          ? 100
          : 30
        : 10,
      left: config.data
        ? d3.select(el).node().getBoundingClientRect().width < 400
          ? 70
          : 90
        : 10,
    };
    if (!config.width) {
      config.width = d3.select(el).node()
        ? d3.select(el).node().getBoundingClientRect().width -
          config.margin.left -
          config.margin.right
        : 100;
    }
    const computeHeight = () => {
      if (window.innerWidth > 1920) {
        return 540;
      } else if (window.innerWidth > 1368 && window.innerWidth < 1700) {
        return 370;
      } else {
        return 440;
      }
    };
    const _height = computeHeight();
    config.height = config.data ? _height : 100;
    //x scale is 200% - 100% on left side, 100% on right side
    var x = d3.scaleBand().domain([0, 2]).range([0, config.width]);
    console.log("config.height", config.height);
    console.log("config.margin.top", config.margin.top);
    console.log("config.margin.bottom", config.margin.bottom);

    var chartContainer = d3
      .select(el)
      .append("svg")
      .attr("width", config.width + config.margin.left + config.margin.right)
      // .attr("height", config.height + config.margin.top + config.margin.bottom);
      .attr("height", config.height + config.margin.bottom);

    var chart = chartContainer
      .append("g")
      .attr(
        "transform",
        "translate(" + config.margin.left + "," + config.margin.top + ")"
      );
    var tooltip = d3
      .select(el)
      .append("div")
      .attr("class", "tooltip-scatter")
      .style("opacity", 0);

    // gridlines in x axis function
    function make_x_gridlines() {
      return d3
        .axisBottom(xScale)
        .ticks(
          d3.select(el).node().getBoundingClientRect().width < 900 ? 2 : 5
        );
    }

    // gridlines in y axis function

    var xScale;

    if (config.range !== undefined) {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      var [fromMonth, fromDate, fromYear] = config.range.from
        .split("-")
        .map((item) => parseInt(item));
      var [toMonth, toDate, toYear] = config.range.to
        .split("-")
        .map((item) => parseInt(item));
      const firstDate = new Date(fromYear, fromMonth - 1, fromDate);
      var secondDate = new Date(toYear, toMonth - 1, toDate);
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      secondDate.setDate(secondDate.getDate() + Math.ceil(diffDays / 10) + 5);
      xScale = d3
        .scaleTime()
        .domain([
          new Date(fromYear, fromMonth - 1, fromDate).getTime(),
          getWeekStartOrMonthStart(
            secondDate.getTime(),
            Math.ceil(diffDays / 10) + 5 > 12 ? true : false
          ),
        ])
        .range([0, config.width]);
    } else {
      config.data = config.data.filter(
        (item) => item.closed_date >= new Date().getTime()
      );
      xScale = d3
        .scaleTime()
        .domain([new Date().getTime(), getMonthIntervals()[1]])
        .range([0, config.width]);
    }

    // console.log(config.data)
    var minValDate = d3.min(config.data, (d) => d.closed_date);
    var maxValDate = d3.max(config.data, (d) => d.closed_date);
    let { lower_bound_date, upper_bound_date } = computeDomainLimitsDate(
      minValDate,
      maxValDate
    );
    xScale = d3
      .scaleTime()
      .domain([lower_bound_date, upper_bound_date])
      .range([0, config.width]);
    var color = d3.scaleOrdinal(config.stage.map((i) => i.forward_color_hash));
    color.domain(uniqueStages);

    chart
      .append("g")
      .attr("class", "xaxis")
      .attr("transform", "translate(0," + config.barHeight + ")")
      .call(
        d3
          .axisBottom(xScale)
          .ticks(
            d3.select(el).node().getBoundingClientRect().width < 900 ? 2 : 5
          )
          .tickFormat(d3.timeFormat("%d-%b-%y"))
      );

    var yScale;
    var maxVal = d3.max(config.data, (d) => d.ACV);
    var minVal = d3.min(config.data, (d) => d.ACV);
    // console.log(maxVal)
    // if (config.company === "C0002") {
    //   maxVal *= 1.1
    // }
    // console.log(maxVal)
    // var minVal = d3.min(config.data, d => d.ACV)
    const { lower_bound, upper_bound } = computeDomainLimits(minVal, maxVal);
    yScale = d3
      .scaleLinear()
      // .domain([config.acv === null || _isNaN(config.acv) || config.acv === "" ? minVal === undefined ? 0 : 0 : config.acvLimit > 100000 || _isNaN(config.acvLimit) ? 0 : config.acv, config.acvLimit === null || _isNaN(config.acvLimit) || config.acvLimit === "" ? maxVal === undefined ? 50000 : maxVal === 0 ? 10000 : maxVal : config.acvLimit])
      .domain([lower_bound, upper_bound])
      .range([config.barHeight, 0]);

    function make_y_gridlines() {
      return d3.axisLeft(yScale).ticks(5);
    }

    chart
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", config.width)
      .attr("y2", 0)
      .attr("storke-width", 1)
      .attr("stroke", "#e6e6e6");
    chart
      .append("line")
      .attr("x1", config.width)
      .attr("y1", 0)
      .attr("x2", config.width)
      .attr("y2", config.barHeight)
      .attr("storke-width", 1)
      .attr("stroke", "#e6e6e6");
    chart
      .append("line")
      .attr("x1", 0)
      .attr("y1", config.barHeight)
      .attr("x2", config.width)
      .attr("y2", config.barHeight)
      .attr("storke-width", 1)
      .attr("stroke", "#e6e6e6");
    chart
      .append("line")
      .attr("x1", 0)
      .attr("y1", config.barHeight)
      .attr("x2", 0)
      .attr("y2", 0)
      .attr("storke-width", 1)
      .attr("stroke", "#e6e6e6");

    chart
      .append("g")
      .attr("class", "yaxis")
      .call(
        d3
          .axisLeft(yScale)
          .tickFormat((d) =>
            d !== 0
              ? `${Currency_Symbol}${getYaxisFormatter(
                  config.acv === null || _isNaN(config.acv) || config.acv === ""
                    ? minVal === undefined
                      ? 0
                      : minVal
                    : config.acv,
                  config.acvLimit === null ||
                    _isNaN(config.acvLimit) ||
                    config.acvLimit === ""
                    ? maxVal === undefined
                      ? 50000
                      : maxVal
                    : config.acvLimit,
                  d
                )}`.toUpperCase()
              : `${Currency_Symbol}0`
          )
          .ticks(5)
      );
    chart
      .selectAll(".xaxis text")
      .attr("font-size", "0.85rem")
      .attr("font-weight", 500);
    chart
      .selectAll(".yaxis text")
      .attr("font-size", "0.85rem")
      .attr("font-weight", 500);
    // add the X gridlines
    chart
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0," + config.barHeight + ")")
      .attr("z-index", -1)
      .call(make_x_gridlines().tickSize(-config.barHeight).tickFormat(""));

    // add the Y gridlines
    chart
      .append("g")
      .attr("class", "grid")
      .attr("z-index", -1)
      .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));
    chart
      .append("g")
      .selectAll("dot-scatter")
      .data(config.data)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return xScale(d.closed_date);
      })
      .attr("cy", function (d) {
        return yScale(d.ACV);
      })
      .attr("r", 10)
      .attr("class", "dot-scatter")
      .attr("stroke", (d) =>
        d.anamoly === 0 || d.anamoly === undefined
          ? "black"
          : d.anamoly === 1
          ? "orange"
          : "red"
      )
      .attr("stroke-width", (d) => (d.anamoly > 0 ? "2px" : "1px"))
      .attr(
        "fill",
        (d, i) =>
          config.stage.filter((item) => item.stage_name === d.stage)[0]
            .forward_color_hash
      )
      .style("cursor", "pointer")
      // .style("z-index")
      .on("click", (d) => config.handleChange("drilldownOppId", d.Opp_ID))
      .on("mouseover", (d) => {
        store.dispatch({ type: "deselected_scatter", descatterpoint: "" });
        store.dispatch({ type: "selected_scatter", scatterpoint: d });
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `<div id="tooltip" class="tooltip-container">
            <div class="tooltip-box-scatter"  style="border-top:10px solid ${
              config.stage.filter((item) => item.stage_name === d.stage)[0]
                .forward_color_hash
            };border-radius:0.3rem; ${
              d.anamoly !== 0 && d.anamoly !== undefined
                ? `border-bottom:10px solid ${
                    d.anamoly === 1 ? "orange" : d.anamoly === 2 ? "red" : ""
                  };`
                : ""
            }">
            <h3 class="heading-style">${d.account}</h3>              
              <div>
                <div class="flex-container">
                  <div class="flex-item heading-style">
                  ${Currency_Symbol}${formatter.format(d.ACV)}
                  </div>
                  <div class="flex-item heading-style">
                    ${formatClosedDate(d.closed_date)}
                  </div>
                  <div class="flex-item heading-style">
                    ${
                      config.company === "C0003"
                        ? d.stage.charAt(d.stage.length - 2)
                        : d.stage
                    }
                  </div>
                  <div class="flex-item">
                    ${d.owner} / ${d.team !== null ? d.team : "-"}
                  </div>
                </div>
                ${
                  d.personas_iden === undefined &&
                  d.competitor_primary === undefined
                    ? ""
                    : `
                  <div class="flex-container">
                    <div class="flex-item">
                    ${
                      d.personas_iden !== undefined
                        ? `<span style="color:gray;">Personas IDed:</span> ${d.personas_iden}`
                        : ""
                    }
                    </div>
                    <div class="flex-item">
                    ${
                      d.competitor_primary !== undefined
                        ? `<span style="color:gray;">Comp. (P):</span> ${
                            d.competitor_primary !== null
                              ? d.competitor_primary
                              : "-"
                          }`
                        : ""
                    }
                    </div>                  
                  </div>
                `
                }
                ${
                  d.qualified_date === undefined && d.history
                    ? ""
                    : `
                <div class="flex-container-spaced">
                  <div class="flex-item">
                    ${
                      d.qualified_date !== undefined
                        ? `<span style="color:gray;">Qualified On:</span> ${d.qualified_date}`
                        : ""
                    }
                  </div>
                  <div class="flex-item">
                  ${
                    d.history !== undefined
                      ? `<span style="color:gray;">History:</span> ${
                          d.history ? d.history : "-"
                        }`
                      : ""
                  }
                  </div>
                </div>
                `
                }
                
              </div>
              
                   
              
              ${
                d.anamoly !== undefined
                  ? d.anamoly !== 0
                    ? `<div style="padding:0 1rem 0.5rem 1rem;"><h5 style="margin-top:5px;"><span style="color:${
                        d.anamoly === 1
                          ? "orange"
                          : d.anamoly === 2
                          ? "red"
                          : ""
                      }">${
                        d.anamoly === 1
                          ? "Warning"
                          : d.anamoly === 2
                          ? "Alert"
                          : ""
                      }</span>: Less than ${
                        d.anamoly === 1 ? "20%" : "10%"
                      } probability of getting to Closed Won by ${formatClosedDate(
                        d.closed_date
                      )}</h5></div>`
                    : ""
                  : ""
              }
              
              </div>
            </div>`
          )

          .style(
            "left",
            `${
              d3.select(el).node().getBoundingClientRect().width < 800
                ? d3.event.pageX < window.innerWidth * 0.55
                  ? `calc(${d3.event.pageX + "px"})`
                  : `calc(${d3.event.pageX + "px"} - 40%)`
                : d3.event.pageX < window.innerWidth * 0.55
                ? `calc(${d3.event.pageX + "px"})`
                : `calc(${d3.event.pageX + "px"} - 25%)`
            }`
          )
          .style("top", d3.event.pageY + "px");
      })
      .on("mouseout", (d) => {
        store.dispatch({ type: "selected_scatter", scatterpoint: "" });
        store.dispatch({ type: "deselected_scatter", descatterpoint: d });
        tooltip.transition().duration(300).style("opacity", 0);
      });
    chartContainer.on("mouseleave", (d) => {
      store.dispatch({ type: "deselected_scatter", descatterpoint: "" });
    });
    chart
      .append("text")
      .attr("dy", "1em")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("y", config.margin.left - 160)
      .attr("x", -(config.barHeight / 2))
      .attr("transform", "rotate(-90)") // although axis is rotated, text is not
      .attr("fill", "#00000")
      .text(`${config.Value_Label}`);

    chart
      .append("text")
      .attr("y", config.barHeight + 35)
      .attr("x", config.width / 2 - 50)
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("fill", "#000000")
      .text("Projected Close Date");
    // if (d3.select(el).node().getBoundingClientRect().width < 550) {
    //   let offset = config.width > 600 ? 110 : 70;
    //   let offset2 = offset - (config.width > 600 ? 20 : 15);

    //   config.stage.map((s, i) =>
    //     i % 2 == 0
    //       ? chart
    //           .append("circle")
    //           .attr("class", "dot-scatter-legend")
    //           .attr("r", 10)
    //           .attr("cx", config.width / config.stage.length - offset)
    //           .attr("cy", config.barHeight + 70 + 30 * 0)
    //           .style("fill", s.forward_color_hash)
    //       : chart
    //           .append("circle")
    //           .attr("class", "dot-scatter-legend")
    //           .attr("r", 10)
    //           .attr("cx", (config.width / config.stage.length) * 4 - offset)
    //           .attr("cy", config.barHeight + 70 + 30 * 0)
    //           .style("fill", s.forward_color_hash)
    //   );

    //   config.stage.map((s, i) =>
    //     i % 2 == 0
    //       ? chart
    //           .append("text")
    //           .attr("x", config.width / config.stage.length - offset2)
    //           .attr("y", config.barHeight + 75 + 30 * 0)
    //           .text(s.stage_name)
    //           .style("font-size", "0.85rem")
    //           .style("font-weight", fontWeight)
    //       : chart
    //           .append("text")
    //           .attr("x", (config.width / config.stage.length) * 4 - offset2)
    //           .attr("y", config.barHeight + 75 + 30 * 0)
    //           .text(s.stage_name)
    //           .style("font-size", "0.85rem")
    //           .style("font-weight", fontWeight)
    //   );
    // } else {
    //   let offset = config.width > 600 ? 110 : 70;
    //   let offset2 = offset - (config.width > 600 ? 40 : 15);

    //   console.log("config.width", config.width);

    // config.stage.map((s, i) =>
    //   chart
    //     .append("circle")
    //     .attr("class", "dot-scatter-legend")
    //     .attr("r", 10)
    //     .attr(
    //       "cx",
    //       (config.width / config.stage.length) * (i + 1) - offset - 90
    //     )
    //     .attr("cy", config.barHeight + 70)
    //     .style("fill", s.forward_color_hash)
    // );

    // config.stage.map((s, i) =>
    //   chart
    //     .append("text")
    //     .attr(
    //       "x",
    //       (config.width / config.stage.length) * (i + 1) - offset2 - 110
    //     )
    //     .attr("y", config.barHeight + 75)
    //     .text(
    //       config.stage.length > 7
    //         ? s.stage_name.slice(0, 12) + "..."
    //         : s.stage_name
    //     )
    //     .style("font-size", "0.85rem")
    //     .style("font-weight", fontWeight)
    // );
    // console.log("stages", config.stage);
    // }
  }
};
D3OpenOpp.destroy = (el) => {
  // Cleaning code here
  d3.select(el).selectAll("svg").remove();
};

const isNotOlderThan90Days = (item) =>
  (new Date(item.closed_date).getTime() - new Date().getTime()) /
    (1000 * 3600 * 24) >=
  -90;
export default D3OpenOpp;
