import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "./DrilldownBody.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import GetApp from "@material-ui/icons/GetApp";
import HoverableIcon from "../../components/Buttons/HoverableIcon ";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    // fontSize: '15px',
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    // fontSize: '14px',
    whiteSpace: "normal",
  },
  titleContainer: {
    width: "100%",
  },
}));

const dateAndTimeFormatter = (params) => {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(params.value));
};

const DrilldownBody = (props) => {
  console.log(props);
  const classes = useStyle();

  const containerHeight = props.body
    ? props.body.recordset[0].length * 50 < window.innerHeight - 300
      ? "-"
      : window.innerHeight - 300
    : 500;
  const domLayout = props.body
    ? props.body.recordset[0].length * 50 < window.innerHeight - 300
      ? "autoHeight"
      : "normal"
    : "normal";
  const defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  let gridApi, columnApi, params;

  const gridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params = params;
  };
  const onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };
  const exportCSV = (params) => {
    gridApi.exportDataAsCsv();
  };

  return (
    <Grid container className={classes.root}>
      {props.body ? (
        <React.Fragment>
          <Grid
            container
            justify="space-betweeen"
            className={classes.titleContainer}
            xs={12}
          >
            <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
            >
              {props.isMetaData ? (
                <>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: "600", padding: "0.3rem" }}
                  >
                    <>Added in the past {props.metaData[3].value} weeks</>
                    &nbsp;&nbsp;&nbsp;&nbsp; # of Opps:{" "}
                    <span data-test="baseInfo-count">
                      {d3.format(",")(props.body.recordsets[0][0].count)}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;ACV: $
                    <span data-test="baseInfo-acv">
                      {d3.format(",")(
                        Math.round(props.body.recordsets[0][0].acv)
                      )}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <>
                      Avg. deal size: $
                      <span data-test="baseInfo-avgAcv">
                        {d3.format(",")(
                          Math.round(props.body.recordsets[0][0].avg)
                        )}
                      </span>
                    </>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trailing
                    average&nbsp;&nbsp;&nbsp;
                    <>
                      # of Opps:{" "}
                      <span data-test="averageInfo-count">
                        {props.body.recordsets[0][0].count_avg.toFixed(1)}
                      </span>
                    </>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    <>
                      ACV: $
                      <span data-test="averageInfo-acv">
                        {d3.format(",")(
                          Math.round(props.body.recordsets[0][0].acv_avg)
                        )}
                      </span>
                    </>
                  </Typography>
                </>
              ) : (
                <Typography variant="body1" style={{ fontWeight: "600" }}>
                  Number of opportunities:{" "}
                  <span data-test="count">
                    {d3.format(",")(props.body.recordsets[0][0].count)}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ACV: $
                  <span data-test="acv">
                    {d3.format(",")(
                      Math.round(props.body.recordsets[0][0].acv)
                    )}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
              )}
            </Grid>

            <Grid
              direction="column"
              item
              container
              xs={2}
              justify="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
            >
              <Grid item justify="flex-end" style={{ paddingBottom: "1rem" }}>
                {/* <Button variant="contained"
                                    color="primary" onClick={exportCSV}><GetApp /></Button> */}
                <button style={{ border: "none" }} onClick={exportCSV}>
                  <HoverableIcon />
                </button>
              </Grid>
            </Grid>
          </Grid>

          <div
            className="ag-theme-custom-react_DB"
            style={{
              height: containerHeight,
              width: "100%",
            }}
          >
            <AgGridReact
              columnDefs={[
                {
                  headerName: "#",
                  field: "OppNo",
                  type: "rightAligned",
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "3px",
                  },
                  headerClass: classes.tableHeader,
                  maxWidth: 45,
                },
                {
                  headerName: "Date & Time",
                  field: "stage_change_date",
                  //minWidth: 100,
                  flex: 1.3,
                  suppressSizeToFit: true,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                  valueFormatter: dateAndTimeFormatter,
                },
                {
                  headerName: "Account",
                  field: "Account_Name",
                  //minWidth: 100,
                  flex: 1.5,
                  sortable: true,
                  suppressSizeToFit: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Customer Type",
                  field: "Cust_Type",
                  //minWidth: 100,
                  flex: 1.2,
                  suppressSizeToFit: true,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Opp Name",
                  field: "OppName",
                  //minWidth: 100,
                  flex: 1.8,
                  suppressSizeToFit: true,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "From Stage",
                  //minWidth: 80,
                  flex: 0.8,
                  field: "fromStage",
                  type: "rightAligned",
                  suppressSizeToFit: true,
                  sortable: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "To Stage",
                  //minWidth: 80,
                  flex: 0.8,
                  field: "toStage",
                  type: "rightAligned",
                  suppressSizeToFit: true,
                  sortable: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "ACV at change",
                  // minWidth: 60,
                  flex: 1,
                  field: "acvAtStage",
                  type: "rightAligned",
                  suppressSizeToFit: true,
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  sortingOrder: ["desc", "asc"],
                  valueFormatter: (x) => {
                    return "$ " + d3.format(",")(Math.round(x.value));
                  },
                },
                {
                  headerName: "Projected Close Date",
                  //minWidth: 80,
                  flex: 1.1,
                  field: "Projected_Close_Date",
                  suppressSizeToFit: true,
                  type: "rightAligned",
                  sortable: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  valueFormatter: (x) => {
                    const mainParts = x.data.Projected_Close_Date.split("T");
                    const subParts = mainParts[0].split("-");
                    return (
                      parseInt(subParts[1]) +
                      "/" +
                      parseInt(subParts[2]) +
                      "/" +
                      parseInt(subParts[0])
                    );
                  },
                },
                {
                  headerName: "Solution Category",
                  //minWidth: 80,
                  flex: 1,
                  field: "Solution_Cat",
                  type: "rightAligned",
                  suppressSizeToFit: true,
                  sortable: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Owner",
                  field: "Owner_Name",
                  //minWidth: 80,
                  flex: 1.2,
                  suppressSizeToFit: true,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Team",
                  resizable: false,
                  field: "Team",
                  suppressSizeToFit: true,
                  //minWidth: 80,
                  flex: 1,
                  sortable: true,
                  filter: true,
                  resizable: false,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
              ]}
              sizeColumnsToFit={true}
              domLayout={domLayout}
              headerHeight="55"
              rowData={props.body.recordset[0]}
              onFirstDataRendered={onFirstDataRendered}
              // onCellClicked={this.onCellClicked.bind(this)}
              // pagination={true}
              // paginationPageSize={6}
              defaultColDef={defaultColDef}
              onColumnResized={
                props.body.recordset[0].length <= 10 ? onColumnResized : null
              }
              enableCellTextSelection={true}
              // autoHeight={true}
              colResizeDefault={"shift"}
              debounceVerticalScrollbar={true}
              onGridReady={gridReady}
              // cellClass='cell-wrap-text'
            ></AgGridReact>
          </div>
        </React.Fragment>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height={280}
          style={{ margin: "20px 0px" }}
        />
      )}
    </Grid>
  );
};

export default DrilldownBody;
