import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

const ForgotPasswordForm = ({
  onSubmit,
  classes,
  errorMessage,
  open,
  setErrorMessage,
  handleChangeLocation,
}) => (
  <Formik
    initialValues={{ email: "" }}
    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email("Must be a valid email.")
        .max(255)
        .required("Email is required."),
    })}
    onSubmit={onSubmit}
  >
    {({
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      touched,
      values,
      isValid,
      dirty,
    }) => (
      <form noValidate onSubmit={handleSubmit} className={classes.formStyle}>
        {errorMessage && (
          <Collapse in={open}>
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setErrorMessage("")}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <Typography variant="body1">{errorMessage}</Typography>
            </Alert>
          </Collapse>
        )}
        <TextField
          error={Boolean(touched.email && errors.email)}
          fullWidth
          helperText={touched.email && errors.email}
          label="Email Address"
          margin="normal"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          variant="outlined"
        />
        <Box mt={2}>
          <Button
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!isValid || !dirty} // Button is disabled if the form is invalid or untouched
          >
            Send Code
          </Button>
        </Box>
        <Box mt={2} className={classes.backButtonContainer}>
          <Button
            size="small"
            variant="small"
            onClick={() => handleChangeLocation("login")}
            className={classes.backButton}
          >
            Cancel
          </Button>
        </Box>
      </form>
    )}
  </Formik>
);

export default ForgotPasswordForm;
