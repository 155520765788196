import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";
import {
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  percentFormatter,
  dateFormatterMomentV4,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";

import StyledTableRow from "../../components/StyledTableRow";
import * as d3 from "d3";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import { replaceAll } from "../../components/xlsx-export/LabelCange";
// import RepBenchMarkExcelExportButton from "../../components/xlsx-export/RepBenchMark";
const useStyle = makeStyles((theme) => ({
  root: {},
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    width: "100%",
  },
  colHeader: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 7px",
    color: "inherit",
    fontWeight: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader1: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function LowMIdHighTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();

  console.log(props.showDifference, props, "Difference");
  const ast = (x) => (
    <>
      <span>{x}</span> <span style={{ color: "#ed7d31" }}>*</span>
    </>
  );
  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        <Grid container justify="center">
          <TableContainer style={{ width: "auto" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
            <Table
              ref={tableRef}
              data-test={"pipeline"}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
            >
              <TableHead>
                <TableRow
                  style={{ fontWeight: 600, paddingLeft: 5, height: 50 }}
                >
                  <TableCell
                    align="center"
                    className={classes.repHeader1}
                    colSpan={2}
                  >
                    As of{" "}
                    {props.quarterLastDate !== undefined
                      ? dateFormatterMomentV4(props.quarterLastDate)
                      : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.repHeader1}
                    colSpan={3}
                    style={{
                      background: "#5b9bd5",
                      color: "white",
                      minWidth: "40px",
                      maxWidth: "200px",
                    }}
                  >
                    80% confidence
                  </TableCell>
                </TableRow>
                <TableRow style={{ fontWeight: 600 }}>
                  <TableCell
                    align="center"
                    className={`${classes.repHeader1} ${classes.fontBold}`}
                    colSpan={2}
                  >
                    Category
                  </TableCell>
                  <React.Fragment>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                      style={{ minWidth: 100 }}
                    >
                      Low
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                      style={{ minWidth: 100 }}
                    >
                      Midpoint
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.repHeader1}
                      colSpan={1}
                      style={{ minWidth: 100 }}
                    >
                      High
                    </TableCell>
                  </React.Fragment>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.winAcvData.map((row, i) => {
                  const category = row.category.split(":");
                  return (
                    <StyledTableRow key={i}>
                      {category[0] === "Total" ? (
                        <>
                          <TableCell
                            align="left"
                            className={`${classes.repText} ${classes.fontBold}`}
                            colSpan={2}
                            style={
                              category[0] === "Total" ? { height: 50 } : {}
                            }
                          >
                            {category[0]}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell
                            align="left"
                            className={`${classes.repNumber} ${classes.fontBold}`}
                            colSpan={1}
                          >
                            {category[0]}:
                          </TableCell>
                          <TableCell
                            align="left"
                            className={`${classes.repText} ${classes.fontBold}`}
                            style={{ maxWidth: "390px" }}
                            colSpan={1}
                            id="rep"
                          >
                            {category[1].trim() ===
                              "Already Won (as of the date above)" &&
                            props.showDifference
                              ? ast(
                                  replaceAll(
                                    category[1],
                                    "acv",
                                    `${props.Value_Label}`
                                  )
                                )
                              : replaceAll(
                                  category[1],
                                  "acv",
                                  `${props.Value_Label}`
                                )}
                          </TableCell>
                        </>
                      )}

                      <React.Fragment key={i}>
                        <TableCell
                          align="right"
                          className={classes.repHeader}
                          style={
                            category[0] === "Total"
                              ? {
                                  background: "#C6e0b4",
                                  height: 50,
                                  fontWeight: 600,
                                }
                              : {}
                          }
                          colSpan={1}
                        >
                          {row !== undefined
                            ? countOrACVFormatter(Math.round(row["low"]), true)
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.repHeader}
                          style={
                            category[0] === "Total"
                              ? {
                                  background: "#548235",
                                  color: "white",
                                  height: 50,
                                  fontWeight: 600,
                                }
                              : {}
                          }
                          colSpan={1}
                        >
                          {row !== undefined
                            ? countOrACVFormatter(Math.round(row["mid"]), true)
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.repHeader}
                          style={
                            category[0] === "Total"
                              ? {
                                  background: "#C6e0b4",
                                  height: 50,
                                  fontWeight: 600,
                                }
                              : {}
                          }
                          colSpan={1}
                        >
                          {row !== undefined
                            ? countOrACVFormatter(Math.round(row["high"]), true)
                            : "-"}
                        </TableCell>
                      </React.Fragment>
                    </StyledTableRow>
                  );
                })}

                {props.showDifference && (
                  <TableRow>
                    <TableCell
                      align="left"
                      className={`${classes.repText} ${classes.fontBold}`}
                      colSpan={2}
                      style={{
                        border: "none",
                        background: "white",
                        paddingTop: "1rem",
                        paddingLeft: "0rem",
                      }}
                    >
                      <span style={{ color: "#ED7D31" }}>*</span>&nbsp;{" "}
                      {countOrACVFormatter(props.closedWonAfterOffset, true)}{" "}
                      additional ACV won after{" "}
                      {props.quarterLastDate !== undefined
                        ? dateFormatterMomentV4(props.quarterLastDate)
                        : ""}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
