import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  percentageFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const KeyMetricsPartnerDealsTable = (props) => {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  let total;

  var uniqueQuarters = props.partenerDetailsData
    .map((s) => s[props.quarter])
    .filter(getUniqueValues)
    .filter((i) => i !== "Total")
    .sort();
  const uniquePartnerChannel = props.partenerDetailsData
    .map((s) => s.Partner_Channel__c)
    .filter(getUniqueValues);
  const qtrs = props.partenerDetailsData
    .map((s) => s[props.quarter])
    .filter(getUniqueValues)
    .filter((i) => i !== "Total")
    .sort();
  if (uniqueQuarters.length > 1) {
    uniqueQuarters = [...uniqueQuarters, "Total"];
  }

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainermetric");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);
  return (
    <div style={{ maxWidth: "98%", justifyContent: "center", padding: "2rem" }}>
      <Grid xs={12}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{
          width: "auto",
          borderLeft: isSticky ? "0.5px solid lightgrey" : "",
        }}
        id="myTableContainermetric"
        ref={tableContainerRef}
      >
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.type}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow
              style={{ fontWeight: 600, paddingLeft: 5 }}
              className={classes.fontBold}
            >
              <TableCell
                align="center"
                // className={classes.repHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.repHeader}`
                    : classes.repHeader
                }
                rowSpan={2}
                style={{ minWidth: 150 }}
              >
                {getMixKey(props.quarter)}
              </TableCell>
              {uniqueQuarters.map((i, index) => {
                return (
                  <TableCell
                    colSpan={2}
                    key={i}
                    align="center"
                    style={
                      index % 2 === 0
                        ? {
                            background: "#4471c4",
                            color: "white",
                            width: "13rem",
                          }
                        : {
                            background: "#5b9bd5",
                            color: "white",
                            width: "13rem",
                          }
                    }
                    className={classes.repHeader}
                  >
                    {i}
                  </TableCell>
                );
              })}

              {uniqueQuarters.length === 0 && (
                <TableCell
                  rowSpan={8}
                  className={classes.repHeader}
                  style={{ width: 200, textAlign: "center" }}
                >
                  No data available
                </TableCell>
              )}
            </TableRow>
            <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
              {uniqueQuarters.map((f) => {
                return (
                  <>
                    <TableCell
                      align="center"
                      style={{ minWidth: 50 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      # of Opps
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 50 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      ACV
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
            {uniquePartnerChannel.map((partner, i) => {
              total = i + 1;
              return (
                <StyledTableRow>
                  <TableCell
                    align="left"
                    // className={classes.cellStyle}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.cellStyle}`
                        : classes.cellStyle
                    }
                    style={
                      isSticky && i % 2 === 0 ? { background: "#f5f5f5" } : {}
                    }
                    colSpan={1}
                  >
                    {partner}
                  </TableCell>
                  {uniqueQuarters.map((q) => {
                    const rowData = props.partenerDetailsData.filter(
                      (i) =>
                        i[props.quarter] === q &&
                        i.Partner_Channel__c === partner
                    );
                    const acv = rowData.length === 0 ? 0 : rowData[0].acv;
                    const count = rowData.length === 0 ? 0 : rowData[0].count;
                    const cfq = rowData[0].closed_fiscal_quarter;
                    console.log();
                    return (
                      <>
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                quarter: q === "Total" ? qtrs : [q],
                                partner: [partner],
                              },
                              "Partner"
                            )
                          }
                          className={
                            cfq === "Total"
                              ? classes.cellHeader
                              : classes.cellNormal
                          }
                          colSpan={1}
                        >
                          {![null, undefined, ""].includes(count)
                            ? countOrACVFormatter(count, false)
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                quarter: q === "Total" ? qtrs : [q],
                                partner: [partner],
                              },
                              "Partner"
                            )
                          }
                          className={
                            cfq === "Total"
                              ? classes.cellHeader
                              : classes.cellNormal
                          }
                          colSpan={1}
                        >
                          {![null, undefined, ""].includes(acv)
                            ? countOrACVFormatter(acv, true)
                            : "-"}
                        </TableCell>
                      </>
                    );
                  })}
                </StyledTableRow>
              );
            })}

            {props.totalData.length >= 1 ? (
              <>
                <StyledTableRow>
                  <TableCell
                    align="left"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={
                      isSticky && total % 2 === 0
                        ? { background: "#f5f5f5" }
                        : {}
                    }
                  >
                    {"Total"}
                  </TableCell>
                  {uniqueQuarters.map((q) => {
                    const rowData = props.totalData.filter(
                      (i) => i[props.quarter] === q
                    );
                    const count = rowData.length === 0 ? 0 : rowData[0].count;
                    const acv = rowData.length === 0 ? 0 : rowData[0].acv;
                    return (
                      <>
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                quarter: q === "Total" ? qtrs : [q],
                                partner: uniquePartnerChannel,
                              },
                              "Partner"
                            )
                          }
                          className={classes.cellHeader}
                          colSpan={1}
                        >
                          {![null, undefined, ""].includes(count)
                            ? countOrACVFormatter(count, false)
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                quarter: q === "Total" ? qtrs : [q],
                                partner: uniquePartnerChannel,
                              },
                              "Partner"
                            )
                          }
                          className={classes.cellHeader}
                          colSpan={1}
                        >
                          {![null, undefined, ""].includes(acv)
                            ? countOrACVFormatter(acv, true)
                            : "-"}
                        </TableCell>
                      </>
                    );
                  })}
                </StyledTableRow>
              </>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default KeyMetricsPartnerDealsTable;
