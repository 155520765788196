import { combineReducers } from "redux";

import { app } from "../App/app.reducer";
import ScoreCardReducer from "../RepPerformance/ScoreCard/core/reducer";
import ManagementReducer from "../Settings/QuotaMaintainence/redux/management.slice";
const rootReducer = combineReducers({
  app,
  ScoreCard: ScoreCardReducer,
  management: ManagementReducer,
});

export default rootReducer;
