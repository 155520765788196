import React, { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";

const ProductsByAccountComponent = (props) => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await localStorage.setItem("skygeni-user-page", "Enhanced Insights");
      await localStorage.setItem(
        "skygeni-user-enhancedPage",
        "White Space Analysis + Products by Account"
      );
      await localStorage.removeItem("skygeni-user-repPage");
      history.push("/");
      window.location.reload();
    })();
  }, []);

  return <div style={{ margin: "1rem auto" }}>Loading...</div>;
};
let routerAddedProductsByAccount = withRouter(ProductsByAccountComponent);
export default routerAddedProductsByAccount;
