import React from "react";
import { SyncLoader } from "react-spinners";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "50px",
    margin: theme.spacing(1),
  },
}));

const ChatLoading = () => {
  const classes = useStyles();

  return (
    // <div className={classes.loadingContainer}>
    <SyncLoader
      color={"#007BFF"}
      cssOverride={{ marginBottom: 15 }}
      loading={true}
      height={14}
      width={4}
    />
    // </div>
  );
};

export default ChatLoading;
