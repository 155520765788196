import {
  Card,
  Grid,
  makeStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import * as d3 from "d3";
import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { StyledTableRow } from "../../util/customFunctions";
import { formatDataValuesNew, camelize } from "../../util/dataFormatter";
import { AgGridReact } from "ag-grid-react";
import "./closedFunnelTableV2.scss";
import GetApp from "@material-ui/icons/GetApp";
import { store } from "../../util/store";

const tooltipValueGetter = (params) => console.log(params);

const useStyle = makeStyles((theme) => ({
  tableHeader: {
    fontSize: "0.85rem",
    paddingRight: "5rem",
    fontFamily: "Roboto/ Helvetica/ Aria/ sans-serif",
    fontWeight: 600,
    color: "black",
    background: "white",
  },
  tableCell: {
    whiteSpace: "normal",
    padding: "5px",
    fontFamily: "Roboto/ Helvetica/ Aria/ sans-serif",
    fontSize: "0.85rem",
    fontWeight: 400,
    textAlign: "right",
    height: "31.8px !important",
  },
  tableHeader1: {
    color: "white",
    overflow: "visible",
    overflowWrap: "break-word",
    background: "rgb(198, 89, 17)",
  },
  tableHeader2: {
    color: "white",
    background: "rgb(112, 173, 71)",
  },
  tableCellstage: {
    whiteSpace: "normal",
    padding: "7px",
    fontFamily: "Roboto/ Helvetica/ Aria/ sans-serif",
    fontSize: "0.85rem",
    fontWeight: 400,
    textAlign: "left",
    height: "32px !important",
  },
  tableHeader3: {
    cursor: "pointer",
    fontSize: "0.85rem",
    fontFamily: "Roboto/ Helvetica/ Aria/ sans-serif",
    fontWeight: 600,
    color: "black",
    background: "white",
    border: "1px solid  !important",
    height: "48px",

    //   borderLeft:"1px solid black",
    //   borderRight:"1px solid black"
  },
  tableCellwhatif: {
    whiteSpace: "normal",
    padding: "5px",
    fontFamily: "Roboto/ Helvetica/ Aria/ sans-serif",
    fontSize: "0.85rem",
    fontWeight: 400,
    textAlign: "right",
    height: "34px !important",
  },
  tableHeaderCom: {
    background: "rgb(68,114,196)",
    color: "white",
  },
  stageHeader: {
    background: "red",
  },
}));

export default function FunnelTableClosedV2(props) {
  const state = store.getState();
  const Currency_Symbol = state.app.user.Currency;

  console.log(props);
  const gridRef = useRef();
  const classes = useStyle();
  let initialData;
  let data = props.data;

  console.log(data);

  data = data.map((d) => {
    return {
      ...d,
      label: d.label,
      actualAcv: d.acv,
      whatifAcv: d.acv,
      actualLost: d.lostFromStage,
      whatifLost: d.lostFromStage,
      actualMoved: d.movedToNextStage,
      whatifMoved: d.movedToNextStage,
      actualStagetostage: d.stageToStageConversion,
      whatifStagetostage: d.stageToStageConversion,
      actualWinperc: d.winRatePercentage,
      whatifWinPerc: d.winRatePercentage,
      acvRate: d.acvRate,
      companyAvgWinRate: d.companyAvgWinRate,
      diffRate: d.diffRate,
      diffacvRate: d.diffacvRate,
      rate: d.rate,
      count: d.count,
      //    diffRateCompany:d.diffRateCompany
    };
  });

  data = data.map((d) => {
    return {
      ...d,
      deltaAcv: d.whatifAcv - d.actualAcv,
      deltaLost: d.whatifLost - d.actualLost,
      deltaMoved: d.whatifMoved - d.actualMoved,
      deltaStagetostage: d.whatifStagetostage - d.actualStagetostage,
      deltaWinPerc: d.whatifWinPerc - d.actualWinperc,
    };
  });

  data[data.length] = {
    actualAcv: "-",
    actualLost: "-",
    actualMoved: "-",
    actualStagetostage: "-",
    actualWinperc: "-",
    acv: "-",
    acvRate: "-",
    companyAvgAcv: "-",
    companyAvgWinRate: "-",
    count: "-",
    deltaAcv: "-",
    deltaLost: "-",
    deltaMoved: "-",
    deltaStagetostage: "-",
    deltaWinPerc: "-",
    diffRate: "-",
    diffacvRate: "-",
    label: "-",
    lostFromStage: "-",
    movedToNextStage: "-",
    rate: "-",
    stageToStageConversion: "-",
    whatifAcv: "-",
    whatifLost: "-",
    whatifMoved: "-",
    whatifStagetostage: "-",
    whatifWinPerc: "-",
    winRatePercentage: "-",
  };

  console.log(data);

  const onGridReady = (params) => {
    console.log(params);
    // params.api.updateRowData({ add:[result], addIndex: 6 });
  };

  console.log(data);
  const [rowData, setRowData] = useState(data);

  let stageData = rowData;

  useEffect(() => {
    setRowData(data);
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      cellClass: "cell-wrap-text",
      suppressMovable: true,
      cellClass: "suppress-movable-col",
      containerWidth: "100%",
      colResizeDefault: "shift",
      getRowHeight: function (params) {
        return params.data.rowHeight;
      },
      // resizable:true,
    };
  }, []);

  //   const onBtnExport = useCallback(() => {
  //       console.log()
  //     gridRef.current.api.exportDataAsCsv();

  //   }, []);

  const formateacvValue = (x, Currency_Symbol) => {
    if (x.value === x.value && x.value !== "-" && x.value !== 0) {
      return `${Currency_Symbol}${d3.format(",")(Math.round(x.value))}`;
    }
    if (x.data.label === "-") {
      return " ";
    } else {
      return "-";
    }
  };

  const formatePerc = (x) => {
    if (x.value === x.value && x.value !== "-" && x.value !== 0) {
      return `${d3.format("")(Math.round(x.value))}%`;
    }
    if (x.data.label === "-") {
      return " ";
    } else {
      return "-";
    }
  };

  const formateLabel = (x) => {
    if (x.data.label === "-") {
      return " ";
    }
  };
  const formatedeltaPerc = (x) => {
    if (x.value === "-") {
      return "-";
    } else {
      return `${d3.format("")(Math.round(x.value))}%`;
    }
  };

  function isNaN(x) {
    return x !== x;
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "label",
      headerName: "Stage",
      cellClass: classes.tableCellstage,
      headerClass: classes.tableHeader,
      children: [
        {
          field: "label",
          headerName: " ",
          resizable: true,
          cellClass: classes.tableCellstage,
          flex: 2.0,
          headerClass: classes.tableHeader,
          valueFormatter: (x) => formateLabel(x),
          cellStyle: (x) => (x.data.label === "-" ? { border: "none" } : {}),
        },
      ],
    },
    {
      headername: "Came to Stage",
      field: "Came to Stage",
      cellClass: classes.tableCell,
      headerClass: classes.tableHeader,
      children: [
        {
          headerName: "Actual",
          field: "actualAcv",
          resizable: true,
          valueFormatter: (x) => formateacvValue(x, Currency_Symbol),
          flex: 1.2,
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader,
          cellStyle: (x) => (x.data.label === "-" ? { border: "none" } : {}),
        },
        {
          headerName: "What-if",
          field: "whatifAcv",
          resizable: true,
          flex: 1.2,
          valueFormatter: (x) => formateacvValue(x, Currency_Symbol),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader,
          cellStyle: (x) =>
            x.value < x.data.actualAcv
              ? { backgroundColor: "rgb(274, 162, 87)", fontWeight: 600 }
              : x.value > x.data.actualAcv
              ? { backgroundColor: "rgb(154, 203, 119)", fontWeight: 600 }
              : { backgroundColor: "inherit" },
        },
        {
          headerName: "Delta",
          field: "deltaAcv",
          resizable: true,
          flex: 1.2,
          valueFormatter: (x) =>
            x.data.label === "-"
              ? formatedeltaPerc(x)
              : formateacvValue(x, Currency_Symbol),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader,
          cellStyle: (x) =>
            x.value < 0
              ? { backgroundColor: "rgb(274, 162, 87)", fontWeight: 600 }
              : x.value > 0
              ? { backgroundColor: "rgb(154, 203, 119)", fontWeight: 600 }
              : { backgroundColor: "inherit" },
        },
      ],
    },
    {
      headername: "Lost / Disqualified from Stage",
      resizable: true,
      field: "Lost / Disqualified from Stage",
      cellClass: classes.tableCell,
      headerClass: classes.tableHeader1,
      children: [
        {
          headerName: "Actual",
          field: "actualLost",
          resizable: true,
          flex: 1.2,
          valueFormatter: (x) => formateacvValue(x, Currency_Symbol),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader1,
          cellStyle: (x) => (x.data.label === "-" ? { border: "none" } : {}),
        },
        {
          headerName: "What-if",
          field: "whatifLost",
          resizable: true,
          flex: 1.2,
          valueFormatter: (x) => formateacvValue(x, Currency_Symbol),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader1,
          cellStyle: (x) =>
            x.data.label === "-"
              ? { border: "none" }
              : x.value < x.data.actualLost
              ? { backgroundColor: "rgb(154, 203, 119)", fontWeight: 600 }
              : x.value > x.data.actualLost
              ? { backgroundColor: "rgb(274, 162, 87)", fontWeight: 600 }
              : { backgroundColor: "inherit" },
        },
        {
          headerName: "Delta",
          field: "deltaLost",
          resizable: true,
          flex: 1.2,
          valueFormatter: (x) => formateacvValue(x, Currency_Symbol),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader1,
          cellStyle: (x) =>
            x.data.label === "-"
              ? { border: "none" }
              : x.value < 0
              ? { backgroundColor: "rgb(154, 203, 119)", fontWeight: 600 }
              : x.value > 0
              ? { backgroundColor: "rgb(274, 162, 87)", fontWeight: 600 }
              : { backgroundColor: "inherit" },
          rowSpan: (params) => (params.data.label === "Closed Won" ? 2 : 3),
        },
      ],
    },
    {
      headername: "Moved to next stage",
      field: "Moved to next stage",
      cellClass: classes.tableCell,
      headerClass: classes.tableHeader2,
      children: [
        {
          headerName: "Actual",
          field: "actualMoved",
          flex: 1.2,
          valueFormatter: (x) => formateacvValue(x, Currency_Symbol),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader2,
          cellStyle: (x) => (x.data.label === "-" ? { border: "none" } : {}),
        },
        {
          headerName: "What-if",
          field: "whatifMoved",
          flex: 1.2,
          resizable: true,
          valueFormatter: (x) => formateacvValue(x, Currency_Symbol),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader2,
          cellStyle: (x) =>
            x.data.label === "-"
              ? { border: "none" }
              : x.value < x.data.actualMoved
              ? { backgroundColor: "rgb(274, 162, 87)", fontWeight: 600 }
              : x.value > x.data.actualMoved
              ? { backgroundColor: "rgb(154, 203, 119)", fontWeight: 600 }
              : { backgroundColor: "inherit" },
        },
        {
          headerName: "Delta",
          field: "deltaMoved",
          flex: 1.2,
          resizable: true,
          valueFormatter: (x) => formateacvValue(x, Currency_Symbol),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader2,
          cellStyle: (x) =>
            x.data.label === "-"
              ? { border: "none" }
              : x.value < 0
              ? { backgroundColor: "rgb(274, 162, 87)", fontWeight: 600 }
              : x.value > 0
              ? { backgroundColor: "rgb(154, 203, 119)", fontWeight: 600 }
              : { backgroundColor: "inherit" },
        },
      ],
    },
    {
      headername: "Stage to Stage Conversion",
      field: "Stage to Stage Conversion",
      cellClass: classes.tableCell,
      headerClass: classes.tableHeader,
      children: [
        {
          headerName: "Actual",
          field: "actualStagetostage",
          flex: 0.8,
          resizable: true,
          valueFormatter: (x) => formatePerc(x),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader,
          cellStyle: (x) => (x.data.label === "-" ? { border: "none" } : {}),
        },
        {
          headerName: "What-if",
          field: "whatifStagetostage",
          resizable: true,
          headerComponentParams: {
            template: `<div style="text-align:center;">
                  <p style="text-align:center;">What-if</p><p style="text-align:center;background:rgb(191, 143, 0);color:white;padding:2px">Edit Here</p>
           </div>`,
          },
          cellEditor: "agTextCellEditor",
          cellEditorPopup: true,
          cellEditorParams: {
            maxLength: 3,
            rows: 10,
            cols: 50,
          },
          editable: true,
          enableTooltip: true,
          headerTooltip: "Double-click on the cell you'd like to edit",
          flex: 0.9,
          valueFormatter: (x) => formatePerc(x),
          cellClass: classes.tableCellwhatif,
          headerClass: classes.tableHeader3,
          cellStyle: (x) =>
            x.data.label === data[data.length - 2].label
              ? { borderTop: "1px solid" }
              : x.data.label === "-"
              ? { border: "none" }
              : x.data.label !== data[data.length - 2].label &&
                parseInt(x.value) > x.data.actualStagetostage
              ? {
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  background: "rgb(154, 203, 119)",
                  fontWeight: 600,
                }
              : x.data.label !== data[data.length - 2].label &&
                parseInt(x.value) < x.data.actualStagetostage
              ? {
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  background: "rgb(274, 162, 87)",
                  fontWeight: 600,
                }
              : x.data.label !== data[data.length - 2].label
              ? { border: "1px solid !important", background: "inherit" }
              : "",
        },
        {
          headerName: "Delta",
          field: "deltaStagetostage",
          resizable: true,
          flex: 0.8,
          valueFormatter: (x) => formatePerc(x),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader,
          cellStyle: (x) =>
            x.data.label === "-"
              ? { border: "none" }
              : x.value < 0
              ? { backgroundColor: "rgb(274, 162, 87)", fontWeight: 600 }
              : x.value > 0
              ? { backgroundColor: "rgb(154, 203, 119)", fontWeight: 600 }
              : { backgroundColor: "inherit" },
        },
      ],
    },
    {
      headername: `${props.Win_Rate_Prefix} %`,
      field: `${props.Win_Rate_Prefix} %`,
      cellClass: classes.tableCell,
      headerClass: classes.tableHeader,
      resizable: false,
      children: [
        {
          headerName: "Actual",
          field: "actualWinperc",
          resizable: true,
          flex: 0.8,
          valueFormatter: (x) => formatePerc(x),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader,
          cellStyle: (x) => (x.data.label === "-" ? { border: "none" } : {}),
        },
        {
          headerName: "What-if",
          field: "whatifWinPerc",
          resizable: true,
          flex: 0.8,
          valueFormatter: (x) => formatePerc(x),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader,
          cellStyle: (x) =>
            x.data.label === "-"
              ? { border: "none" }
              : x.value < x.data.actualWinperc
              ? { backgroundColor: "rgb(274, 162, 87)", fontWeight: 600 }
              : x.value > x.data.actualWinperc
              ? { backgroundColor: "rgb(154, 203, 119)", fontWeight: 600 }
              : { backgroundColor: "inherit" },
        },
        {
          headerName: "Delta",
          field: "deltaWinPerc",
          resizable: true,
          flex: 0.8,
          valueFormatter: (x) => formatePerc(x),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader,
          cellStyle: (x) =>
            x.data.label === "-"
              ? { border: "none" }
              : x.value < 0
              ? { backgroundColor: "rgb(274, 162, 87)", fontWeight: 600 }
              : x.value > 0
              ? { backgroundColor: "rgb(154, 203, 119)", fontWeight: 600 }
              : { backgroundColor: "inherit" },
        },
      ],
    },
    {
      headername: "Companywide",
      field: "Companywide",
      resizable: false,
      cellClass: classes.tableCell,
      headerClass: classes.tableHeaderCom,
      cellStyle: {},
      children: [
        {
          headerName: "Stage to Stage Conversion",
          flex: 1,
          field: "companyAvgAcv",
          valueFormatter: (x) => formatePerc(x),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader,
          cellStyle: (x) => (x.data.label === "-" ? { border: "none" } : {}),
        },
        {
          headerName: `${props.Win_Rate_Prefix} %`,
          field: "companyAvgWinRate",
          resizable: false,
          valueFormatter: (x) => formatePerc(x),
          cellClass: classes.tableCell,
          headerClass: classes.tableHeader,
          cellStyle: (x) => (x.data.label === "-" ? { border: "none" } : {}),
        },
      ],
    },
  ]);

  const onCellValueChanged = (event) => {
    if (
      parseInt(event.newValue) > 100 ||
      parseInt(event.newValue) < 0 ||
      isNaN(parseInt(event.newValue)) === true
    ) {
      console.log(event);
      event.data.whatifStagetostage = event.oldValue;
    } else
      for (let i = 0; i < stageData.length; i++) {
        if (i === event.rowIndex) {
          stageData[i].deltaStagetostage =
            parseInt(stageData[i].whatifStagetostage) -
            stageData[i].actualStagetostage;
          stageData[i].whatifMoved =
            (stageData[i].whatifAcv *
              parseInt(stageData[i].whatifStagetostage)) /
            100;
          stageData[i].whatifLost =
            stageData[i].whatifAcv - stageData[i].whatifMoved;
          stageData[i].deltaAcv =
            stageData[i].whatifAcv - stageData[i].actualAcv;
          stageData[i].deltaLost =
            stageData[i].whatifLost - stageData[i].actualLost;
          stageData[i].deltaMoved =
            stageData[i].whatifMoved - stageData[i].actualMoved;

          console.log(
            parseInt(
              stageData[i].whatifStagetostage - stageData[i].actualStagetostage
            )
          );
        }
        if (i > event.rowIndex && i < stageData.length - 1) {
          stageData[i].deltaStagetostage =
            parseInt(stageData[i].whatifStagetostage) -
            stageData[i].actualStagetostage;
          stageData[i].whatifAcv = stageData[i - 1].whatifMoved;
          stageData[i].whatifMoved =
            (stageData[i].whatifAcv * stageData[i].whatifStagetostage) / 100;
          stageData[i].whatifLost =
            stageData[i].whatifAcv - stageData[i].whatifMoved;
          stageData[i].deltaAcv =
            stageData[i].whatifAcv - stageData[i].actualAcv;
          stageData[i].deltaLost =
            stageData[i].whatifLost - stageData[i].actualLost;
          stageData[i].deltaMoved =
            stageData[i].whatifMoved - stageData[i].actualMoved;
        }
        if (i === stageData.length - 2) {
          for (let k = 0; k < stageData.length; k++) {
            stageData[k].whatifWinPerc =
              (stageData[i].whatifAcv / stageData[k].whatifAcv) * 100;
            stageData[k].deltaWinPerc = Math.round(
              stageData[k].whatifWinPerc - stageData[k].actualWinperc
            );

            if (k < stageData.length - 2) {
              stageData[k + 1].diffacvRate =
                parseInt(stageData[k].whatifStagetostage) / 100;
            }
            if (k < stageData.length - 1) {
              stageData[k].acvRate =
                stageData[k].whatifAcv / stageData[0].whatifAcv;
            }
          }
        }

        if (i === stageData.length - 1) {
          stageData[i].deltaAcv =
            (stageData[i - 1].deltaAcv / stageData[i - 1].actualAcv) * 100;

          console.log(stageData[i]);
        }
      }

    props.getdataValue(stageData);
    event.api.setRowData(stageData);
    console.log(rowData);
  };

  return (
    <Grid item xs={12} component={Card} justify="center" container>
      <Grid
        item
        xs={12}
        id="grid-container"
        className="closed_funnel_table_v2"
        style={{ padding: "1.1rem" }}
      >
        {/* <Grid item justify="flex-end" align='end' style={{ paddingBottom: '0.5rem'}}>
                        <Button variant="contained"
                            color="primary" onClick={onBtnExport}><GetApp /></Button>
                    </Grid> */}
        <AgGridReact
          style={{ width: "100%" }}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          //   getRowId={getRowId}
          onGridReady={onGridReady}
          readOnlyEdit={true}
          onCellValueChanged={onCellValueChanged}
          //   enableCellTextSelection={true}
          headerHeight={45}
          domLayout={"autoHeight"}
          rowHeight={32}
          //   enableBrowserTooltips={true}
          tooltipShowDelay={-0}
          tooltipHideDelay={2000}
          colResizeDefault={"shift"}
          suppressExcelExport={true}
        ></AgGridReact>
      </Grid>
    </Grid>
  );
}
