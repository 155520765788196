import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  percentageFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

export default function KeyMetricsTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  let countinc = 0;

  const uniqueAccountSegments = props.accountSegmentRollupData
    .map((s) => s.Acct_Segment_Rollup)
    .filter(getUniqueValues);
  const uniqueOppTypes = props.accountSegmentRollupData
    .map((s) => s.Booking_Type_NL_CS_US)
    .filter(getUniqueValues);
  var uniqueQuarters = props.accountSegmentRollupData
    .map((s) => s[props.quarter])
    .filter(getUniqueValues)
    .filter((i) => i !== "Total")
    .sort();
  const qtrs = props.accountSegmentRollupData
    .map((s) => s[props.quarter])
    .filter(getUniqueValues)
    .filter((i) => i !== "Total")
    .sort();
  if (uniqueQuarters.length > 1) {
    uniqueQuarters = [...uniqueQuarters, "Total"];
  }

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer-Second");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);
  return (
    <div style={{ maxWidth: "98%", justifyContent: "center", padding: "2rem" }}>
      <Grid xs={12}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{
          width: "auto",
          borderLeft: isSticky ? "0.5px solid lightgrey" : "",
          // padding: "2rem",
        }}
        id="myTableContainer-Second"
        ref={tableContainerRef}
      >
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.type}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow
              style={{ fontWeight: 600, paddingLeft: 5 }}
              className={classes.fontBold}
            >
              <TableCell
                align="center"
                // className={classes.repHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.repHeader}`
                    : classes.repHeader
                }
                rowSpan={2}
                style={{ minWidth: 150 }}
              >
                {getMixKey(props.quarter)}
              </TableCell>
              {uniqueQuarters.map((i, index) => {
                return (
                  <TableCell
                    colSpan={3}
                    key={i}
                    align="center"
                    style={
                      index % 2 === 0
                        ? { background: "#4471c4", color: "white" }
                        : { background: "#5b9bd5", color: "white" }
                    }
                    className={classes.repHeader}
                  >
                    {i}
                  </TableCell>
                );
              })}

              {uniqueQuarters.length === 0 && (
                <TableCell
                  rowSpan={8}
                  className={classes.repHeader}
                  style={{ width: 200, textAlign: "center" }}
                >
                  No data available
                </TableCell>
              )}
            </TableRow>
            <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
              {uniqueQuarters.map((i) => {
                return (
                  <React.Fragment key={i}>
                    <TableCell
                      align="center"
                      style={{ minWidth: 50, maxWidth: 50 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      # of Opps
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 90, maxWidth: 90 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      {props.type === "attainment" ? "ACV" : "Avg. Deal Size"}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 120, maxWidth: 120 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      {props.type === "attainment" ? (
                        <>
                          Win / Win + Lost <br />( No. of opps )
                        </>
                      ) : (
                        <>Avg. Qualifying to Win Cycle Time</>
                      )}
                    </TableCell>
                  </React.Fragment>
                );
              })}
            </TableRow>
            {uniqueOppTypes.map((oppType) => {
              countinc = countinc + 1;
              return (
                <>
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      // className={classes.repHeader}
                      className={
                        isSticky
                          ? `${classes.sticky} ${classes.repHeader}`
                          : classes.repHeader
                      }
                      style={
                        isSticky && countinc % 2 === 1
                          ? { background: "#f5f5f5", paddingLeft: "25px" }
                          : { paddingLeft: "25px" }
                      }
                      colSpan={1}
                    >
                      {oppType}
                    </TableCell>
                    {uniqueQuarters.map((q) => {
                      const rowData = props.oppData.filter(
                        (i) =>
                          i[props.quarter] === q &&
                          i.Booking_Type_NL_CS_US === oppType
                      );
                      const count = rowData.length === 0 ? 0 : rowData[0].count;
                      const acv = rowData.length === 0 ? 0 : rowData[0].acv;
                      const daysOrPercent =
                        rowData.length === 0
                          ? 0
                          : rowData[0][
                              props.type === "attainment" ? "perc" : "days"
                            ];
                      return (
                        <>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  quarter: q === "Total" ? qtrs : [q],
                                  oppType: [oppType],
                                  segment: uniqueAccountSegments,
                                },
                                props.type
                              )
                            }
                            className={classes.cellHeader}
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(count)
                              ? countOrACVFormatter(count, false)
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  quarter: q === "Total" ? qtrs : [q],
                                  oppType: [oppType],
                                  segment: uniqueAccountSegments,
                                },
                                props.type
                              )
                            }
                            className={classes.cellHeader}
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(acv)
                              ? countOrACVFormatter(acv, true)
                              : "-"}
                          </TableCell>
                          {props.type === "attainment" ? (
                            <TableCell
                              align="right"
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              {![null, undefined, ""].includes(daysOrPercent)
                                ? props.type === "attainment"
                                  ? percentageFormatter(daysOrPercent)
                                  : Math.round(daysOrPercent)
                                : "-"}
                            </TableCell>
                          ) : (
                            <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  {
                                    quarter: q === "Total" ? qtrs : [q],
                                    oppType: [oppType],
                                    segment: uniqueAccountSegments,
                                  },
                                  props.type
                                )
                              }
                              className={classes.cellHeader}
                              colSpan={1}
                            >
                              {![null, undefined, ""].includes(daysOrPercent)
                                ? props.type === "attainment"
                                  ? percentageFormatter(daysOrPercent)
                                  : Math.round(daysOrPercent)
                                : "-"}
                            </TableCell>
                          )}
                        </>
                      );
                    })}
                  </StyledTableRow>
                  {uniqueAccountSegments.map((segment) => {
                    countinc = countinc + 1;
                    return (
                      <>
                        <StyledTableRow>
                          <TableCell
                            align="left"
                            style={{ textIndent: 25 }}
                            // className={classes.cellStyle}
                            className={
                              isSticky
                                ? `${classes.sticky} ${classes.cellStyle}`
                                : classes.cellStyle
                            }
                            style={
                              isSticky && countinc % 2 === 1
                                ? { background: "#f5f5f5", textIndent: 25 }
                                : { textIndent: 25 }
                            }
                            colSpan={1}
                          >
                            {segment}
                          </TableCell>
                          {uniqueQuarters.map((q) => {
                            const rowData =
                              props.accountSegmentRollupData.filter(
                                (i) =>
                                  i[props.quarter] === q &&
                                  i.Acct_Segment_Rollup === segment &&
                                  i.Booking_Type_NL_CS_US === oppType
                              );
                            const count =
                              rowData.length === 0 ? 0 : rowData[0].count;
                            const acv =
                              rowData.length === 0 ? 0 : rowData[0].acv;
                            const daysOrPercent =
                              rowData.length === 0
                                ? 0
                                : rowData[0][
                                    props.type === "attainment"
                                      ? "perc"
                                      : "days"
                                  ];
                            return (
                              <>
                                <TableCell
                                  align="right"
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        quarter: q === "Total" ? qtrs : [q],
                                        oppType: [oppType],
                                        segment: [segment],
                                      },
                                      props.type
                                    )
                                  }
                                  className={classes.cellNormal}
                                  colSpan={1}
                                >
                                  {![null, undefined, ""].includes(count)
                                    ? countOrACVFormatter(count, false)
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        quarter: q === "Total" ? qtrs : [q],
                                        oppType: [oppType],
                                        segment: [segment],
                                      },
                                      props.type
                                    )
                                  }
                                  className={classes.cellNormal}
                                  colSpan={1}
                                >
                                  {![null, undefined, ""].includes(acv)
                                    ? countOrACVFormatter(acv, true)
                                    : "-"}
                                </TableCell>
                                {props.type === "attainment" ? (
                                  <TableCell
                                    align="right"
                                    className={classes.cellStyle}
                                    colSpan={1}
                                  >
                                    {![null, undefined, ""].includes(
                                      daysOrPercent
                                    )
                                      ? props.type === "attainment"
                                        ? percentageFormatter(daysOrPercent)
                                        : Math.round(daysOrPercent)
                                      : "-"}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="right"
                                    onClick={() =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        {
                                          quarter: q === "Total" ? qtrs : [q],
                                          oppType: [oppType],
                                          segment: [segment],
                                        },
                                        props.type
                                      )
                                    }
                                    className={classes.cellNormal}
                                    colSpan={1}
                                  >
                                    {![null, undefined, ""].includes(
                                      daysOrPercent
                                    )
                                      ? props.type === "attainment"
                                        ? percentageFormatter(daysOrPercent)
                                        : Math.round(daysOrPercent)
                                      : "-"}
                                  </TableCell>
                                )}
                              </>
                            );
                          })}
                        </StyledTableRow>
                      </>
                    );
                  })}
                </>
              );
            })}
            {props.totalData.length >= 1 ? (
              <>
                <StyledTableRow>
                  <TableCell
                    align="left"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    style={
                      isSticky && (countinc + 1) % 2 === 1
                        ? { background: "#f5f5f5", paddingLeft: "25px" }
                        : { paddingLeft: "25px" }
                    }
                    colSpan={1}
                  >
                    {"Total"}
                  </TableCell>

                  {uniqueQuarters.map((q) => {
                    const rowData = props.totalData.filter(
                      (i) => i[props.quarter] === q
                    );
                    const count = rowData.length === 0 ? 0 : rowData[0].count;
                    const acv = rowData.length === 0 ? 0 : rowData[0].acv;
                    const daysOrPercent =
                      rowData.length === 0
                        ? 0
                        : rowData[0][
                            props.type === "attainment" ? "perc" : "days"
                          ];
                    return (
                      <>
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                quarter: q === "Total" ? qtrs : [q],
                                oppType: uniqueOppTypes,
                                segment: uniqueAccountSegments,
                              },
                              props.type
                            )
                          }
                          className={classes.cellHeader}
                          colSpan={1}
                        >
                          {![null, undefined, ""].includes(count)
                            ? countOrACVFormatter(count, false)
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              {
                                quarter: q === "Total" ? qtrs : [q],
                                oppType: uniqueOppTypes,
                                segment: uniqueAccountSegments,
                              },
                              props.type
                            )
                          }
                          className={classes.cellHeader}
                          colSpan={1}
                        >
                          {![null, undefined, ""].includes(acv)
                            ? countOrACVFormatter(acv, true)
                            : "-"}
                        </TableCell>
                        {props.type === "attainment" ? (
                          <TableCell
                            align="right"
                            className={classes.repHeader}
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(daysOrPercent)
                              ? props.type === "attainment"
                                ? percentageFormatter(daysOrPercent)
                                : Math.round(daysOrPercent)
                              : "-"}
                          </TableCell>
                        ) : (
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  quarter: q === "Total" ? qtrs : [q],
                                  oppType: uniqueOppTypes,
                                  segment: uniqueAccountSegments,
                                },
                                props.type
                              )
                            }
                            className={classes.cellHeader}
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(daysOrPercent)
                              ? props.type === "attainment"
                                ? percentageFormatter(daysOrPercent)
                                : Math.round(daysOrPercent)
                              : "-"}
                          </TableCell>
                        )}
                      </>
                    );
                  })}
                </StyledTableRow>
                {uniqueAccountSegments.map((segment, k) => {
                  return (
                    <>
                      <StyledTableRow>
                        <TableCell
                          align="left"
                          // style={{ textIndent: 25 }}
                          // className={classes.cellStyle}
                          className={
                            isSticky
                              ? `${classes.sticky} ${classes.cellStyle}`
                              : classes.cellStyle
                          }
                          style={
                            isSticky && k % 2 === 1
                              ? { background: "#f5f5f5", textIndent: 25 }
                              : { textIndent: 25 }
                          }
                          colSpan={1}
                        >
                          {segment}
                        </TableCell>
                        {uniqueQuarters.map((q) => {
                          const rowData = props.totalSegmentData.filter(
                            (i) =>
                              i[props.quarter] === q &&
                              i.Acct_Segment_Rollup === segment
                          );
                          const count =
                            rowData.length === 0 ? 0 : rowData[0].count;
                          const acv = rowData.length === 0 ? 0 : rowData[0].acv;
                          const daysOrPercent =
                            rowData.length === 0
                              ? 0
                              : rowData[0][
                                  props.type === "attainment" ? "perc" : "days"
                                ];
                          return (
                            <>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    {
                                      quarter: q === "Total" ? qtrs : [q],
                                      oppType: uniqueOppTypes,
                                      segment: [segment],
                                    },
                                    props.type
                                  )
                                }
                                className={classes.cellNormal}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(count)
                                  ? countOrACVFormatter(count, false)
                                  : "-"}
                              </TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    {
                                      quarter: q === "Total" ? qtrs : [q],
                                      oppType: uniqueOppTypes,
                                      segment: [segment],
                                    },
                                    props.type
                                  )
                                }
                                className={classes.cellNormal}
                                colSpan={1}
                              >
                                {![null, undefined, ""].includes(acv)
                                  ? countOrACVFormatter(acv, true)
                                  : "-"}
                              </TableCell>
                              {props.type === "attainment" ? (
                                <TableCell
                                  align="right"
                                  className={classes.cellStyle}
                                  colSpan={1}
                                >
                                  {![null, undefined, ""].includes(
                                    daysOrPercent
                                  )
                                    ? props.type === "attainment"
                                      ? percentageFormatter(daysOrPercent)
                                      : Math.round(daysOrPercent)
                                    : "-"}
                                </TableCell>
                              ) : (
                                <TableCell
                                  align="right"
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        quarter: q === "Total" ? qtrs : [q],
                                        oppType: uniqueOppTypes,
                                        segment: [segment],
                                      },
                                      props.type
                                    )
                                  }
                                  className={classes.cellNormal}
                                  colSpan={1}
                                >
                                  {![null, undefined, ""].includes(
                                    daysOrPercent
                                  )
                                    ? props.type === "attainment"
                                      ? percentageFormatter(daysOrPercent)
                                      : Math.round(daysOrPercent)
                                    : "-"}
                                </TableCell>
                              )}
                            </>
                          );
                        })}
                      </StyledTableRow>
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
