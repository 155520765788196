import { Grid, Typography } from "@material-ui/core";
import React from "react";

const NotUpdatedLengend = ({ text }) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        style={{ width: "1rem", height: "1rem", background: " #f4b183" }}
      ></Grid>
      <Grid item>
        <Typography align="center" style={{ margin: "1rem", paddingTop: 5 }}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotUpdatedLengend;
