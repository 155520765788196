import React from "react";

import "./Loading.scss";

export default function Loading() {
  return (
    <div class="pendulum">
      <img
        src="https://skygeniwebapp.blob.core.windows.net/logos/SkyGeni.png"
        alt="SkyGeni"
      />
      <div class="pendulum_box">
        <div class="ball first sk1"></div>
        <div class="ball sk2"></div>
        <div class="ball sk1"></div>
        <div class="ball sk2"></div>
        <div class="ball last sk1"></div>
      </div>
    </div>
  );
}
