import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
// import tableStyle from '../../../CustomerSuccess/styles/tableStyle'
import { StyledTableRow, _isNaN } from "../../../util/customFunctions";
import PrimaryColorTableHeader from "./PrimaryColorTableHeader";
import Legend from "../../AsOfDate/Legend";
import DimensionTableTooltip from "./DimensionTableTooltip";

import { makeStyles } from "@material-ui/core";
import CopyTablesObject from "../../../components/xlsx-export/CopyTablesObject";

const tableStyle = makeStyles(
  (theme) => ({
    root: {
      margin: "0.5rem",
      padding: "0.5rem",
    },
    colHeaderNew: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      backgroundColor: "inherit",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    dataCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
    },
    drilldownCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      cursor: "pointer",
    },
    drilldownCellNQP: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: "3%",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    cellMargin: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      paddingLeft: "2rem",
    },
    fontBold: {
      fontWeight: 600,
    },
    indentName: {
      paddingLeft: 30,
    },
    noBorder: {
      border: "none",
    },
    primaryColumnColor: {
      backgroundColor: "#4472C4",
      color: "white",
    },
    secondaryColumnColor: {
      backgroundColor: "#70ad47",
      color: "white",
    },
    tertiaryColumnColor: {
      backgroundColor: "#bf8f00",
      color: "white",
    },
  }),
  { index: 1 }
);

const ExpectedAcvScore = (props) => {
  const classes = tableStyle();
  const tableRef = React.createRef();

  const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: "rgb(255,255,255,0.8)",
      color: "black",
      fontFamily: "roboto",
      borderRadius: "1rem",
      padding: "0px",
      boxShadow: "0 0.1rem 0.1rem 0",
      marginTop: "0.3rem",
      maxWidth: "30rem",
    },
  }))(Tooltip);

  return (
    <Grid container justify="flex-start">
      <Grid item xs={12}>
        <TableContainer>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div> */}
          <Table ref={tableRef}>
            <TableBody>
              <TableRow
                className={`${classes.primaryColumnColor} ${classes.fontBold}`}
              >
                <PrimaryColorTableHeader title="Dimension" />
                <PrimaryColorTableHeader title="Value" />
                <PrimaryColorTableHeader
                  title="Probability Score"
                  width="15%"
                />
              </TableRow>
              {props.data.map((item) => (
                <StyledTableRow
                  style={
                    item.isLimitingFactor ? { background: "#fbe5d6ff" } : {}
                  }
                >
                  <HtmlTooltip
                    title={
                      <DimensionTableTooltip
                        isLimitingFactor={item.isLimitingFactor}
                        dimension={item.dimension}
                        code={props.thresholds !== undefined ? "close" : ""}
                      />
                    }
                  >
                    <TableCell className={classes.dataCell} align="left">
                      {item.dimension}
                    </TableCell>
                  </HtmlTooltip>
                  <HtmlTooltip
                    title={
                      <DimensionTableTooltip
                        isLimitingFactor={item.isLimitingFactor}
                        dimension={item.dimension}
                        code={props.thresholds !== undefined ? "close" : ""}
                      />
                    }
                  >
                    <TableCell className={classes.dataCell} align="center">
                      {item.value === undefined
                        ? item.daysFlag === "days"
                          ? 0
                          : ""
                        : item.value}{" "}
                      {item.daysFlag}
                    </TableCell>
                  </HtmlTooltip>
                  <HtmlTooltip
                    title={
                      <DimensionTableTooltip
                        isLimitingFactor={item.isLimitingFactor}
                        dimension={item.dimension}
                        code={props.thresholds !== undefined ? "close" : ""}
                      />
                    }
                  >
                    <TableCell
                      className={classes.dataCell}
                      align="right"
                      style={{ paddingRight: 30 }}
                    >
                      {Math.round(item.score * 100)}
                    </TableCell>
                  </HtmlTooltip>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} container justify="flex-start">
        <Legend
          square={15}
          just="flex-start"
          type="AsOfDate"
          legends={[
            {
              text: `Limiting Factor${
                props.data.filter((item) => item.isLimitingFactor === true)
                  .length > 1
                  ? "s"
                  : ""
              }`,
              color: "#fbe5d6ff",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default ExpectedAcvScore;
