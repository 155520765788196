import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import {
  countOrACVFormatter,
  getUniqueValues,
  StyledTableRow,
} from "../../util/customFunctions";

const useStyle = makeStyles((theme) => ({
  headerCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  dataCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function QualipiplineTablev2(props) {
  const classes = useStyle();
  const tableRef = React.createRef();

  const ufqs = props.data
    .map((f) => f[ props.ht ? 'Qualified_Initial_Year_Month' : 'Qualified_Date_Initial_Fiscal_Quarter'])
    .filter(getUniqueValues);
    const t = props.ht ? "Average per month Actual" : "Average per qtr. Actual"
  const uniqueCols = [...ufqs,"Needed", t, "Delta"];
  let actual = 0;
  let needed = props.value;
  if (props.data.length > 0) {
    actual =
      props.data.map((f) => f.acv).reduce((a, b) => a + b, 0) /
      props.data.length;
  }

  const delta = Math.max(needed - actual, 0);

  return (
    <div>
      <TableContainer
        style={{
          padding: "1rem",
          paddingBottom: "3rem",
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
            // marginRight: "10px",
          }}
          align="center"
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              {uniqueCols.map((l, i) => {
                return (
                  <TableCell
                    align="center"
                    className={classes.headerCell}
                    colSpan={1}
                    style={{
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      wordWrap: "break-word",
                      background: i % 2 === 0 ? "#4471c4" : "#5b9bd5",
                      color: "white",
                      width: "25px",
                    }}
                  >
                    {l}
                  </TableCell>
                );
              })}
            </TableRow>

            <StyledTableRow key={"r0w123"}>
              {ufqs.map((q) => {
                const d = props.data.filter(
                  (f) => f[ props.ht ? 'Qualified_Initial_Year_Month' : 'Qualified_Date_Initial_Fiscal_Quarter'] === q
                );
                let acv = "-";
                if (d.length > 0 && ![null, undefined, ""].includes(d[0].acv)) {
                  acv = countOrACVFormatter(d[0].acv, true);
                }
                return (
                  <TableCell className={classes.headerCell} align="right">
                    {acv}
                  </TableCell>
                );
              })}
               <TableCell className={classes.headerCell} align="right">
                {[null, undefined, ""].includes(needed)
                  ? "-"
                  : countOrACVFormatter(needed, true)}
              </TableCell>
              <TableCell className={classes.headerCell} align="right">
                {[null, undefined, ""].includes(actual)
                  ? "-"
                  : countOrACVFormatter(actual, true)}
              </TableCell>
              <TableCell className={classes.headerCell} align="right">
                {[null, undefined, ""].includes(delta)
                  ? "-"
                  : countOrACVFormatter(delta, true)}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
