import * as d3 from "d3"
// import './D3RepPerformanceBar.scss'
import { updatedScheme10 } from '../../util/chartColorScheme';
import { computeDomainLimits } from "../../Dashboard/OpenOpportunities/utils";
import { customTickFormat, getUniqueValues, stageFormattter, countOrACVFormatter, countOrACVFormatterThousand, countOrACVFormatterThousandNew } from '../../util/customFunctions'

const D3GroupedBar = {};
const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};
D3GroupedBar.create = (el, config) => {
  d3.selection.prototype.last = function () { return d3.select(this.nodes()[this.size() - 1]) };

//   console.log(config.data)
  
  if (config.data) {
    // config.data = config.data.filter(item => item.Name !== "total")
    // const uniqueCompetitors = config.data.map(item => item.competitor).filter(getUniqueValues).slice(0, 8)
    var color = config.color !== undefined ? config.color : d3.scaleOrdinal(updatedScheme10);
    config.barHeight = 10;
    // const status = config.status.map(st => st.stage_name)


    var marginLeftCalc = config.data;

    config.margin = {
        top: 20,
        right: 70,
        bottom: 180,
        left: 120
        // config.type === "Region" || config.type === "Segment" ? marginLeftCalc.sort((a, b) => b.Display_Name.length - a.Display_Name.length)[0]['Display_Name'].length * 9 : marginLeftCalc.sort((a, b) => b['Sales Rep'].length - a['Sales Rep'].length)[0]['Sales Rep'].length * 7
      };
    config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right : 100;
    config.height = 500 - config.margin.top - config.margin.bottom;
    //  config.type === "Region" || config.type === "Segment" ? config.data.length * 90 : config.data.length * 30 
    // console.log(config.data, config.binLabel)

    let tempData = config.data.filter(d => d[config.quarters] !== 'total').sort((a,b) => config.binLabel.indexOf(a[config.groupbyColumn]) - config.binLabel.indexOf(b[config.groupbyColumn]))
    var data = config.toggleValue === 'PercSort' ? 
     tempData
    : 
    tempData.sort((a,b) => config.logicalTeams.indexOf(a[config.groupbyColumn]) - config.logicalTeams.indexOf(b[config.groupbyColumn]))
   

    // let zData = data.filter(x => x.label === "0%")
    // let remainingData = data.filter(x => x.label !== "0%")
    // data = [...zData, ...remainingData]

    const binLabels = data.map(item => item[config.groupbyColumn]).filter(getUniqueValues)
    // console.log(binLabels, data)



    var x0 = d3.scaleBand()
      .rangeRound([0, config.width])
      //  .padding(0.6)
      .align(0.5);
    // set y scale
    var x1 = d3.scaleBand()
      .paddingOuter(0.6)

    var y = d3.scaleLinear()
      .range([0, config.height]);

    const xAxis = d3.axisBottom(x0)

    var formatyAxis = d3.format('');
    const yAxis = d3.axisLeft(y).tickFormat(d => d !== 0 ? `${d3.format(formatyAxis)(Math.round(d*100))}` : 0).ticks(d3.max(data, d => d.value) < 8 ? 5 : 10);

    var subCat =  config.data.map(x => x[config.quarters]).filter(getUniqueValues)
    // console.log(subCat)
    // subCat = config.xlabel !== 'None' ? config.toggleValue === 'PercSort' ? subCat : config.logicalTeams.filter(e => subCat.indexOf(e) !== -1)  : subCat 
    // console.log(subCat)
    

    // console.log(config.data.map(d => d.PrimaryCompetitor).filter(getUniqueValues))


    x0.domain(binLabels);
    x1.domain(subCat).rangeRound([0, x0.bandwidth()])

    //console.log(uniqueQuarters)

    const { lower_bound, upper_bound } = computeDomainLimits(d3.min(data, d => d.value ), d3.max(data, d => d.value ))
    y.domain([
      upper_bound, 0
    ]);

    // console.log(lower_bound, upper_bound)

    // var xAxisTransform =  config.height;
    // if(d3Min < 0 && 0 < d3Max) {
    //     xAxisTransform = config.height * (d3Max / (d3Max - d3Min));  
    // }
// console.log()
    // var data = config.type === "net" ? config.arra : config.data

    // console.log(data)

    function make_x_gridlines() {
      return d3.axisBottom(x1).ticks(8);
    }

    // gridlines in y axis function
    function make_y_gridlines() {
      return d3.axisLeft(y).ticks(10);
    }

    var xAxisHeight = config.height

    var chartContainer = d3.select(el)
    .append('svg')
    .attr('width', config.width + config.margin.left + config.margin.right)
    .attr('height', config.height + config.margin.top + config.margin.bottom + 30)
var chart = chartContainer
    .append('g')
    .attr("transform", "translate(" + config.margin.left + "," + config.margin.top + ")");
    var tooltip = d3
            .select(el)
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);

    // const chart = d3
    //   .select(el)
    //   .append('svg')
    //   .attr('width', config.width + config.margin.left + config.margin.right)
    //   .attr('height', config.height + config.margin.top + config.margin.bottom)
    //   .append('g')
    //   .attr('transform', `translate(${config.margin.left},${config.margin.top})`);



    chart
      .append("g")
      .attr("class", "grid")
      .attr("z-index", -1)
      .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));

    chart.append("g")
      .attr("class", "xaxis")
      .style('font-size', "0.85rem")
      .attr("transform", "translate(" + ((0)) + "," + (config.height) + ")")
      .call(xAxis).selectAll("text")  
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)" );

    chart.append("g")
      .attr("class", "yaxis")
      .call(yAxis);


   // console.log('data-data',data)

    var bars = chart.selectAll(".bar")
      .data(data)
      .enter()
      .append("g")
      .attr('transform', function (d, i) {
        return "translate(" + x0(d['label']) + ",0)";
      })
      

  bars.selectAll("rect")
  .data(function (d)  {  return  subCat.map(function (key) { return { key: key, value: d[config.quarters] === key && (d.value), label: d.label, [config.quarters]: d[config.quarters], drilldown: d.drilldown }; }); })
  .enter().append("rect")
  .style("cursor","pointer")
  // .on("click",d =>  config.handleChangeLocation("drilldownOnGraph", {row: d.label, cell: d[config.quarters], x: config.quarters, z:d.value} ))  
  .attr("x", d => x1(d.key) + x1.bandwidth() / 32)
  //If the value is negative, put the top left corner of the rect bar on the zero line
  .attr("y", d => { return (d.value < 0 ? y(0) : y(d.value)) })
  .attr("width", x1.bandwidth())
  .attr("height", d => Math.abs(y(d.value) - y(0)))
  .attr("fill", function (d) { return color(d.key); })
  .style("opacity", 0.9)
  .on("mousemove", (d, i) => {
    // const repName = config.data.filter(item => {
    //     if (config.type === "RepPerformance") {
    //         if (item[`${wonLabel}_ACV_%`] !== null)
    //             return (+(item[`${wonLabel}_ACV_%`]).toFixed(5) === +((d[1] - d[0]) * 0.01).toFixed(5))
    //     } else if (config.type === "Segment") {
    //         if (item.acv_percent !== null)
    //             return (+(item.acv_percent) === +(d[1] - d[0]))
    //     }
    // })[0]
    tooltip.transition().duration(200).style("opacity", 1)
    tooltip
        .html(`
        <div id="tooltip" class="tooltip-container">
        <div class="tooltip-box-scatter" style="border-top:10px solid ${color(
          d[config.quarters]
        )};border-radius:0.3rem;width:15rem;">              
        <h4 class="heading-style">${d[config.quarters]}</h4>
        <div class="tooltip-grid-container">
    <div class="flex-item" style="color:gray;">${config.xlabel}</div>
        <div class="flex-item" style="text-align: right">${d['label']}</div>
      </div>
      <div class="tooltip-grid-container">
      <div class="flex-item" style="color:gray;">% of Attain.:</div>
      <div class="flex-item" style="text-align: right">${Math.round(d['value'] * 100)}%</div>
    </div>
     
      </div>

       
        `)
        //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
        .style("top", d + "px")
        //  .attr("class", "tool")
        .style("left", d3.event.pageX + "px")
        .style("top", d3.event.pageY - 28 + "px");

})
.on("mouseout", () => {
    tooltip.transition().duration(300).style("opacity", 0);
});

  // bars.selectAll("text")
  // .data(function (d)  {  return  subCat.map(function (key) { return { key: key, value: d[config.quarters] === key && (d.value), label: d.label, [config.quarters]: d[config.quarters] }; }); })
  //   .enter().append("text")
  //   .attr("x", d => x1(d.key) + x1.bandwidth() / 2 + 12)
  // .style("cursor", "pointer")
  // // .on("click",d =>  config.handleChangeLocation("drilldownOnGraph", {row: d.label, cell: "total", x: config.quarters, z: d.value} ))
  // //x position is 3 pixels to the right of the bar
  // .attr("y", d => y(d.value) - 5)
  // .text(d =>  `(${Math.round(((d.value/data.filter(c => c[config.quarters] === d[config.quarters]).map(c => c['value']).reduce((a,b) => a+b,0)) * 100))})`)
  // // .style("font-weight", 400)
  //  .style("font-size", "0.7rem")
  // .attr("fill", 'black')
  // // .style("cursor", "pointer")   
  // .attr("text-anchor", "middle");

   bars.selectAll("text")
  .data(function (d)  {  return  subCat.map(function (key) { return { key: key, value: d[config.quarters] === key && (d.value), label: d.label, [config.quarters]: d[config.quarters], drilldown: d.drilldown }; }); })
    .enter().append("text")
    .attr("x", d => x1(d.key) + x1.bandwidth() / 2 )
    //offset the position of the y value (positive / negative) to have the text over/under the rect bar
    .attr("y", d => y(d.value) - 5)
    .style("cursor","pointer")
    // .on("click",d =>  config.handleChangeLocation("drilldownOnGraph", {row: d.label, cell: d[config.quarters], x: config.quarters, z:d.value} )) 
       .style('fill', "black")
    .attr('text-anchor', 'middle')
    .style('font-size', '0.7rem')
    //make sure one just decimal place is displayed
    .text((d, i) => `${(y(d.value) - y(0) >= 20 || y(0) - y(d.value) >= 20) && x1.bandwidth() > 20 ? `${Math.round(d.value * 100)}%` : ''}`)
  // .text(d =>  countOrACVFormatterThousand(d.value, true))



//   bars.selectAll("text1")
//   .data(function (d)  {  return  subCat.map(function (key) { return { key: key, value: d[config.quarters] === key && (d.value), label: d.label, [config.quarters]: d[config.quarters], drilldown: d.drilldown }; }); })
//     .enter().append("text")
//     .attr("x", d => x1(d.key) + x1.bandwidth() / 2 + 14)
//     //offset the position of the y value (positive / negative) to have the text over/under the rect bar
//     .attr("y", d => y(d.value) - 20)
//     .style("cursor","pointer")
//     .on("click",d =>  config.handleChangeLocation("drilldownOnGraph", {row: d.label, cell: d[config.quarters], x: config.quarters, z:d.value} ))
//     .style('fill', "black")
//     .attr('text-anchor', 'middle')
//     .style('font-size', '0.85rem')
//     //make sure one just decimal place is displayed
//     .text((d, i) => `${(y(d.value) - y(0) >= 20 || y(0) - y(d.value) >= 20) && x1.bandwidth() > 20 ? `${Math.round(d.value * 100)}%` : ''}`)
  // .text(d =>  countOrACVFormatterThousand(d.value, true))


    // console.log(data.map(d => uniqueCompetitors.map(function(key) { return {key: key, value: d.acv}; })))


    chart
      .append("text")
      .attr("y", config.height + 40)
      .attr("x", (config.width / 2) - 90)
      .attr("dy", "8em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("fill", "#000000")
      .text(config.xlabel)

      chart
      .append("text")
      .attr("y", config.margin.left - 190)
      .attr("x",  -(config.height / 2))
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      .attr("transform", "rotate(-90)") // although axis is rotated, text is not
      .attr("fill", "#00000")
      // .style('text-transform', 'capitalize')
      .text("Attainment (%)");

    // bars.append("line")
    //   .attr("y1", y(0))
    //   .attr("y2", y(0))
    //   .attr("x1", 0)
    //   .attr("x2", x0.bandwidth())
    //   .attr("stroke", "black")
    //   .attr("stroke-width", config.type === "CompetitorPerformance" ?  "0.1px" : "0.5px")
  }
}
D3GroupedBar.destroy = el => {
  d3.select(el).selectAll('svg').remove();
  d3.select('.legend-flex').remove();
  d3.select('.tooltip').remove();
}

export default D3GroupedBar;

{/* <div style="border-top:10px solid ${color(d[config.quarters])};border-radius:1rem;">
<div class="tooltip-box">
    <h3 class="heading-style">${d[config.quarters]}</h3>
    <hr>
    <div class="container">
        <h4 class="result-style">
        Attainment Band :<span style="color:${color(d[config.quarters])}">${d['label']}</span>                    
        </h4>   
        <h4 class="result-style">
        # of Reps :<span style="color:${color(d["value"])}">${d['value']}</span>                    
        </h4>  
        <h4 class="result-style">
        % of team :<span style="color:${color(d["value"])}">${data.filter(c => c[config.quarters] === d[config.quarters]).map(c => c['value']).reduce((a,b) => a+b,0) > 0 || d['value'] > 0 ?
         Math.round((d['value']/data.filter(c => c[config.quarters] === d[config.quarters]).map(c => c['value']).reduce((a,b) => a+b,0)) * 100) : 0}%</span>                    
        </h4>                            
    </div>
</div>                        
</div> */}