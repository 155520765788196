import * as d3 from "d3"
import { countOrACVFormatter } from '../../util/customFunctions';
const D3TimeLine = {};
D3TimeLine.create = (el, config) => {

    if (config.data) {        
       
        config.margin = {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
        };
        config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width : 100;
     



        config.height = 250 - config.margin.top - config.margin.bottom;
       
        var chartContainer = d3.select(el)
            .append('svg')
            .attr('width', config.width)
            .attr('height', config.height + config.margin.top + config.margin.bottom + 30)
        var chart = chartContainer
            .append('g')
            .attr("transform", "translate(" + config.margin.left + "," + config.margin.top + ")");
        
        chart.append('line')
            .style("stroke", "black")
            .style("stroke-width", 1)
            .attr("x1", 60)
            .attr("y1", 160)
            .attr("x2", 1060)
            .attr("y2", 160); 

            var  data = [{x1: 0, x2: 120, y1: 40, y2: 120,lx1:60,ly1:160,lx2:60,ly2:120,text:'11/01/2021',Stage:'Suspect',acv:10000,close_dt:'03/31/22'},
            {x1: 140, x2: 260, y1: 200, y2: 280,lx1:200,ly1:200,lx2:200,ly2:160,text:'11/15/2021',Stage:'Qualify',acv:15000,close_dt:'03/31/22'},
            {x1: 380, x2: 500, y1: 40, y2: 120,lx1:440,ly1:160,lx2:440,ly2:120,text:'12/10/2021',Stage:'Demo',acv:15000,close_dt:'03/31/22'},
            {x1: 600, x2: 720, y1: 200, y2: 280,lx1:660,ly1:200,lx2:660,ly2:160,text:'12/30/2021',Stage:'Proposal',acv:15000,close_dt:'04/30/22'},
            {x1: 1000, x2: 1120, y1: 40, y2: 120,lx1:1060,ly1:160,lx2:1060,ly2:120,text:'02/21/2022',Stage:'Negotiate',acv:15000,close_dt:'04/30/22'}];
                
                var rects = chart.selectAll("foo")
                    .data(data)
                    .enter()
                    .append("rect")
                    .attr("x", d=> d.x1)
                    .attr("y", d=> d.y1)
                    .attr("width", d=> d.x2 - d.x1)
                    .attr("height", d=> d.y2 - d.y1)
                    .attr('fill','white')
                    .attr("stroke-width",'0.10rem')
                    .style("stroke", '#7F7F7F');
                var rectangle = chart.append("rect")
                    .attr("x",0)
                    .attr("y",8)
                    .attr("width",120)
                    .attr("height",30)
                    .attr('fill','#C4E0B2')
                    .attr("stroke-width",'0.11rem')
                    .style("stroke", '#7F7F7F');
                
                var createdText = chart.append("text")
                        .attr("x",35)
                        .attr("y", 2)
                        .attr("dy", "2rem")
                        .attr('font-size','1rem')
                        .text('Created')
                        .attr('fill','black')
                        .style("font-weight", "bold");
                
                
                
                 var lines=chart.selectAll("lines")
                        .data(data)
                        .enter()
                        .append('line')
                        .style("stroke", "black")
                        .style("stroke-width", 1)
                        .attr("x1", d=> d.lx1)
                        .attr("y1", d=> d.ly1)
                        .attr("x2", d=> d.lx2)
                        .attr("y2", d=> d.ly2)
                        .style("stroke-dasharray","7,7");
                
                var texts=chart.selectAll('.text')
                            .data(data)
                            .enter()
                            .append('text')
                            .attr("x", (d,i)=> i%2 === 0 ? d.lx1 -30 : d.lx2 -30)
                            .attr("y", (d,i)=>  i%2 === 0 ? d.ly1 + 10 : d.ly2 + 10)
                            .attr("dy", ".85rem")
                            .text(function(d) { return d.text ; })
                            .attr('fill','#7F7F7F')
                            .style("font-weight", "bold");

                var rectLine1=chart.selectAll('.rectline1')
                            .data(data)
                            .enter()
                            .append('line')
                            .style("stroke", "#7F7F7F")
                            .attr("stroke-width",'0.04rem')
                            .attr("x1", d=> d.x1)
                            .attr("y1", d=> d.y1+26)
                            .attr("x2", d=> d.x2)
                            .attr("y2", d=> d.y1+26);
                var rectLine2=chart.selectAll('.rectline2')
                            .data(data)
                            .enter()
                            .append('line')
                            .style("stroke", "#7F7F7F")
                            .attr("stroke-width",'0.04rem')
                            .attr("x1", d=> d.x1)
                            .attr("y1", d=> d.y1+52)
                            .attr("x2", d=> d.x2)
                            .attr("y2", d=> d.y1+52);

                    var rectStage=chart.selectAll('.rectStage')
                                         .data(data)
                                         .enter()
                                         .append('text')
                                         .attr("x", (d,i)=> d.x1+18)
                                         .attr("y", (d,i)=>  d.y1+4)
                                         .attr("dy", ".85rem")
                                         .text(function(d) { return 'Stage: ' })
                                         .attr('fill','#7F7F7F')
                                         .style("font-weight", "bold");
                        var rectStageName=chart.selectAll('.rectStageName')
                                         .data(data)
                                         .enter()
                                         .append('text')
                                         .attr("x", (d,i)=> d.x1+60)
                                         .attr("y", (d,i)=>  d.y1+4)
                                         .attr("dy", ".85rem")
                                         .text(function(d) { return d.Stage })
                                         .attr('fill',d=>d.Stage !== 'Suspect' ? '#70AD47':'black')
                                         .style("font-weight", "bold");
                        var rectACV=chart.selectAll('.rectacv')
                                         .data(data)
                                         .enter()
                                         .append('text')
                                         .attr("x", (d,i)=> d.x1+25)
                                         .attr("y", (d,i)=>  d.y1+32)
                                         .attr("dy", ".85rem")
                                         .text(function(d) { return 'ACV: ' })
                                         .attr('fill','#7F7F7F')
                                         .style("font-weight", "bold");

                            var rectACVName=chart.selectAll('.rectACVValue')
                                         .data(data)
                                         .enter()
                                         .append('text')
                                         .attr("x", (d,i)=> d.x1+60)
                                         .attr("y", (d,i)=>  d.y1+32)
                                         .attr("dy", ".85rem")
                                         .text(function(d) { return `$${countOrACVFormatter(d.acv)}`})
                                         .attr('fill',d=>d.Stage === 'Qualify' ? '#70AD47':'black')
                                         .style("font-weight", "bold");

                                var rectDate=chart.selectAll('.rectclosedt')
                                         .data(data)
                                         .enter()
                                         .append('text')
                                         .attr("x", (d,i)=> d.x1+4)
                                         .attr("y", (d,i)=>  d.y1+60)
                                         .attr("dy", ".85rem")
                                         .text(function(d) { return 'Close Dt: ' })
                                         .attr('fill','#7F7F7F')
                                         .style("font-weight", "bold");

                                var rectDateName=chart.selectAll('.rectdateValue')
                                         .data(data)
                                         .enter()
                                         .append('text')
                                         .attr("x", (d,i)=> d.x1+60)
                                         .attr("y", (d,i)=>  d.y1+60)
                                         .attr("dy", ".85rem")
                                         .text(function(d) { return d.close_dt})
                                         .attr('fill', d=>d.Stage === 'Proposal' ? '#Ed7D31':'black')
                                         .style("font-weight", "bold");


                

                // var rectStage=chart.selectAll('.rectStage')
                //             .data(data)
                //             .enter()
                //             .append('text')
                //             .attr("x", (d,i)=> d.x1)
                //             .attr("y", (d,i)=>  d.y1)
                //             .attr("dy", ".85rem")
                //             //.text(function(d) { return `Stage : ${d.Stage}`; })
                //             .attr('fill','black')
                //             .style("font-weight", "bold")
                //             .append('tspan')
			    //             .attr('dy', '1em')
			    //             .style('font-size', '0.80rem')
                //             .html(function(d,i) {
                //                 return rectangleBox(d);
                //               });


                            


        


    //     var xAxis = chart.append("g")
    //         .attr("transform", `translate(0,${config.height})`)
    //         .attr("class", "x-axis")

    //     chart.selectAll(".x-axis")
    //         .call(d3.axisBottom(x).tickFormat(d => d).tickSizeOuter(0))
    //         .selectAll("text")
    //         .attr("transform", d => "rotate(0)");
    //         var lines = chart.selectAll(".line")
    //         .data(config.data)
    //         .enter()
    //         .append("g")
    
    //         lines.append("rect")
    //         .attr("x",d => x(d.date_At_Stage))
    //         .attr("y",200)
    //         .attr("width",100)
    //         .attr("height",50)
    //         .attr('fill',d=>d.Stage === 'Won' ? '#F48135' : d.Stage === 'Suspect' ? '#E2EFDA' : 'white')
    //         .attr('stroke','black');
    
    //         lines.append("line")
    //             .attr('x1', (d,i) =>  x((i+1)*100))
    //             .attr('y1', 0)
    //             .attr("x2",(d,i) =>  x((i+1)*100))
    //             .attr("y2", 800)
    //             .attr("stroke", "black")
    //             .style("stroke-dasharray","5,5");
            
          
    
    // lines.append("text")
    //     .attr("x", d=> x(d.Days+300))
    //     .attr("y", 210)
    //     .attr('text-align','center')
    //     .attr("dy", ".85rem")
    //     .text(function(d) { return `${d.Stage}`; })
    //     .attr('fill',d=>d.Stage === 'Won' ? 'white' : 'black')
    //     .style("font-weight", "bold");
    }

}
D3TimeLine.destroy = (el) => {
    // Cleaning code here
    d3.select(el).selectAll("svg").remove();
};
export default D3TimeLine;