import React from "react";
import { Button } from "@material-ui/core";
import GetApp from "@material-ui/icons/GetApp";
import HoverableIcon from "../../Buttons/HoverableIcon ";
const ExportCSVButton = (props) => {
  return (
    <div>
      {/* <Button variant="contained"
                                color="primary" data-test='export-csv' onClick={props.exportCSV}><GetApp /></Button> */}
      <button style={{ border: "none" }} onClick={props.exportCSV}>
        <HoverableIcon />
      </button>
    </div>
  );
};

export default ExportCSVButton;
