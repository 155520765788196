import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCompanyQuotaData,
  fetchQuotaData,
  fetchQuotaFiscalQuartersFilters,
  fetchTeamQuotaData,
  updateCompanyQuotaData,
  updateQuotaData,
  updateTeamQuotaData,
} from "./management.thunk";

const initialState = {
  quotaFiscalQuarters: null,
  quotaData: null,
  quotaDataCopy: null,
  teamQuota: null,
  teamQuotaCopy: null,
  companyQuotaCopy: null,
  companyQuota: null,
  updatedRecords: null,
  teams: null,
  rampStatus: null,
  updateFailed: null,
};
const managementSlice = createSlice({
  name: "skygeni-management",
  initialState,
  reducers: {
    clearManagementSlice: (state, action) => {
      state.quotaData = null;
      state.quotaFiscalQuarters = null;
      state.quotaDataCopy = null;
      state.teamQuota = null;
      state.teamQuotaCopy = null;
      state.companyQuota = null;
      state.companyQuotaCopy = null;
      state.updatedRecords = null;
      state.teams = null;
      state.rampStatus = null;
      state.updateFailed = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyQuotaData.pending, (state, action) => {
      state.companyQuota = null;
      state.updatedRecords = null;
      state.companyQuotaCopy = null;
    });
    builder.addCase(fetchCompanyQuotaData.fulfilled, (state, action) => {
      state.companyQuota = action.payload.message;
      state.companyQuotaCopy = action.payload.message;
    });
    builder.addCase(fetchTeamQuotaData.pending, (state, action) => {
      state.teamQuota = null;
      state.updatedRecords = null;
      state.teamQuotaCopy = null;
    });
    builder.addCase(fetchTeamQuotaData.fulfilled, (state, action) => {
      state.teamQuota = action.payload.message;
      state.teamQuotaCopy = action.payload.message;
    });
    builder.addCase(fetchQuotaData.pending, (state, action) => {
      state.quotaData = null;
      state.quotaDataCopy = null;
      state.updatedRecords = null;
    });

    builder.addCase(fetchQuotaData.fulfilled, (state, action) => {
      const data = action.payload.message;
      const teams = [...new Set(data.map((d) => d.Team_Assignment))];
      const rampStatus = ["Ramping", "Ramped"];
      state.quotaData = data;
      state.quotaDataCopy = data;
      state.teams = teams;
      state.rampStatus = rampStatus;
    });
    builder.addCase(updateQuotaData.pending, (state, action) => {
      state.updatedRecords = null;
      state.quotaData = null;
      state.updateFailed = null;
    });
    builder.addCase(updateQuotaData.fulfilled, (state, action) => {
      const updatedRecords = action.payload.message.updatedRecords;
      state.updatedRecords = updatedRecords;
      const updatedQuotaState = state.quotaDataCopy.map((it) => {
        const value = updatedRecords.filter(
          (u) => it.UserId === u.UserId && it.FiscalQuarter === u.FiscalQuarter
        )[0];
        if (value) {
          const {
            Ramp_Status,
            Team_Assignment,
            UserId,
            Quota,
            NQP_GOAL,
            FullName,
            FiscalQuarter,
            isUpdated,
          } = value;
          return {
            Ramp_Status,
            Team_Assignment,
            UserId,
            Quota,
            NQP_GOAL,
            FullName,
            FiscalQuarter,
            isUpdated,
          };
        } else {
          return { ...it };
        }
      });
      state.quotaData = updatedQuotaState;
      state.quotaDataCopy = updatedQuotaState;
      // console.log(updatedQuotaState);
    });
    builder.addCase(updateTeamQuotaData.pending, (state, action) => {
      state.updatedRecords = null;
      state.teamQuota = null;
      state.updateFailed = null;
    });
    builder.addCase(updateTeamQuotaData.fulfilled, (state, action) => {
      const updatedRecords = action.payload.message.updatedRecords;
      state.updatedRecords = updatedRecords;
      const updatedQuotaState = state.teamQuotaCopy.map((it) => {
        const value = updatedRecords.filter(
          (u) => it.Team === u.Team && it.FiscalQuarter === u.FiscalQuarter
        )[0];
        if (value) {
          const { Team, FiscalQuarter, Quota, NQP_GOAL, isUpdated } = value;
          return { Team, FiscalQuarter, Quota, NQP_GOAL, isUpdated };
        }
        return { ...it };
      });
      state.teamQuota = updatedQuotaState;
      state.teamQuotaCopy = updatedQuotaState;
    });
    builder.addCase(updateCompanyQuotaData.pending, (state, action) => {
      state.updatedRecords = null;
      state.updateFailed = null;
      state.companyQuota = null;
    });
    builder.addCase(updateCompanyQuotaData.fulfilled, (state, action) => {
      const updatedRecords = action.payload.message.updatedRecords;
      state.updatedRecords = updatedRecords;
      const updatedQuotaState = state.companyQuotaCopy.map((it) => {
        const value = updatedRecords.filter(
          (u) => it.FiscalQuarter === u.FiscalQuarter
        )[0];
        if (value) {
          const { FiscalQuarter, Quota, NQP_GOAL, isUpdated } = value;
          return { FiscalQuarter, Quota, NQP_GOAL, isUpdated };
        }
        return { ...it };
      });
      state.companyQuota = updatedQuotaState;
      state.companyQuotaCopy = updatedQuotaState;
    });

    builder.addCase(
      fetchQuotaFiscalQuartersFilters.pending,
      (state, action) => {
        state.quotaFiscalQuarters = null;
      }
    );
    builder.addCase(
      fetchQuotaFiscalQuartersFilters.fulfilled,
      (state, action) => {
        state.quotaFiscalQuarters = action.payload.message;
      }
    );
    builder.addCase(updateCompanyQuotaData.rejected, (state, action) => {
      state.updateFailed = true;
    });
    builder.addCase(updateTeamQuotaData.rejected, (state, action) => {
      state.updateFailed = true;
    });
    builder.addCase(updateQuotaData.rejected, (state, action) => {
      state.updateFailed = true;
    });
  },
});

export const selectQuotaFiscalQuarters = (state) =>
  state.management.quotaFiscalQuarters;
export const selectTeamQuotaData = (state) => state.management.teamQuota;
export const selectCompanyQuotaData = (state) => state.management.companyQuota;
export const selectQuotaData = (state) => state.management.quotaData;
export const selectTeams = (state) => state.management.teams;
export const selectRampStatus = (state) => state.management.rampStatus;
export const selectUpdatedRecords = (state) => state.management.updatedRecords;
export const { clearManagementSlice } = managementSlice.actions;
export const selectUpdateFailed = (state) => state.management.updateFailed;
export default managementSlice.reducer;
