import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "./StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  customDateFormatter,
} from "../util/customFunctions";
import * as d3 from "d3";
import { getMixKey } from "../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
} from "../util/customSortings";
import { deSnake } from "../util/dataFormatter";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CopyTablesObject from "./xlsx-export/CopyTablesObject";
import stickytableStyle from "../StickyTableStyles/Sticky_Table_Styles";
import { replaceAll } from "./xlsx-export/LabelCange";
import {
  sortQuarters,
  sortQuartersGeneric,
  sortYearMonth,
  sortYearMonthGeneric,
} from "../EnhancedInsights/PipelineProgression/utils";
// import ExcelExportButton from "./xlsx-export/xlsxexport";

const useStyle = makeStyles((theme) => ({
  root: {},
  stickyHeader: {
    position: "sticky",
    top: -1,
    background: "white",
    outline: "0.2px solid lightgrey",
    outlineOffset: "-1.5px",
    zIndex: 5,
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px", // Adjust the thickness as needed
      // background: "lightgrey", // Adjust the color as needed
    },
  },
  // sticky: {
  //   position: "sticky",
  //   left: -1,
  //   // color: "inherit",
  //   background: "white",
  //   // backgroundColor: "inherit",
  //   // boxShadow: "5px 2px 5px grey",
  //   // borderRight: "2px solid black",
  // },
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
    fontWeight: "inherit",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: "inherit",
  },
  repNumberACV: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "92px",
    fontWeight: "inherit",
  },
  repNumberPercent: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "90px",
    fontWeight: 600,
    cursor: "pointer",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
}));
export default function DyanmicTable(props) {
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const labelMap = {
    Open_Initial_Year_Month: "Open Initial Month",
    Projected_Close_Year_Month: "Projected Close Month",
    Closed_Year_Month: "Closed Month",
    Open_Date_Initial_Fiscal_Quarter: "Open Initial Fiscal Qtr",
    Created_Year_Month: "Created Month",
    Qualified_Date_Initial_Fiscal_Quarter: "Qualified Initial Fiscal Qtr",
    Qualified_Initial_Year_Month: "Qualified Initial Month",
  };

  let acvLabel = "ACV";
  if (props.Value_Label) {
    acvLabel = props.Value_Label;
  }

  const [parentWidth, setparentWidth] = useState();
  const [acvordering, setacvordering] = useState("acv");
  const [oppsordering, setOppsordering] = useState("dsc");

  const [pointer, setPointer] = useState("acv");

  const [isSticky, setIsSticky] = useState(false);

  const [rowPin, setrowPin] = useState(false);

  let total;

  const uniqueQuarters = props.winAcvData
    .map((item) => item[props.quarter])
    .filter(getUniqueValues)
    .sort();
  let uniqueStages = props.winAcvData
    .map((item) => item[props.type])
    .filter(getUniqueValues);

  let uniqueStagesDisplay;
  const stages = props.winAcvData.map((item) => item[props.type]);
  if (props.type === "Acct_Segment") {
    const sortedSegemnt = customSortedSegments;
    const missingSegemnt = uniqueStages.filter(function (obj) {
      return sortedSegemnt.indexOf(obj) == -1;
    });
    uniqueStages = [...sortedSegemnt, ...missingSegemnt];
  } else {
    // Initialize a variable for sorted unique stages
    var sortedUniquestages = {};

    // Map and calculate the total value for each unique stage
    uniqueStages.map(
      (item) =>
        (sortedUniquestages[item] = props.winAcvData
          .filter((f) => f[props.type] === item)
          .map((i) => i[acvordering])
          .reduce(
            (a, b) => a + b,
            [
              "PCD_At_creation_Fiscal_Quarter",
              "PCD_At_Creation_Year_Month",
            ].includes(acvordering)
              ? ""
              : 0
          ))
    );
    console.log("sortedUniquestages", sortedUniquestages);

    // Sort in descending order by default
    let sortable = Object.fromEntries(
      Object.entries(sortedUniquestages).sort(([x, a], [y, b]) => {
        console.log("INside Sort", x, y, a, b);

        if (
          [
            "PCD_At_creation_Fiscal_Quarter",
            "PCD_At_Creation_Year_Month",
          ].includes(acvordering)
        ) {
          console.log("oppsorderingData", oppsordering);

          if (acvordering === "PCD_At_creation_Fiscal_Quarter") {
            return sortQuartersGeneric(x, y, oppsordering === "asc");
          } else if (acvordering === "PCD_At_Creation_Year_Month") {
            return sortYearMonthGeneric(x, y, oppsordering === "asc");
          }
        }
        console.log("AValidData", a, b, acvordering);
        return oppsordering === "asc" ? a - b : b - a;
      })
    );

    // Update unique stages
    uniqueStages = Object.keys(sortable);

    // Handle 'null' string value conversion to actual null
    for (let i = 0; i < uniqueStages.length; i++) {
      if (uniqueStages[i] === "null") {
        uniqueStages[i] = null;
      }
    }
  }

  const handleSort = (key) => {
    console.log("clickedkey", key);
    setacvordering(key);
    setPointer(key);
    // setOppsordering(oppsordering === "asc" ? "dsc" : "asc");
    console.log("opsorder", oppsordering);
  };

  // function MouseOver(event) {
  //   event.target.style.color = "#3b3d3c";
  //   event.target.style.border = "#3b3d3c";
  //   // console.log("mouseOver");
  // }
  // function MouseOut(event) {
  //   event.target.style.color = "#ffffff";
  //   // console.log("mouseOut");
  // }

  if (props.toggleValue === "stageSequence" && props.label === "Stage") {
    uniqueStages = props.sotedStages
      .filter((e) => uniqueStages.indexOf(e) !== -1)
      .filter(Boolean);
  }

  //to Update copy to clipboard position
  const updateParentWidth = () => {
    const newParentWidth =
      tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(newParentWidth);
  };

  useEffect(() => {
    // Initial width calculation
    updateParentWidth();

    // Add event listener for window resize
    window.addEventListener("resize", updateParentWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateParentWidth);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (tableContainer.scrollTop > 0) {
        setrowPin(true);
      } else {
        setrowPin(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: 10 }}>
        <Grid container justify="center">
          {parentWidth !== undefined ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginBottom: "0.6rem",
                // marginRight: "1rem",
                width: `${parentWidth}px`,
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null}
        </Grid>
        <Grid container justify="center">
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
              borderTop: `${
                props.type === "lostReason" && rowPin
                  ? "0.5px solid lightgrey"
                  : ""
              }`,
              maxHeight: props.type === "lostReason" ? "85vh" : "none",
            }}
            id="myTableContainer"
            ref={tableContainerRef}
          >
            <Table
              ref={tableRef}
              data-test={getMixKey(props.type, "ACV")}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              id={
                props.type === "Cust_Type"
                  ? "Customer_Type_table"
                  : `${props.type}_table`
              }
            >
              <TableHead style={{ position: "sticky", top: -1, zIndex: 5 }}>
                <TableRow
                  style={{ fontWeight: 600, paddingLeft: 5 }}
                  className={`${
                    props.type === "lostReason" && rowPin
                      ? classes.stickyHeader
                      : ""
                  }`}
                >
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                  >
                    {props.type === "lostReason" && props.cellHeader
                      ? props.cellHeader
                      : labelMap[props.quarter]
                      ? labelMap[props.quarter]
                      : deSnake(props.quarter)}{" "}
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        className={classes.repHeader}
                        colSpan={props.UICols ? props.UICols.length : 3}
                      >
                        {props.isWeek ? customDateFormatter(i) : i}
                      </TableCell>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueQuarters.length > 1 &&
                          uniqueQuarters.length % 2 === 0
                            ? { background: "#4471c4", color: "white" }
                            : { background: "#5b9bd5", color: "white" }
                        }
                        align="center"
                        className={classes.repHeader}
                        colSpan={props.UICols ? props.UICols.length : 3}
                      >
                        {"Total"}
                      </TableCell>
                    </React.Fragment>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 200, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
                <TableRow
                  style={{ fontWeight: 600, paddingLeft: 5 }}
                  className={`${
                    props.type === "lostReason" && rowPin
                      ? classes.stickyHeader
                      : ""
                  }`}
                >
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    // onClick={() => {
                    //   setOppsordering(oppsordering === "asc" ? "dsc" : "asc");
                    //   handleSort(props.type);
                    // }}
                    style={{
                      wordWrap: "break-word",
                      maxWidth: "100px", // Set your desired max-width
                      // overflow: "hidden", // Hide overflowing content
                      // textOverflow: "ellipsis", // Show ellipsis for truncated content
                      whiteSpace: "normal", // Allow wrapping
                    }}
                    onClick={
                      props.type === "PCD_At_creation_Fiscal_Quarter" ||
                      props.type === "PCD_At_Creation_Year_Month"
                        ? () => {
                            setOppsordering(
                              oppsordering === "asc" ? "dsc" : "asc"
                            );
                            handleSort(props.type);
                          }
                        : undefined
                    }
                  >
                    {/* {props.label !== undefined
                      ? props.label
                      : getMixKey(props.type)} */}
                    {props.label !== undefined
                      ? replaceAll(props.label, "acv", `${acvLabel}`)
                      : getMixKey(props.type, acvLabel)}{" "}
                    {pointer === props.type ? (
                      <TableSortLabel
                        active={true}
                        direction={oppsordering}
                      ></TableSortLabel>
                    ) : null}
                  </TableCell>
                  {uniqueQuarters.map((i) => {
                    return (
                      <React.Fragment key={i}>
                        {props.UICols.map((item) => {
                          return item.type === "perc" ? (
                            <TableCell
                              align="center"
                              style={{ minWidth: 70 }}
                              className={classes.repHeader}
                              colSpan={1}
                            >
                              {item.label}
                            </TableCell>
                          ) : (
                            <TableCell
                              align="center"
                              style={{ minWidth: 70 }}
                              className={classes.repHeader}
                              colSpan={1}
                              onClick={() => {
                                if (uniqueQuarters.length <= 1) {
                                  setOppsordering(
                                    oppsordering === "asc" ? "dsc" : "asc"
                                  );
                                  handleSort(item.col);
                                }
                              }}
                            >
                              {/* {item.label} */}
                              {replaceAll(
                                item.label,
                                "ACV",
                                `${props.Value_Label}`
                              )}
                              {pointer === item.col &&
                              uniqueQuarters.length <= 1 ? (
                                <TableSortLabel
                                  active={true}
                                  direction={oppsordering}
                                ></TableSortLabel>
                              ) : null}
                            </TableCell>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      {props.UICols.map((item) => {
                        return item.type === "perc" ? (
                          <TableCell
                            align="center"
                            style={{ minWidth: 70 }}
                            className={classes.repHeader}
                            colSpan={1}
                          >
                            {item.label}
                          </TableCell>
                        ) : (
                          <TableCell
                            align="center"
                            style={{ minWidth: 70 }}
                            className={classes.repHeader}
                            colSpan={1}
                            onClick={() => {
                              setOppsordering(
                                oppsordering === "asc" ? "dsc" : "asc"
                              );
                              handleSort(item.col);
                            }}
                          >
                            {/* {item.label} */}
                            {replaceAll(
                              item.label,
                              "acv",
                              `${props.Value_Label}`
                            )}
                            {pointer === item.col ? (
                              <TableSortLabel
                                active={true}
                                direction={oppsordering}
                              ></TableSortLabel>
                            ) : null}
                          </TableCell>
                        );
                      })}
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueStages.map((row, i) => {
                  total = i + 1;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // className={classes.repText}
                        className={
                          isSticky
                            ? `${stickyClasses.sticky} ${classes.repText}`
                            : classes.repText
                        }
                        style={
                          isSticky && i % 2 === 1
                            ? { background: "#f5f5f5" }
                            : {}
                        }
                        colSpan={1}
                      >
                        {row}
                      </TableCell>
                      {uniqueQuarters.map((cell, j) => {
                        const cellData = props.winAcvData.filter(
                          (item) =>
                            item[props.quarter] === cell &&
                            item[props.type] === row
                        )[0];
                        return (
                          <React.Fragment key={j}>
                            {props.UICols.map((item) => {
                              let val = "-";
                              // console.log(cellData[item.col], '12CELLDATA')
                              if (![null, undefined, ""].includes(cellData)) {
                                val =
                                  item.type === "currency"
                                    ? countOrACVFormatter(
                                        cellData[item.col],
                                        true,
                                        props.Currency_Symbol
                                      )
                                    : countOrACVFormatter(
                                        cellData[item.col],
                                        false,
                                        props.Currency_Symbol
                                      );
                              }
                              if (item.type === "perc") {
                                val =
                                  ![null, undefined, ""].includes(cellData) &&
                                  cellData.acv > 0 &&
                                  props.winAcvData
                                    .filter(
                                      (item) => item[props.quarter] === cell
                                    )
                                    .map((item) => item.acv)
                                    .reduce((a, b) => a + b, 0) > 0
                                    ? `${Math.round(
                                        (cellData.acv /
                                          props.winAcvData
                                            .filter(
                                              (item) =>
                                                item[props.quarter] === cell
                                            )
                                            .map((item) => item.acv)
                                            .reduce((a, b) => a + b, 0)) *
                                          100
                                      )}%`
                                    : "0%";
                              }
                              return item.type === "perc" ? (
                                <TableCell
                                  align="right"
                                  className={classes.repNumber}
                                  colSpan={1}
                                >
                                  {val}
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      { row, cell },
                                      props.type,
                                      props.quarter
                                    )
                                  }
                                  align="right"
                                  className={classes.repNumber}
                                  colSpan={1}
                                >
                                  {val}
                                </TableCell>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                      {uniqueQuarters.length > 1 && (
                        <React.Fragment>
                          {
                            props.UICols.map((it) => {
                              let val = "-";
                              val =
                                it.type === "currency"
                                  ? countOrACVFormatter(
                                      props.winAcvData
                                        .filter(
                                          (item) => item[props.type] === row
                                        )
                                        .map((item) => item[it.col])
                                        .reduce((a, b) => a + b, 0),
                                      true,
                                      props.Currency_Symbol
                                    )
                                  : countOrACVFormatter(
                                      props.winAcvData
                                        .filter(
                                          (item) => item[props.type] === row
                                        )
                                        .map((item) => item[it.col])
                                        .reduce((a, b) => a + b, 0),
                                      false
                                    );
                              if (it.type === "perc") {
                                const a = props.winAcvData
                                  .filter((item) => item[props.type] === row)
                                  .map((item) => item["acv"])
                                  .reduce((a, b) => a + b, 0);
                                const ta = props.winAcvData
                                  .map((item) => item["acv"])
                                  .reduce((a, b) => a + b, 0);
                                val =
                                  a > 0 && ta > 0
                                    ? `${Math.round((a / ta) * 100)}%`
                                    : "0%";
                              }

                              return it.type === "perc" ? (
                                <TableCell
                                  align="right"
                                  className={classes.repNumber}
                                  colSpan={1}
                                >
                                  {val}
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row,
                                        cell: props.isMonth
                                          ? uniqueQuarters
                                          : "Total",
                                      },
                                      props.type,
                                      props.quarter
                                    )
                                  }
                                  align="right"
                                  className={classes.repNumber}
                                  colSpan={1}
                                >
                                  {val}
                                </TableCell>
                              );
                            })
                            // <TableCell
                            //   style={{ cursor: "pointer" }}
                            //   onClick={() =>
                            //     props.handleChangeLocation(
                            //       "drilldownOnGraph",
                            //       {
                            //         row,
                            //         cell: props.isMonth
                            //           ? uniqueQuarters
                            //           : "Total",
                            //       },
                            //       props.type,
                            //       props.quarter
                            //     )
                            //   }
                            //   align="right"
                            //   className={classes.repNumber}
                            //   colSpan={1}
                            // >{`${d3.format(",")(
                            //   props.winAcvData
                            //     .filter((item) => item[props.type] === row)
                            //     .map((item) => item.count)
                            //     .reduce((a, b) => a + b, 0)
                            // )}`}</TableCell>
                            // <TableCell
                            //   style={{ cursor: "pointer" }}
                            //   onClick={() =>
                            //     props.handleChangeLocation(
                            //       "drilldownOnGraph",
                            //       {
                            //         row,
                            //         cell: props.isMonth
                            //           ? uniqueQuarters
                            //           : "Total",
                            //       },
                            //       props.type,
                            //       props.quarter
                            //     )
                            //   }
                            //   align="right"
                            //   className={classes.repNumber}
                            //   colSpan={1}
                            // >
                            //   {`$${d3.format(",")(
                            //     Math.round(
                            //       props.winAcvData
                            //         .filter((item) => item[props.type] === row)
                            //         .map((item) => item.acv)
                            //         .reduce((a, b) => a + b, 0)
                            //     )
                            //   )}`}
                            // </TableCell>
                            // <TableCell
                            //   style={{ cursor: "pointer", fontWeight: 600 }}
                            //   onClick={() =>
                            //     props.handleChangeLocation(
                            //       "drilldownOnGraph",
                            //       {
                            //         row,
                            //         cell: props.isMonth
                            //           ? uniqueQuarters
                            //           : "Total",
                            //       },
                            //       props.type,
                            //       props.quarter
                            //     )
                            //   }
                            //   align="right"
                            //   className={classes.repNumberACV}
                            //   colSpan={1}
                            // >
                            //   {`${
                            //     _isNaN(
                            //       Math.round(
                            //         (props.winAcvData
                            //           .filter((item) => item[props.type] === row)
                            //           .map((item) => item.acv)
                            //           .reduce((a, b) => a + b, 0) /
                            //           props.winAcvData
                            //             .map((item) => item.acv)
                            //             .reduce((a, b) => a + b, 0)) *
                            //           100
                            //       )
                            //     )
                            //       ? "-"
                            //       : Math.round(
                            //           (props.winAcvData
                            //             .filter(
                            //               (item) => item[props.type] === row
                            //             )
                            //             .map((item) => item.acv)
                            //             .reduce((a, b) => a + b, 0) /
                            //             props.winAcvData
                            //               .map((item) => item.acv)
                            //               .reduce((a, b) => a + b, 0)) *
                            //             100
                            //         )
                            //   }%`}
                            // </TableCell>
                          }
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    align="left"
                    // className={classes.repText}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repText}`
                        : classes.repText
                    }
                    colSpan={1}
                    style={
                      isSticky && total % 2 === 1
                        ? { background: "#f5f5f5" }
                        : {}
                    }
                  >
                    Total
                  </TableCell>
                  {uniqueQuarters.map((cell, j) =>
                    props.UICols.map((it) => {
                      let val = "-";
                      const data =
                        props.clusterTotal.length >= 1
                          ? props.clusterTotal
                          : props.winAcvData;
                      val =
                        it.type === "currency"
                          ? countOrACVFormatter(
                              data
                                .filter((item) => item[props.quarter] === cell)
                                .map((item) => item[it.col])
                                .reduce((a, b) => a + b, 0),
                              true,
                              props.Currency_Symbol
                            )
                          : countOrACVFormatter(
                              data
                                .filter((item) => item[props.quarter] === cell)
                                .map((item) => item[it.col])
                                .reduce((a, b) => a + b, 0),
                              false
                            );

                      if (it.type === "perc") {
                        val = "100%";
                      }

                      return it.type === "perc" ? (
                        <TableCell
                          // style={{ cursor: "pointer" }}
                          align="right"
                          // onClick={() =>
                          //   props.handleChangeLocation(
                          //     "drilldownOnGraph",
                          //     { row: "Total", cell },
                          //     props.type,
                          //     props.quarter
                          //   )
                          // }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {val}
                        </TableCell>
                      ) : (
                        <TableCell
                          style={{ cursor: "pointer" }}
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              { row: "Total", cell },
                              props.type,
                              props.quarter
                            )
                          }
                          className={classes.repNumber}
                          colSpan={1}
                        >
                          {val}
                        </TableCell>
                      );
                    })
                  )}
                  {uniqueQuarters.length > 1 && (
                    <React.Fragment>
                      {props.UICols.map((it) => {
                        let val = "-";
                        const data =
                          props.clusterTotal.length >= 1
                            ? props.clusterTotal
                            : props.winAcvData;
                        val =
                          it.type === "currency"
                            ? countOrACVFormatter(
                                data
                                  .map((item) => item[it.col])
                                  .reduce((a, b) => a + b, 0),
                                true,
                                props.Currency_Symbol
                              )
                            : countOrACVFormatter(
                                data
                                  .map((item) => item[it.col])
                                  .reduce((a, b) => a + b, 0),
                                false
                              );

                        if (it.type === "perc") {
                          val = "100%";
                        }
                        return it.type === "perc" ? (
                          <TableCell
                            // style={{ cursor: "pointer" }}
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {val}
                          </TableCell>
                        ) : (
                          <TableCell
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  row: "Total",
                                  cell: props.isMonth
                                    ? uniqueQuarters
                                    : "Total",
                                },
                                props.type,
                                props.quarter
                              )
                            }
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {val}
                          </TableCell>
                        );
                      })}
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
