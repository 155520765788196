import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Grid, CardHeader } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { generateIntegerAbove99 } from "../util/utils";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 140,
    backgroundSize: "contain",
  },
  outer: {
    width: `90%`,
    height: "16rem",
    //paddingTop: `${(90 * 0.5625)}%`,
    margin: "0.625rem 0rem",
    [theme.breakpoints.up("md")]: {
      position: "relative",
      width: "38%",
      height: "16rem",
      // paddingTop: `${35 * 0.5625}%`,
      margin: "1.25rem 1.87rem",
    },
  },
  header: {
    margin: 0,
    [theme.breakpoints.up("md")]: {
      margin: "5px",
      padding: "5px 2px",
    },
  },
  inner: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      height: "100%",
    },
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();

  const [starred, setStarred] = useState();
  useEffect(() => {
    setStarred(props.Position !== null && props.Position !== undefined);
  }, [props]);
  const handleStarClick = () => {
    const curr_val = !starred;

    if (curr_val === true) {
      // add to favourites api call
      console.log("add to favourites api call", props.chartID);
      props.addFavourite(props.dashboard_id);
      setStarred(curr_val);
      const otherCharts = props.charts.filter(
        (it) => it.dashboard_id !== props.dashboard_id
      );
      props.setCharts([
        ...otherCharts,
        { ...props.curr_chart, Position: generateIntegerAbove99() },
      ]);
    } else {
      // remove from favourites api call
      props.handleOpen({
        dashboard_id: props.dashboard_id,
        Chart_Name: props.header,
      });
    }
  };
  // console.log('Insight Card',props)
  useEffect(() => {
    if (
      props.confirmSignal &&
      props.selectedDashboard.dashboard_id === props.dashboard_id
    ) {
      console.log(
        "confirmSignal",
        props.confirmSignal,
        props.dashboard_id,
        props.selectedDashboard
      );
      const curr_val = !starred;
      setStarred(curr_val);
      props.removeFavourite(props.dashboard_id, props.navItem);
      props.cleanAfterConfirm();
    }
  }, [props.confirmSignal]);

  return (
    <Card
      raised={true}
      className={classes.outer}
      id={`navlink_${props.header === 'Performance Scorecard (CT)' ? 'Performance_Scorecard_CT' : props.header.split(" ").join("_")}`}
      style={{ position: "relative" }}
    >
      {/* Clickable overlay for the entire card it calls changeLocation()*/}
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          cursor: "pointer",
          zIndex: 1,
        }}
        onClick={() => props.changeLocation(props.header, props.Nav_Item)}
      ></div>
      <CardHeader
        style={{ padding: 0, margin: 0, cursor: "pointer", zIndex: 2 }}
        title={
          // <CardActionArea
          //   style={{ paddingTop: 10 }}
          //   onClick={() => props.changeLocation(props.header, props.Nav_Item)}
          // >
          <Typography
            style={{ cursor: "pointer" }}
            className={classes.header}
            variant="h2"
            component="h2"
            align="center"
            onClick={() => props.changeLocation(props.header)}
          >
            {props.header}
          </Typography>
          // </CardActionArea>
        }
        action={
          // <CardActionArea style={{ zIndex: 99999 }} onClick={handleStarClick}>
          <IconButton
            style={{
              padding: "0.43rem 0.31rem",
              margin: "0.625rem 1.25rem",
              zIndex: 2,
            }}
            onClick={handleStarClick}
          >
            {starred ? (
              <StarIcon style={{ color: "#FFD700" }} />
            ) : (
              <StarBorderIcon />
            )}
          </IconButton>
          // </CardActionArea>
        }
      />
      {/* <CardActionArea
        onClick={() => props.changeLocation(props.header)}
        className={classes.inner}
      > */}

      <CardContent
        style={{ width: "100%", cursor: "pointer", zIndex: 2 }}
        // onClick={() => props.changeLocation(props.header, props.Nav_Item)}
      >
        <Grid container>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Typography variant="body1" color="textSecondary">
              {props.text}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <CardMedia
              className={classes.media}
              image={props.image}
              title={props.header}
            />
          </Grid>
        </Grid>
      </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
}
