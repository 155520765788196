import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import CopyTablesObject from "../../../components/xlsx-export/CopyTablesObject";
import { getUniqueValues, StyledTableRow } from "../../../util/customFunctions";
import Legend from "../../AsOfDate/Legend";
import BalancedScorecardLegend from "./BalancedLegends";

const useStyle = makeStyles((theme) => ({
  headerCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  dataCell: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function PipelineMetricTable(props) {
  // console.log("TableData", props.tabletype);
  const classes = useStyle();
  const tableRef = React.createRef();
  const legendRef = React.createRef();

  const uniqueCols = props.data.map((l) => l.label).filter(getUniqueValues);
  let uniqeRows = props.data.map((l) => l.subject).filter(getUniqueValues);
  const total = uniqeRows.filter((f) =>
    ["Team", "Company", "Rep"].includes(f)
  )[0];
  uniqeRows = [
    total,
    ...uniqeRows.filter((f) => !["Team", "Company", "Rep"].includes(f)).sort(),
  ];

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const legendChunks = chunkArray(props.legends, uniqueCols.length + 1);

  return (
    <div>
      <TableContainer
        style={{
          padding: "1rem",
          paddingBottom: "3rem",
          margin: "auto",
          width: "60%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
            // marginRight: "10px",
          }}
          align="center"
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
          id={props.id}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                style={{ width: 50, height: 50 }}
              colSpan={1}
                className={classes.headerCell}
              >
                Team / Rep
              </TableCell>
              {uniqueCols.map((label, i) => {
                const lab = label.split("(M");
                const l1 = lab[0];
                const l2 = lab[1];
                return l2 ? (
                  <TableCell
                    align="center"
                    className={classes.headerCell}
                  colSpan={1}
                    style={{
                      color: "Black",
                      width: 50,
                    }}
                  >
                    {l1}
                    <br /> {/* Add line break here */}
                    <span style={{ color: "#2f5496" }}>(M{l2}</span>
                  </TableCell>
                ) : (
                  <TableCell
                    align="center"
                    className={classes.headerCell}
                  colSpan={1}
                    style={{
                      // background: "#e2efd9",
                      color: "Black",
                      width: 50,
                    }}
                  >
                    {label}
                  </TableCell>
                );
              })}
            </TableRow>
            {uniqeRows.map((row) => (
              <StyledTableRow key={row}>
                <TableCell
                colSpan={1}
                  className={classes.headerCell}
                  align="left"
                  style={
                    ["Company", "Team", "Rep"].includes(row)
                      ? { paddingLeft: "1.75rem" }
                      : { paddingLeft: "2.5rem" }
                  }
                >
                  {row}
                </TableCell>
                {uniqueCols.map((col, index) => {
                  const labelParts = col.split("(M");
                  const l1 = labelParts[0];
                  const l2 = labelParts[1];
                  const d = props.data.find(
                    (f) => f.label === col && f.subject === row
                  );
                  let score =
                    d && ![null, undefined, "", 'N/A'].includes(d[d.metric])
                      ? d[d.metric].toFixed(2)
                      : "-";
                   if(d && ['N/A'].includes(d[d.metric])){
                    score = 'N/A'
                   }   
                  const best = d ? d.best : null;
                  const actionThreshold = d ? d.actionThreshold : null;
                  let bg = l2 ? "red" : "";
                  let cl = l2 ? "white" : "black";

                  if (l2 && d && ![null, undefined, ""].includes(d.clr)) {
                    bg = d.clr;
                    cl = d.textClr;
                  }

                  if (l2 && d && ![null, undefined, ""].includes(d.textClr)) {
                    cl = d.textClr;
                  }

                  return (
                    <TableCell
                    colSpan={1}
                      key={index}
                      className={classes.headerCell}
                      style={{
                        color: cl,
                        background: bg,
                        fontSize: props.tabletype === "Summary" ? "1.3rem" : "",
                      }}
                      align="right"
                    >
                      {score}
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            ))}
            <TableRow style={{ border: "none" }}>
              <TableCell
                align="center"
                colSpan={uniqueCols.length + 1}
                className={classes.headerCell}
                style={{ border: "none" }}
              ></TableCell>
            </TableRow>
            {legendChunks.map((legendChunk, index) => (
              <TableRow>
                {legendChunk.map((item, index) => {
                  return (
                    <>
                  
                  <TableCell colSpan={1} align="center" style={{ border: 'none', paddingLeft: '0.5rem', textAlign: 'center' }} className={classes.headerCell}>
                  <span style={{ color: item.color, fontWeight: 'bold', paddingRight: '0.5rem', display: 'inline-block', fontSize: '1.17rem'}}>▇</span>
  {/* <span style={{ color: item.color, fontWeight: 'bold', paddingRight: '0.5rem', display: 'inline-block'}}>◼ ██ ▇ </span> */}
  <span style={{ color: 'black', display: 'inline-block' }}>{item.text}</span>
</TableCell>
                    
                    

                    </>
                  );
                })}

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}