import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { LoginView } from "../Login/Login";
import { App } from "./App";
import useIsAuthenticated from "./hooks/useIsAuthenticated";
import ScoreCardComponent from "./ScorecardComponent";
import GuardedRoute from "./GuardedRoute";
import { connect } from "react-redux";
import { appService } from "./app.service";
import Loading from "./Loading";
import StageProgressionComponent from "./StageProgressionComponent";
import RepBenchmarkingComponent from "./RepBenchmarkingComponent";
import ManagerSummaryComponent from "./ManagerSummaryComponent";
import routerAddedLostOppAnalysis from "./LostOppAnalysisComponent";
import routerAddedClosedFunnelComponent from "./ClosedFunnelComponent";
import routerAddedPipelineWaterfallComponent from "./PipelineWaterfallComponent";
import NewQualifiedPipelineComponent from "./NewQualifiedComponent";
import routerAddedBuildingPipelineComponent from "./BuildingPipelineComponent";
import routerAddedBalancedScoreCard from "./BalancedScoreCardComponent";
import ScoreCardCTComponent from "./ScorecardCTComponent";
import routerAddedProductsByAccount from './ProductsByAccountComponent'
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
// class SkyGeniRouter extends React.Component {
//     componentDidMount() {

//     }
// }
function SkyGeniRouter(props) {
  const [hidden, setHidden] = useState(null);
  const [visibilityChange, setVisibilityChange] = useState("hidden");
  const storageChange = (event) => {
    console.log(localStorage.getItem("skygeni-user"));
    if (event.key === "skygeni-user" && document.hidden) {
      if (localStorage.getItem("skygeni-user") === null) {
        props.signout();
      }
      if (JSON.parse(localStorage.getItem("skygeni-user"))) {
        props.softLogin();
      }
    }
  };

  // const defaultProtectedRouteProps = useIsAuthenticated()
  // console.log(defaultProtectedRouteProps)\
  useEffect(() => {
    console.log("adding storage event listener");

    props.getUser();
    if (typeof document.hidden !== "undefined") {
      setHidden("hidden");
      setVisibilityChange("visibilitychange");
    } else if (typeof document.msHidden !== "undefined") {
      setHidden("msHidden");
      setVisibilityChange("msvisibilitychange");
    } else if (typeof document.webkitHidden !== "undefined") {
      setHidden("webkitHidden");
      setVisibilityChange("webkitvisibilitychange");
    }
    window.addEventListener("storage", (event) => storageChange(event), false);
  }, []);

  const history = useHistory();
const location = useLocation();

// When the user is not logged in and tries to access a protected route
if (!props.loggedIn && location.pathname !== "/login") {
  localStorage.setItem("redirectAfterLogin", location.pathname);
  history.push("/login");
}
  // console.log(hidden);
  // console.log('XYZZZZZ', window.location.pathname)
  return (
    <div style={{ height: "100vh" }}>
      {props.userLoaded ? (
        <Switch>
          <Route path="/login" component={LoginView} />
          <Route path="/scorecard" component={ScoreCardComponent} />
          <Route path="/balancedScorecard/:tab" component={routerAddedBalancedScoreCard} />
          <Route path="/manager-summary" component={ManagerSummaryComponent} />
          <Route path="/new-qualified-pipeline" component={NewQualifiedPipelineComponent} />
          <Route path="/buildingpipelinetowin" component={routerAddedBuildingPipelineComponent} />
          <Route path="/Scorecard_CT" component={ScoreCardCTComponent} />
          <Route path="/whitespace" component={routerAddedProductsByAccount} />
          <Route
            path="/stageProgression/:filters"
            component={StageProgressionComponent}
          />
          <Route
            path="/lostOppAnalysis/"
            component={routerAddedLostOppAnalysis}
          />
          <Route
            path="/pipeline-waterfall/"
            component={routerAddedPipelineWaterfallComponent}
          />
          <Route
            path="/pipeline-waterfall/"
            component={routerAddedPipelineWaterfallComponent}
          />
          <Route
            path="/closed-funnel/"
            component={routerAddedClosedFunnelComponent}
          />
          <Route
            path="/repBenchmarking/:filters"
            component={RepBenchmarkingComponent}
          />
          <GuardedRoute
            {...{
              isAuthenticated: props.loggedIn,
              authenticationPath: "/login",
            }}
            path="/"
            component={App}
          />
          <Redirect from="/" to="/" />
        </Switch>
      ) : (
        <Loading />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { loggedIn, userLoaded } = state.app;
  return {
    loggedIn,
    userLoaded,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getUser: () => {
    dispatch({ type: "get_user_data_request" });
    appService.getUser().then(
      (json) => {
        dispatch({ type: "get_user_data_success", user: json.user });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_user_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_user_data_failure", error });
      }
    );
  },
  signout: () => {
    appService.logout().then(
      (json) => {
        dispatch({ type: "signout", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({ type: "login_failure", error: "Something went wrong" });
        else dispatch({ type: "login_failure", error });
      }
    );
  },
  softLogin: () => {
    dispatch({ type: "soft_login" });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SkyGeniRouter);
