import * as d3 from "d3";
import { computeDomainLimits } from "../../Dashboard/OpenOpportunities/utils";
import {
  countOrACVFormatter,
  dateOppPyramidFormatter,
  getUniqueValues,
} from "../../util/customFunctions";

const D3OpportunityPyramid2 = {};
const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};
D3OpportunityPyramid2.create = (el, config) => {
  const handleDrilldown = (location, oppId) => {
    config.handleDrilldown(location, oppId);
  };
  d3.selection.prototype.last = function () {
    return d3.select(this.nodes()[this.size() - 1]);
  };

  if (config.data) {
    // config.data = config.data.filter(item => item.Name !== "total")
    config.barColor = "#70ad47";
    config.barHeight = 10;
    var marginLeftCalc = config.data;
    console.log(config.data);
    config.margin = {
      top: 20,
      right: 70,
      bottom: 60,
      left: 120,
      // config.type === "Region" || config.type === "Segment" ? marginLeftCalc.sort((a, b) => b.Display_Name.length - a.Display_Name.length)[0]['Display_Name'].length * 9 : marginLeftCalc.sort((a, b) => b['Sales Rep'].length - a['Sales Rep'].length)[0]['Sales Rep'].length * 7
    };
    // if (!config.width) {
    //   config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right : 50;
    // }

    config.width = d3.select(el).node()
      ? d3.select(el).node().getBoundingClientRect().width -
        config.margin.left -
        config.margin.right
      : 100;
    config.height = 600 - config.margin.top - config.margin.bottom;
    var centreSpacing = 60;

    var barData = config.data.sort((a, b) => a.ACV - b.ACV || b.days - a.days);

    // var barData = config.data.map(d => ({
    //   ...d,
    //   ACV: Math.round(d.ACV / 10000) * 10000,
    //   acvRaw: d.ACV
    // })).sort((a,b) => a.ACV - b.ACV || b.days - a.days)

    const uniqueAcv = barData
      .map((a) => a.ACV)
      .filter(getUniqueValues)
      .sort((a, b) => a - b);

    var tooltip = d3
      .select(el)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    var y = d3
      .scaleBand()
      .domain(barData.map((d) => d.ACV))
      .range([config.height, 0])
      .padding(0.5);

    var maxVal = d3.max(barData, (d) => d3.max([d.days]));

    var maxAcv = d3.max(barData, (d) => d3.max([d.ACV]));
    // const { lower_bound, upper_bound } = computeDomainLimits(d3.min(barData, d => d.ACV ), d3.max(barData, d => d.ACV ))
    // console.log(lower_bound, upper_bound)
    var maxAcv =
      d3.max(barData, (d) => d.ACV) > 200000
        ? d3.max(barData, (d) => d.ACV)
        : 200000;
    const { lower_bound, upper_bound } = computeDomainLimits(
      d3.min(barData, (d) => d.ACV),
      maxAcv
    );

    const radius = y.bandwidth() > 20 ? 20 : y.bandwidth();

    var y1 = d3
      .scaleLinear()
      .domain([lower_bound, upper_bound])
      .range([config.height, 0]);
    //  .padding(0.1)

    var x = d3
      .scaleLinear()
      .domain([0, maxVal])
      .range([0, (config.width - centreSpacing) / 2]);

    var svg = d3
      .select(el)
      .append("svg")
      .attr("width", config.width + config.margin.left + config.margin.right)
      .attr("height", config.height + config.margin.top + config.margin.bottom);

    var xReverse = d3
      .scaleLinear()
      .domain([maxVal, 0])
      .range([0, (config.width - centreSpacing) / 2]);

    function make_y_gridlinesLeft() {
      return d3.axisLeft(y1);
    }

    function make_y_gridlinesRight() {
      return d3.axisRight(y1);
    }

    var formatyAxis = d3.format(",");
    var yAxisLeft = d3
      .axisLeft(y1)
      .tickFormat((d) => (d !== 0 ? `${d3.format(formatyAxis)(d)}` : ""))
      .ticks(5);
    var yAxisRight = d3
      .axisRight(y1)
      .tickFormat((d) => (d !== 0 ? "" : ""))
      .ticks(5);

    // create bars for Lost Days
    const chartLost = svg
      .append("g")
      .attr(
        "transform",
        `translate(${config.margin.left},${config.margin.top})`
      );

    var barsLost = chartLost
      .selectAll(".bar")
      .data(barData)
      .enter()
      .append("g");

    // let bitem=barsLost.append("rect")
    // .attr('x',200)
    // .attr('y',200)
    // .attr('height',150)
    // .attr('width',150)
    // .attr("fill",'red')
    //     .on("click",d=>{
    //       console.log(d)
    //       return handleDrilldown('drilldownOppId',d.Opp_ID)
    //     })

    barsLost
      .append("rect")
      .attr("opacity", 0.5)
      .attr(
        "x",
        (d) =>
          (config.width - centreSpacing) / 2 -
          x(d.type === "lost" && (d.days === 0 ? 3 : d.days))
      )
      .attr("y", (d) => y1(d.ACV) - radius / 4)
      .attr("height", radius / 2)
      .attr("width", (d) => {
        return x(d.type === "lost" && d.days);
      })
      .attr("rx", "5")
      .attr("ry", "5")
      .style("fill", "#c55a11")
      .style("cursor", "pointer")
      .on("click", (d) => {
        return handleDrilldown("drilldownOppId", d.Opp_ID);
      })
      .on("mousemove", (d, i) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `
                <div style="border-top:10px solid #c55a11;border-radius:1rem;">
                    <div class="tooltip-box">
                        <h3 class="heading-style">Opp Name : ${d.OppName}</h3>
                        <h3 class="heading-style"> Qualified To Closed Days :  ${
                          d.days
                        }</h3>
                        <h3 class="heading-style">${
                          config.Value_Label
                        }:  ${countOrACVFormatter(d.ACV, true)}</h3>
                    </div>                        
                </div>
                `
          )
          //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
          .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("click", (d) => {
        return handleDrilldown("drilldownOppId", d.Opp_ID);
      })
      .on("mouseout", () => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    var grad = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "grad")
      .attr("x1", "0%")
      .attr("x2", "0%")
      .attr("y1", "100%")
      .attr("y2", "0%");
    grad.append("stop").attr("offset", "50%").style("stop-color", "grey");
    grad.append("stop").attr("offset", "50%").style("stop-color", "white");

    //     svg.append("defs").append("svg:pattern")
    // .attr("id", "grad")
    // .attr("width", radius)
    // .attr("height", radius)
    // .attr("patternUnits", "userSpaceOnUse")
    // .append("svg:image")
    // .attr("xlink:href", `${process.env.REACT_APP_URL}/cleo.svg`)
    // .attr("width", radius)
    // .attr("height", radius)
    // .attr("x", 0)
    // .attr("y", 0);

    // barsLost
    // .append('image')
    // .attr('xlink:href', `${process.env.REACT_APP_URL}/QuarterFilledHarveyBall.svg`)
    // .attr('width', d => {
    //   if((d.days0 !== null && d.days0 >= 0)  ){
    //  return (d.type === 'lost' && radius * 4)
    // } else return 0
    //  })
    // .attr('height', d => {
    //   if((d.days0 !== null && d.days0 >= 0)  ){
    //  return (d.type === 'lost' && radius * 4)
    // } else return 0
    //  })
    // .attr("y",
    //   d => {
    //   if((d.days0 !== null && d.days0 >= 0) ){
    //     return  (d.type === 'lost' && y1(d.ACV))
    //   }

    // })
    //   .attr("x",
    //   d => {
    //     if(d.days0 !== null && d.days0 >= 0){
    //       return (config.width - centreSpacing) / 2 - 45  - x(d.type === "lost" && d.days0)
    //     }
    // })
    // .attr('preserveAspectRatio', 'none')
    // .on("mousemove", (d, i) => {
    //         tooltip.transition().duration(200).style("opacity", 1)
    //         tooltip
    //             .html(`
    //             <div style="border-top:10px solid #c55a11;border-radius:1rem;">
    //                 <div class="tooltip-box">
    //                   <h3 class="heading-style">Opp Name : ${d.OppName}</h3>
    //                   <h3 class="heading-style"> Qualified To Closed Days :  ${d.days}</h3>
    //                   <h3 class="heading-style"> ACV :  ${countOrACVFormatter(d.ACV, true)}</h3>
    //                 </div>
    //             </div>
    //             `)
    //             //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
    //             .style("top", d + "px")
    //             //  .attr("class", "tool")
    //             .style("left", d3.event.pageX + "px")
    //             .style("top", d3.event.pageY - 28 + "px");

    //     })
    //     .on("mouseout", () => {
    //         tooltip.transition().duration(300).style("opacity", 0);
    //     });

    barsLost
      .append("circle")
      .attr("cx", (d) => {
        if (d.days0 !== null && d.days0 >= 0) {
          return (
            (config.width - centreSpacing) / 2 - x(d.type === "lost" && d.days0)
          );
        }
      })
      .attr("cy", (d) => {
        if (d.days0 !== null && d.days0 >= 0) {
          return y1(d.type === "lost" && d.ACV);
        }
      })
      .attr("r", (d) => {
        if (d.days0 !== null && d.days0 >= 0) {
          return d.type === "lost" && radius / 4;
        } else return 0;
      })
      .attr("stroke", "black")
      .attr("fill", "url(#grad)")
      .style("cursor", "pointer")
      .on("mousemove", (d, i) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `
                <div style="border-top:10px solid #c55a11;border-radius:1rem;">
                    <div class="tooltip-box">
                      <h3 class="heading-style">Opp Name : ${d.OppName}</h3>
                      <h3 class="heading-style"> Qualified To Closed Days :  ${
                        d.days
                      }</h3>
                      <h3 class="heading-style"> ${
                        config.Value_Label
                      }:  ${countOrACVFormatter(d.ACV, true)}</h3>
                    </div>                        
                </div>
                `
          )
          //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
          .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("click", (d) => {
        return handleDrilldown("drilldownOppId", d.Opp_ID);
      })
      .on("mouseout", () => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    barsLost
      .append("circle")
      .attr("cx", (d) => {
        if (d.days1 !== null && d.days1 >= 0) {
          return (
            (config.width - centreSpacing) / 2 -
            x(d.type === "lost" && d.days1 + d.days0)
          );
        }
      })
      .attr("cy", (d) => {
        if (d.days1 !== null && d.days1 >= 0) {
          return y1(d.type === "lost" && d.ACV);
        }
      })
      .attr("r", (d) => {
        if (d.days1 !== null && d.days1 >= 0) {
          return d.type === "lost" && radius / 4;
        } else return 0;
      })
      .attr("stroke", "black")
      .attr("stroke-width", "black")
      .attr("fill", "grey")
      .style("cursor", "pointer")
      .on("click", (d) => {
        return handleDrilldown("drilldownOppId", d.Opp_ID);
      })
      .on("mousemove", (d, i) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `
                <div style="border-top:10px solid #c55a11;border-radius:1rem;">
                    <div class="tooltip-box">
                      <h3 class="heading-style">Opp Name : ${d.OppName}</h3>
                      <h3 class="heading-style"> Qualified To Closed Days :  ${
                        d.days
                      }</h3>
                      <h3 class="heading-style"> ${
                        config.Value_Label
                      }:  ${countOrACVFormatter(d.ACV, true)}</h3>
                    </div>                        
                </div>
                `
          )
          //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
          .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    barsLost
      .append("circle")
      .attr("cx", (d) => {
        if (d.days2 !== null && d.days2 >= 0) {
          return (
            (config.width - centreSpacing) / 2 -
            x(d.type === "lost" && d.days2 + d.days1 + d.days0)
          );
        }
      })
      .attr("cy", (d) => {
        if (d.days2 !== null && d.days2 >= 0) {
          return y1(d.type === "lost" && d.ACV);
        }
      })
      .attr("r", (d) => {
        if (d.days2 !== null && d.days2 >= 0) {
          return d.type === "lost" && radius / 4;
        } else return 0;
      })
      .style("cursor", "pointer")
      .on("click", (d) => {
        return handleDrilldown("drilldownOppId", d.Opp_ID);
      })
      .on("mousemove", (d, i) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `
                <div style="border-top:10px solid #c55a11;border-radius:1rem;">
                    <div class="tooltip-box">
                      <h3 class="heading-style">Opp Name : ${d.OppName}</h3>
                      <h3 class="heading-style"> Qualified To Closed Days :  ${
                        d.days
                      }</h3>
                      <h3 class="heading-style"> ${
                        config.Value_Label
                      }:  ${countOrACVFormatter(d.ACV, true)}</h3>
                    </div>                        
                </div>
                `
          )
          //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
          .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    // Win Days population
    // create g element for bars
    var chartWon = svg
      .append("g")
      .attr(
        "transform",
        `translate(${
          config.margin.left +
          (config.width - centreSpacing) / 2 +
          centreSpacing
        },${config.margin.top})`
      );

    var barsWon = chartWon.selectAll(".bar").data(barData).enter().append("g");

    barsWon
      .append("rect")
      .attr("opacity", 0.5)
      .attr("x", 0)
      .attr("y", (d) => y1(d.ACV) - radius / 4)
      .attr("height", radius / 2)
      .attr("width", (d) => x(d.type === "win" && (d.days === 0 ? 3 : d.days)))
      .style("fill", "#548235")
      .style("cursor", "pointer")
      .attr("rx", "5")
      .attr("ry", "5")
      .on("click", (d) => {
        return handleDrilldown("drilldownOppId", d.Opp_ID);
      })
      .on("mousemove", (d, i) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `
                <div style="border-top:10px solid #548235;border-radius:1rem;">
                    <div class="tooltip-box">
                      <h3 class="heading-style">Opp Name : ${d.OppName}</h3>
                      <h3 class="heading-style"> Qualified To Closed Days :  ${
                        d.days
                      }</h3>
                      <h3 class="heading-style"> ${
                        config.Value_Label
                      }:  ${countOrACVFormatter(d.ACV, true)}</h3>
                    </div>                        
                </div>
                `
          )
          //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
          .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    barsWon
      .append("circle")
      .attr("cx", (d) => {
        if (d.days0 !== null && d.days0 >= 0) {
          return x(d.type === "win" && d.days0);
        }
      })
      .attr("cy", (d) => {
        if (d.days0 !== null && d.days0 >= 0) {
          return y1(d.type === "win" && d.ACV);
        }
      })
      .attr("r", (d) => {
        if (d.days0 !== null && d.days0 >= 0) {
          return d.type === "win" && radius / 4;
        } else return 0;
      })
      .attr("stroke", "black")
      .attr("fill", "url(#grad)")
      .style("cursor", "pointer")
      .style("cursor", "pointer")
      .on("click", (d) => {
        return handleDrilldown("drilldownOppId", d.Opp_ID);
      })
      .on("mousemove", (d, i) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `
                <div style="border-top:10px solid #548235;border-radius:1rem;">
                    <div class="tooltip-box">
                      <h3 class="heading-style">Opp Name : ${d.OppName}</h3>
                      <h3 class="heading-style"> Qualified To Closed Days :  ${
                        d.days
                      }</h3>
                      <h3 class="heading-style"> ${
                        config.Value_Label
                      }:  ${countOrACVFormatter(d.ACV, true)}</h3>
                    </div>                        
                </div>
                `
          )
          //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
          .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    barsWon
      .append("circle")
      .attr("cx", (d) => {
        if (d.days1 !== null && d.days1 >= 0) {
          return x(d.type === "win" && d.days1 + d.days0);
        }
      })
      .attr("cy", (d) => {
        if (d.days1 !== null && d.days1 >= 0) {
          return y1(d.type === "win" && d.ACV);
        }
      })
      .attr("r", (d) => {
        if (d.days1 !== null && d.days1 >= 0) {
          return d.type === "win" && radius / 4;
        } else return 0;
      })
      .attr("stroke", "black")
      .attr("stroke-width", "black")
      .attr("fill", "grey")
      .style("cursor", "pointer")
      .on("click", (d) => {
        return handleDrilldown("drilldownOppId", d.Opp_ID);
      })
      .on("mousemove", (d, i) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `
                <div style="border-top:10px solid #548235;border-radius:1rem;">
                    <div class="tooltip-box">
                      <h3 class="heading-style">Opp Name : ${d.OppName}</h3>
                      <h3 class="heading-style"> Qualified To Closed Days :  ${
                        d.days
                      }</h3>
                      <h3 class="heading-style"> ${
                        config.Value_Label
                      }:  ${countOrACVFormatter(d.ACV, true)}</h3>
                    </div>                        
                </div>
                `
          )
          //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
          .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    barsWon
      .append("circle")
      .attr("cx", (d) => {
        if (d.days2 !== null && d.days2 >= 0) {
          return x(d.type === "win" && d.days2 + d.days1 + d.days0);
        }
      })
      .attr("cy", (d) => {
        if (d.days2 !== null && d.days2 >= 0) {
          return y1(d.type === "win" && d.ACV);
        }
      })
      .attr("r", (d) => {
        if (d.days2 !== null && d.days2 >= 0) {
          return d.type === "win" && radius / 4;
        } else return 0;
      })
      .style("cursor", "pointer")
      .on("click", (d) => {
        return handleDrilldown("drilldownOppId", d.Opp_ID);
      })
      .on("mousemove", (d, i) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `
                <div style="border-top:10px solid #548235;border-radius:1rem;">
                    <div class="tooltip-box">
                      <h3 class="heading-style">Opp Name : ${d.OppName}</h3>
                      <h3 class="heading-style"> Qualified To Closed Days :  ${
                        d.days
                      }</h3>
                      <h3 class="heading-style"> ${
                        config.Value_Label
                      }:  ${countOrACVFormatter(d.ACV, true)}</h3>
                    </div>                        
                </div>
                `
          )
          //   .style("margin-left", `${d.closed_date * 0.00000000001}` + 10 + "px")
          .style("top", d + "px")
          //  .attr("class", "tool")
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    // add labels for age groups in the centre of the chart
    // first, a g element for the labels
    var gLabels = svg
      .append("g")
      .attr(
        "transform",
        "translate(" +
          (config.margin.left +
            (config.width - centreSpacing) / 2 +
            "," +
            config.margin.top +
            ")")
      );

    // add an axis for win pop values
    chartWon
      .append("g")
      .attr("transform", "translate(0," + (config.height + 3) + ")")
      .call(d3.axisBottom(x).ticks(config.width / 200, "s"));

    // add an axis for lost pop values
    chartLost
      .append("g")
      .attr("transform", "translate(0," + (config.height + 3) + ")")
      .call(d3.axisBottom(xReverse).ticks(config.width / 200, "s"));

    chartWon.append("g").attr("class", "yaxis").call(yAxisLeft);

    chartLost
      .append("g")
      .attr("class", "yaxis")
      .attr(
        "transform",
        `translate(${config.width / 2 + 30 - centreSpacing},0)`
      )
      .call(yAxisRight);

    chartWon
      .append("g")
      .attr("class", "grid")
      .attr("z-index", -1)
      .call(
        make_y_gridlinesLeft()
          .tickSize(-config.width / 2 + config.margin.right)
          .tickFormat("")
          .ticks(5)
      );

    chartLost
      .append("g")
      .attr("class", "grid")
      .attr("z-index", -1)
      .call(
        make_y_gridlinesRight()
          .tickSize(config.width / 2 + 50 - config.margin.right)
          .tickFormat("")
          .ticks(5)
      );

    chartLost
      .append("text")
      .attr("y", config.height + 30)
      .attr("x", config.width / 4 - 90)
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("fill", "#000000")
      .text("Qualified to Lost days");

    chartWon
      .append("text")
      .attr("y", config.height + 30)
      .attr("x", config.width / 4)
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("fill", "#000000")
      .text("Qualified to Won days");
  }
};
D3OpportunityPyramid2.destroy = (el) => {
  d3.select(el).selectAll("svg").remove();
  d3.select(".tooltip").remove();
  // d3.select('.legend-flex').remove();
};
export default D3OpportunityPyramid2;
