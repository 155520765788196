import { FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import React from 'react'

export default function Region(props) {
    return (
        <Grid justify="flex-start" item xs={12} container component={Paper} style={{ padding: 10, marginRight: 10 }}>
            <Grid item xs={12}>
                <FormControl fullWidth >
                    <InputLabel style={{ color: !props.Region_RollupValue.includes('All') ? '#4472c4' : '#000' }} id="select-vfiscalQuarter-label">Region:</InputLabel>
                    <Select
                        labelId="select-vRegion_Rollup-label"
                        id="select-cRegion_Rollup"
                        value={props.Region_RollupValue}
                        onChange={props.handleChange}
                        label="Region_Rollup"
                        name='Region_Rollup'
                        multiple
                    >
                        <MenuItem value={'All'} >All</MenuItem>

                        {props.funnelCompareFilters !== '' && props.funnelCompareFilters.filter(y => y.type === "Region_Rollup" && y["Region_Rollup"] !== '').sort((a, b) => {
                            if (a.Region_Rollup < b.Region_Rollup) { return -1 }
                            if (a.Region_Rollup > b.Region_Rollup) { return 1 }
                            return 0;
                        })
                            .map(y => <MenuItem key={y['Region_Rollup']} value={y['Region_Rollup']}>{y['Region_Rollup']}</MenuItem>)}
                    </Select>

                </FormControl>
            </Grid>
        </Grid>
    )
}