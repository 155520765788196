import * as d3 from 'd3'

const D3Funnel = {};

D3Funnel.create = (el, config) => {
  // D3 Code to create the chart

  config.margin = { top: 20, right: 40, bottom: 30, left: 300 }
  if (!config.width) {
    config.width = d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right;
  }
  config.height = d3.select(el).node().getBoundingClientRect().height - config.margin.top - config.margin.bottom;
  const data = config.data

  var y = d3.scaleBand()
    .range([config.height, 0])
    .padding(0.1);

  var x = d3.scaleLinear()
    .range([0, config.width]);

  var make_x_axis = () => {
    return d3.axisBottom(x)
  }

  var svg = d3.select(el).append("svg")
    .attr("width", config.width + config.margin.left + config.margin.right)
    .attr("height", config.height + config.margin.top + config.margin.bottom)
    .append("g")
    .attr("transform",
      "translate(" + config.margin.left + "," + config.margin.top + ")");

  // format the data
  data.forEach(function (d) {
    d.score = +d.score;
  });

  // Scale the range of the data in the domains
  x.domain([0, d3.max(data, function (d) { return d.scale[1]; })])
  y.domain(data.map(function (d) { return d.category; }));
  //y.domain([0, d3.max(data, function(d) { return d.score; })]);


  svg.selectAll(".bar2")
    .data(data)
    .enter().append("rect")
    .attr("class", "bar2")
    .attr("fill", "rgb(157, 195, 230)")
    //.attr("x", function(d) { return x(d.score); })
    .attr("width", function (d) { return x(d.scale[1]); })
    .attr("y", function (d) { return y(d.category); })
    .attr("height", y.bandwidth());

  // append the rectangles for the bar chart

  svg.append("g")
    .attr("class", "grid")
    .attr("transform", "translate(0," + config.height + ")")
    .call(make_x_axis()
      .tickSize(-config.height, 0, 0)
      .tickFormat("")
    )

  svg.selectAll('g.grid').selectAll('g.tick').selectAll('line')
    .attr("stroke", "white")
    .attr('opacity', 0.5)

  svg.selectAll(".bar3")
    .data(data)
    .enter().append("rect")
    .attr("class", "bar3")
    .attr("fill", "rgb(197, 224, 180)")
    //.attr("x", function(d) { return x(d.score); })
    .attr("width", function (d) { return x(d.score); })
    .attr("y", function (d) { return y(d.category) + 10; })
    .attr("stroke", "rgb(127, 127, 127)")
    .attr("height", y.bandwidth() - 20);

  svg.selectAll(".marker")
    .data(data)
    .enter().append("path").attr("d", d3.symbol().size(250).type(d3.symbolDiamond)).attr('transform', function (d) { return `translate(${x(d.point)},${y(d.category) + 20})` })
    .attr("class", "circle")
    .attr("fill", "rgb(84, 130, 53)")
  //.attr("cx", function (d) { return x(d.point) })
  //.attr("cy", function (d) { return y(d.category) + 20; })
  //.attr("r", function (d) { return 20 })
  //.attr("height", y.bandwidth());

  // add the x Axis
  svg.append("g")
    .attr("class", "xaxis")
    .attr("transform", "translate(0," + config.height + ")")
    .call(d3.axisBottom(x));

  // add the y Axis
  svg.append("g")
    .attr("class", "yaxis")
    .call(d3.axisLeft(y));

  svg.selectAll(".xaxis text")
    .attr('font-size', '16px')

  svg.selectAll(".yaxis text")
    .attr('font-size', '16px')





};

D3Funnel.update = (el, config) => {

};

D3Funnel.destroy = (el) => {
  // Cleaning code here
  d3.select(el).selectAll('svg').remove();
};

export default D3Funnel;