import { FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core'
import React from 'react'
import { nearestCompletedFiscalQuarter } from '../util/fiscalQuarterFunctions'

export default function FiscalQuarterFilter(props) {
    return (
        <Grid justify="center" item xs={2} container component={Paper} style={{ padding: 10, marginRight: 10 }}>
            <Grid item xs={12}>
                <FormControl fullWidth >
                    <InputLabel style={{ color: !props.closed_fiscal_quarter.includes('All') ? '#4472c4' : '#000' }} id="select-vfiscalQuarter-label">Closed Fiscal Quarter:</InputLabel>
                    <Select
                        labelId="select-vfiscalQuarter-label"
                        id="select-cfiscalQuarter"
                        value={props.closed_fiscal_quarter}
                        onChange={props.handleChange}
                        label="Closed Fiscal Quarter"
                        name='closed_fiscal_quarter'
                        multiple
                    >
                        <MenuItem value={'All'} >All</MenuItem>
                        {props.funnelCompareFilters === '' && <MenuItem value={nearestCompletedFiscalQuarter()} >{nearestCompletedFiscalQuarter()}</MenuItem>}
                        {props.funnelCompareFilters !== '' && props.funnelCompareFilters.filter(y => y.type === props.type && y["closed_fiscal_quarter"] !== '')
                            .map(y => <MenuItem key={y['closed_fiscal_quarter']} value={y['closed_fiscal_quarter']}>{y['closed_fiscal_quarter']}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}
