import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
} from "../../util/customFunctions";

import * as d3 from "d3";
import { getMixKey } from "../../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
} from "../../util/customSortings";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
const useStyle = makeStyles((theme) => ({
  content: { width: "100%" },
  sticky: {
    position: "sticky",
    left: -1,
    background: "white",
    // outline: "0.5px solid lightgrey",
    // outlineOffset: "-1.5px",
    //  pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px", // Adjust the thickness as needed
      background: "lightgrey", // Adjust the color as needed
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function CompetitorPerformanceTable(props) {
  const classes = useStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isSticky, setIsSticky] = useState(false);

  let total;

  const quarter = props.data.filter((item) => item.quarter !== "total");
  const topCompetitors = props.top.map((comp) => comp.competitor).slice(0, 8);
  const uniqueQuarters = quarter
    .map((item) => item.quarter)
    .filter(getUniqueValues)
    .sort();
  const uniqueCompetitors = topCompetitors;
  const status = props.status.map((st) => st.stage_name);

  const netTotal = uniqueQuarters.map((cell, j) => {
    const total = props.net
      .filter((item) => item.quarter === cell)
      .map((item) => item.acv)
      .reduce((a, b) => a + b, 0);
    return total;
  });

  const netTotalObj = netTotal.reduce((a, b) => a + b, 0);

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer2");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: "1rem" }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              // marginBottom: "10px",
              marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="flex-start" style={{ padding: "1rem" }}>
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id="myTableContainer2"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              ref={tableRef}
              data-test="Top 8 known primary competitors  Net Win Trend"
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              data-test="primary-competitor-net-table"
            >
              <TableHead>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ minWidth: 150 }}
                  >
                    {"Primary Competitors"}
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 90,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                padding: "10px 0",
                                minWidth: 90,
                              }
                        }
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueQuarters.length > 1 &&
                          uniqueQuarters.length % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 90,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                padding: "10px 0",
                                minWidth: 90,
                              }
                        }
                        align="center"
                        className={`${classes.repHeader} ${classes.tableColWidth}`}
                        colSpan={1}
                      >
                        {"Total"}
                      </TableCell>
                    </React.Fragment>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 400, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueCompetitors.map((owner, i) => {
                  total = i + 1;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // className={`${classes.table}`}
                        className={
                          isSticky
                            ? `${classes.sticky} ${classes.table}`
                            : classes.table
                        }
                        style={
                          isSticky && i % 2 === 1
                            ? { background: "#f5f5f5" }
                            : {}
                        }
                        colSpan={1}
                      >
                        {owner}
                      </TableCell>
                      {uniqueQuarters.map((qtr, j) => {
                        const WonData = props.data
                          .filter(
                            (item) =>
                              item.quarter === qtr &&
                              item.status === status[0] &&
                              item.competitor === owner
                          )
                          .map((item) => item.acv)[0];
                        const LostData = props.data
                          .filter(
                            (item) =>
                              item.quarter === qtr &&
                              item.status === status[1] &&
                              item.competitor === owner
                          )
                          .map((item) => item.acv)[0];
                        const net = WonData - LostData;
                        return (
                          <>
                            <TableCell
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {net === "undefiend" ||
                              net === "null" ||
                              _isNaN(net)
                                ? "-"
                                : countOrACVFormatter(net, true)}{" "}
                            </TableCell>
                          </>
                        );
                      })}
                      {uniqueQuarters.length >= 1 && (
                        <React.Fragment>
                          <TableCell
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {props.net
                              .filter(
                                (item) =>
                                  item.competitor === owner &&
                                  item.status === status[0]
                              )
                              .map((item) => item.acv)
                              .reduce((a, b) => a + b, 0) === "undefiend" ||
                            props.net
                              .filter(
                                (item) =>
                                  item.competitor === owner &&
                                  item.status === status[0]
                              )
                              .map((item) => item.acv)
                              .reduce((a, b) => a + b, 0) === "null" ||
                            _isNaN(
                              props.net
                                .filter(
                                  (item) =>
                                    item.competitor === owner &&
                                    item.status === status[0]
                                )
                                .map((item) => item.acv)
                                .reduce((a, b) => a + b, 0)
                            )
                              ? "-"
                              : countOrACVFormatter(
                                  props.net
                                    .filter(
                                      (item) =>
                                        item.competitor === owner &&
                                        item.status === status[0]
                                    )
                                    .map((item) => item.acv)
                                    .reduce((a, b) => a + b, 0),
                                  true
                                )}{" "}
                          </TableCell>
                        </React.Fragment>
                      )}
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    align="left"
                    // className={`${classes.repHeader} ${classes.tableColWidth}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader} ${classes.tableColWidth}`
                        : `${classes.repHeader} ${classes.tableColWidth}`
                    }
                    colSpan={1}
                    // style={{ fontWeight: 600 }}
                    style={
                      isSticky && total % 2 === 1
                        ? { background: "#f5f5f5", fontWeight: 600 }
                        : { fontWeight: 600 }
                    }
                  >
                    Total
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    const total = props.net
                      .filter((item) => item.quarter === cell)
                      .map((item) => item.acv)
                      .reduce((a, b) => a + b, 0);
                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          align="right"
                          className={classes.repNumber}
                          colSpan={1}
                          style={{ fontWeight: 600 }}
                        >
                          {total === "undefiend" ||
                          total === "null" ||
                          _isNaN(total)
                            ? "-"
                            : countOrACVFormatter(total, true)}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ fontWeight: 600 }}
                      >
                        {netTotalObj === "undefiend" ||
                        netTotalObj === "null" ||
                        _isNaN(netTotalObj)
                          ? "-"
                          : countOrACVFormatter(netTotalObj, true)}{" "}
                      </TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
