import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  StyledTableRow,
  countOrACVFormatter,
  dateFormatter,
  dateFormatterV3,
} from "../../util/customFunctions";
import { sortAccountIdsByColumn } from "./utils";
import OppNameTooltip from "./OppNameTooltip";
import { CopyToClipboardTableContainer } from "../../components/Containers/CopyToClipboardTableContainer";
import StickyStyledTableHeader from "../../components/Containers/StickyStyledTableHeader";
import StickyStyledTableRow from "../../components/Containers/StickyStyledTableRow";
import SortingTableCell from "../../components/Containers/SortingTableCell";

const useStyles = makeStyles((theme) => ({
  content: { width: "100%" },
  sticky: {
    position: "sticky",
    left: -1,
    background: "white",
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "1.5px",
      background: "lightgrey",
    },
  },
  stickyHeader: {
    position: "sticky",
    top: -1,
    background: "white",
    outline: "lightgrey solid 0.5px",
    outlineOffset: "-1.5px",
    zIndex: 5,
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px",
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
    textAlign: "center",
  },
  borderTopCell: {
    border: "none",
    borderTop: "0.5px solid lightgrey",
    background: "white",
    width: "10px !important", // Set the width of the OppName column with !important
    maxWidth: "10px !important",
    zIndex: 999999,
  },
  emptyCell: {
    border: "none",
    background: "white",
    width: "10px !important", // Set the width of the OppName column with !important
    maxWidth: "10px !important",
    zIndex: 999999,
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 60,
  },
  accNameCol: {
    width: "90px !important", // Set the width of the OppName column with !important
    maxWidth: "90px !important",
    // wordBreak: "break-word",
    overflowWrap: "break-word", // Allow text to wrap to the next line
    whiteSpace: "normal", // Allow text to wrap to the next line
  },
  oppNameCol: {
    width: "80px !important", // Set the width of the OppName column with !important
    maxWidth: "80px !important",
    // wordBreak: "break-word",
    overflowWrap: "break-word", // Allow text to wrap to the next line
    whiteSpace: "normal", // Allow text to wrap to the next line
    [theme.breakpoints.up("xl")]: {
      width: "250px !important", // Set the width of the OppName column with !important
      maxWidth: "250px !important",
    },
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));

const AccountExpansionTable = (props) => {
  // console.log(props.data, "accountExpansionDebug");
  const classes = useStyles();

  const [sortColumn, setSortColumn] = useState("delta");
  const [order, setOrder] = useState("desc");
  const [sortedAccountId, setSortedAccountId] = useState([]);

  const sortedByAccountName = sortAccountIdsByColumn(
    props.data.accountInfo,
    "Account_Name",
    "asc"
  );
  const sortedByAccountDelta = sortAccountIdsByColumn(
    props.data.accountInfo,
    "delta",
    "desc"
  );

  const handleSort = (key) => {
    console.log("clickedkey", key);
    setSortColumn(key);
    if (order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
  };

  useEffect(() => {
    console.log("insideUseEffect");
    const ids = sortAccountIdsByColumn(
      props.data.accountInfo,
      sortColumn,
      order
    );
    setSortedAccountId(ids);
  }, [order, sortColumn]);

  useEffect(() => {
    const ids = sortAccountIdsByColumn(
      props.data.accountInfo,
      sortColumn,
      order
    );
    // console.log("outsidesortedAccountId", sortedAccountId);

    setSortedAccountId(ids);
  }, []);

  // console.log();
  // console.log("outsidesortedAccountId", sortedAccountId);
  return (
    <CopyToClipboardTableContainer datatest="AccountValueExpansion_Detail">
      <StickyStyledTableHeader>
        <StickyStyledTableRow className={classes.fontBold}>
          <TableCell className={classes.emptyCell}></TableCell>
          <TableCell
            className={classes.repHeader}
            colSpan={7}
            style={{ background: props.priorColor }}
          >
            {`Prior Fiscal Year (FY ${props.data.priorFiscalYear}) Won Opps`}
          </TableCell>

          <TableCell
            className={classes.repHeader}
            colSpan={7}
            style={{ background: props.currentColor }}
          >
            {`Current Fiscal Year (FY ${props.data.currentFiscalYear}) Opps`}
          </TableCell>
          <TableCell className={classes.emptyCell}></TableCell>
        </StickyStyledTableRow>
        <StickyStyledTableRow className={classes.fontBold}>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth} ${classes.accNameCol}`}
            onClick={() => {
              const isMoreThenOneRow = sortedAccountId.length > 1;
              console.log("cliked");
              if (isMoreThenOneRow) {
                handleSort("Account_Name");
                console.log("Insidecliked");
              }
            }}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Account
            <SortingTableCell
              col={"Account_Name"}
              sortColumn={sortColumn}
              order={order}
              isMoreThenOneRow={Object.keys(props.data.accountInfo).length > 1}
            />
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.oppNameCol}`} // Apply the new class here
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Opp Name
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Opp Type
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Opp Identifier
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Parent Opp Identifier
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            ACV
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Won Date
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Total Account ACV for Prior FY Opps
          </TableCell>

          <TableCell
            className={`${classes.repHeader} ${classes.oppNameCol}`} // Apply the new class here
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Opp Name
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Opp Identifier
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Parent Opp Identifier
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Opp Type
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            ACV
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Projected Close Date
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
          >
            Total Account ACV for Current FY Opps
          </TableCell>
          <TableCell
            className={`${classes.repHeader} ${classes.tableColWidth}`}
            style={{
              outline: "lightgrey solid 0.5px",
              verticalAlign: "top",
            }}
            onClick={() => {
              const isMoreThenOneRow = sortedAccountId.length > 1;
              if (isMoreThenOneRow) {
                handleSort("delta");
              }
            }}
          >
            Account ACV Delta
            <SortingTableCell
              col={"delta"}
              sortColumn={sortColumn}
              order={order}
              isMoreThenOneRow={Object.keys(props.data.accountInfo).length > 1}
            />
          </TableCell>
        </StickyStyledTableRow>
      </StickyStyledTableHeader>
      <TableBody>
        {sortedAccountId.map((accountId, accountIdx) => {
          let rowSpan,
            parentOppsDataAvailable = false,
            currentOppsDataAvailable = false;
          const accountName = props.data.accountInfo[accountId].Account_Name;
          // if (props.data.parentOppsMap[accountId]) {
          //   accountName = props.data.parentOppsMap[accountId].Account_Name;
          // } else if (props.data.currentOppsMap[accountId]) {
          //   accountName = props.data.currentOppsMap[accountId].Account_Name;
          // }
          let numParentOpps = 0,
            numCurrentOpps = 0,
            parentOpps = [],
            currentOpps = [];
          if (props.data.parentOppsMap[accountId]) {
            numParentOpps = props.data.parentOppsMap[accountId].numOpps;
            parentOpps = props.data.parentOppsMap[accountId].opps;
          }
          if (props.data.currentOppsMap[accountId]) {
            numCurrentOpps = props.data.currentOppsMap[accountId].numOpps;
            currentOpps = props.data.currentOppsMap[accountId].opps;
          }
          const parentOppsSetForCurrentOpps = new Set(
            currentOpps.map((it) => it.Parent_Opp_Identifier)
          );
          const leafNodesFromParentOpps = new Set(
            props.data.accountInfo[accountId].parentOppsLeafNodes
          );

          const noCurrentOppParentNode = new Set(
            [...leafNodesFromParentOpps].filter(
              (x) => !parentOppsSetForCurrentOpps.has(x)
            )
          );

          rowSpan = props.data.accountInfo[accountId]
            ? Math.max(numParentOpps, numCurrentOpps, 1)
            : Math.max(numParentOpps, numCurrentOpps);

          return (
            <>
              <TableRow
                style={
                  accountIdx % 2 == 0 ? { backgroundColor: "#f5f5f5" } : {}
                }
                key={accountIdx}
              >
                <TableCell
                  rowSpan={rowSpan + 1}
                  className={`${classes.repText} ${classes.fontBold}`}
                  data-test={`Account_${accountIdx}`}
                >
                  {accountName}
                </TableCell>
              </TableRow>
              {Array(rowSpan)
                .fill()
                .map((it, idx) => {
                  const parentOpp = parentOpps[idx];
                  const currentOpp = currentOpps[idx];

                  return (
                    <TableRow
                      style={
                        accountIdx % 2 == 0
                          ? { backgroundColor: "#f5f5f5" }
                          : {}
                      }
                    >
                      {!parentOpp ? (
                        Array(props.data.parentOppsMap[accountId] ? 6 : 7)
                          .fill()
                          .map((it) => (
                            <TableCell
                              className={
                                idx === 0
                                  ? classes.borderTopCell
                                  : classes.emptyCell
                              }
                            ></TableCell>
                          ))
                      ) : (
                        <>
                          {/* <OppNameTooltip
                            OppName={parentOpp.OppName}
                            color={priorColor}
                          > */}
                          <TableCell
                            onClick={() =>
                              props.handleSecondLevelDrilldown(
                                "drilldownOppId",
                                parentOpp.Opp_ID
                              )
                            }
                            className={`${classes.repText} ${classes.drilldownCell} ${classes.oppNameCol}`}
                            style={{ width: "90px !important" }} // Inline style with !important
                            // data-test="drilldown"
                          >
                            {parentOpp.OppName}
                          </TableCell>
                          {/* </OppNameTooltip> */}
                          <TableCell className={classes.repText}>
                            {parentOpp.Cust_Type}
                          </TableCell>
                          <TableCell
                            style={
                              noCurrentOppParentNode.has(
                                parentOpp.Current_Opp_Identifier
                              )
                                ? { background: props.priorYearOppHighlight }
                                : {}
                            }
                            onClick={() =>
                              props.handleSecondLevelDrilldown(
                                "drilldownOppId",
                                parentOpp.Opp_ID
                              )
                            }
                            className={`${classes.repText} ${
                              parentOpp.Opp_ID !== null
                                ? classes.drilldownCell
                                : ""
                            }`}
                            data-test="drilldown"
                          >
                            {parentOpp.Current_Opp_Identifier}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              props.handleSecondLevelDrilldown(
                                "drilldownOppId",
                                parentOpp.Parent_Opportunity__c
                              )
                            }
                            className={`${classes.repText} ${
                              parentOpp.Parent_Opportunity__c !== null
                                ? classes.drilldownCell
                                : ""
                            }`}
                            data-test="drilldown"
                          >
                            {parentOpp.Parent_Opp_Identifier}
                          </TableCell>
                          <TableCell className={classes.repNumber}>
                            {countOrACVFormatter(parentOpp.ACV, true)}
                          </TableCell>
                          <TableCell className={classes.repNumber}>
                            {dateFormatterV3(parentOpp.Closed_Date)}
                          </TableCell>
                          {idx === 0 && (
                            <TableCell
                              rowSpan={rowSpan + 1}
                              className={`${classes.repNumber} ${classes.fontBold}`}
                            >
                              {countOrACVFormatter(
                                props.data.parentOppsMap[accountId].totalACV,
                                true
                              )}
                            </TableCell>
                          )}
                        </>
                      )}

                      {!currentOpp ? (
                        Array(7)
                          .fill()
                          .map((it) => (
                            <TableCell
                              className={
                                idx === 0
                                  ? classes.borderTopCell
                                  : classes.emptyCell
                              }
                            ></TableCell>
                          ))
                      ) : (
                        <>
                          {/* <OppNameTooltip
                            OppName={currentOpp.OppName}
                            color={currentColor}
                          > */}
                          <TableCell
                            onClick={() =>
                              props.handleSecondLevelDrilldown(
                                "drilldownOppId",
                                currentOpp.Opp_ID
                              )
                            }
                            className={`${classes.repText} ${classes.drilldownCell} ${classes.oppNameCol}`}
                            style={{ width: "90px !important" }} // Inline style with !important
                            data-test="drilldown"
                          >
                            {currentOpp.OppName}
                          </TableCell>
                          {/* </OppNameTooltip> */}
                          <TableCell
                            onClick={() =>
                              props.handleSecondLevelDrilldown(
                                "drilldownOppId",
                                currentOpp.Opp_ID
                              )
                            }
                            className={`${classes.repText} ${
                              currentOpp.Opp_ID !== null
                                ? classes.drilldownCell
                                : ""
                            }`}
                            data-test="drilldown"
                          >
                            {currentOpp.Current_Opp_Identifier}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              props.handleSecondLevelDrilldown(
                                "drilldownOppId",
                                currentOpp.Parent_Opportunity__c
                              )
                            }
                            className={`${classes.repText} ${
                              currentOpp.Parent_Opportunity__c !== null
                                ? classes.drilldownCell
                                : ""
                            }`}
                            data-test="drilldown"
                          >
                            {currentOpp.Parent_Opp_Identifier}
                          </TableCell>
                          <TableCell className={classes.repText}>
                            {currentOpp.Cust_Type}
                          </TableCell>
                          <TableCell className={classes.repNumber}>
                            {countOrACVFormatter(currentOpp.ACV, true)}
                          </TableCell>
                          <TableCell className={classes.repNumber}>
                            {dateFormatterV3(currentOpp.Projected_Close_Date)}
                          </TableCell>
                          {idx === 0 && (
                            <TableCell
                              rowSpan={rowSpan}
                              className={`${classes.repNumber} ${classes.fontBold}`}
                            >
                              {countOrACVFormatter(
                                props.data.currentOppsMap[accountId].totalACV,
                                true
                              )}
                            </TableCell>
                          )}
                        </>
                      )}

                      {idx === 0 && (
                        <TableCell
                          rowSpan={rowSpan + 1}
                          className={`${classes.repNumber} ${classes.fontBold}`}
                        >
                          {countOrACVFormatter(
                            props.data.accountInfo[accountId].delta,
                            true
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </>
          );
        })}
      </TableBody>
    </CopyToClipboardTableContainer>
  );
};

export default AccountExpansionTable;
