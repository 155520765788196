import React, { useMemo } from "react";

const TooltipAGGrid = (props) => {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: props.color || "white" }}
    >
      <p>
        <span>{data.FullName}</span>
      </p>
      <p>
        <span>Country: </span> {data.Quota}
      </p>
      <p>
        <span>Total: </span> {data.Ramp_Status}
      </p>
    </div>
  );
};

export default TooltipAGGrid;
