import {
  Card,
  Divider,
  Grid,
  Paper,
  Typography,
  withStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  styled,
  Tooltip,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import YTDTable from "../RegionPerformance/YTDTable";
import D3RepPerformanceBarQT from "./D3RepPerformaceBarQT";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import QuotaAttainmentDrilldown from "./QuotaAttainmentDrilldown";
import {
  getUniqueValues,
  stageFormattter,
  _isNaN,
  getDefaultSelectedFiscalQuarters,
} from "../../util/customFunctions";
import Legend from "../AsOfDate/Legend";
import QTTable from "./QTTable";
import QTDetailTable from "./QTDetailTable";
import { ToggleButton, ToggleButtonGroup, tooltipClasses } from "@mui/material";
import Drilldown from "../../components/Drilldown/Drilldown";

import YearQuarterFilterCombo from "../../Filters/YearQuarterFilterCombo";
import { isThisISOWeek } from "date-fns";
import QuotaAttainmentDetailDrilldown from "./QuotaAttainmentDetailDrilldown";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import DataTable from "./DataTable";
import addHeaderdescription from "../../components/HeaderDescription";
import { Autocomplete } from "@material-ui/lab";
import NumberFormat from "react-number-format";
const styles = (theme) => ({
  root: {},

  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    color: "inherit",
  },
  table1: {
    background: "#FAFAFA",
    border: "none",
    width: 5,
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    color: "white",
    background: "#4472C4",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 5px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});
class QuotaAttainmentV3 extends Component {
  filters = ["fiscal_quarter", "Cust_Type", "Record_Type_Name__c", "team", "Acct_Industry", "Acct_Size_Segment", "Lead_Source", "Solution_Cat", "Account_Designation__c"];
  state = {
    fiscal_quarter: ["All"],
    closed_fiscal_year: ["All"],
    Cust_Type: ["All"],
    Record_Type_Name__c: ["All"],
    team: ["All"],
    Acct_Industry: ["All"],
    Acct_Size_Segment: ["All"],
    Lead_Source: ["All"],
    Solution_Cat: ["All"],
    Account_Designation__c: ["All"],
    disabled: ["year", "month", "quarter"],
    activeTimeFilter: "closed_fiscal_quarter",
    selectedDrilldown: "",
    drilldownData: "",
    drilldownText: "",
    selectedDrilldownQY: "",
    selectDrillStagee: "",
    filtersForDrilldown: ["All"],
    secondLevelLocation: null,
    toggleValue: "PercSort", 
    drildownHeader: 'Quota Attainment Distribution',
    acv: 0,
  acvlt: 0,
  };

  componentDidMount() {
    this.props.getQuotaAttainmentCompareFilters(this.props.company);
    window.addEventListener("resize", this.onResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.quotaAttainmentCompareFilters !== "" &&
      prevProps.quotaAttainmentCompareFilters === ""
    ) {
      const currentQuarter = getDefaultSelectedFiscalQuarters(
        this.props.quotaAttainmentCompareFilters.fiscal_quarter,
        "fiscal_quarter"
      );
      // console.log(currentQuarter)
      this.setState({
        ...prevState,
        fiscal_quarter: currentQuarter,
        filtersForDrilldown: currentQuarter,
      });
    }
    
  }

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.fiscal_quarter.includes("All")) {
      filtersArray = [...filtersArray, this.state.fiscal_quarter];
    }

   

    return filtersArray[0];
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));

      if (this.state.acv !== null && this.state.acv !== 0) {
        filters = [
          ...filters,
          {
            name: "acv",
            value:
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv,
            string: false,
          },
        ];
      }
  
      if (this.state.acvlt !== null && this.state.acvlt !== 0) {
        filters = [
          ...filters,
          {
            name: "acvlt",
            value:
              this.state.acvlt && typeof this.state.acvlt === "string"
                ? this.state.acvlt.match(/,/g)
                  ? this.state.acvlt.match(/,/g).length === 1
                    ? parseInt(this.state.acvlt) * 1000
                    : this.state.acvlt.match(/,/g).length === 2
                    ? parseInt(this.state.acvlt) * 1000000
                    : parseInt(this.state.acvlt)
                  : parseInt(this.state.acvlt)
                : this.state.acvlt,
            string: false,
          },
        ];
      }

    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
    this.props.getQuotaAttainmentData(
      this.props.company,
      filters
    );
  };
  
  handleChange = (e) => {
    if (
      e.target.name === "closed_fiscal_year" &&
      (e.target.value.length === 0 || e.target.value.includes("All"))
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
      });
    } else if (e.target.name === "closed_fiscal_year") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
      });
    } else if (e.target.name === "closed_fiscal_quarter") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_quarter",
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  handleChangeLocation = (location, stage, Open_Datetime) => {
    const a = [].concat
      .apply(
        [],
        this.props.newBins
          .filter((x) => x[stage.x] === stage.cell)
          .map((x) => x.drilldown)
      )
      .sort((a, b) => b.attainment === a.attainment);
    const b = this.props.quota_attainment_with_perc.filter(
      (item) => item[this.state.activeTimeFilter] !== "total"
    );
    const uniqueOwner = this.props.quota_attainment_with_perc
      .filter((item) => item[this.state.activeTimeFilter] !== "total")
      .map((x) => x.Owner_Name)
      .filter(getUniqueValues);
    const uniqueQtr = this.props.quota_attainment_with_perc
      .filter((item) => item[this.state.activeTimeFilter] !== "total")
      .map((x) => x[this.state.activeTimeFilter])
      .filter(getUniqueValues);
    // console.log(uniqueQtr)
    // console.log(uniqueOwner.map(owner => b.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQtr.length &&  owner).filter(x => x !== false))
    const uniqueRep = uniqueOwner
      .map(
        (owner) =>
          b.filter((x) => x.Owner_Name === owner && x.Quota !== null).length <
            uniqueQtr.length && owner
      )
      .filter((x) => x !== false);

    this.setState({
      ...this.state,
      location: location,
      selectedDrilldown:
        stage.cell !== "total" ? stage.cell : this.state.filtersForDrilldown,
      selectDrillStagee: stage.x,
      drilldownData: {
        uniqueRep: uniqueRep,
        quarters: stage.x,
        uniqueQuarters: stage.uniqueQuarters,
        cell: stage.cell,
        label: stage.y === "total" ? "All" : stage.row,
        repNo: stage.z,
        opportunities:
          stage.y === "total"
            ? [].concat
                .apply(
                  [],
                  this.props.newBins
                    .filter((x) => x[stage.x] === stage.cell)
                    .map((x) => x.drilldown)
                )
                .sort((a, b) =>
                  b.attainment === a.attainment
                    ? b.acv - a.acv
                    : b.attainment - a.attainment
                )
            : [].concat.apply(
                [],
                this.props.newBins
                  .filter(
                    (x) => x.label === stage.row && x[stage.x] === stage.cell
                  )
                  .map((x) => x.drilldown)
              ),
      },
    });
  };

  handleChangeLocationDrill = (location, stage, team, segment, type) => {
    let header = 'Quota Attainment Distribution';
    if (type === 'beg') {
        header = 'Stage 1 or higher pipe at Qtr. Start';
    }

    this.setState({
        location: location,
        secondLevelLocation: location,
        selectedDrilldown: stage.cell !== "Total" ? stage.cell : this.state.filtersForDrilldown,
        selectedDrilldownQY: stage.quarter,
        drildownHeader: header,
    });

    console.log(segment, 'SSSSSSS');

    if (location !== "overview") {
        let filters = [
            {
                name: type === 'beg' ? 'fiscal_quarter' : 'closed_fiscal_quarter',
                value: stage.cell !== "Total" ? [stage.cell] : stage.uniqueQuarters,
                string: true,
            },
            {
                name: "Owner_Name",
                value: stage.row !== "total" ? [stage.row] : stage.uniqueRep,
                string: true,
            },
        ];

        if (this.state.team[0] !== "All") {
            filters.push({
                name: "team",
                value: Array.isArray(this.state.team) ? this.state.team : [this.state.team],
                string: true,
            });
        }

        if (team) {
            filters.push({
                name: "team",
                value: Array.isArray(team) ? team : [team],
                string: true,
            });
        }

        if (stage.cell === "total" && this.state.Ramp_Status[0] !== "All") {
            filters.push({
                name: "Ramp_Status",
                value: this.state.Ramp_Status,
            });
        }

        if (stage.rampStatus) {
            filters.push({
                name: "Ramp_Status",
                value: Array.isArray(stage.rampStatus) ? stage.rampStatus : [stage.rampStatus],
            });
        }

        let filtersv2 = this.filters
        .map((f) => {
          return {
            name: f,
            value:
              f === "rep"
                ? this.state[f].includes("All")
                  ? "All"
                  : this.getSelectedRepIDs()
                : this.state[f],
            string: true,
          };
        })
        .filter(
          (f) =>
            (!Array.isArray(f.value) && f.value !== "All") ||
            !f.value.includes("All")
        )
        .filter((f) => !this.state.disabled.includes(f.name));

        filtersv2 = filtersv2.filter(f => !['team', 'fiscal_quarter', 'Owner_Name'].includes(f.name))

        filters = [...filters, ...filtersv2]

        if (this.state.acv !== null && this.state.acv !== 0) {
          filters = [
            ...filters,
            {
              name: "acv",
              value:
                this.state.acv && typeof this.state.acv === "string"
                  ? this.state.acv.match(/,/g)
                    ? this.state.acv.match(/,/g).length === 1
                      ? parseInt(this.state.acv) * 1000
                      : this.state.acv.match(/,/g).length === 2
                      ? parseInt(this.state.acv) * 1000000
                      : parseInt(this.state.acv)
                    : parseInt(this.state.acv)
                  : this.state.acv,
              string: false,
            },
          ];
        }
    
        if (this.state.acvlt !== null && this.state.acvlt !== 0) {
          filters = [
            ...filters,
            {
              name: "acvlt",
              value:
                this.state.acvlt && typeof this.state.acvlt === "string"
                  ? this.state.acvlt.match(/,/g)
                    ? this.state.acvlt.match(/,/g).length === 1
                      ? parseInt(this.state.acvlt) * 1000
                      : this.state.acvlt.match(/,/g).length === 2
                      ? parseInt(this.state.acvlt) * 1000000
                      : parseInt(this.state.acvlt)
                    : parseInt(this.state.acvlt)
                  : this.state.acvlt,
              string: false,
            },
          ];
        }

        if (segment === 'team') {
            const filteredFilters = filters.filter(f => f.name !== 'Owner_Name');
            if (type === 'beg') {
                this.props.getBeginningOftheQuarterAttainmentDrilldown(filteredFilters);
            } else {
                this.props.getTeamAttainmentDrilldown(filteredFilters);
            }
        } else {
            if (type === 'beg') {
                this.props.getBeginningOftheQuarterAttainmentDrilldown(filters);
            } else {
                this.props.getQuotaAttainmentDrilldown(filters);
            }
        }
    } else {
        this.props.clearDrilldownData();
    }
};


  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  handleChangeToggle = (e) => {
    // const screenPosition = window.scrollY;
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };


  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer}>
        <Grid
          item
          container
          className={classes.grid}
          style={{ marginBottom: 10, marginLeft: 25 }}
          xs={12}
        >
          {/* <Grid item xs={12} justify="center">
            <Typography variant="h2" align="center">
            Beginning Of Quarter Qualified Pipe Attainment
            </Typography>
          </Grid>
          <Grid item xs={12} justify="left" style={{ margintop: 10 }}>
            <Typography variant="body1">
            Beginning Of Quarter Qualified Pipe Attainment.
            </Typography>
          </Grid> */}
          {addHeaderdescription(
        `Beginning Of Quarter Qualified Pipe Attainment`,
        `Beginning Of Quarter Qualified Pipe Attainment`
      )}
          {this.props.quotaAttainmentCompareFilters !== undefined ? (
            <Grid
              className={classes.grid}
              item
              container
              justify="flex-start"
              xs={12}
            >
              <Grid container item style={{ padding: 10 }} xs={2}>
              <Grid item spacing={2} xs={12} container component={Paper} style={{ paddingTop: 8 }}>

              <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth  id="filter_cfq">
                  <InputLabel
                    id="select-fiscalQuarter-label"
                    style={{
                      color: !this.state.fiscal_quarter.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                  >
                    Fiscal Quarter:
                  </InputLabel>
                  <Select
                    data-test="closed-fiscal-quarter"
                    labelId="select-fiscalQuarter-label"
                    id="select-fiscalQuarter"
                    value={this.state.fiscal_quarter}
                    onChange={this.handleChange}
                    label="Fiscal Quarter"
                    name="fiscal_quarter"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.quotaAttainmentCompareFilters !== "" &&
                      this.props.quotaAttainmentCompareFilters.fiscal_quarter
                        .filter((item) => item.fiscal_quarter !== "")
                        .map((y) => (
                          <MenuItem
                            key={y.fiscal_quarter}
                            value={y.fiscal_quarter}
                          >
                            {y.fiscal_quarter}
                          </MenuItem>
                        ))}
                  </Select>
                  </FormControl>
              </Grid>
              </Grid>
              </Grid>

              <Grid container item style={{ padding: 10 }} xs={2}>
              <Grid item spacing={2} xs={12} container component={Paper} style={{ paddingTop: 8 }}>

              <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth  id="filter_cfq">
                  <InputLabel
                    id="select-fiscateamlQuarter-label"
                    style={{
                      color: !this.state.team.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                  >
                    Team:
                  </InputLabel>
                  <Select
                    data-test="team"
                    labelId="select-team-label"
                    id="select-team"
                    value={this.state.team}
                    onChange={this.handleChange}
                    label="Team"
                    name="team"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.quotaAttainmentCompareFilters !== "" &&
                      this.props.quotaAttainmentCompareFilters.team
                        .filter((item) => item.Display_Name !== "")
                        .map((y) => (
                          <MenuItem
                            key={y.Display_Name}
                            value={y.Display_Name}
                          >
                            {y.Display_Name}
                          </MenuItem>
                        ))}
                  </Select>
                  </FormControl>
              </Grid>
              </Grid>
              </Grid>

              <Grid container item style={{ padding: 10 }} xs={6}>
              <Grid item spacing={2} xs={12} container component={Paper} style={{ paddingTop: 8 }}>

              <Grid item xs={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth  id="filter_cfq">
                  <InputLabel
                    id="select-Acct_Industry-label"
                    style={{
                      color: !this.state.Acct_Industry.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                  >
                    Industry:
                  </InputLabel>
                  <Select
                    data-test="Acct_Industry"
                    labelId="select-Acct_Industry-label"
                    id="select-Acct_Industry"
                    value={this.state.Acct_Industry}
                    onChange={this.handleChange}
                    label="Industry"
                    name="Acct_Industry"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.quotaAttainmentCompareFilters !== "" &&
                      this.props.quotaAttainmentCompareFilters.industry
                        .filter((item) => item !== "")
                        .map((y) => (
                          <MenuItem
                            key={y}
                            value={y}
                          >
                            {y}
                          </MenuItem>
                        ))}
                  </Select>
                  </FormControl>
              </Grid>

              <Grid item xs={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth  id="filter_cfq">
                  <InputLabel
                    id="select-Acct_Size_Segment-label"
                    style={{
                      color: !this.state.Acct_Size_Segment.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                  >
                    Customer Segment:
                  </InputLabel>
                  <Select
                    data-test="Acct_Size_Segment"
                    labelId="select-Acct_Size_Segment-label"
                    id="select-Acct_Size_Segment"
                    value={this.state.Acct_Size_Segment}
                    onChange={this.handleChange}
                    label="Customer Segment"
                    name="Acct_Size_Segment"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.quotaAttainmentCompareFilters !== "" &&
                      this.props.quotaAttainmentCompareFilters.segment
                        .filter((item) => item !== "")
                        .map((y) => (
                          <MenuItem
                            key={y}
                            value={y}
                          >
                            {y}
                          </MenuItem>
                        ))}
                  </Select>
                  </FormControl>
              </Grid>

              <Grid item xs={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth  id="filter_cfq">
                  <InputLabel
                    id="select-Cust_Type-label"
                    style={{
                      color: !this.state.Cust_Type.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                  >
                    Customer Type:
                  </InputLabel>
                  <Select
                    data-test="Cust_Type"
                    labelId="select-Cust_Type-label"
                    id="select-Cust_Type"
                    value={this.state.Cust_Type}
                    onChange={this.handleChange}
                    label="Customer Type"
                    name="Cust_Type"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.quotaAttainmentCompareFilters !== "" &&
                      this.props.quotaAttainmentCompareFilters.Cust_Type
                        .filter((item) => item.Cust_Type !== "")
                        .map((y) => (
                          <MenuItem
                            key={y.Cust_Type}
                            value={y.Cust_Type}
                          >
                            {y.Cust_Type}
                          </MenuItem>
                        ))}
                  </Select>
                  </FormControl>
              </Grid>
              </Grid>
              </Grid>

              <Grid container item style={{ padding: 10 }} xs={2}>
              <Grid item spacing={2} xs={12} container component={Paper} style={{ paddingTop: 8 }}>

              <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth  id="filter_cfq">
                  <InputLabel
                    id="select-Record_Type_Name__c-label"
                    style={{
                      color: !this.state.Record_Type_Name__c.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                  >
                    Opp Record Type:
                  </InputLabel>
                  <Select
                    data-test="Record_Type_Name__c"
                    labelId="select-Record_Type_Name__c-label"
                    id="select-Record_Type_Name__c"
                    value={this.state.Record_Type_Name__c}
                    onChange={this.handleChange}
                    label="Opp Record Type"
                    name="Record_Type_Name__c"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.quotaAttainmentCompareFilters !== "" &&
                      this.props.quotaAttainmentCompareFilters.Record_Type_Name__c
                        .filter((item) => item.Record_Type_Name__c !== "")
                        .map((y) => (
                          <MenuItem
                            key={y.Record_Type_Name__c}
                            value={y.Record_Type_Name__c}
                          >
                            {y.Record_Type_Name__c}
                          </MenuItem>
                        ))}
                  </Select>
                  </FormControl>
              </Grid>
              </Grid>
              </Grid>

              
              <Grid container item style={{ padding: 10 }} xs={6}>
              <Grid item spacing={2} xs={12} container component={Paper} style={{ paddingTop: 8 }}>

              <Grid item xs={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth  id="filter_cfq">
                  <InputLabel
                    id="select-Lead_Source-label"
                    style={{
                      color: !this.state.Lead_Source.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                  >
                    Lead Source:
                  </InputLabel>
                  <Select
                    data-test="Lead_Source"
                    labelId="select-Lead_Source-label"
                    id="select-Lead_Source"
                    value={this.state.Lead_Source}
                    onChange={this.handleChange}
                    label="Lead Source"
                    name="Lead_Source"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.quotaAttainmentCompareFilters !== "" &&
                      this.props.quotaAttainmentCompareFilters.Lead_Source
                        .filter((item) => item.Lead_Source !== "")
                        .map((y) => (
                          <MenuItem
                            key={y.Lead_Source}
                            value={y.Lead_Source}
                          >
                            {y.Lead_Source}
                          </MenuItem>
                        ))}
                  </Select>
                  </FormControl>
              </Grid>

              <Grid item xs={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth  id="filter_cfq">
                  <InputLabel
                    id="select-Solution_Cat-label"
                    style={{
                      color: !this.state.Solution_Cat.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                  >
                    Solution Category:
                  </InputLabel>
                  <Select
                    data-test="Solution_Cat"
                    labelId="select-Solution_Cat-label"
                    id="select-Solution_Cat"
                    value={this.state.Solution_Cat}
                    onChange={this.handleChange}
                    label="Soultion Cat"
                    name="Solution_Cat"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.quotaAttainmentCompareFilters !== "" &&
                      this.props.quotaAttainmentCompareFilters.Solution_Cat
                        .filter((item) => item.Solution_Cat !== "")
                        .map((y) => (
                          <MenuItem
                            key={y.Solution_Cat}
                            value={y.Solution_Cat}
                          >
                            {y.Solution_Cat}
                          </MenuItem>
                        ))}
                  </Select>
                  </FormControl>
              </Grid>

              <Grid item xs={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth  id="filter_cfq">
                  <InputLabel
                    id="select-Account_Designation__c-label"
                    style={{
                      color: !this.state.Account_Designation__c.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                  >
                   Account Designation:
                  </InputLabel>
                  <Select
                    data-test="Account_Designation__c"
                    labelId="select-Account_Designation__c-label"
                    id="select-Account_Designation__c"
                    value={this.state.Account_Designation__c}
                    onChange={this.handleChange}
                    label="Customer Type"
                    name="Account_Designation__c"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.quotaAttainmentCompareFilters !== "" &&
                      this.props.quotaAttainmentCompareFilters.Account_Designation__c
                        .filter((item) => item.Account_Designation__c !== "")
                        .map((y) => (
                          <MenuItem
                            key={y.Account_Designation__c}
                            value={y.Account_Designation__c}
                          >
                            {y.Account_Designation__c}
                          </MenuItem>
                        ))}
                  </Select>
                  </FormControl>
              </Grid>
              </Grid>
              </Grid>


              {/*ACV >= and ACV <= */}

              <Grid container item style={{ padding: 10 }} xs={4}>
              <Grid item spacing={2} xs={12} container component={Paper} style={{ paddingTop: 8 }}>
              <Grid item xs={12} sm={6} style={{ paddingTop: 5 }}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  options={
                    this.props.quotaAttainmentCompareFilters !== "" &&
                    this.props.quotaAttainmentCompareFilters.ACV.map((d) =>
                      d3.format(",")(String(d.acv)))
                  }
                  // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                  //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                  onChange={this.onAcvChange}
                  value={this.state.acv}
                  data-test="acv"
                  autoSelect={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${this.props.Value_Label} >= :`}
                      margin="normal"
                      variant="standard"
                      style={{ marginTop: "0px", marginBottom: "0px" }}
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color:
                            this.state.acv !== null && this.state.acv !== 0
                              ? "#4472c4"
                              : "#000",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        inputComponent: NumberFormatCustom,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingTop: 5 }}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  options={
                    this.props.quotaAttainmentCompareFilters !== "" &&
                    this.props.quotaAttainmentCompareFilters.ACV.map((d) =>
                      d3.format(",")(String(d.acv)))
                  }
                  // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                  //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                  onChange={this.onAcvLtChange}
                  value={this.state.acvlt}
                  data-test="acvlt"
                  autoSelect={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${this.props.Value_Label} < :`}
                      margin="normal"
                      variant="standard"
                      style={{ marginTop: "0px", marginBottom: "0px" }}
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color:
                            this.state.acvlt !== null &&
                            this.state.acvlt !== 0
                              ? "#4472c4"
                              : "#000",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        inputComponent: NumberFormatCustom,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
                item
                sm={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                  data-test="go"
                >
                  GO
                </Button>
              </Grid>


             
            </Grid>
          ) : (
            <Grid
              item
              container
              component={Card}
              style={{ margin: "1rem" }}
              justify="center"
            >
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className={classes.grid} container justify="center">
          <Grid item xs={12} style={{ margin: "2rem" }}>
            <Card style={{ margin: "auto", width: "100%" }} raised={true}>
            <Grid container xs={12} justify="center">
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="h2"
                    style={{
                      margin: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      padding: "1rem",
                    }}
                  >
                    Beginning Of Quarter Qualified Pipe Attainment
                  </Typography>
                </Grid>
                <Grid item xs={1} style={{ marginTop: "2rem" }}>
                  <ToggleButtonGroup
                    color="primary"
                    indicatorColor="primary"
                    value={this.state.toggleValue}
                    exclusive
                    size="small"
                    onChange={this.handleChangeToggle}
                  >
                    <CustomWidthTooltip
                      title="Sort attainment % highest to lowest."
                      placement="bottom"
                    >
                      <ToggleButton
                        style={{
                          background:
                            this.state.toggleValue === "PercSort"
                              ? "#4472C4"
                              : "",
                          color:
                            this.state.toggleValue === "PercSort"
                              ? "white"
                              : "",
                          fontSize: "1rem",
                          fontWeight: 600,
                          width: "3rem",
                          height: "2rem",
                        }}
                        value="PercSort"
                      >
                        %
                      </ToggleButton>
                    </CustomWidthTooltip>
                    <CustomWidthTooltip
                      title="Sort in logical order."
                      placement="bottom"
                    >
                      <ToggleButton
                        style={{
                          background:
                            this.state.toggleValue === "AlphaSort"
                              ? "#4472C4"
                              : "",
                          color:
                            this.state.toggleValue === "AlphaSort"
                              ? "white"
                              : "",
                          fontSize: "1rem",
                          fontWeight: 600,
                          width: "3rem",
                          height: "2rem",
                        }}
                        value="AlphaSort"
                      >
                        A
                      </ToggleButton>
                    </CustomWidthTooltip>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              {
                  this.props.QuotaAttainmentData !== "" ?
                  <Grid container>
                      <DataTable quarters={this.props.QuotaAttainmentData.uniqueQuarters}
                      uniqueTeamReps={this.props.QuotaAttainmentData.uniqueTeamRep}
                      data={this.props.QuotaAttainmentData.data}
                      teamData={this.props.QuotaAttainmentData.teamData}
                      uniqueTeams={this.props.QuotaAttainmentData.uniqueTeams}
                      logicalTeams={this.props.QuotaAttainmentData.logicalTeams}
                      showReps={true}
                      toggleValue={this.state.toggleValue}
                      handleChangeLocation={this.handleChangeLocationDrill}
                      name="Begining_Of_The_Quarter_Quota_Attainmnet"
                      />
                  </Grid>
                  :
                  <Skeleton
                      animation="wave"
                      variant="rect"
                      width="95%"
                      height={230}
                      style={{ margin: "2rem" }}
                    />
              }

            </Card>
          </Grid>

          
        </Grid>
        {this.state.location === "drilldownOnGraph" && this.props.newBins && (
          <Grid
            item
            xs={12}
            container
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Quota Attainment Distribution`}
              back={this.handleChangeLocation}
              // filters={this.state.filtersForDrilldown}
              filters={[
                {
                  title:
                    this.state.selectDrillStagee === "quarter"
                      ? "Closed Fiscal Quarter"
                      : "Closed Fiscal Year",
                  value: this.state.selectedDrilldown,
                },
              ]}
              //  filters={[{title: this.state.activeTimeFilter === "closed_fiscal_quarter" ? "Closed Fiscal Quarter" : "Closed Fiscal Year", value: this.state.selectDrillStagee}]}
            >
              <QuotaAttainmentDrilldown data={this.state.drilldownData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.location === "drilldownOnGraph2" &&
          this.props.QuotaAttainmentData && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={this.state.drildownHeader}
                back={this.handleChangeLocationDrill}
                // filters={this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : null}
                // filters={[{title: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ? "Closed Fiscal Quarter" : "Closed Fiscal Year", value: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ?  this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : this.state.selectedDrilldown : this.state.selectedDrilldown === "total" ? this.state.closed_fiscal_year : this.state.selectedDrilldown}]}
                filters={[
                  {
                    title:
                      this.state.drildownHeader === 'Stage 1 or higher pipe at Qtr. Start' ? "Fiscal Quarter" : "Closed Fiscal Quarter",
                    value: this.state.selectedDrilldown,
                  },
                ]}
              >
                <Drilldown
                  body={this.props.drilldownOnGraphData}
                  company={this.props.company}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                  Value_Label={this.props.Value_Label}
                />
              </SingleLevelDrilldown>
            </Grid>
          )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationDrill}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

function mapStateToProps(state) {
  const {
    quotaAttainmentCompareFilters,
    QuotaAttainmentData,
    user,
    currentFilters,
    drilldownOnGraphData,
    ExpectedAcvData,
  } = state.app;


  return {
    quotaAttainmentCompareFilters: quotaAttainmentCompareFilters,
    QuotaAttainmentData: QuotaAttainmentData,
    company: user.company,
    Value_Label: user.Value_Label,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  };
}
const mapDispatchToProps = (dispatch) => ({
  getQuotaAttainmentCompareFilters: () => {
    dispatch({ type: "get_quota_attainment_filters_request" });
    appService.getBeginningOftheQuarterQuotaAttainmentFilters().then(
      (json) => {
        dispatch({ type: "get_quota_attainment_filters_success", json });
        dispatch({ type: "get_quota_attainment_data_request" });
        const filters = [
          {
            name: "fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message.fiscal_quarter,
              "fiscal_quarter"
            ),
            string: true,
          },
        ];
        // console.log(filters)
        // console.log(filters)
        dispatch({ type: "current_selected_filters", filters });
        appService
          .getBeginningOftheQuarterQuotaAttainmentData(json.message.length === 0 ? [] : filters)
          .then(
            (json) => {
              dispatch({ type: "get_quota_attainment_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_quota_attainment_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({ type: "get_quota_attainment_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_quota_attainment_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_filters_failure", error });
      }
    );
  },
  getQuotaAttainmentData: (company, filters) => {
    dispatch({ type: "get_quota_attainment_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getBeginningOftheQuarterQuotaAttainmentData(filters).then(
      (json) => {
        dispatch({ type: "get_quota_attainment_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_quota_attainment_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_data_failure", error });
      }
    );
  },
  getQuotaAttainmentDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getQuotaAttainmentDrilldownData(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1];
        tempOpportunities.sort((a, b) => (a.acv > b.acv ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  getTeamAttainmentDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getTeamAttainmentDrilldown(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.ACV > b.ACV ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };

        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getBeginningOftheQuarterAttainmentDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getBeginningOftheQuarterAttainmentDrilldown(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.ACV > b.ACV ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };

        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedQuotaAttainmentv3 = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QuotaAttainmentV3));
export default connectedQuotaAttainmentv3;
