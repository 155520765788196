import React, { useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { tooltipClasses } from "@mui/material/Tooltip"; // Import tooltipClasses

// Define CustomWidthTooltip
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "6.9rem",
    fontSize: "0.9rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
    // Set a fixed width to force text wrapping
    // width: "150px", // Adjust the width as needed
  },
});

const CopyObjectIcon = ({ text }) => {
  const iconStyles = {
    width: "20px",
    height: "20px",
    marginRight: "3px",
    color: "#A9A9A9",
    fill: "#A9A9A9",
  };
  const handleMouseEnter = (e) => {
    e.currentTarget.style.backgroundColor = "#e6e3e3"; // Change to the desired hover background color
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.backgroundColor = "#ffffff"; // Reset the background color when the mouse leaves
  };

  return (
    <div
      style={{
        width: "32px",
        height: "30px",
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "background-color 0.3s", // Add a smooth transition
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CustomWidthTooltip title={`${text}`} placement="left">
        <img src="/Copy_To_Clipboard_Icon.svg" alt="Icon" style={iconStyles} />
      </CustomWidthTooltip>
    </div>
  );
};

export default CopyObjectIcon;
