import { CardContent, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { isThisMinute } from 'date-fns';
import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar';
import StyledTableRow from '../../components/StyledTableRow';
import { convertToThousands, getUniqueValues, _isNaN, countOrACVFormatter } from '../../util/customFunctions';
import * as d3 from "d3"
import { getMixKey } from '../../util/generateHeadings';
import { customSortedOppType, customSortedSegments } from '../../util/customSortings';
import { deSnake } from '../../util/dataFormatter';
import { color } from 'd3';

const useStyle = makeStyles(theme => ({
    root: {},
    content: { width: '100%' },
    table: {
        border: '0.5px solid lightgrey', padding: '5px', color: 'inherit'
    },
    repText: {
        border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'left', minWidth: '169px', fontWeight: 'inherit'
    },
    repNumber: {
        border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'right', fontWeight: 'inherit',
    },
    repNumberACV: {
        border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'right', minWidth: '92px', fontWeight: 'inherit'
    },
    repNumberPercent: {
        border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'right', minWidth: '90px', fontWeight: 600, cursor: 'pointer'
    },
    repTextH: {
        border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'left', fontWeight: 600
    },
    repNumberH: {
        border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'right', fontWeight: 600
    },
    tableHeader: {
        fontWeight: 600, border: '0.5px solid lightgrey', padding: '5px', color: 'inherit'
    },
    repHeader: {
        fontWeight: 600, border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', width: "5%"
    },
    inner: {
        height: 375,
        minWidth: 500
    },
    chart: {
        height: '100%'
    },
    tableHeader: {
        fontSize: '15px',
        whiteSpace: 'normal',
        textAlign: 'center'
    },
    tableCell: {
        whiteSpace: 'normal',
    },
    blackLabel: {
        color: 'black'
    },
    inputFieldContainers: {
        marginBottom: 10,
        padding: 10,
        [theme.breakpoints.up('lg')]: {
            paddingRight: 0
        }
    },
    fontBold: {
        fontWeight: 600
    },
    stickyColumn: {
        minWidth: 200,
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar + 1,

    }
}))
export default function QTDetailTable(props) {
    const classes = useStyle()

    const quarter = props.data.filter(item => item[props.quarters] !== "total" && item[props.quarters] !== null)

    const uniqueQuarters = quarter.map(item => item[props.quarters]).filter(getUniqueValues)

    const uniqueAttainment = props.data.filter(item => item[props.quarters] === "total").sort((a, b) => b.attainment === a.attainment ? b.acv - a.acv : b.attainment - a.attainment)

    const uniqueOwner = uniqueAttainment.map(item => item.Owner_Name).filter(getUniqueValues).filter(item => item !== null)
      
    // console.log((uniqueOwner.map(owner => quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQuarters.length ) ))

    const ast = x => <><span>{x}</span> <span style={{ color: "#ed7d31" }}>*</span></>
    const ast2 = x => <><span style={{ color: "#ed7d31" }}>*</span>&nbsp; <span>{x}</span> </>
//     const xyz  = uniqueOwner.map(owner => quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQuarters.length &&  owner).filter(x => x !== false) 
//  console.log(xyz)
    return (
        <div className={classes.root}
            style={{ height: '100%', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
            <CardContent className={classes.content} style={{ padding: "1rem" }}>
                
                    <Grid container justify="flex-start" style={{ padding: "1rem" }}>

                        <TableContainer className={classes.mainTable} >

                            <Table size="small" aria-label="html table" style={{ tableLayout: 'auto', width: 'initial' }} data-test="quota-attainment-detail-table">

                                <TableHead>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                                        <TableCell align='center' className={`${classes.repHeader}`} colSpan={1} style={{ minWidth: 150 }}>{props.quarters === "closed_fiscal_quarter" ? "Closed Fiscal Quarter" : "Closed Fiscal Year"}</TableCell>
                                        {uniqueQuarters.map((i, index) => {

                                            return (<TableCell key={i} align='center' style={index % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white', padding: "10px 0" }} className={classes.repHeader} colSpan={3} >{i}</TableCell>)
                                        })
                                        }
                                        {uniqueQuarters.length >= 1 &&
                                            <React.Fragment>

                                                <TableCell style={uniqueQuarters.length >= 1 && uniqueQuarters.length % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} align='center' className={classes.repHeader} colSpan={3} >{'Total'}</TableCell>
                                            </React.Fragment>
                                        }

                                        {uniqueQuarters.length === 0 &&
                                            <TableCell rowSpan={8} className={classes.repHeader} style={{ width: 400, textAlign: 'center' }}>
                                                No data available
                                            </TableCell>
                                        }
                                    </TableRow>
                                    <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                                        <TableCell align='center' className={`${classes.repHeader}`} colSpan={1} >{'Rep'}</TableCell>
                                        {uniqueQuarters.map(i => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <TableCell align='center' className={classes.repHeader} colSpan={1} >Quota</TableCell>
                                                    <TableCell align='center' className={classes.repHeader} colSpan={1} >Actual</TableCell>
                                                    <TableCell align='center' className={classes.repHeader} colSpan={1} >Attain.%</TableCell>
                                                </React.Fragment>
                                            )
                                        })
                                        }
                                        {uniqueQuarters.length >= 1 &&
                                            <React.Fragment>
                                                <TableCell align='center' className={classes.repHeader} colSpan={1} >Quota</TableCell>
                                                <TableCell align='center' className={classes.repHeader} colSpan={1} >Actual</TableCell>
                                                <TableCell align='center' className={classes.repHeader} colSpan={1} >Attain.%</TableCell>
                                            </React.Fragment>
                                        }

                                    </TableRow>

                                    {
                                        uniqueOwner.map((owner, i) =>
                                            <StyledTableRow key={i}>

                                                <TableCell align='left' className={`${classes.table}`} colSpan={1} >{(quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQuarters.length) ? ast(owner) : owner}</TableCell>

                                                {
                                                    uniqueQuarters.map((qtr, j) => {
                                                        const QuotaData = props.data.filter(item => item[props.quarters] === qtr && item.Owner_Name === owner).map(item => item.Quota)[0]
                                                        // console.log(props.data.filter(item => item.Owner_Name === owner).map(item => item.Quota)[0] === 0)
                                                        const acvData = props.data.filter(item => item[props.quarters] === qtr && item.Owner_Name === owner).map(item => item.acv)[0]
                                                        const attainmentData = props.data.filter(item => item[props.quarters] === qtr && item.Owner_Name === owner).map(item => item.attainment)[0]
                                                        return <>
                                                            <TableCell align='right'  className={classes.repNumber} colSpan={1} >{QuotaData === "undefiend" || QuotaData === null || _isNaN(QuotaData) ? "-" : countOrACVFormatter(QuotaData, true)}  </TableCell>
                                                            <TableCell align='right' className={classes.repNumber} colSpan={1} style={{cursor: "pointer"}}
                                                             onClick={() => props.handleChangeLocation("drilldownOnGraph2", {row: owner, cell: qtr, quarter: props.quarters})}
                                                             >{acvData === "undefiend" || acvData === null || _isNaN(acvData) ? "-" : countOrACVFormatter(acvData, true)}</TableCell>
                                                            <TableCell align='right' className={classes.repNumber} colSpan={1} style={{cursor: "pointer"}}
                                                            onClick={() => props.handleChangeLocation("drilldownOnGraph2", {row: owner, cell: qtr, quarter: props.quarters})}
                                                            >{attainmentData === "undefiend" || attainmentData === null || _isNaN(attainmentData) ? "-" : Math.round(attainmentData * 100) + "%"}</TableCell>

                                                        </>
                                                    })
                                                }

                                                {uniqueQuarters.length >= 1 &&

                                                    <React.Fragment>
                                                        <TableCell align='right' className={classes.repNumber} colSpan={1} >{props.data.filter(item => item[props.quarters] === "total" && item.Owner_Name === owner).map(item => item.Quota === "undefiend" || item.Quota === null ? "-" : countOrACVFormatter(item.Quota, true))}</TableCell>
                                                        <TableCell  align='right' className={classes.repNumber} colSpan={1} style={{cursor: "pointer"}}
                                                        onClick={() => props.handleChangeLocation("drilldownOnGraph2", {row: owner, cell: "total", quarter: props.quarters, uniqueQuarters: uniqueQuarters})}
                                                        >{props.data.filter(item => item[props.quarters] === "total" && item.Owner_Name === owner).map(item => item.acv === "undefiend" || item.acv === null ? "-" : countOrACVFormatter(item.acv, true))}</TableCell>
                                                        <TableCell  align='right' className={classes.repNumber} colSpan={1} style={{cursor: "pointer"}}
                                                        onClick={() => props.handleChangeLocation("drilldownOnGraph2", {row: owner, cell: "total", quarter: props.quarters, uniqueQuarters: uniqueQuarters})}
                                                        >{props.data.filter(item => item[props.quarters] === "total" && item.Owner_Name === owner).map(item => item.attainment === "undefiend" || item.attainment === null ? "-" : Math.round(item.attainment * 100) + "%")}</TableCell>
                                                    </React.Fragment>
                                                }


                                            </StyledTableRow>)
                                    }

                                    <StyledTableRow className={classes.fontBold}>
                                        <TableCell align='left' className={`${classes.repHeader}`} colSpan={1} style={{ fontWeight: 600 }}>Total</TableCell>
                                        {uniqueQuarters.map((cell, j) => {
                                            const totalQuota = props.data.filter(item => item[props.quarters] === cell).map(item => item.Quota).reduce((a, b) => a + b, 0)
                                            const totalActual = props.data.filter(item => item[props.quarters] === cell).map(item => item.acv).reduce((a, b) => a + b, 0)
                                            const totalAttainment = props.data.filter(item => item[props.quarters] === cell).map(item => item.attainment).reduce((a, b) => a + b, 0)
                                            const uniqueRep = props.data.filter(item => item[props.quarters] === cell).map(item => item.Owner_Name)
                                            // const  totalQ2 = props.winAcvData.filter(item => item[props.quarter] === cell).map(item => item.count).reduce((a, b) => a + b, 0)
                                            return (
                                                <React.Fragment key={j}>
                                                    <TableCell align='right' className={classes.repHeader} colSpan={1} style={{ fontWeight: 600 }}>{countOrACVFormatter(totalQuota, true)}</TableCell>
                                                    <TableCell align='right' className={classes.repHeader} colSpan={1} style={{ fontWeight: 600, cursor: "pointer" }} 
                                                    onClick={() => props.handleChangeLocation("drilldownOnGraph2", {row: "total", cell: cell, quarter: props.quarters, uniqueRep: uniqueRep})}
                                                    >{countOrACVFormatter(totalActual, true)}</TableCell>
                                                    <TableCell align='right' className={classes.repHeader} colSpan={1} style={{ fontWeight: 600, cursor: "pointer" }} 
                                                    onClick={() => props.handleChangeLocation("drilldownOnGraph2", {row: "total", cell: cell, quarter: props.quarters, uniqueRep: uniqueRep})}
                                                    >{_isNaN(totalActual / totalQuota) ? 0 : Math.round(totalActual / totalQuota * 100)}%</TableCell>
                                                </React.Fragment>
                                            )
                                        })}
                                        {uniqueQuarters.length >= 1 &&

                                            <React.Fragment >
                                                <TableCell align='right'  className={classes.repHeader} colSpan={1} style={{ fontWeight: 600 }} >{props.data.filter(item => item[props.quarters] === "total").map(item => item.Quota).reduce((a, b) => a + b, 0) === undefined ? "-" : countOrACVFormatter(props.data.filter(item => item[props.quarters] === "total").map(item => item.Quota).reduce((a, b) => a + b, 0), true)}</TableCell>
                                                <TableCell align='right'  className={classes.repHeader} colSpan={1} style={{ fontWeight: 600, cursor: "pointer" }}
                                                 onClick={() => props.handleChangeLocation("drilldownOnGraph2", {row: "total", cell: "total", quarter: props.quarters, uniqueRep: uniqueOwner, uniqueQuarters: uniqueQuarters})}
                                                 >{props.data.filter(item => item[props.quarters] === "total").map(item => item.acv).reduce((a, b) => a + b, 0) === undefined ? "-" : countOrACVFormatter(props.data.filter(item => item[props.quarters] === "total").map(item => item.acv).reduce((a, b) => a + b, 0), true)}</TableCell>
                                                <TableCell align='right'  className={classes.repHeader} colSpan={1} style={{ fontWeight: 600, cursor: "pointer"}}
                                                 onClick={() => props.handleChangeLocation("drilldownOnGraph2", {row: "total", cell: "total", quarter: props.quarters, uniqueRep: uniqueOwner, uniqueQuarters: uniqueQuarters})}
                                                 >{_isNaN(props.data.filter(item => item[props.quarters] === "total").map(item => item.acv).reduce((a, b) => a + b, 0) / props.data.filter(item => item[props.quarters] === "total").map(item => item.Quota).reduce((a, b) => a + b, 0)) ? 0 : Math.round(props.data.filter(item => item[props.quarters] === "total").map(item => item.acv).reduce((a, b) => a + b, 0) / props.data.filter(item => item[props.quarters] === "total").map(item => item.Quota).reduce((a, b) => a + b, 0) * 100)}%</TableCell>
                                            </React.Fragment>

                                        }
                                    </StyledTableRow>



                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                
              
                {
                    ((uniqueOwner.map(owner => quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQuarters.length ? quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length : 0).reduce((a, b) => a + b, 0)  !== 0 && uniqueOwner.map(owner => quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQuarters.length ? quarter.filter(x => x.Owner_Name === owner && x.Quota !== null).length : 0).reduce((a, b) => a + b, 0) !== 0) )
                    &&
                    <Grid item container xs={10} justify="flex-start" alignItems="flex-end" >
                        <Typography variant="body2" style={{ paddingLeft: "1rem" }}>
                            <span style={{ color: "#ED7D31" }}>*</span>&nbsp; Excluded from analysis for time periods where no quota data is available.
                        </Typography>
                    </Grid>
                }
            </CardContent>
        </div>
    )
}
