import {
  Card,
  Grid,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { _isNaN } from "../util/customFunctions";
import { formatDataValuesNewForSummary } from "../util/dataFormatter";
const useStyle = makeStyles((theme) => ({
  colHeader: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  fontBold: {
    fontWeight: 600,
  },
  funnelInfoPadding: {
    // paddingRight: window.innerWidth <= 1368 ? '8rem !important' : '14rem !important'
  },
  drilldownText: {
    cursor: "pointer",
  },
  emptyColumn: {
    width: "15%",
  },
  percentWidth: {
    width: "5%",
  },
  infoWidth: {
    width: "15%",
  },
}));
export default function FunnelInfoCard(props) {
  const classes = useStyle();
  return (
    <Grid container xs={12} item component={Card} raised={true}>
      <Table size="small" data-test={props.data_test}>
        <TableBody>
          <TableRow>
            <TableCell
              style={{ width: window.innerWidth <= 1368 ? 260 : 360 }}
              align="right"
            >
              Total Opps Opened :{" "}
            </TableCell>
            <TableCell
              className={`${classes.fontBold} ${classes.funnelInfoPadding} ${classes.infoWidth}`}
              align="right"
            >
              <span
                className={classes.drilldownText}
                onClick={() =>
                  props.handleChangeLocationNew(
                    "drilldownOnGraph",
                    props.wonLabel,
                    "totalOpportunities"
                  )
                }
              >
                {formatDataValuesNewForSummary(
                  props.data[props.isAcv ? `total_acv` : `total`],
                  props.type,
                  props.company
                )}
              </span>
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell
              className={classes.emptyColumn}
              align="right"
            ></TableCell>
          </TableRow>

          {!(
            props.data.backToTargetedAlive === 0 &&
            props.data.backToTargetedAlive_acv === 0
          ) && (
            <TableRow>
              <TableCell align="right">
                Back to non-open stages - Alive :
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.fontBold} ${classes.funnelInfoPadding}`}
              >
                <span
                  className={classes.drilldownText}
                  onClick={() =>
                    props.handleChangeLocationNew(
                      "drilldownOnGraph",
                      props.wonLabel,
                      "backToNonOpenStages"
                    )
                  }
                >
                  {`${formatDataValuesNewForSummary(
                    props.data[
                      props.isAcv
                        ? `backToTargetedAlive_acv`
                        : `backToTargetedAlive`
                    ],
                    props.type,
                    props.company
                  )}`}
                </span>
              </TableCell>
              <TableCell className={classes.percentWidth} align="right">{` (${
                _isNaN(
                  Math.round(
                    (props.data[
                      props.isAcv
                        ? `backToTargetedAlive_acv`
                        : `backToTargetedAlive`
                    ] /
                      props.data[props.isAcv ? `total_acv` : `total`]) *
                      100
                  )
                )
                  ? 0
                  : (
                      (props.data[
                        props.isAcv
                          ? `backToTargetedAlive_acv`
                          : `backToTargetedAlive`
                      ] /
                        props.data[props.isAcv ? `total_acv` : `total`]) *
                      100
                    ).toFixed(1)
              }%)`}</TableCell>
              <TableCell
                className={classes.emptyColumn}
                align="right"
              ></TableCell>
            </TableRow>
          )}
          {!(
            props.data.lostOrDisqualifiedFromTargetedOrNonActiveStages === 0 &&
            props.data.lostOrDisqualifiedFromTargetedOrNonActiveStages_acv === 0
          ) && (
            <TableRow>
              <TableCell align="right">
                Lost / Disqualified from non-open stages :{" "}
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.fontBold} ${classes.funnelInfoPadding}`}
              >
                <span
                  className={classes.drilldownText}
                  onClick={() =>
                    props.handleChangeLocationNew(
                      "drilldownOnGraph",
                      props.wonLabel,
                      "lostOrDisqualifiedFromNonOpenStages"
                    )
                  }
                >
                  {formatDataValuesNewForSummary(
                    props.data[
                      props.isAcv
                        ? `lostOrDisqualifiedFromTargetedOrNonActiveStages_acv`
                        : `lostOrDisqualifiedFromTargetedOrNonActiveStages`
                    ],
                    props.type,
                    props.company
                  )}
                </span>
              </TableCell>

              <TableCell align="right">{` (${
                _isNaN(
                  Math.round(
                    (props.data[
                      props.isAcv
                        ? `lostOrDisqualifiedFromTargetedOrNonActiveStages_acv`
                        : `lostOrDisqualifiedFromTargetedOrNonActiveStages`
                    ] /
                      props.data[props.isAcv ? `total_acv` : `total`]) *
                      100
                  )
                )
                  ? 0
                  : (
                      (props.data[
                        props.isAcv
                          ? `lostOrDisqualifiedFromTargetedOrNonActiveStages_acv`
                          : `lostOrDisqualifiedFromTargetedOrNonActiveStages`
                      ] /
                        props.data[props.isAcv ? `total_acv` : `total`]) *
                      100
                    ).toFixed(1)
              }%)`}</TableCell>
              <TableCell
                className={classes.emptyColumn}
                align="right"
              ></TableCell>
            </TableRow>
          )}

          {!(
            props.data.movedForward === 0 && props.data.movedForward_acv === 0
          ) && (
            <TableRow>
              <TableCell align="right">Moved forward :</TableCell>
              <TableCell
                align="right"
                className={`${classes.fontBold} ${classes.funnelInfoPadding}`}
              >
                <span
                  className={classes.drilldownText}
                  onClick={() =>
                    props.handleChangeLocationNew(
                      "drilldownOnGraph",
                      props.wonLabel,
                      "movedForwardFromAll"
                    )
                  }
                >
                  <span className={`${classes.fontBold}`}>
                    {`${formatDataValuesNewForSummary(
                      props.data[
                        props.isAcv ? `movedForward_acv` : `movedForward`
                      ],
                      props.type,
                      props.company
                    )}`}
                  </span>
                </span>
              </TableCell>
              <TableCell align="right">{` (${
                _isNaN(
                  Math.round(
                    (props.data[
                      props.isAcv ? `movedForward_acv` : `movedForward`
                    ] /
                      props.data[props.isAcv ? `total_acv` : `total`]) *
                      100
                  )
                )
                  ? 0
                  : (
                      (props.data[
                        props.isAcv ? `movedForward_acv` : `movedForward`
                      ] /
                        props.data[props.isAcv ? `total_acv` : `total`]) *
                      100
                    ).toFixed(1)
              }%)`}</TableCell>
              <TableCell
                className={classes.emptyColumn}
                align="right"
              ></TableCell>
            </TableRow>
          )}
          {/* props.handleChangeLocationNew('drilldownOnGraph', props.wonLabel, "movedForwardFromAll") */}
          {!(
            props.data.lostDisqualifiedFromSuspectOnwards === 0 &&
            props.data.lostDisqualifiedFromSuspectOnwards_acv === 0
          ) && (
            <TableRow>
              <TableCell align="right">
                Lost / Disqualified from Open stages :
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.funnelInfoPadding}`}
              >
                <span
                  className={`${classes.fontBold} ${classes.drilldownText}`}
                  onClick={() => {
                    // console.log(
                    //   props.funnelStages.filter((it) => it !== props.wonLabel)
                    // );
                    props.handleChangeLocationNew(
                      "drilldownOnGraph",
                      props.funnelStages.filter((it) => it !== props.wonLabel),
                      "orange"
                    );
                  }}
                >
                  {`${formatDataValuesNewForSummary(
                    props.data[
                      props.isAcv
                        ? `lostDisqualifiedFromSuspectOnwards_acv`
                        : `lostDisqualifiedFromSuspectOnwards`
                    ],
                    props.type,
                    props.company
                  )}`}
                </span>
              </TableCell>
              <TableCell align="right">{` (${
                _isNaN(
                  Math.round(
                    (props.data[
                      props.isAcv
                        ? `lostDisqualifiedFromSuspectOnwards_acv`
                        : `lostDisqualifiedFromSuspectOnwards`
                    ] /
                      props.data[props.isAcv ? `total_acv` : `total`]) *
                      100
                  )
                )
                  ? 0
                  : Math.round(
                      (props.data[
                        props.isAcv
                          ? `lostDisqualifiedFromSuspectOnwards_acv`
                          : `lostDisqualifiedFromSuspectOnwards`
                      ] /
                        props.data[props.isAcv ? `total_acv` : `total`]) *
                        100
                    ).toFixed(1)
              }%)`}</TableCell>
              <TableCell
                className={classes.emptyColumn}
                align="right"
              ></TableCell>
            </TableRow>
          )}

          {!(props.data.closedWon === 0 && props.data.closedWon_acv === 0) && (
            <TableRow>
              <TableCell align="right"> {props.wonLabel} :</TableCell>
              <TableCell
                align="right"
                className={`${classes.funnelInfoPadding}`}
              >
                <span
                  className={`${classes.fontBold} ${classes.drilldownText}`}
                  onClick={() =>
                    props.handleChangeLocationNew(
                      "drilldownOnGraph",
                      props.wonLabel,
                      "closedWon"
                    )
                  }
                >
                  {`${formatDataValuesNewForSummary(
                    props.data[props.isAcv ? `closedWon_acv` : `closedWon`],
                    props.type,
                    props.company
                  )}`}
                </span>
              </TableCell>
              <TableCell align="right">{` (${
                _isNaN(
                  Math.round(
                    (props.data[props.isAcv ? `closedWon_acv` : `closedWon`] /
                      props.data[props.isAcv ? `total_acv` : `total`]) *
                      100
                  )
                )
                  ? 0
                  : Math.round(
                      (props.data[props.isAcv ? `closedWon_acv` : `closedWon`] /
                        props.data[props.isAcv ? `total_acv` : `total`]) *
                        100
                    ).toFixed(1)
              }%)`}</TableCell>
              <TableCell
                className={classes.emptyColumn}
                align="right"
              ></TableCell>
            </TableRow>
          )}

          {!(
            props.data.currentlyOpen === 0 && props.data.currentlyOpen_acv === 0
          ) && (
            <TableRow>
              <TableCell align="right">Currently Open : </TableCell>
              <TableCell
                align="right"
                className={`${classes.funnelInfoPadding}`}
              >
                <span
                  className={`${classes.fontBold} ${classes.drilldownText}`}
                  onClick={() =>
                    props.handleChangeLocation(
                      "drilldownOnOpen",
                      "stage1",
                      false
                    )
                  }
                >
                  {`${formatDataValuesNewForSummary(
                    props.data[
                      props.isAcv ? `currentlyOpen_acv` : `currentlyOpen`
                    ],
                    props.type,
                    props.company
                  )}`}
                </span>
              </TableCell>
              <TableCell align="right">{` (${
                _isNaN(
                  Math.round(
                    (props.data[
                      props.isAcv ? `currentlyOpen_acv` : `currentlyOpen`
                    ] /
                      props.data[props.isAcv ? `total_acv` : `total`]) *
                      100
                  )
                )
                  ? 0
                  : Math.round(
                      (props.data[
                        props.isAcv ? `currentlyOpen_acv` : `currentlyOpen`
                      ] /
                        props.data[props.isAcv ? `total_acv` : `total`]) *
                        100
                    ).toFixed(1)
              }%)`}</TableCell>
              <TableCell
                className={classes.emptyColumn}
                align="right"
              ></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Grid>
  );
}
