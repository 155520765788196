import { TableHead, withStyles } from "@material-ui/core";

const StickyStyledTableHeader = withStyles((theme) => ({
  root: {
    position: "sticky",
    top: 0,
    zIndex: 5,
    backgroundColor: "#fff", // Ensure background color to avoid transparency issues
    outlineOffset: "-1.5px",
    outline: "lightgrey solid 0.5px",
  },
}))(TableHead);

export default StickyStyledTableHeader;
