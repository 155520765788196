import { Grid,Typography } from '@material-ui/core'
import SingleLevelDrilldown from '../../../components/Drilldown/SingleLevelDrilldown';
import React from 'react'
import { getHeaderForDrilldown } from '../../../util/customFunctions';
import FirstStageDrilldown from './FirstStageDrilldown';
import ExpectedAcvPage from './ExpectedAcvPage'
const ExpectedAcvDrilldown = (props) => {

  return (
    <Grid item xs={12} style={{ position: 'absolute', width: '100%', marginTop: '30px' }}>
            
            <SingleLevelDrilldown
              header={getHeaderForDrilldown(props.tableType)}
              back={props.handleChangeLocationBack}
              stepBack={props.selectedDrilldownStage !== "stage1"?props.stepBack:null}
              filters={props.filters}>

                {props.selectedDrilldownStage === "stage1" ?
                  <FirstStageDrilldown 
                  tableType={props.tableType} 
                  data={props.data} 
                  drilldownTable={props.drilldownTable} 
                  col={props.col}
                  metaArr={props.metaArr}
                  nqpMetaData={props.nqpMetaData}
                  tableSection={props.tableSection}
                  /> :
                  <ExpectedAcvPage data={props.expectedAcvData} company={props.company}/>
              }

            </SingleLevelDrilldown>

          </Grid >
  )
}

export default ExpectedAcvDrilldown
