import {
  Card,
  Divider,
  Grid,
  Paper,
  Typography,
  withStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  InputAdornment,
  styled,
  Tooltip,
} from "@material-ui/core";
import * as d3 from "d3";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NumberFormat from "react-number-format";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import YTDTable from "../RegionPerformance/YTDTable";
import D3RepPerformanceBarQT from "./D3RepPerformaceBarQT";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import QuotaAttainmentDrilldown from "./QuotaAttainmentDrilldown";
import {
  getUniqueValues,
  stageFormattter,
  _isNaN,
  getDefaultSelectedFiscalQuarters,
  getCustomSortedColoredLegends,
  getCustomSortedColoredLegendsV2,
} from "../../util/customFunctions";
import Legend from "../AsOfDate/Legend";
import QTTable from "./QTTable";
import QTDetailTableV2 from "./QTDetailTableV2";
import Drilldown from "../../components/Drilldown/Drilldown";

import YearQuarterFilterCombo from "../../Filters/YearQuarterFilterCombo";
import { isThisISOWeek } from "date-fns";
import QuotaAttainmentDetailDrilldown from "./QuotaAttainmentDetailDrilldown";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import D3RepPerformanceGroupedBar from "./D3RepPerformanceGroupedBarChart";
import D3RepPerformanceBarQTV2 from "./D3RepPerformanceBarQTV2";
import AttainmnetThresholdTable from "./AttainmnetThresholdTable";
import { ToggleButton, ToggleButtonGroup, tooltipClasses } from "@mui/material";
import D3GroupedBar from "./D3GroupedChart";
import addHeaderdescription from "../../components/HeaderDescription";
import YearQuarterFilterCombov2 from "../../Filters/YearQuarterFilterCombov2";
import QTDSeatsTable from "./QTDSeatsTable";
const styles = (theme) => ({
  root: {},

  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    color: "inherit",
  },
  table1: {
    background: "#FAFAFA",
    border: "none",
    width: 5,
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    color: "white",
    background: "#4472C4",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 5px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

class QuotaAttainment extends Component {
  filters = [
    "closed_fiscal_quarter",
    "closed_fiscal_year",
    "team",
    "Ramp_Status",
    "Opportunity_Record_Type",
  ];
  state = {
    Opportunity_Record_Type: ["All"],
    closed_fiscal_quarter: ["All"],
    closed_fiscal_year: ["All"],
    disabled: ["year", "month", "quarter"],
    activeTimeFilter: "closed_fiscal_quarter",
    selectedDrilldown: "",
    drilldownData: "",
    drilldownText: "",
    selectedDrilldownQY: "",
    selectDrillStagee: "",
    filtersForDrilldown: ["All"],
    secondLevelLocation: null,
    groupby: "Team",
    team: ["All"],
    Ramp_Status: ["All"],
    Threshold: "85",
    toggleValue: "PercSort",
    screenPositionY: 0,
    tab: "Summary",
    fDrillDown: []
  };

  componentDidMount() {
    this.props.getQuotaAttainmentCompareFilters(this.props.company);
    window.addEventListener("resize", this.onResize);
  }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.onResize);
  // }
  componentDidUpdate(prevProps, prevState) {
    // Check if quotaAttainmentCompareFilters has changed
    if (
      this.props.quotaAttainmentCompareFilters !== "" &&
      prevProps.quotaAttainmentCompareFilters === ""
    ) {
      const currentQuarter = getDefaultSelectedFiscalQuarters(
        this.props.quotaAttainmentCompareFilters,
        "closed_fiscal_quarter"
      );
      this.setState({
        ...prevState,
        closed_fiscal_quarter: currentQuarter,
        filtersForDrilldown: currentQuarter,
      });
    }

    // Capture the current scroll position
    const currentScrollY = window.scrollY;

    if (this.props.QuotaAttainmentData !== "") {
      // Destroy previous D3 charts
      D3RepPerformanceBarQT.destroy(this._rootNode1);
      D3RepPerformanceGroupedBar.destroy(this._rootNode2);
      D3RepPerformanceBarQTV2.destroy(this._rootNode3);
      D3GroupedBar.destroy(this._rootNode4);

      // Create new D3 charts with updated data
      this._chart1 = D3RepPerformanceBarQT.create(this._rootNode1, {
        data: this.props.newBins,
        quarters:
          this.props.selectionColumn === "timeFrame"
            ? this.props.fiscal_years !== undefined
              ? "year"
              : "quarter"
            : this.props.selectionColumn,
        handleChangeLocation: this.handleChangeLocation,
      });

      this._chart2 = D3RepPerformanceGroupedBar.create(this._rootNode2, {
        data: this.props.newBins,
        quarters:
          this.props.selectionColumn === "timeFrame"
            ? this.props.fiscal_years !== undefined
              ? "year"
              : "quarter"
            : this.props.selectionColumn,
        handleChangeLocation: this.handleChangeLocation,
        xlabel: this.props.groupby,
        logicalTeams: this.props.logicalTeams,
        toggleValue: this.state.toggleValue,
      });

      this._chart3 = D3RepPerformanceBarQTV2.create(this._rootNode3, {
        data: this.props.quota_attainment_with_perc_team
          .filter(
            (c) =>
              c[
                this.props.fiscal_years !== undefined
                  ? "closed_fiscal_year"
                  : "closed_fiscal_quarter"
              ] === "total"
          )
          .map((c) => ({
            ...c,
            value: c.attainment,
            label: c[this.props.selectionColumn],
          })),
        quarters:
          this.props.selectionColumn === "timeFrame"
            ? this.props.fiscal_years !== undefined
              ? "year"
              : "quarter"
            : this.props.selectionColumn,
        handleChangeLocation: this.handleChangeLocation,
        xlabel: this.props.groupby,
        logicalTeams: this.props.logicalTeams,
        groupbyColumn: this.props.groupbyColumn,
        toggleValue: this.state.toggleValue,
      });

      this._chart4 = D3GroupedBar.create(this._rootNode4, {
        data: this.props.quota_attainment_with_perc_team
          .filter(
            (c) =>
              c[
                this.props.fiscal_years !== undefined
                  ? "closed_fiscal_year"
                  : "closed_fiscal_quarter"
              ] !== "total"
          )
          .map((c) => ({
            ...c,
            value: c.attainment,
            label: c[this.props.selectionColumn],
          })),
        quarters:
          this.props.fiscal_years !== undefined
            ? "closed_fiscal_year"
            : "closed_fiscal_quarter",
        handleChangeLocation: this.handleChangeLocation,
        xlabel: this.props.groupby,
        logicalTeams: this.props.logicalTeams,
        groupbyColumn: this.props.groupbyColumn,
        toggleValue: this.state.toggleValue,
        binLabel: this.props.quota_attainment_with_perc_team
          .filter(
            (c) =>
              c[
                this.props.fiscal_years !== undefined
                  ? "closed_fiscal_year"
                  : "closed_fiscal_quarter"
              ] === "total"
          )
          .sort((a, b) => b.attainment - a.attainment)
          .map((c) => c[this.props.selectionColumn]),
      });
    }

    // Restore the scroll position after drilldown
    window.scrollTo(0, currentScrollY);
  }

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: "",
      secondLevelLocation: "",
    });
    // window.scrollTo(0, this.state.screenPositionY);
  };

  handleChangeTab = (e) => {
    this.handleGo();
    console.log(e.target.value);
    this.setState({
      ...this.state,
      tab: e.target.value,
    });
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [...filtersArray, this.state.closed_fiscal_quarter];
    }

    if (!this.state.closed_fiscal_year.includes("All")) {
      filtersArray = [...filtersArray, this.state.closed_fiscal_year];
    }
    if (
      this.state.closed_fiscal_year.includes("All") &&
      this.state.closed_fiscal_quarter.includes("All")
    ) {
      filtersArray = [...filtersArray, "All"];
    }

    return filtersArray[0];
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));

    let groupbyFilters = { groupby: this.state.groupby };
    console.log(groupbyFilters);

    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      screenPositionY: 0,
    });
    this.props.getQuotaAttainmentData(
      this.props.company,
      this.state.activeTimeFilter === "closed_fiscal_year"
        ? "closed_fiscal_year"
        : "",
      filters,
      groupbyFilters
    );
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }

  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }

  _setRef3(componentNode) {
    this._rootNode3 = componentNode;
  }

  _setRef4(componentNode) {
    this._rootNode4 = componentNode;
  }

  onResize = async () => {
    D3RepPerformanceBarQT.destroy(this._rootNode1);
    D3RepPerformanceGroupedBar.destroy(this._rootNode2);
    D3RepPerformanceBarQT.destroy(this._rootNode3);
    D3GroupedBar.destroy(this._rootNode4);
    // const uniqueQuarters = this.props.QuotaAttainmentData1[0].map(item => item[quarter]).filter(getUniqueValues)
    // this._chart1 = D3WonStackBar.create(this._rootNode1, { data: this.props.QuotaAttainmentData1, quarter: 'closed_fiscal_quarter', handleChangeLocation: this.handleChangeLocation, uniqueQuarters: uniqueQuarters.length === 0 ? this.state.closed_fiscal_quarter : uniqueQuarters })

    this._chart1 = D3RepPerformanceBarQT.create(this._rootNode1, {
      data: this.props.newBins,
      quarters:
        this.props.selectionColumn === "timeFrame"
          ? this.props.fiscal_years !== undefined
            ? "year"
            : "quarter"
          : this.props.selectionColumn,
      handleChangeLocation: this.handleChangeLocation,
    });
    this._chart2 = D3RepPerformanceGroupedBar.create(this._rootNode2, {
      data: this.props.newBins,
      quarters:
        this.props.selectionColumn === "timeFrame"
          ? this.props.fiscal_years !== undefined
            ? "year"
            : "quarter"
          : this.props.selectionColumn,
      handleChangeLocation: this.handleChangeLocation,
      xlabel: this.props.groupby,
      logicalTeams: this.props.logicalTeams,
      toggleValue: this.state.toggleValue,
    });

    this._chart3 = D3RepPerformanceBarQTV2.create(this._rootNode3, {
      data: this.props.quota_attainment_with_perc_team
        .filter(
          (c) =>
            c[
              this.props.fiscal_years !== undefined
                ? "closed_fiscal_year"
                : "closed_fiscal_quarter"
            ] == "total"
        )
        .map((c) => ({
          ...c,
          value: c.attainment,
          label: c[this.props.selectionColumn],
        })),
      quarters:
        this.props.selectionColumn === "timeFrame"
          ? this.props.fiscal_years !== undefined
            ? "year"
            : "quarter"
          : this.props.selectionColumn,
      handleChangeLocation: this.handleChangeLocation,
      xlabel: this.props.groupby,
      logicalTeams: this.props.logicalTeams,
      groupbyColumn: this.props.groupbyColumn,
      toggleValue: this.state.toggleValue,
    });

    this._chart4 = D3GroupedBar.create(this._rootNode4, {
      data: this.props.quota_attainment_with_perc_team
        .filter(
          (c) =>
            c[
              this.props.fiscal_years !== undefined
                ? "closed_fiscal_year"
                : "closed_fiscal_quarter"
            ] !== "total"
        )
        .map((c) => ({
          ...c,
          value: c.attainment,
          label: c[this.props.selectionColumn],
        })),
      quarters:
        this.props.fiscal_years !== undefined
          ? "closed_fiscal_year"
          : "closed_fiscal_quarter",
      handleChangeLocation: this.handleChangeLocation,
      xlabel: this.props.groupby,
      logicalTeams: this.props.logicalTeams,
      groupbyColumn: this.props.groupbyColumn,
      toggleValue: this.state.toggleValue,
      binLabel: this.props.quota_attainment_with_perc_team
        .filter(
          (c) =>
            c[
              this.props.fiscal_years !== undefined
                ? "closed_fiscal_year"
                : "closed_fiscal_quarter"
            ] === "total"
        )
        .sort((a, b) => b.attainment - a.attainment)
        .map((c) => c[this.props.selectionColumn]),
    });
  };
  handleChange = (e) => {
    if (
      e.target.name === "closed_fiscal_year" &&
      (e.target.value.length === 0 || e.target.value.includes("All"))
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
        screenPositionY: 0,
      });
    } else if (e.target.name === "closed_fiscal_year") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
        screenPositionY: 0,
      });
    } else if (e.target.name === "closed_fiscal_quarter") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_quarter",
        screenPositionY: 0,
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        screenPositionY: 0,
      });
    }
  };

  handleChangeLocation = (location, stage, Open_Datetime) => {
    // const screenPosition = window.scrollY;
    const a = [].concat
      .apply(
        [],
        this.props.newBins
          .filter((x) => x[stage.x] === stage.cell)
          .map((x) => x.drilldown)
      )
      .sort((a, b) => b.attainment === a.attainment);
    const b = this.props.quota_attainment_with_perc.filter(
      (item) => item[this.state.activeTimeFilter] !== "total"
    );
    const uniqueOwner = this.props.quota_attainment_with_perc
      .filter((item) => item[this.state.activeTimeFilter] !== "total")
      .map((x) => x.Owner_Name)
      .filter(getUniqueValues);
    const uniqueQtr = this.props.quota_attainment_with_perc
      .filter((item) => item[this.state.activeTimeFilter] !== "total")
      .map((x) => x[this.state.activeTimeFilter])
      .filter(getUniqueValues);
    // console.log(uniqueQtr)
    // console.log(uniqueOwner.map(owner => b.filter(x => x.Owner_Name === owner && x.Quota !== null).length < uniqueQtr.length &&  owner).filter(x => x !== false))
    const uniqueRep = uniqueOwner
      .map(
        (owner) =>
          b.filter((x) => x.Owner_Name === owner && x.Quota !== null).length <
            uniqueQtr.length && owner
      )
      .filter((x) => x !== false);

    console.log(stage);

    this.setState({
      ...this.state,
      location: location,
      selectedDrilldown:
        stage.cell !== "total" ? stage.cell : this.state.filtersForDrilldown,
      selectDrillStagee: stage.x,
      drilldownData: {
        uniqueRep: uniqueRep,
        quarters: stage.x,
        uniqueQuarters: stage.uniqueQuarters,
        cell: stage.cell,
        label: stage.y === "total" ? "All" : stage.row,
        repNo: stage.z,

        opportunities:
          stage.y === "total"
            ? [].concat
                .apply(
                  [],
                  this.props.newBins
                    .filter((x) => x[stage.x] === stage.cell)
                    .map((x) => x.drilldown)
                )
                .sort((a, b) =>
                  b.attainment === a.attainment
                    ? b.acv - a.acv
                    : b.attainment - a.attainment
                )
            : [].concat.apply(
                [],
                this.props.newBins
                  .filter(
                    (x) => x.label === stage.row && x[stage.x] === stage.cell
                  )
                  .map((x) => x.drilldown)
              ),
      },
    });
  };

  handleChangeLocationDrill = (location, stage, team, segment) => {
    // const screenPosition = window.scrollY;
let fDrill = []
if(stage.row !== 'total'){
  if(![null, undefined, ''].includes('stage.row')){
    fDrill.push({
      title: "Owner Name",
      value: stage.row !== "total" ? [stage.row] : stage.uniqueRep,
    })
  }
  
}

if(team !== undefined){
  fDrill.push({
    title: "Team",
    value: typeof team === "string" ? [team] : team,
  })
}
  
    
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
      selectedDrilldown:
        stage.cell !== "total" ? stage.cell : this.state.filtersForDrilldown,
      selectedDrilldownQY: stage.quarter,
      fDrillDown: fDrill
    });
    // console.log(stage.uniqueQuarters)
    if (location !== "overview") {
      let filters = [];
      filters = [
        //  ...this.props.currentFilters,
        {
          name: stage.quarter,
          value: stage.cell !== "total" ? [stage.cell] : stage.uniqueQuarters,
          // this.state[stage.quarter].includes("All") ?
          //     this.props.quotaAttainmentCompareFilters !== '' && this.props.quotaAttainmentCompareFilters.filter(y => y.type === stage.quarter && y[stage.quarter] !== '')
          //         .map(y => y[stage.quarter]).filter(item => item !== null)
          //     : this.state[stage.quarter],
          string: true,
        },
        {
          name: "Owner_Name",
          value: stage.row !== "total" ? [stage.row] : stage.uniqueRep,
          string: true,
        },
      ];

      // filters =
      //   this.state.team[0] !== "All"
      //     ? [
      //         ...filters,
      //         {
      //           name: "team",
      //           value:
      //             typeof this.state.team === "string"
      //               ? [this.state.team]
      //               : this.state.team,
      //           string: true,
      //         },
      //       ]
      //     : filters;

      filters =
        team !== undefined
          ? [
              ...filters,
              {
                name: "team",
                value: typeof team === "string" ? [team] : team,
                string: true,
              },
            ]
          : filters;

      if (stage.cell === "total") {
        if (this.state.Ramp_Status[0] !== "All") {
          filters = [
            ...filters,
            { name: "Ramp_Status", value: this.state.Ramp_Status },
          ];
        }
      }

      if (stage.rampStatus) {
        filters = [
          ...filters,
          {
            name: "Ramp_Status",
            value:
              typeof stage.rampStatus === "string"
                ? [stage.rampStatus]
                : stage.rampStatus,
          },
        ];
      }
      if (segment === 'team') {
        const filteredFilters = filters.filter(f => f.name !== 'Owner_Name');
      
            this.props.getTeamAttainmentDrilldown(filteredFilters);
        }else{

          this.props.getQuotaAttainmentDrilldown(filters);
        }
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  onThresholdChange = (event, value) => {
    // const screenPosition = window.scrollY;
    this.setState({
      ...this.state,
      Threshold: value < 0 || value === null ? 85 : value,
    });
  };

  handleChangeToggle = (e) => {
    // const screenPosition = window.scrollY;
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer}>
        {addHeaderdescription(
          "Quota Attainment Distribution",
          "Examine the quota attainment distribution across your entire team."
        )}
        <Grid
          item
          container
          className={classes.grid}
          style={{ marginBottom: 10, marginLeft: 25 }}
          xs={12}
        >
          {/* <Grid item xs={12} justify="center">
            <Typography variant="h2" align="center">
              Quota Attainment Distribution
            </Typography>
          </Grid>
          <Grid item xs={12} justify="left" style={{ margintop: 10 }}>
            <Typography variant="body1">
              Examine the quota attainment distribution across your entire team.
            </Typography>
          </Grid> */}

          {this.props.quotaAttainmentCompareFilters !== undefined ? (
            <Grid
              className={classes.grid}
              item
              container
              justify="flex-start"
              xs={12}
            >
              <Grid container item style={{ padding: 10 }} xs={4}>
                <YearQuarterFilterCombov2
                  activeTimeFilter={this.state.activeTimeFilter}
                  closedFiscalQuarterState={this.state.closed_fiscal_quarter}
                  closedFiscalYear={this.state.closed_fiscal_year}
                  handleChange={this.handleChange}
                  funnelCompareFilters={
                    this.props.quotaAttainmentCompareFilters
                  }
                />
              </Grid>

              <Grid container item style={{ padding: 10 }} xs={2}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth>
                      <InputLabel id="groupby-label">Group By:</InputLabel>
                      <Select
                        labelId="groupby-label"
                        id="groupby"
                        value={this.state.groupby}
                        onChange={this.handleChange}
                        label="groupby"
                        name="groupby"
                        data-test="group-by"
                      >
                        <MenuItem value={"None"}>None</MenuItem>
                        <MenuItem value={"Team"}>Team</MenuItem>
                        <MenuItem value={"Team, Ramp Status"}>
                          Team, Ramp Status
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item style={{ padding: 10 }} xs={2}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.team.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Team:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-team"
                        value={this.state.team}
                        onChange={this.handleChange}
                        label="Team"
                        data-test="team"
                        name="team"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.quotaAttainmentCompareFilters !== "" &&
                          this.props.quotaAttainmentCompareFilters
                            .filter((i) => i.type === "team")
                            .map((t) => t.Display_Name)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item style={{ padding: 10 }} xs={2}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-ramp-label"
                        style={{
                          color: !this.state.Ramp_Status.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Ramp Status:
                      </InputLabel>
                      <Select
                        labelId="select-ramp-label"
                        id="select-team"
                        value={this.state.Ramp_Status}
                        onChange={this.handleChange}
                        label="Ramp Status"
                        data-test="ramp"
                        name="Ramp_Status"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.quotaAttainmentCompareFilters !== "" &&
                          this.props.quotaAttainmentCompareFilters
                            .filter((i) => i.type === "Ramp_Status")
                            .map((t) => t.Ramp_Status)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                  data-test="go"
                >
                  GO
                </Button>
              </Grid>

              {/* OppType */}
              {/* {this.props.company === "C0013" && (
                <Grid
                  item
                  spacing={2}
                  xs={2}
                  container
                  component={Paper}
                  style={{ margin: 3 }}
                >
                  <Grid item xs={12} style={{ paddingTop: 12 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Opportunity_Record_Type.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opportunity Record Type:
                      </InputLabel>
                      <Select
                        data-test="Opportunity_Record_Type"
                        value={this.state.Opportunity_Record_Type}
                        onChange={this.handleChange}
                        name="Opportunity_Record_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.quotaAttainmentCompareFilters !== "" &&
                          this.props.quotaAttainmentCompareFilters
                            .filter(
                              (y) =>
                                y.type === "Opportunity_Record_Type" &&
                                y.Opportunity_Record_Type !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Opportunity_Record_Type}
                                value={y.Opportunity_Record_Type}
                              >
                                {y.Opportunity_Record_Type}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )} */}
            </Grid>
          ) : (
            <Grid
              item
              container
              component={Card}
              style={{ margin: "1rem" }}
              justify="center"
            >
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          )}
        </Grid>

        {["C0016"].includes(this.props.company) &&
          (this.props.quotaAttainmentCompareFilters ? (
            <Grid
              item
              xs={12}
              md={12}
              id="grid-container"
              style={{
                marginTop: "1rem",
                marginLeft: "1.8rem",
                marginRight: "1.8rem",
              }}
            >
              <Paper style={{ marginBottom: "-7px" }}>
                <ToggleButtonGroup
                  color="primary"
                  indicatorColor="primary"
                  value={this.state.tab}
                  exclusive
                  size="large"
                  onChange={this.handleChangeTab}
                >
                  <ToggleButton
                    style={{
                      background: this.state.tab === "Summary" ? "#4472C4" : "",
                      color: this.state.tab === "Summary" ? "white" : "",
                      fontSize: "1rem",
                      fontWeight: 600,
                      height: "3.5rem",
                      width: "20rem",
                    }}
                    value="Summary"
                  >
                    Summary
                  </ToggleButton>
                  <ToggleButton
                    style={{
                      background: this.state.tab === "seats" ? "#4472C4" : "",
                      color: this.state.tab === "seats" ? "white" : "",
                      fontSize: "1rem",
                      fontWeight: 600,
                      height: "3.5rem",
                      width: "20rem",
                    }}
                    value="seats"
                  >
                    Seats View
                  </ToggleButton>
                </ToggleButtonGroup>
              </Paper>
            </Grid>
          ) : (
            <Card style={{ margin: "auto", width: "100%" }} raised={true}>
              <Grid item container justify="center">
                <Skeleton
                  variant="react"
                  animation="wave"
                  height={20}
                  width="90%"
                  style={{ margin: "1rem" }}
                />
              </Grid>
            </Card>
          ))}

        {this.state.tab === "Summary" ? (
          <Grid
            item
            xs={12}
            className={classes.grid}
            container
            justify="center"
          >
            <Grid item xs={12} style={{ margin: "2rem" }}>
              <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                {this.props.QuotaAttainmentData && (
                  <Grid container xs={12} justify="center">
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Typography
                        variant="h2"
                        style={{
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        Quota Attainment Distribution (Summary)
                      </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: "2rem" }}>
                      <ToggleButtonGroup
                        color="primary"
                        indicatorColor="primary"
                        value={this.state.toggleValue}
                        exclusive
                        size="small"
                        onChange={this.handleChangeToggle}
                      >
                        <CustomWidthTooltip
                          title="Sort attainment % highest to lowest."
                          placement="bottom"
                        >
                          <ToggleButton
                            style={{
                              background:
                                this.state.toggleValue === "PercSort"
                                  ? "#4472C4"
                                  : "",
                              color:
                                this.state.toggleValue === "PercSort"
                                  ? "white"
                                  : "",
                              fontSize: "1rem",
                              fontWeight: 600,
                              width: "3rem",
                              height: "2rem",
                            }}
                            value="PercSort"
                          >
                            %
                          </ToggleButton>
                        </CustomWidthTooltip>
                        <CustomWidthTooltip
                          title="Sort in logical order."
                          placement="bottom"
                        >
                          <ToggleButton
                            style={{
                              background:
                                this.state.toggleValue === "AlphaSort"
                                  ? "#4472C4"
                                  : "",
                              color:
                                this.state.toggleValue === "AlphaSort"
                                  ? "white"
                                  : "",
                              fontSize: "1rem",
                              fontWeight: 600,
                              width: "3rem",
                              height: "2rem",
                            }}
                            value="AlphaSort"
                          >
                            A
                          </ToggleButton>
                        </CustomWidthTooltip>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                )}

                {this.props.newBins !== undefined ? (
                  <Grid container>
                    {(this.props.newBins && this.props.fiscal_years) !==
                    undefined ? (
                      <>
                        <Grid item container xs={12} justify="center">
                          <div
                            style={{
                              justifyContent: "center",
                              marginTop: 20,
                              margin: "auto",
                              width: "95%",
                            }}
                            className="line-container"
                            ref={this._setRef1.bind(this)}
                          />
                        </Grid>
                        {(this.props.fiscal_years.length <= 1
                          ? ["Team", "Team, Ramp Status"].includes(
                              this.props.groupby
                            )
                          : ["None", "Team", "Team, Ramp Status"].includes(
                              this.props.groupby
                            )) && (
                          <>
                            <Grid item container xs={12} justify="center">
                              <Grid item xs={12}>
                                <Typography
                                  variant="h2"
                                  align="center"
                                  style={{
                                    margin: "1rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "1rem",
                                  }}
                                >
                                  {`Quota Attainment Distribution By ${this.props.groupby}`}
                                </Typography>
                              </Grid>

                              <div
                                style={{
                                  justifyContent: "center",
                                  marginTop: 20,
                                  margin: "auto",
                                  width: "95%",
                                }}
                                className="line-container"
                                ref={this._setRef2.bind(this)}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              container
                              style={{
                                margin: "1rem",
                                marginTop: "-3rem",
                                marginLeft: "6rem",
                              }}
                            >
                              <Legend
                                square={15}
                                legends={getCustomSortedColoredLegendsV2(
                                  this.props.groupby !== "None"
                                    ? this.state.toggleValue === "PercSort"
                                      ? this.props.newBins.filter(
                                          (d) =>
                                            d[
                                              this.props.selectionColumn ===
                                              "timeFrame"
                                                ? "year"
                                                : this.props.selectionColumn
                                            ] !== "total"
                                        )
                                      : this.props.logicalTeams
                                          .map((t) =>
                                            this.props.newBins
                                              .filter(
                                                (d) =>
                                                  d[
                                                    this.props
                                                      .selectionColumn ===
                                                    "timeFrame"
                                                      ? "year"
                                                      : this.props
                                                          .selectionColumn
                                                  ] !== "total"
                                              )
                                              .find(
                                                (c) =>
                                                  c[
                                                    this.props.groupbyColumn
                                                  ] === t
                                              )
                                          )
                                          .filter(Boolean)
                                    : this.props.newBins.filter(
                                        (d) =>
                                          d[
                                            this.props.selectionColumn ===
                                            "timeFrame"
                                              ? "year"
                                              : this.props.selectionColumn
                                          ] !== "total"
                                      ),
                                  this.props.selectionColumn === "timeFrame"
                                    ? "year"
                                    : this.props.selectionColumn,
                                  "avg_cycle_time"
                                )}
                                type="segment"
                              />

                              {/* <AverageDealSizeTable subtitle="Avg. Deal Size" type={"acv"} quarter={"closed_fiscal_year"} winAcvData={this.props.QuotaAttainmentData1} isAcv={true} /> */}
                            </Grid>
                          </>
                        )}
                        <Grid align="center" container>
                          <QTTable
                            data={this.props.newBins}
                            quarters={
                              this.props.selectionColumn === "timeFrame"
                                ? "year"
                                : this.props.selectionColumn
                            }
                            handleChangeLocation={this.handleChangeLocation}
                            toggleValue={this.state.toggleValue}
                            groupby={this.props.groupby}
                            logicalTeams={this.props.logicalTeams}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item container xs={12}>
                          <div
                            style={{
                              justifyContent: "center",
                              marginTop: 5,
                              margin: "auto",
                              width: "95%",
                            }}
                            className="line-container"
                            ref={this._setRef1.bind(this)}
                          />
                        </Grid>

                        {(this.props.fiscal_quarters.length <= 1
                          ? ["Team", "Team, Ramp Status"].includes(
                              this.props.groupby
                            )
                          : ["None", "Team", "Team, Ramp Status"].includes(
                              this.props.groupby
                            )) && (
                          <>
                            <Grid item container xs={12} md={12}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="h2"
                                  align="center"
                                  style={{
                                    margin: "1rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "1rem",
                                  }}
                                >
                                  {`Quota Attainment Distribution By ${this.props.groupby}`}
                                </Typography>
                              </Grid>
                              <div
                                style={{
                                  justifyContent: "center",
                                  marginTop: 20,
                                  margin: "auto",
                                  width: "95%",
                                }}
                                className="line-container"
                                ref={this._setRef2.bind(this)}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              container
                              style={{
                                margin: "1rem",
                                marginTop: "-3rem",
                                marginLeft: "6rem",
                              }}
                            >
                              <Legend
                                square={15}
                                legends={getCustomSortedColoredLegendsV2(
                                  this.props.groupby !== "None"
                                    ? this.state.toggleValue === "PercSort"
                                      ? this.props.newBins.filter(
                                          (d) =>
                                            d[
                                              this.props.selectionColumn ===
                                              "timeFrame"
                                                ? "quarter"
                                                : this.props.selectionColumn
                                            ] !== "total"
                                        )
                                      : this.props.logicalTeams
                                          .map((t) =>
                                            this.props.newBins
                                              .filter(
                                                (d) =>
                                                  d[
                                                    this.props
                                                      .selectionColumn ===
                                                    "timeFrame"
                                                      ? "quarter"
                                                      : this.props
                                                          .selectionColumn
                                                  ] !== "total"
                                              )
                                              .find(
                                                (c) =>
                                                  c[
                                                    this.props.groupbyColumn
                                                  ] === t
                                              )
                                          )
                                          .filter(Boolean)
                                    : this.props.newBins.filter(
                                        (d) =>
                                          d[
                                            this.props.selectionColumn ===
                                            "timeFrame"
                                              ? "quarter"
                                              : this.props.selectionColumn
                                          ] !== "total"
                                      ),
                                  this.props.selectionColumn === "timeFrame"
                                    ? "quarter"
                                    : this.props.selectionColumn,
                                  "avg_cycle_time"
                                )}
                                type="segment"
                              />

                              {/* <AverageDealSizeTable subtitle="Avg. Deal Size" type={"acv"} quarter={"closed_fiscal_year"} winAcvData={this.props.QuotaAttainmentData1} isAcv={true} /> */}
                            </Grid>
                          </>
                        )}
                        <Grid align="center" container>
                          <QTTable
                            data={this.props.newBins}
                            quarters={
                              this.props.selectionColumn === "timeFrame"
                                ? "quarter"
                                : this.props.selectionColumn
                            }
                            handleChangeLocation={this.handleChangeLocation}
                            toggleValue={this.state.toggleValue}
                            groupby={this.props.groupby}
                            logicalTeams={this.props.logicalTeams}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                ) : (
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width="95%"
                    height={230}
                    style={{ margin: "2rem" }}
                  />
                )}
              </Card>
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.grid}
              style={{ margin: "2rem" }}
            >
              <Card style={{ margin: "auto", width: "100%" }}>
                {this.props.QuotaAttainmentData && (
                  <Grid container xs={12} justify="center">
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Typography
                        variant="h2"
                        style={{
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        Quota Attainment (Summary)
                      </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: "2rem" }}>
                      <ToggleButtonGroup
                        color="primary"
                        indicatorColor="primary"
                        value={this.state.toggleValue}
                        exclusive
                        size="small"
                        onChange={this.handleChangeToggle}
                      >
                        <CustomWidthTooltip
                          title="Sort attainment % highest to lowest."
                          placement="bottom"
                        >
                          <ToggleButton
                            style={{
                              background:
                                this.state.toggleValue === "PercSort"
                                  ? "#4472C4"
                                  : "",
                              color:
                                this.state.toggleValue === "PercSort"
                                  ? "white"
                                  : "",
                              fontSize: "1rem",
                              fontWeight: 600,
                              width: "3rem",
                              height: "2rem",
                            }}
                            value="PercSort"
                          >
                            %
                          </ToggleButton>
                        </CustomWidthTooltip>
                        <CustomWidthTooltip
                          title="Sort in logical order."
                          placement="bottom"
                        >
                          <ToggleButton
                            style={{
                              background:
                                this.state.toggleValue === "AlphaSort"
                                  ? "#4472C4"
                                  : "",
                              color:
                                this.state.toggleValue === "AlphaSort"
                                  ? "white"
                                  : "",
                              fontSize: "1rem",
                              fontWeight: 600,
                              width: "3rem",
                              height: "2rem",
                            }}
                            value="AlphaSort"
                          >
                            A
                          </ToggleButton>
                        </CustomWidthTooltip>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                )}

                {this.props.quota_attainment_with_perc !== undefined &&
                  this.props.selectionColumn !== "timeFrame" && (
                    <>
                      <Grid item container xs={12} md={12}>
                        <div
                          style={{
                            justifyContent: "center",
                            marginTop: 20,
                            margin: "auto",
                            width: "95%",
                          }}
                          className="line-container"
                          ref={this._setRef3.bind(this)}
                        />
                      </Grid>

                      <Typography
                        variant="h2"
                        align="center"
                        style={{
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        {`Quota Attainment By ${this.props.groupby}`}
                      </Typography>

                      <Grid item container xs={12} md={12}>
                        <div
                          style={{
                            justifyContent: "center",
                            marginTop: 20,
                            margin: "auto",
                            width: "95%",
                          }}
                          className="line-container"
                          ref={this._setRef4.bind(this)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        style={{
                          margin: "1rem",
                          marginTop: "-3rem",
                          marginLeft: "6rem",
                        }}
                      >
                        <Legend
                          square={15}
                          legends={getCustomSortedColoredLegendsV2(
                            this.props.quota_attainment_with_perc_team
                              .filter(
                                (c) =>
                                  c[
                                    this.props.fiscal_years !== undefined
                                      ? "closed_fiscal_year"
                                      : "closed_fiscal_quarter"
                                  ] !== "total"
                              )
                              .map((c) => ({
                                ...c,
                                value: c.attainment,
                                label: c[this.props.selectionColumn],
                              })),
                            this.props.fiscal_years !== undefined
                              ? "closed_fiscal_year"
                              : "closed_fiscal_quarter",
                            "avg_cycle_time"
                          )}
                          type="segment"
                        />

                        {/* <AverageDealSizeTable subtitle="Avg. Deal Size" type={"acv"} quarter={"closed_fiscal_year"} winAcvData={this.props.QuotaAttainmentData1} isAcv={true} /> */}
                      </Grid>
                    </>
                  )}

                {this.props.quota_attainment_with_perc !== undefined ? (
                  <Grid container>
                    {(this.props.quota_attainment_with_perc &&
                      this.props.fiscal_years) !== undefined ? (
                      <>
                        <QTDetailTableV2
                          data={this.props.quota_attainment_with_perc}
                          teamData={this.props.quota_attainment_with_perc_team}
                          repData={this.props.quota_attainment_with_perc_rep}
                          quarters={"closed_fiscal_year"}
                          uniqueQuarters={this.props.fiscal_years}
                          groupby={this.props.groupby}
                          toggleValue={this.state.toggleValue}
                          logicalTeams={this.props.logicalTeams}
                          groupbyColumn={this.props.groupbyColumn}
                          handleChangeLocation={this.handleChangeLocationDrill}
                          showReps={true}
                        />
                        {(this.props.groupby === "Team" ||
                          this.props.groupby === "Team, Ramp Status") && (
                          <QTDetailTableV2
                            data={this.props.quota_attainment_with_perc}
                            teamData={
                              this.props.quota_attainment_with_perc_team
                            }
                            repData={this.props.quota_attainment_with_perc_rep}
                            quarters={"closed_fiscal_year"}
                            uniqueQuarters={this.props.fiscal_years}
                            groupby={this.props.groupby}
                            toggleValue={this.state.toggleValue}
                            logicalTeams={this.props.logicalTeams}
                            groupbyColumn={this.props.groupbyColumn}
                            handleChangeLocation={
                              this.handleChangeLocationDrill
                            }
                            dataTest={"quota-attainment-detail-by-team-table"}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <QTDetailTableV2
                          data={this.props.quota_attainment_with_perc}
                          teamData={this.props.quota_attainment_with_perc_team}
                          repData={this.props.quota_attainment_with_perc_rep}
                          quarters={"closed_fiscal_quarter"}
                          uniqueQuarters={this.props.fiscal_quarters}
                          groupby={this.props.groupby}
                          toggleValue={this.state.toggleValue}
                          logicalTeams={this.props.logicalTeams}
                          groupbyColumn={this.props.groupbyColumn}
                          handleChangeLocation={this.handleChangeLocationDrill}
                          showReps={true}
                        />
                        {(this.props.groupby === "Team" ||
                          this.props.groupby === "Team, Ramp Status") && (
                          <QTDetailTableV2
                            data={this.props.quota_attainment_with_perc}
                            teamData={
                              this.props.quota_attainment_with_perc_team
                            }
                            repData={this.props.quota_attainment_with_perc_rep}
                            quarters={"closed_fiscal_quarter"}
                            uniqueQuarters={this.props.fiscal_quarters}
                            groupby={this.props.groupby}
                            toggleValue={this.state.toggleValue}
                            logicalTeams={this.props.logicalTeams}
                            groupbyColumn={this.props.groupbyColumn}
                            handleChangeLocation={
                              this.handleChangeLocationDrill
                            }
                            dataTest={"quota-attainment-detail-by-team-table"}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                ) : (
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width="95%"
                    height={230}
                    style={{ margin: "2rem" }}
                  />
                )}
              </Card>
            </Grid>

            {this.props.QuotaAttainmentData &&
              this.props.selectionColumn !== "timeFrame" && (
                <Grid
                  item
                  xs={12}
                  className={classes.grid}
                  style={{ margin: "2rem" }}
                >
                  <Card style={{ margin: "auto", width: "100%" }}>
                    {this.props.QuotaAttainmentData && (
                      <Grid container xs={12} justify="center">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="h2"
                            style={{
                              margin: "1rem",
                              display: "flex",
                              justifyContent: "center",
                              padding: "1rem",
                            }}
                          >
                            Attainment Threshold
                          </Typography>
                        </Grid>
                        <Grid item xs={1} style={{ marginTop: "2rem" }}>
                          <ToggleButtonGroup
                            color="primary"
                            indicatorColor="primary"
                            value={this.state.toggleValue}
                            exclusive
                            size="small"
                            onChange={this.handleChangeToggle}
                          >
                            <CustomWidthTooltip
                              title="Sort attainment % highest to lowest."
                              placement="bottom"
                            >
                              <ToggleButton
                                style={{
                                  background:
                                    this.state.toggleValue === "PercSort"
                                      ? "#4472C4"
                                      : "",
                                  color:
                                    this.state.toggleValue === "PercSort"
                                      ? "white"
                                      : "",
                                  fontSize: "1rem",
                                  fontWeight: 600,
                                  width: "3rem",
                                  height: "2rem",
                                }}
                                value="PercSort"
                              >
                                %
                              </ToggleButton>
                            </CustomWidthTooltip>
                            <CustomWidthTooltip
                              title="Sort in logical order."
                              placement="bottom"
                            >
                              <ToggleButton
                                style={{
                                  background:
                                    this.state.toggleValue === "AlphaSort"
                                      ? "#4472C4"
                                      : "",
                                  color:
                                    this.state.toggleValue === "AlphaSort"
                                      ? "white"
                                      : "",
                                  fontSize: "1rem",
                                  fontWeight: 600,
                                  width: "3rem",
                                  height: "2rem",
                                }}
                                value="AlphaSort"
                              >
                                A
                              </ToggleButton>
                            </CustomWidthTooltip>
                          </ToggleButtonGroup>
                        </Grid>
                      </Grid>
                    )}

                    {this.props.quota_attainment_with_perc !== undefined && (
                      <Grid
                        container
                        item
                        style={{ padding: 10, marginLeft: "2rem" }}
                        xs={2}
                      >
                        <Grid
                          item
                          spacing={2}
                          xs={12}
                          container
                          component={Paper}
                          style={{ paddingTop: 8 }}
                        >
                          <Grid item xs={12} style={{ paddingTop: 0 }}>
                            <Autocomplete
                              id="free-solo-demo"
                              freeSolo
                              options={["0", "25", "50", "75", "100"]}
                              // getOptionLabel={(option) => option}
                              value={this.state.Threshold}
                              onChange={this.onThresholdChange}
                              name="Threshold"
                              data-test="Threshold"
                              autoSelect={true}
                              // disabled={!this.state.team.includes('All')}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Attainment Threshold >= :"
                                  variant="standard"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                  // InputProps={{
                                  //   ...params.InputProps,
                                  //   inputComponent: NumberFormatCustom,
                                  // }}
                                  InputLabelProps={{
                                    style: {
                                      color:
                                        this.state.Threshold !== null &&
                                        this.state.Threshold !== 0
                                          ? "#4472c4"
                                          : "#000",
                                    },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {this.props.quota_attainment_with_perc !== undefined ? (
                      <Grid container>
                        {(this.props.quota_attainment_with_perc &&
                          this.props.fiscal_years) !== undefined ? (
                          <AttainmnetThresholdTable
                            data={this.props.quota_attainment_with_perc}
                            teamData={
                              this.props.quota_attainment_with_perc_team
                            }
                            repData={this.props.quota_attainment_with_perc}
                            quarters={"closed_fiscal_year"}
                            uniqueQuarters={this.props.fiscal_years}
                            groupby={this.props.groupby}
                            Threshold={this.state.Threshold}
                            logicalTeams={this.props.logicalTeams}
                            groupbyColumn={this.props.groupbyColumn}
                            toggleValue={this.state.toggleValue}
                            handleChangeLocation={
                              this.handleChangeLocationDrill
                            }
                          />
                        ) : (
                          <AttainmnetThresholdTable
                            data={this.props.quota_attainment_with_perc}
                            teamData={
                              this.props.quota_attainment_with_perc_team
                            }
                            repData={this.props.quota_attainment_with_perc}
                            quarters={"closed_fiscal_quarter"}
                            uniqueQuarters={this.props.fiscal_quarters}
                            groupby={this.props.groupby}
                            Threshold={this.state.Threshold}
                            logicalTeams={this.props.logicalTeams}
                            toggleValue={this.state.toggleValue}
                            groupbyColumn={this.props.groupbyColumn}
                            handleChangeLocation={
                              this.handleChangeLocationDrill
                            }
                          />
                        )}
                      </Grid>
                    ) : (
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="95%"
                        height={230}
                        style={{ margin: "2rem" }}
                      />
                    )}
                  </Card>
                </Grid>
              )}
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            className={classes.grid}
            style={{ margin: "2rem" }}
          >
            <Card style={{ margin: "auto", width: "100%" }}>
              {this.props.QuotaAttainmentData && (
                <Grid container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{
                        margin: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      Quota Attainment (Seats View)
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ marginTop: "2rem" }}>
                    <ToggleButtonGroup
                      color="primary"
                      indicatorColor="primary"
                      value={this.state.toggleValue}
                      exclusive
                      size="small"
                      onChange={this.handleChangeToggle}
                    >
                      <CustomWidthTooltip
                        title="Sort attainment % highest to lowest."
                        placement="bottom"
                      >
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "PercSort"
                                ? "#4472C4"
                                : "",
                            color:
                              this.state.toggleValue === "PercSort"
                                ? "white"
                                : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          value="PercSort"
                        >
                          %
                        </ToggleButton>
                      </CustomWidthTooltip>
                      <CustomWidthTooltip
                        title="Sort in logical order."
                        placement="bottom"
                      >
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "AlphaSort"
                                ? "#4472C4"
                                : "",
                            color:
                              this.state.toggleValue === "AlphaSort"
                                ? "white"
                                : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          value="AlphaSort"
                        >
                          A
                        </ToggleButton>
                      </CustomWidthTooltip>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              )}

              {this.props.quota_attainment_with_perc !== undefined ? (
                <Grid container>
                  {(this.props.quota_attainment_with_perc &&
                    this.props.fiscal_years) !== undefined ? (
                    <>
                      <QTDSeatsTable
                        data={this.props.quota_attainment_with_perc}
                        teamData={this.props.quota_attainment_with_perc_team}
                        repData={this.props.quota_attainment_with_perc_rep}
                        quarters={"closed_fiscal_year"}
                        uniqueQuarters={this.props.fiscal_years}
                        groupby={this.props.groupby}
                        toggleValue={this.state.toggleValue}
                        logicalTeams={this.props.logicalTeams}
                        groupbyColumn={this.props.groupbyColumn}
                        handleChangeLocation={this.handleChangeLocationDrill}
                        showReps={true}
                      />
                    </>
                  ) : (
                    <>
                      <QTDSeatsTable
                        data={this.props.quota_attainment_with_perc}
                        teamData={this.props.quota_attainment_with_perc_team}
                        repData={this.props.quota_attainment_with_perc_rep}
                        quarters={"closed_fiscal_quarter"}
                        uniqueQuarters={this.props.fiscal_quarters}
                        groupby={this.props.groupby}
                        toggleValue={this.state.toggleValue}
                        logicalTeams={this.props.logicalTeams}
                        groupbyColumn={this.props.groupbyColumn}
                        handleChangeLocation={this.handleChangeLocationDrill}
                        showReps={true}
                      />
                    </>
                  )}
                </Grid>
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="95%"
                  height={230}
                  style={{ margin: "2rem" }}
                />
              )}
            </Card>
          </Grid>
        )}
        {this.state.location === "drilldownOnGraph" && this.props.newBins && (
          <Grid
            item
            xs={12}
            container
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Quota Attainment Distribution`}
              back={this.handleChangeLocationBack}
              // filters={this.state.filtersForDrilldown}
              filters={[
                {
                  title:
                    this.props.selectionColumn === "timeFrame"
                      ? this.state.selectDrillStagee === "quarter"
                        ? "Fiscal Qtr."
                        : "Fiscal Year"
                      : "Team",
                  value: this.state.selectedDrilldown,
                },
              ]}
              //  filters={[{title: this.state.activeTimeFilter === "closed_fiscal_quarter" ? "Closed Fiscal Quarter" : "Closed Fiscal Year", value: this.state.selectDrillStagee}]}
            >
              <QuotaAttainmentDrilldown data={this.state.drilldownData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.location === "drilldownOnGraph2" &&
          this.props.quota_attainment_with_perc && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={`Quota Attainment Detail`}
                back={this.handleChangeLocationBack}
                // filters={this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : null}
                // filters={[{title: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ? "Closed Fiscal Quarter" : "Closed Fiscal Year", value: this.state.selectedDrilldownQY === "closed_fiscal_quarter" ?  this.state.selectedDrilldown === "total" ? this.state.filtersForDrilldown : this.state.selectedDrilldown : this.state.selectedDrilldown === "total" ? this.state.closed_fiscal_year : this.state.selectedDrilldown}]}
                filters={[
                  ...this.state.fDrillDown,
                  {
                    title:
                      this.state.selectedDrilldownQY === "closed_fiscal_quarter"
                        ? "Fiscal Qtr."
                        : "Fiscal Year",
                    value: this.state.selectedDrilldown,
                  },
                ]}
              >
                <Drilldown
                  body={this.props.drilldownOnGraphData}
                  company={this.props.company}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                  Value_Label={this.props.Value_Label}
                />
              </SingleLevelDrilldown>
            </Grid>
          )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      type="text"
      thousandSeparator
      displayType={"string"}
      // isNumericString
      suffix="%"
      allowNegative={false}
    />
  );
}

function mapStateToProps(state) {
  const {
    quotaAttainmentCompareFiltersV2,
    QuotaAttainmentDataV2,
    user,
    currentFilters,
    drilldownOnGraphData,
    ExpectedAcvData,
  } = state.app;
  const {
    fiscal_quarters,
    newBins,
    quota_attainment_with_perc,
    quota_attainment_with_perc_team,
    fiscal_years,
    selectionColumn,
    groupby,
    groupbyColumn,
    quota_attainment_with_perc_rep,
    logicalTeams,
  } = QuotaAttainmentDataV2;

  return {
    quotaAttainmentCompareFilters: quotaAttainmentCompareFiltersV2,
    QuotaAttainmentData: QuotaAttainmentDataV2,
    company: user.company,
    Value_Label: user.Value_Label,
    fiscal_quarters,
    newBins,
    groupby,
    groupbyColumn,
    quota_attainment_with_perc,
    quota_attainment_with_perc_team,
    quota_attainment_with_perc_rep,
    fiscal_years,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
    selectionColumn,
    logicalTeams,
  };
}
const mapDispatchToProps = (dispatch) => ({
  getQuotaAttainmentCompareFilters: () => {
    dispatch({ type: "get_quota_attainment_filters_v2_request" });
    appService.getQuotaAttainmentFilters().then(
      (json) => {
        dispatch({ type: "get_quota_attainment_filters_v2_success", json });
        dispatch({ type: "get_quota_attainment_data_v2_request" });
        const filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
        ];
        const groupbyFilters = { groupby: "Team" };
        // console.log(filters)
        // console.log(filters)
        dispatch({ type: "current_selected_filters", filters });
        appService
          .getQuotaAttainmentDataV2(
            json.message.length === 0 ? [] : filters,
            "closed_fiscal_quarter",
            groupbyFilters
          )
          .then(
            (json) => {
              dispatch({ type: "get_quota_attainment_data_v2_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_quota_attainment_data_v2_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_quota_attainment_data_v2_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_quota_attainment_filters_v2_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_quota_attainment_filters_v2_failure", error });
      }
    );
  },
  getQuotaAttainmentData: (company, key, filters, groupbyFilters) => {
    dispatch({ type: "get_quota_attainment_data_v2_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getQuotaAttainmentDataV2(filters, key, groupbyFilters).then(
      (json) => {
        dispatch({ type: "get_quota_attainment_data_v2_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_quota_attainment_data_v2_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_data_v2_failure", error });
      }
    );
  },
  getQuotaAttainmentDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getQuotaAttainmentDrilldownData(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1];
        tempOpportunities.sort((a, b) => (a.acv > b.acv ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getTeamAttainmentDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getTeamAttainmentDrilldown(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.ACV > b.ACV ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };

        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedQuotaAttainment = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QuotaAttainment));
export default connectedQuotaAttainment;