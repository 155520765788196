import * as d3 from "d3";
import "./D3RepPerformanceBar.scss";
import {
  customTickFormat,
  getUniqueValues,
  stageFormattter,
} from "../../../util/customFunctions";
import { store } from "../../../util/store";
const D3RepPerformanceBar = {};
const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};
D3RepPerformanceBar.create = (el, config) => {
  const Symbol = store.getState();
  const Currency_Symbol = Symbol.app.user.Currency;

  d3.selection.prototype.last = function () {
    return d3.select(this.nodes()[this.size() - 1]);
  };

  if (config.data) {
    config.data = config.data.filter((item) => item.Name !== "total");
    config.barColor = "#70ad47";
    config.barHeight = 10;
    var marginLeftCalc = config.data;
    console.log(config);
    config.margin = {
      top: 20,
      right: 70,
      bottom: 60,
      left:
        config.type === "Region" || config.type === "Segment"
          ? marginLeftCalc.length >= 1
            ? marginLeftCalc.sort(
                (a, b) => b.Display_Name.length - a.Display_Name.length
              )[0]["Display_Name"].length * 11
            : 9
          : marginLeftCalc.sort(
              (a, b) => b["Sales Rep"].length - a["Sales Rep"].length
            )[0]["Sales Rep"].length * 7,
    };
    if (!config.width) {
      config.width = d3.select(el).node()
        ? d3.select(el).node().getBoundingClientRect().width -
          config.margin.left -
          config.margin.right
        : 50;
    }

    //const wonLabel = config.label.filter(item => item.type === "won")[0].label

    if (config.type === "AsOfDate") {
      config.data = config.data
        .map((item) => ({
          closedWon: _isNaN(parseFloat(item[`FY21 Wins`]))
            ? 0
            : parseFloat(item[`FY21 Wins`]),
          rep: item["Sales Rep"],
          quota: item["FY21 Quota"],
          attainment: item["% Attainment YTD"],
        }))
        .filter((item) => item.rep !== "total");
    } else if (config.type === "Region") {
      const uniqueQuarters = config.data
        .map((item) => item.Quarter)
        .filter(getUniqueValues);
      // const uniqueRegions = config.data.map(item=>item.Display_Name).filter(getUniqueValues)

      let uniqueRegions = config.data
        .map((item) => item.Display_Name)
        .filter(getUniqueValues)
        .sort();
      config.data = uniqueRegions.map((region) => ({
        closedWon: _isNaN(
          parseFloat(
            config.data
              .filter((item) => item.Display_Name === region)
              .map((item) => item.acv)
              .reduce((a, b) => a + b, 0)
          )
        )
          ? 0
          : config.data
              .filter((item) => item.Display_Name === region)
              .map((item) => item.acv)
              .reduce((a, b) => a + b, 0),
        quota: _isNaN(
          parseFloat(
            config.data
              .filter((item) => item.Display_Name === region)
              .map((item) => item.Quota)
              .reduce((a, b) => a + b, 0)
          )
        )
          ? 0
          : config.data
              .filter((item) => item.Display_Name === region)
              .map((item) => item.Quota)
              .reduce((a, b) => a + b, 0),
        rep: region,
        attainment:
          _isNaN(
            parseFloat(
              config.data
                .filter((item) => item.Display_Name === region)
                .map((item) => item.acv)
                .reduce((a, b) => a + b, 0)
            )
          ) ||
          _isNaN(
            parseFloat(
              config.data
                .filter((item) => item.Display_Name === region)
                .map((item) => item.Quota)
                .reduce((a, b) => a + b, 0)
            )
          )
            ? 0
            : config.data
                .filter((item) => item.Display_Name === region)
                .map((item) => item.Quota)
                .reduce((a, b) => a + b, 0) === 0
            ? "N/A"
            : config.data
                .filter((item) => item.Display_Name === region)
                .map((item) => item.acv)
                .reduce((a, b) => a + b, 0) /
              config.data
                .filter((item) => item.Display_Name === region)
                .map((item) => item.Quota)
                .reduce((a, b) => a + b, 0),
      }));
    } else if (config.type === "Segment") {
      let uniqueRegions = config.data
        .map((item) => item.Display_Name)
        .filter(getUniqueValues);

      config.data = uniqueRegions.map((row) => ({
        closedWon: config.data
          .filter((item) => item.Display_Name !== null && item.acv !== null)
          .filter((item) => item.Display_Name === row)
          .map((item) => item.ELV)
          .reduce((a, b) => a + b, 0),
        quota: _isNaN(
          config.data
            .filter((item) => item.Display_Name !== null && item.acv !== null)
            .filter((item) => item.Display_Name === row)
            .map((item) => item.Quota)
            .reduce((a, b) => a + b, 0)
        )
          ? 0
          : config.data
              .filter((item) => item.Display_Name !== null && item.acv !== null)
              .filter((item) => item.Display_Name === row)
              .map((item) => item.Quota)
              .reduce((a, b) => a + b, 0),
        rep: row,
        attainment:
          config.data
            .filter((item) => item.Display_Name !== null && item.acv !== null)
            .filter((item) => item.Display_Name === row)
            .map((item) => item.attainmentPercent)
            .reduce((a, b) => a + b, 0) === 0
            ? "N/A"
            : config.data
                .filter(
                  (item) => item.Display_Name !== null && item.acv !== null
                )
                .filter((item) => item.Display_Name === row)
                .map((item) => item.attainmentPercent)
                .reduce((a, b) => a + b, 0),
      }));
    }

    config.height =
      config.type === "Region" || config.type === "Segment"
        ? config.data.length * 90
        : config.data.length * 30;

    var data =
      config.type === "Region" || config.type === "Segment"
        ? config.data
        : config.data.sort((a, b) => b.closedWon - a.closedWon);

    const y = d3
      .scaleBand()
      .rangeRound([0, config.height])
      .padding(0.534482775);

    const x = d3.scaleLinear().range([config.width, 0]);

    const xAxis = d3
      .axisBottom(x)
      .tickFormat((d) =>
        d !== 0
          ? `${Currency_Symbol}${d3.format(".2s")(d)}`.toUpperCase()
          : null
      )
      .ticks(8);

    const yAxis = d3.axisLeft(y);
    y.domain(
      data.map((d) => {
        return d.rep;
      })
    );

    x.domain([
      d3.max([...data.map((d) => d.closedWon), ...data.map((d) => d.quota)]) +
        d3.max([...data.map((d) => d.closedWon), ...data.map((d) => d.quota)]) *
          0.1,
      0,
    ]);

    function make_x_gridlines() {
      return d3.axisBottom(x).ticks(8);
    }

    // gridlines in y axis function
    function make_y_gridlines() {
      return d3.axisLeft(y).ticks(5);
    }

    var xAxisHeight = config.height;
    const chart = d3
      .select(el)
      .append("svg")
      .attr("width", config.width + config.margin.left + config.margin.right)
      .attr("height", config.height + config.margin.top + config.margin.bottom)
      .append("g")
      .attr(
        "transform",
        `translate(${config.margin.left},${config.margin.top})`
      );
    var legend = d3.select(el).append("div").attr("class", "legend");
    chart
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0," + config.barHeight + ")")
      .attr("z-index", -1)
      .call(make_x_gridlines().tickSize(xAxisHeight).tickFormat(""));

    // add the Y gridlines
    // chart
    //     .append("g")
    //     .attr("class", "grid")
    //     .attr("z-index", -1)
    //     .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));

    chart
      .append("g")
      .attr("class", "xaxis")
      .attr("transform", "translate(0," + xAxisHeight + ")")
      .call(xAxis);

    chart.append("g").attr("class", "yaxis").call(yAxis);
    var bars = chart.selectAll(".bar").data(data).enter().append("g");

    bars
      .append("rect")
      //.attr("class", "bar")
      .attr("stroke", "#00000")
      .attr("fill", "#BF9000")
      .attr("opacity", 0.3)
      .attr("y", (d) => (config.type !== "AsOfDate" ? y(d.rep) : y(d.rep) - 4))
      .attr("height", (d) =>
        config.type !== "AsOfDate"
          ? y.bandwidth() / 2.25 + 20
          : y.bandwidth() / 2.25 + 16
      )
      .attr("x", 0)
      // .transition()
      .attr("width", function (d) {
        return x(d.quota);
      });

    bars
      .append("rect")
      .attr("class", "bar")
      .attr("stroke", "#00000")
      .attr("fill", "#70AD47")
      //.attr("opacity", 0.75)
      .attr("y", (d) =>
        config.type !== "AsOfDate" ? y(d.rep) + 4 : y(d.rep) - 2
      )
      .attr("height", y.bandwidth() / 2.25 + 12)
      .attr("x", 0)
      // .transition()
      .attr("width", function (d) {
        return x(d.closedWon);
      });

    //add a value label to the right of each bar
    /* bars.append("text")
      .attr("class", "label")
      //y position of the label is halfway down the bar
      .attr("y", function (d) {
        return y(d.rep) + y.bandwidth() / 2.25 + 4;
      })
      //x position is 3 pixels to the right of the bar
      .attr("x", function (d) {
        return 2;
      })
      .text(d => `$${_isNaN(d.closedWon) ? (0) : d3.format(",")(Math.round(d.closedWon))}`)
      .style("font-weight", 500)
      .attr("fill", 'black'); */

    if (config.type === "Region") {
      bars
        .append("text")
        .attr("class", "label")
        //y position of the label is halfway down the bar
        .attr("y", function (d) {
          return y(d.rep) + y.bandwidth() / 2.25 + 4;
        })
        //x position is 3 pixels to the right of the bar
        .attr("x", function (d) {
          return 2;
        })
        .attr("dy", "0.1rem")
        .text((d) =>
          _isNaN(d.attainment)
            ? d.attainment
            : `${Math.round(d.attainment * 100)}%`
        )
        .style("font-weight", 500)
        .attr("fill", "white");
    } else if (config.type === "Segment") {
      bars
        .append("text")
        .attr("class", "label")
        //y position of the label is halfway down the bar
        .attr("y", function (d) {
          return y(d.rep) + y.bandwidth() / 2.25 + 4;
        })
        //x position is 3 pixels to the right of the bar
        .attr("x", function (d) {
          return 5;
        })
        .attr("dy", "0.1rem")
        .text(
          (d) =>
            `${Currency_Symbol}${
              _isNaN(d.closedWon) ? 0 : d3.format(",")(Math.round(d.closedWon))
            } (${_isNaN(d.attainment) ? 0 : Math.round(d.attainment)}%)`
        )
        .style("font-weight", 500)
        .attr("fill", "#404040");
    } else {
      bars
        .append("text")
        .attr("class", "label")
        .attr("text-anchor", "start")
        //y position of the label is halfway down the bar
        .attr("y", function (d) {
          return y(d.rep) + y.bandwidth() / 2.25 + 4;
        })
        //x position is 3 pixels to the right of the bar
        .attr("x", function (d) {
          return x(d.closedWon) + 2;
        })
        .attr("dy", "0.1rem")
        .text(
          (d) =>
            `${Currency_Symbol}${
              _isNaN(d.closedWon) ? 0 : d3.format(",")(Math.round(d.closedWon))
            } (${_isNaN(d.attainment) ? 0 : Math.round(d.attainment * 100)}%)`
        )
        .style("font-weight", 500);
    }

    chart
      .append("text")
      .attr("y", xAxisHeight + 30)
      .attr("x", config.width / 2 - 80)
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("fill", "#000000");
    //.text(`${(wonLabel).split("_").join(" ")} ACV`);

    if (config.type !== "Region" && config.type !== "Segment") {
      var legends = legend
        .selectAll("div")
        .data([
          { text: "FY21 Wins YTD", color: "#70AD47" },
          { text: "FY21 Quota", color: "#BF9000" },
        ])
        .enter()
        .append("div")
        .attr("class", "legend-flex");
      legends
        .append("div")
        .attr("class", "legends")
        .style("align-items", "end")
        .style("background-color", (d) => d.color)
        .style("opacity", (d) => (d.color === "#BF9000" ? 0.3 : 1));
      legends

        .append("div")
        .text((d) => d.text)
        // .style('text-align','center')
        .style("padding-left", "1rem")
        .style("min-width", "10rem")
        .style("max-width", "10rem");

      legend
        .attr("width", config.width)
        .attr("height", config.height)
        .attr("class", "legend-flex")
        .style("justify-content", "center")
        .selectAll(".legends")
        .data(["one", "two"])
        .enter()
        .append("div")
        .attr("class", "legends")
        .style("background-color", (d) => "blue")
        .html((d) => `<div></div>`);
    }
  }
};
D3RepPerformanceBar.destroy = (el) => {
  d3.select(el).selectAll("svg").remove();
  d3.select(".legend-flex").remove();
};
export default D3RepPerformanceBar;
