/**
 *
 * @param {any[]} data
 * @param {string} quarter
 * @param {string[]} stages
 */
export const sankeyDataTransformer = (
  data,
  quarter,
  stages,
  { source, quarterRefernce, countReference, acvReference, toogleValue }
) => {
  console.log(data);
  const sankeyData = stages
    .map((stage) => {
      const stageDataForQuarter = data.filter((it) => {
        console.log(
          it[quarterRefernce],
          quarterRefernce,
          it,
          quarter,
          it.Stage,
          stage
        );
        return it[quarterRefernce] === quarter && it.Stage === stage;
      });
      // console.log(stageDataForQuarter, quarterRefernce);
      const reference = toogleValue === "count" ? countReference : acvReference;
      const count = stageDataForQuarter.reduce(
        (a, b) => a + b[countReference],
        0
      );
      if (count === 0) {
        return null;
      }
      const sankeyBlock = {
        source: `${source} in  ${quarter}`,
        target: stage,
        value: stageDataForQuarter.reduce((a, b) => a + b[reference], 0),
        count,
        acv: stageDataForQuarter.reduce((a, b) => a + b[acvReference], 0),
      };
      return sankeyBlock;
    })
    .filter(Boolean);
  return sankeyData;
};

export function removeYearFromString(inputString) {
  return inputString.replace(/_year/gi, "");
}

export function removeDateFromString(inputString) {
  return inputString.replace(/_date/gi, "");
}
export const sortYearMonth = (a, b) => {
  // Extract year and month as numbers
  const [yearA, monthA] = a[a.fqType].split("-").map(Number);
  const [yearB, monthB] = b[b.fqType].split("-").map(Number);

  // Concatenate year and month as a single numeric value
  const valueA = Number(`${yearA}${monthA.toString().padStart(2, "0")}`);
  const valueB = Number(`${yearB}${monthB.toString().padStart(2, "0")}`);

  // Compare the numeric values in reverse for descending order
  return valueB - valueA;
};
export const sortQuarters = (a, b) => {
  // Extract year and quarter as numbers
  const [yearA, quarterA] = a[a.fqType].split("-Q").map(Number);
  const [yearB, quarterB] = b[b.fqType].split("-Q").map(Number);

  // Concatenate year and quarter as a single numeric value
  const valueA = Number(`${yearA}${quarterA}`);
  const valueB = Number(`${yearB}${quarterB}`);

  // Compare the numeric values in reverse for descending order
  return valueB - valueA;
};
export const sortQuartersGeneric = (a, b, asc) => {
  // Extract year and quarter as numbers
  const [yearA, quarterA] = a.split("-Q").map(Number);
  const [yearB, quarterB] = b.split("-Q").map(Number);

  // Concatenate year and quarter as a single numeric value
  const valueA = Number(`${yearA}${quarterA}`);
  const valueB = Number(`${yearB}${quarterB}`);
  console.log("Quarertesort", valueA, valueB, asc);

  // Compare the numeric values in reverse for descending order
  return asc ? valueA - valueB : valueB - valueA;
};

export const sortYearMonthGeneric = (a, b, asc = true) => {
  // Extract year and month as numbers
  const [yearA, monthA] = a.split("-").map(Number);
  const [yearB, monthB] = b.split("-").map(Number);

  // Concatenate year and month as a single numeric value
  const valueA = Number(`${yearA}${monthA.toString().padStart(2, "0")}`);
  const valueB = Number(`${yearB}${monthB.toString().padStart(2, "0")}`);

  // Compare the numeric values in reverse for descending order
  return asc ? valueA - valueB : valueB - valueA;
};
