export const preprocessFilters = (filters) => {
  // Remove the "metric" property from the 'filters' object
  delete filters["metric"];

  // Check and modify other properties in the 'filters' object
  if ("q_industry" in filters) {
    filters["industry"] = filters["q_industry"];
    delete filters["q_industry"];
  }

  if ("rep" in filters) {
    filters["rep"] = [filters["rep"]["UserID"]];
  }

  if ("team" in filters && typeof filters["team"] !== "boolean") {
    filters["team"] = filters["team"].map((t) => t["Display_Name"]);
  } else if ("team" in filters && typeof filters["team"] === "boolean") {
    delete filters["team"];
  }

  if ("month" in filters) {
    filters["month"] = filters["month"]["month_nums"];
  }

  if ("acvPreset" in filters) {
    filters["acv"] = filters["acvPreset"];
    delete filters["acvPreset"];
  }
  return filters;
};
