import React from "react";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Divider,
  Button,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import DynamicTableSegment from "../../components/DyanmicTableSegment";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Fragment } from "react";
import DatePicker from "../../components/DatePicker";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import WaterfallDrilldownBody from "../NewWaterFall/WaterfallDrilldownBody";
import PipelineTrendsRollingTable from "./PipelineTrendsRollingTable";
import PipelineTrendsDetailTable from "./PipelineTrendsDetailTable";
import {
  customDateFormatter,
  customDateFormatterNew,
  dateFormatter,
  dateFormatterCalenderControl,
  dateFormatterMonthInWords,
  dateSubractorInISO,
  dateSubtractorMoment,
  isdateGreater,
  isdateLesser,
  getTimingArr,
  dateFormatterMoment,
  _isNaN,
} from "../../util/customFunctions";
import { nearestCompletedFiscalQuarter } from "../../util/fiscalQuarterFunctions";
import FilterContainer from "../../Filters/FilterContainer";
import RegionFilter from "../../Filters/Region_Filter";
import Acct_SegmentFilter from "../../Filters/Acct_SegmentFilter";
import OppotunityType from "../../Filters/OpportunityType";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  formControl: {
    maxWidth: 250,
  },
  grid: {
    width: "90%",
    margin: "auto",
    marginTop: "1rem",
  },
  datesFilterContainer: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    padding: 10,
    paddingBottom: 10,
    width: "32%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  repTeamFilterContainer: {
    padding: 10,
    margin: 5,
    width: "29%",
    [theme.breakpoints.down("md")]: {
      width: "37%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  acvFilterContainer: {
    padding: 10,
    margin: 5,
    paddingBottom: 2,
    width: "20%",
    [theme.breakpoints.down("md")]: {
      width: "35%",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
  stageFilterContainer: {
    padding: 5,
    paddingTop: 10,
    margin: 5,
    paddingBottom: 10,
    width: "10%",
    [theme.breakpoints.down("md")]: {
      width: "20%",
    },
  },
  buttonContainer: {
    padding: 5,
    width: "5%",
    alignSelf: "center",
  },
});

class PipelineTrendsRolling extends React.Component {
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  filters = [
    "Rep",
    "team",
    "Projected_Close_Date_At_Time_Fiscal_Quarter",
    "Cust_Type",
    "Region_Rollup",
    "Booking_Type_NL_CS_US",
    "Acct_Segment",
    "Record_Type_Name__c",
  ];

  formatDate = (dt) => {
    let date = dt.toString();
    return date.length < 2 ? `0${date}` : date;
  };

  state = {
    number: 4,
    activelast: 7,
    startingEnding: "Ending",
    timeFrame: "window",
    from: dateFormatter(this.props.waterfallMaxDate),
    to: dateFormatter(this.props.waterfallMaxDate),
    // fromDisplay: customDateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, 7)),
    // toDisplay: customDateFormatter(this.props.waterfallMaxDate),
    // activeFrom: dateFormatter(dateSubractorInISO(this.props.waterfallMaxDate, 7)),
    // activeTo: dateFormatter(this.props.waterfallMaxDate),
    minDateForTo: this.props.waterfallMinDate,
    maxDateForTo: this.props.waterfallMaxDate,
    minDateForFrom: this.props.waterfallMinDate,
    maxDateForFrom: this.props.waterfallMaxDate,
    maxDate: this.props.waterfallMaxDate,
    trendInterval: "quarters",
    errorTextFrom: "",
    errorTextTo: "",
    Rep: ["All"],
    team: ["All"],
    Cust_Type: ["All"],
    disabled: [],
    repTeam: "Rep",
    Projected_Close_Date_At_Time_Fiscal_Quarter: ["All"],
    Projected_Close_Rolling: [6],
    filtersForDrilldownpcfq: ["All"],
    earliestOpenStage: 3,
    location: "overview",
    filtersForDrilldown: [],
    activeFilters: [],
    selectedDrilldownStage: "",
    selectedDrilldownStageLabel: "",
    filtersForDrilldownStaringEnding: "Ending",
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    isRolling: true,
    Record_Type_Name__c: ["All"],
  };

  orderRepsByLastName = () => {
    let reps = this.props.waterfallFilters.reps
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ");
        return {
          firstName: res[0],
          lastName: res[res.length - 1],
          fullName: rep,
        };
      });
    function compare(a, b) {
      if (a.lastName < b.lastName) {
        return -1;
      }
      if (a.lastName > b.lastName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  componentDidMount() {
    this.props.getWaterfallMinDate();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    // D3Funnel.destroy(this._rootNode)
    // this._chart = D3Funnel.create(
    //   this._rootNode,
    //   {
    //     data: this.props.waterfallData,
    //     handleChangeLocation: this.handleChangeLocation,
    //     company: this.props.company,
    //     disableStartingEndingPipeline: this.props.company === "C0003"?false:false,
    //     disableDrilldown:true
    //   }
    // )
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.waterfallMinDate !== prevProps.waterfallMinDate ||
      this.props.waterfallMaxDate !== prevProps.waterfallMaxDate
    ) {
      var [year, month, dt] = getTimingArr(
        this.props.waterfallMaxDate,
        false,
        "T"
      );
      // console.log(month)
      // console.log(dateFormatterMoment({year,month:month,date:dt}),year,month,dt)
      this.setState({
        ...this.state,
        minDateForTo: this.props.waterfallMinDate,
        maxDateForTo: dateFormatterMoment({ year, month, date: dt }),
        minDateForFrom: this.props.waterfallMinDate,
        maxDateForFrom: dateFormatterMoment({ year, month, date: dt }),
        filtersForDrilldown: this.passFiltersForDrilldown(),
        //  maxDateForFrom: this.stae.maxDateForFrom,
        //Projected_Close_Date_At_Time_Fiscal_Quarter: [this.props.waterfallFilters.currentFiscalQuarter.current_fiscal_quarter],
        //filtersForDrilldownpcfq: [this.props.waterfallFilters.currentFiscalQuarter.current_fiscal_quarter],
        from: dateFormatter(this.props.waterfallMaxDate),
        to: dateFormatterMoment({ year: year, month, date: dt }),
        maxDate: this.props.waterfallMaxDate,
        fromDisplay: customDateFormatter(
          dateSubractorInISO(this.props.waterfallMaxDate, 7)
        ),
        toDisplay: customDateFormatter(this.props.waterfallMaxDate),
        activeFrom: dateFormatter(
          dateSubractorInISO(this.props.waterfallMaxDate, 7)
        ),
        activeTo: dateFormatterMoment({ year, month, date: dt }),
      });
    }
  }

  /* componentWillUnmount() {
      D3Funnel.destroy(this._rootNode);
  } */

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  // handleChange = e => {
  //   if (e.target.value !== 1) {
  //     this.setState({ ...this.state, [e.target.name]: e.target.value, from: dateFormatter(this.props.waterfallMaxDate) })
  //     console.log(this.props.waterfallMaxDate,'maxdate')
  //   } else {
  //     this.setState({ ...this.statem, [e.target.name]: e.target.value })
  //   }
  // }

  handleChange = (e) => {
    if (this.state.trendInterval === "Months") {
      var date = new Date();
      var [year, month, dt] = getTimingArr(
        this.props.waterfallMaxDate,
        false,
        "T"
      );
      var dateSplit = { month: month, year: date.getFullYear(), date: 1 };
      var from = dateSplit.month + "-" + dateSplit.date + "-" + dateSplit.year;
      this.setState({ ...this.state, [e.target.name]: e.target.value, from });
    } else if (e.target.value === "Weeks" || e.target.value === "Days") {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        from: this.state.maxDateForFrom,
      });
    } else if (this.state.number) {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  };

  trendIntervalChange = (e) => {
    if (e.target.value === "months" || e.target.value === "quarters") {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  };

  handleChangeFilter = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "Rep" || e.target.name === "Projected_Close_Rolling"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleChangeRepTeam = (e) => {
    this.setState({
      ...this.state,
      repTeam: e.target.value,
      disabled:
        e.target.value === "Rep"
          ? [...this.state.disabled.filter((f) => f !== "Rep"), "team"]
          : [...this.state.disabled.filter((f) => f !== "team"), "Rep"],
    });
  };

  plot = () => {
    const fromSplit = this.state.from.split("-");
    const toSplit = this.state.to.split("-");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          string: true,
          value:
            f === "Rep"
              ? this.state[f].includes("All")
                ? this.state.Rep
                : this.state.Rep.map(
                    (s) =>
                      this.props.waterfallFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.waterfallFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : // f === "Cust_Type" ? this.state[f].includes('All') ? this.state[f] : this.state[f].map(s => this.props.waterfallFilters.Cust_Type.filter(r => r.Cust_Type === s)[0].Cust_Type) :
                this.state[f],
        };
      })
      .filter((f) => !f.value.includes("All"))
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_namesOnwards = [];
    let stage_namesBefore = [];
    this.props.waterfallFilters.stages.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence >= this.state.earliestOpenStage)
          stage_namesOnwards = [...stage_namesOnwards, i.stage_name];
      }
    });
    this.props.waterfallFilters.stages.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence < this.state.earliestOpenStage)
          stage_namesBefore = [...stage_namesBefore, i.stage_name];
      }
    });

    // console.log(month)
    var [year, month, dt] = getTimingArr(this.state.maxDateForFrom, false, "T");

    var timeFrameFilters = [];
    if (this.state.Projected_Close_Rolling) {
      timeFrameFilters = [
        ...timeFrameFilters,
        {
          name: "projectedCloseRolling",
          value:
            this.state.Projected_Close_Rolling[0] === "All"
              ? "All"
              : this.state.Projected_Close_Rolling[0],
        },
      ];
    }
    timeFrameFilters = [
      ...timeFrameFilters,
      { name: "timeFrame", value: this.state.number },
      { name: "durationType", value: this.state.trendInterval },
    ];

    filters = [
      ...filters,
      { name: "timeFrame", value: [...timeFrameFilters] },
      {
        name: "Earliest_Open_Stage",
        value: [stage_namesOnwards[0]],
        string: true,
      },
      // {
      //   name: 'StagesBeforeEarliest',
      //   value: stage_namesBefore,
      //   string: true
      // }
    ];

    // this.props.getPipelineData(filters,{isRolling : this.state.Projected_Close_Rolling.includes('All') ? false : true})
    this.props.getPipelineData(filters, { isRolling: true });
    this.setState({
      ...this.state,
      fromDisplay: customDateFormatterNew(this.state.from),
      toDisplay: customDateFormatterNew(this.state.to),
      activeFrom: this.state.from,
      // isRolling:this.state.Projected_Close_Rolling.includes('All') ? false : true,
      isRolling: true,
      // activeTo: this.state.to,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      activeFilters: filters,
      // activelast: this.state.number
    });
  };

  handleChangeLocation = (location, stage, Open_Datetime) => {
    console.log(stage);

    this.setState({
      ...this.state,
      location: location,
      selectedDrilldownStage: stage.x,
      selectedDrilldownStageLabel: stage.cell,
      selectedDrilldown: stage.y,
    });
    if (location !== "overview") {
      const fromSplit = this.state.from.split("-");
      const toSplit = this.state.to.split("-");
      let stage_namesOnwards = [];
      let stage_namesBefore = [];
      this.props.waterfallFilters.stages.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence >= this.state.earliestOpenStage)
            stage_namesOnwards = [...stage_namesOnwards, i.stage_name];
        }
      });
      this.props.waterfallFilters.stages.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence < this.state.earliestOpenStage)
            stage_namesBefore = [...stage_namesBefore, i.stage_name];
        }
      });

      // let filters = this.filters.map(f => {
      //   return {
      //     name: f,
      //     string: true,
      //     value: f === 'Rep' ? this.state[f].includes('All') ? this.state.Rep : this.state.Rep.map(s => this.props.waterfallFilters.reps.filter(r => r.Full_Name === s)[0].UserID) :
      //       f === 'team' ? this.state[f].includes('All') ? this.state[f] : this.state[f].map(s => this.props.waterfallFilters.teams.filter(r => r.Display_Name === s)[0].Display_Name) :
      //       // f === "Cust_Type" ? this.state[f].includes('All') ? this.state[f] : this.state[f].map(s => this.props.waterfallFilters.Cust_Type.filter(r => r.Cust_Type === s)[0].Cust_Type) :
      //        this.state[f] ,

      //   }
      // }).filter(f => !f.value.includes('All')).filter(f => !this.state.disabled.includes(f.name))
      // filters = [...filters,  {
      //   name: 'Earliest_Open_Stage',
      //   value: [stage_namesOnwards[0]],
      //   string: true
      // }, {name:"Open_Datetime",value:stage.cell,string:true},{name:stage.x, value: stage.row ,string:true } ]
      let filters = [];
      filters = [
        ...filters,
        ...this.props.selectedFilters,
        { name: "Open_Datetime", value: stage.cell, string: true },
        { name: stage.x, value: stage.row, string: true },
      ];
      // console.log(this.props.selectedFilters)
      // console.log(stage.x, 'stage')

      this.props.getWaterfallDrilldownOnGraph(
        { filters: filters },
        { isRolling: this.state.isRolling }
      );
    } else {
      this.props.clearDrilldownData();
    }
  };
  enableMonthStart(date) {
    return date.getDate() !== 1;
  }

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    if (!this.state.Projected_Close_Rolling.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Projected Close Rolling",
          value: this.state.Projected_Close_Rolling + " " + "months",
        },
      ];
    }
    if (
      !this.state.Projected_Close_Date_At_Time_Fiscal_Quarter.includes("All")
    ) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Projected Close Fiscal Quarter",
          value: this.state.Projected_Close_Date_At_Time_Fiscal_Quarter,
        },
      ];
    }
    let stage_namesOnwards = [];
    let stage_namesBefore = [];
    this.props.waterfallFilters.stages.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence >= this.state.earliestOpenStage)
          stage_namesOnwards = [...stage_namesOnwards, i.stage_name];
      }
    });
    this.props.waterfallFilters.stages.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence < this.state.earliestOpenStage)
          stage_namesBefore = [...stage_namesBefore, i.stage_name];
      }
    });
    filtersArray = [
      ...filtersArray,
      { title: "Earliest Open Stage", value: stage_namesOnwards[0] },
      {
        title: "Time Frame",
        value: this.state.number + " " + this.state.trendInterval,
      },
    ];
    if (!this.state.Rep.includes("All")) {
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.Rep }];
    }
    if (!this.state.team.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];
    }
    if (!this.state.Cust_Type.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Customer Type", value: this.state.Cust_Type },
      ];
    }
    if (!this.state.Booking_Type_NL_CS_US.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];
    }
    if (!this.state.Region_Rollup.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Region", value: this.state.Region_Rollup },
      ];
    }
    if (!this.state.Acct_Segment.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Account Segment", value: this.state.Acct_Segment },
      ];
    }
    return filtersArray;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      Rep: [value ? value : "All"],
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: "",
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      location: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      location: "drilldownOnGraph",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    //  this.props.current_fiscal_quarter !== "" && console.log(this.props.currentFiscalQuarter.current_fiscal_quarter)
    const { classes } = this.props;
    console.log("filters-debug", this.props.waterfallFilters);
    const filtersArr = [
      <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel
            className={classes.filterLabel}
            style={{
              color: !this.state[
                `${
                  this.props.company === "C0003"
                    ? "Acct_Segment"
                    : "Acct_Size_Segment"
                }`
              ].includes("All")
                ? "#4472c4"
                : "#000",
            }}
            id="select-industry-label"
          >
            Segment:
          </InputLabel>
          <Select
            value={
              this.state[
                `${
                  this.props.company === "C0003"
                    ? "Acct_Segment"
                    : "Acct_Size_Segment"
                }`
              ]
            }
            onChange={this.handleChangeFilter}
            name={`${
              this.props.company === "C0003"
                ? "Acct_Segment"
                : "Acct_Size_Segment"
            }`}
            data-test={`${
              this.props.company === "C0003"
                ? "Acct_Segment"
                : "Acct_Size_Segment"
            }`}
            multiple
          >
            <MenuItem value={"All"}>All</MenuItem>
            {this.props.waterfallFilters !== "" &&
              this.props.waterfallFilters.segmentTypeFilter
                .map(
                  (t) =>
                    t[
                      `${
                        this.props.company === "C0003"
                          ? "Acct_Segment"
                          : "Acct_Size_Segment"
                      }`
                    ]
                )
                .sort()
                .map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>,
    ];
    if (this.props.company === "C0003") {
      filtersArr.push(
        <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel
              className={classes.filterLabel}
              style={{
                color: !this.state.Booking_Type_NL_CS_US.includes("All")
                  ? "#4472c4"
                  : "#000",
              }}
              id="select-industry-label"
            >
              Opportunity Type:
            </InputLabel>
            <Select
              value={this.state.Booking_Type_NL_CS_US}
              onChange={this.handleChangeFilter}
              name="Booking_Type_NL_CS_US"
              data-test="Booking_Type_NL_CS_US"
              multiple
            >
              <MenuItem value={"All"}>All</MenuItem>
              {this.props.waterfallFilters !== "" &&
                this.props.waterfallFilters.oppTypeFilter
                  .map((t) => t.Booking_Type_NL_CS_US)
                  .sort()
                  .map((y) => (
                    <MenuItem key={y} value={y}>
                      {y}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
      );
      filtersArr.push(
        <RegionFilter
          Region_RollupValue={this.state.Region_Rollup}
          handleChange={this.handleChangeFilter}
          funnelCompareFilters={this.props.waterfallFilters.regionTypeFilter}
        />
      );
    }
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid item style={{ marginBottom: 5 }} xs={12}>
          <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Pipeline Trends (Rolling)</Typography>
          <Typography variant='body1'>
          Analyze the open pipeline by stage at various points in time to instantly spot changes and visualize trends.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Pipeline Trends (Rolling)",
          "Analyze the open pipeline by stage at various points in time to instantly spot changes and visualize trends."
        )}

        {this.props.waterfallFilters !== "" ? (
          <>
            <Grid
              container
              xs={12}
              alignItems="flex-start"
              style={{ marginBottom: 5 }}
            >
              <Grid
                xs={2}
                spacing={5}
                item
                container
                component={Paper}
                className={classes.repTeamFilterContainer}
              >
                <Grid item xs={12} style={{ padding: 0, paddingRight: 10 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      !this.state.Projected_Close_Date_At_Time_Fiscal_Quarter.includes(
                        "All"
                      )
                    }
                  >
                    <InputLabel
                      id="select-last-label"
                      style={{
                        color: !this.state.Projected_Close_Rolling.includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Proj. Close Rolling
                    </InputLabel>
                    <Select
                      labelId="select-last-label"
                      data-test="select-Projected_Close_Rolling"
                      id="select-Projected_Close_Rolling"
                      value={this.state.Projected_Close_Rolling}
                      onChange={this.handleChangeFilter}
                      // label="Last:"
                      name="Projected_Close_Rolling"
                      // disabled={this.state.timeFrame === 'custom'}
                    >
                      {[
                        { text: "All", value: "All" },
                        ,
                        { text: "3 months", value: 3 },
                        { text: "6 months", value: 6 },
                        { text: "9 months", value: 9 },
                        { text: "12 months", value: 12 },
                      ].map((y) => (
                        <MenuItem key={y.text} value={y.value}>
                          {y.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={6} style={{ padding: 0, paddingRight: 10 }} >
                  <FormControl fullWidth className={classes.formControl} disabled={!this.state.Projected_Close_Rolling.includes('All')} >
                    <InputLabel className={classes.filterLabel} style={{ color: !this.state.Projected_Close_Date_At_Time_Fiscal_Quarter.includes('All') ? '#4472c4' : '#000' }} id="select-fiscalQuarter-label">Proj. Close Fiscal Quarter:</InputLabel>
                    <Select
                      value={this.state.Projected_Close_Date_At_Time_Fiscal_Quarter}
                      onChange={this.handleChangeFilter}
                      name="Projected_Close_Date_At_Time_Fiscal_Quarter"
                      data-test="select-Projected_Close_Date_At_Time_Fiscal_Quarter"
                      multiple
                    >
                      <MenuItem value={'All'} >All</MenuItem>
                      {this.props.waterfallFilters === '' && <MenuItem value={nearestCompletedFiscalQuarter()} >{nearestCompletedFiscalQuarter()}</MenuItem>}
                      {this.props.waterfallFilters !== '' && this.props.waterfallFilters.pcfq.map(y => <MenuItem key={y['Projected_Close_Fiscal_Quarter']} value={y['Projected_Close_Fiscal_Quarter']}>{y['Projected_Close_Fiscal_Quarter']}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
              <Grid
                xs={2}
                item
                container
                className={classes.datesFilterContainer}
                component={Paper}
              >
                <Grid item xs={12} style={{ padding: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-stage-label"
                      style={{ color: "#4472c4", width: 144 }}
                    >
                      Earliest Open Stage:
                    </InputLabel>
                    <Select
                      labelId="select-stage-label"
                      id="select-stage"
                      value={this.state.earliestOpenStage}
                      onChange={this.handleChangeEarliestOpenStage}
                      label="Earliest Open Stage:"
                      name="earliestOpenStage"
                      data-test="select-earliestOpenStage"
                    >
                      {this.props.waterfallFilters !== "" &&
                        this.props.waterfallFilters.stages.map(
                          (y) =>
                            y.sequence && (
                              <MenuItem key={y.sequence} value={y.sequence}>
                                {y.stage_name}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                xs={3}
                item
                container
                className={classes.datesFilterContainer}
                component={Paper}
              >
                <Grid item xs={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-last-label"
                      style={{ color: "#4472c4", width: 200 }}
                    >
                      Time Frame Last:
                    </InputLabel>
                    <Select
                      labelId="select-last-label"
                      id="select-last"
                      value={this.state.number}
                      onChange={this.handleChange}
                      // label="Last:"
                      name="number"
                      data-test="select-number"
                      // disabled={this.state.timeFrame === 'custom'}
                    >
                      {Array(16)
                        .fill(1)
                        .map((y, i) => (
                          <MenuItem key={i + 1} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8} style={{ paddingLeft: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-last-label"
                      style={{ color: "#4472c4" }}
                    ></InputLabel>
                    <Select
                      labelId="select-last-label"
                      data-test="select-trendInterval"
                      id="select-weeks"
                      value={this.state.trendInterval}
                      onChange={this.trendIntervalChange}
                      // label="Last:"
                      name="trendInterval"
                      // disabled={this.state.timeFrame === 'custom'}
                    >
                      {[
                        { text: "Months", value: "months" },
                        { text: "Quarters", value: "quarters" },
                      ].map((y) => (
                        <MenuItem key={y.text} value={y.value}>
                          {y.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={3}
                className={classes.repTeamFilterContainer}
                component={Paper}
              >
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    data-test="select-rep"
                    options={
                      this.props.waterfallFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.Rep[0]}
                    onChange={this.onRepChange}
                    name="Rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Rep:"
                        variant="standard"
                        InputLabelProps={{
                          style: {
                            color: !this.state.Rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.Rep.includes("All")}
                  >
                    <InputLabel
                      id="select-team-label"
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Team:
                    </InputLabel>
                    <Select
                      labelId="select-team-label"
                      id="select-team"
                      data-test="select-team"
                      value={this.state.team}
                      onChange={this.handleChangeFilter}
                      label="Team"
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.waterfallFilters !== "" &&
                        this.props.waterfallFilters.teams
                          .map((t) => t.Display_Name)
                          .sort()
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container className={classes.buttonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.plot}
                  disabled={
                    this.state.errorTextFrom !== "" ||
                    this.state.errorTextTo !== ""
                  }
                  data-test="go"
                >
                  GO
                </Button>
              </Grid>
              <Grid
                item
                container
                xs={2}
                className={classes.repTeamFilterContainer}
                component={Paper}
              >
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-team-label"
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Customer Type:
                    </InputLabel>
                    <Select
                      labelId="select-team-label"
                      id="select-cust-type"
                      data-test="select-cust-type"
                      value={this.state.Cust_Type}
                      onChange={this.handleChangeFilter}
                      label=""
                      name="Cust_Type"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.waterfallFilters !== "" &&
                        this.props.waterfallFilters.custType
                          .map((t) => t.Cust_Type)
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {/* {this.props.company === "C0010" && (
                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.Record_Type_Name__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-cust-type"
                        data-test="select-cust-type"
                        value={this.state.Record_Type_Name__c}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="Record_Type_Name__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.waterfallFilters !== "" &&
                          this.props.waterfallFilters.oppRecordTypeFilter
                            .map((t) => t.Record_Type_Name__c)
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )} */}

              <Grid container item style={{ padding: 13 }} xs={6}>
                <Grid item container xs={12}>
                  <FilterContainer pipeStyle={true} filters={filtersArr} />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid item container spacing={2} justify="space-around">
          <Grid item xs={12}>
            {this.props.PipelineTrendsRollingData !== "" ? (
              <>
                <Grid container>
                  {this.props.PipelineTrendsRollingData.map(
                    ({ type, data, label, total }, i) => {
                      return (
                        <Grid key={i} className={classes.grid} item xs={12}>
                          <DataVisualizer
                            chartName={`Pipeline Trends Rolling`}
                            title={`Pipeline Trends by ${label}`}
                            type={type}
                            chartType="acv"
                            winACVMixAnalysisData={data.filter(
                              (x) => x.type !== "total"
                            )}
                            handleChangeLocation={this.handleChangeLocation}
                            quarter={"Open_Datetime"}
                            // isDrilldown={false}
                            isDrilldown={
                              ["Apps_Cluster", "Product_Line"].includes(type)
                                ? false
                                : true
                            }
                            doughnutNoNeeded={true}
                            chart={"pipelinetrendsRolling"}
                            tableComponent={
                              <PipelineTrendsRollingTable
                                data_test={`table_${label}`}
                                data={data}
                                date={"Open_Datetime"}
                                type={type}
                                total={total}
                                handleChangeLocation={this.handleChangeLocation}
                                label={label}
                              />
                            }
                            unit="acv"
                          />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.grid}
                  style={{ marginTop: "2rem" }}
                >
                  {/* 
            <Card style={{ margin: "auto", width: "100%" }}>
              <Typography variant='h2' style={{ margin: '1rem', display: 'flex', justifyContent: 'center', padding: '1rem' }}>Open Pipeline Detail</Typography>
              {(this.props.openPipelineDetail) !== undefined ?
                (
                  <Grid className={classes.grid} item xs={12}>
                   <PipelineTrendsDetailTable data={this.props.openPipelineDetail} handleChangeLocation={this.handleChangeLocation} date={"Open_Datetime"} stag ={"Stage_At_Time_T"} owner={"Owner_Name"} company={this.props.company}/>
                  </Grid>
                ) :
                (<Skeleton animation="wave" variant="rect" width='95%' height={230} style={{ margin: '2rem' }} />)}
            </Card> */}
                </Grid>
              </>
            ) : (
              <Card
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                raised={true}
              >
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="60%"
                  style={{ margin: "20px 10px", alignSelf: "flex-start" }}
                />
                <Divider style={{ width: "100%" }} />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={350}
                  style={{ margin: "20px 0px" }}
                />
              </Card>
            )}
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" &&
          this.state.selectedDrilldownStageLabel !== "" && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={`${this.state.filtersForDrilldownStaringEnding} Pipeline`}
                back={this.handleChangeLocationBack}
                filters={[
                  ...this.state.filtersForDrilldown,
                  {
                    title: "Date",
                    value: customDateFormatter(
                      this.state.selectedDrilldownStageLabel
                    ),
                  },
                ]}
              >
                <Drilldown
                  body={this.props.drilldownOnGraphData}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                  Value_Label={this.props.Value_Label}
                />
              </SingleLevelDrilldown>
            </Grid>
          )}
        {this.state.location === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const {
    waterfallFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate,
    waterfallError,
    alert,
    drilldownOnGraphData,
    updatingWaterfallData,
    selectedFilters,
    user,
    PipelineTrendsRollingData,
    ExpectedAcvData,
  } = state.app;
  const { currentFiscalQuarter } = waterfallFilters;

  return {
    waterfallFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate,
    waterfallError,
    alert,
    drilldownOnGraphData,
    updatingWaterfallData,
    PipelineTrendsRollingData,
    selectedFilters,
    currentFiscalQuarter,
    company: user.company,
    Value_Label: user.Value_Label,
    ExpectedAcvData,
    trend:
      waterfallData !== ""
        ? Math.round(
            waterfallData.filter((r) => r.name === "Ending Pipeline")[0].value -
              waterfallData.filter((r) => r.name === "Starting Pipeline")[0]
                .value
          )
        : 0,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getPipelineData: (filters, meta) => {
    dispatch({ type: "get_pipelineTrends_Rolling_data_request" });
    dispatch({ type: "selected_filters", selectedFilters: filters });
    // console.log(filters)
    appService.getPipelineTrendsRollingData(filters, meta).then(
      (json) => {
        dispatch({ type: "get_pipelineTrends_Rolling_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pipelineTrends_Rolling_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_pipelineTrends_Rolling_data_failure", error });
      }
    );
  },
  getWaterfallMinDate: () => {
    dispatch({ type: "get_waterfall_min_date_request" });
    dispatch({ type: "get_pipelineTrends_Rolling_data_request" });
    appService.getPipelineTrendsRollingFilters().then(
      (json) => {
        dispatch({ type: "get_waterfall_min_date_success", json });
        dispatch({ type: "get_waterfall_data_request" });
        let filters = [];
        let stage_namesOnwards = [];
        let stage_namesBefore = [];
        json.message.filters.stages.map((i) => {
          if (i.sequence !== undefined) {
            if (i.sequence >= 3)
              stage_namesOnwards = [...stage_namesOnwards, i.stage_name];
          }
        });
        json.message.filters.stages.map((i) => {
          if (i.sequence !== undefined) {
            if (i.sequence < 3)
              stage_namesBefore = [...stage_namesBefore, i.stage_name];
          }
        });
        var [year, month, dt] = getTimingArr(
          json.message.minMax.maxDate,
          false,
          "T"
        );
        const dat = dateFormatterMoment({ year, month, date: dt });
        filters = [
          ...filters,
          {
            name: "timeFrame",
            value: [
              { name: "projectedCloseRolling", value: 6 },
              { name: "timeFrame", value: 4 },
              { name: "durationType", value: "quarters" },
            ],
          },
          //{ name: "Projected_Close_Date_At_Time_Fiscal_Quarter", value: [json.message.filters.currentFiscalQuarter.current_fiscal_quarter], string: true },
          {
            name: "Earliest_Open_Stage",
            value: [stage_namesOnwards[0]],
            string: true,
          },
        ];

        dispatch({ type: "selected_filters", selectedFilters: filters });

        appService
          .getPipelineTrendsRollingData(
            json.message.length === 0 ? [] : filters,
            { isRolling: true }
          )
          .then(
            (json) => {
              dispatch({
                type: "get_pipelineTrends_Rolling_data_success",
                json,
              });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_pipelineTrends_Rolling_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_pipelineTrends_Rolling_data_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_waterfall_min_date_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_waterfall_min_date_failure", error });
      }
    );
  },
  getWaterfallDrilldownOnGraph: (filters, isRolling) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService
      .getPipelineTrendsRollingDrilldownOnGraph(filters, isRolling)
      .then(
        (json) => {
          // console.log(json.message)
          let tempOpportunities = json.message.opportunities[1].map(
            (item, index) => {
              let x = {
                ...item,
              };
              return x;
            }
          );

          tempOpportunities.sort((a, b) =>
            a.Acv_At_Time_T > b.Acv_At_Time_T ? -1 : 1
          );

          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json = {
            ...json,
            message: {
              ...json.message,
              opportunities: tempOpportunities,
              count_acv: json.message.opportunities[0],
              columns: json.message.columns,
            },
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});

const connectedPipelineTrendsRolling = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PipelineTrendsRolling));
export { connectedPipelineTrendsRolling as PipelineTrendsRolling };
