import * as d3 from "d3";
import "./D3RepPerformanceBar.scss";
import {
  customTickFormat,
  stageFormattter,
} from "../../../util/customFunctions";
import { store } from "../../../util/store";
const D3RepPerformanceBar = {};
const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};
D3RepPerformanceBar.create = (el, config) => {
  const Symbol = store.getState();
  const Currency_Symbol = Symbol.app.user.Currency;

  d3.selection.prototype.last = function () {
    return d3.select(this.nodes()[this.size() - 1]);
  };

  if (config.data) {
    config.data = config.data.filter((item) => item.Name !== "total");
    config.barColor = "#70ad47";
    config.barHeight = 10;
    var marginLeftCalc = config.data;

    config.margin = {
      top: 20,
      right: 20,
      bottom: 120,
      left:
        marginLeftCalc.sort((a, b) => b.Name.length - a.Name.length)[0].Name
          .length * 7.3,
    };
    if (!config.width) {
      config.width = d3.select(el).node()
        ? d3.select(el).node().getBoundingClientRect().width -
          config.margin.left -
          config.margin.right
        : 50;
    }
    config.height = config.data.length * 30;
    const wonLabel = config.label.filter((item) => item.type === "won")[0]
      .label;

    config.data = config.data
      .map((item) => ({
        closedWon: _isNaN(parseFloat(item[`${wonLabel}_ACV`]))
          ? 0
          : parseFloat(item[`${wonLabel}_ACV`]),
        rep: item.Name,
      }))
      .filter((item) => item.rep !== "total");
    var data = config.data.sort((a, b) => b.closedWon - a.closedWon);

    const y = d3
      .scaleBand()
      .rangeRound([0, config.height])
      .padding(0.534482775);

    const x = d3.scaleLinear().range([config.width, 0]);

    const xAxis = d3
      .axisBottom(x)
      .tickFormat((d) =>
        d !== 0
          ? `${Currency_Symbol}${d3.format(".2s")(d)}`.toUpperCase()
          : null
      )
      .ticks(8);

    const yAxis = d3.axisLeft(y);
    y.domain(
      data.map((d) => {
        return d.rep;
      })
    );

    x.domain([
      d3.max(data, (d) => d.closedWon) +
        d3.max(data, (d) => d.closedWon) * 0.1 ===
      0
        ? 100 * 1000
        : d3.max(data, (d) => d.closedWon) +
          d3.max(data, (d) => d.closedWon) * 0.1,
      0,
    ]);

    function make_x_gridlines() {
      return d3.axisBottom(x).ticks(8);
    }

    // gridlines in y axis function
    function make_y_gridlines() {
      return d3.axisLeft(y).ticks(5);
    }

    var xAxisHeight = config.height;
    const chart = d3
      .select(el)
      .append("svg")
      .attr("width", config.width + config.margin.left + config.margin.right)
      .attr("height", config.height + config.margin.top + config.margin.bottom)
      .append("g")
      .attr(
        "transform",
        `translate(${config.margin.left},${config.margin.top})`
      );

    chart
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0," + config.barHeight + ")")
      .attr("z-index", -1)
      .call(make_x_gridlines().tickSize(xAxisHeight).tickFormat(""));

    // add the Y gridlines
    // chart
    //     .append("g")
    //     .attr("class", "grid")
    //     .attr("z-index", -1)
    //     .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));

    chart
      .append("g")
      .attr("class", "xaxis")
      .attr("transform", "translate(0," + xAxisHeight + ")")
      .call(xAxis);

    chart.append("g").attr("class", "yaxis").call(yAxis);
    var bars = chart.selectAll(".bar").data(data).enter().append("g");

    //append rects
    bars
      .append("rect")
      .attr("class", "bar")
      .attr("stroke", "#00000")
      .attr("fill", config.barColor)
      .attr("y", function (d) {
        return y(d.rep) - 2;
      })
      .attr("height", y.bandwidth() / 2.25 + 12)
      .attr("x", 0)
      // .transition()
      .attr("width", function (d) {
        return x(d.closedWon);
      });

    //add a value label to the right of each bar
    bars
      .append("text")
      .attr("class", "label")
      //y position of the label is halfway down the bar
      .attr("y", function (d) {
        return y(d.rep) + y.bandwidth() / 2.25 + 4;
      })
      //x position is 3 pixels to the right of the bar
      .attr("x", function (d) {
        return x(d.closedWon) + 3;
      })
      .text(
        (d) =>
          `${Currency_Symbol}${
            _isNaN(d.closedWon) ? 0 : d3.format(",")(Math.round(d.closedWon))
          }`
      )
      .style("font-weight", 500);
    chart
      .append("text")
      .attr("y", xAxisHeight + 30)
      .attr("x", config.width / 2 - 80)
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("fill", "#000000")
      .text(`${wonLabel.split("_").join(" ")} ${config.Value_Label}`);
  }
};
D3RepPerformanceBar.destroy = (el) => {
  d3.select(el).selectAll("svg").remove();
};
export default D3RepPerformanceBar;
