import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { format as commaFormatter } from 'd3'
import { countOrACVFormatter, getUniqueValues, StyledTableRow } from '../../../../util/customFunctions'
import tableStyle from '../../../styles/tableStyle'
import NoDataCell from './NoDataCell'
import { coloredCells } from '../utils'
import { renewalsRow } from '../../RenewalsCompleted/components/utils'
export default function SummaryTrendsTable(props) {
  const classes = tableStyle()

  let rows = props.rows !== undefined ? props.rows : props.data.map(item => item[props.row]).filter(getUniqueValues)
  let columns = props.columns !== undefined ? props.columns : props.data.map(item => item[props.col]).filter(getUniqueValues)
  columns = props.weekOverWeekTrends ? ["Churned", 'Red', 'Yellow', 'Orange', 'Green', 'Green +', null] : columns
  rows = props.chartName === "accountHealthCurrentStatus" || props.chartName === "accountHealthChangeOverTime" ? ["Red", "Yellow", "Orange", "Green", "Green +", "Churned"] : rows
  if (props.chartName === "accountHealthCurrentStatus") {
    const missingCols = columns.filter(function (obj) { return renewalsRow.indexOf(obj) == -1; });
    columns = [...renewalsRow, ...missingCols]
  }

  if (props.chartName === "QBRSummary") {
    const missingRows = rows.filter(function (obj) { return renewalsRow.indexOf(obj) == -1; });
    rows = [...renewalsRow, ...missingRows]
  }
  const drilldownHandler = (col, row, meta) => {
    if (props.chartName === "QBRSummary" || props.chartName === "accountHealthChangeOverTime") {
      props.handleChangeLocation("drilldownOnGraph", { col, row }, meta)
    }
  }
  return (
    <TableContainer className={classes.root}>
      <Table size="small">
        <TableHead></TableHead>
        <TableBody>
          {
            props.data.length > 0 ? <>
              {
                props.statusByPriorWeek ? <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                  <TableCell style={{ background: 'white' }}></TableCell>


                  <TableCell align="center" colSpan={columns.length} style={{ backgroundColor: '#bf8f00' }} className={classes.colHeader}>Health prior week</TableCell>
                </TableRow> : null
              }
              <TableRow style={{ fontWeight: 600, background: '#4472c4', color: 'white', paddingLeft: 5 }}>
                <TableCell align="center" className={classes.colHeader} style={{ background: 'white', color: 'black' }}>{props.rowHeading}</TableCell>

                {columns.map((item, index) => <TableCell key={index} align="center" style={props.chartName === "QBRSummary" ? index % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' } : coloredCells[item] !== undefined ? { backgroundColor: coloredCells[item], color: 'black' } : {}} className={classes.colHeader}>{item}</TableCell>)}
                <TableCell align="center" style={columns.length > 1 && columns.length % 2 === 0 ? { background: '#4471c4', color: 'white' } : { background: '#5b9bd5', color: 'white' }} className={classes.colHeader}>Total</TableCell>
              </TableRow>
              {
                rows.map(row =>
                  <StyledTableRow key={row}>
                    <TableCell align="left" className={classes.dataCell} style={{ backgroundColor: coloredCells[row], color: 'black' }}>{row}</TableCell>
                    {columns.map(col => {
                      const cellData = props.data.filter(item => item[props.col] === col && item[props.row] === row)[0] !== undefined ? props.data.filter(item => item[props.col] === col && item[props.row] === row)[0][props.type] : "-"
                      return <TableCell key={col} onClick={() => drilldownHandler(col, row)} align="right" className={classes.drilldownCell}>{cellData === "-" ? cellData : countOrACVFormatter(cellData, props.type === "CARR")}</TableCell>
                    })}
                    {
                      <TableCell align="right" onClick={() => drilldownHandler(columns, row, 'totalCol')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.row] === row).map(item => item[props.type]).reduce((a, b) => a + b, 0), props.type === "CARR")}</TableCell>
                    }
                  </StyledTableRow>)
              }
              <StyledTableRow style={{ fontWeight: 600 }} key={'total'}>
                <TableCell align="left" className={classes.dataCell}>Total</TableCell>
                {
                  columns.map(col => <TableCell key={col} onClick={() => drilldownHandler(col, rows, 'totalRow')} align="right" className={classes.drilldownCell}>{countOrACVFormatter(props.data.filter(item => item[props.col] === col).map(item => item[props.type]).reduce((a, b) => a + b, 0), props.type === "CARR")}</TableCell>)
                }
                <TableCell align="right" onClick={() => drilldownHandler(columns, rows, 'totalAll')} className={classes.drilldownCell}>{countOrACVFormatter(props.data.map(item => item[props.type]).reduce((a, b) => a + b, 0), props.type === "CARR")}</TableCell>
              </StyledTableRow>
            </> : <NoDataCell />
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
