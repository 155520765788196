import React from 'react'
import {
  Card, CardHeader, CardContent, Divider, Button, Typography, Grid, Radio, RadioGroup, FormControlLabel, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 375,
    minWidth: 500
  },
  chart: {
    height: '100'
  },
  table: {
    fontWeight: 600, border: '0.5px solid lightgrey'
  },
  rep: {
    fontWeight: 600, border: '0.5px solid lightgrey', width: '20%'
  }
})

const data = [
  {
    "Discover to Qualify": "-4",
    "Qualify to Demo": "-10",
    "Demo to POC": "24",
    "POC to Proposal": "-12",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "-5",
    "Rep": "Amabelle Halloran",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-5",
    "Demo to POC__1": "9",
    "POC to Proposal__1": "-29",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "-3"
  },
  {
    "Discover to Qualify": "9",
    "Qualify to Demo": "-2",
    "Demo to POC": "13",
    "POC to Proposal": "2",
    "Proposal to Negotiate": "0",
    "Negotiate to Closed Won": "1",
    "Rep": "Barrie Thompkins",
    "Discover to Qualify__1": "-4",
    "Qualify to Demo__1": "-7",
    "Demo to POC__1": "-1",
    "POC to Proposal__1": "-24",
    "Proposal to Negotiate__1": "1",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "-27",
    "Qualify to Demo": "19",
    "Demo to POC": "15",
    "POC to Proposal": "-12",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "1",
    "Rep": "Brita Pieracci",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "6",
    "Demo to POC__1": "9",
    "POC to Proposal__1": "-21",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "1",
    "Qualify to Demo": "-13",
    "Demo to POC": "15",
    "POC to Proposal": "10",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "1",
    "Rep": "Dorella Pavy",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-32",
    "Demo to POC__1": "-6",
    "POC to Proposal__1": "1",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "-16",
    "Qualify to Demo": "4",
    "Demo to POC": "-3",
    "POC to Proposal": "15",
    "Proposal to Negotiate": "-3",
    "Negotiate to Closed Won": "1",
    "Rep": "Dur Vesco",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-7",
    "Demo to POC__1": "6",
    "POC to Proposal__1": "11",
    "Proposal to Negotiate__1": "-5",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "-3",
    "Qualify to Demo": "3",
    "Demo to POC": "19",
    "POC to Proposal": "-5",
    "Proposal to Negotiate": "0",
    "Negotiate to Closed Won": "1",
    "Rep": "Eddi Lewisham",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-2",
    "Demo to POC__1": "-1",
    "POC to Proposal__1": "-14",
    "Proposal to Negotiate__1": "-1",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "-14",
    "Qualify to Demo": "-7",
    "Demo to POC": "13",
    "POC to Proposal": "-35",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "1",
    "Rep": "Egan Bullivant",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-10",
    "Demo to POC__1": "9",
    "POC to Proposal__1": "-72",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "-8",
    "Qualify to Demo": "-12",
    "Demo to POC": "-2",
    "POC to Proposal": "-29",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "1",
    "Rep": "Elisha Larkins",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "0",
    "Demo to POC__1": "-5",
    "POC to Proposal__1": "-47",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "-16",
    "Qualify to Demo": "3",
    "Demo to POC": "13",
    "POC to Proposal": "3",
    "Proposal to Negotiate": "-17",
    "Negotiate to Closed Won": "1",
    "Rep": "Fran Feldman",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "17",
    "Demo to POC__1": "14",
    "POC to Proposal__1": "-35",
    "Proposal to Negotiate__1": "-29",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "1",
    "Qualify to Demo": "-3",
    "Demo to POC": "18",
    "POC to Proposal": "-6",
    "Proposal to Negotiate": "-4",
    "Negotiate to Closed Won": "1",
    "Rep": "Gertrud Spellward",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-21",
    "Demo to POC__1": "6",
    "POC to Proposal__1": "-10",
    "Proposal to Negotiate__1": "-18",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "7",
    "Qualify to Demo": "12",
    "Demo to POC": "-3",
    "POC to Proposal": "-7",
    "Proposal to Negotiate": "-4",
    "Negotiate to Closed Won": "1",
    "Rep": "Horton Kindon",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "8",
    "Demo to POC__1": "-11",
    "POC to Proposal__1": "-13",
    "Proposal to Negotiate__1": "1",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "8",
    "Qualify to Demo": "-18",
    "Demo to POC": "-11",
    "POC to Proposal": "-10",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "1",
    "Rep": "Ileane Gersam",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "1",
    "Demo to POC__1": "-9",
    "POC to Proposal__1": "9",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "0",
    "Qualify to Demo": "-21",
    "Demo to POC": "36",
    "POC to Proposal": "-35",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "1",
    "Rep": "Jayme Villiers",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-7",
    "Demo to POC__1": "45",
    "POC to Proposal__1": "-89",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "4",
    "Qualify to Demo": "-2",
    "Demo to POC": "-4",
    "POC to Proposal": "8",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "-3",
    "Rep": "Jolie Lownds",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-1",
    "Demo to POC__1": "14",
    "POC to Proposal__1": "8",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "-8",
    "Qualify to Demo": "-1",
    "Demo to POC": "18",
    "POC to Proposal": "-2",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "1",
    "Rep": "Kathie Cottee",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-17",
    "Demo to POC__1": "9",
    "POC to Proposal__1": "-45",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "-1",
    "Qualify to Demo": "-5",
    "Demo to POC": "4",
    "POC to Proposal": "-12",
    "Proposal to Negotiate": "-15",
    "Negotiate to Closed Won": "1",
    "Rep": "Lief Banghe",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-17",
    "Demo to POC__1": "-2",
    "POC to Proposal__1": "-67",
    "Proposal to Negotiate__1": "-86",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "11",
    "Qualify to Demo": "-43",
    "Demo to POC": "-14",
    "POC to Proposal": "15",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "1",
    "Rep": "Merline Brandsma",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-39",
    "Demo to POC__1": "-31",
    "POC to Proposal__1": "11",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "-3",
    "Qualify to Demo": "11",
    "Demo to POC": "-2",
    "POC to Proposal": "-5",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "1",
    "Rep": "Morena Sooley",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "8",
    "Demo to POC__1": "-20",
    "POC to Proposal__1": "-11",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "8",
    "Qualify to Demo": "-17",
    "Demo to POC": "20",
    "POC to Proposal": "11",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "1",
    "Rep": "Reidar Alcock",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-23",
    "Demo to POC__1": "-12",
    "POC to Proposal__1": "0",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "7",
    "Qualify to Demo": "5",
    "Demo to POC": "15",
    "POC to Proposal": "-6",
    "Proposal to Negotiate": "3",
    "Negotiate to Closed Won": "1",
    "Rep": "Rickard Slowley",
    "Discover to Qualify__1": "-1",
    "Qualify to Demo__1": "-23",
    "Demo to POC__1": "-5",
    "POC to Proposal__1": "-68",
    "Proposal to Negotiate__1": "2",
    "Negotiate to Closed Won__1": "0"
  },
  {
    "Discover to Qualify": "-3",
    "Qualify to Demo": "16",
    "Demo to POC": "18",
    "POC to Proposal": "-28",
    "Proposal to Negotiate": "-12",
    "Negotiate to Closed Won": "-36",
    "Rep": "Rosemary Keneforde",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "8",
    "Demo to POC__1": "24",
    "POC to Proposal__1": "-48",
    "Proposal to Negotiate__1": "-19",
    "Negotiate to Closed Won__1": "-66"
  },
  {
    "Discover to Qualify": "5",
    "Qualify to Demo": "6",
    "Demo to POC": "12",
    "POC to Proposal": "-1",
    "Proposal to Negotiate": "-6",
    "Negotiate to Closed Won": "-4",
    "Rep": "Toma Willard",
    "Discover to Qualify__1": "0",
    "Qualify to Demo__1": "-22",
    "Demo to POC__1": "-13",
    "POC to Proposal__1": "-8",
    "Proposal to Negotiate__1": "-19",
    "Negotiate to Closed Won__1": "-36"
  }
]

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: 'rgb(218, 227, 243)',
    },
  },
}))(TableRow);

class PerformanceBySalesStage extends React.Component {


  render () {
    const { classes } = this.props
    return (
      <Grid container>
        <Grid item style={{ marginBottom: 30 }} xs={12}>
          <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Performance By Sales Stage</Typography>
          <Typography variant='body1'>
            Benchmark conversion rates by sales stage for each rep against the cohort of top reps to identify blind spots for proactive, targeted intervention.
          </Typography>
        </Grid>
        <Typography variant='h6'>
          Conversion rates by sales funnel stage vs. Top Reps – Q1 2020 & Q2 2020
          </Typography>
        <Grid item container spacing={2} justify='space-around'>
          <Grid item xs={12}>
            <TableContainer component={Paper} style={{ marginBottom: 20 }}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableRow>
                  <TableCell align='right' className={classes.table} style={{ width: '8%' }}>60%</TableCell>
                  <TableCell align='right' className={classes.table} style={{ width: '8%' }}>52%</TableCell>
                  <TableCell align='right' className={classes.table} style={{ width: '8%' }}>82%</TableCell>
                  <TableCell align='right' className={classes.table} style={{ width: '8%' }}>99%</TableCell>
                  <TableCell align='right' className={classes.table} style={{ width: '8%' }}>100%</TableCell>
                  <TableCell align='center' className={classes.rep}>Top Reps</TableCell>
                  <TableCell align='right' className={classes.table} style={{ width: '8%' }}>57%</TableCell>
                  <TableCell align='right' className={classes.table} style={{ width: '8%' }}>54%</TableCell>
                  <TableCell align='right' className={classes.table} style={{ width: '8%' }}>83%</TableCell>
                  <TableCell align='right' className={classes.table} style={{ width: '8%' }}>98%</TableCell>
                  <TableCell align='right' className={classes.table} style={{ width: '8%' }}>100%</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align='center' colSpan={5} style={{ fontWeight: 600, background: 'rgb(58, 99, 120)', color: 'white' }}>By Opportunity Count</TableCell>
                    <TableCell align='center' className={classes.rep} rowSpan={2} style={{ background: '#4472c4', color: 'white' }}>Rep</TableCell>
                    <TableCell align='center' colSpan={5} style={{ fontWeight: 600, background: 'rgb(84, 130, 53)', color: 'white' }}>By ACV</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center' className={classes.table} style={{ border: '0.5px solid lightgrey', background: '#4472c4', color: 'white', width: '8%' }}>Qualify to Demo</TableCell>
                    <TableCell align='center' className={classes.table} style={{ border: '0.5px solid lightgrey', background: '#4472c4', color: 'white', width: '8%' }}>Demo to POC</TableCell>
                    <TableCell align='center' className={classes.table} style={{ border: '0.5px solid lightgrey', background: '#4472c4', color: 'white', width: '8%' }}>POC to Proposal</TableCell>
                    <TableCell align='center' className={classes.table} style={{ border: '0.5px solid lightgrey', background: '#4472c4', color: 'white', width: '8%' }}>Proposal to Negotiate</TableCell>
                    <TableCell align='center' className={classes.table} style={{ border: '0.5px solid lightgrey', background: '#4472c4', color: 'white', width: '8%' }}>Negotiate to Won</TableCell>
                    <TableCell align='center' className={classes.table} style={{ border: '0.5px solid lightgrey', background: '#4472c4', color: 'white', width: '8%' }}>Qualify to Demo</TableCell>
                    <TableCell align='center' className={classes.table} style={{ border: '0.5px solid lightgrey', background: '#4472c4', color: 'white', width: '8%' }}>Demo to POC</TableCell>
                    <TableCell align='center' className={classes.table} style={{ border: '0.5px solid lightgrey', background: '#4472c4', color: 'white', width: '8%' }}>POC to Proposal</TableCell>
                    <TableCell align='center' className={classes.table} style={{ border: '0.5px solid lightgrey', background: '#4472c4', color: 'white', width: '8%' }}>Proposal to Negotiate</TableCell>
                    <TableCell align='center' className={classes.table} style={{ border: '0.5px solid lightgrey', background: '#4472c4', color: 'white', width: '8%' }}>Negotiate to Won</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    data.map((d, i) =>
                      <StyledTableRow key={i}>
                        <TableCell align='right' className={classes.table} style={Number(d['Qualify to Demo']) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', border: '0.5px solid lightgrey' } : Number(d['Qualify to Demo']) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', border: '0.5px solid lightgrey' } : { border: '0.5px solid lightgrey' }}>{d['Qualify to Demo']}%</TableCell>
                        <TableCell align='right' className={classes.table} style={Number(d['Demo to POC']) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', border: '0.5px solid lightgrey' } : Number(d['Demo to POC']) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', border: '0.5px solid lightgrey' } : { border: '0.5px solid lightgrey' }}>{d['Demo to POC']}%</TableCell>
                        <TableCell align='right' className={classes.table} style={Number(d['POC to Proposal']) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', border: '0.5px solid lightgrey' } : Number(d['POC to Proposal']) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', border: '0.5px solid lightgrey' } : { border: '0.5px solid lightgrey' }}>{d['POC to Proposal']}%</TableCell>
                        <TableCell align='right' className={classes.table} style={Number(d['Proposal to Negotiate']) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', border: '0.5px solid lightgrey' } : Number(d['Proposal to Negotiate']) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', border: '0.5px solid lightgrey' } : { border: '0.5px solid lightgrey' }}>{d['Proposal to Negotiate']}%</TableCell>
                        <TableCell align='right' className={classes.table} style={Number(d['Negotiate to Closed Won']) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', border: '0.5px solid lightgrey' } : Number(d['Negotiate to Closed Won']) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', border: '0.5px solid lightgrey' } : { border: '0.5px solid lightgrey' }}>{d['Negotiate to Closed Won']}%</TableCell>
                        <TableCell align='left' className={classes.rep} style={Number(d['Rep']) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', border: '0.5px solid lightgrey' } : Number(d['Rep']) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', border: '0.5px solid lightgrey' } : { border: '0.5px solid lightgrey' }}>{d['Rep']}</TableCell>
                        <TableCell align='right' className={classes.table} style={Number(d['Qualify to Demo__1']) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', border: '0.5px solid lightgrey' } : Number(d['Qualify to Demo__1']) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', border: '0.5px solid lightgrey' } : { border: '0.5px solid lightgrey' }}>{d['Qualify to Demo__1']}%</TableCell>
                        <TableCell align='right' className={classes.table} style={Number(d['Demo to POC__1']) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', border: '0.5px solid lightgrey' } : Number(d['Demo to POC__1']) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', border: '0.5px solid lightgrey' } : { border: '0.5px solid lightgrey' }}>{d['Demo to POC__1']}%</TableCell>
                        <TableCell align='right' className={classes.table} style={Number(d['POC to Proposal__1']) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', border: '0.5px solid lightgrey' } : Number(d['POC to Proposal__1']) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', border: '0.5px solid lightgrey' } : { border: '0.5px solid lightgrey' }}>{d['POC to Proposal__1']}%</TableCell>
                        <TableCell align='right' className={classes.table} style={Number(d['Proposal to Negotiate__1']) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', border: '0.5px solid lightgrey' } : Number(d['Proposal to Negotiate__1']) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', border: '0.5px solid lightgrey' } : { border: '0.5px solid lightgrey' }}>{d['Proposal to Negotiate__1']}%</TableCell>
                        <TableCell align='right' className={classes.table} style={Number(d['Negotiate to Closed Won__1']) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', border: '0.5px solid lightgrey' } : Number(d['Negotiate to Closed Won__1']) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', border: '0.5px solid lightgrey' } : { border: '0.5px solid lightgrey' }}>{d['Negotiate to Closed Won__1']}%</TableCell>
                      </StyledTableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}


function mapStateToProps (state) {
  const {
    funnelFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate
  } = state.app

  return {
    funnelFilters, waterfallData, persistentAlert, waterfallMinDate, waterfallMaxDate,
  }
}

const mapDispatchToProps = (dispatch) => ({

})

const connectedBar = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PerformanceBySalesStage))
export { connectedBar as PerformanceBySalesStage }