import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  percentageFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import { getMixKey } from "../../util/generateHeadings";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

export default function PipelineMetricsTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  let countinc = 0;

  const uniqueOppTypes = ["SAL", "SQL"];
  var uniqueQuarters = props.pipelineData
    .map((s) => s.quarter)
    .filter(getUniqueValues);

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainerpipeline");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);
  return (
    <div style={{ maxWidth: "98%", justifyContent: "center", padding: "2rem" }}>
      <Grid xs={12}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{
          width: "auto",
          borderLeft: isSticky ? "0.5px solid lightgrey" : "",
          // padding: "2rem",
        }}
        id="myTableContainerpipeline"
        ref={tableContainerRef}
      >
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.type}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow
              style={{ fontWeight: 600, paddingLeft: 5 }}
              className={classes.fontBold}
            >
              <TableCell
                align="center"
                // className={classes.repHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.repHeader}`
                    : classes.repHeader
                }
                rowSpan={2}
                style={{ minWidth: 150 }}
              >
                Fiscal Quarter
              </TableCell>
              {uniqueQuarters.map((i, index) => {
                return (
                  <TableCell
                    colSpan={3}
                    key={i}
                    align="center"
                    style={
                      index % 2 === 0
                        ? { background: "#4471c4", color: "white" }
                        : { background: "#5b9bd5", color: "white" }
                    }
                    className={classes.repHeader}
                  >
                    {i}
                  </TableCell>
                );
              })}

              {uniqueQuarters.length === 0 && (
                <TableCell
                  rowSpan={8}
                  className={classes.repHeader}
                  style={{ width: 200, textAlign: "center" }}
                >
                  No data available
                </TableCell>
              )}
            </TableRow>
            <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
              {uniqueQuarters.map((i) => {
                return (
                  <React.Fragment key={i}>
                    <TableCell
                      align="center"
                      style={{ minWidth: 60, maxWidth: 60 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      # of Opps
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 100, maxWidth: 100 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      {"ACV"}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: 60, maxWidth: 60 }}
                      className={classes.repHeader}
                      colSpan={1}
                    >
                      {"QoQ %"}
                    </TableCell>
                  </React.Fragment>
                );
              })}
            </TableRow>
            {uniqueOppTypes.map((oppType, i) => {
              return (
                <>
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      // className={classes.cellStyle}
                      className={
                        isSticky
                          ? `${classes.sticky} ${classes.cellStyle}`
                          : classes.cellStyle
                      }
                      style={
                        isSticky && i % 2 === 0 ? { background: "#f5f5f5" } : {}
                      }
                      colSpan={1}
                    >{`${oppType} and above`}</TableCell>
                    {uniqueQuarters.map((q) => {
                      const rowData = props.pipelineData.filter(
                        (i) => i.quarter === q
                      );
                      const count =
                        rowData.length === 0
                          ? 0
                          : rowData[0][
                              oppType === "SAL" ? "salCount" : "sqlCount"
                            ];
                      const acv =
                        rowData.length === 0
                          ? 0
                          : rowData[0][oppType === "SAL" ? "salACV" : "sqlACV"];
                      const perc =
                        rowData.length === 0
                          ? 0
                          : rowData[0][oppType === "SAL" ? "salQoQ" : "sqlQoQ"];
                      const openDateTime =
                        rowData.length === 0 ? 0 : rowData[0].Open_Datetime;
                      return (
                        <>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  quarter: [q],
                                  oppType,
                                  datetime: [openDateTime],
                                },
                                props.type
                              )
                            }
                            className={classes.cellNormal}
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(count)
                              ? countOrACVFormatter(count, false)
                              : ""}
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  quarter: [q],
                                  oppType,
                                  datetime: [openDateTime],
                                },
                                props.type
                              )
                            }
                            className={classes.cellNormal}
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(acv)
                              ? countOrACVFormatter(acv, true)
                              : ""}
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                {
                                  quarter: [q],
                                  oppType,
                                  datetime: [openDateTime],
                                },
                                props.type
                              )
                            }
                            className={classes.cellNormal}
                            colSpan={1}
                          >
                            {![null, undefined, ""].includes(perc)
                              ? percentageFormatter(perc)
                              : ""}
                          </TableCell>
                        </>
                      );
                    })}
                  </StyledTableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
