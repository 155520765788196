import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  capitalize,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
// import tableStyle from '../../CustomerSuccess/styles/tableStyle'
import {
  countOrACVFormatter,
  getUniqueValues,
  StyledTableRow,
} from "../../util/customFunctions";

import { makeStyles } from "@material-ui/core";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const tableStyle = makeStyles(
  (theme) => ({
    root: {
      margin: "0.5rem",
      padding: "0.5rem",
    },
    sticky: {
      position: "sticky",
      left: -1,
      // color: "inherit",
      background: "white",
      // backgroundColor: "inherit",
      // boxShadow: "5px 2px 5px grey",
      // borderRight: "2px solid black",
    },
    colHeaderNew: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      backgroundColor: "inherit",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    dataCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
    },
    drilldownCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      cursor: "pointer",
    },
    drilldownCellNQP: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: "3%",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    cellMargin: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      paddingLeft: "2rem",
    },
    fontBold: {
      fontWeight: 600,
    },
    indentName: {
      paddingLeft: 30,
    },
    noBorder: {
      border: "none",
    },
    primaryColumnColor: {
      backgroundColor: "#4472C4",
      color: "white",
    },
  }),
  { index: 1 }
);

function AsOfDateTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const pipelineNames = props.data.final[0].pipeline.map((item) => item.name);
  const teamNames = props.data.final
    .map((item) => item.team)
    .filter(getUniqueValues);
  const repNames = props.data.final
    .map((item) => item.name)
    .filter(getUniqueValues);
  const sortData = props.data.final.sort((a, b) =>
    b.team.localeCompare(a.team)
  );
  var writtenTeams = [];

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);
  return (
    <div style={{ maxWidth: "98%", justifyContent: "center", padding: "2rem" }}>
      <Grid xs={12}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{
          width: "auto",
          borderLeft: isSticky ? "0.5px solid lightgrey" : "",
        }}
        id="myTableContainer"
        ref={tableContainerRef}
      >
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Table ref={tableRef} data-test="as-of-date">
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#bf8f00",
                  color: "white",
                  fontWeight: 600,
                }}
                colSpan={2 * pipelineNames.length}
              >
                Open Pipeline with projected close in FY {props.data.currentFY}
              </TableCell>
              <TableCell className={classes.noBorder}></TableCell>
              <TableCell colSpan={3}></TableCell>
            </TableRow>
            <TableRow style={{ background: "#4472C4", color: "white" }}>
              <TableCell
                align="center"
                // className={classes.colHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : classes.colHeader
                }
                style={{ background: "#4472C4" }}
              >
                Team
              </TableCell>
              <TableCell align="center" className={classes.colHeader}>
                Manager
              </TableCell>
              <TableCell align="center" className={classes.colHeader}>
                Sales Rep
              </TableCell>
              {pipelineNames.map((stage) => (
                <TableCell align="center" className={classes.colHeader}>
                  # {capitalize(stage)}
                </TableCell>
              ))}
              {pipelineNames.map((stage) => (
                <TableCell align="center" className={classes.colHeader}>
                  $ {capitalize(stage)}
                </TableCell>
              ))}
              <TableCell
                className={classes.noBorder}
                style={{ background: "white", color: "white" }}
              ></TableCell>
              <TableCell align="center" className={classes.colHeader}>
                FY {props.data.currentFY} Wins YTD
              </TableCell>
              <TableCell align="center" className={classes.colHeader}>
                FY {props.data.currentFY} Quota
              </TableCell>
              <TableCell align="center" className={classes.colHeader}>
                % Attainment YTD
              </TableCell>
              <TableCell
                className={classes.noBorder}
                style={{ background: "white", color: "white" }}
              ></TableCell>
              <TableCell align="center" className={classes.colHeader}>
                FY {props.data.currentFY} Weighted Pipeline
              </TableCell>
              <TableCell align="center" className={classes.colHeader}>
                FY {props.data.currentFY} Projected Wins
              </TableCell>
              <TableCell align="center" className={classes.colHeader}>
                FY {props.data.currentFY} Projected % Attainment
              </TableCell>
            </TableRow>
            {sortData.map((item, index) => {
              let rowSpan = 0;
              if (!writtenTeams.includes(item.team)) {
                writtenTeams.push(item.team);
                rowSpan = sortData.filter((i) => i.team === item.team).length;
              }
              if (props.data.final.length === index + 1) {
                writtenTeams = [];
              }
              return (
                <TableRow>
                  {rowSpan !== 0 && (
                    <TableCell
                      align="left"
                      // className={classes.dataCell}
                      className={
                        isSticky
                          ? `${classes.sticky} ${classes.dataCell}`
                          : classes.dataCell
                      }
                      rowspan={rowSpan}
                    >
                      {item.team}
                    </TableCell>
                  )}
                  {rowSpan !== 0 && (
                    <TableCell
                      align="left"
                      className={classes.dataCell}
                      rowspan={rowSpan}
                    >
                      {item.manager}
                    </TableCell>
                  )}
                  <TableCell align="left" className={classes.dataCell}>
                    {item.name}
                  </TableCell>
                  {["count", "acv"].map((type) =>
                    pipelineNames.map((stage) => (
                      <TableCell align="right" className={classes.dataCell}>
                        {countOrACVFormatter(
                          item.pipeline.filter((it) => it.name === stage)[0][
                            `${type}InStage`
                          ],
                          type === "acv" ? true : false
                        )}
                      </TableCell>
                    ))
                  )}
                  <TableCell className={classes.noBorder}></TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {countOrACVFormatter(item.FYWins.value, true)}
                  </TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {countOrACVFormatter(item.repQuota, true)}
                  </TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {(item.repAttainment * 100).toFixed(2)}%
                  </TableCell>
                  <TableCell className={classes.noBorder}></TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {countOrACVFormatter(item.weightedPipeline, true)}
                  </TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {countOrACVFormatter(item.projectedFYWins, true)}
                  </TableCell>
                  <TableCell align="right" className={classes.dataCell}>
                    {(item.projectedAttainment * 100).toFixed(2)}%
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AsOfDateTable;
