import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";

const BackdropContainer = (props) => {
  return (
    <Backdrop
      style={{ color: "#fff", zIndex: 9999999 }}
      open={props.showLoading}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropContainer;
