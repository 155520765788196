import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import GetApp from "@material-ui/icons/GetApp";
import "./AccountHealthDrilldown.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import {
  getCSVFileNameString,
  csvDateFormatter,
  removeQuotes,
  _isNaN,
} from "../../../../util/customFunctions";
import HoverableIcon from "../../../../components/Buttons/HoverableIcon ";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    // fontSize: '15px',
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    // fontSize: '14px',
    whiteSpace: "normal",
  },
  titleContainer: {
    width: "100%",
  },
}));

const AccountDrilldownBody = (props) => {
  const classes = useStyle();
  const containerHeight = props.body
    ? props.body.opportunities.length * 35 < window.innerHeight - 300
      ? "-"
      : window.innerHeight - 300
    : 500;
  const domLayout = props.body
    ? props.body.opportunities.length * 35 < window.innerHeight - 300
      ? "autoHeight"
      : "normal"
    : "normal";
  const defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  let gridApi, columnApi, params;

  const gridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params = params;
  };
  const onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const exportCSV = () => {
    var params = {
      skipHeader: false,
      skipFooters: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: true,
      processCellCallback: (cellParams) => {
        const rowIndex = cellParams.column.colId;
        if ((cellParams && rowIndex === "SFDC") || rowIndex === "CS_Owner") {
          return cellParams.value ? `"${cellParams.value}"` : ""; //apply your string formatter
        } else if (cellParams && rowIndex === "ACV") {
          return _isNaN(cellParams.value) ? 0 : Math.round(cellParams.value);
        } else return cellParams.value; // no formatting
      },
      fileName: getCSVFileNameString(props.header),
      columnSeparator: ",",
    };
    gridApi.exportDataAsCsv(params);
  };

  return (
    <Grid container className={classes.root}>
      {props.body ? (
        <React.Fragment>
          <Grid
            container
            justify="space-betweeen"
            className={classes.titleContainer}
            xs={12}
          >
            <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="body1" style={{ fontWeight: "600" }}>
                Number of opportunities:{" "}
                {d3.format(",")(props.body.count_acv[0].count)}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ACV: ${" "}
                {d3.format(",")(Math.round(props.body.count_acv[0].acv))}
              </Typography>
            </Grid>

            <Grid
              direction="column"
              item
              container
              xs={2}
              justify="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
            >
              <Grid item justify="flex-end" style={{ paddingBottom: "1rem" }}>
                {/* <Button variant="contained"
                            color="primary" onClick={exportCSV}><GetApp /></Button> */}
                <button style={{ border: "none" }} onClick={exportCSV}>
                  <HoverableIcon />
                </button>
              </Grid>
            </Grid>
          </Grid>

          <div
            className="ag-theme-custom-react_DB"
            style={{
              height: containerHeight,
              width: "100%",
            }}
          >
            <AgGridReact
              columnDefs={[
                {
                  headerName: "#",
                  field: "OppNo",
                  type: "rightAligned",
                  sortable: false,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "3px",
                  },
                  headerClass: classes.tableHeader,
                  maxWidth: 45,
                },
                {
                  headerName: "Account",
                  field: "SFDC",
                  flex: 2,
                  suppressSizeToFit: true,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Account Segment ",
                  field: "Account_Segment",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },

                // { headerName: "Account Segment ", field: "Account_Segment", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                // { headerName: "Status ", field: "Status", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                // { headerName: "CARR ", field: "CARR", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                // { headerName: "Current Health ", field: "Overall_Health", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                // { headerName: "Region ", field: "Region", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                // { headerName: "CS Owner ", field: "CS_Owner", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                // { headerName: "Team ", field: "Team", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                // { headerName: "CS Head ", field: "CS_Head", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                // { headerName: "GS Head ", field: "GS_Head", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                // { headerName: "Get Well By Quarter", field: "Qtr_for_Green_Fiscal_Qtr", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                // { headerName: "Get Well By Qtr prior week", field: "Qtr_Get_Well_By_Last_Week_Fiscal_Quarter", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },

                // { headerName: "Status", field: "Status", minWidth: 100, flex: 1.2, sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-left': '5px' }, cellClass: classes.tableCell, headerClass: classes.tableHeader },
                {
                  headerName: `CARR`,
                  field: `CARR`,
                  flex: 0.6,
                  type: "rightAligned",
                  sortable: true,
                  filter: "agNumberColumnFilter",
                  filterParams: { defaultOption: "greaterThanOrEqual" },
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-right": "5px",
                  },
                  headerClass: classes.tableHeader,
                  sortingOrder: ["desc", "asc"],
                  valueFormatter: (x) => {
                    return "$ " + d3.format(",")(Math.round(x.value));
                  },
                },

                // {
                //     headerName: `Annual Revenue Value`, field: `CARR`, minWidth: 60, flex: 1, type: 'rightAligned', sortable: true, filter: 'agNumberColumnFilter', filterParams: { defaultOption: 'greaterThanOrEqual' }, cellStyle: { 'border-right-color': 'lightgrey', 'padding-right': '5px' }, headerClass: classes.tableHeader, sortingOrder: ['desc', 'asc'], valueFormatter: x => {
                //         return '$ ' + d3.format(",")(Math.round(x.value))
                //     }
                // },

                // {
                //     headerName: "Renewal Contract Effective Date", field: "Renewal_Contract_Effective_Date_c", minWidth: 80, flex: 1, type: 'rightAligned', sortable: true, filter: true, cellStyle: { 'border-right-color': 'lightgrey', 'padding-right': '5px' }, headerClass: classes.tableHeader, valueFormatter: x => {
                //         let tparts = x.value.split('T')
                //         let parts = tparts[0].split('-')
                //         return parseInt(parts[1]) + '/' + parseInt(parts[2]) + '/' + parseInt(parts[0])
                //     }
                // },
                {
                  headerName: "Current Health",
                  field: "Overall_Health",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Health Prior Wk.",
                  field: "Status_Last_week",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Region",
                  field: "Region",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "CS Owner",
                  field: "CS_Owner",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },

                {
                  headerName: "Team",
                  field: "AH_Team",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "CS Head",
                  field: "CS_Head",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "GS Head",
                  field: "GS_Head",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Well By Qtr.",
                  field: "Qtr_for_Green_Fiscal_Qtr",
                  flex: 0.6,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
                {
                  headerName: "Well By Qtr. prior Wk.",
                  field: "Qtr_Get_Well_By_Last_Week_Fiscal_Quarter",
                  flex: 0.7,
                  sortable: true,
                  filter: true,
                  cellStyle: {
                    "border-right-color": "lightgrey",
                    "padding-left": "5px",
                  },
                  cellClass: classes.tableCell,
                  headerClass: classes.tableHeader,
                },
              ]}
              sizeColumnsToFit={true}
              domLayout={domLayout}
              headerHeight="55"
              rowData={props.body.opportunities}
              onFirstDataRendered={onFirstDataRendered}
              // onCellClicked={this.onCellClicked.bind(this)}
              // pagination={true}
              // paginationPageSize={6}
              defaultColDef={defaultColDef}
              onColumnResized={props.body.length <= 10 ? onColumnResized : null}
              enableCellTextSelection={true}
              // autoHeight={true}
              colResizeDefault={"shift"}
              onGridReady={gridReady}
              debounceVerticalScrollbar={true}

              // cellClass='cell-wrap-text'
            ></AgGridReact>
          </div>
        </React.Fragment>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height={280}
          style={{ margin: "20px 0px" }}
        />
      )}
    </Grid>
  );
};

export default AccountDrilldownBody;
