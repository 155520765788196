import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ConfifmModal(props) {
  const confirmClick = () => {
    props.setConfirmSignal(true);
    props.handleConfirmClose();
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            textAlign={"center"}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Are you sure you want to remove{" "}
            <b>{props.selectedDashboard.Chart_Name}</b> from your favorites
            list?
          </Typography>
          <Grid
            item
            justifyContent="center"
            container
            style={{ paddingTop: "1rem", marginTop: "0.5rem" }}
          >
            <Grid item style={{ padding: "0 1rem" }}>
              <Button
                variant="contained"
                onClick={confirmClick}
                color="primary"
              >
                Yes
              </Button>
            </Grid>
            <Grid item style={{ padding: "0 1rem" }}>
              <Button onClick={props.handleClose}>No</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
