import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  customDateFormatter,
} from "../../util/customFunctions";
//  import { convertToThousands, getUniqueValues, _isNaN } from '../../util/customFunctions';
import * as d3 from "d3";
import { getMixKey } from "../../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
} from "../../util/customSortings";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import stickytableStyle from "../../StickyTableStyles/Sticky_Table_Styles";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
const useStyle = makeStyles((theme) => ({
  content: { width: "100%" },
  // sticky: {
  //   position: "sticky",
  //   left: -1,
  //   // color: "inherit",
  //   background: "white",
  //   // backgroundColor: "inherit",
  //   // boxShadow: "5px 2px 5px grey",
  //   // borderRight: "2px solid black",
  // },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function PipelineTrendsTable(props) {
  const classes = useStyle();
  const stickyClasses = stickytableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const uniqueDate = props.data
    .map((d) => d[props.date])
    .filter(getUniqueValues)
    .sort();
  const uniqueStages = props.data
    .map((d) => d[props.stage])
    .filter(getUniqueValues);
  const drillUniqueStages = uniqueStages.filter((x) => x !== "total");

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [stickyClasses.sticky]);

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: "1rem" }}>
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="flex-start" style={{ padding: "1rem" }}>
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id="myTableContainer"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}

            <Table
              ref={tableRef}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              data-test="pipeline-trends-table"
            >
              <TableHead>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ minWidth: 150 }}
                  >
                    {"Date"}
                  </TableCell>
                  {uniqueDate.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 210,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                padding: "10px 0",
                                minWidth: 100,
                              }
                        }
                        className={classes.repHeader}
                        colSpan={3}
                      >
                        {customDateFormatter(i)}
                      </TableCell>
                    );
                  })}
                  {uniqueDate.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 400, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>

                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${stickyClasses.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                  >{`Stage`}</TableCell>
                  {uniqueDate.map((quarter, i) => (
                    <React.Fragment key={i}>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 60 }}
                      >
                        {" "}
                        # of Opps{" "}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 90 }}
                      >
                        {/* ACV */}
                        {`${props.Value_Label}`}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 60 }}
                      >
                        % of Total
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueStages.map((owner, i) => (
                  <StyledTableRow key={i}>
                    <TableCell
                      align="left"
                      // className={
                      //   owner === "total"
                      //     ? `${classes.fontBold} ${classes.table}`
                      //     : `${classes.table}`
                      // }
                      className={
                        isSticky
                          ? `${stickyClasses.sticky} ${classes.table}`
                          : classes.table
                      }
                      style={
                        isSticky && i % 2 === 1 ? { background: "#f5f5f5" } : {}
                      }
                      colSpan={1}
                    >
                      {owner === "total" ? "Total" : owner}
                    </TableCell>
                    {uniqueDate.map((qtr, j) => {
                      const acv = props.data
                        .filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.stage] === owner
                        )
                        .map((item) => item.acv);
                      const opps = props.data
                        .filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.stage] === owner
                        )
                        .map((item) => item.count);
                      const perc = props.data
                        .filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.stage] === owner
                        )
                        .map((item) => item.percentageOfTotal);
                      const stage = props.data
                        .filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.stage] === owner
                        )
                        .map((item) => item[props.stage]);
                      const date = props.data
                        .filter(
                          (item) =>
                            item[props.date] === qtr &&
                            item[props.stage] === owner
                        )
                        .map((item) => item[props.date]);
                      return (
                        <>
                          <TableCell
                            align="right"
                            className={
                              owner === "total"
                                ? `${classes.fontBold} ${classes.repNumber} ${classes.drilldownCell}`
                                : `${classes.repNumber} ${classes.drilldownCell}`
                            }
                            colSpan={1}
                            onClick={
                              owner === "total"
                                ? () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: drillUniqueStages,
                                        cell: date,
                                        x: "Stage_At_Time_T",
                                        y: "All",
                                      }
                                    )
                                : () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: stage,
                                        cell: date,
                                        x: "Stage_At_Time_T",
                                        y: stage,
                                      }
                                    )
                            }
                          >
                            {opps === "undefiend" ||
                            opps === null ||
                            _isNaN(opps)
                              ? "-"
                              : opps}{" "}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={
                              owner === "total"
                                ? `${classes.fontBold} ${classes.repNumber} ${classes.drilldownCell}`
                                : `${classes.repNumber} ${classes.drilldownCell}`
                            }
                            colSpan={1}
                            onClick={
                              owner === "total"
                                ? () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: drillUniqueStages,
                                        cell: date,
                                        x: "Stage_At_Time_T",
                                        y: "All",
                                      }
                                    )
                                : () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: stage,
                                        cell: date,
                                        x: "Stage_At_Time_T",
                                        y: stage,
                                      }
                                    )
                            }
                          >
                            {acv === "undefiend" || acv === null || _isNaN(acv)
                              ? "-"
                              : countOrACVFormatter(acv, true)}{" "}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={`${classes.repNumber} ${classes.drilldownCell}`}
                            colSpan={1}
                            onClick={
                              owner === "total"
                                ? () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: drillUniqueStages,
                                        cell: date,
                                        x: "Stage_At_Time_T",
                                        y: "All",
                                      }
                                    )
                                : () =>
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      {
                                        row: stage,
                                        cell: date,
                                        x: "Stage_At_Time_T",
                                        y: stage,
                                      }
                                    )
                            }
                          >
                            {perc === "undefiend" ||
                            perc === null ||
                            _isNaN(perc)
                              ? "-"
                              : Math.round(perc * 100) + "%"}{" "}
                          </TableCell>
                        </>
                      );
                    })}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
