import * as d3 from 'd3'

const D3Funnel = {};


D3Funnel.create = (el, config) => {

	d3.selection.prototype.last = function () { return d3.select(this.nodes()[this.size() - 1]) };

	const handleChangeLocation = (location, stage, lastStage) => {
		config.handleChangeLocation(location, stage, lastStage)
	}
	// D3 Code to create the chart
	if (config.data) {
		config.barColor = '#70ad47';
		config.barHeight = d3.select(el).node().getBoundingClientRect().height / ((config.data.length * 2));
		config.margin = {
			top: 0,
			right: 50,
			bottom: 20,
			left: config.data ? d3.select(el).node().getBoundingClientRect().width < 400 ? 10 : (Math.max(...config.data.map(d => d.label.length)) * 6) + 30 : 100
		};
		if (!config.width) {
			config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right : 100;
		}
		config.height = d3.select(el).node().getBoundingClientRect().height//config.data ? config.data.length * 35 : 100//(config.barHeight * config.data.length + (config.data.length * 5) < 200 ? 200 : config.barHeight * config.data.length + (config.data.length * 5) + 60) - config.margin.top - config.margin.bottom;
		// config.handleChangeLocation("drilldownOnGraph")
		//x scale is 200% - 100% on left side, 100% on right side
		var x = d3.scaleBand().domain([0, 2]).range([0, config.width]);

		var chartContainer = d3.select(el)
			.append('svg')
			.attr('width', config.width + config.margin.left + config.margin.right)
			.attr('height', config.height + config.margin.top + config.margin.bottom)
		var chart = chartContainer
			.append('g')
			.attr("transform", "translate(" + config.margin.left + "," + config.margin.top + ")");

		/*var middleLine = chart.append('line')
		 .attr('class', 'middle-line')
		 .attr('x1', config.width / 2)
		 .attr('y1', config.height)
		 .attr('x2', config.width / 2)
		 .attr('y2', 0);*/

		var rateRectsGroup = chart.selectAll('g.rates')
			.data(config.data).enter()
			.append('g')
			.attr('class', 'rates')
			.attr("transform", function (d, i) {
				var xPos,
					yPos = ((config.barHeight * i) * 2);
				xPos = 0;
				return "translate(" + xPos + "," + yPos + ")";
			});

		var rateRectsFull = rateRectsGroup.append('rect')
			.attr('height', config.barHeight)
			.attr('width', config.width)
			.attr('class', 'compare-bar')
			.attr('fill', '#bfbfbf')
			.style("cursor", "pointer")
			.on("click", i => {
				if (rateRectsFull.last()._groups[0][0].__data__.label === i.label) {
					handleChangeLocation('drilldownOnGraph', i.label, true)
				} else {
					handleChangeLocation('drilldownOnGraph', i.label, false)
				}
			})
			.on('mouseover', function (d, i) {
				d3.select(this)
					.transition()
					.style('opacity', '0.5');

			})

			.on('mouseout', function (d, i) {
				d3.select(this)
					.transition()
					.style('opacity', '1');

			})
			.attr("transform", 'translate(0, 0)');

		var rateRects = rateRectsGroup.append('rect')
			.attr('height', config.barHeight)
			.attr('width', function (d) {
				console.log(d)
				return (config.width * d.rate)
			})
			.attr('class', 'actual-bar')
			.style("cursor", "pointer")
			.style('fill', config.barColor)
			.on("click", i => {
				if (rateRects.last()._groups[0][0].__data__.label === i.label) {
					handleChangeLocation('drilldownOnGraph', i.label, true)
				} else {
					handleChangeLocation('drilldownOnGraph', i.label, false)
				}
			})
			.on('mouseover', function (d, i) {
				d3.select(this)
					.transition()
					.style('opacity', '0.5');
			})
			.on('mouseout', function (d, i) {
				d3.select(this)
					.transition()
					.style('opacity', '1');
			})
			.attr("transform", function (d) {
				return 'translate(' + (config.width - (config.width * d.rate)) / 2 + ', 0)';
			});

		var rateRectsLabel = rateRectsGroup.append('text')
			.attr('text-anchor', 'middle')
			.attr('class', 'category-value')
			.attr("transform", function (d, i) {
				var xPos = config.width / 2,
					yPos = d3.select(el).node().getBoundingClientRect().width < 400 ? config.barHeight / 2 - 5 : config.barHeight / 2 - 7
				return "translate(" + xPos + "," + yPos + ")";
			})
			//.attr('transform', 'translate(' + config.width / 2 + ', 0)')
			.append('tspan')
			.attr('dy', '1em')
			.style('font-size', '0.85rem')
			.attr('font-weight', 600)
			.attr('cursor', 'pointer')
			.attr('fill', 'white')
			.text(function (d) {
				return `${d3.format(",")(d.count)}`;
			})
			.on("click", i => {
				if (rateRectsLabel.last()._groups[0][0].__data__.label === i.label) {
					handleChangeLocation('drilldownOnGraph', i.label, true)
				} else {
					handleChangeLocation('drilldownOnGraph', i.label, false)
				}
			});

		var ratePercentageLabel = rateRectsGroup.append('text')
			.attr('text-anchor', 'middle')
			.attr('class', 'category-value-percentage')
			.attr("transform", function (d, i) {
				var xPos = config.width / 2,
					yPos = d3.select(el).node().getBoundingClientRect().width < 400 ? -config.barHeight : -config.barHeight * 0.9
				return "translate(" + xPos + "," + yPos + ")";
			})
			//.attr('transform', 'translate(' + config.width / 2 + ', -20)')
			.append('tspan')
			.attr('dy', '1em')
			.style('font-size', '0.85rem')
			.attr('font-weight', 600)
			.text(function (d) {
				return `${Math.round(d.diffRate * 100)}% (${Math.round(d.rate * 100)}%)`;
			});

		var categoryLabels2 = rateRectsGroup
			.append('text')
			.attr('class', 'bar-text')
			.style("cursor", "pointer")
			.on("click", i => {
				if (categoryLabels.last()._groups[0][0].__data__.label === i.label) {
					handleChangeLocation('drilldownOnGraph', i.label, true)
				} else {
					handleChangeLocation('drilldownOnGraph', i.label, false)
				}
			})
			.attr('text-anchor', 'start')
			.attr("transform", function (d, i) {
				var xPos = config.width + 10,
					yPos = d3.select(el).node().getBoundingClientRect().width < 400 ? config.barHeight / 2 - 5 : config.barHeight / 2 - 7
				return "translate(" + xPos + "," + yPos + ")";
			})
			.append('tspan')
			.attr('dy', '1em')
			.style('font-size', '0.85rem')
			.attr('font-weight', 600)
			.attr('fill', 'black')
			.on('mouseover', function (d, i) {
				d3.select(this)
					.transition()
					.style('opacity', '0.5')
			})
			.on('mouseout', function (d, i) {
				d3.select(this)
					.transition()
					.style('opacity', '1')
			})
			.text(function (d) {
				return `${(Math.min(...config.data.map(d => d.count)) === 0 || d.count === 0) ? 0 : Math.round((Math.min(...config.data.map(d => d.count)) / d.count) * 100)}%`
			});


		var categoryLabels = rateRectsGroup
			.append('text')
			.attr('class', 'bar-text')
			.style("cursor", "pointer")
			.on("click", i => {
				if (categoryLabels.last()._groups[0][0].__data__.label === i.label) {
					handleChangeLocation('drilldownOnGraph', i.label, true)
				} else {
					handleChangeLocation('drilldownOnGraph', i.label, false)
				}
			})
			.attr('text-anchor', d3.select(el).node().getBoundingClientRect().width < 400 ? 'start' : 'end')
			.attr("transform", function (d, i) {
				var xPos = d3.select(el).node().getBoundingClientRect().width < 400 ? 5 : -10,
					yPos = d3.select(el).node().getBoundingClientRect().width < 400 ? config.barHeight / 2 - 5 : config.barHeight / 2 - 7
				return "translate(" + xPos + "," + yPos + ")";
			})
			.append('tspan')
			.attr('dy', '1em')
			.style('font-size', '0.85rem')
			.attr('font-weight', 400)
			.attr('fill', d3.select(el).node().getBoundingClientRect().width < 400 ? 'white' : 'black')
			.on('mouseover', function (d, i) {
				d3.select(this)
					.transition()
					.style('opacity', '0.5')
			})
			.on('mouseout', function (d, i) {
				d3.select(this)
					.transition()
					.style('opacity', '1')
			})
			.text(function (d) {
				return d.label
			});
	}
};

D3Funnel.update = (el, config) => {
	config.barColor = '#4caf50';
	config.barHeight = 20;
	config.margin = {
		top: 0,
		right: 20,
		bottom: 20,
		left: 100
	};
	if (!config.width) {
		config.width = d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right;
	}
	config.height = 300
	// D3 Code to update the chart
	var chartContainer = d3.select(el)
	var chart = chartContainer.select('g')
	var rateRectsGroup = chart.selectAll('g.rates').data(config.data).exit().remove().enter()

	var rateRectsFull = rateRectsGroup.append('rect')
		.attr('height', config.barHeight)
		.attr('width', config.width)
		.attr('class', 'compare-bar')
		.attr('fill', '#ccc')
		.attr("transform", 'translate(0, 0)');

	var rateRects = rateRectsGroup.append('rect')
		.attr('height', config.barHeight)
		.attr('width', function (d) {
			return (config.width * d.rate)
		})
		.attr('class', 'actual-bar')
		.style('fill', config.barColor)
		.attr("transform", function (d) {
			return 'translate(' + (config.width - (config.width * d.rate)) / 2 + ', 0)';
		});

	var rateRectsLabel = rateRectsGroup.append('text')
		.attr('text-anchor', 'middle')
		.attr('class', 'category-value')
		.attr('transform', 'translate(' + config.width / 2 + ', 0)')
		.append('tspan')
		.attr('dy', '1em')
		.text(function (d) {
			return `${d.count} - ${(d.rate * 100).toFixed(2)}%`;
		});

	rateRectsGroup.select('rect.actual-bar').transition().duration(500).attr('width', function (d) {
		return (config.width * d.rate)
	}).attr("transform", function (d) {
		return 'translate(' + (config.width - (config.width * d.rate)) / 2 + ', 0)';
	});

	rateRectsGroup.select('text.category-value').select('tspan').transition().duration(500).text(function (d) {
		return `${d.count} - ${(d.rate * 100).toFixed(2)}%`;
	})
};

D3Funnel.destroy = (el) => {
	// Cleaning code here
	d3.select(el).selectAll('svg').remove();
};

export default D3Funnel;