export function generateIntegerAbove99() {
  var randomNumber =
    Math.floor(Math.random() * (Number.MAX_SAFE_INTEGER - 99)) + 100;
  return randomNumber;
}
export const MAC_BROWSER_WIDTH = 0.85;
export const dashboardRecommnedationString = (
  dashboards
) => `The following dashboard${
  dashboards.length > 1 ? "s" : ""
} can provide you more information:
${dashboards.map((it) => it).join(",")}`;
export const replaceDynamicValueLabel = (str, valueLabel) => {
  const regex = /acv/gi; // "g" for global, "i" for case-insensitive
  const replacedString = str.replace(regex, valueLabel);
  return replacedString;
};
