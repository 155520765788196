import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@material-ui/core";
  import React from "react";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import { countOrACVFormatter, StyledTableRow } from "../../util/customFunctions";

  const useStyle = makeStyles((theme) => ({
    headerCell: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
    },
    dataCell: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
    },
    drilldownCell: {
      cursor: "pointer",
    },
  }));
  export default function QualipiplineTable(props) {
    const classes = useStyle();
    const tableRef = React.createRef();
  
    const uniqueCols = ['Needed','Actual', 'Delta'];
    return (
      <div>
        <TableContainer
          style={{
            padding: "1rem",
            paddingBottom: "3rem",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
            align="center"
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
          <Table
            ref={tableRef}
            size="small"
            aria-label="html table"
            style={{ tableLayout: "auto" }}
            data-test={props.dataTest}
          >
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                {uniqueCols.map((l, i) => {
                  return (
                    <TableCell
                      align="center"
                      className={classes.headerCell}
                      colSpan={1}
                      style={
                        i % 2 === 0
                          ? { background: "#4471c4", color: "white" }
                          : { background: "#5b9bd5", color: "white" }
                      }
                    >
                      {l}
                    </TableCell>
                  );
                })}
              </TableRow>

                  <StyledTableRow key={'r0w123'}>
                  <TableCell className={classes.headerCell} align="right">
                    {[null,undefined, ''].includes(props.data.needed) ? '-' : countOrACVFormatter(props.data.needed, true)}
                    </TableCell>
                    <TableCell className={classes.headerCell} align="right">
                      {[null,undefined, ''].includes(props.data.acv) ? '-' : countOrACVFormatter(props.data.acv, true)}
                    </TableCell>
                    <TableCell className={classes.headerCell} align="right">
                    {[null,undefined, ''].includes(props.data.delta) ? '-' : countOrACVFormatter(props.data.delta, true)}
                    </TableCell>
                  </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  