// Function to replace all occurrences of a pattern in a string
export function replaceAll(string, pattern, replacement) {
  const regex = new RegExp(pattern, "gi"); // 'g' for global, 'i' for case-insensitive
  return string.replace(regex, replacement);
}

//Winrate Replace
export function replaceWinRateLabel(string, pattern, replacement) {
  const regex = new RegExp(pattern, "gi"); // 'g' for global, 'i' for case-insensitive
  return string.replace(regex, replacement);
}
