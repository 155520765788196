import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  customDateFormatter,
  getTimingArr,
  dateFormatterMoment,
  csvDateFormatter,
} from "../../util/customFunctions";
//  import { convertToThousands, getUniqueValues, _isNaN } from '../../util/customFunctions';
import * as d3 from "d3";
import { getMixKey } from "../../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
} from "../../util/customSortings";
import { deSnake } from "../../util/dataFormatter";
import { map } from "d3";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import {
  replaceAll,
  replaceWinRateLabel,
} from "../../components/xlsx-export/LabelCange";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
// import RepBenchMarkExcelExportButton from "../../components/xlsx-export/RepBenchMark";
const useStyle = makeStyles((theme) => ({
  content: { width: "100%" },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function OpportunityPyramidSummaryTable(props) {
  console.log("timeline", props);
  const classes = useStyle();
  const tableRef = React.createRef();

  var uniqueName = props.data
    .map((d) => d.name)
    .filter(getUniqueValues)
    .filter((f) => f !== undefined);
  console.log(uniqueName);
  const uniqueStages = props.data
    .map((d) => d[props.stage])
    .filter(getUniqueValues);
  const uniqueRep = props.data
    .filter((d) => d[props.owner] !== "total")
    .map((d) => d[props.owner])
    .filter(getUniqueValues);
  const uniqueType = props.data.map((d) => d.type).filter(getUniqueValues);

  const reps = props.data.filter((d) => d.team && d[props.owner] !== "total");
  // const Rep = uniqueTeams.map(team => reps.filter(x => x.team === team).map(x => x[props.owner]))

  const total = props.data.filter(
    (d) => d.team === "total" && d[props.owner] === "total"
  );

  return (
    <div className={classes.root}>
      <CardContent className={classes.content} style={{ padding: "0.2rem" }}>
        <Grid container justifyContent="center" style={{ padding: "0.2rem" }}>
          <TableContainer className={classes.mainTable}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
            <Table
              ref={tableRef}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              data-test="cycle-time-analysis-table"
            >
              <TableHead>
                {" "}
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    className={`${classes.repHeader}`}
                    colSpan={1}
                    style={{ minWidth: 150 }}
                  ></TableCell>
                  {uniqueName.map((i, index) => {
                    // console.log(i)
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          i === "Won"
                            ? {
                                background: "#70AD47",
                                color: "white",
                                minWidth: 150,
                                maxWidth: 150,
                              }
                            : i === "Lost"
                            ? {
                                background: "#C65911",
                                color: "white",
                                minWidth: 150,
                                maxWidth: 150,
                              }
                            : index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 150,
                                maxWidth: 150,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                padding: "10px 0",
                                minWidth: 150,
                                maxWidth: 150,
                              }
                        }
                        className={classes.repHeader}
                        colSpan={1}
                      >
                        {/* {i} */}
                        {replaceWinRateLabel(
                          i,
                          "Win Rate",
                          `${props.Win_Rate_Prefix}`
                        )}
                      </TableCell>
                    );
                  })}

                  {uniqueName.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 400, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueType.map((team, i) => {
                  const teamData = props.data.filter(
                    (item) => item.name === team
                  );
                  //  console.log(props.data.filter(x => x.count).map(y => y.count))
                  const reps = props.data
                    .filter((item) => item.name === team)
                    .map((item) => item["name"])
                    .filter(getUniqueValues);
                  return (
                    <>
                      <StyledTableRow key={i}>
                        <TableCell
                          align="left"
                          className={`${classes.repText} ${classes.fontBold}`}
                          colSpan={1}
                        >
                          {/* {team}t1 */}
                          {replaceAll(team, "acv", `${props.Value_Label}`)}
                        </TableCell>
                        {uniqueName.map((name, i) => {
                          const count = props.data
                            .filter(
                              (c) =>
                                c.name === name && c.type === team && c.value
                            )
                            .map((c) =>
                              c.name === "Qual. To Closed Won Win Rate"
                                ? `${(c.value * 100).toFixed(2)}%`
                                : c.type === "Total ACV" ||
                                  c.type === "Avg. Deal Size" ||
                                  c.type ===
                                    `Avg. ACV / ${
                                      String(props.timeline).endsWith("_Month")
                                        ? "Month"
                                        : "Qtr."
                                    }`
                                ? countOrACVFormatter(c.value, true)
                                : c.type ===
                                  `# of Opps / ${
                                    String(props.timeline).endsWith("_Month")
                                      ? "Month"
                                      : "Qtr."
                                  }`
                                ? c.value.toFixed(2)
                                : countOrACVFormatter(c.value, false)
                            );
                          const key = props.data.filter(
                            (c) => c.name === name
                          )[0].name;
                          const type = props.data.filter(
                            (t) => t.type === team
                          )[0].type;
                          return (
                            <>
                              <TableCell
                                style={
                                  key === "Qual. To Closed Won Win Rate" ||
                                  (type !== "Opp. Count" &&
                                    type !== "Total ACV")
                                    ? { cursor: "auto" }
                                    : { display: "pointer" }
                                }
                                onClick={() =>
                                  key !== "Qual. To Closed Won Win Rate" &&
                                  (type === "Opp. Count" ||
                                    type === "Total ACV") &&
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    key
                                  )
                                }
                                align="left"
                                className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                                colSpan={1}
                              >
                                {count.length == 0 ? "-" : count}
                              </TableCell>
                            </>
                          );
                        })}
                      </StyledTableRow>
                    </>
                  );
                })}
                {props.text !== undefined && (
                  <StyledTableRow>
                    <TableCell
                      align="left"
                      className={`${classes.repText} `}
                      colSpan={uniqueName.length + 1}
                    >
                      <span style={{ color: "#ED7D31" }}>*</span>&nbsp;{" "}
                      {props.text}
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
