const { handleResponse } = require("../../../App/app.service");
const viz_url = process.env.REACT_APP_VIZ_URL;
class ManagementService {
  authHeader() {
    return {
      "Content-Type": "application/json; charset=utf-8",
    };
  }
  getQuotaFiscalQuarterFilters() {
    const requestOptions = {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: this.authHeader(),
      body: null,
    };

    return fetch(
      `${viz_url}/api/manage/v2/quotaMaintainence/filters`,
      requestOptions
    ).then(handleResponse);
  }
  getQuotaDataV2(filters, isDryRun) {
    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: this.authHeader(),
      body: JSON.stringify({
        filters,
      }),
    };

    return fetch(
      `${viz_url}/api/manage/v2/quotaMaintainence/data?dryRun=${isDryRun}`,
      requestOptions
    ).then(handleResponse);
  }
  getTeamQuotaDataV2(filters, isDryRun) {
    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: this.authHeader(),
      body: JSON.stringify({
        filters,
      }),
    };

    return fetch(
      `${viz_url}/api/manage/v2/quotaMaintainence/team/data?dryRun=${isDryRun}`,
      requestOptions
    ).then(handleResponse);
  }
  getCompanyQuotaDataV2(filters, isDryRun) {
    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: this.authHeader(),
      body: JSON.stringify({
        filters,
      }),
    };

    return fetch(
      `${viz_url}/api/manage/v2/quotaMaintainence/company/data?dryRun=${isDryRun}`,
      requestOptions
    ).then(handleResponse);
  }
  updateQuotaDataV2(updatedObjects, isDryRun) {
    const requestOptions = {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: this.authHeader(),
      body: JSON.stringify({
        updatedObjects,
      }),
    };

    return fetch(
      `${viz_url}/api/self-service/rep?dryRun=${isDryRun}`,
      requestOptions
    ).then(handleResponse);
  }
  updateTeamQuotaData(updatedObjects, isDryRun) {
    const requestOptions = {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: this.authHeader(),
      body: JSON.stringify({
        updatedObjects,
      }),
    };

    return fetch(
      `${viz_url}/api/self-service/team?dryRun=${isDryRun}`,
      requestOptions
    ).then(handleResponse);
  }
  updateCompanyQuotaData(updatedObjects, isDryRun) {
    const requestOptions = {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: this.authHeader(),
      body: JSON.stringify({
        updatedObjects,
      }),
    };

    return fetch(
      `${viz_url}/api/self-service/company?dryRun=${isDryRun}`,
      requestOptions
    ).then(handleResponse);
  }
}
const managementService = new ManagementService();
export default managementService;
