import { Card, Grid, Paper, Typography, withStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { sort } from "d3-array";
import React, { Component } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  customDateFormatter,
  getUniqueValues,
} from "../../util/customFunctions";

import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import { styled } from "@mui/material/styles";
import Drilldown from "../GenericScorecard/Drilldown";
import addHeaderdescription from "../../components/HeaderDescription";
import DataTable from "./DataTable";
import DTable from "./DTable";
import DTablev4 from "./DTablev4";
import PipelineCoverageAndShapeAnalysisSection from "../GenericScorecard/PipelineCoverageAndShapeAnalysisSection";
import ErrorBoundary from "../../components/ErrorBoundary";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  tableClickable: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
    cursor: "pointer",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});
class PSCA extends Component {
  state = {
    location: null,
    secondLevelLocation: null,
    toggleValue: "Current_acv",
    tab: 'Summary',
    num: 2,
  };
  componentDidMount = () => {
    let filters = [];
    this.props.getNewQualifiedPipelineData(filters);
  };
  drilldown = (rep, weekEnding, avgDrilldownFlag, metaData) => {
    console.log(rep, weekEnding);
    let selectNum = 2;
    var filters = [],
      filtersForDrilldown = [],
      heading = "";
    if (["Twelve", "Eight", "Four"].includes(weekEnding)) {
      filters = [
        {
          name: "Owner_Name",
          value: rep,
          string: true,
        },
      ];
      filtersForDrilldown = [
        {
          title: "Time Frame",
          value:
            weekEnding === "Twelve"
              ? this.props.NewQualifiedPipelineData.timeFrameEnding ===
                "Half_Month_Ending"
                ? "Trailing 6 months"
                : "Trailing 12 weeks"
              : weekEnding === "Eight"
              ? this.props.NewQualifiedPipelineData.timeFrameEnding ===
                "Half_Month_Ending"
                ? "Trailing 4 months"
                : "Trailing 8 weeks"
              : this.props.NewQualifiedPipelineData.timeFrameEnding ===
                "Half_Month_Ending"
              ? "Trailing 2 months"
              : "Trailing 4 weeks",
        },
        {
          title: rep.length > 1 ? "Team" : "Rep",
          value:
            rep.length > 1
              ? this.props.NewQualifiedPipelineData.summary
                  .filter((item) => rep.includes(item.Owner_Name))
                  .map((i) => i.team)
                  .filter(getUniqueValues)
              : rep,
        },
      ];
      heading = "New Qualified Pipeline Trends";

      if (
        this.props.NewQualifiedPipelineData.timeFrameEnding ===
        "Half_Month_Ending"
      ) {
        if (weekEnding === "Twelve") {
          selectNum = 6;
        }

        if (weekEnding === "Eight") {
          selectNum = 4;
        }

        if (weekEnding === "Four") {
          selectNum = 2;
        }
      }
    } else {
      filters = [
        {
          name: "Owner_Name",
          value: rep,
          string: true,
        },
        {
          name: this.props.NewQualifiedPipelineData.timeFrameEnding,
          value: weekEnding,
          string: true,
        },
      ];
      const timeEnded =
        this.props.NewQualifiedPipelineData.timeFrameEnding === "Week_Ending"
          ? "Week"
          : "Half-Month";
      filtersForDrilldown = [
        {
          title: "Time Frame",
          value: `${timeEnded} ended ${customDateFormatter(weekEnding)}`,
        },
        {
          title: rep.length > 1 ? "Team" : "Rep",
          value:
            rep.length > 1
              ? this.props.NewQualifiedPipelineData.summary
                  .filter((item) => rep.includes(item.Owner_Name))
                  .map((i) => i.team)
                  .filter(getUniqueValues)
              : rep,
        },
      ];
      heading = "New Qualified Pipeline";

      if (
        this.props.NewQualifiedPipelineData.timeFrameEnding ===
        "Half_Month_Ending"
      ) {
        if (weekEnding === "Twelve") {
          selectNum = 6;
        }

        if (weekEnding === "Eight") {
          selectNum = 4;
        }

        if (weekEnding === "Four") {
          selectNum = 2;
        }
      }
    }
    this.props.getNewQualifiedPipelineDrilldownData(filters);

    this.setState({
      ...this.state,
      isMetaData: avgDrilldownFlag,
      metaData,
      drilldownLabelString:
        this.props.NewQualifiedPipelineData.timeFrameEnding === "Week_Ending"
          ? "week"
          : "half-month",
      heading,
      location: "drilldown",
      filtersForDrilldown: filtersForDrilldown,
      num: selectNum,
    });
  };

  handleChangeToggle = (e) => {
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  handleChangeTab = (e) => {
    console.log(e.target.value);
    this.setState({
      ...this.state,
      tab: e.target.value,
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
    //this.props.setTable('')
  };
  handleChangeLocation = () => {
    this.setState({ ...this.state, location: "Home", filtersForDrilldown: [] });
    // this.props.clearDrilldownData()
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      // location: location,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    return (
      <Grid container>
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center">
            New Qualified pipeline
          </Typography>
        </Grid>
        <Typography variant="body1">
          Opportunities that came to, or went past the Qualified stage, moving
          in the forward direction, and for the first time.
        </Typography> */}
        {addHeaderdescription("Pipeline Coverage and Shape Analysis", "Pipeline Coverage and Shape Analysis")}
      
          <Grid
            item
            xs={12}
            md={12}
            id="grid-container"
            style={{ marginTop: "1rem" }}
          >
            <Paper style={{ marginBottom: "-7px" }}>
              <ToggleButtonGroup
                color="primary"
                indicatorColor="primary"
                value={this.state.tab}
                exclusive
                size="large"
                onChange={this.handleChangeTab}
              >
                <ToggleButton
                  style={{
                    background: this.state.tab === "Summary" ? "#4472C4" : "",
                    color: this.state.tab === "Summary" ? "white" : "",
                    fontSize: "1rem",
                    fontWeight: 600,
                    height: "3.5rem",
                    width: "8rem",
                  }}
                  value="Summary"
                  id="navlink_Summary_Tab"
                >
                  Summary
                </ToggleButton>
                <ToggleButton
                  style={{
                    background: this.state.tab === "Team_Rep" ? "#4472C4" : "",
                    color: this.state.tab === "Team_Rep" ? "white" : "",
                    fontSize: "1rem",
                    fontWeight: 600,
                    height: "3.5rem",
                    width: "8rem",
                  }}
                  value="Team_Rep"
                  id="navlink_Team_Rep_Tab"
                >
                  Team / Rep
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>
          </Grid>
        

        


       

        {
          this.state.tab == 'Team_Rep' &&
          <>
          <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {this.props.NewQualifiedPipelineData !== "" ? (
            <Grid item container xs={12} justify="flex-start">
              <Card style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{
                        width: "100%",
                        margin: "auto",
                        padding: "1rem",
                        paddingTop: "0.3rem",
                      }}
                      align="center"
                    >
                      Pipeline Coverage
                    </Typography>
                  </Grid>
                  <Grid item xs={1} align="right"></Grid>
                </Grid>
                <DataTable
                  quartersCoverageRatioMap={
                    this.props.NewQualifiedPipelineData.quartersCoverageRatioMap
                  }
                  fiscalQuartersIdxMap={
                    this.props.NewQualifiedPipelineData.fiscalQuartersIdxMap
                  }
                  data={this.props.NewQualifiedPipelineData}
                  columns={this.props.NewQualifiedPipelineData.cfqs}
                  data-test="PCSA"
                  id="PSCA"
                />
              </Card>
            </Grid>
          ) : (
            <Grid item container component={Card} justify="center">
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
          <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {this.props.NewQualifiedPipelineData !== "" ? (
            <Grid item container xs={12} justify="flex-start">
              <Card style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{
                        width: "100%",
                        margin: "auto",
                        padding: "1rem",
                        paddingTop: "0.3rem",
                      }}
                      align="center"
                    >
                      Pipeline Distribution by Stage (Qualification and above)
                    </Typography>
                  </Grid>
                  <Grid item xs={1} align="right"></Grid>
                </Grid>
                <DTable
                  data={this.props.NewQualifiedPipelineData}
                  quartersCoverageRatioMap={
                    this.props.NewQualifiedPipelineData.quartersCoverageRatioMap
                  }
                  fiscalQuartersIdxMap={
                    this.props.NewQualifiedPipelineData.fiscalQuartersIdxMap
                  }
                  columns={this.props.NewQualifiedPipelineData.cfqs}
                  col="actual"
                  col2="stage_name_char"
                  uniqueNames={this.props.NewQualifiedPipelineData.uniqueStages}
                  data-test="PCSA-stage"
                  id="PSCA-stage"
                />
              </Card>
            </Grid>
          ) : (
            <Grid item container component={Card} justify="center">
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {this.props.NewQualifiedPipelineData !== "" ? (
            <Grid item container xs={12} justify="flex-start">
              <Card style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{
                        width: "100%",
                        margin: "auto",
                        padding: "1rem",
                        paddingTop: "0.3rem",
                      }}
                      align="center"
                    >
                      Pipeline Shape vs. Goal (Qualification and above)
                    </Typography>
                  </Grid>
                  <Grid item xs={1} align="right"></Grid>
                </Grid>
                <DTablev4
                  data={this.props.NewQualifiedPipelineData}
                  quartersCoverageRatioMap={
                    this.props.NewQualifiedPipelineData.quartersCoverageRatioMap
                  }
                  fiscalQuartersIdxMap={
                    this.props.NewQualifiedPipelineData.fiscalQuartersIdxMap
                  }
                  columns={this.props.NewQualifiedPipelineData.cfqs}
                  col="actualByStage"
                  col2="stageWithGoal"
                  uniqueNames={
                    this.props.NewQualifiedPipelineData.uniqueStagesWithGoals
                  }
                  length={
                    this.props.NewQualifiedPipelineData.uniqueStages.length
                  }
                  data-test="PCSA-stage"
                  id="PSCA-stage"
                />
              </Card>
            </Grid>
          ) : (
            <Grid item container component={Card} justify="center">
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
          </>
        }

        {
          this.state.tab === 'Summary' &&
          <>
           <Grid
                  component={Card}
                  style={{ marginTop: "2rem" }}
                  item
                  xs={12}
                >
                  <Grid item xs={12} id="grid-container">
                    <ErrorBoundary>
                      {" "}
                      <PipelineCoverageAndShapeAnalysisSection
                        filters={[]}
                        selectedTeam={["All"]}
                        selectedRep={["All"]}
                        title={"Pipeline Coverage"}
                        db={this.props.db}
                      />
                    </ErrorBoundary>
                    {/* <PipelineCoverageSection filters={this.state.filters} selectedTeam={this.state.selectedTeam} selectedRep={this.state.selectedRep} title={"Pipeline Coverage"} type={"NFQ"} db={this.props.db}/> */}
                  </Grid>
                </Grid>
          </>
        }
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  var {
    user,
    NewQualifiedPipelineData,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
    buildingPipelineData
  } = state.app;

  return {
    company: user.company,
    NewQualifiedPipelineData,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
    buildingPipelineData
  };
};
const mapDispatchToProps = (dispatch) => ({
  clearDrilldownData: () => {
    dispatch({ type: "clear_NewQualifiedPipeline_drilldown_data" });
  },
  getPipelineMixAnalysisDatav2: (company, filters = []) => {
    dispatch({ type: "get_building_pipeline_analysis_data_v2_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getBuildingPipelineDatav3(filters).then(
      (json) => {
        dispatch({
          type: "get_building_pipeline_analysis_data_v2_success",
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_building_pipeline_analysis_data_v2_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_building_pipeline_analysis_data_v2_failure",
            error,
          });
      }
    );
  },
  getNewQualifiedPipelineDrilldownData: (filters) => {
    dispatch({ type: "get_NewQualifiedPipeline_drilldown_data_request" });
    appService.getNewQualifiedPipelineDrilldownData(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.acvAtStage > b.acvAtStage ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            columns: json.message.columns,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
          },
        };

        dispatch({
          type: "get_NewQualifiedPipeline_drilldown_data_success",
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_NewQualifiedPipeline_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_NewQualifiedPipeline_drilldown_data_failure",
            error,
          });
      }
    );
  },
  getNewQualifiedPipelineData: (filters) => {
    dispatch({ type: "get_NewQualifiedPipeline_data_request" });
    appService.getPCSAData(filters).then(
      (json) => {
        dispatch({ type: "get_NewQualifiedPipeline_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_NewQualifiedPipeline_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_NewQualifiedPipeline_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedPSCA = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PSCA));
export default connectedPSCA;
