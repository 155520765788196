import * as d3 from "d3";
import { updatedScheme10 } from "../../util/chartColorScheme";
import {
  countOrACVFormatter,
  customDateFormatter,
} from "../../util/customFunctions";

var D3StackedAreaChart = {};
D3StackedAreaChart.create = (
  el,
  { data, goalData, type, quarter, uniqueQuarters }
) => {
  const goalArr = goalData;

  // console.log(d3.select(el).node().getBoundingClientRect(), 'ELtl')
  var margin = { top: 20, right: 50, bottom: 110, left: 55 },
    height = 400 - margin.top - margin.bottom,
    width =
      d3.select(el).node().getBoundingClientRect().width -
      margin.left -
      margin.right; // fix the width make it dynamic

  var svg = d3
    .select(el)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  // data.sort((a, b) =>
  //   a.Qualified_Date_Initial_Fiscal_Quarter.localeCompare(
  //     b.Qualified_Date_Initial_Fiscal_Quarter
  //   )
  // );

  // Process data
  var sumstat = d3
    .nest()
    .key(function (d) {
      return d[quarter];
    })
    .key(function (d) {
      return d[type];
    })
    .rollup(function (v) {
      return d3.sum(v, (d) => d.runningACV);
    })
    .entries(data)
    .map(function (d) {
      return {
        key: d.key,
        values: d.values.map(function (v) {
          return { key: v.key, value: v.value };
        }),
      };
    });
  var tooltip = d3
    .select(el)
    .append("div")
    .attr("class", "tooltip-line-chart")
    .style("opacity", 0);
  var subgroups = data
    .map((d) => d[type])
    .filter((value, index, self) => self.indexOf(value) === index);

  // console.log(subgroups, 'SUBGROUPS')

  // Stack the data
  var stackedData = d3
    .stack()
    .keys(subgroups)
    .value((d, key) => {
      let val = d.values.find((sub) => sub.key === key);
      return val ? val.value : 0;
    })(sumstat);

  // Set up the x-scale
  var x = d3
    .scalePoint()
    .domain(data.map((d) => d[quarter]))
    .range([0, width]);

  // Set up the right y-scale
  const max1 = d3.max(goalArr.map((f) => f.Goal));
  const max2 = data.map((f) => f.acv).reduce((a, b) => a + b, 0);
  // console.log(max1, max2, 'MAXX')
  const maxVal = Math.max(max1, max2); // Assuming 'totalActual' is the property for the right y-axis
  const formatMillionDollar = (d) => `$${d3.format(".1f")(d / 1e6)}M`;
  var yRight = d3
    .scaleLinear()
    .domain([0, maxVal * 1.2])
    .range([height, 0]);

  // Draw the right y-axis
  // svg.append("g")
  //   //
  //   .call(d3.axisRight(yRight).tickFormat(formatMillionDollar))
  svg
    .append("g")
    .attr("transform", `translate(${width},0)`)
    .call(d3.axisRight(yRight).tickFormat(formatMillionDollar));

  // Draw the x-axis
  svg
    .append("g")
    .attr("transform", `translate(0,${height})`)
    .call(d3.axisBottom(x).tickFormat(customDateFormatter))
    .selectAll("text")
    .style("text-anchor", (d) => (uniqueQuarters.length >= 11 ? "end" : null)) // Set text anchor to end
    .attr("transform", (d) =>
      uniqueQuarters.length >= 11 ? "rotate(-45)" : null
    ); // Rotate the text

  // Set up the y-scale

  // console.log(maxVal, 'MaxVal')
  var y = d3
    .scaleLinear()
    .domain([0, maxVal * 1.2])
    .range([height, 0]);

  // Format the y-axis labels

  // Draw the y-axis
  svg.append("g").call(d3.axisLeft(y).tickFormat(formatMillionDollar));

  // Color palette
  var color = d3.scaleOrdinal().domain(subgroups).range(updatedScheme10);

  // Draw the stacked areas
  svg
    .selectAll("mylayers")
    .data(stackedData)
    .enter()
    .append("path")
    .style("fill", (d) => color(d.key))
    .attr(
      "d",
      d3
        .area()
        .x((d) => x(d.data.key))
        .y0((d) => y(d[0]))
        .y1((d) => y(d[1]))
    );
  var lineAndDots = svg
    .append("g")
    .attr("class", "line-and-dots")
    .attr("transform", "translate(" + 0 + "," + 0 + ")");
  // Add the line
  lineAndDots
    .append("path")
    .datum(goalArr)
    .attr("fill", "none")
    .attr("stroke", "#5B9BD5")
    .attr("stroke-width", 3)
    .attr(
      "d",
      d3
        .line()
        .x(function (d) {
          return x(d[quarter]);
        })
        .y(function (d) {
          return y(d.Goal);
        })
    );
  lineAndDots
    .selectAll("line-circle")
    .data(goalArr)
    .enter()
    .append("circle")
    .attr("class", "data-circle")
    .attr("fill", "#5B9BD5")
    .attr("r", 5)
    .attr("cx", function (d) {
      return x(d[quarter]);
    })
    .attr("cy", function (d) {
      return y(d.Goal);
    })
    .style("cursor", "pointer")
    .on("mouseover", (d) => {
      tooltip.transition().duration(200).style("opacity", 1);
      tooltip
        .html(
          `
        <div id="tooltip" class="tooltip-container">
    <div class="tooltip-box-scatter" style="border-top:10px solid #5B9BD5;border-radius:0.3rem;">              
    <h4 class="heading-style">${customDateFormatter(d[quarter])}</h4>
    <div class="tooltip-grid-container">
    <div class="flex-item" style="color:gray;">Cumulative Goal:</div>
    <div class="flex-item" style="text-align: right">${countOrACVFormatter(
      d.Goal,
      true
    )}</div>
  </div>
  </div>`
        )
        .style(
          "left",
          `${
            d3.select(el).node().getBoundingClientRect().width < 800
              ? d3.event.pageX < window.innerWidth * 0.55
                ? `calc(${d3.event.pageX + "px"})`
                : `calc(${d3.event.pageX + "px"}) - 20%`
              : `calc(${d3.event.pageX + "px"} - 15%)`
          }`
        )
        .style("top", `calc(${d3.event.pageY + "px"} - 3%)`);
    })
    .on("mouseout", (d) => {
      tooltip.transition().duration(300).style("opacity", 0);
    });

  // Add legend
  var legend = svg
    .selectAll(".legend")
    .data(subgroups)
    .enter()
    .append("g")
    .attr("class", "legend")
    .attr("transform", function (d, i) {
      return (
        "translate(" +
        i * (width / subgroups.length) +
        "," +
        (height + margin.top + 70) +
        ")"
      );
    });

  legend
    .append("rect")
    .attr("x", 0)
    .attr("width", 12)
    .attr("height", 12)
    .style("fill", color);

  legend
    .append("text")
    .attr("x", 24)
    .attr("y", 9)
    .attr("dy", ".35em")
    .text(function (d) {
      return d;
    });

  svg
    .append("text")
    .attr("x", width / 2) // Center the label horizontally
    .attr("y", height + margin.top + 50) // Position the label below the x-axis
    .style("text-anchor", "middle") // Center the text
    .text("Week Ending"); // Set the text
};

D3StackedAreaChart.destroy = (el) => {
  // Cleaning code here
  d3.select(el).selectAll("svg").remove();
  d3.select(".tooltip").remove();
  // d3.select('.legend').remove();
};

export default D3StackedAreaChart;
