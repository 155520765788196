import { Card, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import MQOTable from "./MQOTable";
import Drilldown from "./Drilldown";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import { customDateFormatter } from "../../util/customFunctions";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";

const styles = (theme) => ({
  gridItem: {
    width: "100%",
    padding: "1rem",
  },
});

const classes = styles();

const MQO = (props) => {
  const [location, setlLocation] = useState("");
  const [secondLevelLocation, setSecondLevelLocation] = useState("");
  const [trailingAvarage, setTrailingAvarage] = useState(false);
  const [filtersForDrilldown, setFiltersForDrilldown] = useState([]);
  const [time, setTime] = useState("");

  useEffect(() => {
    console.log(
      props.filters,
      props.filters === undefined || props.filters === null,
      ![undefined, null].includes(props.filters)
    );
    if (![undefined, null, ""].includes(props.filters)) {
      props.getMQOData(props.filters);
    }
  }, [props.filters]);

  const handleChangeLocationBack = (location) => {
    setlLocation("");
    setSecondLevelLocation("");
  };

  const handleSecondLevelDrilldown = (location, oppId) => {
    // setlLocation(location);
    setSecondLevelLocation(location);

    props.getOpenOppData(oppId);
  };

  const stepBack = () => {
    // setlLocation("");
    setSecondLevelLocation("");
    // props.clearExpectedAcvData();
  };

  const handleChangeLocation = (location, custType, team, key) => {
    var filters = [...props.filters];
    var drilldownfilter = [{ title: "Customer Type", value: custType }];
    const type = ["New Customer", "New Engagement"].includes(custType)
      ? "New"
      : ["Existing Customer", "Existing Client"].includes(custType)
      ? "Existing"
      : "Total";
    if (team !== undefined) {
      var filter =
        props.selectedTeam.includes("All") && props.selectedRep.includes("All")
          ? { name: "Team", value: team }
          : { name: "Owner_Name", value: team };
      filters = [...filters, filter];
      drilldownfilter = [
        {
          title:
            props.selectedTeam.includes("All") &&
            props.selectedRep.includes("All")
              ? "Team"
              : "Rep",
          value: team.length > 1 ? ["All"] : team,
        },
        ...drilldownfilter,
      ];
    } else {
      drilldownfilter = [
        {
          title:
            (props.selectedTeam.includes("All") &&
              props.selectedRep.includes("All")) ||
            (!props.selectedTeam.includes("All") &&
              props.selectedRep.includes("All"))
              ? "Team"
              : "Rep",
          value:
            !props.selectedTeam.includes("All") &&
            props.selectedRep.includes("All")
              ? props.selectedTeam
              : props.selectedTeam.includes("All") &&
                !props.selectedRep.includes("All")
              ? props.selectedRep
              : ["All"],
        },
        ...drilldownfilter,
      ];
    }
    setTime(key);
    setlLocation(location);
    setFiltersForDrilldown(drilldownfilter);
    props.getMQODrilldown(filters, type, key);
  };
  console.log(props);
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12}>
        {props.MQOData !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            <Typography
              variant="h2"
              style={{ width: "100%", margin: "auto", marginTop: "1rem" }}
              align="center"
            >
              {props.MQOData.title ? props.MQOData.title : props.title}
            </Typography>
            <MQOTable
              selectedTeam={props.selectedTeam}
              selectedRep={props.selectedRep}
              handleChangeLocation={handleChangeLocation}
              dataTest={props.title}
              MQOData={props.MQOData}
              typesOfCustomers={props.MQOData.uniqueCustType}
              isrepScoreCard={props.isrepScoreCard}
            />
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
      </Grid>
      {location === "drilldownOnGraph" && (
        <Grid
          item
          xs={12}
          style={{ position: "absolute", width: "100%", marginTop: "30px" }}
        >
          <SingleLevelDrilldown
            header={`MQOs generated in the last ${time} days`}
            back={handleChangeLocationBack}
            filters={filtersForDrilldown}
          >
            <Drilldown
              header={`MQOs generated in the last ${time} days`}
              body={props.drilldownOnGraphData}
              handleSecondLevelDrilldown={handleSecondLevelDrilldown}
              Value_Label={props.Value_Label}
            />
          </SingleLevelDrilldown>
        </Grid>
      )}
      {secondLevelLocation === "drilldownOppId" && (
        <Grid
          item
          xs={12}
          style={{
            position: "absolute",
            width: "100%",
            marginTop: "30px",
          }}
        >
          <SingleLevelDrilldown
            header={"Opportunity Change Events"}
            back={handleChangeLocationBack}
            stepBack={stepBack}
            filters={[]}
          >
            <ExpectedAcvPage body={props.ExpectedAcvData} />
          </SingleLevelDrilldown>
        </Grid>
      )}
    </Grid>
  );
};
const mapStateToProps = (state) => {
  const { MQOData, user, drilldownOnGraphData, ExpectedAcvData } = state.app;

  return {
    MQOData,
    user,
    drilldownOnGraphData,
    Value_Label: user.Value_Label,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getMQOData: (filters) => {
    dispatch({ type: `get_scorecard_mqo_data_request` });
    appService.getMQOData(filters).then(
      (json) => {
        dispatch({ type: `get_scorecard_mqo_data_success`, json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_scorecard_mqo_data_failure`,
            error: "Something went wrong",
          });
        else dispatch({ type: "get_scorecard_mqo_data_failure", error });
      }
    );
  },
  getMQODrilldown: (filters, type, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getMQODrilldown(filters, type, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.ACV > b.ACV ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
});
const MQOSection = connect(mapStateToProps, mapDispatchToProps)(MQO);
export default MQOSection;
