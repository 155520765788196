import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Button, colors } from '@material-ui/core'
import { store } from './util/store';
import { App } from './App/App';
import { SnackbarProvider } from 'notistack';
import './index.css';
import ErrorBoundary from './components/ErrorBoundary';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {BrowserRouter} from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';
import SkyGeniRouter from './App/SkyGeniRouter';

const theme = createMuiTheme({
    typography: {
        //fontSize: '1rem',
        h1: {
            fontSize: '1.5rem',
            fontWeight: 500
        },
        h2: {
            fontSize: '1.2rem',
            fontWeight: 500
        },
        body1: {
            fontSize: '1rem',
        },
        h4: {
            fontSize: '0.85rem'
        },

    },
    palette: {
        primary: {
            main: '#4472c4',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#000000'
        }
    },
    overrides: {
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: '#4472c4',
                    color: 'white',

                },
                '&$selected:hover': {
                    //old color 2f4f89
                    backgroundColor: '#2f4f89',
                    color: 'white'
                }
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit'
            }
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: '#4472c4'
            }
        },
        MuiFormControlLabel: {
            label: {
                //'line-height': '1.43',
                //'letter-spacing': '0.01071em',
                //fontSize: '0.875rem'
            }
        },
        MuiInputLabel: {
            root: {
                whiteSpace: 'nowrap'
            }
        },
        MuiTableCell: {
            root: {
                fontSize: '0.85rem',
            }

        },
        MuiButton: {
            label: {
                fontSize: '1rem',
            }
        },
        MuiCardHeader: {
            content: {
                fontSize: '1rem',
                fontWeight: 600
            }
        },
        MuiInputLabel: {
            shrink: {
                transform: 'translate(0, 1.5px) scale(0.9)',
                color: 'black'
            }
        },
        MuiSnackbarContent: {
            message: {
                fontSize: '1rem'
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: '1.65rem'
            }
        }
    },
});

render(
    <ErrorBoundary>
        <BrowserRouter>
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <SnackbarProvider maxSnack={2} dense disableWindowBlurListener={true} preventDuplicate={true} autoHideDuration={2000}>
                    <SkyGeniRouter />
                </SnackbarProvider>
            </Provider>
        </MuiThemeProvider>
        </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById('root')
);
// serviceWorkerRegistration.register({
//     onSuccess: () => store.dispatch({ type: "SW_INIT" }),
//     onUpdate: reg => store.dispatch({ type: "SW_UPDATE", payload: reg }),
// });

serviceWorkerRegistration.unregister()