import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import Tooltip from "@material-ui/core/Tooltip";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  customDateFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";

export default function NQPTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const [isOverflowing, setIsOverflowing] = useState(false);

  console.log(props.acvColumn);
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgb(255,255,255,0.8)",
      color: "black",
      fontFamily: "roboto",
      fontSize: theme.typography.pxToRem(14),
      borderRadius: "1rem",
      padding: "0px",
      boxShadow: "0 0.1rem 0.1rem 0",
      marginTop: "0.4rem",
    },
  }))(Tooltip);

  const nqpGoalTooltip = (team, goal) => {
    return (
      <>
        {Array(1)
          .fill()
          .map((item) => (
            <>
              <div
                style={{
                  borderTop: "solid #4471c4",
                  borderRadius: "1rem",
                  padding: "0px",
                  margin: 0,
                  borderWidth: "0.6rem",
                }}
              >
                <div
                  className="tooltip-box"
                  style={{ padding: "0px", fontSize: "1rem" }}
                >
                  <h3 className="heading-style" style={{ padding: "0.5rem" }}>
                    {team}
                  </h3>
                  <div
                    style={{ paddingLeft: "0.8rem", paddingRight: "0.8rem" }}
                  >
                    <hr />
                  </div>
                  <div
                    className="container"
                    style={{ padding: "0.5rem", fontSize: "0.8rem" }}
                  >
                    <h3 className="result-style">
                      {" "}
                      Goal :{" "}
                      <span style={{ color: "rgb(31,119,180)" }}>
                        {countOrACVFormatter(goal, true)}
                      </span>{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </>
          ))}
      </>
    );
  };

  props.trailingFourWeeks.sort();
  const uniqueReps = props.uniqueCustomerSegments
    ? props.uniqueCustomerSegments
    : props.trailingFourWeeksData
        .map(
          (rep) =>
            rep[
              props.selectedTeam.includes("All") &&
              props.selectedRep.includes("All")
                ? "Team"
                : "Owner_Name"
            ]
        )
        .filter(getUniqueValues)
        .sort();
  const uniqueWeeks = props.uniqueWeeks;

  var segmentThreshold;
  if (props.thresholds !== undefined) {
    if (
      props.selectedTeam.includes("All") &&
      props.selectedRep.includes("All")
    ) {
      segmentThreshold = props.thresholds.filter((i) => i.Team === "Company")[0]
        .Threshold;
    } else if (!props.selectedTeam.includes("All")) {
      segmentThreshold = props.thresholds
        .filter((i) => props.selectedTeam.includes(i.Team))
        .reduce((a, b) => a + b.Threshold, 0);
    } else if (!props.selectedRep.includes("All")) {
      segmentThreshold = props.thresholds.filter((i) => i.Team === "Rep")[0]
        .Threshold;
    }
  }

  const thresholdHandler = (acv, rep) => {
    var color = "";
    if (props.thresholds !== undefined && rep !== undefined) {
      if (
        props.selectedTeam.includes("All") &&
        props.selectedRep.includes("All")
      ) {
        console.log(props, acv, rep);
        color =
          props.thresholds.filter((i) => i.Team === rep)[0].Threshold < acv
            ? "#E2EFDA"
            : "";
      } else if (!props.selectedTeam.includes("All")) {
        color =
          props.thresholds.filter((i) => i.Team === "Rep")[0].Threshold < acv
            ? "#E2EFDA"
            : "";
      } else if (!props.selectedRep.includes("All")) {
        color =
          props.thresholds.filter((i) => i.Team === "Rep")[0].Threshold < acv
            ? "#E2EFDA"
            : "";
      }
    }
    return color;
  };
  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainerNQP");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);
  return (
    <Grid item xs={12}>
      {isOverflowing ? (
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            // marginBottom: "10px",
            // marginRight: "2rem",
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
      ) : null}
      <TableContainer
        style={{ padding: "1rem", paddingBottom: "3rem" }}
        id="myTableContainerNQP"
      >
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
          id={props.dataID}
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow
              style={{
                fontWeight: 600,
                background: "#4472c4",
                color: "white",
                paddingLeft: 5,
                height: 60,
              }}
            >
              <TableCell
                className={classes.colHeader}
                style={{
                  background: "white",
                  color: "black",
                  width: "16rem",
                }}
              >
                {props.firstColumn}
              </TableCell>
              {props.thresholdFlag && props.showOrange && (
                <TableCell
                  className={classes.colHeader}
                  style={{
                    background: "white",
                    color: "black",
                  }}
                  align="center"
                >
                  Goal
                </TableCell>
              )}
              {props.trailingFourWeeks.map((week, index) => (
                <TableCell
                  colSpan={3}
                  style={
                    index % 2 === 0
                      ? { background: "#4471c4", color: "white" }
                      : { background: "#5b9bd5", color: "white" }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {customDateFormatter(week)}
                </TableCell>
              ))}
              {props.firstColumn === "Half-Month Ending" ? (
                <TableCell
                  colSpan={3}
                  style={
                    props.trailingFourWeeks.length > 1 &&
                    props.trailingFourWeeks.length % 2 === 0
                      ? { background: "#4471c4", color: "white" }
                      : { background: "#5b9bd5", color: "white" }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {props.lastColumn}
                  <br />
                  <span>(per half-month)</span>
                </TableCell>
              ) : (
                <TableCell
                  colSpan={3}
                  style={
                    props.trailingFourWeeks.length > 1 &&
                    props.trailingFourWeeks.length % 2 === 0
                      ? { background: "#4471c4", color: "white" }
                      : { background: "#5b9bd5", color: "white" }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {props.lastColumn}
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell
                className={classes.colHeader}
                style={{ background: "white", color: "black" }}
              ></TableCell>
              {props.thresholdFlag && props.showOrange && (
                <TableCell
                  className={classes.colHeader}
                  style={{
                    background: "white",
                    color: "black",
                  }}
                  align="center"
                ></TableCell>
              )}
              {props.trailingFourWeeks.map((week, index) => (
                <>
                  <TableCell align="center" className={classes.colHeader}>
                    #
                  </TableCell>
                  <TableCell align="center" className={classes.colHeader}>
                    {props.acvColumnName}
                  </TableCell>
                  <TableCell align="center" className={classes.colHeader}>
                    {/* Avg. ACV */}
                    {`Avg. ${props.Value_Label}`}
                  </TableCell>
                </>
              ))}
              {/* 4 week avg col */}
              <>
                <TableCell align="center" className={classes.colHeader}>
                  #
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {props.acvColumnName}
                </TableCell>
                <TableCell align="center" className={classes.colHeader}>
                  {/* Avg. ACV */}
                  {`Avg. ${props.Value_Label}`}
                </TableCell>
              </>
            </TableRow>
            {!props.isrepScoreCard && (
              <StyledTableRow key={"Segment"} style={{ fontWeight: 600 }}>
                <TableCell
                  align="left"
                  className={classes.dataCell}
                  style={{ width: "16rem" }}
                >
                  {props.selectedTeam.includes("All") &&
                  props.selectedRep.includes("All")
                    ? "Company"
                    : !props.selectedTeam.includes("All")
                    ? props.selectedTeam.length > 1
                      ? "Teams"
                      : "Team"
                    : "Total"}
                </TableCell>
                {props.thresholdFlag && props.showOrange && (
                  <TableCell align="right" className={classes.dataCell}>
                    {props.traillingFourWeeksDataSegment.length > 0
                      ? countOrACVFormatter(
                          props.traillingFourWeeksDataSegment[0].nqpGoal,
                          true
                        )
                      : 0}
                  </TableCell>
                )}
                {props.trailingFourWeeks.map((week, idx) => {
                  let cellDataCount =
                    props.traillingFourWeeksDataSegment.filter(
                      (item) =>
                        item[props.frequencyChurnedStageChangeColumn] === week
                    )[0].count;
                  let cellDataACV = props.traillingFourWeeksDataSegment.filter(
                    (item) =>
                      item[props.frequencyChurnedStageChangeColumn] === week
                  )[0][props.acvColumn];
                  let cellDataAvg = _isNaN(cellDataACV / cellDataCount)
                    ? 0
                    : cellDataACV / cellDataCount;
                  let cellDataThreshold =
                    props.traillingFourWeeksDataSegment.filter(
                      (item) =>
                        item[props.frequencyChurnedStageChangeColumn] === week
                    );
                  cellDataThreshold =
                    cellDataThreshold.length > 0
                      ? ![null, undefined, ""].includes(
                          cellDataThreshold[0]["nqpGoal"]
                        )
                        ? cellDataThreshold[0]["nqpGoal"]
                        : 0
                      : 0;
                  return (
                    <>
                      <TableCell
                        align="right"
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            [week],
                            uniqueReps,
                            false
                          )
                        }
                        className={classes.drilldownCell}
                      >
                        {countOrACVFormatter(cellDataCount, false)}
                      </TableCell>
                      {props.thresholdFlag === "Y" ? (
                        cellDataThreshold > 0 ? (
                          <HtmlTooltip
                            title={nqpGoalTooltip(
                              props.selectedTeam.includes("All") &&
                                props.selectedRep.includes("All")
                                ? "Company"
                                : !props.selectedTeam.includes("All")
                                ? props.selectedTeam.length > 1
                                  ? "Teams"
                                  : "Team"
                                : "Total",
                              cellDataThreshold
                            )}
                          >
                            <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  [week],
                                  uniqueReps,
                                  false
                                )
                              }
                              className={classes.drilldownCell}
                              style={{
                                background:
                                  cellDataThreshold <= cellDataACV
                                    ? "#E2EFDA"
                                    : props.showOrange && cellDataACV <= 0
                                    ? "#F4B183"
                                    : "",
                              }}
                            >
                              {countOrACVFormatter(cellDataACV, true)}
                            </TableCell>
                          </HtmlTooltip>
                        ) : (
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                [week],
                                uniqueReps,
                                false
                              )
                            }
                            className={classes.drilldownCell}
                          >
                            {countOrACVFormatter(cellDataACV, true)}
                          </TableCell>
                        )
                      ) : (
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              [week],
                              uniqueReps,
                              false
                            )
                          }
                          className={classes.drilldownCell}
                        >
                          {countOrACVFormatter(cellDataACV, true)}
                        </TableCell>
                      )}

                      <TableCell
                        align="right"
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            [week],
                            uniqueReps,
                            false
                          )
                        }
                        className={classes.drilldownCell}
                      >
                        {countOrACVFormatter(cellDataAvg, true)}
                      </TableCell>
                    </>
                  );
                })}

                {/* 4 week avg col */}
                {
                  <>
                    <TableCell
                      align="right"
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          uniqueWeeks,
                          uniqueReps,
                          true
                        )
                      }
                      className={classes.drilldownCell}
                    >
                      {props.traillingEightWeeksSegmentAverage.count.toFixed(1)}
                    </TableCell>
                    {props.thresholdFlag === "Y" ? (
                      props.traillingEightWeeksSegmentAverage.nqpGoal > 0 ? (
                        <HtmlTooltip
                          title={nqpGoalTooltip(
                            props.selectedTeam.includes("All") &&
                              props.selectedRep.includes("All")
                              ? "Company"
                              : !props.selectedTeam.includes("All")
                              ? props.selectedTeam.length > 1
                                ? "Teams"
                                : "Team"
                              : "Total",
                            props.traillingEightWeeksSegmentAverage.nqpGoal
                          )}
                        >
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                uniqueWeeks,
                                uniqueReps,
                                true
                              )
                            }
                            className={classes.drilldownCell}
                            style={{
                              background:
                                props.traillingEightWeeksSegmentAverage
                                  .nqpGoal <=
                                props.traillingEightWeeksSegmentAverage[
                                  props.acvColumn
                                ]
                                  ? "#E2EFDA"
                                  : props.showOrange &&
                                    props.traillingEightWeeksSegmentAverage[
                                      props.acvColumn
                                    ] <= 0
                                  ? "#F4B183"
                                  : "",
                            }}
                          >
                            {countOrACVFormatter(
                              props.traillingEightWeeksSegmentAverage[
                                props.acvColumn
                              ],
                              true
                            )}
                          </TableCell>
                        </HtmlTooltip>
                      ) : (
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              uniqueWeeks,
                              uniqueReps,
                              true
                            )
                          }
                          className={classes.drilldownCell}
                        >
                          {countOrACVFormatter(
                            props.traillingEightWeeksSegmentAverage[
                              props.acvColumn
                            ],
                            true
                          )}
                        </TableCell>
                      )
                    ) : (
                      <TableCell
                        align="right"
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            uniqueWeeks,
                            uniqueReps,
                            true
                          )
                        }
                        className={classes.drilldownCell}
                      >
                        {countOrACVFormatter(
                          props.traillingEightWeeksSegmentAverage[
                            props.acvColumn
                          ],
                          true
                        )}
                      </TableCell>
                    )}

                    <TableCell
                      align="right"
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          uniqueWeeks,
                          uniqueReps,
                          true
                        )
                      }
                      className={classes.drilldownCell}
                    >
                      {countOrACVFormatter(
                        _isNaN(
                          props.traillingEightWeeksSegmentAverage[
                            props.acvColumn
                          ] / props.traillingEightWeeksSegmentAverage.count
                        )
                          ? 0
                          : props.traillingEightWeeksSegmentAverage[
                              props.acvColumn
                            ] / props.traillingEightWeeksSegmentAverage.count,
                        true
                      )}
                    </TableCell>
                  </>
                }
              </StyledTableRow>
            )}
            {uniqueReps.map((rep) => {
              console.log(props.trailingEightWeeksAverage);
              let averageData = props.trailingEightWeeksAverage.filter(
                (item) =>
                  item[
                    props.type === "Segment"
                      ? "Acct_Segment"
                      : props.type === "Deal"
                      ? "deal_category__c"
                      : props.selectedTeam.includes("All") &&
                        props.selectedRep.includes("All")
                      ? "Team"
                      : "Owner_Name"
                  ] === rep
              )[0];
              let averageDataEightWeeks;
              //   if (props.company === "C0001" || props.company === "C0004") {
              //     averageDataEightWeeks = props.trailingEightWeeksAverage.filter(item => item[props.team === "All" ? "Team" : "Owner_Name"] === rep)[0];
              //     averageDataEightWeeks = averageDataEightWeeks === undefined ? { count: 0, acv: 0 } : averageDataEightWeeks;
              //   }
              averageData =
                averageData === undefined
                  ? { count: 0, acv: 0, nqpGoal: 0, currentACV: 0 }
                  : averageData;

              const goals = props.trailingFourWeeksData.filter(
                (item) =>
                  item[
                    props.type === "Segment"
                      ? "Acct_Segment"
                      : props.type === "Deal"
                      ? "deal_category__c"
                      : props.selectedTeam.includes("All") &&
                        props.selectedRep.includes("All")
                      ? "Team"
                      : "Owner_Name"
                  ] === rep
              );

              const goal = goals.length > 0 ? goals[0].nqpGoal : 0;

              return (
                <StyledTableRow key={rep} style={{ fontWeight: 600 }}>
                  <TableCell
                    style={{ width: "16rem", paddingLeft: "1.75rem" }}
                    align="left"
                    className={classes.colHeader}
                  >
                    {rep}
                  </TableCell>
                  {props.thresholdFlag && props.showOrange && (
                    <TableCell align="right" className={classes.colHeader}>
                      {countOrACVFormatter(goal, true)}
                    </TableCell>
                  )}
                  {props.trailingFourWeeks.map((week, idx) => {
                    let cellData = props.trailingFourWeeksData.filter(
                      (item) =>
                        item[props.frequencyChurnedStageChangeColumn] ===
                          week &&
                        item[
                          props.type === "Segment"
                            ? "Acct_Segment"
                            : props.type === "Deal"
                            ? "deal_category__c"
                            : props.selectedTeam.includes("All") &&
                              props.selectedRep.includes("All")
                            ? "Team"
                            : "Owner_Name"
                        ] === rep
                    );
                    cellData =
                      cellData.length === 0
                        ? { acv: 0, count: 0, nqpGoal: 0, currentACV: 0 }
                        : cellData[0];
                    return (
                      <>
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              [week],
                              [rep],
                              false
                            )
                          }
                          className={classes.drilldownCell}
                        >
                          {countOrACVFormatter(cellData.count, false)}
                        </TableCell>
                        {props.thresholdFlag === "Y" ? (
                          cellData.nqpGoal > 0 ? (
                            <HtmlTooltip
                              title={nqpGoalTooltip(rep, cellData.nqpGoal)}
                            >
                              <TableCell
                                align="right"
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    [week],
                                    [rep],
                                    false
                                  )
                                }
                                className={classes.drilldownCell}
                                style={{
                                  background:
                                    cellData.nqpGoal <=
                                    cellData[props.acvColumn]
                                      ? "#E2EFDA"
                                      : props.showOrange &&
                                        cellData[props.acvColumn] <= 0
                                      ? "#F4B183"
                                      : "",
                                }}
                              >
                                {countOrACVFormatter(
                                  cellData[props.acvColumn],
                                  true
                                )}
                              </TableCell>
                            </HtmlTooltip>
                          ) : (
                            <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  [week],
                                  [rep],
                                  false
                                )
                              }
                              className={classes.drilldownCell}
                            >
                              {countOrACVFormatter(
                                cellData[props.acvColumn],
                                true
                              )}
                            </TableCell>
                          )
                        ) : (
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                [week],
                                [rep],
                                false
                              )
                            }
                            className={classes.drilldownCell}
                          >
                            {countOrACVFormatter(
                              cellData[props.acvColumn],
                              true
                            )}
                          </TableCell>
                        )}
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              [week],
                              [rep],
                              false
                            )
                          }
                          className={classes.drilldownCell}
                        >
                          {countOrACVFormatter(
                            _isNaN(cellData[props.acvColumn] / cellData.count)
                              ? 0
                              : cellData[props.acvColumn] / cellData.count,
                            true
                          )}
                        </TableCell>
                      </>
                    );
                  })}
                  {/* 4 week avg col */}
                  {
                    <>
                      <TableCell
                        align="right"
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            uniqueWeeks,
                            [rep],
                            true
                          )
                        }
                        className={classes.drilldownCell}
                      >
                        {averageData.count.toFixed(1)}
                      </TableCell>
                      {props.thresholdFlag === "Y" ? (
                        averageData.nqpGoal > 0 ? (
                          <HtmlTooltip
                            title={nqpGoalTooltip(rep, averageData.nqpGoal)}
                          >
                            <TableCell
                              align="right"
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  uniqueWeeks,
                                  [rep],
                                  true
                                )
                              }
                              className={classes.drilldownCell}
                              style={{
                                background:
                                  averageData.nqpGoal <=
                                  averageData[props.acvColumn]
                                    ? "#E2EFDA"
                                    : props.showOrange &&
                                      averageData[props.acvColumn] <= 0
                                    ? "#F4B183"
                                    : "",
                              }}
                            >
                              {countOrACVFormatter(
                                averageData[props.acvColumn],
                                true
                              )}
                            </TableCell>
                          </HtmlTooltip>
                        ) : (
                          <TableCell
                            align="right"
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                uniqueWeeks,
                                [rep],
                                true
                              )
                            }
                            className={classes.drilldownCell}
                          >
                            {countOrACVFormatter(
                              averageData[props.acvColumn],
                              true
                            )}
                          </TableCell>
                        )
                      ) : (
                        <TableCell
                          align="right"
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              uniqueWeeks,
                              [rep],
                              true
                            )
                          }
                          className={classes.drilldownCell}
                        >
                          {countOrACVFormatter(
                            averageData[props.acvColumn],
                            true
                          )}
                        </TableCell>
                      )}
                      <TableCell
                        align="right"
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            uniqueWeeks,
                            [rep],
                            true
                          )
                        }
                        className={classes.drilldownCell}
                      >
                        {countOrACVFormatter(
                          _isNaN(
                            averageData[props.acvColumn] / averageData.count
                          )
                            ? 0
                            : averageData[props.acvColumn] / averageData.count,
                          true
                        )}
                      </TableCell>
                    </>
                  }
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
