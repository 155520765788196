import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
// import tableStyle from '../../../CustomerSuccess/styles/tableStyle'
import {
  StyledTableRow,
  _isNaN,
  getUniqueValues,
} from "../../../util/customFunctions";
import PrimaryColorTableHeader from "./PrimaryColorTableHeader";
import Legend from "../../AsOfDate/Legend";
import DimensionTableTooltip from "./DimensionTableTooltip";

import { makeStyles } from "@material-ui/core";
import CopyTablesObject from "../../../components/xlsx-export/CopyTablesObject";
import { replaceWinRateLabel } from "../../../components/xlsx-export/LabelCange";

const tableStyle = makeStyles(
  (theme) => ({
    root: {
      margin: "0.5rem",
      padding: "0.5rem",
    },
    colHeaderNew: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      backgroundColor: "inherit",
    },
    colHeader: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: 600,
    },
    dataCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
    },
    drilldownCell: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      cursor: "pointer",
    },
    drilldownCellNQP: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: "3%",
      fontWeight: "inherit",
      cursor: "pointer",
    },
    cellMargin: {
      fontSize: "0.85rem",
      border: "0.5px solid lightgrey",
      padding: "7px 10px",
      color: "inherit",
      width: 120,
      fontWeight: "inherit",
      paddingLeft: "2rem",
    },
    fontBold: {
      fontWeight: 600,
    },
    indentName: {
      paddingLeft: 30,
    },
    noBorder: {
      border: "none",
    },
    primaryColumnColor: {
      backgroundColor: "#4472C4",
      color: "white",
    },
    secondaryColumnColor: {
      backgroundColor: "#70ad47",
      color: "white",
    },
    tertiaryColumnColor: {
      backgroundColor: "#bf8f00",
      color: "white",
    },
  }),
  { index: 1 }
);

const ExpectedAcvCategory = (props) => {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const uniqueCategories = props.data
    .filter((i) => i.category == null)
    .sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12}>
        <TableContainer>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div> */}
          <Table ref={tableRef} data-test={"expected-yield"}>
            <TableBody>
              <TableRow
                className={`${classes.primaryColumnColor} ${classes.fontBold}`}
              >
                <PrimaryColorTableHeader title="Category" width="13%" />
                <PrimaryColorTableHeader title="Dimension" width="47%" />
                <PrimaryColorTableHeader title="Value" width="22%" />
                <PrimaryColorTableHeader
                  title="Probability Sub-Score"
                  width="8%"
                />
                <PrimaryColorTableHeader title="Probability Score" width="8%" />
              </TableRow>

              {uniqueCategories.map((cell, j) => {
                const dimensions = props.data
                  .filter(
                    (i) =>
                      i.category === cell.dimension &&
                      i.value !== undefined &&
                      i.score !== undefined
                  )
                  .sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
                return (
                  <React.Fragment key={j}>
                    {dimensions.length > 0 && (
                      <StyledTableRow
                        key={j}
                        style={{
                          background: cell.isLimitingFactor ? "#fbe5d6ff" : "",
                        }}
                      >
                        <TableCell
                          className={classes.dataCell}
                          rowSpan={dimensions.length}
                          align="center"
                        >
                          {cell.dimension}
                        </TableCell>
                        <TableCell className={classes.dataCell} align="left">
                          {dimensions[0].dimension}
                        </TableCell>
                        <TableCell
                          className={classes.dataCell}
                          align={_isNaN(dimensions[0].value) ? "left" : "right"}
                        >{`${dimensions[0].value} ${dimensions[0].daysFlag}`}</TableCell>
                        <TableCell
                          className={classes.dataCell}
                          align={_isNaN(dimensions[0].score) ? "left" : "right"}
                        >{`${
                          _isNaN(dimensions[0].score)
                            ? dimensions[0].score
                            : Math.round(dimensions[0].score * 100)
                        }`}</TableCell>
                        <TableCell
                          className={classes.dataCell}
                          rowSpan={dimensions.length}
                          align="right"
                        >
                          {cell.score !== undefined
                            ? Math.round(cell.score * 100)
                            : ""}
                        </TableCell>
                      </StyledTableRow>
                    )}
                    {dimensions.length > 1 &&
                      dimensions.slice(1).map((s) => (
                        <StyledTableRow
                          key={j}
                          style={{
                            background: cell.isLimitingFactor
                              ? "#fbe5d6ff"
                              : "",
                          }}
                        >
                          <TableCell className={classes.dataCell} align="left">
                            {/* {s.dimension} */}
                            {replaceWinRateLabel(
                              s.dimension,
                              "Win Rate",
                              `${props.Win_Rate_Prefix}`
                            )}{" "}
                          </TableCell>
                          <TableCell
                            className={classes.dataCell}
                            align={_isNaN(s.value) ? "left" : "right"}
                          >{`${s.value} ${s.daysFlag}`}</TableCell>
                          <TableCell
                            className={classes.dataCell}
                            align={_isNaN(s.score) ? "left" : "right"}
                          >{`${
                            _isNaN(s.score)
                              ? s.score
                              : Math.round(s.score * 100)
                          }`}</TableCell>
                        </StyledTableRow>
                      ))}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ExpectedAcvCategory;
