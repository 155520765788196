import 'date-fns';
import React from 'react';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import './DatePicker_v2.css'


export default function MaterialUIPickers(props) {

  const [selectedDate, setSelectedDate] = React.useState(new Date(Date.now()))

  React.useEffect(() => {
    const date = props.current.split('-')
    setSelectedDate(new Date(date[2], date[0] - 1, date[1]))
  }, [props.current]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (isValidDate(date)) props.setDate(date)
  };
console.log(props)
  const isValidDate = d => d instanceof Date && !isNaN(d)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        allowSameDateSelection
        showToolbar
        disabled={props.disabled}
        inputFormat="MMM-dd-yyyy"
        margin="none"
        id="date-picker-inline"
        label={props.label}
        value={selectedDate}
        disableHighlightToday
        onChange={handleDateChange}
        minDate={props.min}
        maxDate={props.max}
        showToolbar
        shouldDisableDate={props.shouldDisableDate}
        InputLabelProps={{ style: { color: props.disabled ? '' : '#4472c4' } }}
        renderInput={(params) => <TextField  variant="standard"  helperText={props.errorText !== '' ? props.errorText : ''} {...params} />}
      />
    </LocalizationProvider>
  );
}