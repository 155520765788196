import * as d3 from "d3";
import { updatedScheme10 } from "../../util/chartColorScheme";
import { getUniqueValues, _isNaN } from "../../util/customFunctions";
import {
  dataFormatterForDonutChart,
  dataFormatterForDonutChartV2,
} from "../../util/dataFormatter";
import { getMixKey } from "../../util/generateHeadings";
import { store } from "../../util/store";
const D3DonutChart = {};
D3DonutChart.create = (el, config) => {
  const Symbol = store.getState();
  const Currency_Symbol = Symbol.app.user.Currency;

  config.data = config.data.filter((item) => item[config.unit] !== null);
  if (config.data.length > 0) {
    config.data = ["qfq", "qfm"].includes(config.type)
      ? dataFormatterForDonutChartV2(
          config.data,
          config.type,
          config.quarter,
          config.sortedUniqueStages,
          config.unit
        )
      : dataFormatterForDonutChart(
          config.data,
          config.type,
          config.quarter,
          config.sortedUniqueStages,
          config.unit
        );
    const totalUnit = config.data
      .map((item) => item[config.unit])
      .reduce((a, b) => a + b, 0);

    config.margin = {
      top: 10,
      right: 100,
      bottom: 10,
      left: 10,
    };
    config.data = config.data.sort((a, b) => b.acv - a.acv);
    config.width = d3.select(el).node()
      ? d3.select(el).node().getBoundingClientRect().width
      : 100;
    config.height = 500 - config.margin.top - config.margin.bottom;
    var chartContainer = d3
      .select(el)
      .append("svg")
      .attr("width", config.width + config.margin.left)
      .attr(
        "height",
        config.height + config.margin.top + config.margin.bottom + 30
      );
    var chart = chartContainer
      .append("g")
      .attr(
        "transform",
        "translate(" + config.margin.left + "," + config.margin.top + ")"
      );

    chart.append("g").attr("class", "slices");
    chart.append("g").attr("class", "labels");
    chart.append("g").attr("class", "lines");
    var radius = Math.min(config.width, config.height) / 3;

    let uniqueStages = config.sortedUniqueStages;

    var color =
      config.color === undefined
        ? d3.scaleOrdinal(updatedScheme10)
        : config.color;
    color.domain(uniqueStages);

    var pie = d3
      .pie()
      .sort(null)
      .value((d) => d[config.unit]);
    var arc = d3
      .arc()
      .innerRadius(radius * 0.7)
      .outerRadius(radius * 0.35);

    var outerArc = d3
      .arc()
      .outerRadius(radius * 0.9)
      .innerRadius(radius * 0.9);

    chart.attr(
      "transform",
      "translate(" + config.width / 2 + "," + config.height / 2 + ")"
    );
    chart
      .selectAll("path")
      .data(pie(config.data))
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d, i) => color(d.data[config.type]))
      .style("opacity", 0.9)
      .style("cursor", config.isDrilldown === false ? "" : "pointer")
      .attr("data-test", `${getMixKey(config.type)}-doughnut`)
      .on("click", (d) =>
        config.handleChangeLocation(
          "drilldownOnGraph",
          {
            row:
              config.type === "qfq"
                ? d.data["qualified_fiscal_quarter"]
                : config.type === "qfm"
                ? d.data["Qualified_Year_Month"]
                : d.data[config.type],
            cell:
              config.type === "Overall_Health" ||
              config.type === "label" ||
              config.type === "Account_Segment" ||
              config.isMonth === true
                ? config.uniqueQuarters
                : d.data[config.quarter],
            rowKey: "rowTotal",
            rowCell: ["qfq", "qfm"].includes(config.type)
              ? d.data[config.type]
              : "",
          },
          config.type,
          config.type === "Overall_Health" ||
            config.type === "label" ||
            config.type === "Account_Segment"
            ? "rawValsTotalDonut"
            : config.quarter
        )
      );
    chart.append("g").classed("labels", true);
    chart.append("g").classed("lines", true);

    var polyline = chart
      .select(".lines")
      .selectAll("polyline")
      .data(pie(config.data))
      .enter()
      .append("polyline")
      .style("fill", "none")
      .style("stroke", "#bfbfbf")
      .attr("points", function (d) {
        // see label transform function for explanations of these three lines.

        var pos = outerArc.centroid(d);
        pos[0] = radius * 0.9 * (midAngle(d) < Math.PI ? 1 : -1);
        return d.endAngle - d.startAngle > 0.25
          ? d.data[config.unit] === null
            ? null
            : [arc.centroid(d), outerArc.centroid(d), pos]
          : null;
      })
      .style("cursor", config.isDrilldown === false ? "" : "pointer")
      .on("click", (d) =>
        config.handleChangeLocation(
          "drilldownOnGraph",
          {
            row:
              config.type === "qfq"
                ? d.data["qualified_fiscal_quarter"]
                : config.type === "qfm"
                ? d.data["Qualified_Year_Month"]
                : d.data[config.type],
            cell:
              config.type === "Overall_Health" ||
              config.type === "label" ||
              config.type === "Account_Segment" ||
              config.isMonth === true
                ? config.uniqueQuarters
                : d.data[config.quarter],
            rowKey: "rowTotal",
            rowCell: ["qfq", "qfm"].includes(config.type)
              ? d.data[config.type]
              : "",
          },
          config.type,
          config.type === "Overall_Health" ||
            config.type === "label" ||
            config.type === "Account_Segment"
            ? "rawValsTotalDonut"
            : config.quarter
        )
      );

    var label = chart
      .select(".labels")
      .selectAll("text")
      .data(pie(config.data))
      .enter()
      .append("text")
      .attr("data-test", `${getMixKey(config.type)}-doughnut`)
      .attr("dy", ".35rem")
      .html((d) =>
        d.endAngle - d.startAngle > 0.25
          ? d.data[config.unit] === null
            ? null
            : config.chartType === "number"
            ? `${d.data[config.unit]} (${
                _isNaN(Math.round((d.data[config.unit] / totalUnit) * 100))
                  ? 0
                  : Math.round((d.data[config.unit] / totalUnit) * 100)
              }%)`
            : `${Currency_Symbol}${d3.format(",")(
                Math.round(
                  d.data[config.unit] > 1000
                    ? d.data[config.unit] / 1000
                    : d.data[config.unit]
                )
              )}K (${
                _isNaN(Math.round((d.data[config.unit] / totalUnit) * 100))
                  ? 0
                  : Math.round((d.data[config.unit] / totalUnit) * 100)
              }%)`
          : null
      )
      //(${Math.round((d.data[config.unit] / totalUnit) * 100)}%)
      .attr("transform", function (d) {
        var pos = outerArc.centroid(d);
        pos[0] = radius * 0.95 * (midAngle(d) < Math.PI ? 1 : -1);
        return "translate(" + pos + ")";
      })
      .style("text-anchor", function (d) {
        console.log();
        return midAngle(d) < Math.PI ? "start" : "end";
      })
      .style("cursor", config.isDrilldown === false ? "" : "pointer")
      .on("click", (d) =>
        config.handleChangeLocation(
          "drilldownOnGraph",
          {
            row:
              config.type === "qfq"
                ? d.data["qualified_fiscal_quarter"]
                : config.type === "qfq"
                ? d.data["Qualified_Year_Month"]
                : d.data[config.type],
            cell:
              config.type === "Overall_Health" ||
              config.type === "label" ||
              config.type === "Account_Segment" ||
              config.isMonth === true
                ? config.uniqueQuarters
                : d.data[config.quarter],
            rowKey: "rowTotal",
            rowCell: ["qfq", "qfm"].includes(config.type)
              ? d.data[config.type]
              : "",
          },
          config.type,
          config.type === "Overall_Health" ||
            config.type === "label" ||
            config.type === "Account_Segment"
            ? "rawValsTotalDonut"
            : config.quarter
        )
      );

    chart
      .append("text")
      .attr("class", "toolCircle")
      .attr("dy", -5) // hard-coded. can adjust this to adjust text vertical alignment in tooltip
      .html(config.data.length !== 0 && totalUnit !== 0 ? `Total` : "") // add text to the circle.
      .style("font-size", "0.85rem")
      .style("font-weight", 600)
      .style("text-anchor", "middle")
      .style("cursor", config.isDrilldown === false ? "" : "pointer")
      .attr("data-test", `${getMixKey(config.type)}-total-doughnut`)
      .on("click", (d) =>
        config.handleChangeLocation(
          "drilldownOnGraph",
          {
            row:
              config.type === "Overall_Health" ||
              config.type === "label" ||
              config.type === "Account_Segment"
                ? uniqueStages
                : "Total",
            cell:
              config.type === "Overall_Health" ||
              config.type === "Account_Segment" ||
              config.type === "label" ||
              config.isMonth === true
                ? config.uniqueQuarters
                : "Total",
          },
          config.type,
          config.type === "Overall_Health" || config.type === "label"
            ? "rawValsTotalDonutOverAll"
            : config.quarter
        )
      );

    chart
      .append("text")
      .attr("class", "toolCircle")
      .attr("dy", 10) // hard-coded. can adjust this to adjust text vertical alignment in tooltip
      .html(
        config.data.length !== 0 && totalUnit !== 0
          ? config.chartType === "number"
            ? `${d3.format(",")(Math.round(totalUnit))}`
            : `${Currency_Symbol}${d3.format(",")(
                Math.round(totalUnit > 1000 ? totalUnit / 1000 : totalUnit)
              )}K`
          : ""
      ) // add text to the circle.
      .style("font-size", "0.85rem")
      .style("font-weight", 600)
      .style("text-anchor", "middle")
      .style("cursor", config.isDrilldown === false ? "" : "pointer")
      .attr("data-test", getMixKey(config.type))
      .on("click", (d) =>
        config.handleChangeLocation(
          "drilldownOnGraph",
          {
            row:
              config.type === "Overall_Health" ||
              config.type === "label" ||
              config.type === "Account_Segment"
                ? uniqueStages
                : "Total",
            cell:
              config.type === "Overall_Health" ||
              config.type === "Account_Segment" ||
              config.type === "label" ||
              config.isMonth === true
                ? config.uniqueQuarters
                : "Total",
          },
          config.type,
          config.type === "Overall_Health" || config.type === "label"
            ? "rawValsTotalDonutOverAll"
            : config.quarter
        )
      );

    function midAngle(d) {
      return d.startAngle + (d.endAngle - d.startAngle) / 2;
    }
  }
};

D3DonutChart.destroy = (el) => {
  // Cleaning code here
  d3.select(el).selectAll("svg").remove();
};
export default D3DonutChart;
