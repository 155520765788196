export const getMixAnalysisHeading = (type, key) =>
  `${getMixType(type)} mix by ${getMixKey(key)}`;

const getMixType = (type) => {
  if (type === "projected") {
    return "Pipeline";
  }
  if (type === "closed") {
    return "Won ACV";
  }
};
export const getMixKey = (key, Value_Label, company) => {
  // console.log("Recived_Key", key);
  if (key === "Acct_Segment") {
    return "Segment";
  } else if (key === "Booking_Type_NL_CS_US") {
    return "Opportunity Type";
  } else if (key === "Region_Rollup") {
    return "Region";
  } else if (key === "Cust_Type") {
    return "Customer Type";
  } else if (key === "Acct_Industry") {
    return "Industry";
  } else if (key === "Acct_Size_Segment") {
    return "Customer Segment";
  } else if (key === "Solution_Cat") {
    return "Solution Category";
  } else if (key === "Acct_Vertical") {
    return "Vertical";
  } else if (key === "Apps_Cluster") {
    return "Apps Cluster";
  } else if (key === "Implementation_Primed_by__c") {
    return "Implementation";
  } else if (key === "Partner_Channel__c") {
    return "Partner Involvement";
  } else if (key === "ACV_Range") {
    return `${Value_Label} Range`;
  } else if (key === "closed_fiscal_quarter") {
    return "Closed Fiscal Quarter";
  } else if (key === "closed_fiscal_year") {
    return "Closed Fiscal Year";
  } else if (key === "projected_close_fiscal_quarter") {
    return "Projected Close Fiscal Quarter";
  } else if (key === "projected_close_fiscal_year") {
    return "Projected Close Fiscal Year";
  } else if (key === "rep") {
    return "Rep";
  } else if (key === "team") {
    return "Team";
  } else if (key === "deal_category__c") {
    return "Deals Category";
  } else if (key === "cross_sell1__c") {
    return "Sales Type";
  } else if (key === "Type") {
    return company === "C0017" ? "Opp. Type" : "Deal Type";
  } else if (key === "Opportunity_Record_Type") {
    return "Opportunity Record Type";
  } else if (key === "Record_Type_Name__c") {
    return "Opp Record Type";
  } else if (key === "Closed_Year_Month") {
    return "Closed Month";
  } else if (key === "Projected_Close_Year_Month") {
    return "Projected Close Month";
  } else if (key === "PCD_At_Creation_Year_Month") {
    return "PCD_At_creation_Fiscal_Quarter";
  } else if (key === "Of_Locations_Range") {
    return "Location Range";
  }
  return key;
};
