import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import tableStyle from '../../../styles/tableStyle'

export default function NoDataCell() {
    const classes = tableStyle()
    return (
        <TableRow><TableCell align="center" className={classes.colHeader}>No Data Available</TableCell></TableRow>
    )
}
