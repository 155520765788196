import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  TextField,
  Divider,
  Button,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Paper,
} from "@material-ui/core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DatePicker from "../../components/DatePicker_v2";
import NumberFormat from "react-number-format";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import D3SankeyContainer from "./D3SankeyContainer";
import PCDMovedByQuartersTable from "./PCDMovedByQuartersTable";
import D3LineChart from "./D3LineChart";
import { deSnake, getLineChartFormatedData } from "../../util/dataFormatter";
import Legend from "../../RepPerformance/AsOfDate/Legend";

import {
  customDateFormatterNew,
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
  getDefaultACV,
  getCustomSortedColoredLegends,
  getDefaultSalesType,
} from "../../util/customFunctions";
import PCDMovedTable from "./PCDMovedTable";
import PCDMovedByQuartersMoved from "./PCDMovedByQuartersMoved";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import PCDMovedTableMetric from "./PCDMovedTableMetric";
import PCDMovedByQuartersMovedV2 from "./PCDMovedDataV2";
import { styled } from "@mui/material/styles";
import addHeaderdescription from "../../components/HeaderDescription";
import LocationRangeFilter from "../../components/LocationRangeFilter";
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip followCursor {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: "none",
    fontSize: "1rem",
    color: "black",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});
const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  formControl: {
    maxWidth: 250,
  },
  datesFilterContainer: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    padding: 10,
    paddingBottom: 10,
    width: "32%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  repTeamFilterContainer: {
    padding: 10,
    margin: 5,
    width: "29%",
    [theme.breakpoints.down("md")]: {
      width: "37%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  acvFilterContainer: {
    padding: 10,
    margin: 5,
    paddingBottom: 2,
    width: "20%",
    [theme.breakpoints.down("md")]: {
      width: "35%",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
  stageFilterContainer: {
    padding: 5,
    paddingTop: 10,
    margin: 5,
    paddingBottom: 10,
    width: "10%",
    [theme.breakpoints.down("md")]: {
      width: "20%",
    },
  },
  buttonContainer: {
    padding: 5,
    width: "5%",
    alignSelf: "center",
  },
});

class PcdMovedAnalysis extends React.Component {
  filters = [
    "fiscal_quarter",
    "rep",
    "team",
    "Cust_Type",
    "deal_category__c",
    "Acct_Industry",
    "Acct_Vertical",
    "Acct_Segment",
    "Acct_Size_Segment",
    "Solution_Cat",
    "Type",
    "cross_sell1__c",
    "sameTimePriorQtr",
    "onlyMoves",
    "Record_Type_Name__c",
    "Of_Locations_Range",
  ];

  state = {
    last: 30,
    activelast: 30,
    timeFrame: "window",
    acv: null,
    acvlt: null,
    disabled: [],
    fiscal_quarter: ["All"],
    earliestOpenStage: "",
    location: "overview",
    filtersForDrilldown: [],
    filtersForDrilldownpcfq: ["2021-Q1"],
    filtersForDrilldownEarliestOpenStage: 3,
    activeFilters: [],
    selectedDrilldownStage: "",
    selectedDrilldownStageLabel: "",
    rep: ["All"],
    team: ["All"],
    Acct_Industry: ["All"],
    Acct_Vertical: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    deal_category__c: ["All"],
    Cust_Type: ["All"],
    Solution_Cat: ["All"],
    drilldown: {},
    drilldownFilters: [],
    tab: "summary",
    toggleValue: "count",
    cross_sell1__c: ["All"],
    Type: ["All"],
    secondLevelLocation: null,
    monthInQuater: ["All"],
    sameTimePriorQtr: "None",
    onlyMoves: 1,
    header: false,
    Record_Type_Name__c: ["All"],
    Of_Locations_Range: ["All"],
  };

  componentDidMount() {
    this.props.getPcdMovedAnalysisFilters(this.props.company);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.pcdMovedAnalysisFilters !== "" &&
      prevProps.pcdMovedAnalysisFilters == ""
    ) {
      let fiscal_quarter = getDefaultSelectedFiscalQuarters(
        this.props.pcdMovedAnalysisFilters.fiscal_quarter,
        "fiscal_quarter"
      );

      var salesTypes = ["All"];
      let dealTypes = ["All"];
      let solutioncat = ["All"];
      dealTypes = getDefaultSalesType(
        this.props.pcdMovedAnalysisFilters.oppTypeFilter,
        "Type"
      );
      solutioncat = getDefaultSalesType(
        this.props.pcdMovedAnalysisFilters.solutionCat,
        "Solution_Cat"
      );
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.pcdMovedAnalysisFilters.salesType,
          "cross_sell1__c"
        );
        dealTypes = getDefaultSalesType(
          this.props.pcdMovedAnalysisFilters.dealType,
          "Type"
        );
      }

      this.setState({
        ...this.state,
        fiscal_quarter,
        filtersForDrilldownfiscal_quarter: getDefaultSelectedFiscalQuarters(
          this.props.pcdMovedAnalysisFilters.fiscal_quarter,
          "fiscal_quarter"
        ),
        earliestOpenStage: getDefaultSelectedStage(
          this.props.pcdMovedAnalysisFilters.stages
        ),
        filtersForDrilldownEarliestOpenStage: getDefaultSelectedStage(
          this.props.pcdMovedAnalysisFilters.stages
        ),
        cross_sell1__c: salesTypes,
        Type: dealTypes,
        Solution_Cat: solutioncat,
        acv:
          getDefaultACV(this.props.pcdMovedAnalysisFilters.acvFilter) > 0
            ? d3.format(",")(
                String(
                  getDefaultACV(this.props.pcdMovedAnalysisFilters.acvFilter)
                )
              )
            : getDefaultACV(this.props.pcdMovedAnalysisFilters.acvFilter),
      });
    }

    if (
      this.props.pcdMovedAnalysisTrendsData !== "" &&
      this.state.tab !== "summary"
    ) {
      const chartData = this.props.pcdMovedAnalysisTrendsData.trendsData;
      const quarter = "quarter";
      D3LineChart.destroy(this._rootNode1);
      const uniqueQuarters = chartData.FQMovedResults.map(
        (item) => item["fiscal_quarter"]
      ).filter(getUniqueValues);
      const uniqueFQMoves = chartData.FQMovedResults.map(
        (item) => item["qtrsMoved"]
      ).filter(getUniqueValues);
      this._chart = D3LineChart.create(this._rootNode1, {
        data: getLineChartFormatedData(
          chartData.FQMovedResults,
          "acv",
          "pcdMovedAnalysis",
          uniqueFQMoves
        ),
        type: this.state.toggleValue,
        uniqueQuarters: uniqueQuarters.length === 0 ? [] : uniqueQuarters,
        quarter,
        chart: "pcdMovedAnalysis",
        key: "qtrsMoved",
        Value_Label: this.props.Value_Label,
      });
    }
  }

  componentWillUnmount() {
    D3LineChart.destroy(this._rootNode1);
  }

  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }

  onResize = async () => {
    if (this.state.tab !== "summary") {
      D3LineChart.destroy(this._rootNode1);
      const chartData = this.props.pcdMovedAnalysisTrendsData.trendsData;
      const quarter = "quarter";
      D3LineChart.destroy(this._rootNode1);
      const uniqueQuarters = chartData.FQMovedResults.map(
        (item) => item["fiscal_quarter"]
      ).filter(getUniqueValues);
      const uniqueFQMoves = chartData.FQMovedResults.map(
        (item) => item["qtrsMoved"]
      ).filter(getUniqueValues);
      this._chart = D3LineChart.create(this._rootNode1, {
        data: getLineChartFormatedData(
          chartData.FQMovedResults,
          "acv",
          "pcdMovedAnalysis",
          uniqueFQMoves
        ),
        type: this.state.toggleValue,
        uniqueQuarters: uniqueQuarters.length === 0 ? [] : uniqueQuarters,
        quarter,
        chart: "pcdMovedAnalysis",
        key: "qtrsMoved",
      });
    }
  };

  handleChange = (e) => {
    if (e.target.value !== 1) {
      const projectedClosedDateMapping = {
        7: this.props.pcdMovedAnalysisFilters.dateFilters.prior7Days.split("-"),
        15: this.props.pcdMovedAnalysisFilters.dateFilters.prior15Days.split(
          "-"
        ),
        30: this.props.pcdMovedAnalysisFilters.dateFilters.prior1Month.split(
          "-"
        ),
        90: this.props.pcdMovedAnalysisFilters.dateFilters.prior3Month.split(
          "-"
        ),
        180: this.props.pcdMovedAnalysisFilters.dateFilters.prior6Month.split(
          "-"
        ),
        365: this.props.pcdMovedAnalysisFilters.dateFilters.prior1Year.split(
          "-"
        ),
      };
      const fromDateObj = new Date(
        parseInt(projectedClosedDateMapping[e.target.value][0]),
        parseInt(projectedClosedDateMapping[e.target.value][1]) - 1,
        parseInt(projectedClosedDateMapping[e.target.value][2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDateComponents =
        this.props.pcdMovedAnalysisFilters.dateFilters.currentDate.split("-");
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        to: toDate,
        from: fromDate,
        errorTextFrom: "",
        errorTextTo: "",
      });
    } else {
      this.setState({ ...this.statem, [e.target.name]: e.target.value });
    }
  };

  handleChangeFilter = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.name === "fiscal_quarter"
          ? this.state.tab === "summary"
            ? [e.target.value]
            : e.target.value
          : e.target.value,
    });
  };
  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  // setFromDate = (date) => {
  //   const split = this.state.to.split("-");
  //   const toDate = new Date(
  //     parseInt(split[2]),
  //     parseInt(split[0]) - 1,
  //     parseInt(split[1])
  //   );
  //   if (new Date(date) > toDate) {
  //     this.setState({
  //       ...this.state,
  //       from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
  //       errorTextFrom: "From date cannot be later than the To date",
  //     });
  //   } else {
  //     this.setState({
  //       ...this.state,
  //       from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
  //       errorTextFrom: "",
  //       errorTextTo: "",
  //     });
  //   }
  // };

  // setToDate = (date) => {
  //   const split = this.state.from.split("-");
  //   const fromDate = new Date(
  //     parseInt(split[2]),
  //     parseInt(split[0]) - 1,
  //     parseInt(split[1])
  //   );
  //   if (new Date(date) < fromDate) {
  //     this.setState({
  //       ...this.state,
  //       to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
  //       errorTextFrom: "To date cannot be earlier than the From date",
  //     });
  //   } else {
  //     this.setState({
  //       ...this.state,
  //       to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
  //       errorTextTo: "",
  //       errorTextFrom: "",
  //     });
  //   }
  // };

  plot = (tab) => {
    let filters = this.filters
      .map((f) => {
        console.log("f.value", f.value);
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.pcdMovedAnalysisFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.pcdMovedAnalysisFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
          string: typeof this.state[f] === "number" ? undefined : true,
        };
      })
      .filter((f) => {
        if (Array.isArray(f.value)) {
          return !f.value.includes("All");
        } else {
          return f.value !== "None" || f.value !== "All";
        }
      })
      .filter((f) => !this.state.disabled.includes(f.name));
    // if(this.state.fiscal_quarter.includes('All')) {
    //   filters=[...filters,{name:'fiscal_quarter',value:this.props.pcdMovedAnalysisFilters.fiscal_quarter.map(s=>s.fiscal_quarter)}]
    // }
    let stage_namesOnwards = [];
    let stage_namesBefore = [];
    this.props.pcdMovedAnalysisFilters.stages.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence >= this.state.earliestOpenStage)
          stage_namesOnwards = [...stage_namesOnwards, i.stage_name];
      }
    });
    filters = [
      ...filters,
      {
        name: "EarliestStageOnwards",
        value: stage_namesOnwards,
        string: true,
      },
    ];
    filters = [
      ...filters,
      // { name: "PCDFromDate", value: this.state.from, string: true },
      // { name: "PCDToDate", value: this.state.to, string: true },
    ];
    filters = [
      ...filters,
      {
        name: "acvAtTime",
        value:
          this.state.acv && typeof this.state.acv === "string"
            ? this.state.acv.match(/,/g)
              ? this.state.acv.match(/,/g).length === 1
                ? parseInt(this.state.acv) * 1000
                : this.state.acv.match(/,/g).length === 2
                ? parseInt(this.state.acv) * 1000000
                : parseInt(this.state.acv)
              : parseInt(this.state.acv)
            : [null, undefined, ""].includes(this.state.acv)
            ? 0
            : this.state.acv,
        string: false,
      },
    ];
    if (this.state.acvlt !== null && this.state.acvlt !== "") {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }
    if (
      this.state.monthInQuater !== null &&
      this.state.monthInQuater !== "" &&
      this.state.monthInQuater !== []
    ) {
      console.log(this.state.monthInQuater[0] === "All");

      if (this.state.monthInQuater === ["All"]) {
        this.state.monthInQuater = [1, 2, 3];
      }
      filters = [
        ...filters,
        {
          name: "monthInQuarter",
          value:
            this.state.monthInQuater[0] === "All"
              ? [1, 2, 3]
              : this.state.monthInQuater,
          string: false,
        },
      ];
    }

    console.log(filters);
    if (this.state.onlyMoves !== "No") {
      filters = [
        ...filters,
        {
          name: "onlyMovesFromAndToChosenQuarters",
          value: this.state.onlyMoves,
          string: false,
        },
      ];
    }
    console.log("debug_pcd", this.state.tab, tab);
    if (tab === "trends" || tab === "summary") {
      if (tab === "trends") {
        const debug_key = "trends_debug";
        console.log(debug_key, "make trends api call");
        console.log(debug_key, this.state.tab, tab, filters);
        this.props.getPCDMovedAnalysisTrendsData(filters);
      }
      if (tab === "summary") {
        this.props.getPCDMovedAnalysisData(filters);
      }
    } else {
      console.log("debug_pcd");
      if (this.state.tab === "trends") {
        const debug_key = "trends_debug";
        console.log(debug_key, "make trends api call");
        console.log(debug_key, this.state.tab, tab, filters);
        this.props.getPCDMovedAnalysisTrendsData(filters);
      }
      if (this.state.tab === "summary") {
        this.props.getPCDMovedAnalysisData(filters);
      }
    }

    this.setState({
      ...this.state,
      // fromDisplay: customDateFormatterNew(this.state.from),
      // toDisplay: customDateFormatterNew(this.state.to),
      // activeFrom: this.state.from,
      // activeTo: this.state.to,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      filtersForDrilldownEarliestOpenStage:
        this.passFiltersForDrilldownEarliestOpenStage(),
      activeFilters: filters,
      activelast: this.state.last,
    });
  };
  handleChangeLocationQtrsMoved = (
    location,
    obj,
    key,
    isUnion,
    fiscal_quarterList
  ) => {
    if (location !== "overview") {
      let filters = this.props.currentFilters;
      let drilldownBubbles = [];
      let drilldownFilters = [];
      let otherFiltersBubbles = [];

      if (!this.state.Type.includes("All")) {
        otherFiltersBubbles = [
          {
            title: this.props.company === "C0017" ? "Opp. Type" : "Deal Type",
            value: this.state.Type,
          },
        ];
      }

      if (key === "actual") {
        drilldownFilters = [
          {
            name: "qtrsMovedData",
            value: obj.quarters,
          },
        ];
        drilldownBubbles = [
          {
            title: "Projected Close Date Old Fiscal Quarters",
            value: obj.oldQtr,
          },
          {
            title: "Projected Close Date New Fiscal Quarters",
            value: obj.newQtr,
          },
          ...otherFiltersBubbles,
        ];
      } else if (key === "quarterMovedSummary") {
        drilldownFilters = [{ name: "FQ_Moved", value: obj.fq_moved }];
        drilldownBubbles = [
          {
            title: "Qtrs. Moved",
            value: obj.fq_moved,
          },
        ];
      }
      if (fiscal_quarterList) {
        filters = filters.filter((it) => it.name !== "fiscal_quarter");
        filters = [
          ...filters,
          { name: "fiscal_quarter", value: fiscal_quarterList, string: true },
        ];
      }
      this.props.getPcdMovedAnalysisQtrsMovedDrilldown(
        { filters, drilldownFilters },
        key,
        isUnion
      );

      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        drilldownFilters: drilldownBubbles,
        header: isUnion === true,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleChangeLocation = (location, obj, key, isUnion, fiscal_quarterList) => {
    if (location !== "overview") {
      let filters = this.props.currentFilters;
      let drilldownBubbles = [];
      let drilldownFilters = [];
      let otherFiltersBubbles = [];

      if (!this.state.Type.includes("All")) {
        otherFiltersBubbles = [
          {
            title: this.props.company === "C0017" ? "Opp. Type" : "Deal Type",
            value: this.state.Type,
          },
        ];
      }

      if (key === "actual") {
        drilldownFilters = [
          {
            name: "Projected_Close_Date_Old_Fiscal_Quarter",
            value: obj.oldQtr,
          },
          {
            name: "Projected_Close_Date_New_Fiscal_Quarter",
            value: obj.newQtr,
          },
        ];
        drilldownBubbles = [
          {
            title: "Projected Close Date Old Fiscal Quarters",
            value: obj.oldQtr,
          },
          {
            title: "Projected Close Date New Fiscal Quarters",
            value: obj.newQtr,
          },
          ...otherFiltersBubbles,
        ];
      } else if (key === "quarterMovedSummary") {
        drilldownFilters = [{ name: "FQ_Moved", value: obj.fq_moved }];
        drilldownBubbles = [
          {
            title: "Qtrs. Moved",
            value: obj.fq_moved,
          },
        ];
      }
      if (fiscal_quarterList) {
        filters = filters.filter((it) => it.name !== "fiscal_quarter");
        filters = [
          ...filters,
          { name: "fiscal_quarter", value: fiscal_quarterList, string: true },
        ];
      }
      this.props.getPcdMovedAnalysisDrilldown(
        { filters, drilldownFilters },
        key,
        isUnion
      );

      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        drilldownFilters: drilldownBubbles,
        header: isUnion === true,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleChangeLocationByQuarters = (location, obj, key) => {
    if (location !== "overview") {
      let filters = this.props.currentFilters;
      let drilldownBubbles = [];
      let drilldownFilters = [];
      if (key === "quarterMovedTrends") {
        drilldownFilters = [
          { name: "fiscal_quarter", value: obj.quarter },
          { name: "FQ_Moved", value: obj.fq_moved },
        ];
        drilldownBubbles = [
          {
            title: "Fiscal Qtr.",
            value: obj.quarter,
          },
          {
            title: "Qtrs. Moved",
            value: obj.fq_moved,
          },
        ];
      }
      filters = [
        ...filters.filter((it) => it.name !== "fiscal_quarter"),
        { name: "fiscal_quarter", value: obj.quarter, string: true },
      ];
      this.props.getPcdMovedAnalysisDrilldown(
        { filters, drilldownFilters },
        key
      );

      this.setState({
        ...this.state,
        location: location,
        header: false,
        drilldownFilters: drilldownBubbles,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: "overview",
      secondLevelLocation: location,
    });
  };
  handleChangeSameTimePriorQtr = (event) => {
    this.setState({
      ...this.state,
      sameTimePriorQtr: event.target.value,
    });
  };
  handleChangeOnlyMoves = (event) => {
    this.setState({
      ...this.state,
      onlyMoves: event.target.value,
    });
  };
  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    return filtersArray;
  };

  passFiltersForDrilldown2 = () => {
    let filtersArray = [];
    if (this.state.fiscal_quarter) {
      filtersArray = [...filtersArray, this.state.fiscal_quarter];
    }
    return filtersArray;
  };

  passFiltersForDrilldownEarliestOpenStage = () => {
    if (this.state.earliestOpenStage) {
      return this.state.earliestOpenStage;
    }
  };
  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (e, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onAcvLtChange = (e, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  handleChangeTab = (e) => {
    console.log("debug_pcd", e.target.value, this.state.tab);
    if (e.target.value === "summary" && this.state.tab !== "summary") {
      let fiscal_quarter = getDefaultSelectedFiscalQuarters(
        this.props.pcdMovedAnalysisFilters.fiscal_quarter,
        "fiscal_quarter"
      );
      this.state.fiscal_quarter = fiscal_quarter;
      this.plot(e.target.value);
      this.setState({
        ...this.state,
        tab: e.target.value,
      });
    } else if (e.target.value === "trends" && this.state.tab !== "trends") {
      let fiscal_quarter =
        this.props.pcdMovedAnalysisFilters.trendsDefaultQuarters.map(
          (s) => s["fiscal_quarter"]
        );
      if (fiscal_quarter.length >= 1) {
        fiscal_quarter = fiscal_quarter;
      } else {
        fiscal_quarter = getDefaultSelectedFiscalQuarters(
          this.props.pcdMovedAnalysisFilters.fiscal_quarter,
          "fiscal_quarter"
        );
      }

      this.state.fiscal_quarter = fiscal_quarter;
      this.plot(e.target.value);
      this.setState({
        ...this.state,
        tab: e.target.value,
      });
    } else {
      this.setState({
        ...this.state,
        tab: e.target.value,
      });
    }
  };

  orderRepsByLastName = () => {
    let temp = this.props.pcdMovedAnalysisFilters.reps;
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleChangeToggle = (e) => {
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid item style={{ marginBottom: 5 }} xs={12}>
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Projected Close Date Moves
          </Typography>
          <Typography variant="body1">
            Visualize close date moves in any quarter and analyze trends across
            quarters.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Projected Close Date Moves",
          "Visualize close date moves in any quarter and analyze trends across quarters."
        )}

        {this.props.pcdMovedAnalysisFilters !== "" ? (
          <>
            <Grid
              container
              xs={12}
              alignItems="flex-start"
              style={{ marginBottom: 5 }}
            >
              <Grid
                xs={3}
                spacing={5}
                item
                container
                component={Paper}
                className={classes.repTeamFilterContainer}
              >
                <Grid item xs={6} style={{ padding: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Move Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state.fiscal_quarter}
                      onChange={this.handleChangeFilter}
                      name="fiscal_quarter"
                      multiple={this.state.tab === "trends"}
                    >
                      {this.state.tab === "trends" && (
                        <MenuItem value={"All"}>All</MenuItem>
                      )}
                      {this.props.pcdMovedAnalysisFilters !== "" &&
                        this.props.pcdMovedAnalysisFilters.fiscal_quarter.map(
                          (y) => (
                            <MenuItem
                              key={y["fiscal_quarter"]}
                              value={y["fiscal_quarter"]}
                            >
                              {y["fiscal_quarter"]}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ padding: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-stage-label"
                      style={{ color: "#4472c4", width: 144 }}
                    >
                      Earliest Open Stage:
                    </InputLabel>
                    <Select
                      labelId="select-stage-label"
                      id="select-stage"
                      value={this.state.earliestOpenStage}
                      onChange={this.handleChangeEarliestOpenStage}
                      label="Earliest Open Stage:"
                      name="earliestOpenStage"
                    >
                      {this.props.pcdMovedAnalysisFilters !== "" &&
                        this.props.pcdMovedAnalysisFilters.stages.map(
                          (y) =>
                            y.sequence && (
                              <MenuItem key={y.sequence} value={y.sequence}>
                                {y.stage_name}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {/* <Grid
                xs={4}
                item
                container
                className={classes.datesFilterContainer}
                component={Paper}
              >
                <Grid item xs={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-last-label"
                      style={{ color: "#4472c4" }}
                    >
                      Time Frame - Last:
                    </InputLabel>
                    <Select
                      labelId="select-last-label"
                      id="select-last"
                      value={this.state.last}
                      onChange={this.handleChange}
                      label="Last:"
                      name="last"
                      disabled={this.state.timeFrame === "custom"}
                    >
                      {[
                        { text: "Custom", value: 1 },
                        { text: "7 days", value: 7 },
                        { text: "15 days", value: 15 },
                        { text: "1 month", value: 30 },
                        { text: "3 months", value: 90 },
                        { text: "6 months", value: 180 },
                        { text: "1 year", value: 365 },
                      ].map((y) => (
                        <MenuItem key={y.text} value={y.value}>
                          {y.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 10 }}>
                  <DatePicker
                    label="From start of:"
                    setDate={(date) => this.setFromDate(date)}
                    disabled={this.state.last !== 1}
                    min={this.state.minDateForFrom}
                    max={this.state.maxDateForFrom}
                    current={this.state.from}
                    errorText={this.state.errorTextFrom}
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 10 }}>
                  <DatePicker
                    label="To end of:"
                    setDate={(date) => this.setToDate(date)}
                    disabled={this.state.last !== 1}
                    min={this.state.minDateForTo}
                    max={this.state.maxDateForTo}
                    current={this.state.to}
                    errorText={this.state.errorTextTo}
                  />
                </Grid>
              </Grid> */}
              <Grid
                item
                xs={4}
                container
                className={classes.repTeamFilterContainer}
                component={Paper}
              >
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={
                      this.props.pcdMovedAnalysisFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Rep:"
                        variant="standard"
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      id="select-team-label"
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Team:
                    </InputLabel>
                    <Select
                      labelId="select-team-label"
                      id="select-team"
                      value={this.state.team}
                      onChange={this.handleChangeFilter}
                      label="Team"
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.pcdMovedAnalysisFilters !== "" &&
                        this.props.pcdMovedAnalysisFilters.teams
                          .map((t) => t.Display_Name)
                          .sort()
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                container
                className={classes.acvFilterContainer}
                component={Paper}
              >
                <Grid item xs={6}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.pcdMovedAnalysisFilters !== "" &&
                      this.props.pcdMovedAnalysisFilters.acvFilter.map((d) =>
                        d3.format(",")(String(d.acv))
                      )
                    }
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: 4 }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== "" && this.state.acv !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.pcdMovedAnalysisFilters !== "" &&
                      this.props.pcdMovedAnalysisFilters.acvFilter.map((d) =>
                        d3.format(",")(String(d.acv))
                      )
                    }
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: 4 }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== "" &&
                              this.state.acvlt !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {/* $ */}
                                {this.props.Currency_Symbol}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {/* ACV and GO */}
              <Grid
                container
                item
                className={classes.datesFilterContainer}
                xs={2}
              >
                <Grid item container className={classes.buttonContainer}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.plot}
                    // disabled={
                    //   this.state.errorTextFrom !== "" ||
                    //   this.state.errorTextTo !== ""
                    // }
                  >
                    GO
                  </Button>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={6}
                className={classes.repTeamFilterContainer}
                component={Paper}
                style={{ marginRight: 10 }}
              >
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-team-label"
                      style={{
                        color: !this.state.Cust_Type.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Customer Type:
                    </InputLabel>
                    <Select
                      labelId="select-team-label"
                      id="select-cust-type"
                      data-test="select-cust-type"
                      value={this.state.Cust_Type}
                      onChange={this.handleChangeFilter}
                      label=""
                      name="Cust_Type"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.pcdMovedAnalysisFilters !== "" &&
                        this.props.pcdMovedAnalysisFilters.custType
                          .map((t) => t.Cust_Type)
                          .sort()
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.props.company === "C0003"
                            ? "Acct_Vertical"
                            : "Acct_Industry"
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Industry:"}
                    </InputLabel>
                    <Select
                      data-test="Industry"
                      value={
                        this.state[
                          this.props.company === "C0003"
                            ? "Acct_Vertical"
                            : "Acct_Industry"
                        ]
                      }
                      onChange={this.handleChangeFilter}
                      name={
                        this.props.company === "C0003"
                          ? "Acct_Vertical"
                          : "Acct_Industry"
                      }
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.pcdMovedAnalysisFilters !== "" &&
                        this.props.pcdMovedAnalysisFilters.industries
                          .sort()
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.props.company === "C0003"
                            ? "Acct_Segment"
                            : "Acct_Size_Segment"
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      {this.props.company === "C0003"
                        ? "Segment:"
                        : "Customer Segment:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Segment"
                      value={
                        this.state[
                          this.props.company === "C0003"
                            ? "Acct_Segment"
                            : "Acct_Size_Segment"
                        ]
                      }
                      onChange={this.handleChangeFilter}
                      name={
                        this.props.company === "C0003"
                          ? "Acct_Segment"
                          : "Acct_Size_Segment"
                      }
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.pcdMovedAnalysisFilters !== "" &&
                        this.props.pcdMovedAnalysisFilters.custSeg.map((y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {this.props.company === "C0008" && (
                <Grid
                  item
                  container
                  xs={4}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.cross_sell1__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Sales Type:
                      </InputLabel>
                      <Select
                        data-test="cross_sell1__c"
                        value={this.state.cross_sell1__c}
                        onChange={this.handleChangeFilter}
                        name="cross_sell1__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.pcdMovedAnalysisFilters !== "" &&
                          this.props.pcdMovedAnalysisFilters.salesType.map(
                            (y) => (
                              <MenuItem
                                key={y.cross_sell1__c}
                                value={y.cross_sell1__c}
                              >
                                {y.cross_sell1__c}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Type:
                      </InputLabel>
                      <Select
                        data-test="cross_sell1__c"
                        value={this.state.Type}
                        onChange={this.handleChangeFilter}
                        name="Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.pcdMovedAnalysisFilters !== "" &&
                          this.props.pcdMovedAnalysisFilters.dealType.map(
                            (y) => (
                              <MenuItem key={y.Type} value={y.Type}>
                                {y.Type}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {this.props.pcdMovedAnalysisFilters.solutionCat && (
                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.deal_category__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Solution Category:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-cust-type"
                        data-test="select-cust-type"
                        value={this.state.Solution_Cat}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="Solution_Cat"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.pcdMovedAnalysisFilters !== "" &&
                          this.props.pcdMovedAnalysisFilters.solutionCat
                            .map((f) => f.Solution_Cat)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {this.props.pcdMovedAnalysisFilters.dealCategory && (
                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.deal_category__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-cust-type"
                        data-test="select-cust-type"
                        value={this.state.deal_category__c}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="deal_category__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.pcdMovedAnalysisFilters !== "" &&
                          this.props.pcdMovedAnalysisFilters.dealCategory
                            .map((t) => t.deal_category__c)
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                item
                style={{
                  marginBottom: 5,
                  marginLeft: 3,
                  padding: 10,
                  paddingTop: 13,
                }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ padding: 10 }}>
                    <CustomWidthTooltip title="Only include moves from or to chosen quarter(s).">
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color:
                              this.state.onlyMoves !== "No"
                                ? "#4472c4"
                                : "#000",
                            whiteSpace: "nowrap",
                          }}
                          id="select-region-label"
                        >
                          Only From/To Chosen Qtr(s).:
                        </InputLabel>
                        <Select
                          data-test="onlyMoves"
                          value={this.state.onlyMoves}
                          onChange={this.handleChangeOnlyMoves}
                          name="onlyMoves"
                        >
                          <MenuItem value={"No"}>No</MenuItem>
                          <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                      </FormControl>
                    </CustomWidthTooltip>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                style={{
                  marginBottom: 5,
                  marginLeft: 3,
                  padding: 10,
                  paddingTop: 13,
                }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ padding: 10 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color:
                            this.state.sameTimePriorQtr !== "None"
                              ? "#4472c4"
                              : "#000",
                        }}
                        id="select-region-label"
                      >
                        Same Time Prior Period :
                      </InputLabel>
                      <Select
                        data-test="sameTimePriorQtr"
                        value={this.state.sameTimePriorQtr}
                        onChange={this.handleChangeSameTimePriorQtr}
                        name="sameTimePriorQtr"
                      >
                        <MenuItem value={"None"}>None</MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                style={{
                  marginBottom: 5,
                  marginLeft: 3,
                  padding: 10,
                  paddingTop: 13,
                }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ padding: 10 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.monthInQuater.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Month in Qtr.:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-cust-type"
                        data-test="select-cust-type"
                        value={this.state.monthInQuater}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="monthInQuater"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {this.props.company === "C0020" && (
                <Grid
                  container
                  item
                  style={{
                    marginBottom: 5,
                    marginLeft: 3,
                    padding: 10,
                    paddingTop: 13,
                  }}
                  xs={2}
                >
                  <LocationRangeFilter
                    classes={classes}
                    options={this.props.pcdMovedAnalysisFilters.LocationRange}
                    value={this.state.Of_Locations_Range}
                    onChange={this.handleChangeFilter}
                    paddingAvailable={true}
                  />
                </Grid>
              )}
              {/* C0010 Opp Record Type*/}
              {this.props.company === "C0010" && (
                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-region-label"
                        style={{
                          color: !this.state.Record_Type_Name__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        data-test="Record_Type_Name__c"
                        value={this.state.Record_Type_Name__c}
                        onChange={this.handleChangeFilter}
                        name="Record_Type_Name__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.pcdMovedAnalysisFilters !== "" &&
                          this.props.pcdMovedAnalysisFilters.oppRecordTypeFilter
                            .map((t) => t.Record_Type_Name__c)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {/* C0017 Opp Type Filter addition*/}
              {this.props.company === "C0017" && (
                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-region-label"
                        style={{
                          color: !this.state.Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Opp. Type:
                      </InputLabel>
                      <Select
                        data-test="Type"
                        value={this.state.Type}
                        onChange={this.handleChangeFilter}
                        name="Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.pcdMovedAnalysisFilters !== "" &&
                          this.props.pcdMovedAnalysisFilters.oppTypeFilter
                            .map((t) => t.Type)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={12}
          style={{
            marginLeft: "1rem",
            marginBottom: "0.1rem",
            marginRight: "1rem",
          }}
        >
          <Paper style={{ marginBottom: "-7px" }}>
            <ToggleButtonGroup
              color="primary"
              indicatorColor="primary"
              value={this.state.tab}
              exclusive
              size="large"
              onChange={this.handleChangeTab}
            >
              <ToggleButton
                style={{
                  background: this.state.tab === "summary" ? "#4472C4" : "",
                  color: this.state.tab === "summary" ? "white" : "",
                  fontSize: "1rem",
                  fontWeight: 600,
                  height: "3.5rem",
                  width: "8rem",
                }}
                value="summary"
              >
                SUMMARY
              </ToggleButton>
              <ToggleButton
                style={{
                  background: this.state.tab === "trends" ? "#4472C4" : "",
                  color: this.state.tab === "trends" ? "white" : "",
                  fontSize: "1rem",
                  fontWeight: 600,
                  height: "3.5rem",
                  width: "8rem",
                }}
                value="trends"
              >
                Trends
              </ToggleButton>
            </ToggleButtonGroup>
          </Paper>
        </Grid>
        {this.state.tab === "summary" ? (
          <>
            {this.props.pcdMovedAnalysisData !== "" ? (
              <Grid
                className={classes.grid}
                item
                xs={12}
                style={{ margin: "1rem" }}
              >
                <D3SankeyContainer
                  tableData={this.props.pcdMovedAnalysisData.formattedData}
                  toggleValue={this.state.toggleValue}
                  handleChangeToggle={this.handleChangeToggle}
                  Value_Label={this.props.Value_Label}
                />
                <Grid container component={Card} style={{ marginTop: "1rem" }}>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        align="center"
                        variant="h2"
                        style={{ margin: "1rem" }}
                      >
                        {" "}
                        {`Projected Close Date Moves (All Moves)`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      margin: "2rem",
                      marginTop: "0rem",
                      paddingRight: "1rem",
                    }}
                    align="center"
                    container
                  >
                    <PCDMovedTable
                      tableData={this.props.pcdMovedAnalysisData.formattedData}
                      handleChangeLocation={this.handleChangeLocation}
                      Value_Label={this.props.Value_Label}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      margin: "2rem",
                      marginTop: "0rem",
                      paddingRight: "1rem",
                    }}
                    align="center"
                    container
                  >
                    <PCDMovedTableMetric
                      tableData={this.props.pcdMovedAnalysisData.formattedData}
                      data={
                        this.props.pcdMovedAnalysisData.formattedData
                          .sankeyFormattedData
                      }
                      title={"Count"}
                      handleChangeLocation={this.handleChangeLocation}
                      metric={"count"}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      margin: "2rem",
                      marginTop: "0rem",
                      paddingRight: "1rem",
                    }}
                    align="center"
                    container
                  >
                    <PCDMovedTableMetric
                      tableData={this.props.pcdMovedAnalysisData.formattedData}
                      data={
                        this.props.pcdMovedAnalysisData.formattedData
                          .sankeyFormattedDataACV
                      }
                      // title={"ACV"}
                      title={`${this.props.Value_Label}`}
                      handleChangeLocation={this.handleChangeLocation}
                      metric={"acv"}
                    />
                  </Grid>
                </Grid>
                <Grid container component={Card} style={{ marginTop: "1rem" }}>
                  <Grid container item style={{ padding: "1rem" }} xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        align="center"
                        variant="h2"
                        style={{ margin: "1rem" }}
                      >
                        {" "}
                        Projected Close Date Moves (Net Moves) - Quarters Moved
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      margin: "2rem",
                      marginTop: "0rem",
                      paddingRight: "1rem",
                    }}
                    align="center"
                    container
                  >
                    <PCDMovedByQuartersMovedV2
                      tableData={this.props.pcdMovedAnalysisData.formattedData}
                      handleChangeLocation={this.handleChangeLocationQtrsMoved}
                      Value_Label={this.props.Value_Label}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="100%"
                style={{ margin: "1rem" }}
              />
            )}
          </>
        ) : (
          <>
            {this.props.pcdMovedAnalysisTrendsData !== "" ? (
              <>
                <Grid
                  className={classes.grid}
                  item
                  xs={12}
                  style={{ margin: "1rem", display: "flex" }}
                >
                  <Grid
                    container
                    component={Card}
                    raised
                    style={{ padding: "1rem" }}
                    xs={12}
                  >
                    <Grid item xs={1} style={{ padding: "1rem" }}></Grid>
                    <Grid item style={{ padding: "1rem" }} xs={10}>
                      <Typography
                        align="center"
                        variant="h2"
                        style={{ margin: "1rem" }}
                      >
                        {`Projected Close Date Moves (Net Moves) - >= 1 Quarter`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{ padding: "1rem", height: "2rem" }}
                    >
                      <ToggleButtonGroup
                        color="primary"
                        indicatorColor="primary"
                        value={this.state.toggleValue}
                        exclusive
                        size="small"
                        onChange={this.handleChangeToggle}
                      >
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "count"
                                ? "#4472C4"
                                : "",
                            color:
                              this.state.toggleValue === "count" ? "white" : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          value="count"
                        >
                          #
                        </ToggleButton>
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "acv" ? "#4472C4" : "",
                            color:
                              this.state.toggleValue === "acv" ? "white" : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          value="acv"
                        >
                          {/* $ */}
                          {this.props.Currency_Symbol}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          justifyContent: "center",
                          marginTop: 20,
                          margin: "auto",
                          width: "95%",
                        }}
                        id="svgContainer"
                        className="svg-container"
                        ref={this._setRef1.bind(this)}
                      />
                      <Legend
                        square={15}
                        type="pcd"
                        just="center"
                        legends={getCustomSortedColoredLegends(
                          this.props.pcdMovedAnalysisData.formattedData
                            .FQ_MovedData.FQ_MovedResult,
                          "FQ_Moved",
                          "acv"
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container component={Card} style={{ marginTop: "1rem" }}>
                  <Grid container item style={{ padding: "1rem" }} xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        align="center"
                        variant="h2"
                        style={{ margin: "1rem" }}
                      >
                        {" "}
                        Projected Close Date Moves (Net Moves) - Quarters Moved
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      margin: "2rem",
                      marginTop: "0rem",
                      paddingRight: "1rem",
                    }}
                    align="center"
                    container
                  >
                    <PCDMovedByQuartersTable
                      Value_Label={this.props.Value_Label}
                      tableData={this.props.pcdMovedAnalysisTrendsData}
                      handleChangeLocation={this.handleChangeLocationQtrsMoved}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="100%"
                style={{ margin: "1rem" }}
              />
            )}
          </>
        )}

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Projected Close Date Moves ${
                this.state.header ? "(Net Moves)" : ""
              }`}
              description={
                this.state.header
                  ? "Showing all the raw moves that were netted out"
                  : null
              }
              back={this.handleChangeLocationBack}
              filters={this.state.drilldownFilters}
            >
              <Drilldown
                body={this.props.drilldownOnGraphData}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                chartName={"Projected Close Date Moves"}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

function mapStateToProps(state) {
  const {
    pcdMovedAnalysisData,
    pcdMovedAnalysisFilters,
    currentFilters,
    pcdMovedMinMaxdate,
    drilldownOnGraphData,
    user,
    ExpectedAcvData,
    pcdMovedAnalysisTrendsData,
  } = state.app;

  return {
    pcdMovedAnalysisData,
    pcdMovedAnalysisTrendsData,
    pcdMovedAnalysisFilters,
    pcdMovedMinMaxdate,
    currentFilters,
    company: user.company,
    Value_Label: user.Value_Label,
    Currency_Symbol: user.Currency,
    drilldownOnGraphData,
    ExpectedAcvData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getPCDMovedAnalysisTrendsData: (filters) => {
    dispatch({ type: "get_pcd_Moved_trends_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getPCDMovedAnalysisTrendsData(filters).then(
      (json) => {
        dispatch({ type: "get_pcd_Moved_trends_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_waterfall_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_waterfall_data_failure", error });
      }
    );
  },
  getPCDMovedAnalysisData: (filters) => {
    dispatch({ type: "get_pcd_Moved_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getPCDMovedAnalysisData(filters).then(
      (json) => {
        dispatch({ type: "get_pcd_Moved_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_waterfall_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_waterfall_data_failure", error });
      }
    );
  },
  getPcdMovedAnalysisFilters: (company) => {
    dispatch({ type: "get_pcd_Moved_filters_request" });
    appService.getPCDMovedAnalysisFilters().then(
      (json) => {
        dispatch({ type: "get_pcd_Moved_filters_success", json });
        dispatch({ type: "get_pcd_Moved_data_request" });
        let stageSequence = getDefaultSelectedStage(
          json.message.filters.stages
        );
        let filters = [];
        let stage_namesOnwards = [];
        json.message.filters.stages.map((i) => {
          if (i.sequence !== undefined) {
            if (i.sequence >= stageSequence)
              stage_namesOnwards = [...stage_namesOnwards, i.stage_name];
          }
        });
        filters = [
          ...filters,
          {
            name: "fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message.filters.fiscal_quarter,
              "fiscal_quarter"
            ),
          },
        ];
        filters = [
          ...filters,
          {
            name: "EarliestStageOnwards",
            value: stage_namesOnwards,
            string: true,
          },
        ];
        // let datefilters = json.message.filters.dateFilters;
        // let fromdate = datefilters.prior1Month;
        // let toDate = datefilters.currentDate;
        let defaultAcv = getDefaultACV(json.message.filters.acvFilter);
        if (![undefined, null, ""].includes(defaultAcv)) {
          filters = [
            ...filters,
            { name: "acvAtTime", value: defaultAcv, string: false },
          ];
        }
        filters = [
          ...filters,
          // { name: "PCDFromDate", value: fromdate, string: true },
          // { name: "PCDToDate", value: toDate, string: true },
        ];
        filters = [
          ...filters,
          {
            name: "monthInQuarter",
            value: [1, 2, 3],
            string: false,
          },
        ];
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filters.salesType,
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filters.dealType,
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }
        filters = [
          ...filters,
          { name: "onlyMovesFromAndToChosenQuarters", value: 1, string: false },
        ];

        const deaultOppType = getDefaultSalesType(
          json.message.filters.oppTypeFilter,
          "Type"
        );
        const defaultSolutionCat = getDefaultSalesType(
          json.message.filters.solutionCat,
          "Solution_Cat"
        );

        filters =
          deaultOppType[0] === "All"
            ? filters
            : [...filters, { name: "Type", value: deaultOppType }];
        filters =
          defaultSolutionCat[0] === "All"
            ? filters
            : [...filters, { name: "Solution_Cat", value: defaultSolutionCat }];
        dispatch({ type: "current_selected_filters", filters });
        appService.getPCDMovedAnalysisData(filters).then(
          (json) => {
            dispatch({ type: "get_pcd_Moved_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_waterfall_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_waterfall_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_waterfall_min_date_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_waterfall_min_date_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  getPcdMovedAnalysisDrilldown: (filters, key, isUnion) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getPcdMovedAnalysisDrilldown(filters, key, isUnion).then(
      (json) => {
        let tempOpportunities = json.message.drilldownData[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) =>
          a.ACV_at_start > b.ACV_at_start ? -1 : 1
        );

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            count_acv: [json.message.drilldownData[0]],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getPcdMovedAnalysisQtrsMovedDrilldown: (filters, key, isUnion) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService
      .getPcdMovedAnalysisQtrsMovedDrilldown(filters, key, isUnion)
      .then(
        (json) => {
          let tempOpportunities = json.message.drilldownData[1].map(
            (item, index) => {
              let x = {
                ...item,
                OppNo: index + 1,
              };
              return x;
            }
          );

          tempOpportunities.sort((a, b) =>
            a.ACV_at_start > b.ACV_at_start ? -1 : 1
          );

          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json = {
            ...json,
            message: {
              count_acv: [json.message.drilldownData[0]],
              opportunities: tempOpportunities,
              columns: json.message.columns,
            },
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});

const connectedPcdMoved = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PcdMovedAnalysis));
export { connectedPcdMoved as PcdMovedAnalysis };
