import { createAsyncThunk } from "@reduxjs/toolkit";
import managementService from "../services/management.service";

export const fetchQuotaFiscalQuartersFilters = createAsyncThunk(
  "skygeni-management/quota-filters",
  async () => {
    const response = await managementService.getQuotaFiscalQuarterFilters();
    return response;
  }
);

export const fetchQuotaData = createAsyncThunk(
  "skygeni-management/quota-data",
  async ({ filters, isDryRun }) => {
    const response = await managementService.getQuotaDataV2(filters, isDryRun);
    return response;
  }
);
export const fetchTeamQuotaData = createAsyncThunk(
  "skygeni-management/quota-data/team",
  async ({ filters, isDryRun }) => {
    const response = await managementService.getTeamQuotaDataV2(
      filters,
      isDryRun
    );
    return response;
  }
);
export const fetchCompanyQuotaData = createAsyncThunk(
  "skygeni-management/quota-data/company",
  async ({ filters, isDryRun }) => {
    const response = await managementService.getCompanyQuotaDataV2(
      filters,
      isDryRun
    );
    return response;
  }
);
export const updateQuotaData = createAsyncThunk(
  "quota-maintainence/quota/update",
  async ({ updatedObjects, isDryRun }) => {
    const response = await managementService.updateQuotaDataV2(
      updatedObjects,
      isDryRun
    );
    return response;
  }
);
export const updateTeamQuotaData = createAsyncThunk(
  "quota-maintainence/quota/team/update",
  async ({ updatedObjects, isDryRun }) => {
    const response = await managementService.updateTeamQuotaData(
      updatedObjects,
      isDryRun
    );
    return response;
  }
);
export const updateCompanyQuotaData = createAsyncThunk(
  "quota-maintainence/quota/company/update",
  async ({ updatedObjects, isDryRun }) => {
    const response = await managementService.updateCompanyQuotaData(
      updatedObjects,
      isDryRun
    );
    return response;
  }
);
