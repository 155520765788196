import { Button, Card, Grid, Paper, Typography, withStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import React from 'react';
import AttainmentSection from './AttainmentSection';
import AttainmentRepSection from './AttainmnetRepSection';
import AttainmentSectionACVRange from './AttainmentSectionACVRange';

const Attainment = (props) => {
  return     (  
    
   <Grid component={Card} style={{ padding: '0.5rem 1rem' }} item xs={12} align='center' >
              { props.isrepScoreCard && <Typography variant='h2' style={{ width: '100%', marginTop: '1rem' }} align="center">Attainment</Typography> }
               <Grid container xs={12} item>
              <Grid item xs={ 12} id='grid-container' style={{ padding: '1rem' }}>
                <AttainmentSection filters={props.filters} selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} type={"YTD"} tableName={props.isrepScoreCard ? "Attainment YTD - Overall" : "Attainment YTD"} tableHeader={props.isrepScoreCard ? "Rep" : "Team / Rep"} goal={"Quota"} isrepScoreCard={props.isrepScoreCard}
                tableID={props.isrepScoreCard ? 'Rep_Attainment_YTD_table' : 'Attainment_YTD_table'}
                isSplitByNonRenewalsRenewals={props.isSplitByNonRenewalsRenewals}
                />
              </Grid >  

              {
                props.isCTScorecard &&  <Grid item xs={ 12} id='grid-container' style={{ padding: '1rem' }}>
                <AttainmentSectionACVRange filters={props.filters} selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} type={"YTD"} tableName={props.isrepScoreCard ? "Attainment YTD - Overall" : "Attainment YTD By ACV range"} tableHeader={props.isrepScoreCard ? "Rep" : "Team / Rep"} goal={"Quota"} isrepScoreCard={props.isrepScoreCard}
                tableID={props.isrepScoreCard ? 'Rep_Attainment_YTD_table' : 'Attainment_YTD_ACV_Range_table'}
                />
              </Grid >  
              }       

              {
                (props.isrepScoreCard && props.showIFCCSec) &&
               
                <Grid item xs={ 12} id='grid-container' style={{padding: '1rem', width: props.isrepScoreCard ? '80%' : '100%' }}>
                <AttainmentRepSection filters={props.filters} selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} type={"YTD"} tableName={"Attainment YTD - IFCC"} tableHeader={props.isrepScoreCard ? "Rep" : "Team / Rep"} goal={"Quota"} isrepScoreCard={props.isrepScoreCard}
                 tableID={'Attainment_YTD_IFCC_table'}/>
              </Grid >
              
              }
              
             {/* <Grid item xs={12} id='grid-container' style={{ }}>
               <AttainmentSection filters={props.filters} selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} type={"completedFQ"} tableName={"Completed Quarters"} tableHeader={"Completed Quarters vs. Goal"} goal={"Goal"} />
             </Grid > */}
             
              <Grid item xs={ 12} id='grid-container' style={{padding: '1rem', width: props.isrepScoreCard ? '80%' : '100%' }}>
                <AttainmentSection filters={props.filters} selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} type={"CFQ"} tableName={props.isrepScoreCard ? "Attainment QTD - Overall"  : "Attainment - Current Quarter"} tableHeader={props.isrepScoreCard ? "Rep" : "Team / Rep"} goal={"Quota"} isrepScoreCard={props.isrepScoreCard}
                isSplitByNonRenewalsRenewals={props.isSplitByNonRenewalsRenewals}
                tableID={props.isrepScoreCard ? 'Rep_Attainment_QTD_table' : 'Attainment_QTD_table'}/>
              </Grid >
              
              {
                props.isCTScorecard &&  <Grid item xs={ 12} id='grid-container' style={{ padding: '1rem' }}>
                <AttainmentSectionACVRange filters={props.filters} selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} type={"CFQ"} tableName={props.isrepScoreCard ? "Attainment QTD - Overall" : "Attainment - Current Quarter By ACV range"} tableHeader={props.isrepScoreCard ? "Rep" : "Team / Rep"} goal={"Quota"} isrepScoreCard={props.isrepScoreCard}
                tableID={props.isrepScoreCard ? 'Rep_Attainment_QTD_table' : 'Attainment_QTD_ACV_RANGE_table'}
                />
              </Grid >  
              }     

              {
                (props.isrepScoreCard && props.showIFCCSec) &&
               
                <Grid item xs={ 12} id='grid-container' style={{ padding: '1rem', width: props.isrepScoreCard ? '80%' : '100%'}}>
                <AttainmentRepSection filters={props.filters} selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} type={"CFQ"} tableName={"Attainment QTD - IFCC"} tableHeader={props.isrepScoreCard ? "Rep" : "Team / Rep"} goal={"Quota"} isrepScoreCard={props.isrepScoreCard}
                tableID={'Attainment_QTD_IFCC_table'}/>
              </Grid >
             
              }  
              </Grid>
              </Grid>     
);
};

export default Attainment;