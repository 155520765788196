import {
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@material-ui/core";
  import React, { useEffect, useRef, useState } from "react";
  import {
    countOrACVFormatterMillion,
    customDateFormatter,
    getUniqueValues,
    StyledTableRow,
  } from "../../util/customFunctions";
  
  import { makeStyles } from "@material-ui/core";
  import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
  
  const tableStyle = makeStyles(
    (theme) => ({
      root: {
        margin: "0.5rem",
        padding: "0.5rem",
      },
      colHeaderNew: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
        backgroundColor: "inherit",
      },
      colHeader: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: 600,
      },
      dataCell: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
      },
      drilldownCell: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
        // cursor: "pointer",
      },
      drilldownCellNQP: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: "3%",
        fontWeight: "inherit",
        cursor: "pointer",
      },
      cellMargin: {
        fontSize: "0.85rem",
        border: "0.5px solid lightgrey",
        padding: "7px 10px",
        color: "inherit",
        width: 120,
        fontWeight: "inherit",
        paddingLeft: "2rem",
      },
      fontBold: {
        fontWeight: 600,
      },
      indentName: {
        paddingLeft: 30,
      },
      noBorder: {
        border: "none",
      },
      primaryColumnColor: {
        backgroundColor: "#4472C4",
        color: "white",
      },
    }),
    { index: 1 }
  );
  
  function SummaryTableNew(props) {
    const tableRef = React.createRef();
    const tableContainerRef = useRef();
  
    const [parentWidth, setparentWidth] = useState();
  
   
  
    useEffect(() => {
      // Get the width of the parent TableContainer
      const parentWidth = tableContainerRef.current.getBoundingClientRect().width;
      setparentWidth(parentWidth);
    }, [parentWidth]);
  
    const classes = tableStyle();

    

    return (
      <div
        style={{
          maxWidth: "100%",
          justifyContent: "center",
          padding: "2.5rem",
          paddingTop: 0,
        }}
      >
        {/* <Grid container component={Card} raised> */}
        <Grid xs={12}>
          {parentWidth !== undefined ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginBottom: "0.6rem",
                // marginRight: "1rem",
                width: `${
                  tableContainerRef.current.getBoundingClientRect().width
                }px`,
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null}
        </Grid>
        <TableContainer
          style={{
            width: "auto",
          }}
          id="myTableContainer-Second"
          ref={tableContainerRef}
        >
          <Table ref={tableRef} data-test={props.dataTest} id={props.tableID}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.colHeader}
                  style={{
                    // minWidth: 150,
                    whiteSpace: "nowrap",
                    background: "white",
                  }}
                >
                  {/* Team / Rep ↓ / Week Ending → */}
                </TableCell>
               
                  <TableCell
                    colSpan={4}
                    style={
                       {
                            background: "#4471c4",
                            color: "white",
                            // minWidth: "7rem",
                          }
                       
                    }
                    align="center"
                    className={classes.colHeader}
                  >
                   {props.totalWeekText}
                  </TableCell>
                  <TableCell
                   
                    style={
                      
                         {
                            background: "none",
                            color: "white",
                            minWidth: "7rem",
                            border: "none"
                          }
                    }
                    align="center"
                    className={classes.colHeader}
                  >
                  
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    style={
                      
                         {
                            background: "#5b9bd5",
                            color: "white",
                            minWidth: "7rem",
                          }
                    }
                    align="center"
                    className={classes.colHeader}
                  >
                   {props.newTitle}
                  </TableCell>



              </TableRow>
           
              <TableRow>
              <TableCell
                    colSpan={1}
                    style={{
                        // minWidth: 150,
                        whiteSpace: "nowrap",
                        background: "white",
                      }}
                    align="center"
                    className={classes.colHeader}
                  >
                   {''}
                  </TableCell>
                {["Total Goal", "Actual", "% of Goal", "Gap to Total Goal"].map(it =>
                    <TableCell
                                                                             align="center"
                                                                             className={classes.colHeader}
                                                                             colSpan={1}
                                                                           >
                  {it}
                </TableCell>
                )}
                
               
                  
                  <TableCell
                   
                    style={
                      
                         {
                            background: "none",
                            color: "white",
                            minWidth: "7rem",
                            border: "none"
                          }
                    }
                    align="center"
                    className={classes.colHeader}
                  >
                  
                  </TableCell>
                  {["Goal", "Actual", "% of Goal", "Delta"].map(it =>
                    <TableCell
                                                                             align="center"
                                                                             className={classes.colHeader}
                                                                             colSpan={1}
                                                                           >
                  {it}
                </TableCell>
                )}



              </TableRow>
            </TableHead>
              {
                            [...props.rows, 'Total'].map((row,i) => {
                                const data = props.data.filter(f => f[props.newType] === row)
                                const totalGoal = data.length > 0 ? data[0].totalGoal : null
                                const goal = data.length > 0 ? data[0].goal : null
                                const actual = data.length > 0 ? data[0].actual : null
                                const totalActual = data.length > 0 ? data[0].totalActual : null
                                const delta = data.length > 0 ? data[0].delta : null
                                const perc = data.length > 0 ? data[0].perc_of_total : null
                                const percTotal = data.length > 0 ? data[0].total_perc_of_Total : null
                                const rs = row === 'Total' ? props.rows : [row]
                                const gap = data.length > 0 ? data[0].gap : null
                               const source = props.source === 'By Team' ? 't' : 's'
    
                                let bg = ''
                                let color = ''
                                if(delta < 0){
                                    color= 'red'
                                }
    
                                if(actual >= goal && goal > 0){
                                    bg= '#e2efda'
                                }
                                return <StyledTableRow key={i}>
                                <TableCell
                                className={classes.colHeader}
                                style={{
                                  // minWidth: 150,
                                  whiteSpace: "nowrap",
                                  
                                }}
                              >
                                {row}
                              </TableCell>
                              
             <TableCell
                                        align="right"
                                        className={classes.colHeader}
                                        colSpan={1}
                                      >
                                        {[null, undefined, ''].includes(totalGoal) ? '-' : countOrACVFormatterMillion(totalGoal, true)}
                                      </TableCell>

                                      <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                            style={  {cursor: 'pointer'}}
                            onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  {
                                    row: source === 's' ? rs : props.splits,
                                    cell:
                                      data[0][props.timeframe],
                                    repOrTeam:
                                    source === 's' ? props.splits : rs
                                  },
                                  props.type,
                                  props.timeframe,
                                  props.gb
                                )
                              }
                          >
                            {[null, undefined, ''].includes(totalActual) ? '-' : countOrACVFormatterMillion(totalActual, true)}
                          </TableCell>

                          <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                          >
                            {[null, undefined, ''].includes(percTotal) ? '-' : `${Math.round(percTotal * 100)}%`}
                          </TableCell>

                          <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                          >
                            {[null, undefined, ''].includes(gap) ? '-' : countOrACVFormatterMillion(gap, true)}
                          </TableCell>


                          <TableCell
                            align="right"
                            
                            colSpan={1}
                            style={{
                                background: 'white',
                                border: 'none'
                            }}
                          >
                           
                          </TableCell>


                          <TableCell
                                        align="right"
                                        className={classes.colHeader}
                                        colSpan={1}
                                      >
                                        {[null, undefined, ''].includes(goal) ? '-' : countOrACVFormatterMillion(goal, true)}
                                      </TableCell>

                                      <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                            style={ bg ? {background: bg, cursor: 'pointer'} : {cursor: 'pointer'}}
                            onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  {
                                    row: source === 's' ? rs : props.splits,
                                    cell:
                                      data[0][props.timeframe],
                                    repOrTeam:
                                    source === 's' ? props.splits : rs
                                  },
                                  props.type,
                                  props.timeframe,
                                  props.gb
                                )
                              }
                          >
                            {[null, undefined, ''].includes(actual) ? '-' : countOrACVFormatterMillion(actual, true)}
                          </TableCell>

                          <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                          >
                            {[null, undefined, ''].includes(perc) ? '-' : `${Math.round(perc * 100)}%`}
                          </TableCell>

                          <TableCell
                            align="right"
                            className={classes.colHeader}
                            colSpan={1}
                            style={
                              
                                color ? {color: color} : {}}
                          >
                            {[null, undefined, ''].includes(delta) ? '-' : countOrACVFormatterMillion(delta, true)}
                          </TableCell>

          
                              </StyledTableRow>
                            })

                            
                                
                          }
            <TableBody>
              
               

               

              




         

                

            </TableBody>
           
          </Table>
        </TableContainer>
        {/* </Grid> */}
      </div>
    );
  }
  export default SummaryTableNew;
  