import React from "react";
import ResetPasswordFormikComponent from "../App/ResetPasswordFormikComponent";

const ChangePasswordForm = ({
  forgotPassEmail,
  changePass,
  errorMessage,
  open,
  alert,
  classes,
  setState,
  persistentAlert,
  handleChangeLocation,
  forgotPass,
  changePasswordSuccess,
}) => (
  <ResetPasswordFormikComponent
    type="resetpassword"
    title="Reset Password"
    changePass={changePass}
    errorMessage={errorMessage}
    open={open}
    alert={alert}
    classes={classes}
    setState={setState}
    persistentAlert={persistentAlert}
    handleChangeLocation={handleChangeLocation}
    forgotPass={forgotPass}
    forgotPassEmail={forgotPassEmail}
    changePasswordSuccess={changePasswordSuccess}
  />
);

export default ChangePasswordForm;
