import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  makeStyles,
  Grid,
} from "@material-ui/core";
import StyledTableRow from "../../components/StyledTableRow";
import { format as d3Formatter, pointer } from "d3";
import { formatDataValues } from "../../util/dataFormatter";
import { getUniqueValues, _isNaN } from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";

const useStyle = makeStyles((theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
  colHeader: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  drilldowncell: {
    cursor: "pointer",
  },
}));

export default function SummaryViewTable(props) {
  let { data, uniqueXYAxisValues, xyAxis } = props;
  const [isOverflowing, setIsOverflowing] = useState(false);

  const {
    PBISummaryData,
    totalAcvVertical,
    totalRow,
    totalAcv,
    highestValues,
    highestValuesByPercentRow,
  } = data;
  var uniqueXAxisValues = uniqueXYAxisValues.uniqueXAxisValues;
  var uniqueYAxisValues = uniqueXYAxisValues.uniqueYAxisValues;
  var xAxis = xyAxis.xValue;
  var yAxis = xyAxis.yValue;

  console.log(xyAxis, xAxis, yAxis, "xy");

  const classes = useStyle();
  const tableRef = React.createRef();

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  return (
    <Grid item xs={11} justifyContent="center">
      {isOverflowing ? (
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "10px",
            // marginRight: "2rem",
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
      ) : null}
      <TableContainer style={{ margin: "2rem" }} id="myTableContainer">
        {isOverflowing === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              // marginRight: "10px",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Table ref={tableRef} data-test={props.data_test} size="small">
          <TableHead></TableHead>
          <TableBody>
            {" "}
            <TableRow
              style={{
                fontWeight: 600,
                // background: "#4472c4",
                // color: "white",
                paddingLeft: 5,
              }}
            >
              <TableCell
                align="center"
                className={classes.colHeader}
                style={{ background: "white" }}
              ></TableCell>
              {uniqueXAxisValues.map((s) => (
                <TableCell
                  align="center"
                  className={classes.colHeader}
                  style={{ background: "#4471c4", color: "white" }}
                >
                  {s}
                </TableCell>
              ))}
              <TableCell
                align="center"
                className={classes.colHeader}
                style={{ background: "#4471c4", color: "white" }}
              >
                Total{" "}
              </TableCell>
            </TableRow>
            {uniqueYAxisValues.map((item) => {
              const rowTotal = ![null, undefined].includes(
                totalAcvVertical.filter((i) => i.vertical === item)[0]
              )
                ? totalAcvVertical.filter((i) => i.vertical === item)[0][
                    props.isAcv ? "value" : "percentage"
                  ]
                : 0;
              return (
                <StyledTableRow>
                  <TableCell align="left" className={classes.colHeader}>
                    {item}
                  </TableCell>
                  {uniqueXAxisValues.map((s, j) => {
                    const cellData = ![null, undefined].includes(
                      PBISummaryData.filter(
                        (i) => i[xAxis] === s && i[yAxis] === item
                      )[0]
                    )
                      ? PBISummaryData.filter(
                          (i) => i[xAxis] === s && i[yAxis] === item
                        )[0][props.isAcv ? "acv" : "percentage"]
                      : 0;

                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          align="right"
                          onClick={() => {
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              [item],
                              [s]
                            );
                          }}
                          className={`${classes.colHeader} ${classes.drilldowncell}`}
                          style={
                            highestValues.filter(
                              (highValue) =>
                                highValue.vertical === s &&
                                highValue.key === item
                            )[0] !== undefined
                              ? {
                                  background: highestValues.filter(
                                    (highValue) =>
                                      highValue.vertical === s &&
                                      highValue.key === item
                                  )[0].background,
                                  color: highestValues.filter(
                                    (highValue) =>
                                      highValue.vertical === s &&
                                      highValue.key === item
                                  )[0].color,
                                  fontWeight: 600,
                                }
                              : {}
                          }
                        >{`${formatDataValues(
                          _isNaN(cellData) ? 0 : cellData,
                          props.isAcv
                        )}`}</TableCell>
                      </React.Fragment>
                    );
                  })}
                  <TableCell
                    align="right"
                    onClick={() => {
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        [item],
                        uniqueXAxisValues
                      );
                    }}
                    className={`${classes.colHeader} ${classes.drilldowncell}`}
                    style={
                      !props.isAcv
                        ? highestValues.filter(
                            (highValue) => highValue.vertical === item
                          )[0] !== undefined
                          ? {
                              background: highestValues.filter(
                                (highValue) => highValue.vertical === item
                              )[0].background,
                              color: highestValues.filter(
                                (highValue) => highValue.vertical === item
                              )[0].color,
                              fontWeight: 600,
                            }
                          : {}
                        : {}
                    }
                  >
                    {" "}
                    {`${formatDataValues(
                      _isNaN(rowTotal) ? 0 : rowTotal,
                      props.isAcv
                    )}`}{" "}
                  </TableCell>
                </StyledTableRow>
              );
            })}
            <StyledTableRow>
              <TableCell align="left" className={classes.colHeader}>
                Total
              </TableCell>
              {uniqueXAxisValues.map((s, j) => {
                const cellData = ![null, undefined, ""].includes(
                  totalRow.filter((i) => i.row === s)[0]
                )
                  ? totalRow.filter((i) => i.row === s)[0][
                      props.isAcv ? "acv" : "percentage"
                    ]
                  : 0;
                return (
                  <React.Fragment key={j}>
                    <TableCell
                      align="right"
                      onClick={() => {
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          uniqueYAxisValues,
                          [s]
                        );
                      }}
                      style={
                        highestValuesByPercentRow !== undefined
                          ? highestValuesByPercentRow.filter(
                              (item) => item.key === s
                            )[0] !== undefined
                            ? {
                                background: highestValuesByPercentRow.filter(
                                  (item) => item.key === s
                                )[0].background,
                                color: highestValuesByPercentRow.filter(
                                  (item) => item.key === s
                                )[0].color,
                                fontWeight: 600,
                              }
                            : {}
                          : {}
                      }
                      className={`${classes.colHeader} ${classes.drilldowncell}`}
                    >{`${formatDataValues(
                      _isNaN(cellData) ? 0 : cellData,
                      props.isAcv
                    )}`}</TableCell>
                  </React.Fragment>
                );
              })}

              <TableCell
                align="right"
                onClick={() => {
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    uniqueYAxisValues,
                    uniqueXAxisValues
                  );
                }}
                className={`${classes.colHeader} ${classes.drilldowncell}`}
              >
                {`${formatDataValues(
                  _isNaN(totalAcv) ? 0 : totalAcv,
                  props.isAcv
                )}`}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
