import { Card,Typography,Grid} from '@material-ui/core'
import React,{useEffect,useState} from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { connect } from 'react-redux'
import { appService } from '../../../../App/app.service';

import Drilldown from '../../Drilldown';
import SingleLevelDrilldown from '../../../../components/Drilldown/SingleLevelDrilldown';
import AttainmentTable from './AttainmentTable';
import QTDDealsTable from './QTDDealsTable'
import ExpectedAcvPage from '../../../../EnhancedInsights/NewWaterFall/ExpectedAcvPage';


const styles = theme => ({
    gridItem: {
        width:'100%',
        padding:'1rem'
      }
})

const classes=styles()

const Attainment = (props) => {
    const [location, setlLocation] = useState('');
    const [filtersForDrilldown, setFiltersForDrilldown] = useState([]);
    const [secondlevellocation,setsecondlevellocation] = useState('')

    useEffect(() => {
        console.log(props.filters, props.filters === undefined || props.filters === null, !([undefined, null].includes(props.filters)))
       if(!([undefined, null, ""].includes(props.filters))){
        props.getAttainmentData(props.filters,props.type)
          if(props.type === 'CFQ') {
            props.getQTDGreaterThan20kData(props.filters)
          }
       }
      }, [props.filters])

      const handleChangeLocationBack = () => {
        console.log(location)
        setlLocation('')
        setsecondlevellocation('')
      }


      const handleSecondLevelDrilldown = (location, oppId) => {
        console.log(oppId)
        setsecondlevellocation(location)
        props.getOpenOppData(oppId)
      }

      const  stepBack = () => {
        setsecondlevellocation('')
        props.clearExpectedAcvData()
      }
      const handleChangeLocation = (location,bookingTypes,team,key) => {
        var filters=[...props.filters]
        var drilldownfilter=[]
        if(key === 'QTD20') {
          filters=[...filters, {name: props.selectedTeam.includes('All') ? 'Team' : 'Owner_Name',value: team} ]
          let teamOrOwnerNameBubble = props.selectedTeam.includes('All') && props.selectedRep.includes('All') ? {title:'Team',value:team.length > 1 ? ['All'] : team} : {title: team.length > 1 ? 'Team' : 'Rep',value:team.length > 1 ? props.selectedTeam.includes('All') ? props.selectedRep: props.selectedTeam: team}
          drilldownfilter=[teamOrOwnerNameBubble,{title: 'Closed Fiscal Quarter', value: props.QTDGreaterthan20kData.fiscalQuartersForFrontEnd},{title:'ACV',value:'>=$30,000'},...drilldownfilter]
          props.getAttainmentSectionDrilldown(filters, props.type,'QTD20')
        } else {
          filters=[...filters, {name: "Booking_Type_NL_CS_US",value: bookingTypes }]
          drilldownfilter= [{ title:'Opportunity Type' ,value:bookingTypes.length>1 ? ['All'] : bookingTypes}]
          if(team !== undefined) {
              var filter=props.selectedTeam.includes('All') && props.selectedRep.includes('All') ?{name:'Team',value:team} : {name:'Owner_Name',value:team}
              filters=[...filters, filter]
              drilldownfilter=[{title:props.selectedTeam.includes('All') && props.selectedRep.includes('All') ? 'Team' : 'Rep',value:team},...drilldownfilter]
          }
          props.getAttainmentSectionDrilldown(filters, props.type)

        }
       
        setlLocation(location)
        setFiltersForDrilldown(drilldownfilter)
      }

    return (
        <Grid container justify="flex-start" >
            <Grid item xs={12} >
            {
                 props[`AttainmentData${props.type}`] !== '' ?
                <Grid item xs={12}>
                    <Typography variant='h2' style={{ width: '100%', margin: 'auto',}} align="center">{props.tableName}</Typography>
                    <Typography variant='body1' style={{ width: '100%', margin: 'auto', 'padding-bottom': "1rem",  fontWeight: 600 }} align="center">({props[`AttainmentData${props.type}`].fiscalQuarters !== undefined ?  Array.isArray(props[`AttainmentData${props.type}`].fiscalQuarters) ? props[`AttainmentData${props.type}`].fiscalQuarters.sort().join(', ') : props[`AttainmentData${props.type}`].fiscalQuarters : ''})</Typography>                    
                   <AttainmentTable AttainmentSegmentRow={ props[`AttainmentData${props.type}`].segmentRow} AttainmentTable={props[`AttainmentData${props.type}`].table} selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} handleChangeLocation={handleChangeLocation} tableHeader={props.tableHeader} goal={props.goal} dataTest={`Attainment${props.type}`} attainmentSplitUp={props[`AttainmentData${props.type}`].splitup} />
                 </Grid> : 
                <Grid component={Card} item container  justify="center">
                <Skeleton variant="react" animation="wave" height={120} width='95%' style={{ margin: '1rem' }} />
            </Grid> 
            }
            </Grid>
            {
              props.QTDGreaterthan20kData !== '' ? props.type === 'CFQ' && <QTDDealsTable dataTest={`AttainmentQtdDeals${props.type}`} qtdDealsSegmentRow={props.QTDGreaterthan20kData.segmentRow} qtdDealsTableData={props.QTDGreaterthan20kData.table}  selectedTeam={props.selectedTeam} selectedRep={props.selectedRep} handleChangeLocation={handleChangeLocation} /> : <></>
            }
            {location === "drilldownOnGraph" &&
                    <Grid item xs={12} style={{ position: 'absolute', width: '100%', marginTop: '30px' }}>

                        <SingleLevelDrilldown
                            header={"Won Opportunities"}
                            back={handleChangeLocationBack}
                            filters={filtersForDrilldown}>

                            <Drilldown header={"Won Opportunities"} body={props.drilldownOnGraphData} handleSecondLevelDrilldown={handleSecondLevelDrilldown} showPointer={true} showService={true} />
                        </SingleLevelDrilldown>

                    </Grid >

                }
                       {secondlevellocation === "drilldownOppId"  &&
          <Grid item xs={12} style={{ position: 'absolute', width: '100%', marginTop: '30px' }}>

            <SingleLevelDrilldown
              header={'Opportunity Change Events'}
              back={handleChangeLocationBack}
              stepBack={stepBack}
              filters={[]}>

              <ExpectedAcvPage  body={props.ExpectedAcvData} />
            </SingleLevelDrilldown>

          </Grid >
        }
            </Grid>
        )
}
const mapStateToProps = state => {
    const { AttainmentDataYTD,AttainmentDataCFQ,AttainmentDatacompletedFQ, user,drilldownOnGraphData,QTDGreaterthan20kData, ExpectedAcvData} = state.app;

    return {
      AttainmentDataYTD,AttainmentDataCFQ,AttainmentDatacompletedFQ,user,drilldownOnGraphData,QTDGreaterthan20kData,ExpectedAcvData
    }
  }
  const mapDispatchToProps = (dispatch) => ({
    getAttainmentData: (filters,type) => {
        dispatch({ type: `get_attainment_data_${type}_request` })
        appService.getAttainmentData(filters,type)
          .then(json => {
            dispatch({ type: `get_attainment_data_${type}_success`, json })
          }, error => {
            if (typeof error === 'object')
              dispatch({ type:`get_attainment_data_${type}_failure`, error: 'Something went wrong' })
            else
              dispatch({ type: `get_attainment_data_${type}_failure`, error })
          })
    },
      getAttainmentSectionDrilldown: (filters, key,key2) => {
      dispatch({ type: 'get_drilldown_on_graph_request' })
      appService.getAttainmentSectionDrilldown(filters, key,key2)
          .then(json => {
            let tempOpportunities = json.message.opportunities[1].sort((a, b) => a.ACV > b.ACV ? -1 : 1)
            tempOpportunities = tempOpportunities.map((item, index) => {
              let temp = {
                ...item,
                OppNo: index + 1
              }
              return temp
            })
    
            json.message = {
              ...json,
                columns:json.message.columns,
                count_acv:json.message.opportunities[0],
                opportunities: tempOpportunities
              }
              dispatch({ type: 'get_drilldown_on_graph_success', json })
          }, error => {
              if (typeof error === 'object') dispatch({ type: 'get_drilldown_on_graph_failure', error: 'Something went wrong' })
              else
                  dispatch({ type: 'get_drilldown_on_graph_failure', error })
          })
  },
  getQTDGreaterThan20kData: (filters) => {
    dispatch({ type: `get_qtd_greaterthan20k_data_request` })
    appService.getQTDGreaterThan20kData(filters)
      .then(json => {
        dispatch({ type: `get_qtd_greaterthan20k_data_success`, json })
      }, error => {
        if (typeof error === 'object')
          dispatch({ type:`get_qtd_greaterthan20k_data_failure`, error: 'Something went wrong' })
        else
          dispatch({ type: `get_qtd_greaterthan20k_data_failure`, error })
      })
}, getOpenOppData: (oppId) => {
  dispatch({ type: 'get_expectedAcv_drilldown_data_request' })
  appService.getWaterfallOppData(oppId)
    .then(json => {
      dispatch({ type: 'get_expectedAcv_drilldown_data_success', json })
    }, error => {
      if (typeof error === 'object') dispatch({ type: 'get_expectedAcv_drilldown_data_failure', error: 'Something went wrong' })
      else
        dispatch({ type: 'get_expectedAcv_drilldown_data_failure', error })
    })
},
clearExpectedAcvData: () => {
  dispatch({ type: 'clear_expected_acv_data' })
},
    clearDrilldownData: () => {
      dispatch({ type: 'clear_drilldown_data' })
    }
  })
const AttainmentSection = connect(mapStateToProps, mapDispatchToProps)(Attainment)
export default AttainmentSection