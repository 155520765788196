import {
  Table,
  TableContainer,
  TableCell,
  Grid,
  TableBody,
} from "@material-ui/core";
import React from "react";
import tableStyle from "../../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  StyledTableRow,
} from "../../../util/customFunctions";
import CopyTablesObject from "../../../components/xlsx-export/CopyTablesObject";

const ExpectedAcvDimensionTable = (props) => {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const formatter = (item) => {
    if (["acv", "count"].includes(item.type)) {
      return countOrACVFormatter(item.value, true);
    }
    if (item.type === "percent") {
      return `${(item.value * 100).toFixed(2)}%`;
    }
  };
  return (
    <Grid item container xs={12}>
      <TableContainer>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
            // marginRight: "10px",
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div> */}
        <Table ref={tableRef} data-test="probability">
          <TableBody>
            {props.data.map((item) => (
              <StyledTableRow key={item.key}>
                <TableCell className={classes.dataCell} align="left">
                  {item.key}
                </TableCell>
                <TableCell
                  className={classes.dataCell}
                  style={{ width: "17%", fontWeight: 600 }}
                  align="right"
                >
                  {formatter(item)}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default ExpectedAcvDimensionTable;
