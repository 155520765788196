import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 140,
    backgroundSize: "contain",
  },
  outer: {
    width: `90%`,
    //paddingTop: `${(90 * 0.5625)}%`,
    margin: "10px 0px",
    [theme.breakpoints.up("md")]: {
      position: "relative",
      width: "35%",
      paddingTop: `${35 * 0.5625}%`,
      margin: "20px 30px",
    },
  },
  header: {
    margin: 0,
    [theme.breakpoints.up("md")]: {
      marginBottom: 20,
    },
  },
  inner: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      height: "100%",
    },
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <Card
      id={`navlink_${props.header === 'Performance Scorecard (CT)' ? 'Performance_Scorecard_CT' : props.header.split(" ").join("_")}`}
      raised={true}
      className={classes.outer}
    >
      <CardActionArea
        onClick={() => props.changeLocation(props.header)}
        className={classes.inner}
      >
        <CardContent style={{ width: "100%" }}>
          <Typography
            className={classes.header}
            variant="h2"
            component="h2"
            align="center"
          >
            {props.header}
          </Typography>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body1" color="textSecondary">
                {props.text}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <CardMedia
                className={classes.media}
                image={props.image}
                title={props.header}
              />
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
