import React, { Component } from "react";
// import Button from "@material-ui/core/Button";
import {
  Button,
  Card,
  FormControl,
  TextField,
  InputAdornment,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  // withStyles,
  CardContent,
} from "@material-ui/core";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { appService } from "../App/app.service";
import InsightsCard from "../components/InsightsCard";
import Skeleton from "@mui/material/Skeleton";
// import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { color } from "d3";

const styles = (theme) => ({
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "10vh", // Centers vertically on the screen
  },
  // button: {
  //   background: "blue", // Replace with your desired button styling
  //   color: "white",
  //   marginRight: "10px", // Add some spacing between the button and the link
  // },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
});

class GeneratePDFNew extends Component {
  state = {
    location: "home",
    loading: false,
    data: this.props.QuotaDatapdf,
    callData: false,
  };

  getURLS = async () => {
    console.log("function called");
    await this.props.getURLS();
    this.setState({ callData: true });
  };
  componentDidMount() {
    console.log("second_compdidmount");
    this.getURLS();
    this.setState({ loading: false });
  }
  handleLocationChange = (loc) => {
    this.setState({ location: loc });
    // this.props.changeRepPerformancePage(loc);
  };

  // Function to make the API call
  callAPI = async () => {
    console.log("button clicked");
    this.setState({ loading: true });
    try {
      await this.props.generatePDFAPI();
      console.log("butto_first_Loaded");
      this.setState({ loading: false });
    } catch (error) {
      console.log("butto_second_Loaded");
      console.error("Error fetching QuotaDatapdf:", error);
      this.setState({ loading: false });
    }
  };

  deleteDocs = () => {
    console.log("button clicked");
    this.props.deleteQuotaDocs();
  };

  render() {
    const { classes } = this.props;

    if (this.props.QuotaDatapdf.generatedDocsCount) {
      console.log(
        "docs_length",
        this.props.QuotaDatapdf.generatedDocsCount.length
      );
      console.log("newdocs_length", this.props.QuotaDatapdf.generatedDocsCount);
    }
    let urlsData;
    const extractedURLs = {};
    if (this.props.QuotaUrls) {
      urlsData = this.props.QuotaUrls.map((item) => {
        return {
          [item.Property]: item.Value,
        };
      });
      urlsData.forEach((item) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        extractedURLs[key] = value;
      });
    }

    return (
      <div>
        {urlsData && this.state.callData ? (
          <div>
            <Typography variant="h2" align="center" style={{ marginBottom: 5 }}>
              Quota Letters Generation
            </Typography>

            <Typography
              variant="body1"
              align="center"
              style={{
                // color: "rgb(166,166,166)",
                color: "black",
                marginTop: "10px",
              }}
            >
              Generate customized quota letters for all reps in a fully
              automated manner
            </Typography>
            <Card
              style={{
                margin: "auto",
                width: "90%",
                justifyContent: "center",
                marginTop: "30px",
                height: "30rem",
              }}
              // raised={true}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // minHeight: "40vh",
                    // marginTop: "20px",
                  }}
                >
                  <div
                    className={classes.centerContainer}
                    style={{ justifyContent: "center" }}
                  >
                    <TableContainer>
                      <Table style={{ fontSize: "0.875rem" }}>
                        <TableHead></TableHead>
                        <TableBody>
                          <TableRow
                            style={{ borderBottom: "none", fontWeight: 600 }}
                          >
                            <TableCell
                              style={{
                                borderBottom: "none",
                                width: "20%",
                                whiteSpace: "nowrap",
                                // padding: "0.5rem",
                                fontSize: "0.875rem",
                              }}
                            >
                              Google Sheet containing quotas:
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: "none",
                                width: "60%",
                                // padding: "0.5rem",
                                whiteSpace: "nowrap",
                                fontSize: "0.875rem",
                              }}
                            >
                              {extractedURLs.Quota_Sheet_URL ? (
                                <a
                                  href={`${extractedURLs.Quota_Sheet_URL}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "#4472c4",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {`${extractedURLs.Quota_Sheet_URL}`}
                                </a>
                              ) : (
                                <p>"Please add url"</p>
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            style={{ borderBottom: "none", fontWeight: 600 }}
                          >
                            <TableCell
                              style={{
                                borderBottom: "none",
                                width: "20%",
                                whiteSpace: "nowrap",
                                // padding: "0.5rem",
                                fontSize: "0.875rem",
                              }}
                            >
                              Folder with Quota Letter Templates:
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: "none",
                                width: "60%",
                                // padding: "0.5rem",
                                fontSize: "0.875rem",
                              }}
                            >
                              {extractedURLs.Quota_Letters_Folder_URL ? (
                                <a
                                  href={`${extractedURLs.Templates_Folder_URL}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "#4472c4",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {`${extractedURLs.Templates_Folder_URL}`}
                                </a>
                              ) : (
                                <p>"Please add url"</p>
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            style={{ borderBottom: "none", fontWeight: 600 }}
                          >
                            <TableCell
                              style={{
                                borderBottom: "none",
                                width: "20%",
                                whiteSpace: "nowrap",
                                // padding: "0.5rem",
                                fontSize: "0.875rem",
                              }}
                            >
                              Location where quota letters will be saved:
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: "none",
                                width: "60%",
                                // padding: "0.5rem",
                                fontSize: "0.875rem",
                              }}
                            >
                              {extractedURLs.Quota_Letters_Folder_URL ? (
                                <a
                                  href={`${extractedURLs.Quota_Letters_Folder_URL}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "#4472c4",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {`${extractedURLs.Quota_Letters_Folder_URL}`}
                                </a>
                              ) : (
                                <p>"Please add url"</p>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                        <TableBody>
                          <TableRow style={{ borderBottom: "none" }}>
                            <TableCell
                              style={{
                                borderBottom: "none",
                                width: "100%",
                                textAlign: "left",
                                // padding: "0.5rem",
                                fontSize: "0.875rem",
                                whiteSpace: "nowrap",
                              }}
                              colSpan={2}
                            >
                              To update these, please get in touch with SkyGeni
                              at{" "}
                              {extractedURLs.SkyGeni_URL ? (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "#4472c4",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {`${extractedURLs.SkyGeni_URL}`}
                                </a>
                              ) : (
                                ""
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>

                <div className={classes.centerContainer}>
                  <div
                    style={{
                      position: "relative",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    {this.state.loading ? (
                      <Button
                        className={classes.button}
                        variant="contained"
                        // color="red"
                        style={{ borderRadius: 0, background: "#a5a5a5" }}
                        // onClick={this.callAPI}
                        disabled
                      >
                        <p style={{ color: "white" }}>
                          Generating Quota Letters
                        </p>
                      </Button>
                    ) : (
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        style={{ borderRadius: 0 }}
                        onClick={this.callAPI}
                        disabled={
                          typeof extractedURLs.Quota_Sheet_URL ===
                            "undefined" ||
                          typeof extractedURLs.Quota_Letters_Folder_URL ===
                            "undefined" ||
                          typeof extractedURLs.Templates_Folder_URL ===
                            "undefined"
                        }
                      >
                        <p style={{ color: "white" }}>Generate quota letters</p>
                      </Button>
                    )}
                    {this.state.loading && (
                      <div style={{ marginTop: "20px" }}>
                        <img
                          src="/test_loading.gif"
                          alt="Loading..."
                          style={{
                            width: "50px",
                            height: "50px",
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className={classes.centerContainer}>
                  {this.props.QuotaDatapdf && this.state.loading !== true ? (
                    <div style={{ marginTop: "40px" }}>
                      {this.props.QuotaDatapdf.generatedDocsCount > 1 ? (
                        <p style={{ display: "inline" }}>
                          {`${this.props.QuotaDatapdf.generatedDocsCount}`}{" "}
                          quota letters generated and saved at :
                        </p>
                      ) : (
                        <p style={{ display: "inline" }}>
                          {`${this.props.QuotaDatapdf.generatedDocsCount}`}{" "}
                          quota letter generated and saved at :
                        </p>
                      )}

                      <a
                        href={`${extractedURLs.Quota_Letters_Folder_URL}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#4472c4",
                          textDecoration: "underline",
                        }}
                      >
                        {`${extractedURLs.Quota_Letters_Folder_URL}`}
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className={classes.centerContainer}>
                  {this.props.QuotaDatapdf.templatesNTF &&
                  this.props.QuotaDatapdf.templatesNTF.length > 0 &&
                  this.state.loading !== true ? (
                    <div style={{ marginTop: "40px" }}>
                      <p style={{ display: "inline" }}>
                        {`${this.props.QuotaDatapdf.templatesNTF}`} Template not
                        found
                      </p>
                    </div>
                  ) : null}
                </div> */}
                <div className={classes.centerContainer}>
                  {this.props.quotaLetterErrorMessage &&
                  this.state.loading !== true ? (
                    <div style={{ marginTop: "40px" }}>
                      <Typography
                        variant="body1"
                        align="center"
                        style={{
                          display: "inline",
                          color: "red",
                          fontSize: "0.9rem",
                        }}
                      >
                        Error: {`${this.props.quotaLetterErrorMessage}`}
                      </Typography>
                    </div>
                  ) : null}
                </div>
              </CardContent>
            </Card>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40vh",
            }}
          >
            <Skeleton variant="rectangular" width={410} height={160} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var {
    user,
    NewQualifiedPipelineData,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
    QuotaDatapdf,
    QuotaUrls,
    quotaLetterErrorMessage,
  } = state.app;
  var transformedNewQualifiedPipeline,
    teams,
    columns,
    detailsTeams,
    detailsColumns,
    timeframe;

  return {
    company: user.company,
    columns,
    teams,
    detailsTeams,
    detailsColumns,
    NewQualifiedPipelineData,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
    QuotaDatapdf,
    QuotaUrls,
    quotaLetterErrorMessage,
  };
};
const mapDispatchToProps = (dispatch) => ({
  generatePDFAPI: () => {
    // Return the promise here
    return appService.generatePDFAPI().then(
      (json) => {
        console.log("json_data", json);
        dispatch({ type: "generate_quota_letters", json });
        // this.setState({ loading: false });
      },
      (error) => {
        console.log("error to load", error);
      }
    );
  },

  getURLS: () => {
    // Return the promise here
    return appService.getURLS().then(
      (json) => {
        console.log("new_apijson_data", json);
        dispatch({ type: "get_URLS", json });
        // this.setState({ loading: false });
      },
      (error) => {
        console.log("Error_loading", error);
      }
    );
  },
  deleteQuotaDocs: () => {
    appService.deleteQuotaDocs();
  },
});

const GeneratePDF = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GeneratePDFNew));
export default GeneratePDF;
