import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import GetApp from "@material-ui/icons/GetApp";
import {
  getCSVFileNameString,
  csvDateFormatter,
  removeQuotes,
  _isNaN,
  csvDateFormatterNew,
  agGridDateFormatter,
} from "../../util/customFunctions";

import "./FunnelDrilldownBody.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import HoverableIcon from "../../components/Buttons/HoverableIcon ";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHeader: {
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
}));

const OpenDrilldownBody2 = (props) => {
  const classes = useStyle();

  const containerHeight = props.data
    ? props.data.length * 50 < window.innerHeight - 300
      ? "-"
      : window.innerHeight - 300
    : window.innerHeight - 300;
  const domLayout = props.data
    ? props.data.length * 50 < window.innerHeight - 300
      ? "autoHeight"
      : "normal"
    : "normal";

  const defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  let gridApi, columnApi, params;

  const gridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params = params;
  };
  const onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const exportCSV = () => {
    var params = {
      skipHeader: false,
      skipFooters: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: true,
      processCellCallback: (cellParams) => {
        const rowIndex = cellParams.column.colId;
        if (
          (cellParams && rowIndex === "Projected_Close_Date") ||
          rowIndex === "Created_Date"
        ) {
          return cellParams.value ? csvDateFormatter(cellParams.value) : null; //apply your timestamp formatter
        } else if (
          (cellParams && rowIndex === "OppName") ||
          rowIndex === "Account_Name"
        ) {
          return `"${cellParams.value}"`; //apply your string formatter
        } else if (cellParams && rowIndex === "ACV") {
          return _isNaN(cellParams.value) ? 0 : cellParams.value;
        } else return cellParams.value; // no formatting
      },
      fileName: getCSVFileNameString(props.header),
      columnSeparator: ",",
    };
    gridApi.exportDataAsCsv(params);
  };

  return (
    <Grid container className={classes.root}>
      {props.data ? (
        <React.Fragment>
          <Grid
            container
            justify="space-betweeen"
            className={classes.titleContainer}
            xs={12}
          >
            <Grid
              item
              container
              xs={10}
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="body1" style={{ fontWeight: "600" }}>
                Number of opportunities: {d3.format(",")(props.data.length)}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ACV: ${" "}
                {d3.format(",")(
                  Math.round(
                    props.data.reduce((total, item) => total + item.ACV, 0)
                  )
                )}
              </Typography>
            </Grid>

            <Grid
              direction="column"
              item
              container
              xs={2}
              justify="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
            >
              <Grid item justify="flex-end" style={{ paddingBottom: "1rem" }}>
                {/* <Button variant="contained"
                                    color="primary" onClick={exportCSV}><GetApp /></Button> */}
                <button style={{ border: "none" }} onClick={exportCSV}>
                  <HoverableIcon />
                </button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            className="ag-theme-custom-react_DB"
            style={{
              height: containerHeight,
              width: "100%",
            }}
          >
            <AgGridReact
              columnDefs={
                props.company !== "C0001"
                  ? [
                      {
                        headerName: "#",
                        field: "OppNo",
                        type: "rightAligned",
                        sortable: true,
                        cellStyle: { "padding-right": "3px" },
                        headerClass: classes.tableHeader,
                        maxWidth: 45,
                      },
                      {
                        headerName: "Opportunity Name",
                        field: "OppName",
                        flex: 2.3,
                        suppressSizeToFit: true,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Account ",
                        field: "Account_Name",
                        flex: 1.8,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "ACV",
                        field: "ACV",
                        flex: 0.6,
                        type: "rightAligned",
                        sortable: true,
                        filter: "agNumberColumnFilter",
                        filterParams: { defaultOption: "greaterThanOrEqual" },
                        filterParams: { defaultOption: "greaterThan" },
                        cellStyle: { "padding-right": "5px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: (x) => {
                          return "$ " + d3.format(",")(Math.round(x.value));
                        },
                      },
                      {
                        headerName: "Created Date",
                        field: "Created_Date",
                        flex: 0.6,
                        type: "rightAligned",
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-right": "5px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: agGridDateFormatter,
                      },
                      {
                        headerName: "Projected Close Date",
                        field: "Projected_Close_Date",
                        flex: 0.7,
                        type: "rightAligned",
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-right": "5px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: agGridDateFormatter,
                      },
                      {
                        headerName: "Age",
                        field: "Age",
                        flex: 0.5,
                        type: "rightAligned",
                        sortable: true,
                        filter: "agNumberColumnFilter",
                        filterParams: { defaultOption: "greaterThanOrEqual" },
                        cellStyle: { "padding-right": "20px" },
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Probability",
                        flex: 0.6,
                        field: "Probability",
                        type: "rightAligned",
                        filter: "agNumberColumnFilter",
                        sortable: true,
                        cellStyle: { "padding-right": "20px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: (x) => x.value + " %",
                      },
                      {
                        headerName: "Owner",
                        field: "Owner_Name",
                        flex: 0.6,
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-left": "5px" },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Team",
                        field: "Team",
                        flex: 0.6,
                        resizable: false,
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-left": "5px" },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                    ]
                  : [
                      {
                        headerName: "#",
                        field: "OppNo",
                        type: "rightAligned",
                        sortable: true,
                        cellStyle: { "padding-right": "3px" },
                        headerClass: classes.tableHeader,
                        maxWidth: 45,
                      },
                      {
                        headerName: "Opportunity Name",
                        field: "OppName",
                        flex: 2.3,
                        suppressSizeToFit: true,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Account ",
                        field: "Account_Name",
                        flex: 1.8,
                        sortable: true,
                        filter: true,
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-left": "5px",
                        },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "ACV",
                        field: "ACV",
                        flex: 0.6,
                        type: "rightAligned",
                        sortable: true,
                        filter: "agNumberColumnFilter",
                        filterParams: { defaultOption: "greaterThanOrEqual" },
                        filterParams: { defaultOption: "greaterThan" },
                        cellStyle: { "padding-right": "5px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: (x) => {
                          return "$ " + d3.format(",")(Math.round(x.value));
                        },
                      },
                      {
                        headerName: "Services",
                        field: "Professional_Services_Amt_c",
                        flex: 0.8,
                        type: "rightAligned",
                        sortable: true,
                        filter: "agNumberColumnFilter",
                        filterParams: { defaultOption: "greaterThanOrEqual" },
                        cellStyle: {
                          "border-right-color": "lightgrey",
                          "padding-right": "5px",
                        },
                        headerClass: classes.tableHeader,
                        sortingOrder: ["desc", "asc"],
                        valueFormatter: (x) => {
                          return `$${d3.format(",")(Math.round(x.value))}`;
                        },
                      },
                      {
                        headerName: "Created Date",
                        field: "Created_Date",
                        flex: 0.6,
                        type: "rightAligned",
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-right": "5px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: agGridDateFormatter,
                      },
                      {
                        headerName: "Projected Close Date",
                        field: "Projected_Close_Date",
                        flex: 0.7,
                        type: "rightAligned",
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-right": "5px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: agGridDateFormatter,
                      },
                      {
                        headerName: "Age",
                        field: "Age",
                        flex: 0.5,
                        type: "rightAligned",
                        sortable: true,
                        filter: "agNumberColumnFilter",
                        filterParams: { defaultOption: "greaterThanOrEqual" },
                        cellStyle: { "padding-right": "20px" },
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Probability",
                        flex: 0.6,
                        field: "Probability",
                        type: "rightAligned",
                        filter: "agNumberColumnFilter",
                        sortable: true,
                        cellStyle: { "padding-right": "20px" },
                        headerClass: classes.tableHeader,
                        valueFormatter: (x) => x.value + " %",
                      },
                      {
                        headerName: "Owner",
                        field: "Owner_Name",
                        flex: 0.6,
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-left": "5px" },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                      {
                        headerName: "Team",
                        field: "Team",
                        flex: 0.6,
                        resizable: false,
                        sortable: true,
                        filter: true,
                        cellStyle: { "padding-left": "5px" },
                        cellClass: classes.tableCell,
                        headerClass: classes.tableHeader,
                      },
                    ]
              }
              sizeColumnsToFit={true}
              headerHeight="55"
              rowData={props.data}
              onFirstDataRendered={onFirstDataRendered}
              // onCellClicked={this.onCellClicked.bind(this)}
              // pagination={true}
              // paginationPageSize={6}
              defaultColDef={defaultColDef}
              onColumnResized={props.length <= 10 ? onColumnResized : null}
              enableCellTextSelection={true}
              // autoHeight={true}
              colResizeDefault={"shift"}
              onGridReady={gridReady}
              debounceVerticalScrollbar={true}
              domLayout={domLayout}
            ></AgGridReact>
          </Grid>
        </React.Fragment>
      ) : (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height={280}
          style={{ margin: "20px 0px" }}
        />
      )}
    </Grid>
  );
};

export default OpenDrilldownBody2;
