import { map } from "d3";
import { colorStages } from "../../CustomerSuccess/Pages/AccountHealth/utils"
import { getUniqueValues } from "../../util/customFunctions"
import { customSortedOppType,  customSortedGAAPOppType} from "../../util/customSortings";

export const getCustomSortedStages = (sortedUniqueStages, chartName) => {
    if (chartName === "AccountHealth") {
        const colorMap = new Map();
        let uniqueStages = []
        for (const item of sortedUniqueStages) {
            if (!colorMap.has(item)) {
                colorMap.set(item)
                uniqueStages.push(item)
            }
        }

        return uniqueStages
    } else if (chartName === "QBR") {

        const missingRows = sortedUniqueStages.filter(function (obj) { return ["Prime", "Premier", "Strategic"].indexOf(obj) == -1; });
        return ["Prime", "Premier", "Strategic", ...missingRows]

    } else if (chartName === "WinAcvAnalysis Opportunity Type" || chartName === "PipelineMix by Opportunity Type") {

        return customSortedOppType
    }else if (chartName === "WinAcvAnalysis GAAP Opportunity Type"){
        return customSortedGAAPOppType
    }
    return sortedUniqueStages
}