import React from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import AccountExpansionTableContainer from "./AccountExpansionContainer";
import { Skeleton } from "@mui/material";
import { Grid, Button, Paper } from "@material-ui/core";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";
import LoadingSkeleton from "../NewWaterFall/LoadingSkeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

class AccountExpansion extends React.Component {
  state = {
    secondLevelLocation: "",
    team: ["All"],
    rep: ["All"],
  };

  componentDidMount() {
    this.props.getFilters();
    // this.props.getData([]);
  }

  handleChangeFilter = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.name === "fiscal_quarter"
          ? this.state.tab === "summary"
            ? [e.target.value]
            : e.target.value
          : e.target.value,
    });
  };

  handleGo = () => {
    // Fetch data based on the selected filters
    const { team, rep } = this.state;
    const filters = [];

    if (!team.includes("All")) {
      filters.push({ name: "team", value: team });
    }
    console.log(rep, "rep_account_expansion");
    if (!rep.includes("All")) {
      filters.push({
        name: "rep",
        value: [
          this.props.accountExpansionFilters.reps.filter(
            (r) => r.Full_Name === rep[0]
          )[0].UserID,
        ],
      });
    }

    this.props.getData(filters);
  };

  orderRepsByLastName = () => {
    let temp = this.props.accountExpansionFilters.reps;
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep) => {
      return rep.fullName;
    });
    return menuItems;
  };

  handleSecondLevelDrilldown = (location, oppID) => {
    if (oppID !== null) {
      this.setState({
        ...this.state,
        secondLevelLocation: location,
      });
      this.props.getOpenOppData(oppID);
    }
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    // const { classes } = this.props;
    return (
      <Grid container item xs={12}>
        {addHeaderdescription(
          "Account Value Expansion",
          "Analyze Account ACVs year over year."
        )}
        {this.props.accountExpansionFilters !== "" ? (
          <Grid item container>
            {/* Rep and Team Filters */}
            <Grid
              item
              xs={12}
              md={4}
              container
              // className={}
              style={{
                padding: 10,
                margin: 5,
                width: "29%",
                marginLeft: 0,
              }}
              component={Paper}
            >
              <Grid item xs={6} style={{ paddingTop: 0 }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={
                    this.props.accountExpansionFilters !== "" &&
                    this.orderRepsByLastName()
                  }
                  getOptionLabel={(option) => option}
                  value={this.state.rep[0]}
                  onChange={(event, value) =>
                    this.setState({ rep: [value || "All"] })
                  }
                  name="rep"
                  disabled={!this.state.team.includes("All")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Rep:"
                      variant="standard"
                      InputLabelProps={{
                        style: {
                          color: !this.state.rep.includes("All")
                            ? "#4472c4"
                            : "#000",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 10 }}>
                <FormControl
                  fullWidth
                  // className={{
                  disabled={!this.state.rep.includes("All")}
                >
                  <InputLabel
                    id="select-team-label"
                    style={{
                      color: !this.state.team.includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                  >
                    Team:
                  </InputLabel>
                  <Select
                    labelId="select-team-label"
                    id="select-team"
                    value={this.state.team}
                    onChange={this.handleChangeFilter}
                    label="Team"
                    name="team"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {this.props.accountExpansionFilters !== "" &&
                      this.props.accountExpansionFilters.teams
                        .map((t) => t.Display_Name)
                        .sort()
                        .map((y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* GO Button */}
            <Grid item xs={12} md={2} style={{ padding: "1rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container item>
            <LoadingSkeleton />
          </Grid>
        )}
        {/* Data Rendering */}
        {this.props.accountExpansionData !== "" ? (
          <AccountExpansionTableContainer
            handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
            data={this.props.accountExpansionData}
          />
        ) : (
          <Skeleton
            variant="rect"
            animation="wave"
            height={120}
            width="100%"
            style={{ margin: "1rem" }}
          />
        )}

        {/* Drilldown Rendering */}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{
              position: "absolute",
              width: "100%",
              marginTop: "30px",
            }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    accountExpansionData,
    user,
    ExpectedAcvData,
    accountExpansionFilters,
  } = state.app;
  return {
    accountExpansionData,
    accountExpansionFilters,
    Value_Label: user.Value_Label,
    ExpectedAcvData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  getFilters: () => {
    dispatch({ type: "get_account_expansion_filters_request" });
    appService.getAccountExpansionFilters().then((json) => {
      dispatch({
        type: "get_account_expansion_filters_success",
        json: json.message.filters,
      });
      let filters = [];
      dispatch({ type: "current_selected_filters", filters });
      dispatch({ type: "get_account_expansion_data_request" });
      appService.getAccountExpansionData(filters).then(
        (json) => {
          dispatch({ type: "get_account_expansion_data_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_account_expansion_data_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_account_expansion_data_failure", error });
        }
      );
    });
  },
  getData: (filters) => {
    dispatch({ type: "get_account_expansion_data_request" });
    appService.getAccountExpansionData(filters).then(
      (json) => {
        dispatch({ type: "get_account_expansion_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_account_expansion_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_account_expansion_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});

const connectedAccountExpansion = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({})(AccountExpansion));

export default connectedAccountExpansion;
