import React from 'react'
import { TableCell } from '@material-ui/core'
import { makeStyles } from "@material-ui/core";
// import tableStyle from '../../../CustomerSuccess/styles/tableStyle'

const tableStyle = makeStyles(theme => ({
  root: {
      margin: '0.5rem',
      padding: '0.5rem'
  },
  colHeaderNew: {
      fontSize: '0.85rem',
      border: '0.5px solid lightgrey',
      padding: '7px 10px',
      color: 'inherit',
      width: 120,
      fontWeight: 'inherit',
      backgroundColor: 'inherit'
  },
  colHeader: {
      fontSize: '0.85rem', border: '0.5px solid lightgrey', padding: '7px 10px', color: 'inherit', width: 120, fontWeight: 600
  },
  dataCell: {
      fontSize: '0.85rem', border: '0.5px solid lightgrey', padding: '7px 10px', color: 'inherit', width: 120,
      fontWeight: 'inherit',
  },
  drilldownCell: {
      fontSize: '0.85rem', border: '0.5px solid lightgrey', padding: '7px 10px', color: 'inherit', width: 120,
      fontWeight: 'inherit',
      cursor: 'pointer'
  },
  drilldownCellNQP: {
      fontSize: '0.85rem', border: '0.5px solid lightgrey', padding: '7px 10px', color: 'inherit', width: '3%',
      fontWeight: 'inherit',
      cursor: 'pointer',
  },
  cellMargin: {
      fontSize: '0.85rem', border: '0.5px solid lightgrey', padding: '7px 10px', color: 'inherit', width: 120,
      fontWeight: 'inherit',
      paddingLeft: '2rem'
  },
  fontBold: {
      fontWeight: 600
  },
  indentName: {
      paddingLeft: 30
  },
  noBorder: {
      border: 'none'
  },
  primaryColumnColor: {
      backgroundColor: '#4472C4', color: 'white'
  },
  secondaryColumnColor: {
      backgroundColor: '#70ad47', color: 'white'
  },
  tertiaryColumnColor: {
      backgroundColor: '#bf8f00', color: 'white'
  }

}), {index: 1})

const PrimaryColorTableHeader = (props) => {
  const classes = tableStyle()
  return (
    <TableCell align="center" style={{width:props.width}} className={`${classes.colHeaderNew}`}>{props.title}</TableCell>
  )
}

export default PrimaryColorTableHeader
