import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import StyledTableRow from "./StyledTableRow";
import { format as d3Formatter, pointer } from "d3";
import { formatDataValues } from "../util/dataFormatter";
import { _isNaN } from "../util/customFunctions";
import CopyTablesObject from "./xlsx-export/CopyTablesObject";

const useStyle = makeStyles((theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
  colHeader: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
  drilldowncell: {
    cursor: "pointer",
  },
}));

export default function SummaryViewTable(props) {
  let { tableHeaders, data } = props;
  const tableRef = React.createRef();
  console.log(data);
  const {
    PBISummaryData,
    totalAcvVertical,
    totalRow,
    totalAcv,
    highestValues,
    highestValuesByPercentRow,
  } = data;
  const classes = useStyle();
  tableHeaders = tableHeaders.filter(
    (item) => item !== "SG_Apps_Cluster_Others_ACV"
  );
  tableHeaders.push("SG_Apps_Cluster_Others_ACV");

  return (
    <TableContainer style={{ margin: "2rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
          // marginRight: "10px",
        }}
      >
        <CopyTablesObject tableRef={tableRef} />
      </div>
      <Table ref={tableRef} data-test={props.data_test} size="small">
        <TableHead></TableHead>
        <TableBody>
          <TableRow
            style={{
              fontWeight: 600,
              background: "#4472c4",
              color: "white",
              paddingLeft: 5,
            }}
          >
            <TableCell
              align="center"
              className={classes.colHeader}
              style={{ background: "white" }}
            ></TableCell>
            <TableCell align="center" className={classes.colHeader}>
              Audit{" "}
            </TableCell>
            <TableCell align="center" className={classes.colHeader}>
              Compliance{" "}
            </TableCell>
            <TableCell align="center" className={classes.colHeader}>
              Cyber{" "}
            </TableCell>

            <TableCell align="center" className={classes.colHeader}>
              Risk{" "}
            </TableCell>
            <TableCell align="center" className={classes.colHeader}>
              TPM{" "}
            </TableCell>
            <TableCell align="center" className={classes.colHeader}>
              Platform{" "}
            </TableCell>
            <TableCell align="center" className={classes.colHeader}>
              Others{" "}
            </TableCell>
            <TableCell align="center" className={classes.colHeader}>
              Total{" "}
            </TableCell>
          </TableRow>
          {PBISummaryData.map((item) => (
            <StyledTableRow>
              <TableCell align="left" className={classes.colHeader}>
                {item.Acct_Vertical}
              </TableCell>
              <TableCell
                align="right"
                onClick={() => {
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    "Audit",
                    item.Acct_Vertical
                  );
                }}
                className={`${classes.colHeader} ${classes.drilldowncell}`}
                style={
                  highestValues.filter(
                    (highValue) =>
                      highValue.key === "SG_Apps_Cluster_Audit_ACV" &&
                      highValue.vertical === item.Acct_Vertical
                  )[0] !== undefined
                    ? {
                        background: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_Audit_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].background,
                        color: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_Audit_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                }
              >{`${formatDataValues(
                _isNaN(item.SG_Apps_Cluster_Audit_ACV)
                  ? 0
                  : item.SG_Apps_Cluster_Audit_ACV,
                props.isAcv
              )}`}</TableCell>
              <TableCell
                align="right"
                onClick={() => {
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    "Compliance",
                    item.Acct_Vertical
                  );
                }}
                className={`${classes.colHeader} ${classes.drilldowncell}`}
                style={
                  highestValues.filter(
                    (highValue) =>
                      highValue.key === "SG_Apps_Cluster_Compliance_ACV" &&
                      highValue.vertical === item.Acct_Vertical
                  )[0] !== undefined
                    ? {
                        background: highestValues.filter(
                          (highValue) =>
                            highValue.key ===
                              "SG_Apps_Cluster_Compliance_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].background,
                        color: highestValues.filter(
                          (highValue) =>
                            highValue.key ===
                              "SG_Apps_Cluster_Compliance_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                }
              >{`${formatDataValues(
                _isNaN(item.SG_Apps_Cluster_Compliance_ACV)
                  ? 0
                  : item.SG_Apps_Cluster_Compliance_ACV,
                props.isAcv
              )}`}</TableCell>
              <TableCell
                align="right"
                onClick={() => {
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    "Cyber",
                    item.Acct_Vertical
                  );
                }}
                className={`${classes.colHeader} ${classes.drilldowncell}`}
                style={
                  highestValues.filter(
                    (highValue) =>
                      highValue.key === "SG_Apps_Cluster_Cyber_ACV" &&
                      highValue.vertical === item.Acct_Vertical
                  )[0] !== undefined
                    ? {
                        background: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_Cyber_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].background,
                        color: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_Cyber_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                }
              >{`${formatDataValues(
                _isNaN(item.SG_Apps_Cluster_Cyber_ACV)
                  ? 0
                  : item.SG_Apps_Cluster_Cyber_ACV,
                props.isAcv
              )}`}</TableCell>

              <TableCell
                align="right"
                onClick={() => {
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    "Risk",
                    item.Acct_Vertical
                  );
                }}
                className={`${classes.colHeader} ${classes.drilldowncell}`}
                style={
                  highestValues.filter(
                    (highValue) =>
                      highValue.key === "SG_Apps_Cluster_Risk_ACV" &&
                      highValue.vertical === item.Acct_Vertical
                  )[0] !== undefined
                    ? {
                        background: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_Risk_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].background,
                        color: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_Risk_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                }
              >{`${formatDataValues(
                _isNaN(item.SG_Apps_Cluster_Risk_ACV)
                  ? 0
                  : item.SG_Apps_Cluster_Risk_ACV,
                props.isAcv
              )}`}</TableCell>
              <TableCell
                align="right"
                onClick={() => {
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    "TPM",
                    item.Acct_Vertical
                  );
                }}
                className={`${classes.colHeader} ${classes.drilldowncell}`}
                style={
                  highestValues.filter(
                    (highValue) =>
                      highValue.key === "SG_Apps_Cluster_TPM_ACV" &&
                      highValue.vertical === item.Acct_Vertical
                  )[0] !== undefined
                    ? {
                        background: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_TPM_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].background,
                        color: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_TPM_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                }
              >{`${formatDataValues(
                _isNaN(item.SG_Apps_Cluster_TPM_ACV)
                  ? 0
                  : item.SG_Apps_Cluster_TPM_ACV,
                props.isAcv
              )}`}</TableCell>
              <TableCell
                align="right"
                onClick={() => {
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    "Platform",
                    item.Acct_Vertical
                  );
                }}
                className={`${classes.colHeader} ${classes.drilldowncell}`}
                style={
                  highestValues.filter(
                    (highValue) =>
                      highValue.key === "SG_Apps_Cluster_Platform_ACV" &&
                      highValue.vertical === item.Acct_Vertical
                  )[0] !== undefined
                    ? {
                        background: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_Platform_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].background,
                        color: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_Platform_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                }
              >{`${formatDataValues(
                _isNaN(item.SG_Apps_Cluster_Platform_ACV)
                  ? 0
                  : item.SG_Apps_Cluster_Platform_ACV,
                props.isAcv
              )}`}</TableCell>
              <TableCell
                align="right"
                onClick={() => {
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    "Others",
                    item.Acct_Vertical
                  );
                }}
                className={`${classes.colHeader} ${classes.drilldowncell}`}
                style={
                  highestValues.filter(
                    (highValue) =>
                      highValue.key === "SG_Apps_Cluster_Others_ACV" &&
                      highValue.vertical === item.Acct_Vertical
                  )[0] !== undefined
                    ? {
                        background: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_Others_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].background,
                        color: highestValues.filter(
                          (highValue) =>
                            highValue.key === "SG_Apps_Cluster_Others_ACV" &&
                            highValue.vertical === item.Acct_Vertical
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                }
              >{`${formatDataValues(
                _isNaN(item.SG_Apps_Cluster_Others_ACV)
                  ? 0
                  : item.SG_Apps_Cluster_Others_ACV,
                props.isAcv
              )}`}</TableCell>
              <TableCell
                align="right"
                onClick={() => {
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    "All",
                    item.Acct_Vertical
                  );
                }}
                className={`${classes.colHeader} ${classes.drilldowncell}`}
                style={
                  !props.isAcv
                    ? highestValues.filter(
                        (highValue) => highValue.vertical === item.Acct_Vertical
                      )[0] !== undefined
                      ? {
                          background: highestValues.filter(
                            (highValue) =>
                              highValue.vertical === item.Acct_Vertical
                          )[0].background,
                          color: highestValues.filter(
                            (highValue) =>
                              highValue.vertical === item.Acct_Vertical
                          )[0].color,
                          fontWeight: 600,
                        }
                      : {}
                    : {}
                }
              >
                {" "}
                {`${formatDataValues(
                  _isNaN(totalAcvVertical[item.Acct_Vertical])
                    ? 0
                    : totalAcvVertical[item.Acct_Vertical],
                  props.isAcv
                )}`}{" "}
              </TableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow>
            <TableCell align="left" className={classes.colHeader}>
              Total
            </TableCell>
            <TableCell
              align="right"
              onClick={() => {
                props.handleChangeLocation(
                  "drilldownOnGraph",
                  "Audit",
                  PBISummaryData.map((item) => item["Acct_Vertical"])
                );
              }}
              style={
                highestValuesByPercentRow !== undefined
                  ? highestValuesByPercentRow.filter(
                      (item) => item.key === "SG_Apps_Cluster_Audit_ACV"
                    )[0] !== undefined
                    ? {
                        background: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_Audit_ACV"
                        )[0].background,
                        color: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_Audit_ACV"
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                  : {}
              }
              className={`${classes.colHeader} ${classes.drilldowncell}`}
            >{`${formatDataValues(
              _isNaN(totalRow.SG_Apps_Cluster_Audit_ACV)
                ? 0
                : totalRow.SG_Apps_Cluster_Audit_ACV,
              props.isAcv
            )}`}</TableCell>
            <TableCell
              align="right"
              onClick={() => {
                props.handleChangeLocation(
                  "drilldownOnGraph",
                  "Compliance",
                  PBISummaryData.map((item) => item["Acct_Vertical"])
                );
              }}
              style={
                highestValuesByPercentRow !== undefined
                  ? highestValuesByPercentRow.filter(
                      (item) => item.key === "SG_Apps_Cluster_Compliance_ACV"
                    )[0] !== undefined
                    ? {
                        background: highestValuesByPercentRow.filter(
                          (item) =>
                            item.key === "SG_Apps_Cluster_Compliance_ACV"
                        )[0].background,
                        color: highestValuesByPercentRow.filter(
                          (item) =>
                            item.key === "SG_Apps_Cluster_Compliance_ACV"
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                  : {}
              }
              className={`${classes.colHeader} ${classes.drilldowncell}`}
            >{`${formatDataValues(
              _isNaN(totalRow.SG_Apps_Cluster_Compliance_ACV)
                ? 0
                : totalRow.SG_Apps_Cluster_Compliance_ACV,
              props.isAcv
            )}`}</TableCell>
            <TableCell
              align="right"
              onClick={() => {
                props.handleChangeLocation(
                  "drilldownOnGraph",
                  "Cyber",
                  PBISummaryData.map((item) => item["Acct_Vertical"])
                );
              }}
              style={
                highestValuesByPercentRow !== undefined
                  ? highestValuesByPercentRow.filter(
                      (item) => item.key === "SG_Apps_Cluster_Cyber_ACV"
                    )[0] !== undefined
                    ? {
                        background: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_Cyber_ACV"
                        )[0].background,
                        color: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_Cyber_ACV"
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                  : {}
              }
              className={`${classes.colHeader} ${classes.drilldowncell}`}
            >{`${formatDataValues(
              _isNaN(totalRow.SG_Apps_Cluster_Cyber_ACV)
                ? 0
                : totalRow.SG_Apps_Cluster_Cyber_ACV,
              props.isAcv
            )}`}</TableCell>

            <TableCell
              align="right"
              onClick={() => {
                props.handleChangeLocation(
                  "drilldownOnGraph",
                  "Risk",
                  PBISummaryData.map((item) => item["Acct_Vertical"])
                );
              }}
              style={
                highestValuesByPercentRow !== undefined
                  ? highestValuesByPercentRow.filter(
                      (item) => item.key === "SG_Apps_Cluster_Risk_ACV"
                    )[0] !== undefined
                    ? {
                        background: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_Risk_ACV"
                        )[0].background,
                        color: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_Risk_ACV"
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                  : {}
              }
              className={`${classes.colHeader} ${classes.drilldowncell}`}
            >{`${formatDataValues(
              _isNaN(totalRow.SG_Apps_Cluster_Risk_ACV)
                ? 0
                : totalRow.SG_Apps_Cluster_Risk_ACV,
              props.isAcv
            )}`}</TableCell>
            <TableCell
              align="right"
              onClick={() => {
                props.handleChangeLocation(
                  "drilldownOnGraph",
                  "TPM",
                  PBISummaryData.map((item) => item["Acct_Vertical"])
                );
              }}
              style={
                highestValuesByPercentRow !== undefined
                  ? highestValuesByPercentRow.filter(
                      (item) => item.key === "SG_Apps_Cluster_TPM_ACV"
                    )[0] !== undefined
                    ? {
                        background: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_TPM_ACV"
                        )[0].background,
                        color: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_TPM_ACV"
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                  : {}
              }
              className={`${classes.colHeader} ${classes.drilldowncell}`}
            >{`${formatDataValues(
              _isNaN(totalRow.SG_Apps_Cluster_TPM_ACV)
                ? 0
                : totalRow.SG_Apps_Cluster_TPM_ACV,
              props.isAcv
            )}`}</TableCell>
            <TableCell
              align="right"
              onClick={() => {
                props.handleChangeLocation(
                  "drilldownOnGraph",
                  "Platform",
                  PBISummaryData.map((item) => item["Acct_Vertical"])
                );
              }}
              style={
                highestValuesByPercentRow !== undefined
                  ? highestValuesByPercentRow.filter(
                      (item) => item.key === "SG_Apps_Cluster_Platform_ACV"
                    )[0] !== undefined
                    ? {
                        background: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_Platform_ACV"
                        )[0].background,
                        color: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_Platform_ACV"
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                  : {}
              }
              className={`${classes.colHeader} ${classes.drilldowncell}`}
            >{`${formatDataValues(
              _isNaN(totalRow.SG_Apps_Cluster_Platform_ACV)
                ? 0
                : totalRow.SG_Apps_Cluster_Platform_ACV,
              props.isAcv
            )}`}</TableCell>
            <TableCell
              align="right"
              onClick={() => {
                props.handleChangeLocation(
                  "drilldownOnGraph",
                  "Others",
                  PBISummaryData.map((item) => item["Acct_Vertical"])
                );
              }}
              style={
                highestValuesByPercentRow !== undefined
                  ? highestValuesByPercentRow.filter(
                      (item) => item.key === "SG_Apps_Cluster_Others_ACV"
                    )[0] !== undefined
                    ? {
                        background: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_Others_ACV"
                        )[0].background,
                        color: highestValuesByPercentRow.filter(
                          (item) => item.key === "SG_Apps_Cluster_Others_ACV"
                        )[0].color,
                        fontWeight: 600,
                      }
                    : {}
                  : {}
              }
              className={`${classes.colHeader} ${classes.drilldowncell}`}
            >{`${formatDataValues(
              _isNaN(totalRow.SG_Apps_Cluster_Others_ACV)
                ? 0
                : totalRow.SG_Apps_Cluster_Others_ACV,
              props.isAcv
            )}`}</TableCell>

            <TableCell
              align="right"
              onClick={() => {
                props.handleChangeLocation(
                  "drilldownOnGraph",
                  "All",
                  PBISummaryData.map((item) => item["Acct_Vertical"])
                );
              }}
              className={`${classes.colHeader} ${classes.drilldowncell}`}
            >
              {`${formatDataValues(
                _isNaN(totalAcv) ? 0 : totalAcv,
                props.isAcv
              )}`}
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
