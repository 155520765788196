import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
} from "../../util/customFunctions";
//  import { convertToThousands, getUniqueValues, _isNaN } from '../../util/customFunctions';
import * as d3 from "d3";
import { getMixKey } from "../../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
} from "../../util/customSortings";
import { deSnake } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";
const useStyle = makeStyles((theme) => ({
  content: { width: "100%" },
  sticky: {
    position: "sticky",
    left: -1,
    background: "white",
    // outline: "0.5px solid lightgrey",
    // outlineOffset: "-1.5px",
    //  pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px", // Adjust the thickness as needed
      background: "lightgrey", // Adjust the color as needed
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function CompetitorPerformanceTable2(props) {
  const classes = useStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isSticky, setIsSticky] = useState(false);

  let total;

  const quarter = props.data.filter((item) => item.quarter !== "total");
  const topCompetitors = props.top.map((comp) => comp.competitor).slice(0, 8);
  const temp = topCompetitors.map((x) =>
    props.data.filter((comp) => comp.competitor === x)
  );
  const uniqueQuarters = quarter
    .map((item) => item.quarter)
    .filter(getUniqueValues)
    .sort();
  const uniqueCompetitors = topCompetitors;
  const status = props.status
    .map((comp) => comp.stage_name)
    .filter(getUniqueValues);

  const totalPusued = uniqueCompetitors.map((owner) => {
    const wonPursued = props.data
      .filter((item) => item.status === status[0] && item.competitor === owner)
      .map((x) => x.acv)
      .reduce((a, b) => a + b, 0);
    const lostPursued = props.data
      .filter((item) => item.status === status[1] && item.competitor === owner)
      .map((x) => x.acv)
      .reduce((a, b) => a + b, 0);
    const total = wonPursued + lostPursued;
    return { key: owner, acv: total, wonAcv: wonPursued, lostAcv: lostPursued };
  });

  const totalColumn = uniqueCompetitors.map((x) => {
    return props.data.filter((item) => item.competitor === x);
  });

  const totalColumnWon = totalPusued
    .map((x) => x.wonAcv)
    .reduce((a, b) => a + b, 0);
  const totalColumnLost = totalPusued
    .map((x) => x.lostAcv)
    .reduce((a, b) => a + b, 0);
  const totalColumPursued = totalPusued
    .map((x) => x.acv)
    .reduce((a, b) => a + b, 0);

  const handleTotalDrill1 = (status) => {
    var pursued = [],
      drilldownData = [];
    var pursuedDrilldown = [];
    uniqueCompetitors.map((owner) => {
      pursued = props.data.filter(
        (item) => item.status === status && item.competitor === owner
      );
      pursuedDrilldown = [...pursuedDrilldown, ...pursued];
    });
    pursuedDrilldown.map((a) => {
      drilldownData = [...drilldownData, ...a.drilldown];
    });
    //console.log(drilldownData)
    return drilldownData;
  };

  const handleDrilldown = (status, cell) => {
    var filteredData = [];
    var drillDownData = [];
    //console.log(uniqueCompetitors)
    totalColumn.map((x) =>
      x
        .filter((y) => y.quarter === cell && y.status === status)
        .map((z) => filteredData.push(z.drilldown))
    );
    filteredData.map((s) => {
      // s.map(a=> {
      //  drillDownData.push(a)
      //})
      drillDownData = [...drillDownData, ...s];
    });
    return drillDownData;
  };

  const handleChangeDrilldown = (status, owner) => {
    var drillData = [];
    var drillDownData = [];
    var s = props.data
      .filter((item) => item.status === status && item.competitor === owner)
      .map((z) => drillData.push(z.drilldown));
    drillData.map((s) => {
      //s.map(a=> {
      //drillDownData.push(a)
      //})
      drillDownData = [...drillDownData, ...s];
    });
    //console.log(drillData)
    return drillDownData;
  };

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainer");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: "1rem" }}>
        {/* <PerfectScrollbar> */}
        {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              // marginBottom: "10px",
              marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="flex-start" style={{ padding: "1rem" }}>
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
            }}
            id="myTableContainer"
            ref={tableContainerRef}
          >
            {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null}
            <Table
              ref={tableRef}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              data-test="primary-competitor-table"
            >
              <TableHead>
                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                    style={{ minWidth: 240 }}
                  >
                    {/* {"Closed Fiscal Quarter"} */}
                    {props.quarter
                      ? props.quarter === "Closed_Year_Month"
                        ? "Closed Month"
                        : "Closed Fiscal Quarter"
                      : "Closed Fiscal Quarter"}
                    {/* {"Closed Fiscal Quarter"} */}
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 210,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                padding: "10px 0",
                                minWidth: 100,
                              }
                        }
                        className={classes.repHeader}
                        colSpan={3}
                      >
                        {i}
                      </TableCell>
                    );
                  })}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        style={
                          uniqueQuarters.length > 1 &&
                          uniqueQuarters.length % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 210,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                minWidth: 100,
                              }
                        }
                        align="center"
                        className={classes.repHeader}
                        colSpan={3}
                      >
                        {"Total"}
                      </TableCell>
                    </React.Fragment>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 400, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>

                <TableRow style={{ fontWeight: 600, paddingLeft: 5 }}>
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : classes.repHeader
                    }
                    colSpan={1}
                  >{`Primary Competitor`}</TableCell>
                  {uniqueQuarters.map((quarter, i) => (
                    <React.Fragment key={i}>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 70 }}
                      >
                        Pursued
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 70 }}
                      >
                        Won
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 70 }}
                      >
                        Lost
                      </TableCell>
                    </React.Fragment>
                  ))}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 70 }}
                      >
                        Pursued
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 70 }}
                      >
                        Won
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 70 }}
                      >
                        Lost
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueCompetitors.map((owner, i) => {
                  total = i + 1;
                  return (
                    <StyledTableRow key={i}>
                      <TableCell
                        align="left"
                        // className={`${classes.table}`}
                        className={
                          isSticky
                            ? `${classes.sticky} ${classes.table}`
                            : classes.table
                        }
                        style={
                          isSticky && i % 2 === 1
                            ? { background: "#f5f5f5" }
                            : {}
                        }
                        colSpan={1}
                      >
                        {owner}
                      </TableCell>
                      {uniqueQuarters.map((qtr, j) => {
                        const WonData = props.data.filter(
                          (item) =>
                            item.quarter === qtr &&
                            item.status === status[0] &&
                            item.competitor === owner
                        );
                        const LostData = props.data.filter(
                          (item) =>
                            item.quarter === qtr &&
                            item.status === status[1] &&
                            item.competitor === owner
                        );
                        const pursuedData = WonData[0].acv + LostData[0].acv;
                        return (
                          <>
                            <TableCell
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {pursuedData === "undefiend" ||
                              pursuedData === null ||
                              _isNaN(pursuedData)
                                ? "-"
                                : countOrACVFormatter(pursuedData, true)}{" "}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                props.handleChangeLocation(
                                  "drilldownOnGraph1",
                                  qtr,
                                  owner,
                                  status[0],
                                  WonData[0].drilldown,
                                  WonData[0].acv
                                );
                              }}
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {WonData[0].acv === "undefiend" ||
                              WonData[0].acv === null ||
                              _isNaN(WonData[0].acv)
                                ? "-"
                                : countOrACVFormatter(
                                    WonData[0].acv,
                                    true
                                  )}{" "}
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                props.handleChangeLocation(
                                  "drilldownOnGraph1",
                                  qtr,
                                  owner,
                                  status[1],
                                  LostData[0].drilldown,
                                  LostData[0].acv
                                );
                              }}
                              align="right"
                              className={classes.repNumber}
                              colSpan={1}
                            >
                              {LostData[0].acv === "undefiend" ||
                              LostData[0].acv === null ||
                              _isNaN(LostData[0].acv)
                                ? "-"
                                : countOrACVFormatter(
                                    LostData[0].acv,
                                    true
                                  )}{" "}
                            </TableCell>
                          </>
                        );
                      })}
                      {uniqueQuarters.length >= 1 && (
                        <>
                          <TableCell
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                          >
                            {totalPusued
                              .filter((item) => item.key === owner)
                              .map((x) => x.acv) === "undefiend" ||
                            totalPusued
                              .filter((item) => item.key === owner)
                              .map((x) => x.acv) === null ||
                            _isNaN(
                              totalPusued
                                .filter((item) => item.key === owner)
                                .map((x) => x.acv)
                            )
                              ? "-"
                              : countOrACVFormatter(
                                  totalPusued
                                    .filter((item) => item.key === owner)
                                    .map((x) => x.acv),
                                  true
                                )}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              props.handleChangeLocation(
                                "drilldownOnGraph1",
                                "All",
                                owner,
                                status[0],
                                handleChangeDrilldown(status[0], owner),
                                props.data
                                  .filter(
                                    (item) =>
                                      item.status === status[0] &&
                                      item.competitor === owner
                                  )
                                  .map((x) => x.acv)
                                  .reduce((a, b) => a + b, 0)
                              );
                            }}
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                            style={{ cursor: "pointer" }}
                          >
                            {props.data
                              .filter(
                                (item) =>
                                  item.status === status[0] &&
                                  item.competitor === owner
                              )
                              .map((x) => x.acv)
                              .reduce((a, b) => a + b, 0) === "undefiend" ||
                            props.data
                              .filter(
                                (item) =>
                                  item.status === status[0] &&
                                  item.competitor === owner
                              )
                              .map((x) => x.acv)
                              .reduce((a, b) => a + b, 0) === null ||
                            _isNaN(
                              props.data
                                .filter(
                                  (item) =>
                                    item.status === status[0] &&
                                    item.competitor === owner
                                )
                                .map((x) => x.acv)
                                .reduce((a, b) => a + b, 0)
                            )
                              ? "-"
                              : countOrACVFormatter(
                                  props.data
                                    .filter(
                                      (item) =>
                                        item.status === status[0] &&
                                        item.competitor === owner
                                    )
                                    .map((x) => x.acv)
                                    .reduce((a, b) => a + b, 0),
                                  true
                                )}{" "}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              props.handleChangeLocation(
                                "drilldownOnGraph1",
                                "All",
                                owner,
                                status[1],
                                handleChangeDrilldown(status[1], owner),
                                props.data
                                  .filter(
                                    (item) =>
                                      item.status === status[1] &&
                                      item.competitor === owner
                                  )
                                  .map((x) => x.acv)
                                  .reduce((a, b) => a + b, 0)
                              );
                            }}
                            align="right"
                            className={classes.repNumber}
                            colSpan={1}
                            style={{ cursor: "pointer" }}
                          >
                            {props.data
                              .filter(
                                (item) =>
                                  item.status === status[1] &&
                                  item.competitor === owner
                              )
                              .map((x) => x.acv)
                              .reduce((a, b) => a + b, 0) === "undefiend" ||
                            props.data
                              .filter(
                                (item) =>
                                  item.status === status[1] &&
                                  item.competitor === owner
                              )
                              .map((x) => x.acv)
                              .reduce((a, b) => a + b, 0) === null ||
                            _isNaN(
                              props.data
                                .filter(
                                  (item) =>
                                    item.status === status[1] &&
                                    item.competitor === owner
                                )
                                .map((x) => x.acv)
                                .reduce((a, b) => a + b, 0)
                            )
                              ? "-"
                              : countOrACVFormatter(
                                  props.data
                                    .filter(
                                      (item) =>
                                        item.status === status[1] &&
                                        item.competitor === owner
                                    )
                                    .map((x) => x.acv)
                                    .reduce((a, b) => a + b, 0),
                                  true
                                )}{" "}
                          </TableCell>
                        </>
                      )}
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow className={classes.fontBold}>
                  <TableCell
                    align="left"
                    // className={`${classes.repHeader} ${classes.tableColWidth}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}  ${classes.tableColWidth}`
                        : `${classes.repHeader} ${classes.tableColWidth}`
                    }
                    colSpan={1}
                    // style={{ fontWeight: 600 }}
                    style={
                      isSticky && total % 2 === 1
                        ? { background: "#f5f5f5" }
                        : {}
                    }
                  >
                    Total
                  </TableCell>
                  {uniqueQuarters.map((cell, j) => {
                    const totalWon = totalColumn
                      .map(
                        (x) =>
                          x
                            .filter((y) => y.quarter === cell)
                            .map((x) => x.status === status[0] && x.acv)[0]
                      )
                      .reduce((a, b) => a + b, 0);
                    const totalLost = totalColumn
                      .map(
                        (x) =>
                          x
                            .filter((y) => y.quarter === cell)
                            .map((x) => x.status === status[1] && x.acv)[1]
                      )
                      .reduce((a, b) => a + b, 0);
                    const totalPursued = totalWon + totalLost;
                    return (
                      <React.Fragment key={j}>
                        <TableCell
                          align="right"
                          className={classes.repNumber}
                          colSpan={1}
                          style={{ fontWeight: 600 }}
                        >
                          {totalPursued === "undefiend" ||
                          totalPursued === "null" ||
                          _isNaN(totalPursued)
                            ? "-"
                            : countOrACVFormatter(totalPursued, true)}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            props.handleChangeLocation(
                              "drilldownOnGraph1",
                              cell,
                              "All",
                              status[0],
                              handleDrilldown(status[0], cell),
                              totalWon
                            );
                          }}
                          align="right"
                          className={classes.repNumber}
                          colSpan={1}
                          style={{ fontWeight: 600, cursor: "pointer" }}
                        >
                          {totalWon === "undefiend" ||
                          totalWon === "null" ||
                          _isNaN(totalWon)
                            ? "-"
                            : countOrACVFormatter(totalWon, true)}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            props.handleChangeLocation(
                              "drilldownOnGraph1",
                              cell,
                              "All",
                              status[1],
                              handleDrilldown(status[1], cell),
                              totalLost
                            );
                          }}
                          align="right"
                          className={classes.repNumber}
                          colSpan={1}
                          style={{ fontWeight: 600, cursor: "pointer" }}
                        >
                          {totalLost === "undefiend" ||
                          totalLost === "null" ||
                          _isNaN(totalLost)
                            ? "-"
                            : countOrACVFormatter(totalLost, true)}
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment>
                      <TableCell
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ fontWeight: 600 }}
                      >
                        {totalColumPursued === "undefiend" ||
                        totalColumPursued === "null" ||
                        _isNaN(totalColumPursued)
                          ? "-"
                          : countOrACVFormatter(totalColumPursued, true)}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          props.handleChangeLocation(
                            "drilldownOnGraph1",
                            "All",
                            "All",
                            status[0],
                            handleTotalDrill1(status[0]),
                            totalColumnWon
                          );
                        }}
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ fontWeight: 600, cursor: "pointer" }}
                      >
                        {totalColumnWon === "undefiend" ||
                        totalColumnWon === "null" ||
                        _isNaN(totalColumnWon)
                          ? "-"
                          : countOrACVFormatter(totalColumnWon, true)}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          props.handleChangeLocation(
                            "drilldownOnGraph1",
                            "All",
                            "All",
                            status[1],
                            handleTotalDrill1(status[1]),
                            totalColumnLost
                          );
                        }}
                        align="right"
                        className={classes.repNumber}
                        colSpan={1}
                        style={{ fontWeight: 600, cursor: "pointer" }}
                      >
                        {totalColumnLost === "undefiend" ||
                        totalColumnLost === "null" ||
                        _isNaN(totalColumnLost)
                          ? "-"
                          : countOrACVFormatter(totalColumnLost, true)}
                      </TableCell>
                    </React.Fragment>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* </PerfectScrollbar> */}
      </CardContent>
    </div>
  );
}
