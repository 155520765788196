import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "1rem 1rem 0.5rem 0.5rem",
    boxShadow: theme.shadows[1],
    borderTop: `10px solid ${color || "transparent"}`,
    fontSize: "0.85rem",
  },
}));
const OppNameTooltip = (props) => {
  return (
    <LightTooltip title={props.OppName} color={props.color}>
      {props.children}
    </LightTooltip>
  );
};

export default OppNameTooltip;
