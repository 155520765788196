import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import MediaCard from "../components/InsightCardV2";

import { appService } from "../App/app.service";
import EnhancedInsightRoutes from "../EnhancedInsights/EnhancedInsightRoutes";
import RepPerformanceRoutes from "./RepPerformanceRoutes";
import ConfifmModal from "../Favourites/ConfirmModal";
import BackdropContainer from "../components/Backdrop";
import NavItemMenuComponent from "../components/NavItemMenuComponent";
const styles = (theme) => ({
  dashOuter: {
    display: "flex",
    flexGrow: 1,
    margin: "auto",
  },
  dashInner: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  graph1: {
    flexGrow: 1,
    height: 250,
    margin: 10,
  },
});

const RepPerformance = (props) => {
  const [open, setOpen] = React.useState(false);
  const [charts, setCharts] = React.useState([]);
  const [confirmSignal, setConfirmSignal] = React.useState(false);
  const [selectedDashboard, setSelectedDashboard] = React.useState({});
  const cleanAfterConfirm = () => {
    setConfirmSignal(false);
    setSelectedDashboard({});
  };
  const handleOpen = ({ dashboard_id, Chart_Name }) => {
    setSelectedDashboard({ dashboard_id, Chart_Name });
    setOpen(true);
  };
  const handleConfirmClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setConfirmSignal(false);
    setOpen(false);
  };
  useEffect(() => {
    setCharts(props.charts);
  }, [props.charts]);
  useEffect(() => {
    props.getUser();
  }, []);
  const baseURL = "https://skygeniwebapp.blob.core.windows.net/thumbnails/";
  const { classes } = props;

  const handleLocationChange = (loc) => {
    console.log(loc, "lco");
    props.changeRepPerformancePage(loc);
  };

  return (
    <NavItemMenuComponent
      location={props.location}
      isDeleting={props.isDeleting}
      routesComponent={
        <RepPerformanceRoutes
          company={props.company}
          location={props.location}
        />
      }
      removeFavourite={props.removeFavourite}
      handleConfirmClose={handleConfirmClose}
      setConfirmSignal={setConfirmSignal}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      charts={charts}
      setCharts={setCharts}
      handleLocationChange={handleLocationChange}
      baseURL={baseURL}
      selectedDashboard={selectedDashboard}
      cleanAfterConfirm={cleanAfterConfirm}
      confirmSignal={confirmSignal}
      addFavourite={props.addFavourite}
    />
  );
};

const mapStateToProps = (state) => {
  const { repPerformancePage, user, isDeleting } = state.app;

  return {
    location: repPerformancePage,
    company: user.company,
    charts: user.elements
    .filter((item) => item.navItem === "Rep Performance").length > 0 ? user.elements
      .filter((item) => item.navItem === "Rep Performance")[0]
      .charts.sort((a, b) => a.Sequence - b.Sequence) : [],
    isDeleting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: () => {
    dispatch({ type: "get_user_data_request_page" });
    appService.getUser().then(
      (json) => {
        dispatch({ type: "get_user_data_success", user: json.user });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_user_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_user_data_failure", error });
      }
    );
  },
  addFavourite: (chartID) => {
    dispatch({
      type: "add_favourite_request",
      payload: chartID,
    });
    appService.putFavourite(chartID).then(
      (json) => {
        console.log(json);
        dispatch({ type: "add_favourite_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "add_favourite_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "add_favourite_failure", error });
      }
    );
  },
  removeFavourite: (chartID, navItem) => {
    dispatch({
      type: "delete_favourite_request",
      payload: chartID,
    });
    appService.deleteFavourite(chartID).then(
      (json) => {
        console.log(json);
        dispatch({
          type: "delete_favourite_success",
          json,
          payload: { dashboard_id: chartID, navItem },
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "delete_favourite_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "delete_favourite_failure", error });
      }
    );
  },
  changeRepPerformancePage: (loc) => {
    ReactGA.pageview(loc);
    dispatch({ type: "change_repPerformancePage", loc });
    localStorage.setItem("skygeni-user-repPage", loc);
    localStorage.removeItem("skygeni-user-enhancedPage");
    localStorage.removeItem("skygeni-user-productPage");
    localStorage.removeItem("skygeni-user-SettingsPage");
  },
});

const connectedRepPerformance = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RepPerformance));

export { connectedRepPerformance as RepPerformanceV2 };
export default RepPerformance;
