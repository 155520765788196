import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { isThisMinute } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "../../components/StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  dateFormatterMomentV2,
} from "../../util/customFunctions";
import * as d3 from "d3";
import { getMixKey } from "../../util/generateHeadings";
import {
  customSortedOppType,
  customSortedSegments,
} from "../../util/customSortings";
import { deSnake } from "../../util/dataFormatter";
import { color, gray } from "d3";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

// const useStyle = makeStyles(theme => ({
//     root: {},
//     content: { width: '100%' },
//     table: {
//         border: '0.5px solid lightgrey', padding: '5px', color: 'inherit'
//     },
//     repText: {
//         border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'left', minWidth: '169px', fontWeight: 'inherit'
//     },
//     repNumber: {
//         border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'right', fontWeight: 'inherit',
//     },
//     repNumberACV: {
//         border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'right', minWidth: '92px', fontWeight: 'inherit'
//     },
//     repNumberPercent: {
//         border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'right', minWidth: '90px', fontWeight: 600, cursor: 'pointer'
//     },
//     repTextH: {
//         border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'left', fontWeight: 600
//     },
//     repNumberH: {
//         border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', textAlign: 'right', fontWeight: 600
//     },
//     tableHeader: {
//         fontWeight: 600, border: '0.5px solid lightgrey', padding: '5px', color: 'inherit'
//     },
//     repHeader: {
//         fontWeight: 600, border: '0.5px solid lightgrey', padding: '5px', color: 'inherit', width: "5%"
//     },
//     inner: {
//         height: 375,
//         minWidth: 500
//     },
//     chart: {
//         height: '100%'
//     },
//     tableHeader: {
//         fontSize: '15px',
//         whiteSpace: 'normal',
//         textAlign: 'center'
//     },
//     tableCell: {
//         whiteSpace: 'normal',
//     },
//     indentName: {
//         paddingLeft: 30
//     },
//     blackLabel: {
//         color: 'black'
//     },
//     inputFieldContainers: {
//         marginBottom: 10,
//         padding: 10,
//         [theme.breakpoints.up('lg')]: {
//             paddingRight: 0
//         }
//     },
//     fontBold: {
//         fontWeight: 600
//     },
//     stickyColumn: {
//         minWidth: 200,
//         left: 0,
//         position: "sticky",
//         zIndex: theme.zIndex.appBar + 1,

//     }
// }))
export default function RampAnalysisTable(props) {
  const classes = tableStyle();
  const tableContainerRef = useRef();
  const tableRef = React.createRef();

  const [rowPin, setrowPin] = useState(false);

  const [parentWidth, setparentWidth] = useState();

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      // if (tableContainer.scrollLeft > 0) {
      //   setIsSticky(true);
      // } else {
      //   setIsSticky(false);
      // }

      if (tableContainer.scrollTop > 0) {
        setrowPin(true);
      } else {
        setrowPin(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [tableContainerRef.current]);

  //to Update copy to clipboard position
  const updateParentWidth = () => {
    const newParentWidth =
      tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(newParentWidth);
  };

  useEffect(() => {
    // Initial width calculation
    updateParentWidth();

    // Add event listener for window resize
    window.addEventListener("resize", updateParentWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateParentWidth);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  const reps = props.data.map((d) => d.rep).filter(getUniqueValues);
  const timeframe = props.timeFrame;

  const ast = (x) => (
    <>
      <span>{x}</span> <span style={{ color: "#ed7d31" }}>*</span>
    </>
  );

  return (
    <>
      <Grid xs={12}>
        {parentWidth !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.3rem",
              // marginRight: "1rem",
              width: `${parentWidth}px`,
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
      </Grid>
      <TableContainer
        style={{
          margin: "2rem",
          marginBottom: "1rem",
          width: "auto",
          maxHeight: "85vh",
        }}
        id="myTableContainer"
        ref={tableContainerRef}
      >
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
        >
          <TableHead style={{ position: "sticky", top: -1, zIndex: 5 }}>
            <TableRow
              style={{
                fontWeight: 600,
                background: "#4472c4",
                color: "white",
                paddingLeft: 5,
                height: 60,
              }}
            >
              <TableCell
                align="center"
                style={{
                  background: "#4472c4",
                  border: "0.5px solid lightgrey",
                  maxWidth: 50,
                }}
                className={classes.colHeader}
              >
                #
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#4472c4",
                  border: "0.5px solid lightgrey",
                  minWidth: 120,
                }}
                className={classes.colHeader}
              >
                Rep
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#4472c4",
                  border: "0.5px solid lightgrey",
                  minWidth: 120,
                }}
                className={classes.colHeader}
              >
                Start Date
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#4472c4",
                  border: "0.5px solid lightgrey",
                  minWidth: 120,
                }}
                className={classes.colHeader}
              >
                End Date
              </TableCell>
              <TableCell
                align="center"
                style={{
                  background: "#4472c4",
                  border: "0.5px solid lightgrey",
                  minWidth: 120,
                }}
                className={classes.colHeader}
              >{`Average of Hire Fiscal Qtr (HFQ) +4 onwards`}</TableCell>
              {timeframe.map((t, index) => (
                <TableCell
                  align="center"
                  style={
                    index % 2 === 0
                      ? {
                          background: "#5b9bd5",
                          color: "white",
                          minWidth: "71px",
                          outline: "0.4px solid lightgrey",
                          outlineOffset: "-1.5px",
                        }
                      : {
                          background: "#4471c4",
                          color: "white",
                          minWidth: "71px",
                          outline: "0.4px solid lightgrey",
                          outlineOffset: "-1.5px",
                        }
                  }
                  className={classes.colHeader}
                >{`${t}`}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.groupby === "Team"
              ? props.uniqueTeams.map((team) => {
                  let teamData = props.data.filter(
                    (s) => s[props.groupbyColumn] === team
                  );
                  const repsWithTeam = teamData
                    .map((c) => c.rep)
                    .filter(getUniqueValues)
                    .sort();
                  const qData = teamData;
                  const quotaReps = qData
                    .map((c) => c.avgQ4)
                    .filter(Boolean).length;
                  let average = teamData
                    .map((t) => t.avgQ4)
                    .reduce((a, b) => a + b, 0);
                  average =
                    ["", undefined, null, 0].includes(average) || quotaReps <= 0
                      ? "-"
                      : countOrACVFormatter(average / quotaReps / 1000, true);
                  let wondata = teamData.map((c) => c.wonData).flat();
                  return (
                    <>
                      {" "}
                      <StyledTableRow key={team}>
                        <TableCell
                          align="left"
                          style={{
                            border: "0.5px solid lightgrey",
                            paddingRight: "5px",
                            fontWeight: 600,
                          }}
                          className={classes.datacell}
                        ></TableCell>
                        <TableCell
                          align="left"
                          style={{
                            border: "0.5px solid lightgrey",
                            paddingRight: "5px",
                            fontWeight: 600,
                          }}
                          className={classes.datacell}
                        >
                          {team}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            border: "0.5px solid lightgrey",
                            paddingRight: "5px",
                            fontWeight: 600,
                          }}
                          className={classes.datacell}
                        >
                          {"-"}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            border: "0.5px solid lightgrey",
                            paddingRight: "5px",
                            fontWeight: 600,
                          }}
                          className={classes.datacell}
                        >
                          {"-"}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            border: "0.5px solid lightgrey",
                            fontWeight: 600,
                          }}
                          className={classes.datacell}
                        >
                          {average}
                        </TableCell>
                        {timeframe.map((t) => {
                          const timeFrameData = wondata.filter(
                            (c) => c.type === t
                          );
                          // console.log(timeFrameData)
                          // console.log( )
                          const quotaData = timeFrameData;
                          const timeAcv =
                            timeFrameData.length > 0
                              ? timeFrameData
                                  .map((c) => c.acv)
                                  .filter(Boolean)
                                  .reduce((a, b) => a + b, 0)
                              : 0;
                          const noOfReps =
                            timeFrameData.length > 0
                              ? quotaData
                                  .map((c) => ({ acv: c.acv, quota: c.quota }))
                                  .filter((f) => f.acv >= 0 || f.quota >= 0)
                                  .length
                              : 0;
                          const acv = ["", undefined, null].includes(timeAcv)
                            ? "-"
                            : countOrACVFormatter(
                                noOfReps > 0 ? timeAcv / 1000 / noOfReps : 0,
                                true
                              );
                          return (
                            <TableCell
                              align="right"
                              style={{
                                border: "0.5px solid lightgrey",
                                fontWeight: 600,
                              }}
                              className={classes.datacell}
                            >
                              {acv}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                      {repsWithTeam.map((rep, id) => {
                        let repData = props.data.filter(
                          (s) => s.rep === rep
                        )[0];
                        // console.log(repData)
                        let startDate = ["", undefined, null].includes(
                          repData.Hire_Date
                        )
                          ? "-"
                          : dateFormatterMomentV2(repData.Hire_Date);
                        let endDate = ["", undefined, null].includes(
                          repData.Term_Date
                        )
                          ? "-"
                          : dateFormatterMomentV2(repData.Term_Date);
                        let average = ["", undefined, null].includes(
                          repData.avgQ4
                        )
                          ? "-"
                          : countOrACVFormatter(repData.avgQ4 / 1000, true);
                        let wondata = repData.wonData;
                        let termDateFQ = ["", undefined, null].includes(
                          repData.maxTermHFQ
                        )
                          ? "-"
                          : repData.maxTermHFQ;
                        const bgColor = endDate === "-" ? "gray" : "#282727";
                        return (
                          <StyledTableRow key={rep}>
                            <TableCell
                              align="left"
                              style={{
                                border: "0.5px solid lightgrey",
                                paddingRight: "5px",
                              }}
                              className={classes.datacell}
                            >
                              {id + 1}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                border: "0.5px solid lightgrey",
                                paddingRight: "5px",
                              }}
                              className={`${classes.datacell} ${classes.indentName}`}
                            >
                              {endDate !== "-" ? ast(rep) : rep}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                border: "0.5px solid lightgrey",
                                paddingRight: "5px",
                              }}
                              className={classes.datacell}
                            >
                              {startDate}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                border: "0.5px solid lightgrey",
                                paddingRight: "5px",
                              }}
                              className={classes.datacell}
                            >
                              {endDate}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ border: "0.5px solid lightgrey" }}
                              className={classes.datacell}
                            >
                              {average}
                            </TableCell>
                            {timeframe.map((t) => {
                              const timeFrameData = wondata.filter(
                                (c) => c.type === t
                              );
                              // console.log(timeFrameData)
                              // console.log(timeFrameData.length > 0 ? timeFrameData[0].acv : null)
                              const Quota =
                                timeFrameData.length > 0
                                  ? timeFrameData[0].quota
                                  : null;
                              let timeAcv =
                                timeFrameData.length > 0
                                  ? timeFrameData[0].acv
                                  : parseInt(t.split("-")[1]) <=
                                    parseInt(termDateFQ.split("-")[1])
                                  ? 0
                                  : null;
                              timeAcv =
                                [undefined].includes(timeAcv) && Quota >= 0
                                  ? 0
                                  : timeAcv;
                              const acv = [undefined].includes(timeAcv, Quota)
                                ? "-"
                                : ["", null].includes(timeAcv)
                                ? ""
                                : countOrACVFormatter(timeAcv / 1000, true);
                              let timeCount =
                                timeFrameData.length > 0
                                  ? timeFrameData[0].count
                                  : null;
                              let count = [undefined].includes(timeCount)
                                ? "-"
                                : ["", null].includes(timeCount)
                                ? ""
                                : timeCount;
                              let cfq =
                                timeFrameData.length > 0
                                  ? timeFrameData[0].closed_fiscal_quarter
                                  : "-";
                              return (
                                <TableCell
                                  align="right"
                                  style={
                                    count === ""
                                      ? { backgroundColor: bgColor }
                                      : {
                                          border: "0.5px solid lightgrey",
                                          cursor: "pointer",
                                        }
                                  }
                                  onClick={() =>
                                    count !== "" &&
                                    props.handleChangeLocation(
                                      "drilldownOnGraph",
                                      rep,
                                      cfq,
                                      team
                                    )
                                  }
                                  className={classes.datacell}
                                >
                                  {count !== ""
                                    ? acv === ""
                                      ? "$0"
                                      : acv
                                    : acv}
                                </TableCell>
                              );
                            })}
                          </StyledTableRow>
                        );
                      })}
                    </>
                  );
                })
              : reps.map((rep, id) => {
                  let repData = props.data.filter((s) => s.rep === rep)[0];
                  let startDate = ["", undefined, null].includes(
                    repData.Hire_Date
                  )
                    ? "-"
                    : dateFormatterMomentV2(repData.Hire_Date);
                  let endDate = ["", undefined, null].includes(
                    repData.Term_Date
                  )
                    ? "-"
                    : dateFormatterMomentV2(repData.Term_Date);
                  let average = ["", undefined, null].includes(repData.avgQ4)
                    ? "-"
                    : countOrACVFormatter(repData.avgQ4 / 1000, true);
                  let termDateFQ = ["", undefined, null].includes(
                    repData.maxTermHFQ
                  )
                    ? "-"
                    : repData.maxTermHFQ;
                  const bgColor = endDate === "-" ? "gray" : "#282727";
                  let wondata = repData.wonData;
                  return (
                    <StyledTableRow key={rep}>
                      <TableCell
                        align="left"
                        style={{
                          border: "0.5px solid lightgrey",
                          paddingRight: "5px",
                        }}
                        className={classes.datacell}
                      >
                        {id + 1}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "0.5px solid lightgrey",
                          paddingRight: "5px",
                        }}
                        className={classes.datacell}
                      >
                        {endDate !== "-" ? ast(rep) : rep}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          border: "0.5px solid lightgrey",
                          paddingRight: "5px",
                        }}
                        className={classes.datacell}
                      >
                        {startDate}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          border: "0.5px solid lightgrey",
                          paddingRight: "5px",
                        }}
                        className={classes.datacell}
                      >
                        {endDate}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ border: "0.5px solid lightgrey" }}
                        className={classes.datacell}
                      >
                        {average}
                      </TableCell>
                      {timeframe.map((t) => {
                        const timeFrameData = wondata.filter(
                          (c) => c.type === t
                        );
                        const Quota =
                          timeFrameData.length > 0
                            ? timeFrameData[0].quota
                            : null;
                        let timeAcv =
                          timeFrameData.length > 0
                            ? timeFrameData[0].acv
                            : parseInt(t.split("-")[1]) <=
                              parseInt(termDateFQ.split("-")[1])
                            ? 0
                            : null;
                        timeAcv =
                          [undefined].includes(timeAcv) && Quota >= 0
                            ? 0
                            : timeAcv;
                        // console.log(timeAcv, Quota)
                        const acv = [undefined].includes(timeAcv, Quota)
                          ? "-"
                          : ["", null].includes(timeAcv)
                          ? ""
                          : countOrACVFormatter(timeAcv / 1000, true);
                        let timeCount =
                          timeFrameData.length > 0
                            ? timeFrameData[0].count
                            : null;
                        let count = [undefined].includes(timeCount)
                          ? "-"
                          : ["", null].includes(timeCount)
                          ? ""
                          : timeCount;
                        let cfq =
                          timeFrameData.length > 0
                            ? timeFrameData[0].closed_fiscal_quarter
                            : "-";
                        return (
                          <TableCell
                            align="right"
                            style={
                              count === ""
                                ? { backgroundColor: bgColor }
                                : {
                                    border: "0.5px solid lightgrey",
                                    cursor: "pointer",
                                  }
                            }
                            onClick={() =>
                              count !== "" &&
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                rep,
                                cfq
                              )
                            }
                            className={classes.datacell}
                          >
                            {count !== "" ? (acv === "" ? "$0" : acv) : acv}
                          </TableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
