import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import tableStyle from "../../CustomerSuccess/styles/tableStyle";
import {
  countOrACVFormatter,
  dateFormatterMonthInWords,
  StyledTableRow,
  _isNaN,
  customDateFormatter,
  getUniqueValues,
} from "../../util/customFunctions";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
// import ExcelExportButton from "../../components/xlsx-export/xlsxexport";

export default function StageProgressionTable(props) {
  const classes = tableStyle();
  const tableRef = React.createRef();
  const tableContainerRef = useRef();

  const [isSticky, setIsSticky] = useState(false);

  const fromStages = props.StageProgressionData.fromStages;
  const toStages = props.StageProgressionData.tostages;

  let total;
  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  return (
    <Grid item xs={12}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          // marginBottom: "5px",
          marginRight: "2rem",
        }}
      >
        <CopyTablesObject tableRef={tableRef} />
      </div>
      <TableContainer
        style={{
          margin: "2rem",
          width: "auto",
          borderLeft: isSticky ? "0.5px solid lightgrey" : "",
        }}
        id="myTableContainer"
        ref={tableContainerRef}
      >
        <Table
          ref={tableRef}
          size="small"
          aria-label="html table"
          style={{ tableLayout: "auto" }}
          data-test={props.dataTest}
        >
          <TableHead>
            <TableRow
              style={{
                fontWeight: 600,
                background: "#4472c4",
                color: "white",
                paddingLeft: 5,
                height: 60,
              }}
            >
              <TableCell
                align="center"
                style={{ background: "white" }}
                // className={classes.colHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : classes.colHeader
                }
              ></TableCell>
              {toStages.map((stage, index) => (
                <TableCell
                  colSpan={2}
                  style={
                    index % 2 === 0
                      ? {
                          background: "#4471c4",
                          color: "white",
                          minWidth: 60,
                          maxWidth: 60,
                        }
                      : {
                          background: "#5b9bd5",
                          color: "white",
                          minWidth: 60,
                          maxWidth: 60,
                        }
                  }
                  align="center"
                  className={classes.colHeader}
                >
                  {stage}
                </TableCell>
              ))}
              <TableCell
                colSpan={2}
                style={
                  toStages.length > 1 && toStages.length % 2 === 0
                    ? {
                        background: "#4471c4",
                        color: "white",
                        minWidth: 60,
                        maxWidth: 60,
                      }
                    : {
                        background: "#5b9bd5",
                        color: "white",
                        minWidth: 60,
                        maxWidth: 60,
                      }
                }
                align="center"
                className={classes.colHeader}
              >
                Grand Total
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                // className={classes.colHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : classes.colHeader
                }
                style={{ background: "white", color: "black", minWidth: 120 }}
              >
                From <span style={{ fontSize: "16px" }}> &#8595; </span> / To{" "}
                <span style={{ fontSize: "16px" }}> &#8594; </span>{" "}
              </TableCell>
              {toStages.map((stage, index) => (
                <>
                  <TableCell align="center" className={classes.colHeader}>
                    # of Opps
                  </TableCell>
                  <TableCell align="center" className={classes.colHeader}>
                    {/* ACV at Move */}
                    {`${props.Value_Label} at Move`}
                  </TableCell>
                </>
              ))}
              <TableCell align="center" className={classes.colHeader}>
                # of Opps
              </TableCell>
              <TableCell align="center" className={classes.colHeader}>
                {/* ACV at Move */}
                {`${props.Value_Label} at Move`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fromStages.map((stage, idx) => {
              total = idx + 1;
              let averageData = props.StageProgressionData.rowTotal.filter(
                (item) => item.fromstage === stage
              )[0];
              averageData =
                averageData === undefined ? { count: 0, acv: 0 } : averageData;
              return (
                <StyledTableRow key={stage}>
                  <TableCell
                    // style={{ minWidth: 120 }}
                    align="left"
                    // className={classes.colHeader}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.colHeader}`
                        : classes.colHeader
                    }
                    style={
                      isSticky && idx % 2 === 0
                        ? { background: "#f5f5f5", minWidth: 120 }
                        : { minWidth: 120 }
                    }
                  >
                    {stage}
                  </TableCell>
                  {toStages.map((s, idx) => {
                    let cellData =
                      props.StageProgressionData.stageProgression.filter(
                        (item) => item.fromStage === stage && item.toStage === s
                      );
                    cellData =
                      cellData.length === 0
                        ? { acv: 0, count: 0 }
                        : {
                            acv: cellData.reduce((a, b) => a + b.acv, 0),
                            count: cellData.reduce((a, b) => a + b.count, 0),
                          };
                    return (
                      <>
                        <TableCell
                          align="right"
                          className={classes.drilldownCell}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              [stage],
                              [s]
                            )
                          }
                        >
                          {cellData.count == 0
                            ? "-"
                            : countOrACVFormatter(cellData.count, false)}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.drilldownCell}
                          onClick={() =>
                            props.handleChangeLocation(
                              "drilldownOnGraph",
                              [stage],
                              [s]
                            )
                          }
                        >
                          {cellData.count == 0
                            ? "-"
                            : countOrACVFormatter(cellData.acv, true)}
                        </TableCell>
                      </>
                    );
                  })}
                  <TableCell
                    align="right"
                    className={classes.drilldownCell}
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        [stage],
                        toStages
                      )
                    }
                  >
                    {averageData.count == 0
                      ? "-"
                      : countOrACVFormatter(averageData.count, false)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.drilldownCell}
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        [stage],
                        toStages
                      )
                    }
                  >
                    {averageData.count == 0
                      ? "-"
                      : countOrACVFormatter(averageData.acv, true)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
            <StyledTableRow>
              <TableCell
                // style={{ minWidth: 120 }}
                align="left"
                // className={classes.colHeader}
                className={
                  isSticky
                    ? `${classes.sticky} ${classes.colHeader}`
                    : classes.colHeader
                }
                style={
                  isSticky && total % 2 === 0
                    ? { background: "#f5f5f5", minWidth: 120 }
                    : { minWidth: 120 }
                }
              >
                Grand Total
              </TableCell>
              {toStages.map((s, idx) => {
                let cellData = props.StageProgressionData.columnTotal.filter(
                  (item) => item.tostage === s
                );
                cellData =
                  cellData.length === 0 ? { acv: 0, count: 0 } : cellData[0];
                return (
                  <>
                    <TableCell
                      align="right"
                      className={classes.drilldownCell}
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          fromStages,
                          [s]
                        )
                      }
                    >
                      {cellData.count == 0
                        ? "-"
                        : countOrACVFormatter(cellData.count, false)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.drilldownCell}
                      onClick={() =>
                        props.handleChangeLocation(
                          "drilldownOnGraph",
                          fromStages,
                          [s]
                        )
                      }
                    >
                      {cellData.count == 0
                        ? "-"
                        : countOrACVFormatter(cellData.acv, true)}
                    </TableCell>
                  </>
                );
              })}
              <TableCell
                align="right"
                className={classes.drilldownCell}
                onClick={() =>
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    fromStages,
                    toStages
                  )
                }
              >
                {countOrACVFormatter(
                  props.StageProgressionData.columnTotal
                    .map((s) => s.count)
                    .reduce((a, b) => a + b, 0),
                  false
                )}
              </TableCell>
              <TableCell
                align="right"
                className={classes.drilldownCell}
                onClick={() =>
                  props.handleChangeLocation(
                    "drilldownOnGraph",
                    fromStages,
                    toStages
                  )
                }
              >
                {countOrACVFormatter(
                  props.StageProgressionData.columnTotal
                    .map((s) => s.acv)
                    .reduce((a, b) => a + b, 0),
                  true
                )}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
