import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { countOrACVFormatterBillionFixedOne, countOrACVFormatterMillion } from '../../util/customFunctions';

function VerticalStackedBarChart(props) {
  const chartRef = useRef();

  useEffect(() => {
    const resizeChart = () => {
        let data = props.data;
        // console.log(data, 'DATATTTT')
    
        data = data.map(function(d) {
            const type4 = Math.abs(d.type4 - d.type3);
            const type5 = d.type5 !== 0 ? Math.abs(d.type5 - d.type4) : 0;
            const type6 = d.type6 !== 0 ? Math.abs(d.type6 - d.type5) : 0;
    
            return {
              Category: d.Category,
              type1: d.type1,
              type2: d.type2,
              type3: d.type3,
              type4: type4, // Correct calculation for type4
              type5: type5, // Correct calculation for type5
              type6: type6, // Correct calculation for type6
            // type7: d.type3, // Renaming type5 as type3
            // type8: d.type7, // Renaming type6 as type5
          };
        });

    
        const el = chartRef.current;
        const margin = { top: 50, right: 20, bottom: 70, left: 70 };
        const width = d3.select(el).node().getBoundingClientRect().width - margin.left - margin.right;
        const height = 500 - margin.top - margin.bottom;
    
        d3.select(el).selectAll('*').remove(); // Clear previous content
    
        var x0 = d3.scaleBand().range([0, width])
        .paddingInner(0.6)
        .paddingOuter(1.2); // Reduced padding
        // var x1 = d3.scaleBand()
        var x1 = d3.scaleBand()
                // .range([0, x0.bandwidth()])
                // .padding(0.05); // Reduce this to reduce the space between bars within each group
        var y = d3.scaleLinear().range([height, 0]);
    
        var color = d3.scaleOrdinal().range(["#BF9000", "green", "blue"]);
        var color2 = d3.scaleOrdinal().range(["#C5E0B4", "#70AD47", "#a2df79", "#edfedc"]);
        var color3 = d3.scaleOrdinal().range(["#4472C4", "#90EE90"]);
    
        var svg = d3
          .select(el)
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    
        var innerColumns = {
          column1: ["type1", "type2"],
          column2: ["type3", "type4", "type5", "type6"],
        //   column3: ["type7", "type8"]
        };
    
        var columnHeaders = d3.keys(data[0]).filter(function(key) {
          return key !== "Category";
        });

        // console.log(columnHeaders, 'DATATTTT')
    
        color.domain(
          d3.keys(data[0]).filter(function(key) {
            return key !== "Category";
          })
        );
    
        data.forEach(function(d) {
          var yColumn = new Array();
          d.columnDetails = columnHeaders.map(function(name) {
            for (let ic in innerColumns) {
              if (innerColumns[ic].indexOf(name) >= 0) {
                if (!yColumn[ic]) {
                  yColumn[ic] = 0;
                }
                let yBegin = yColumn[ic];
                yColumn[ic] += +d[name];
                return {
                  name: name,
                  column: ic,
                  yBegin: yBegin,
                  yEnd: +d[name] + yBegin
                };
              }
            }
          });
          d.total = d3.max(d.columnDetails, function(d) {
            return d.yEnd;
          });
        });

        // console.log(data, 'DATATTTT')
    
        x0.domain(
          data.map(function(d) {
            return d.Category;
          })
        );
        x1.domain(d3.keys(innerColumns)).range([0, x0.bandwidth()]);
    
        y.domain([
          0,
          1.15 *
            d3.max(data, function(d) {
              return d.total;
            })
        ]);
    
        var stackedbar = svg
          .selectAll(".stackedbar")
          .data(data)
          .enter()
          .append("g")
          .attr("class", "g")
          .attr("transform", function(d) {
            return "translate(" + x0(d.Category) + ",0)";
          });
    
        stackedbar
          .selectAll("rect")
          .data(function(d) {
            return d.columnDetails;
          })
          .enter()
          .append("rect")
          .attr("x", function(d) {
            return x1(d.column);
          })
          .attr("y", function(d) {
            return y(d.yEnd);
          })
          .attr("width", x1.bandwidth()) // Increase bar width (80% of available space)
          .attr("height", function(d) {
            return y(d.yBegin) - y(d.yEnd);
          })
          .style("fill", function(d) {
            if (d.name === "type7") {
              return 'transparent';
            }
    
            if (d.column === "column2") {
              return color2(d.name);
            }
    
            if (d.column === "column3") {
              return color3(d.name);
            }
    
            return color(d.name);
          })
          .attr("stroke", function(d) {
            // Add a stroke only for type5 and type6
            return ["type5", "type6"].includes(d.name) ? "#70AD47" : "none";
          })
          .attr("stroke-width", 1) // Stroke width
          .attr("stroke-dasharray", function(d) {
            // Apply a dotted border for type5 and type6
            return ["type5", "type6"].includes(d.name) ? "4,4" : "none";
          })
          .attr("opacity", function(d) {
            if (d.name === "type7") {
              return 'transparent';
            }
    
            if (d.column === "column1") {
              return 0.5;
            }
    
            if (d.column === "column3") {
              return 0.8;
            }
    
            return 1;
          });
    
        stackedbar
          .selectAll(".bar-value")
          .data(function(d) {
            return d.columnDetails;
          })
          .enter()
          .append("text")
          .attr("class", "bar-value")
          .attr("x", function(d) {
            return x1(d.column) + (x1.bandwidth()) / 2; // Center the text within the bar
          })
          .attr("y", function(d) {
            // console.log(y(d.column))
            return ['type4'].includes(d.name) ? y(d.yEnd) - 20 : y(d.yEnd) - 5; // Position text above the bar
          })
          .style('font-size', '0.8rem')
          .style('font-weight', 500)
          .attr("text-anchor", "middle")
          .style('fill', function(d) {
            
            if(d.name === 'type3'){
              return 'white';
            }
            return 'black';
          })
          .text(function (d, i, nodes) {
            // Check if this is the last category
            const isLastCategory = d3.select(nodes[i].parentNode).datum().Category === data[data.length - 1].Category;
    
            // Find value at type3 in the same stack
        
            // If it's the last stacked bar and type3, return empty string
            if ( d.name === 'type3') {
              return "";
            }
            if (d.name === "type7" || ["type3", 'type5', 'type6'].includes(d.name)) {
              return "";
            }

            if(isLastCategory && d.name === 'type4'){
              return "";
            }
            
             else if (d.name === "type8") {
              return `${countOrACVFormatterMillion((d.yEnd - d.yBegin), true)}`;
            }
    
            return ['type1'].includes(d.name) ? countOrACVFormatterMillion(d.yEnd, true) : `${countOrACVFormatterMillion(d.yEnd, true)} `;
          })

          stackedbar
          .selectAll(".bar-value2")
          .data(function(d) {
            return d.columnDetails;
          })
          .enter()
          .append("text")
          .attr("class", "bar-value2")
          .attr("x", function(d) {
            return x1(d.column) + (x1.bandwidth()) / 2; // Center the text within the bar
          })
          .attr("y", function(d) {
            // return (y(d.yEnd)); // Position this text slightly below the bar's top
            const barHeight = y(d.yBegin) - y(d.yEnd); // Calculate the height of the bar
    return y(d.yEnd) + (barHeight / 2); // Position text vertically at the center
          })
          .style('font-size', '0.8rem')
          .style('font-weight', 500)
          .attr("text-anchor", "middle")
          .style('fill', function(d) {
            if(d.name === 'type3'){
              return 'black';
            }
            return 'white';
          })
          .text(function(d, i, nodes) {
            const barHeight = y(d.yBegin) - y(d.yEnd); // Calculate the height of the bar
            const heightThreshold = 20; // Set your desired height threshold
    // console.log(barHeight, heightThreshold, d.yEnd, d.yBegin, props.datav2,d, d.Category, props.datav2.filter(f => f.quarter === d.Category), 'barrrrrrr')
    // Check if the bar height is greater than the threshold
    
    if (barHeight > heightThreshold) {
      const isLastCategory = d3.select(nodes[i].parentNode).datum().Category === data[data.length - 1].Category;
        if (['type3'].includes(d.name)) {
          return countOrACVFormatterMillion(d.yEnd, true);
        } else if (!isLastCategory && ['type4'].includes(d.name)) {
          return countOrACVFormatterMillion(
           d.yEnd - d.yBegin, 
            true
          );
        }
      }
      return ''; // Return empty string if the bar height is below the threshold
          });
        //   .style("cursor", d => d.name === 'type3' || d.name === 'type6' ? 'pointer' : '')
        //   .on('click', d =>
        //     d.name === 'type3' ? props.handleChangeLocationClosedWon('FY') :
        //     d.name === 'type6' ? props.handleChangeLocation('drilldownOnGraph', 'FY') : ''
        //   );



        stackedbar
        .selectAll(".bar-value3")
        .data(function(d) {
          return d.columnDetails;
        })
        .enter()
        .append("foreignObject")
        .attr("class", "bar-value3")
        .attr("x", function(d) {
          // return x1(d.column) + (x1.bandwidth() * 1.1) / 8; // Center the text within the bar
          return x1(d.column) - 20 ;
        })
        .attr("y", function(d) {
          // console.log(y(d.column))
          return y(d.yEnd) - 60; // Position above the bar
        })
        .attr("width", x1.bandwidth() * 3) // Set width for the foreignObject
        .attr("height", 60) // Set an appropriate height
        .style('color', function(d) {
          if (d.name === 'type3') {
            return 'white';
          }
          return 'black';
        })
        .style('font-size', '0.8rem')
        .style('font-weight', 500)
        .style('text-align', 'center')
        .html(function (d, i, nodes) {
          // Check if this is the last category
          const isLastCategory = d3.select(nodes[i].parentNode).datum().Category === data[data.length - 1].Category;
          const parentData = d3.select(nodes[i].parentNode).datum().columnDetails;
    
            // Find value at type3 in the same stack
            const type1Data = parentData.find(item => item.name === 'type1');
        
          // If it's the last stacked bar and type3, return empty string
          if (isLastCategory && d.name === 'type6') {
            return `<div>
            <div><span style="font-weight:bold;">H: ${countOrACVFormatterMillion(props.lowMidHigh[0].high, true)} (${props.lowMidHigh[0].high > 0 && type1Data.yEnd > 0 ? Math.round((props.lowMidHigh[0].high / type1Data.yEnd) * 100) : 0}%)</span></div>
            <div><span style="font-weight:bold;">M: ${countOrACVFormatterMillion(props.lowMidHigh[0].mid, true)} (${props.lowMidHigh[0].mid > 0 && type1Data.yEnd > 0 ? Math.round((props.lowMidHigh[0].mid / type1Data.yEnd) * 100) : 0}%)</span></div>
            <div><span style="font-weight:bold;">L: ${countOrACVFormatterMillion(props.lowMidHigh[0].low, true)} (${props.lowMidHigh[0].low > 0 && type1Data.yEnd > 0 ? Math.round((props.lowMidHigh[0].low / type1Data.yEnd) * 100) : 0}%)</span></div>
            <div>`
          }
        return ''
        });


        stackedbar
          .selectAll(".bar-value4")
          .data(function(d) {
            return d.columnDetails;
          })
          .enter()
          .append("text")
          .attr("class", "bar-value4")
          .attr("x", function(d) {
            return x1(d.column) + (x1.bandwidth()) / 2; // Center the text within the bar
          })
          .attr("y", function(d) {
            return y(d.yEnd) - 5; // Position text above the bar
          })
          .style('font-size', '0.7rem')
          .style('font-weight', 500)
          .attr("text-anchor", "middle")
          .style('fill', function(d) {
            
            if(d.name === 'type3'){
              return 'white';
            }
            return 'black';
          })
          .text(function (d, i, nodes) {
            // Check if this is the last category
            const isLastCategory = d3.select(nodes[i].parentNode).datum().Category === data[data.length - 1].Category;
            const parentData = d3.select(nodes[i].parentNode).datum().columnDetails;
    
            // Find value at type3 in the same stack
            const type1Data = parentData.find(item => item.name === 'type1');
        
            // If it's the last stacked bar and type3, return empty string
            if ( d.name === 'type3') {
              return "";
            }
            if (d.name === "type7" || ["type3", 'type5', 'type6'].includes(d.name)) {
              return "";
            }

            if(isLastCategory && d.name === 'type4'){
              return "";
            }
            
             else if (d.name === "type8") {
              return '';
            }
    
            return ['type1', 'type2'].includes(d.name) ? '' : (d.yEnd > 0 && type1Data.yEnd > 0 ? `(${Math.round((d.yEnd/type1Data.yEnd) * 100)}%)` : '(0%)');
          })


          stackedbar
          .selectAll(".bar-value5")
          .data(function(d) {
            return d.columnDetails;
          })
          .enter()
          .append("text")
          .attr("class", "bar-value5")
          .attr("x", function(d) {
            return x1(d.column) + (x1.bandwidth()) / 2; // Center the text within the bar
          })
          .attr("y", function(d) {
            // return (y(d.yEnd)); // Position this text slightly below the bar's top
            const barHeight = y(d.yBegin) - y(d.yEnd); // Calculate the height of the bar
            return y(d.yEnd) + (barHeight / 2) + 12; // Position text vertically at the center
          })
          .style('font-size', '0.7rem')
          .style('font-weight', 500)
          .attr("text-anchor", "middle")
          .style('fill', function(d) {
            if(d.name === 'type3'){
              return 'black';
            }
            return 'white';
          })
          .text(function(d, i, nodes) {
            const barHeight = y(d.yBegin) - y(d.yEnd); // Calculate the height of the bar
            const heightThreshold = 30; // Set your desired height threshold
    // console.log(barHeight, heightThreshold, d.yEnd, d.yBegin, props.datav2,d, d.Category, props.datav2.filter(f => f.quarter === d.Category), 'barrrrrrr')
    // Check if the bar height is greater than the threshold
    const parentData = d3.select(nodes[i].parentNode).datum().columnDetails;
    
    // Find value at type3 in the same stack
    const type1Data = parentData.find(item => item.name === 'type1');
    if (barHeight > heightThreshold) {
      const isLastCategory = d3.select(nodes[i].parentNode).datum().Category === data[data.length - 1].Category;
        if (['type3'].includes(d.name)) {
          const p = type1Data.yEnd > 0 && d.yEnd > 0 ? Math.round((d.yEnd/type1Data.yEnd) * 100 ) : 0
          return `(${p}%)`
        }
      }
      return ''; // Return empty string if the bar height is below the threshold
          });
        //   .style("cursor", d => d.name === 'type3' || d.name === 'type6' ? 'pointer' : '')
        //   .on('click', d =>
        //     d.name === 'type3' ? props.handleChangeLocationClosedWon('FY') :
        //     d.name === 'type6' ? props.handleChangeLocation('drilldownOnGraph', 'FY') : ''
        //   );
      
    
        svg.append("g").call(d3.axisBottom(x0)).attr("transform", "translate(0," + height + ")");
        svg.append("g").call(d3.axisLeft(y).tickFormat(d => `$${d3.format(".2s")(d)}`.toUpperCase()));
    
        var line = d3.line()
            .x(function(d) {
                return x0(d.Category) + x0.bandwidth() / 1.4;
            })
            .y(function(d) {
                return y(d.type3);
            });
    
        svg.append("path")
            .datum(data)
            .attr("class", "line")
            .attr("d", line)
            .style("stroke-dasharray", "5,5")
            .style("fill", "none")
            .style("stroke", "black");
    
        d3.select("#legendsv28").selectAll('*').remove(); // Clear existing legends before adding new ones
    
        var legendData = columnHeaders.filter(function(key) {
          return key !== "Category" && key !== "type2" && key !== "type8" && !['type5', 'type6'].includes(key);
        });
    
        var legendNames = {
          type1: "Quota",
          type3: props.timeFrame === 'month' ? "Won MTD" : "Won QTD",
          type4: props.timeFrame === 'month' ? "Won EOM" : "Won EOQ",
          // type5: "Mid",
          // type6: "High",
        //   type6: 'Qual. Pipeline'
        };

        // var legendNames2 = {
        //     type1: "Quota",
        //     type3: "Won QTD",
        //     type4: "Low",
        //     type5: "Mid",
        //     type6: "High",
        //   //   type6: 'Qual. Pipeline'
        //   };

    
        var legendSvg = d3
          .select("#legendsv28")
          .append("svg")
          .attr("width", width)
          .attr("height", 40);
    
        var legend = legendSvg
          .selectAll(".legend")
          .data(legendData)
          .enter()
          .append("g")
          .attr("class", "legend")
          .attr("transform", function(d, i) {
            var legendWidth = width / legendData.length;
            return "translate(" + (300 + i * legendWidth) + ", 0)";
          });
    
        legend
          .append("rect")
          .attr("x", 0)
          .attr("width", 10)
          .attr("height", 10)
          .style("fill", function(d) {
            if (['type3', 'type4', ].includes(d)) {
              return color2(d);
            }

            if(['type5', 'type6'].includes(d)){
              return ''
            }
    
            // if (['type6'].includes(d)) {
            //   return color3(d);
            // }
    
            return color(d);
          });
    
        legend
          .append("text")
          .attr("x", 14)
          .attr("y", 6)
          .attr("dy", ".35em")
          .style("text-anchor", "start")
          .text(function(d) {
            return legendNames[d];
          });


    };    
      

    resizeChart();

    window.addEventListener('resize', resizeChart);
    return () => window.removeEventListener('resize', resizeChart);
  }, [props.data]);

  return (
    <>
      <div id="chartv28" ref={chartRef}></div>
      <div id="legendsv28"></div>
    </>
  );
}

export default VerticalStackedBarChart;