import {
  Typography,
  withStyles,
  Grid,
  Select,
  MenuItem,
  Paper,
  FormControl,
  InputLabel,
  Button,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Divider,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import {
  getNPriorQuarters,
  nearestCompletedFiscalQuarter,
} from "../../util/fiscalQuarterFunctions";
import {
  formatDataForMekkoChart,
  formatSummaryViewData,
  preFormatMekkoData,
} from "../../util/dataFormatter";
import SummaryViewTable from "../../components/SummaryViewTable";
import D3MekkoChart from "./D3PBIMekkoChart";
import PBISummaryLegend from "./PBISummaryLegend";
import NoDataComponent from "../../components/NoDataComponent";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import FiscalQuarterFilter from "../../Filters/FiscalQuarterFilter";
import RegionFilter from "../../Filters/Region_Filter";
import OpportunityType from "../../Filters/OpportunityType";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import WinAcvDrillDownDrilldownBody from "../WinACVAnalysis/WinAcvDrillDown";
import Drilldown from "../../components/Drilldown/Drilldown";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
} from "../../util/customFunctions";

import {
  colorsForRenewal,
  renewalsRow,
} from "../../CustomerSuccess/Pages/RenewalsCompleted/components/utils";
import Acct_SegmentFilter from "../../Filters/Acct_SegmentFilter";
import FilterContainer from "../../Filters/FilterContainer";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },

  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
  colHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
});
class PBISummaryView extends Component {
  filters = [
    "closed_fiscal_quarter",
    "Region_Rollup",
    "Booking_Type_NL_CS_US",
    "Acct_Segment",
  ];
  state = {
    closed_fiscal_quarter: ["All"],
    disabled: ["year", "month", "quarter"],
    earliestOpenStage: 3,
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Segment: ["All"],
    formattedData: "",
    appsCluster: "",
    vertical: "",
    filtersForDrilldown: [],
    filters: [],
    quarter: ["All"],
  };
  componentDidMount() {
    this.props.getPBISummaryFilters(this.props.company);
    window.addEventListener("resize", this.onResize);
  }
  componentWillUnmount() {
    D3MekkoChart.destroy(this._rootNode1);
    window.removeEventListener("resize", this.onResize);
  }
  onResize = () => {
    D3MekkoChart.destroy(this._rootNode1);
    const preFormattedMekkoData = preFormatMekkoData(this.props.PBISummaryData);
    const { properties, formattedData, industries } = formatDataForMekkoChart(
      preFormattedMekkoData
    );

    D3MekkoChart.destroy(this._rootNode1);
    this._chart1 = D3MekkoChart.create(this._rootNode1, {
      data: formattedData,
      labels: { appCluster: properties, vertical: industries },
      handleChangeLocation: this.handleChangeLocation,
    });
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }

  quarterForDrilldown = () => {
    let quarter = [];
    quarter = this.state.closed_fiscal_quarter;
    console.log(quarter);
    return quarter;
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    filtersArray = [
      ...filtersArray,
      {
        title: "Closed Fiscal Quarter",
        value: this.state.closed_fiscal_quarter,
      },
    ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    if (!this.state.Region_Rollup.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Region", value: this.state.Region_Rollup },
      ];

    if (!this.state.Acct_Segment.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Segment", value: this.state.Acct_Segment },
      ];

    return filtersArray;
  };

  handleChangeLocation = (location, x, y) => {
    console.log(x, y);
    if (location !== "overview") {
      var filters = [];
      if (!this.state.quarter.includes("All")) {
        filters = [
          ...filters,
          {
            name: "closed_fiscal_quarter",
            value: this.state.quarter,
            string: true,
          },
        ];
      }
      filters = [
        ...filters,
        {
          name: "Acct_Vertical",
          value: Array.isArray(y) ? y : [y],
          string: true,
        },
      ];

      let localFilters = this.props.currentFilters;
      console.log(this.state.filters);
      var filters1 = [];
      this.state.filters.map((item) => {
        if (item.title === "Closed Fiscal Quarter") {
          item.value = this.state.quarter;
          filters1.push(item);
        } else {
          filters1.push(item);
        }
      });

      filters = [...filters, ...localFilters];
      console.log(filters);
      var filtersDrilldown = [
        ...filters1,
        { title: "Vertical", value: Array.isArray(y) ? "All" : y },
        { title: "Cluster", value: x },
      ];
      this.props.getPbiSummaryDrilldown(filters, {
        appsCluster: x,
        oppStatus: "won",
      });
      this.setState({
        location: location,
        filtersForDrilldown: filtersDrilldown,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };
  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
    });
    //this.props.setTable('')
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    console.log(this.state.quarter);
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_names = [];

    this.setState({
      ...this.state,
      filters: [...this.passFiltersForDrilldown()],
      quarter: this.quarterForDrilldown(),
    });
    this.props.getPBISummaryData(this.props.company, filters);
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const currentQuarter = this.props.funnelCompareFilters.filter(
        (item) => item.type === "current_fiscal_quarter"
      )[0].current_fiscal_quarter;

      const defaultFiscalquarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelCompareFilters,
        "closed_fiscal_quarter"
      );

      this.setState({
        ...prevState,
        closed_fiscal_quarter: defaultFiscalquarter,
        quarter: defaultFiscalquarter,
        filters: this.passFiltersForDrilldown(),
      });
    }
    if (
      this.props.PBISummaryData !== "" &&
      prevProps.PBISummaryData === "" &&
      this.props.PBISummaryData.length !== 0
    ) {
      const preFormattedMekkoData = preFormatMekkoData(
        this.props.PBISummaryData
      );
      const { properties, formattedData, industries } = formatDataForMekkoChart(
        preFormattedMekkoData
      );

      D3MekkoChart.destroy(this._rootNode1);
      this._chart1 = D3MekkoChart.create(this._rootNode1, {
        data: formattedData,
        labels: { appCluster: properties, vertical: industries },
        handleChangeLocation: this.handleChangeLocation,
      });
    }
  }
  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      location: location,
    });
    this.props.getOpenOppData(oppId);
  };
  stepBack = () => {
    this.setState({
      ...this.state,
      location: "drilldownOnGraph",
    });
    this.props.clearExpectedAcvData();
  };

  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Acct_Vertical",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Region",
          LeadSource: "Lead_Source",
          quarter: "validated_fiscal_quarter",
          year: "validated_year",
          month: "validated_month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type_NetNew_Installed_Base",
          LeadSource: "Lead_Source",
          quarter: "open_fiscal_quarter",
          year: "open_calendar_year",
          month: "open_calendar_month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
        };
  render() {
    const { classes } = this.props;
    const tableHeaders =
      this.props.PBISummaryData !== "" &&
      this.props.PBISummaryData !== undefined &&
      this.props.PBISummaryData.length > 0
        ? Object.keys(this.props.PBISummaryData[0]).filter(
            (item) => item !== "Acct_Vertical"
          )
        : null;
    return (
      <Grid container justify="space-around">
        {/* <Grid
          className={classes.grid}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Won ACV by Vertical and Apps Cluster
          </Typography>
          <Typography variant="body1">
            Discover which Vertical segments and Apps clusters drove Won ACV
            with the classic Mekko chart
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Won ACV by Vertical and Apps Cluster",
          "Discover which Vertical segments and Apps clusters drove Won ACV with the classic Mekko chart"
        )}
        {this.props.funnelCompareFilters !== "" ? (
          <Grid item container xs={12}>
            <Grid item container xs={2} style={{ marginRight: "2rem" }}>
              <FiscalQuarterFilter
                handleChange={this.handleChange}
                funnelCompareFilters={this.props.funnelCompareFilters}
                closed_fiscal_quarter={this.state.closed_fiscal_quarter}
                type="closed_fiscal_quarter"
              />
            </Grid>
            <Grid container item style={{ padding: 10 }} xs={6}>
              <Grid item container xs={12}>
                <FilterContainer
                  filters={[
                    <OpportunityType
                      Booking_Type_NL_CS_US={this.state.Booking_Type_NL_CS_US}
                      handleChange={this.handleChange}
                      funnelCompareFilters={this.props.funnelCompareFilters}
                    />,
                    <RegionFilter
                      Region_RollupValue={this.state.Region_Rollup}
                      handleChange={this.handleChange}
                      funnelCompareFilters={this.props.funnelCompareFilters}
                    />,
                    <Acct_SegmentFilter
                      selectedAccount_Segment={this.state.Acct_Segment}
                      handleChange={this.handleChange}
                      funnelCompareFilters={this.props.funnelCompareFilters}
                    />,
                  ]}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {this.props.PBISummaryData !== "" ? (
          <>
            <Grid
              item
              container
              xs={12}
              justify="space-around"
              className={classes.grid}
            >
              <Grid
                container
                component={Card}
                raised={true}
                style={{ marginTop: "0.5rem" }}
              >
                <Grid item container xs={12} style={{ padding: "1rem" }}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6}>
                    <Typography
                      align="center"
                      variant="h2"
                      style={{ margin: "1rem" }}
                    >
                      Won ACV Performance by Vertical and Apps Cluster
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    container
                    justify="flex-end"
                    style={{ padding: "1rem" }}
                  ></Grid>
                </Grid>
                <Grid item xs={12}>
                  {this.props.PBISummaryData !== "" ? (
                    tableHeaders !== null ? (
                      <div
                        style={{
                          justifyContent: "center",
                          marginTop: 20,
                          margin: "auto",
                          width: "95%",
                        }}
                        id="svgContainer"
                        className="svg-container"
                        ref={this._setRef1.bind(this)}
                      />
                    ) : (
                      <NoDataComponent />
                    )
                  ) : (
                    <Grid container justify="center">
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="80%"
                        height={200}
                        style={{ margin: "20px 0px" }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.grid}>
              <Grid container component={Card}>
                <Grid item container xs={12}>
                  <Grid item xs={12} style={{ padding: "1rem" }}>
                    <Typography
                      align="center"
                      variant="h2"
                      style={{ margin: "1rem" }}
                    >
                      Won ACV by Vertical and Apps Cluster
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    justify="center"
                    style={{ marginTop: -20 }}
                  >
                    {this.props.PBISummaryData !== "" ? (
                      <Grid container>
                        {tableHeaders === null ? (
                          <NoDataComponent />
                        ) : (
                          <SummaryViewTable
                            data={formatSummaryViewData(
                              this.props.PBISummaryData,
                              tableHeaders,
                              true
                            )}
                            tableHeaders={tableHeaders}
                            isAcv={true}
                            handleChangeLocation={this.handleChangeLocation}
                            data_test={"won_acv_by_vertical"}
                          />
                        )}
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width="80%"
                          height={200}
                          style={{ margin: "20px 0px" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {this.props.PBISummaryData !== "" &&
                    tableHeaders !== null ? (
                      <PBISummaryLegend />
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item container xs={12} style={{ marginTop: 20 }}>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: "1rem", marginBottom: -20 }}
                  >
                    <Typography
                      align="center"
                      variant="h2"
                      style={{ margin: "1rem" }}
                    >
                      Won ACV by Vertical and Apps Cluster (% of total view)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} justify="center">
                    {this.props.PBISummaryData !== "" ? (
                      <Grid container>
                        {tableHeaders === null ? (
                          <NoDataComponent />
                        ) : (
                          <SummaryViewTable
                            ref={(el) => (this.componentRef = el)}
                            data={formatSummaryViewData(
                              this.props.PBISummaryData,
                              tableHeaders,
                              false
                            )}
                            tableHeaders={tableHeaders}
                            isAcv={false}
                            handleChangeLocation={this.handleChangeLocation}
                            data_test={"won_acv_by_vertical_total_view"}
                          />
                        )}
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width="80%"
                          height={200}
                          style={{ margin: "20px 0px" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "2rem" }}>
                    {this.props.PBISummaryData !== "" &&
                    tableHeaders !== null ? (
                      <PBISummaryLegend />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            style={{ marginTop: "1rem" }}
            container
            component={Card}
            raised={true}
            justify="center"
          >
            <Skeleton
              animation="wave"
              variant="rect"
              width="80%"
              height={200}
              style={{ margin: "20px 0px" }}
            />
          </Grid>
        )}
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Won ACV by Vertical and Apps Cluster"}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              {console.log(this.props.drilldownOnGraphData)}
              <Drilldown
                header={"Won ACV by Vertical and Apps Cluster"}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                title="mekko"
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.location === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage body={this.props.ExpectedAcvData} />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    PBISummaryFilters,
    PBISummaryData,
    user,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;
  return {
    funnelCompareFilters: PBISummaryFilters,
    PBISummaryData: PBISummaryData,
    company: user.company,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getPBISummaryFilters: (company) => {
    dispatch({ type: "get_pbi_summary_filters_request" });
    appService
      .getChartFilters(
        company === "C0003" ? "C0003.CHID00000013" : `${company}.CHID0000009`
      )
      .then(
        (json) => {
          dispatch({ type: "get_pbi_summary_filters_success", json });
          dispatch({ type: "get_pbi_summary_data_request" });
          const defaultFiscalquarters = getDefaultSelectedFiscalQuarters(
            json.message,
            "closed_fiscal_quarter"
          );
          var filters = [
            {
              name: "closed_fiscal_quarter",
              value: defaultFiscalquarters,
              string: true,
            },
          ];
          dispatch({ type: "current_selected_filters", filters });
          appService
            .getChartData(
              company === "C0003"
                ? "C0003.CHID00000013"
                : `${company}.CHID00000015`,
              json.message.length === 0 ? [] : filters
            )
            .then(
              (json) => {
                dispatch({ type: "get_pbi_summary_data_success", json });
              },
              (error) => {
                if (typeof error === "object")
                  dispatch({
                    type: "get_pbi_summary_data_failure",
                    error: "Something went wrong",
                  });
                else dispatch({ type: "get_pbi_summary_data_failure", error });
              }
            );
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_pbi_summary_filters_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_pbi_summary_mix_filters_failure", error });
        }
      );
  },
  getPbiSummaryDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    console.log(meta);
    appService.getPbiSummaryDrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.result.recordsets[1];
        tempOpportunities.sort((a, b) => (a.acv > b.acv ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.result.recordsets[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getPBISummaryData: (company, filters = []) => {
    dispatch({ type: "get_pbi_summary_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService
      .getChartData(
        company === "C0003" ? "C0003.CHID00000013" : `${company}.CHID00000015`,
        filters
      )
      .then(
        (json) => {
          dispatch({ type: "get_pbi_summary_data_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_pbi_summary_data_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_pbi_summary_data_failure", error });
        }
      );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedPBISummaryView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PBISummaryView));
export default connectedPBISummaryView;
