import {
  Card,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as d3 from "d3";
import React, { useEffect, useState } from "react";
import { StyledTableRow } from "../../util/customFunctions";
import { formatDataValuesNew, camelize } from "../../util/dataFormatter";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";

const useStyle = makeStyles((theme) => ({
  colHeader: {
    fontSize: "0.85rem",
    border: "0.5px solid lightgrey",
    padding: "7px 7px",
    color: "inherit",
    fontWeight: "inherit",
  },
  funnelTableWidth: {
    width: "10%",
  },
  drilldownCell: {
    cursor: "pointer",
  },
  fontBold: {
    fontWeight: 600,
  },
}));
export default function FunnelTableClosed(props) {
  const classes = useStyle();
  const tableRef = React.createRef();
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const tableContainer = document.getElementById("myTableContainerclosed");

    const handleOverflowCheck = () => {
      if (tableContainer) {
        setIsOverflowing(
          tableContainer.scrollWidth > tableContainer.offsetWidth
        );
      }
    };

    // Check for overflow initially and whenever the component updates
    handleOverflowCheck();

    // Attach a resize event listener to check for overflow when the window is resized
    window.addEventListener("resize", handleOverflowCheck);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleOverflowCheck);
    };
  }, []);
  return (
    <Grid xs={12}>
      {isOverflowing ? (
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "10px",
            // marginRight: "2rem",
          }}
        >
          <CopyTablesObject tableRef={tableRef} />
        </div>
      ) : null}
      <Grid item xs={12} component={Card} justify="center" container>
        <TableContainer style={{ padding: "2rem" }} id="myTableContainerclosed">
          {isOverflowing === false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
                // marginRight: "10px",
              }}
            >
              <CopyTablesObject tableRef={tableRef} />
            </div>
          ) : null}
          <Table ref={tableRef} size="small" data-test={props.data_test}>
            <TableHead></TableHead>
            <TableBody>
              <TableRow className={classes.fontBold}>
                <TableCell
                  className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                  style={{ minWidth: 80 }}
                >
                  Stage
                </TableCell>
                <TableCell
                  className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                  align="center"
                >
                  Came to Stage
                </TableCell>
                <TableCell
                  className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                  style={{ background: "#c65911", color: "white" }}
                  align="center"
                >
                  Lost / Disqualified from Stage
                </TableCell>

                <TableCell
                  className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                  style={{ background: "#70ad47", color: "white" }}
                  align="center"
                >
                  Moved to next stage
                </TableCell>
                <TableCell
                  className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                  align="center"
                >
                  {/* Win Rate % */}
                  {`${props.Win_Rate_Prefix} %`}
                </TableCell>
                {props.showTeamData && (
                  <TableCell
                    className={`${classes.colHeader} ${classes.funnelTableWidth}`}
                    align="center"
                  >
                    {/* Company Avg. Win Rate % */}
                    {`Company Avg. ${props.Win_Rate_Prefix} %`}
                  </TableCell>
                )}
              </TableRow>
              {props.data.map((item, i) => (
                <StyledTableRow key={i}>
                  <TableCell className={classes.colHeader} align="left">
                    {item.label}
                  </TableCell>
                  <TableCell
                    className={`${classes.colHeader} ${classes.drilldownCell}`}
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        item.label,
                        false,
                        "cameTo"
                      )
                    }
                    style={
                      item.label === props.wonLabel
                        ? { background: "#548236", color: "white" }
                        : {}
                    }
                    align="right"
                  >
                    {formatDataValuesNew(
                      item[props.isAcv ? "acv" : "count"],
                      props.type,
                      props.company
                    )}
                  </TableCell>
                  {/* onClick={()=>props.handleChangeLocation('drilldownOnGraph',item.label,false,'lost')} */}
                  {item.label !== props.wonLabel ? (
                    <>
                      <TableCell
                        className={`${classes.colHeader} ${classes.drilldownCell}`}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            item.label,
                            false,
                            "lost"
                          )
                        }
                        align="right"
                      >
                        {formatDataValuesNew(
                          props.isAcv
                            ? props.data[i + 1]
                              ? item.acv - props.data[i + 1].acv
                              : 0
                            : props.data[i + 1]
                            ? item.count - props.data[i + 1].count
                            : "",
                          props.type,
                          props.company
                        )}
                      </TableCell>
                      <TableCell
                        className={`${classes.colHeader} ${classes.drilldownCell}`}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            item.label,
                            false,
                            "movedOn"
                          )
                        }
                        align="right"
                      >
                        {formatDataValuesNew(
                          props.isAcv
                            ? props.data[i + 1]
                              ? props.data[i + 1].acv
                              : 0
                            : props.data[i + 1]
                            ? props.data[i + 1].count
                            : 0,
                          props.type,
                          props.company
                        )}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell
                        className={`${classes.colHeader} ${classes.drilldownCell}`}
                        onClick={() =>
                          props.handleChangeLocation(
                            "drilldownOnGraph",
                            item.label,
                            false,
                            "lost"
                          )
                        }
                        align="right"
                      >
                        {"-"}
                      </TableCell>
                      <TableCell className={classes.colHeader} align="right">
                        {"-"}
                      </TableCell>
                    </>
                  )}
                  <TableCell className={classes.colHeader} align="right">
                    {props.isAcv
                      ? `${
                          Math.min(...props.data.map((d) => d.acv)) === 0 ||
                          item.acv === 0
                            ? 0
                            : Math.round(
                                (Math.min(...props.data.map((d) => d.acv)) /
                                  item.acv) *
                                  100
                              )
                        }%`
                      : `${
                          Math.min(...props.data.map((d) => d.count)) === 0 ||
                          item.count === 0
                            ? 0
                            : Math.round(
                                (Math.min(...props.data.map((d) => d.count)) /
                                  item.count) *
                                  100
                              )
                        }%`}
                  </TableCell>
                  {props.showTeamData && (
                    <TableCell className={classes.colHeader} align="right">
                      {props.isAcv
                        ? `${
                            Math.min(...props.data.map((d) => d.acvCompay)) ===
                              0 || item.acvCompay === 0
                              ? 0
                              : Math.round(
                                  (Math.min(
                                    ...props.data.map((d) => d.acvCompay)
                                  ) /
                                    item.acvCompay) *
                                    100
                                )
                          }%`
                        : `${
                            Math.min(
                              ...props.data.map((d) => d.countCompany)
                            ) === 0 || item.countCompany === 0
                              ? 0
                              : Math.round(
                                  (Math.min(
                                    ...props.data.map((d) => d.countCompany)
                                  ) /
                                    item.countCompany) *
                                    100
                                )
                          }%`}
                    </TableCell>
                  )}
                </StyledTableRow>
              ))}
              <StyledTableRow className={classes.fontBold} key={"Total"}>
                <TableCell className={classes.colHeader} align="left">
                  {"Total"}
                </TableCell>
                <TableCell className={classes.colHeader} align="right">
                  {"-"}
                </TableCell>
                <TableCell
                  className={`${classes.colHeader} ${classes.drilldownCell}`}
                  onClick={() =>
                    props.handleChangeLocation(
                      "drilldownOnGraph",
                      props.data
                        .map((item) => item.label)
                        .filter((v) => v !== props.wonLabel),
                      false,
                      "lost"
                    )
                  }
                  align="right"
                >
                  {formatDataValuesNew(
                    props.data
                      .map((item, i) =>
                        props.isAcv
                          ? props.data[i + 1] &&
                            item.acv - props.data[i + 1].acv
                          : props.data[i + 1] &&
                            item.count - props.data[i + 1].count
                      )
                      .filter((item) => item !== undefined)
                      .reduce((a, b) => a + b, 0),
                    props.type,
                    props.company
                  )}
                </TableCell>
                <TableCell className={classes.colHeader} align="right">
                  {"-"}
                </TableCell>
                <TableCell className={classes.colHeader} align="right">
                  {"-"}
                </TableCell>
                {props.showTeamData && (
                  <TableCell className={classes.colHeader} align="right">
                    {"-"}
                  </TableCell>
                )}
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}